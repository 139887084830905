import {
  UPDATE_PASSWORD_REQUEST, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAILURE,
  UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAILURE,
  GET_PROFILE_FORM,
  CREATE_PHONE_REQUEST, CREATE_PHONE_SUCCESS, CREATE_PHONE_FAILURE,CREATE_MAIL_REQUEST,
  CREATE_MAIL_SUCCESS,
  GET_MAIL,
  CREATE_MAIL_FAILURE,    
  GET_PHONE_NUMBERS, GET_INFOPHONE_FORM,
  CREATE_EDUCATION_REQUEST, CREATE_EDUCATION_SUCCESS, CREATE_EDUCATION_FAILURE,
  UPDATE_EDUCATION_REQUEST, UPDATE_EDUCATION_SUCCESS, UPDATE_EDUCATION_FAILURE,
  REMOVE_EDUCATION_REQUEST, REMOVE_EDUCATION_SUCCESS, REMOVE_EDUCATION_FAILURE,
  GET_EDUCATION_FORM, GET_EDUCATION_LEVELS,
  CREATE_EXPERIENCE_REQUEST, CREATE_EXPERIENCE_SUCCESS, CREATE_EXPERIENCE_FAILURE,
  UPDATE_EXPERIENCE_REQUEST, UPDATE_EXPERIENCE_SUCCESS, UPDATE_EXPERIENCE_FAILURE,
  REMOVE_EXPERIENCE_REQUEST, REMOVE_EXPERIENCE_SUCCESS, REMOVE_EXPERIENCE_FAILURE,
  GET_EXPERIENCE_FORM,
  CREATE_LANGUAGE_REQUEST, CREATE_LANGUAGE_SUCCESS, CREATE_LANGUAGE_FAILURE,
    CREATE_PUBLICATION_REQUEST, CREATE_PUBLICATION_SUCCESS, CREATE_PUBLICATION_FAILURE,GET_PROFILE_PUBLICATION,
  GET_LANGUAGE_LEVELS, GET_PROFILE_LANGUAGES, GET_LANGUAGE_LIST,
  CREATE_SKILLS_REQUEST, CREATE_SKILLS_SUCCESS, CREATE_SKILLS_FAILURE,
  GET_PROFILE_SKILLS,
  CREATE_SECTORS_REQUEST, CREATE_SECTORS_SUCCESS, CREATE_SECTORS_FAILURE,
  GET_PROFILE_SECTORS,
  CREATE_DOCUMENT_REQUEST, CREATE_DOCUMENT_SUCCESS, CREATE_DOCUMENT_FAILURE,
  UPDATE_DOCUMENT_REQUEST, UPDATE_DOCUMENT_SUCCESS, UPDATE_DOCUMENT_FAILURE,
  REMOVE_DOCUMENT_REQUEST, REMOVE_DOCUMENT_SUCCESS, REMOVE_DOCUMENT_FAILURE,
  GET_DOCUMENT_FORM,
  GET_POSITIONS_FORM,
  CREATE_EXTERNAL_INVITATION_REQUEST, CREATE_EXTERNAL_INVITATION_SUCCESS, CREATE_EXTERNAL_INVITATION_FAILURE
} from 'actions/profile';


// Posible status :
// - creating
// - available
// - updating
// - removing
// - removed

const initialState = {
settings: {
  status: null,
  error: null,
  },
general: {
  status: null,
  error: null,
  data: {},
  },
phone: {
  status: null,
  error: null,
  list: [],
  },  
mails: {
  status: null,
  error: null,
  list: [],
  },  
infophone: {
  status: null,
  error: null,
  list: [],
  },  
positions: {
  error: null,
  message: null,
  spinner: false,
  list: [],
  },
contact: {
  status: null,
  error: null,
  },
education: {
  status: null,
  error: null,
  data: {},
  educationLevels: [],
  },
experience: {
  status: null,
  error: null,
  data: {},
  },
language: {
  status: null,
  error: null,
  message:null,
  list: [],
  levelList: [],
  spinner:false,
  profile_languages: [],
},
publication: {
  status: null,
  error: null,
  list: [],
  levelList: [],
  profile_publication: [],
},
skills: {
  status: null,
  error: null,
  message: null,
  spinner: false,
  profile_skills: [],
},
industries: {
  status: null,
  error: null,
  message: null,
  spinner: false,
  profile_industries: [],
},
sectors: {
  status: null,
  error: null,
  profile_sectors: [],
},
document: {
  status: null,
  error: null,
  data: {},
  list:[],
  spinner:false,
},
externalInvitation: {
  status: null,
  error: null,
},
add_experience:{
  error:null,
  message:null,
  spinner:false,
},
add_education:{
  error:null,
  message:null,
  spinner:false,
},
update_experience:{
  error:null,
  message:null,
  spinner:false,
},
update_education:{
  error:null,
  message:null,
  spinner:false,
},
get_emails:{
  spinner:false,
  error:null,
  list:[]
},
add_emails:{
  spinner:false,
  error:null,
  message:null,
},
remove_emails:{
  spinner:false,
  error:null,
  message:null,
},
get_phones:{
  spinner:false,
  error:null,
  list:[]
},
add_phones:{
  spinner:false,
  error:null,
  message:null,
},
remove_phones:{
  spinner:false,
  error:null,
  message:null,
},
countries:{
  list:[],
  error:null,
  spinner:false,
},
get_locations:{
  list:[],
  error:null,
  spinner:false,
},
add_locations:{
  list:[],
  error:null,
  spinner:false,
},
remove_locations:{
  list:[],
  error:null,
  spinner:false,
},
get_profile_settings:{
  list:{},
  spinner:false,
  error:null
},
activate_email:{
  message:null,
  error:null,
  spinner:false
},
add_doc_to_profile:{
  error:null,
  message:null,
  spinner:false
}
}
// ADD_DOC_TO_PROFILE
export function profil(state = initialState, action){

switch(action.type) {

  case 'ADD_DOC_TO_PROFILE':return {...state,add_doc_to_profile:{...state.add_doc_to_profile, message:action.payload.message, spinner:false, error:null}}
  case 'ADD_DOC_TO_PROFILE_FAILURE':return {...state,add_doc_to_profile:{...state.add_doc_to_profile, error:action.payload.errer, spinner:false, message:null}}
  case 'HANDLE_ADD_DOC_TO_PROFILE':return {...state,add_doc_to_profile:{...state.add_doc_to_profile, error:null, spinner:true, message:null}}
  case 'RESET_ADD_DOC_TO_PROFILE_MESSAGES':return {...state,add_doc_to_profile:{...state.add_doc_to_profile, error:null, spinner:false, message:null}}

case 'ACTIVATE_PROFILE_EMAIL':
  return  {...state, activate_email:{...state.activate_email, error: null,  message: action.payload.message, spinner:false}};
case 'ACTIVATE_PROFILE_EMAIL_FAILURE':
  return  {...state, activate_email:{...state.activate_email, error: action.payload.error,  message:null, spinner:false}};
case 'HANDLE_ACTIVATE_PROFILE_EMAIL':
  return  {...state, activate_email:{...state.activate_email, error:null,  message:null, spinner:true}};
case 'GET_PROFILE_SETTINGS':
  return {...state, get_profile_settings:{...state.get_profile_settings, error: null,  list: action.payload, spinner:false}};
case 'HANDLE_GET_PROFILE_SETTINGS':
  return {...state, get_profile_settings:{...state.get_profile_settings, error: null,  list: {}, spinner:true}};
case 'GET_PROFILE_SETTINGS_FAILURE':
  return {...state, get_profile_settings:{...state.get_profile_settings, error: action.payload.error,  list:{}, spinner:false}};
case 'GET_COUNTRIES':
  return  {...state, countries:{...state.countries, error: null,  list: action.payload, spinner:false}};
case 'GET_COUNTRIES_FAILURE':
  return  {...state, countries:{...state.countries, error: action.payload.error,  list:[], spinner:false}};
case 'HANDLE_GET_COUNTRIES':
  return  {...state, countries:{...state.countries, error: null,  list: [], spinner:true}};
  
  case 'GET_PROFILE_EMAILS':
    return  {...state, get_emails:{...state.get_emails, error: null,  list: action.payload.results, spinner:false}};
  case 'GET_PROFILE_EMAILS_FAILURE':
    return  {...state, get_emails:{...state.get_emails, error: action.payload.error,  list:[], spinner:false}};
  case 'HANDLE_GET_PROFILE_EMAILS':
    return  {...state, get_emails:{...state.get_emails, error: null,  list: [], spinner:true}};

  case 'ADD_PROFILE_EMAILS':
    return  {...state, add_emails:{...state.add_emails, error: null, message:action.payload.message, list: [], spinner:false}};
  case 'ADD_PROFILE_EMAILS_FAILURE':
    return  {...state, add_emails:{...state.add_emails, error: action.payload.error,  list:[], spinner:false}};
  case 'HANDLE_ADD_PROFILE_EMAILS':
    return  {...state, add_emails:{...state.add_emails, error: null,  list: [], spinner:true}};

    case 'REMOVE_PROFILE_EMAILS':
    return  {...state, remove_emails:{...state.remove_emails, error: null, message:action.payload.message, list: [], spinner:false}};
  case 'REMOVE_PROFILE_EMAILS_FAILURE':
    return  {...state, remove_emails:{...state.remove_emails, error: action.payload.error,  message:null, spinner:false}};
  case 'HANDLE_REMOVE_PROFILE_EMAILS':
    return  {...state, remove_emails:{...state.remove_emails, error: null,  message:null, spinner:true}};

    case 'GET_PROFILE_PHONES':
    return  {...state, get_phones:{...state.get_phones, error: null,  list: action.payload, spinner:false}};
  case 'GET_PROFILE_PHONES_FAILURE':
    return  {...state, get_phones:{...state.get_phones, error: action.payload.error,  list:[], spinner:false}};
  case 'HANDLE_GET_PROFILE_PHONES':
    return  {...state, get_phones:{...state.get_phones, error: null,  list: [], spinner:true}};

    case 'REMOVE_PROFILE_PHONES':
    return  {...state, remove_phones:{...state.remove_phones, error: null,  message: action.payload.message, spinner:false}};
  case 'REMOVE_PROFILE_PHONES_FAILURE':
    return  {...state, remove_phones:{...state.remove_phones, error: action.payload.error,  message:null, spinner:false}};
  case 'HANDLE_REMOVE_PROFILE_PHONES':
    return  {...state, remove_phones:{...state.remove_phones, error: null,  message:null, spinner:true}};

    case 'REMOVE_PROFILE_LOCATIONS':
    return  {...state, remove_locations:{...state.remove_locations, error: null, message:action.payload.message, list: [], spinner:false}};
  case 'REMOVE_PROFILE_LOCATIONS_FAILURE':
    return  {...state, remove_locations:{...state.remove_locations, error: action.payload.error,  list:[], spinner:false}};
  case 'HANDLE_REMOVE_PROFILE_LOCATIONS':
    return  {...state, remove_locations:{...state.remove_locations, error: null,  list: [], spinner:true}};

    case 'ADD_PROFILE_LOCATIONS':
    return  {...state, add_locations:{...state.add_locations, error: null, message:action.payload.message, list: [], spinner:false}};
  case 'ADD_PROFILE_LOCATIONS_FAILURE':
    return  {...state, add_locations:{...state.add_locations, error: action.payload.error,  list:[], spinner:false}};
  case 'HANDLE_ADD_PROFILE_LOCATIONS':
    return  {...state, add_locations:{...state.add_locations, error: null,  list: [], spinner:true}};

    case 'GET_PROFILE_LOCATIONS':
    return  {...state, get_locations:{...state.get_locations, error: null, message:null, list:action.payload, spinner:false}};
  case 'GET_PROFILE_LOCATIONS_FAILURE':
    return  {...state, get_locations:{...state.get_locations, error: action.payload.error,  list:[], spinner:false}};
  case 'HANDLE_GET_PROFILE_LOCATIONS':
    return  {...state, get_locations:{...state.get_locations, error: null,  list: [], spinner:true}};

  case 'ADD_PROFILE_PHONES':
    return  {...state, add_phones:{...state.add_phones, error: null, message:action.payload.message, list: [], spinner:false}};
  case 'ADD_PROFILE_PHONES_FAILURE':
    return  {...state, add_phones:{...state.add_phones, error: action.payload.error,  list:[], spinner:false}};
  case 'HANDLE_ADD_PROFILE_PHONES':
    return  {...state, add_phones:{...state.add_phones, error: null,  list: [], spinner:true}};
  case UPDATE_PASSWORD_REQUEST:
    return {...state, settings:{...state.settings, status: 'updating',  error: null}};

  case UPDATE_PASSWORD_SUCCESS:
    return {...state, settings:{...state.settings, status: 'available',  error: null}};

  case UPDATE_PASSWORD_FAILURE:
    return {...state, settings:{...state.settings, status: 'updating',  error: action.payload.error || action.payload.message}};


  case UPDATE_PROFILE_REQUEST:
    return {...state, general:{...state.general, status: 'updating',  error: null}};

  case UPDATE_PROFILE_SUCCESS:
    return {...state, general:{...state.general, status: 'available',  error: null}};

  case UPDATE_PROFILE_FAILURE:
    return {...state, general:{...state.general, status: 'updating',  error: action.payload.error || action.payload.message}};

  case GET_PROFILE_FORM:
    return {...state, general: {...state.general, data: action.payload}}


  case CREATE_PHONE_REQUEST:
    return {...state, phone:{...state.phone, status: 'creating',  error: null}};

  case CREATE_PHONE_SUCCESS:
    return {...state, phone:{...state.phone, status: 'available',  error: null}};

  case CREATE_PHONE_FAILURE:
    return {...state, phone:{...state.phone, status: 'creating',  error: action.payload.error || action.payload.message}};


  case GET_PHONE_NUMBERS:
    return {...state, phone: {...state.phone, list: action.payload}}
  
  case GET_INFOPHONE_FORM:
    return {...state, infophone: {...state.infophone, list: action.payload}}



  
  case GET_MAIL:
    return {...state, mails: {...state.mails, list: action.payload.results}}

  case CREATE_MAIL_REQUEST:
    return {...state, mails:{...state.mails, status: 'creating',  error: null}};

  case CREATE_MAIL_SUCCESS:
    return {...state, mails:{...state.mails, status: 'available',  error: null}};

  case CREATE_MAIL_FAILURE:
    return {...state, mails:{...state.mails, status: 'creating',  error: action.payload.error || action.payload.message}};



  case GET_POSITIONS_FORM:
    return {...state, positions: {...state.positions, list: action.payload}}

  case CREATE_EDUCATION_REQUEST:
    return {...state, education:{...state.education, status: 'creating',  error: null}};

  case CREATE_EDUCATION_SUCCESS:
    return {...state, education:{...state.education, status: 'available',  error: null}};

  case CREATE_EDUCATION_FAILURE:
    return {...state, education:{...state.education, status: 'creating',  error: action.payload.error || action.payload.message}};


  case UPDATE_EDUCATION_REQUEST:
    return {...state, education:{...state.education, status: 'updating',  error: null}};

  case UPDATE_EDUCATION_SUCCESS:
    return {...state, education:{...state.education, status: 'available',  error: null}};

  case UPDATE_EDUCATION_FAILURE:
    return {...state, education:{...state.education, status: 'updating',  error: action.payload.error || action.payload.message}};


  case REMOVE_EDUCATION_REQUEST:
    return {...state, education:{...state.education, status: 'removing',  error: null}};

  case REMOVE_EDUCATION_SUCCESS:
    return {...state, education:{...state.education, status: 'removed',  error: null}};

  case REMOVE_EDUCATION_FAILURE:
    return {...state, education:{...state.education, status: 'removing',  error: action.payload.error || action.payload.message}};


  case GET_EDUCATION_FORM:
    return {...state, education: {...state.education, data: action.payload}}

  case GET_EDUCATION_LEVELS:
    return {...state, education: {...state.education, educationLevels:action.payload.results}}



  case CREATE_EXPERIENCE_REQUEST:
    return {...state, experience:{...state.experience, status: 'creating',  error: null}};

  case CREATE_EXPERIENCE_SUCCESS:
    return {...state, experience:{...state.experience, status: 'available',  error: null}};

  case CREATE_EXPERIENCE_FAILURE:
    return {...state, experience:{...state.experience, status: 'creating',  error: action.payload.error || action.payload.message}};


  case UPDATE_EXPERIENCE_REQUEST:
    return {...state, experience:{...state.experience, status: 'updating',  error: null}};

  case UPDATE_EXPERIENCE_SUCCESS:
    return {...state, experience:{...state.experience, status: 'available',  error: null}};

  case UPDATE_EXPERIENCE_FAILURE:
    return {...state, experience:{...state.experience, status: 'updating',  error: action.payload.error || action.payload.message}};


  case REMOVE_EXPERIENCE_REQUEST:
    return {...state, experience:{...state.experience, status: 'removing',  error: null}};

  case REMOVE_EXPERIENCE_SUCCESS:
    return {...state, experience:{...state.experience, status: 'removed',  error: null}};

  case REMOVE_EXPERIENCE_FAILURE:
    return {...state, experience:{...state.experience, status: 'removing',  error: action.payload.error || action.payload.message}};


  case GET_EXPERIENCE_FORM:
    return {...state, experience: {...state.experience, data: action.payload}}


  case CREATE_LANGUAGE_REQUEST:
    return {...state, language: {...state.language, status:'creating', error: null, spinner:false}};
  case 'HANDLE_CREATE_LANGUAGE':
     return {...state, language: {...state.language, spinner:true, error: null}};
  case CREATE_LANGUAGE_SUCCESS:
    return {...state, language: {...state.language, status: 'available', error: null, message:action.payload.message, spinner:false}};

  case CREATE_LANGUAGE_FAILURE:
    return {...state, language: {...state.language, status: 'creating', error: action.payload.error, message: action.payload.message,spinner:true}};

case CREATE_PUBLICATION_REQUEST:
    return {...state, publication: {...state.publication, status:'creating', error: null}};

  case CREATE_PUBLICATION_SUCCESS:
    return {...state, publication: {...state.publication, status: 'available', error: null}};

  case CREATE_PUBLICATION_FAILURE:
    return {...state, publication: {...state.publication, status: 'creating', error: action.payload.error || action.payload.message}};


  case GET_LANGUAGE_LIST:
    return {...state, language: {...state.language, list: action.payload }}

  case GET_LANGUAGE_LEVELS:
    return {...state, language: {...state.language, levelList: action.payload.results}}

  case GET_PROFILE_LANGUAGES:
    return {...state, language: {...state.language, profile_languages: action.payload.results}}

  // case 'GET_COUNTRIES':
  //   return {...state, countries: {...state.countries, countries: action.payload.results}}
case GET_PROFILE_PUBLICATION:
    return {...state, publication: {...state.publication, profile_publication: action.payload.results}}


  case CREATE_SKILLS_REQUEST:
    return {...state, skills: {...state.skills, status:'creating', error: null}};

  case CREATE_SKILLS_SUCCESS:
    return {...state, skills: {...state.skills, status: action.payload, error: null}};

  case 'ADD_POSITIONS':
      return {...state, positions:{...state.positions, message:action.payload.message,error:null, spinner:false}}
  case 'ADD_POSITIONS_FAILURE':
      return {...state, positions:{...state.positions, message:null,error:action.payload.error, spinner:false}}
  case 'HANDLE_ADD_POSITIONS':
      return {...state, positions:{...state.positions, message:null,error:null, spinner:true}}

      case 'ADD_INDUSTRIES':
      return {...state, industries:{...state.industries, message:action.payload.message,error:null, spinner:false}}
  case 'ADD_INDUSTRIES_FAILURE':
      return {...state, industries:{...state.industries, message:null,error:action.payload.error, spinner:false}}
  case 'HANDLE_ADD_INDUSTRIES':
      return {...state, industries:{...state.industries, message:null,error:null, spinner:true}}
  case 'ADD_EDUCATION':
     return {...state,add_education:{...state.add_education, message:action.payload.message, spinner:false, error:null}}
  case 'ADD_EDUCATION_FAILURE':
     return {...state,add_education:{...state.add_education, error:action.payload.errer, spinner:false, message:null}}
  case 'HANDLE_ADD_EDUCATION':
     return {...state,add_education:{...state.add_education, error:null, spinner:true, message:null}}


  case 'UPDATE_EDUCATION':return {...state,update_education:{...state.update_education, message:action.payload.message, spinner:false, error:null}}
  case 'UPDATE_EDUCATION_FAILURE':return {...state,update_education:{...state.update_education, error:action.payload.errer, spinner:false, message:null}}
  case 'HANDLE_UPDATE_EDUCATION':return {...state,update_education:{...state.update_education, error:null, spinner:true, message:null}}
     
     case 'UPDATE_EXPERIENCE':
     return {...state,update_experience:{...state.update_experience, message:action.payload.message, spinner:false, error:null}}
  case 'UPDATE_EXPERIENCE_FAILURE':
     return {...state,update_experience:{...state.update_experience, error:action.payload.error, spinner:false, message:null}}
  case 'HANDLE_UPDATE_EXPERIENCE':
     return {...state,update_experience:{...state.update_experience, message:null, spinner:true, error:null}}

  case 'ADD_EXPERIENCE':
     return {...state,add_experience:{...state.add_experience, message:action.payload.message, spinner:false, error:null}}
  case 'ADD_EXPERIENCE_FAILURE':
     return {...state,add_experience:{...state.add_experience, error:action.payload.error, spinner:false, message:null}}
  case 'HANDLE_ADD_EXPERIENCE':
     return {...state,add_experience:{...state.add_experience, message:null, spinner:true, error:null}}
     
  case 'ADD_SKILLS':
      return {...state, skills:{...state.skills, message:action.payload.message,error:null, spinner:false}}
  case 'ADD_SKILLS_FAILURE':
      return {...state, skills:{...state.skills, message:null,error:action.payload.error, spinner:false}}
  case 'HANDLE_ADD_SKILLS':
      return {...state, skills:{...state.skills, message:null,error:null, spinner:true}}

  case CREATE_SKILLS_FAILURE:
    return {...state, skills: {...state.skills, status: null, error: action.payload}};


  case GET_PROFILE_SKILLS:
    return {...state, skills: {...state.skills, profile_skills: action.payload}};


  case CREATE_SECTORS_REQUEST:
    return {...state, sectors: {...state.sectors, status:'creating', error: null}};

  case CREATE_SECTORS_SUCCESS:
    return {...state, sectors: {...state.sectors, status: 'available', error: null}};

  case CREATE_SECTORS_FAILURE:
    return {...state, sectors: {...state.sectors, status: 'creating', error: action.payload.error || action.payload.message}};

  case GET_PROFILE_SECTORS:
    return {...state, sectors: {...state.sectors, profile_sectors: action.payload.results}};


  case 'HANDLE_CREATE_DOCUMENT': return {...state, document: {...state.document, status: null,  error: null, spinner:true}};
  case CREATE_DOCUMENT_REQUEST: return {...state, document: {...state.document, status: 'creating',  error: null, spinner:false}};

  case CREATE_DOCUMENT_SUCCESS:
    return {...state, document: {...state.document, status: 'available',  error: null, spinner:false}};

  case CREATE_DOCUMENT_FAILURE:
    return {...state, document: {...state.document, status: 'creating',  error: action.payload.error || action.payload.message, spinner:false}};


  case UPDATE_DOCUMENT_REQUEST:
    return {...state, document: {...state.document, status: 'updating',  error: null}};

  case UPDATE_DOCUMENT_SUCCESS:
    return {...state, document: {...state.document, status: 'available',  error: null}};

  case UPDATE_DOCUMENT_FAILURE:
    return {...state, document: {...state.document, status: 'updating',  error: action.payload.error || action.payload.message}};


  case REMOVE_DOCUMENT_REQUEST:
    return {...state, document: {...state.document, status: 'removing',  error: null}};

  case REMOVE_DOCUMENT_SUCCESS:
    return {...state, document: {...state.document, status: 'removed',  error: null}};

  case REMOVE_DOCUMENT_FAILURE:
    return {...state, document: {...state.document, status: 'removing',  error: action.payload.error || action.payload.message}};


  case GET_DOCUMENT_FORM:
    return {...state, document: {...state.document, list: action.payload.results}}


  case CREATE_EXTERNAL_INVITATION_REQUEST:
    return {...state, externalInvitation: {...state.externalInvitation, status: 'creating',  error: null}};

  case CREATE_EXTERNAL_INVITATION_SUCCESS:
    return {...state, externalInvitation: {...state.externalInvitation, status: 'available',  error: null}};

  case CREATE_EXTERNAL_INVITATION_FAILURE:
    return {...state, externalInvitation: {...state.externalInvitation, status: 'creating',  error: action.payload.error || action.payload.message}};


  default:
    return state;
 }
}
