import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { EditEducationSuccess,EditEducationError} from "actions/profile";

const EditEducationData = async (instance,data)=>
    await instance.put(`/profiles/educations/${data.educationId}/edit/`, data)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* EditEducation(actions){
    
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(EditEducationData,axiosInstance, actions.data);
    if(data.message){
        yield put(EditEducationSuccess(data.message));
        yield put({type:"HANDLE_GET_PROFILE_EDUCATIONS"})
    }else{
        console.log("data.error", data, data.error)
        yield put(EditEducationError(data.error));
    }
}
export function* handleEditEducation(){
    yield takeEvery("HANDLE_EDIT_EDUCATION", EditEducation)
}
export default function* rootSaga() {
    yield all([fork(handleEditEducation)]);
}

