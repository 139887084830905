import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { AddLangueSuccess,AddLangueError} from "actions/profile";
//import AddSectors from "containers/Profil/AddSectors";

const AddLangueData = async (instance,data)=>
    await instance.post(`/profiles/languages/add/`, data)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* AddLangue(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(AddLangueData,axiosInstance, actions.data);
    if(data.message){
        yield put(AddLangueSuccess(data.message));
        yield put({type:"HANDLE_GET_PROFILE_LANGUE"})
    }else{
        console.log("data.error", data, data.error)
        yield put(AddLangueError(data.error));
    }
}
export function* handleAddLangue(){
    yield takeEvery("HANDLE_ADD_LANGUE", AddLangue)
    console.log("Langue", "OK")
}
export default function* rootSaga() {
    yield all([fork(handleAddLangue)]);
}

