import React,{useEffect} from 'react';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import { cleanStringQuery } from 'util/UtilFunctions';
import {useDispatch,useSelector} from 'react-redux'
import { handleOpportunityTypeSearch } from 'actions/filters/opportunityType';
import IntlMessages from "util/IntlMessages";
  
function OpportunityType(props) {
  const dispatch = useDispatch();
  const opportunity_type = useSelector(state=>state.opportunityType.search_opportunity_type.opportunity_type);

  const handleSearchSkill=(data)=>{
    dispatch(handleOpportunityTypeSearch({scope:props.scope, search:`?FacetCategory=${data.currentTarget.value}`}))
  }

  useEffect(()=>{
      dispatch(handleOpportunityTypeSearch({scope: props.scope, search:`${cleanStringQuery(window.location.search)?`?${cleanStringQuery(window.location.search)}`:''}`}))
  },[dispatch])

  const onChecked=(value)=>{
    if(props.keys.filter(obj=>obj.name===value).length!==0){
        props.delete({name:value, type:'OpportunityType'})
    }else{
        props.addItem({name:value, type:'OpportunityType'})
    }
   }
    return (
        <FormControl className="filter">
          <InputLabel htmlFor="select-multiple-chip">{<IntlMessages id="categories"/>}</InputLabel>
          <Select
            label={<IntlMessages id="opportunity_type"/>}
            multiple
            value={[]}
            >
            <div className="search">
            <Input  className="filter"
             name="search" type="search" 
             placeholder="Search"
             autocomplete="off" 
             onChange={(data)=>handleSearchSkill(data)}/>
            </div>
            {opportunity_type.map((name,key) => (
              <MenuItem key={key} value={name.category_id__name}>
                <Checkbox checked={props.keys.filter(obj=>obj.name===name.category_id__name).length!==0} onClick={()=>onChecked(name.category_id__name)}/>
                <ListItemText primary={`${name.category__name}(${name.total})`} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
     
    );
}

export default OpportunityType;
