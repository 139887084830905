const initialState = {
    error: null,
    details:{
    },
    keysDashboard:{
      error: null,
      message: null,
      spinner: false,
      list: [],
    },  
    topLocationsDashboard:{
      error: null,
      message: null,
      spinner: false,
      list: [],
    }, 
    bestTalents:{
        error: null,
        message: null,
        spinner: false,
        list: [],
      }, 
    matchingDashboard:{
        error: null,
        message: null,
        count:0,
        spinner: false,
        list: [],
      }, 
    applicationsDashboard:{
        error: null,
        message: null,
        spinner: false,
        count:0,
        list: [],
      },
      applicationsDashboardForm:{
          error: null,
          message: null,
          spinner: false,
          count:0,
          list: [],
        }, 
        applicationsDashboardFormSelect:{
            error: null,
            message: null,
            spinner: false,
            count:0,
            list: [],
          },
      applicationsSharedDashboard: {
        error: null,
        message: null,
        spinner: false,
        count:0,
        list: [],
      },
    opportunitiesDashboard:{
        error: null,
        message: null,
        count:0,
        spinner: false,
        list: [],
      },
    followersDashboard:{
        error: null,
        message: null,
        count:0,
        spinner: false,
        list: [],
      }, 
    scoresDashboard:{
        error: null,
        message: null,
        pages : 0,
        spinner: false,
        list: [],
      }, 
    recentActivitiesDashboard:{
        error: null,
        message: null,
        pages : 0,
        spinner: false,
        list: [],
      },  
    currentPlanDashboard:{
        error: null,
        message: null,
        spinner: false,
        list: {},
        }, 
    connexionsDashboard:{
        error: null,
        message: null,
        pages : 0,
        spinner: false,
        list: [],
          }, 
    spontaneousApplyDashboard:{
        error: null,
        message: null,
        pages : 0,
        spinner: false,
        list: [],
        }, 
    spontaneousApplyDashboardPipeline:{
        error: null,
        message: null,
        count : 0,
        spinner: false,
        list: [],
        }, 
    applicationsRecommendationsDashboard:{
        error: null,
        message: null,
        count : 0,
        spinner: false,
        list: [],
        }, 
    searchesAppearenceDashboard:{
        error: null,
        message: null,
        pages : 0,
        spinner: false,
        list: [],
        }, 
    opportunitiesApplyDashboard:{
        error: null,
        message: null,
        pages : 0,
        spinner: false,
        list: [],
                }, 
    viewProfilDashboard:{
        error: null,
        message: null,
        pages : 0,
        spinner: false,
        list: [],
        }, 
    applicationsSelectedAppDashboard:{
        error: null,
        message: null,
        pages : 0,
        count: false,
        list: [],
        },
    applicationsSelectedRecDashboard:{
        error: null,
        message: null,
        pages : 0,
        count: false,
        list: [],
        },
    applicationsSelectedSharedDashboard:{
        error: null,
        message: null,
        pages : 0,
        count: false,
        list: [],
        },
    applicationsSpontaneousApplySelectedDashboard:{
        error: null,
        message: null,
        count : 0,
        spinner: false,
        list: [],
            },
opportunitiesDeleteDashboard:{
    error: null,
    message: null,
    count : 0,
    spinner: false,
    list: [],
},
opportunitiesOnlineDashboard:{
    error: null,
    message: null,
    count : 0,
    spinner: false,
    list: [],
},
opportunitiesOfflineDashboard:{
    error: null,
    message: null,
    count : 0,
    spinner: false,
    list: [],
},
  }
  export function keys(state = initialState, action){
  
   switch(action.type) {
   /**
    * Get keys dashboard
    */
  case "HANDLE_GET_KEYS_DASHBOARD":
          return{
              ...state, keysDashboard:{
                  ...state.keysDashboard, error:null, spinner:true
            }
      }
  case "GET_KEYS_DASHBOARD":
      return {
      ...state, keysDashboard:{
          ...state.keysDashboard, error:null, spinner:false, list:action.payload
        }
      }
      
  case "GET_KEYS_DASHBOARD_FAILLURE":
              return{
                  ...state, keysDashboard:{
                      ...state.keysDashboard, error:action.payload, spinner:true, list:null
                  }
          }

    case "KEYS_DASHBOARD_MESSAGES":
              return{
                  ...state, keysDashboard:{
                      ...state.keysDashboard, error:null, spinner:false, message:null
                  }
              }


    /**
    * Get Best talents dashboard
    */
 case "HANDLE_GET_BEST_TALENTS_DASHBOARD":
    return{
        ...state, bestTalents:{
            ...state.bestTalents, error:null, spinner:true
      }
}
case "GET_BEST_TALENTS_DASHBOARD":
return {
...state, bestTalents:{
    ...state.bestTalents, error:null, spinner:false, list:action.payload.results.best_talents
  }
}

case "GET_BEST_TALENTS_DASHBOARD_FAILLURE":
        return{
            ...state, bestTalents:{
                ...state.bestTalents, error:action.payload, spinner:true, list:null
            }
    }

case "BEST_TALENTS_DASHBOARD_MESSAGES":
        return{
            ...state, bestTalents:{
                ...state.bestTalents, error:null, spinner:false, message:null
            }
        }

    
     /**
    * Get top location company with slug
    */
  case "HANDLE_GET_TOP_LOCATIONS_DASHBOARD":
    return{
        ...state, topLocationsDashboard:{
            ...state.topLocationsDashboard, error:null, spinner:true
      }
        }
        case "GET_TOP_LOCATIONS_DASHBOARD":
        return {
        ...state, topLocationsDashboard:{
            ...state.topLocationsDashboard, error:null, spinner:false, list:action.payload.results
        }
        }

        case "GET_TOP_LOCATIONS_DASHBOARD_FAILLURE":
                return{
                    ...state, topLocationsDashboard:{
                        ...state.topLocationsDashboard, error:action.payload, spinner:true, list:null
                    }
            }

        case "TOP_LOCATIONS_DASHBOARD_MESSAGES":
                return{
                    ...state, topLocationsDashboard:{
                        ...state.topLocationsDashboard, error:null, spinner:false, message:null
                    }
                }



/*
* Get Best applicants dashboard
*/
    case "HANDLE_GET_APPLICATIONS_DASHBOARD":
        return{
            ...state, applicationsDashboard:{
                ...state.applicationsDashboard, error:null, spinner:true, count:0
            }
    }
    case "GET_APPLICATIONS_DASHBOARD":
    return {
    ...state, applicationsDashboard:{
        ...state.applicationsDashboard, error:null, spinner:false, list:action.payload.results, count:action.payload.count
        }
    }

    case "GET_APPLICATIONS_DASHBOARD_FAILLURE":
            return{
                ...state, applicationsDashboard:{
                    ...state.applicationsDashboard, error:action.payload, spinner:true, list:null, count:0
                }
        }

    case "APPLICATIONS_DASHBOARD_MESSAGES":
            return{
                ...state, applicationsDashboard:{
                    ...state.applicationsDashboard, error:null, spinner:false, message:null, count:0
                }
            }





/*
* Get Opportunities form dashboard
*/
case "HANDLE_GET_APPLICATIONS_DASHBOARD_FORM":
    return{
        ...state, applicationsDashboardForm:{
            ...state.applicationsDashboardForm, error:null, spinner:true, count:0
        }
}
case "GET_APPLICATIONS_DASHBOARD_FORM":
return {
...state, applicationsDashboardForm:{
    ...state.applicationsDashboardForm, error:null, spinner:false, list:action.payload.results, count:action.payload.count
    }
}

case "GET_APPLICATIONS_DASHBOARD_FORM_FAILLURE":
        return{
            ...state, applicationsDashboardForm:{
                ...state.applicationsDashboardForm, error:action.payload, spinner:true, list:null, count:0
            }
    }

case "APPLICATIONS_DASHBOARD_FORM_MESSAGES":
        return{
            ...state, applicationsDashboardForm:{
                ...state.applicationsDashboardForm, error:null, spinner:false, message:null, count:0
            }
        }




/*
* Get Opportunities DIDH form dashboard
*/
case "HANDLE_GET_APPLICATIONS_DASHBOARD_FORM_SELECTED":
    return{
        ...state, applicationsDashboardFormSelect:{
            ...state.applicationsDashboardFormSelect, error:null, spinner:true, count:0
        }
}
case "GET_APPLICATIONS_DASHBOARD_FORM_SELECTED":
return {
...state, applicationsDashboardFormSelect:{
    ...state.applicationsDashboardFormSelect, error:null, spinner:false, list:action.payload.results, count:action.payload.count
    }
}

case "GET_APPLICATIONS_DASHBOARD_FORM_SELECTED_FAILLURE":
        return{
            ...state, applicationsDashboardFormSelect:{
                ...state.applicationsDashboardFormSelect, error:action.payload, spinner:true, list:null, count:0
            }
    }

case "APPLICATIONS_DASHBOARD_FORM_SELECTED_MESSAGES":
        return{
            ...state, applicationsDashboardFormSelect:{
                ...state.applicationsDashboardFormSelect, error:null, spinner:false, message:null, count:0
            }
        }


/*
* Get Best SHARED applicants dashboard
*/
    case "HANDLE_GET_APPLICATIONS_SHARED_DASHBOARD":
        return{
            ...state, applicationsSharedDashboard:{
                ...state.applicationsSharedDashboard, error:null, spinner:true, count:0
            }
    }
    case "GET_APPLICATIONS_SHARED_DASHBOARD":
    return {
    ...state, applicationsSharedDashboard:{
        ...state.applicationsSharedDashboard, error:null, spinner:false, list:action.payload.results, count:action.payload.count
        }
    }

    case "GET_APPLICATIONS_SHARED_DASHBOARD_FAILLURE":
            return{
                ...state, applicationsSharedDashboard:{
                    ...state.applicationsSharedDashboard, error:action.payload, spinner:true, list:null, count:0
                }
        }

    case "APPLICATIONS_SHARED_DASHBOARD_MESSAGES":
            return{
                ...state, applicationsSharedDashboard:{
                    ...state.applicationsSharedDashboard, error:null, spinner:false, message:null,count:0
                }
            }






/*
* Get Best recmonndations applicants dashboard
*/
    case "HANDLE_GET_APPLICATIONS_RECOMMENDATIONS_DASHBOARD":
        return{
            ...state, applicationsRecommendationsDashboard:{
                ...state.applicationsRecommendationsDashboard, error:null, spinner:true, count:0
            }
    }
    case "GET_APPLICATIONS_RECOMMENDATIONS_DASHBOARD":
    return {
    ...state, applicationsRecommendationsDashboard:{
        ...state.applicationsRecommendationsDashboard, error:null, spinner:false, list:action.payload.results, count:action.payload.count
        }
    }

    case "GET_APPLICATIONS_RECOMMENDATIONS_DASHBOARD_FAILLURE":
            return{
                ...state, applicationsRecommendationsDashboard:{
                    ...state.applicationsRecommendationsDashboard, error:action.payload, spinner:true, list:null, count:0
                }
        }

    case "APPLICATIONS_RECOMMENDATIONS_DASHBOARD_MESSAGES":
            return{
                ...state, applicationsRecommendationsDashboard:{
                    ...state.applicationsRecommendationsDashboard, error:null, spinner:false, message:null,count:0
                }
            }

/*
* Get matching dashboard
*/
case "HANDLE_GET_MATCHING_DASHBOARD":
    return{
        ...state, matchingDashboard:{
            ...state.matchingDashboard, error:null, spinner:true, count:0
        }
}
case "GET_MATCHING_DASHBOARD":
return {
...state, matchingDashboard:{
    ...state.matchingDashboard, error:null, spinner:false, list:action.payload.results.matching, count:action.payload.count
    }
}

case "GET_MATCHING_DASHBOARD_FAILLURE":
        return{
            ...state, matchingDashboard:{
                ...state.matchingDashboard, error:action.payload, spinner:true, list:null, count:0
            }
    }

case "MATCHING_DASHBOARD_MESSAGES":
        return{
            ...state, matchingDashboard:{
                ...state.matchingDashboard, error:null, spinner:false, message:null, count:0
            }
        }

/*
* Get followers dashboard
*/
case "HANDLE_GET_FOLLOWERS_DASHBOARD":
    return{
        ...state, followersDashboard:{
            ...state.followersDashboard, error:null, spinner:true, count:0
        }
}
case "GET_FOLLOWERS_DASHBOARD":
return {
...state, followersDashboard:{
    ...state.followersDashboard, error:null, spinner:false, list:action.payload.results, count:action.payload.count
    }
}

case "GET_FOLLOWERS_DASHBOARD_FAILLURE":
        return{
            ...state, followersDashboard:{
                ...state.followersDashboard, error:action.payload, spinner:true, list:null, count:0
            }
    }

case "FOLLOWERS_DASHBOARD_MESSAGES":
        return{
            ...state, followersDashboard:{
                ...state.followersDashboard, error:null, spinner:false, message:null,count:0
            }
        }
      


/*
* Get followers dashboard
*/
case "HANDLE_GET_SCORES_DASHBOARD":
    return{
        ...state, scoresDashboard:{
            ...state.scoresDashboard, error:null, spinner:true
        }
}
case "GET_SCORES_DASHBOARD":
return {
...state, scoresDashboard:{
    ...state.scoresDashboard, error:null, spinner:false, list:action.payload, pages:action.payload.pages
    }
}

case "GET_SCORES_DASHBOARD_FAILLURE":
        return{
            ...state, scoresDashboard:{
                ...state.scoresDashboard, error:action.payload, spinner:true, list:null
            }
    }

case "SCORES_DASHBOARD_MESSAGES":
        return{
            ...state, scoresDashboard:{
                ...state.scoresDashboard, error:null, spinner:false, message:null
            }
        }
      



/*
* Get opportunities dashboard
*/
case "HANDLE_GET_OPPORTUNITIES_DASHBOARD":
    return{
        ...state, opportunitiesDashboard:{
            ...state.opportunitiesDashboard, error:null, spinner:true ,count:0
        }
}
case "GET_OPPORTUNITIES_DASHBOARD":
return {
...state, opportunitiesDashboard:{
    ...state.opportunitiesDashboard, error:null, spinner:false, list:action.payload.results, count:action.payload.count
    }
}

case "GET_OPPORTUNITIES_DASHBOARD_FAILLURE":
        return{
            ...state, opportunitiesDashboard:{
                ...state.opportunitiesDashboard, error:action.payload, spinner:true, list:null,count:0
            }
    }

case "OPPORTUNITIES_DASHBOARD_MESSAGES":
        return{
            ...state, opportunitiesDashboard:{
                ...state.opportunitiesDashboard, error:null, spinner:false, message:null, count:0
            }
        }




/*
* Get opportunities dashboard
*/
case "HANDLE_GET_OPPORTUNITIES_ONLINE_DASHBOARD":
    return{
        ...state, opportunitiesOnlineDashboard:{
            ...state.opportunitiesOnlineDashboard, error:null, spinner:true ,count:0
        }
}
case "GET_OPPORTUNITIES_ONLINE_DASHBOARD":
return {
...state, opportunitiesOnlineDashboard:{
    ...state.opportunitiesOnlineDashboard, error:null, spinner:false, list:action.payload.results, count:action.payload.count
    }
}

case "GET_OPPORTUNITIES_ONLINE_DASHBOARD_FAILLURE":
        return{
            ...state, opportunitiesOnlineDashboard:{
                ...state.opportunitiesOnlineDashboard, error:action.payload, spinner:true, list:null,count:0
            }
    }

case "OPPORTUNITIES_ONLINE_DASHBOARD_MESSAGES":
        return{
            ...state, opportunitiesOnlineDashboard:{
                ...state.opportunitiesOnlineDashboard, error:null, spinner:false, message:null, count:0
            }
        }





/*
* Get opportunities dashboard
*/
case "HANDLE_GET_OPPORTUNITIES_OFFLINE_DASHBOARD":
    return{
        ...state, opportunitiesOfflineDashboard:{
            ...state.opportunitiesOfflineDashboard, error:null, spinner:true ,count:0
        }
}
case "GET_OPPORTUNITIES_OFFLINE_DASHBOARD":
return {
...state, opportunitiesOfflineDashboard:{
    ...state.opportunitiesOfflineDashboard, error:null, spinner:false, list:action.payload.results, count:action.payload.count
    }
}

case "GET_OPPORTUNITIES_OFFLINE_DASHBOARD_FAILLURE":
        return{
            ...state, opportunitiesOfflineDashboard:{
                ...state.opportunitiesOfflineDashboard, error:action.payload, spinner:true, list:null,count:0
            }
    }

case "OPPORTUNITIES_OFFLINE_DASHBOARD_MESSAGES":
        return{
            ...state, opportunitiesOfflineDashboard:{
                ...state.opportunitiesOfflineDashboard, error:null, spinner:false, message:null, count:0
            }
        }



/*
* Get opportunities dashboard
*/
case "HANDLE_GET_OPPORTUNITIES_DELETE_DASHBOARD":
    return{
        ...state, opportunitiesDeleteDashboard:{
            ...state.opportunitiesDeleteDashboard, error:null, spinner:true ,count:0
        }
}
case "GET_OPPORTUNITIES_DELETE_DASHBOARD":
return {
...state, opportunitiesDeleteDashboard:{
    ...state.opportunitiesDeleteDashboard, error:null, spinner:false, list:action.payload.results, count:action.payload.count
    }
}

case "GET_OPPORTUNITIES_DELETE_DASHBOARD_FAILLURE":
        return{
            ...state, opportunitiesDeleteDashboard:{
                ...state.opportunitiesDeleteDashboard, error:action.payload, spinner:true, list:null,count:0
            }
    }

case "OPPORTUNITIES_DELETE_DASHBOARD_MESSAGES":
        return{
            ...state, opportunitiesDeleteDashboard:{
                ...state.opportunitiesDeleteDashboard, error:null, spinner:false, message:null, count:0
            }
        }



/*
* Get Recent activities
*/
case "HANDLE_GET_RECENT_ACTIVITIES_DASHBOARD":
    return{
        ...state, recentActivitiesDashboard:{
            ...state.recentActivitiesDashboard, error:null, spinner:true
        }
}
case "GET_RECENT_ACTIVITIES_DASHBOARD":
return {
...state, recentActivitiesDashboard:{
    ...state.recentActivitiesDashboard, error:null, spinner:false, list:action.payload.results
    }
}

case "GET_RECENT_ACTIVITIES_DASHBOARD_FAILLURE":
        return{
            ...state, recentActivitiesDashboard:{
                ...state.recentActivitiesDashboard, error:action.payload, spinner:true, list:null
            }
    }

case "RECENT_ACTIVITIES_DASHBOARD_MESSAGES":
        return{
            ...state, recentActivitiesDashboard:{
                ...state.recentActivitiesDashboard, error:null, spinner:false, message:null
            }
        }




/*
* Get Current Plan
*/
case "HANDLE_GET_CURRENT_PLAN_DASHBOARD":
    return{
        ...state, currentPlanDashboard:{
            ...state.currentPlanDashboard, error:null, spinner:true
        }
}
case "GET_CURRENT_PLAN_DASHBOARD":
return {
...state, currentPlanDashboard:{
    ...state.currentPlanDashboard, error:null, spinner:false, list:action.payload
    }
}

case "GET_CURRENT_PLAN_DASHBOARD_FAILLURE":
        return{
            ...state, currentPlanDashboard:{
                ...state.currentPlanDashboard, error:action.payload, spinner:true, list:null
            }
    }

case "CURRENT_PLAN_DASHBOARD_MESSAGES":
        return{
            ...state, currentPlanDashboard:{
                ...state.currentPlanDashboard, error:null, spinner:false, message:null
            }
        }





/*
* Get Connexions Profil
*/
case "HANDLE_GET_CONNEXIONS_DASHBOARD":
    return{
        ...state, connexionsDashboard:{
            ...state.connexionsDashboard, error:null, spinner:true
        }
}
case "GET_CONNEXIONS_DASHBOARD":
return {
...state, connexionsDashboard:{
    ...state.connexionsDashboard, error:null, spinner:false, list:action.payload.results
    }
}

case "GET_CONNEXIONS_DASHBOARD_FAILLURE":
        return{
            ...state, connexionsDashboard:{
                ...state.connexionsDashboard, error:action.payload, spinner:true, list:null
            }
    }

case "CONNEXIONS_DASHBOARD_MESSAGES":
        return{
            ...state, connexionsDashboard:{
                ...state.connexionsDashboard, error:null, spinner:false, message:null
            }
        }




/*
* Get opportunities apply Profil
*/
case "HANDLE_GET_OPPORTUNITIES_APPLY_DASHBOARD":
    return{
        ...state, opportunitiesApplyDashboard:{
            ...state.opportunitiesApplyDashboard, error:null, spinner:true
        }
}
case "GET_OPPORTUNITIES_APPLY_DASHBOARD":
return {
...state, opportunitiesApplyDashboard:{
    ...state.opportunitiesApplyDashboard, error:null, spinner:false, list:action.payload.results
    }
}

case "GET_OPPORTUNITIES_APPLY_DASHBOARD_FAILLURE":
        return{
            ...state, opportunitiesApplyDashboard:{
                ...state.opportunitiesApplyDashboard, error:action.payload, spinner:true, list:null
            }
    }

case "OPPORTUNITIES_APPLY_DASHBOARD_MESSAGES":
        return{
            ...state, opportunitiesApplyDashboard:{
                ...state.opportunitiesApplyDashboard, error:null, spinner:false, message:null
            }
        }




/*
* Get Spontaneous apply Profil
*/
case "HANDLE_GET_SPONTANEOUS_APPLY_DASHBOARD":
    return{
        ...state, spontaneousApplyDashboard:{
            ...state.spontaneousApplyDashboard, error:null, spinner:true
        }
}
case "GET_SPONTANEOUS_APPLY_DASHBOARD":
return {
...state, spontaneousApplyDashboard:{
    ...state.spontaneousApplyDashboard, error:null, spinner:false, list:action.payload.results
    }
}

case "GET_SPONTANEOUS_APPLY_DASHBOARD_FAILLURE":
        return{
            ...state, spontaneousApplyDashboard:{
                ...state.spontaneousApplyDashboard, error:action.payload, spinner:true, list:null
            }
    }

case "SPONTANEOUS_APPLY_DASHBOARD_MESSAGES":
        return{
            ...state, spontaneousApplyDashboard:{
                ...state.spontaneousApplyDashboard, error:null, spinner:false, message:null
            }
        }




/*
* Get Spontaneous apply Dashboard
*/
case "HANDLE_GET_SPONTANEOUS_APPLY_DASHBOARD_PIPELINE":
    return{
        ...state, spontaneousApplyDashboardPipeline:{
            ...state.spontaneousApplyDashboardPipeline, error:null, spinner:true, count:0
        }
}
case "GET_SPONTANEOUS_APPLY_DASHBOARD_PIPELINE":
return {
...state, spontaneousApplyDashboardPipeline:{
    ...state.spontaneousApplyDashboardPipeline, error:null, spinner:false, list:action.payload.results,  count:action.payload.count
    }
}

case "GET_SPONTANEOUS_APPLY_DASHBOARD_PIPELINE_FAILLURE":
        return{
            ...state, spontaneousApplyDashboardPipeline:{
                ...state.spontaneousApplyDashboardPipeline, error:action.payload, spinner:true, list:null,count:0
            }
    }

case "SPONTANEOUS_APPLY_DASHBOARD_PIPELINE_MESSAGES":
        return{
            ...state, spontaneousApplyDashboardPipeline:{
                ...state.spontaneousApplyDashboardPipeline, error:null, spinner:false, message:null,count:0
            }
        }




/*
* Get Spontaneous apply Profil
*/
case "HANDLE_GET_SEARCH_APPEARENCE_DASHBOARD":
    return{
        ...state, searchesAppearenceDashboard:{
            ...state.searchesAppearenceDashboard, error:null, spinner:true
        }
}
case "GET_SEARCH_APPEARENCE_DASHBOARD":
return {
...state, searchesAppearenceDashboard:{
    ...state.searchesAppearenceDashboard, error:null, spinner:false, list:action.payload.results
    }
}

case "GET_SEARCH_APPEARENCE_DASHBOARD_FAILLURE":
        return{
            ...state, searchesAppearenceDashboard:{
                ...state.searchesAppearenceDashboard, error:action.payload, spinner:true, list:null
            }
    }

case "SEARCH_APPEARENCE_DASHBOARD_MESSAGES":
        return{
            ...state, searchesAppearenceDashboard:{
                ...state.searchesAppearenceDashboard, error:null, spinner:false, message:null
            }
        }





/*
* Get Spontaneous apply Profil
*/
case "HANDLE_GET_VIEW_PROFIL_DASHBOARD":
    return{
        ...state, viewProfilDashboard:{
            ...state.viewProfilDashboard, error:null, spinner:true
        }
}
case "GET_VIEW_PROFIL_DASHBOARD":
return {
...state, viewProfilDashboard:{
    ...state.viewProfilDashboard, error:null, spinner:false, list:action.payload.results
    }
}

case "GET_VIEW_PROFIL_DASHBOARD_FAILLURE":
        return{
            ...state, viewProfilDashboard:{
                ...state.viewProfilDashboard, error:action.payload, spinner:true, list:null
            }
    }

case "VIEW_PROFIL_DASHBOARD_MESSAGES":
        return{
            ...state, viewProfilDashboard:{
                ...state.viewProfilDashboard, error:null, spinner:false, message:null
            }
        }






/*
* Get  applicants selected dashboard
*/
case "HANDLE_GET_APPLICATIONS_SELECTED_APP_DASHBOARD":
    return{
        ...state, applicationsSelectedAppDashboard:{
            ...state.applicationsSelectedAppDashboard, error:null, spinner:true, count:0
        }
}
case "GET_APPLICATIONS_SELECTED_APP_DASHBOARD":
return {
...state, applicationsSelectedAppDashboard:{
    ...state.applicationsSelectedAppDashboard, error:null, spinner:false, list:action.payload.results, count:action.payload.count
    }
}

case "GET_APPLICATIONS_SELECTED_APP_DASHBOARD_FAILLURE":
        return{
            ...state, applicationsSelectedAppDashboard:{
                ...state.applicationsSelectedAppDashboard, error:action.payload, spinner:true, list:null, count:0
            }
    }

case "APPLICATIONS_SELECTED_APP_DASHBOARD_MESSAGES":
        return{
            ...state, applicationsSelectedAppDashboard:{
                ...state.applicationsSelectedAppDashboard, error:null, spinner:false, message:null,count:0
            }
        }





/*
* Get applicants SHARED dashboard
*/
case "HANDLE_GET_APPLICATIONS_SELECTED_SHARED_DASHBOARD":
    return{
        ...state, applicationsSelectedSharedDashboard:{
            ...state.applicationsSelectedSharedDashboard, error:null, spinner:true, count:0
        }
}
case "GET_APPLICATIONS_SELECTED_SHARED_DASHBOARD":
return {
...state, applicationsSelectedSharedDashboard:{
    ...state.applicationsSelectedSharedDashboard, error:null, spinner:false, list:action.payload.results, count:action.payload.count
    }
}

case "GET_APPLICATIONS_SELECTED_SHARED_DASHBOARD_FAILLURE":
        return{
            ...state, applicationsSelectedSharedDashboard:{
                ...state.applicationsSelectedSharedDashboard, error:action.payload, spinner:true, list:null, count:0
            }
    }

case "APPLICATIONS_SELECTED_SHARED_DASHBOARD_MESSAGES":
        return{
            ...state, applicationsSelectedSharedDashboard:{
                ...state.applicationsSelectedSharedDashboard, error:null, spinner:false, message:null,count:0
            }
        }








/*
* Get applicants SHARED dashboard
*/
case "HANDLE_GET_SPONTANEOUS_APPLY_DASHBOARD_SELECTED":
    return{
        ...state, applicationsSpontaneousApplySelectedDashboard:{
            ...state.applicationsSpontaneousApplySelectedDashboard, error:null, spinner:true, count:0
        }
}
case "GET_SPONTANEOUS_APPLY_DASHBOARD_SELECTED":
return {
...state, applicationsSpontaneousApplySelectedDashboard:{
    ...state.applicationsSpontaneousApplySelectedDashboard, error:null, spinner:false, list:action.payload.results, count:action.payload.count
    }
}

case "GET_SPONTANEOUS_APPLY_DASHBOARD_SELECTED_FAILLURE":
        return{
            ...state, applicationsSpontaneousApplySelectedDashboard:{
                ...state.applicationsSpontaneousApplySelectedDashboard, error:action.payload, spinner:true, list:null, count:0
            }
    }

case "GET_SPONTANEOUS_APPLY_DASHBOARD_SELECTED_MESSAGES":
        return{
            ...state, applicationsSpontaneousApplySelectedDashboard:{
                ...state.applicationsSpontaneousApplySelectedDashboard, error:null, spinner:false, message:null,count:0
            }
        }





/*
* Get applicants selected dashboard
*/
case "HANDLE_GET_APPLICATIONS_SELECTED_REC_DASHBOARD":
    return{
        ...state, applicationsSelectedRecDashboard:{
            ...state.applicationsSelectedRecDashboard, error:null, spinner:true, count:0
        }
}
case "GET_APPLICATIONS_SELECTED_REC_DASHBOARD":
return {
...state, applicationsSelectedRecDashboard:{
    ...state.applicationsSelectedRecDashboard, error:null, spinner:false, list:action.payload.results, count:action.payload.count
    }
}

case "GET_APPLICATIONS_SELECTED_REC_DASHBOARD_FAILLURE":
        return{
            ...state, applicationsSelectedRecDashboard:{
                ...state.applicationsSelectedRecDashboard, error:action.payload, spinner:true, list:null, count:0
            }
    }

case "APPLICATIONS_SELECTED_REC_DASHBOARD_MESSAGES":
        return{
            ...state, applicationsSelectedRecDashboard:{
                ...state.applicationsSelectedRecDashboard, error:null, spinner:false, message:null,count:0
            }
        }




default:
return state;
        
  }
  }
  