import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Field, reduxForm } from 'redux-form'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { LocationSearchInput } from 'components/formsElements/FieldsComponents';
import Chip from '@material-ui/core/Chip';
import {useDispatch, useSelector} from 'react-redux';
import { handleAddOpportunity, addOpportunityResetMessages, handleAddOpportunityDraft, handleAddOpportunityDraftHome, handleAddOpportunityHome, addOpportunityDraftResetMessages } from 'actions/opportunities';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Alert} from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { handleSearchCategories, handleSearchCompanyAdmin, handleSearchIndustries } from 'actions/search';
import IntlMessages from "util/IntlMessages";
import { RichTextMarkdown } from 'components/formsElements/FormComponents';
import { withTranslation } from 'react-i18next';

// import TextareaAutosize from '@material-ui/core/TextareaAutosize';

// import { Editor } from '@tinymce/tinymce-react';
// import { HtmlEditor } from '@aeaton/react-prosemirror'
// import { options } from '@aeaton/react-prosemirror-config-default'

/**
 * In this component we used redux-form to manage correctly locations.
 */
const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    title: {},
    paper:{
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        marginLeft: 15,
        marginRight: 15,
        [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(2),
        },
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    content:{
        padding:15,
        paddingBottom:30,
        overflowY:"hidden"
    },
    paper:{
        backgroundColor:'#e0e0e0'
    },
    upload:{
        width:80,
        height:80
    }
  }));
const initState={
  title:'',
  // description:'',
  // organization_id:'',
  // industry_id:[],
  // first_name:'',
  // last_name:'',
  // email:'',
  // phone:''
}

const AddOpportunityNotAuth = props => {
  const { handleSubmit, t, load, pristine, reset, submitting } = props
  const classes = useStyles();
  const [state, setState] = useState(initState);
  const [locations, setLocations]= useState([]);
  const searchData = useSelector(state=>state.search.industries.list)
  const [tags, setTags] = useState([])
  const companiesData = useSelector(state=>state.search.companiesAdmin.list);
  const data = useSelector(state=>state.opportunities.add_opp_ano)
  const spinnerDraft = useSelector(state=>state.opportunities.add_opp_ano_draft.spinner)
  const errorDraft = useSelector(state=>state.opportunities.add_opp_ano_draft.error)
  const messageDraft = useSelector(state=>state.opportunities.add_opp_ano_draft.message)
  const isAuthenticated = useSelector((state)=> state.users.authenticated)
  const [description, setDescription] = React.useState();
  const [publication_status, setPublication_status] = React.useState();
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(options[0]);
  const [inputValueCompany, setInputValueCompany] = React.useState('');
  const categoriesData = useSelector(state=>state.search.categories.list);
  const [tagsCategories, setTagsCategories] = useState([]);
  console.log(data,"data")
  console.log("Opp isAuthenticated ",isAuthenticated)
  const handleChangeDescription = (event) => {
    setDescription(event.toString('html'));
  };

  const  dispatch =useDispatch()
  const loader=<CircularProgress  color="primary" />;

  const handleSelectCategories=(test,elt, reason)=>{
    setTagsCategories(elt);
  }
  const handleChange =(event)=>{
    setState({...state, [event.target.name]:event.target.value});
  }
  const handleSearchChangeCategories=(event)=>{
    dispatch(handleSearchCategories({q:event.target.value}))
  }
  const handleAddLocation=(item)=>{
      setLocations([...locations.filter(obj=>obj.place_id!==item.place_id), item])
  }
  const handleChangePS = (event) => {
    setPublication_status(event.target.value);
  };
  const handleSearchChangeCompany=(event)=>{
    dispatch(handleSearchCompanyAdmin({q:event.target.value}))
  }
  const [tagsCompany, setTagsCompany] = useState([]);
  
  const handleSelectCompany=(test,elt, reason)=>{
    setTagsCompany(elt);
  }
 const handleFormReset = () => {
    setState(() => initState);
    reset()
    props.handleClose()
 }
useEffect(()=>{
  dispatch(handleSearchCompanyAdmin({q:""}))
  dispatch(handleSearchCategories({q:""}))
},[dispatch]);

 

  const handleSumbit=(event)=>{
      event.preventDefault();
      console.log(state, locations.map(item=>item.data))
      dispatch(handleAddOpportunityHome({...state, 
        organization_id: tagsCompany.length === 0 ? inputValueCompany :tagsCompany.organization_id,
        locations:locations.map(item=>item.data),
        industry_id:tags.map(item=>item.industry_id),
        description:description,
        publication_status:1,
        category_id: tagsCategories.map(item=>item.category_id),
        first_name: state.first_name,
        last_name: state.last_name,
        email: state.email,
        phone: state.phone
      }))
      }

      const handleSaveSumbit=(event)=>{
        event.preventDefault();
        console.log(state, locations.map(item=>item.data))
        dispatch(handleAddOpportunityDraftHome({...state, 
          organization_id: tagsCompany.length === 0 ? inputValueCompany :tagsCompany.organization_id,
          locations:locations.map(item=>item.data),
          industry_id:tags.map(item=>item.industry_id),
          description:description?description:'',
          publication_status:0,
          category_id: tagsCategories.map(item=>item.category_id),
          first_name: state.first_name,
          last_name: state.last_name,
          email: state.email,
          phone: state.phone
        }))
        }

  const handleSearchChange=(event)=>{
    dispatch(handleSearchIndustries({q:event.target.value}))
  }
  const handleSelect=(test,elt, reason)=>{
    setTags(elt);
  }
   
  return (
    <React.Fragment>
    <Dialog fullScreen={false} maxWidth="sm" fullWidth={true} open={props.open} onClose={props.handleClose}>
        <AppBar className={classes.appBar}>
        <Toolbar>
            <Grid container>
                <Grid md={10} xs={10} sm={10} item container justify="left"><Typography variant="h6" className={classes.title}>{<IntlMessages id="post_a_new_opportunity_title"/>}</Typography></Grid>
                <Grid md={2} xs={2} sm={2} item container justify="flex-end">
                    <IconButton edge="start" color="inherit" aria-label="close" onClick={props.handleClose}>
                        <CloseIcon />
                    </IconButton>   
                </Grid>
            </Grid>
            
          </Toolbar>
          <Grid xs={12}>
            {data.message?<Alert severity="success" onClose={()=>dispatch(addOpportunityResetMessages())}>{data.message}</Alert>:null}
            {data.error?<Alert severity="error" onClose={()=>dispatch(addOpportunityResetMessages())}>{data.error}</Alert>:null}
        
            {messageDraft?<Alert severity="success" onClose={()=>dispatch(addOpportunityDraftResetMessages())}>{messageDraft}</Alert>:null}
            {errorDraft?<Alert severity="error" onClose={()=>dispatch(addOpportunityDraftResetMessages())}>{errorDraft}</Alert>:null}
      
        </Grid>
        </AppBar>
         {/*  */}
         <DialogContent >
        <form onSubmit={handleSubmit}>
        <Grid container spacing={3} className={classes.content}>
        
        <div className="row">
        <div className="col-md-12">
          <TextField
            required
            id="title"
            name="title"
            label={<IntlMessages id="title"/>}
            fullWidth
            autoComplete="given-name"
            onChange={handleChange}
            value={state.title}
          /></div>
        <div className="col-md-12">
          {/* <TextField
            required
            id="nameOforganization"
            name="organization_id"
            label={<IntlMessages id="name_of_organizations"/>}
            fullWidth
            autoComplete="family-name"
            onChange={handleChange}
            value={state.organization_id}
          /> */}

              <Autocomplete
                    fullWidth
                    required
                    id="organization_id"
                    size="small"
                    freeSolo
                    onChange={handleSelectCompany}
                    options={companiesData}
                    getOptionLabel={(option) => option.name}
                    inputValue={inputValueCompany}
                    onInputChange={(event, newInputValue) => {
                    setInputValueCompany(newInputValue);}}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} onChange={handleSearchChangeCompany} variant="outlined" 
                      label={<IntlMessages id="name_of_organizations"/>}
                      margin="normal"
                      placeholder="Ex: Wutiko" />
                    )}
                  /> 
        </div>
        <div className="col-md-12 col-sm-12">
        {/* <Select 
          fullWidth
          value={state.opportunity_type}
          onChange={handleChange}
          label="Opportunity Type (jobs, fundings, ...)"
          inputProps={{
            name: 'opportunity_type',
            id: 'demo-controlled-open-select',
          }}>
          <MenuItem value={0}>{<IntlMessages id="job"/>}</MenuItem>
          <MenuItem value={1}>{<IntlMessages id="Funding"/>}</MenuItem>
          <MenuItem value={2}>{<IntlMessages id="Training"/>}</MenuItem>
          <MenuItem value={3}>{<IntlMessages id="internship"/>}</MenuItem>
          <MenuItem value={4}>{<IntlMessages id="scholarship"/>}</MenuItem>
          <MenuItem value={5}>Fellowship </MenuItem>
          <MenuItem value={6}>{<IntlMessages id="grant"/>}</MenuItem>
          <MenuItem value={7}>Competition</MenuItem>
          <MenuItem value={8}>Conference</MenuItem>
        </Select>*/}
        <Autocomplete
                    multiple
                    fullWidth
                    required
                    id="category_id"
                    size="small"
                    freeSolo
                    onChange={handleSelectCategories}
                    options={categoriesData}
                    getOptionLabel={(option) => option.name}
                    // value={value}
                    // inputValue={inputValueAreas}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValueAreas(newInputValue);}}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} onChange={handleSearchChangeCategories} variant="outlined" 
                      label={<IntlMessages id="opportunity_type"/>}
                      margin="normal"
                      placeholder="Ex: Sport" />
                    )}
                  />
        </div>
        {/* {!isAuthenticated ? 
        <div className="col-md-6 col-12  top-15">
                        <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">{t("wutiko.publication_status")}</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="publication_status"
                        value={publication_status}
                        defaultValue={0}
                        deactived
                        >
                            <MenuItem value="0">Not active</MenuItem>
                        </Select>
                        </FormControl>
                        </div>
            :
                        <div className="col-md-6 col-12  top-15">
                        <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">{t("wutiko.publication_status")}</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="publication_status"
                        value={publication_status}
                        onChange={handleChangePS}
                        >
                            <MenuItem value="0">{t("wutiko.not_active")}</MenuItem>
                            <MenuItem value="1">{t("wutiko.published_and_public")}</MenuItem> 
                            <MenuItem value="2">{t("wutiko.published_and_restricted")}</MenuItem>
                            <MenuItem value="3">{t("wutiko.deactivated")}</MenuItem> 
                            <MenuItem value="4">{t("wutiko.removed")}</MenuItem>
                        </Select>
                        </FormControl>
                        </div>
} */}
        <div className="col-md-12 col-sm-12">
        <Field
          fullWidth
          value={state.location_id}
          component={LocationSearchInput}
          addLocation={handleAddLocation}
          placeholder="Location(s)"
          />
        </div> 

        <div  className="col-md-12 col-sm-12" xs={6} sm={6}>
        {
          locations.map(item=>{
              return(
                <Chip label={`${item.data.city_name} ${item.data.state_province_name} ${item.data.country_name}`} onDelete={()=>setLocations(locations.filter(obj=>obj.place_id!==item.place_id))}/>
              )
            })
        }
        </div> 

        <div className="col-md-12 col-12">
         {/* <TextField
            required
            id="description"
            name="description"
            label={<IntlMessages id="decription"/>}
            fullWidth
            autoComplete="given-name"
            onChange={handleChange}
            multiline={true}
            placeholder="Description"
            value={state.description}
          /> */}
          <Field
                      id="description"
                      name="description"
                      label={<IntlMessages id="add_description"/>}
                      component={RichTextMarkdown}
                      componentclassName="textarea"
                      onChange={handleChangeDescription}
                      value={state.description}
                      />  
                             
        </div>
       
        {!isAuthenticated ? 
        <div  className="bockUser">
        {/* <Grid container justify="flex-start" xs={12}><Typography variant="h6" className={classes.title}>Your details</Typography></Grid> */}
        <div  className="col-md-6 col-12">
          <TextField
            required
            id="first_name"
            name="first_name"
            label={<IntlMessages id="first_name"/>}
            fullWidth
            autoComplete="given-first-name"
            value={state.first_name}
            onChange={handleChange}
          />
        </div>
        <div  className="col-md-6 col-12">
          <TextField
            required
            id="last_name"
            name="last_name"
            label={<IntlMessages id="last_name"/>}
            fullWidth
            autoComplete="family-name"
            onChange={handleChange}
            value={state.last_name}
          />
        </div>
        <div  className="col-md-6 col-12">
          <TextField
            required
            id="email"
            name="email"
            label={<IntlMessages id="email"/>}
            fullWidth
            autoComplete="given-name"
            onChange={handleChange}
            value={state.email}
          />
        </div>
        <div  className="col-md-6 col-12">
          <TextField
            required
            id="phone"
            name="phone"
            label={<IntlMessages id="phone"/>}
            fullWidth
            autoComplete="shipping address-line1"
            onChange={handleChange}
            placeholder={`Ex:+221xxxxxxxxx`}
          />
        </div>
        <div className="col-md-12 top-20 col-12 terms_and_conditions">
            <center>
            {<IntlMessages id="upload_my_cv_footer"
            values={{ terms_and_conditions : <a target="blank" href="https://pages.wutiko.com/en/legal/terms-and-conditions/"> <IntlMessages id="terms_and_conditions"/> </a>}}/>} 
            </center>   
        </div>
        </div>
        : null
        } 
        </div>
        </Grid>
        <Grid item xs={12} className="bottom-20 row opportunities-btn">
         <div className="col-md-6"><Button  onClick={handleFormReset} className="cancel neutre" variant="contained" type="submit" color="primary">{<IntlMessages id="cancel"/>} </Button></div>
         {isAuthenticated ? 
         spinnerDraft?<div className="text-center">{loader}</div>:<div className="col-md-2 text-right"><Button  onClick={handleSaveSumbit} className="saveDraft" variant="contained" type="submit" color="primary">{<IntlMessages id="draft"/>} </Button></div>
        :
        spinnerDraft?<div className="text-center">{loader}</div>:<div className="col-md-6 text-right"><Button  onClick={handleSaveSumbit} className="addopp" variant="contained" type="submit" color="primary">{<IntlMessages id="published_now"/>} </Button></div>}
         {isAuthenticated ? 
         data.spinner?<div className="text-center">{loader}</div>:<div className="col-md-4 text-right"><Button disabled={pristine || submitting}  onClick={handleSumbit} className="addopp" variant="contained" type="submit" color="primary">{<IntlMessages id="published_now"/>} </Button></div>
        :null}
         </Grid> 
        </form>
        </DialogContent>
      </Dialog>   
    </React.Fragment>
  );
}

export default  reduxForm({
  form: 'AddOpportunityNotAuth'
})(withTranslation('translation')(AddOpportunityNotAuth))

