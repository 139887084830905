import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { AddSectorsSuccess,AddSectorsError} from "actions/profile";
//import AddSectors from "containers/Profil/AddSectors";

const AddSectorsData = async (instance,data)=>
    await instance.post(`/profiles/industries/add/`, data)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* AddSectors(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(AddSectorsData,axiosInstance, actions.data);
    if(data.message){

        yield put(AddSectorsSuccess(data.message));
        yield put({type:"HANDLE_GET_PROFILE_SECTORS"})
    }else{
        console.log("data.error", data, data.error)
        yield put(AddSectorsError(data.error));
    }
}
export function* handleAddSectors(){
    yield takeEvery("HANDLE_ADD_SECTORS", AddSectors)
    console.log("SECTORS", "OK")
}
export default function* rootSaga() {
    yield all([fork(handleAddSectors)]);
}

