import React,{useEffect} from 'react';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import { cleanStringQuery } from 'util/UtilFunctions';
import {useDispatch,useSelector} from 'react-redux'
import { handleSearchSkills } from 'actions/filters/skills';
import IntlMessages from "util/IntlMessages";
  
function Skills(props) {
  const dispatch = useDispatch();
  const skills = useSelector(state=>state.skills.search_skills.skills);

  const handleSearchSkill=(data)=>{
    dispatch(handleSearchSkills({scope:props.scope, search:`?FacetSkill=${data.currentTarget.value}`}))
  }

  useEffect(()=>{
      dispatch(handleSearchSkills({scope: props.scope, search:`${cleanStringQuery(window.location.search)?`?${cleanStringQuery(window.location.search)}`:''}`}))
  },[dispatch])

  const onChecked=(value)=>{
    if(props.keys.filter(obj=>obj.name==value).length!=0){
        props.delete({name:value, type:'FacetSkill'})
    }else{
        props.addItem({name:value, type:'FacetSkill'})
    }
   }
   console.log("props.keys", props.keys)
    return (
        <FormControl className="filter">
          <InputLabel htmlFor="select-multiple-chip">{<IntlMessages id="skills"/>}</InputLabel>
          <Select
            label={<IntlMessages id="skills"/>}
            multiple
            value={[]}
            >
            <div className="search">
            <Input  className="filter" name="search" type="search" placeholder="Search" autocomplete="off" onChange={(data)=>handleSearchSkill(data)}/>
            </div>
            {skills.map((name,key) => (
              <MenuItem key={key} value={name.skill_id__name}>
                <Checkbox checked={props.keys.filter(obj=>obj.name===name.skill_id__name).length!==0} onClick={()=>onChecked(name.skill_id__name)}/>
                <ListItemText primary={`${name.skill_id__name}(${name.total})`} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
     
    );
}
export default Skills;