const initialState = {
    get_form:{
        error:null,
        spinner:false,
        details:{},
        message:null
    },
    submit_form:{
        message:null,
        error:null,
        spinner:false
    },
    submit_contact_form:{
        message:null,
        error:null,
        spinner:null
    }
 }

 export function formsGenerator(state = initialState, action){
 
   switch(action.type) {
   /**
    * Get form from backend
    */
   case "HANDLE_GET_FORM":
           return {...state, get_form:{
               ...state.get_form, error:null, spinner:true, details:{}
           }}
   case "GET_FORM_FAILLURE":
            return {...state, get_form:{
                ...state.get_form, error:action.payload, spinner:false 
            }}
   case "GET_FORM":
        return {...state, get_form:{
            ...state.get_form, error:null, spinner:false
            ,details:action.payload 
        }}
   case "GET_FORM_RESET_MESSAGES":
        return {...state, get_form:{
            ...state.get_form, error:null, spinner:false
        }}
    /**
     * Submit a form to the backend
     */
    case "HANDLE_SUBMIT_FORM":
            return {...state, submit_form:{
                ...state.submit_form, error:null, spinner:true, message:null 
            }}
    case "SUBMIT_FORM_FAILLURE":
             return {...state, submit_form:{
                 ...state.submit_form, error:action.payload, spinner:false,message:null
             }}
    case "SUBMIT_FORM":
         return {...state, submit_form:{
             ...state.submit_form, error:null, spinner:false, message:action.payload
         }}
    case "SUBMIT_FORM_RESET_MESSAGES":
         return {...state, submit_form:{
             ...state.submit_form, error:null, spinner:false, message:null
         }}
    /**
     * Handle contact form submission
     */
    case "HANDLE_SUBMIT_CONTACT_FORM":
        return {...state, submit_contact_form:{
            ...state.submit_contact_form, error:null, spinner:true, message:null 
        }}
    case "SUBMIT_CONTACT_FORM_FAILLURE":
            return {...state, submit_contact_form:{
                ...state.submit_contact_form, error:action.payload, spinner:false,message:null
            }}
    case "SUBMIT_CONTACT_FORM":
        return {...state, submit_contact_form:{
            ...state.submit_contact_form, error:null, spinner:false, message:action.payload
        }}
    case "SUBMIT_CONTACT_FORM_RESET_MESSAGES":
        return {...state, submit_contact_form:{
            ...state.submit_contact_form, error:null, spinner:false, message:null
        }}
     default:
       return state;
   }
 }