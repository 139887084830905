
import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { setFormDataError,successSetFormData} from "actions/profile";

const setFormData = async (instance,actions)=>
    await instance.post(`/documents/profile/add/`,actions)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            return error.response.data
        }
    })

function* setForm(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data     =yield call(setFormData,axiosInstance,actions.data);
    if(data.message){
            yield put(successSetFormData(data.message));
            yield put({type:"HANDLE_GET_PROFILE_FILE"});
    }else{
        yield put(setFormDataError(data.error));
    }
    
}
export function* setUplaodDoc(){
    yield takeEvery("HANDLE_UPLOAD_DOC", setForm)
}
export default function* rootSaga() {
    yield all([fork(setUplaodDoc)]);
}