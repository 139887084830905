export function handlePositionsSearch(data){
    return {
        type:"HANDLE_GET_POSITIONS_SEARCH",
        payload:data
    }
}
export function handleSetSearchPositions(data){
    return {
        type:"GET_POSITIONS_SEARCH",
        payload:data
    }
}

export function handleSetSearchPositionsError(data){
    return{
        type:"GET_POSITIONS_SEARCH_FAILLURE",
        payload:data
    }
}
