import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { handleSetSearchIndustries, handleSetSearchIndustriesError } from "actions/filters/industries";

// 
const setSearchIndustries = async (instance,actions)=>
    await instance.get(`/searches/filter/${actions.scope}/industries/${actions.search}`,actions)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            return error.response.data
        }
    })

function* SearchIndustries(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(setSearchIndustries,axiosInstance,actions.payload);
    if(data.results){
            yield put(handleSetSearchIndustries(data));
    }else{
           yield put(handleSetSearchIndustriesError(data.error));
    }
    
}

export function* handleSearchIndustries(){
    yield takeEvery("HANDLE_GET_INDUSTRIES_SEARCH", SearchIndustries)
}

export default function* rootSaga() {
    yield all([fork(handleSearchIndustries)]);
}