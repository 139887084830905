import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { handleFilterOpportunitySuccess, handleFilterOpportunityError } from "actions/filters/opportunities";

// 
const setFilterOpportunity = async (instance,actions)=>
    await instance.get(`/searches/search/admins/opportunities/${actions.search}`,actions)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            return error.response.data
        }
    })

function* FilterOpportunity(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(setFilterOpportunity,axiosInstance,actions.payload);
    if(data.results){
            yield put(handleFilterOpportunitySuccess(data));
    }else{
           yield put(handleFilterOpportunityError(data.error));
    }
    
}

export function* handleFilterOpportunity(){
    yield takeEvery("HANDLE_GET_OPPORTUNITY_FILTER", FilterOpportunity)
}

export default function* rootSaga() {
    yield all([fork(handleFilterOpportunity)]);
}