import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { CLIENT_ID } from "config";
import { ApplyOpportunitySuccess, ApplyOpportunityError} from "actions/opportunities";

const ApplyOpportunity = async (instance, opportunity_id)=>
    await instance.post(`/opportunities/${opportunity_id}/applications/add/`, {})
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })
function* ApplyOpportunityAction(actions){
        const state = yield select((state)=>state.users);
        let axiosInstance;
        if(state.authenticated){
            axiosInstance=state.axiosInstance
        }else{
            axiosInstance=state.blankAxiosInstance
        }
        const data =yield call(ApplyOpportunity,axiosInstance, actions.data.opportunityId);
        if(data.message){
            yield put({type:"HANDLE_GET_OPPORTUNITY_DETAILS", slug:actions.data.slug})
            yield put(ApplyOpportunitySuccess(data.message));
        }else{
            console.log("data.error", data, data.error)
            yield put(ApplyOpportunityError(data.error));
        }
        
    }
function* HandleApplyOpportunity(){
    yield takeEvery("HANDLE_APPLY_OPPORTUNITY", ApplyOpportunityAction)
}
export default function* rootSaga() {
    yield all([fork(HandleApplyOpportunity)]);
}