import {InitialFieldsTypes} from './InitialFieldsTypes';
import { Parser } from 'html-to-react';

 /**
  * This function take two parameters  a field id and a list of id of fields to desable. The function 
  * return true if the liste contains the id.
  */
 export const DisabledFields=(field, desabledFiels)=>{

    return desabledFiels.indexOf(field.id)>=0
 }
 /**
 * This component take a field structure provided by the backend and construct a object that can be passed 
 * to `react-jsonschema-form` Form component.
 */
 export const Json2Form=(fieldConfig,desabledFiels, REQUIRED,UISCHEMA,PROPERTIES)=>{
    const properties=InitialFieldsTypes()[fieldConfig.field_type];
    var parser= new Parser()
    if (fieldConfig.required){
      REQUIRED.push(fieldConfig.id);
    }

  if(properties.type=='email'){
    PROPERTIES[fieldConfig.id]={
      "type": "string",
      "format": "email",
      "title":fieldConfig.label?fieldConfig.label:fieldConfig.id,

    };
     UISCHEMA[fieldConfig.id]={
      "ui:placeholder":fieldConfig.placeholder_text,
      "classNames":fieldConfig.style_class,
      "ui:disabled":DisabledFields(fieldConfig, desabledFiels),
      "ui:description":parser.parse(fieldConfig.description)
    }
  }
  else if(properties.type=='date'){
    PROPERTIES[fieldConfig.id]={
      type:'string',
      format: "date",
      title:fieldConfig.label===""?fieldConfig.id:fieldConfig.label
    };
    UISCHEMA[fieldConfig.id]={
      "classNames":fieldConfig.style_class,
      "ui:disabled":DisabledFields(fieldConfig, desabledFiels),
      "ui:description":parser.parse(fieldConfig.description),
      "ui:help":parser.parse(fieldConfig.help_text),
      a_date: {
        "alt-date": {
          "ui:widget": "alt-date",
          "ui:options":{
            yearsRange: [1980, 2030],
            hideNowButton: true,
            hideClearButton: true,
          },
        },
      },
    }
  }
  else if(properties.type=='file'){
    PROPERTIES[fieldConfig.id]={
      "type": "string",
      "format": "data-url",
      "title":fieldConfig.label?fieldConfig.label:fieldConfig.id
    }
    UISCHEMA[fieldConfig.id]={
      "classNames":fieldConfig.style_class,
      "ui:description":parser.parse(fieldConfig.description),
      "ui:help":parser.parse(fieldConfig.help_text),
      "ui:disabled":DisabledFields(fieldConfig, desabledFiels),
    }
  }else if(properties.type=='tel'){
    PROPERTIES[fieldConfig.id]={
      type:properties.type,
      title:fieldConfig.label===""?fieldConfig.id:fieldConfig.label
    };
    UISCHEMA[fieldConfig.id]={
      "ui:widget":properties.widget,
      "ui:title":fieldConfig.label===""?fieldConfig.id:fieldConfig.label,
      "ui:placeholder":fieldConfig.placeholder_text,
      "classNames":fieldConfig.style_class,
      "ui:help":parser.parse(fieldConfig.help_text),
      "ui:disabled":DisabledFields(fieldConfig, desabledFiels),
      "ui:description":parser.parse(fieldConfig.description)
    }
  }else if(properties.type=='checkboxes'){
    PROPERTIES[fieldConfig.id]=
    {
      type: "array",
      minItems: 1,
      title: fieldConfig.label?fieldConfig.label:fieldConfig.id,
      items: {
        type: "string",
        enum:  fieldConfig.choices.split('[(%'),
      },
      uniqueItems: true
    };
    UISCHEMA[fieldConfig.id]={
      "ui:widget":properties.widget,
      "classNames":fieldConfig.style_class,
      "ui:description":parser.parse(fieldConfig.description),
      "ui:disabled":DisabledFields(fieldConfig, desabledFiels),
    }
  }else if(properties.type=='boolean'){
    PROPERTIES[fieldConfig.id]={
      title:fieldConfig.label===""?fieldConfig.id:fieldConfig.label,
      type: "string",
      enum:  fieldConfig.choices.split('[(%'),
    };
    UISCHEMA[fieldConfig.id]={
      "ui:widget":"radio",
      "classNames":fieldConfig.style_class,
      "ui:help":parser.parse(fieldConfig.help_text),
      "ui:disabled":DisabledFields(fieldConfig, desabledFiels),
      "ui:description":parser.parse(fieldConfig.description)
    }
  }else if(properties.type=='select'){
    PROPERTIES[fieldConfig.id]={
      type:"string",
      title:fieldConfig.label===""?fieldConfig.id:fieldConfig.label,
      "enum": fieldConfig.choices.split('[(%')
    };
    UISCHEMA[fieldConfig.id]={
      "classNames":fieldConfig.style_class,
      "ui:placeholder":fieldConfig.placeholder_text,
      "ui:disabled":DisabledFields(fieldConfig, desabledFiels),
      "ui:description":parser.parse(fieldConfig.description)
    }
  }else if(properties.type=='array'){

  }else{
    PROPERTIES[fieldConfig.id]={
      type:properties.type,
      title:fieldConfig.label===""?fieldConfig.id:fieldConfig.label
    };
    if(properties.widget==''){
         UISCHEMA[fieldConfig.id]={
          "ui:title":fieldConfig.label===""?fieldConfig.id:fieldConfig.label,
          "classNames":fieldConfig.style_class,
          "ui:placeholder":fieldConfig.placeholder_text,
          "ui:help":parser.parse(fieldConfig.help_text),
          "ui:disabled":DisabledFields(fieldConfig, desabledFiels),
          "ui:description":parser.parse(fieldConfig.description)
        }
      }else if(properties.widget==='textarea'){
        UISCHEMA[fieldConfig.id]={
          "ui:widget":properties.widget,
          "classNames":fieldConfig.style_class,
          "ui:title":fieldConfig.label===""?fieldConfig.id:fieldConfig.label,
          "ui:placeholder":fieldConfig.placeholder_text,
          "ui:help":parser.parse(fieldConfig.help_text),
          "ui:description":parser.parse(fieldConfig.description),
          "ui:disabled":DisabledFields(fieldConfig, desabledFiels),
          "ui:options": {
            rows: 5
          }
        }
      }
      else{
        UISCHEMA[fieldConfig.id]={
          "ui:widget":properties.widget,
          "classNames":fieldConfig.style_class,
          "ui:title":fieldConfig.label===""?fieldConfig.id:fieldConfig.label,
          "ui:placeholder":fieldConfig.placeholder_text,
          "ui:help":parser.parse(fieldConfig.help_text),
          "ui:disabled":DisabledFields(fieldConfig, desabledFiels),
          "ui:description":parser.parse(fieldConfig.description)
        }
      }
  }
  }