import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Select from '@material-ui/core/Select';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {useDispatch, useSelector} from 'react-redux'
import {Alert} from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { handleResetuploadDocState } from 'actions/profile';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Icon from '@material-ui/core/Icon';
import IntlMessages from "util/IntlMessages";
const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    title: {
    },
    content:{
        padding:15,
        paddingBottom:30,
        overflowY:"hidden"
    },
    paper:{
        backgroundColor:'#e0e0e0'
    },
    upload:{
        width:80,
        height:80
    },
    input: {
      display: 'none',
    },
  }));

const initState={
  datafile:null,
  name:null,
  document_type:null,
  current_file:null
}
export default function DocumentForm(props) {
  const classes = useStyles();
  const [state, setState] =useState(initState);
  const dispatch = useDispatch()
  const addfile = props.addfile;
  const uploadDocState= useSelector(state=>state.uploadCv.add_file);

  const error= useSelector(state=>state.profile.add_file.error);
  const message= useSelector(state=>state.profile.add_file.message);
  const spinner= useSelector(state=>state.profile.add_file.spinner);
  const userState= useSelector(state=>state.users.authenticated);
  const loader=<CircularProgress  color="primary"/>;
  const handleSubmit=(event)=>{
    event.preventDefault();
    const formData = new FormData(); 
    formData.append("name", state.name);
    formData.append("document_type", state.document_type);
    formData.append("datafile", state.datafile);
    dispatch({type:"HANDLE_UPLOAD_DOC", data:formData})
    console.log("Test add cv" , addfile)
    console.log("Test add cv 1" , message)

  }
  const handleChange= (event) =>{
      setState({...state, [event.target.name]: event.target.value})
  }


  const handleChangeFile=(event)=>{
    setState({...state, [event.target.name]:event.target.files[0], current_file:event.target.value})
  }
  const {t}=props;

  return (
    <React.Fragment>
         <Grid xs={12}>
        {message?<Alert severity="success" onClose={()=>dispatch(handleResetuploadDocState())}>{message}</Alert>:null}
        {error?<Alert severity="error" onClose={()=>dispatch(handleResetuploadDocState())}>{error}</Alert>:null}
        </Grid>
      
    <Dialog fullScreen={false} maxWidth="sm" fullWidth={true} open={props.open} onClose={props.handleClose}>
        <AppBar className={classes.appBar}>
        <Toolbar>
            <Grid container>
                <Grid md={10} xs={10} item container justify="left">
                {addfile ?
                  <Typography variant="h6" className={classes.title}>{<IntlMessages id="add_files"/>}</Typography>
                  :
                  <Typography variant="h6" className={classes.title}>{<IntlMessages id="upload_my_cv"/>}</Typography>
                    
                }
                    </Grid>
                <Grid  md={2}  xs={2} item container justify="flex-end"> 
                    <IconButton edge="start" color="inherit" aria-label="close" onClick={props.handleClose}>
                        <CloseIcon />
                    </IconButton>   
                </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
         {/*  */}
         <DialogContent >
         <form enctype="multipart/form-data" onSubmit={handleSubmit}>
        <Grid container spacing={3} className={classes.content}>
       
        <Grid item xs={12} sm={12}>
          <TextField
            id="name"
            name="name"
            label="Document name"
            fullWidth
            autoComplete="family-name"
            value={state.last_name}
            onChange={handleChange}
          />
        </Grid>
       <Grid item xs={12} sm={12}>
        <FormControl variant="outlined">
                    <InputLabel htmlFor="filled-age-native-simple">{<IntlMessages id="document_type"/>}</InputLabel>
                    <Select
                    native
                    value={state.document_type}
                    id="document_type"
                    name="document_type"
                    onChange={handleChange}
                    variant="outlined" 
                    margin="normal"
                    >
                    <option value={0}>{t("wutiko.cv")}</option>
                    <option value={1}>{t("wutiko.motivation_letter")}</option>
                    <option value={2}>{t("wutiko.certificate_diploma_degree")}</option>
                    <option value={3}>{t("wutiko.english_proficiency_certificate")} </option>
                    <option value={4}>{t("wutiko.others")}</option>
                    </Select>
                </FormControl>
        </Grid>
        
        <Grid item xs={12} sm={12}>
        <input accept=".pdf, .doc, .docx" name="datafile" className={classes.input} id="icon-button-file" type="file" onChange={handleChangeFile}/>
         <Paper className={classes.paper} htmlFor="icon-button-file">
            <Grid container spacing={3}>
                <Grid item container justify="center">
                <label htmlFor="icon-button-file">
                    <IconButton color="primary" aria-label="upload picture" component="span">
                        <CloudUploadIcon className={classes.upload}/>
                    </IconButton>
                </label>
                </Grid>
                <Grid item container justify="center">
                   <center>{state.current_file}</center>
                </Grid>
            </Grid>
         </Paper>   
        </Grid>
       
        <Grid item xs={12} className="top-20 ">
             {spinner?loader:<Button variant="contained" type="submit" color="primary" className="bntsend">{<IntlMessages id="send"/>}</Button>}
        </Grid>
        </Grid>
        </form>
        </DialogContent>
         {/*  */}
      </Dialog>   
    </React.Fragment>
  );
}
DocumentForm = (withTranslation('translation')(DocumentForm));


// import { Field, reduxForm } from 'redux-form';
// import { browserHistory } from 'react-router';
// import DateFnsUtils from '@date-io/date-fns';
// // import { translate } from 'react-i18next';
// import {useDispatch, useSelector} from 'react-redux';
// import moment from 'moment';
// import {useParams} from 'react-router-dom';
// import Select from '@material-ui/core/Select';
// import { withTranslation } from 'react-i18next';
// import { Col, Form, Row, Panel, Modal, Button } from 'react-bootstrap';
// import { renderTextField,renderSubmitGroup, renderDates,renderSelectField, renderFileInput,renderField,  renderAsyncAutocomplete,renderPlaceAutocomplete, renderDateTime, renderFieldWithButton } from '../components/formsElements/FormComponents';
// import {   handleAddFile } from '../actions/profile';
// import { UncontrolledAlert } from 'reactstrap';
// import logo from '../assets/imgs/spinner.gif';
// import FontAwesome from 'react-fontawesome';
// import { LocationSearchInput } from 'components/formsElements/FieldsComponents';
// import TextField from '@material-ui/core/TextField';
// import IconButton from "@material-ui/core/IconButton";
// import CloseIcon from "@material-ui/icons/Close";
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import React,{useEffect, useState} from 'react';
// import MuiDialogTitle from '@material-ui/core/DialogTitle';
// import { withStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
// import Chip from '@material-ui/core/Chip';
// import Checkbox from '@material-ui/core/Checkbox';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import MuiDialogContent from '@material-ui/core/DialogContent';
// import MenuItem from 'material-ui/MenuItem';
// //const  dispatch =useDispatch()
// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';
// import Icon from '@material-ui/core/Icon';
// import CircularProgress from '@material-ui/core/CircularProgress';

// function sleep(delay = 0) {
//   return new Promise((resolve) => {
//     setTimeout(resolve, delay);
//   });
// }
// const styles = (theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(2),
//   },
//   closeButton: {
//     position: 'absolute',
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500],
//   },
// });

// const DialogTitle = withStyles(styles)((props) => {
//   const { children, classes, onClose, ...other } = props;
//   return (
//     <MuiDialogTitle disableTypography className={classes.root} {...other}>
//       <Typography variant="h6">{children}</Typography>
//       {onClose ? (
//         <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </MuiDialogTitle>
//   );
// });

// const DialogContent = withStyles((theme) => ({
//   root: {
//     padding: theme.spacing(2),
//   },
// }))(MuiDialogContent);

// const loader=<CircularProgress  color="primary"/>;


// const initState={
//     name:'',
//     document_type:'',
//     datafile:'',
//   }

//   function DocumentForm({ props,submitError, serverError, t , params , handleSubmit}) {

//     const [state, setState] = useState(initState);
//     const [openDialog, setOpenDialog] = React.useState(false);
//     const spinner = useSelector(state=>state.profile.add_file.spinner)
//     const error = useSelector(state=>state.profile.add_file.error)
//     const message = useSelector(state=>state.profile.add_file.message)
//     const [open, setOpen] = React.useState(false);
//     const {userId} =useParams();
//     const  dispatch =useDispatch();
//     const handleClickOpen = () => {
//         setOpen(true);
//       };
//       const handleClose = () => {
//         setOpen(false);
//       };
  
//     const handleChange =(event)=>{
//         setState({...state, [event.target.name]:event.target.value});
//       }

// //   const submit=(event)=>{
// //     event.preventDefault();
    


// //    const data={
// //     locations:locations.map(item=>item.data),
// //     organization_id:tagsEntreprise.length !== 0 ? tagsEntreprise.organization_id: "",
// //     position_id:tagsPosition.length !== 0 ? tagsPosition.position_id: "",
// //     description: state.description?state.description:"",
// //     responsibilities:state.responsibilities?state.responsibilities:"",
// //     accomplishments:state.accomplishments?state.accomplishments:"",
// //     end_date : selectedEndDate,
// //     start_date : selectedStartDate,
// //     new_organization:tagsEntreprise.organization_id  ? "" : inputValueOrganization ,
// //     new_position:tagsPosition.position_id  ? "" : inputValuePosition ,
// //    }
// //    console.log("inputValueOrganization" , inputValueOrganization)
// //    console.log("inputValuePosition" , inputValuePosition)
// //    console.log("Data" , data)
// //    dispatch(handleAddExperience({data}))
// //   }


//   const submit=(event)=>{
//     event.preventDefault();
//     const formData = new FormData(); 
//     formData.append("name", state.name);
//     formData.append("document_type", state.document_type);
//     formData.append("datafile", state.datafile);
//     dispatch(handleAddFile({data:formData}))

//   }

//     return (
      
//       <div className="">
//       <Button variant="outlined" color="primary" onClick={handleClickOpen}>
//       <Icon className="fa fa-plus-circle" style={{ color: "#ccc"}} /> 
//     </Button>
//     <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
//       <DialogTitle id="customized-dialog-title" onClose={handleClose}>
//         Add Document
//       </DialogTitle>
//           <DialogContent>
//             <DialogContentText id="alert-dialog-description">
//             <div className="row">
//                 {error ?<UncontrolledAlert color="danger">{error}</UncontrolledAlert>: null}
//                 {/* {serverError ?<UncontrolledAlert color="danger">{serverError}</UncontrolledAlert>: null}
//                 {state.error_experience_field?<UncontrolledAlert color="danger">{state.error_experience_field}</UncontrolledAlert>: null}
//                 {state.error_company_field?<UncontrolledAlert color="danger">{state.error_company_field}</UncontrolledAlert>: null}
//               */}
//                <div className="col-lg-12">
//                <Form onSubmit={submit}>                            
//                <Row>
//              <Col xs={6}  sm={6} lg={6} md={6}>
//              <Field
//                 name="name"
//                 component={renderField}
//                 label={t('wutiko.document_name')}
//                 placeholder={t('wutiko.document_name')}
//                 autocomplete="off"
//               />
//              </Col>
//              <Col xs={6}  sm={6} lg={6} md={6}>
//                 <FormControl variant="outlined">
//                     <InputLabel htmlFor="filled-age-native-simple">Document type</InputLabel>
//                     <Select
//                     native
//                     value={state.document_type}
//                     onChange={handleChange}
//                     inputProps={{
//                         name: 'document_type',
//                         id: 'filled-age-native-simple',
//                     }}
//                     >
//                     <option aria-label="None" value="" />
//                     <option value={10}>{t("wutiko.cv")}</option>
//                     <option value={10}>{t("wutiko.motivation_letter")}</option>
//                     <option value={10}>{t("wutiko.certificate_diploma_degree")}</option>
//                     <option value={20}>{t("wutiko.english_proficiency_certificate")} </option>
//                     <option value={30}>{t("wutiko.others")}</option>
//                     </Select>
//                 </FormControl>
//               </Col>
             
//            </Row>
//             <Row>
//              <Col xs={12} sm={12} lg={12} md={12}>
//                 <Field component={renderFileInput} name='datafile'/>
//              </Col>
//            </Row> 
//            <Row>
//              <Col xs={6}>
//                   {spinner?loader:<button name="submit" type="submit" className="register-training save  pull-right"  >{t('wutiko.save')}</button>}
//                   <button className="register-training message save  pull-left" onClick={()=>handleClose()}> {t('wutiko.close')} </button>
//              </Col>
//            </Row>
//           <br/>
//         </Form>

//               </div>
//               </div>
//             </DialogContentText>
//           </DialogContent>
//         </Dialog>

   
//       </div>
//     );
//   }


// DocumentForm = reduxForm({
//   form: 'DocumentForm',
//   enableReinitialize: true
// })(DocumentForm);

// export default withTranslation('translation')(DocumentForm);