import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { setgetTestimonies } from "actions/testimonies";

const getTestimoniesData = async (instance)=>
    await instance.get(`/activities/testimonies/list/`)
    .then(response=>response.data)
    .catch(error=>error)

function* getTestimoniesList(){
    const axiosInstance = (state)=>state.users.blankAxiosInstance
    const instance =yield select(axiosInstance);
    const data =yield call(getTestimoniesData,instance);
    console.log("/activities/testimonies/list/",data)
    yield put(setgetTestimonies(data));
}
export function* getTestimonies(){
    yield takeEvery("HANDLE_GET_TESTIMONIES", getTestimoniesList)
}
export default function* rootSaga() {
    yield all([fork(getTestimonies)]);
}