
  import React,{useEffect} from 'react';
  import { connect } from 'react-redux';
  import { makeStyles } from '@material-ui/core/styles';
  import Dialog from '@material-ui/core/Dialog';
  import AppBar from '@material-ui/core/AppBar';
  import Toolbar from '@material-ui/core/Toolbar';
  import IconButton from '@material-ui/core/IconButton';
  import Typography from '@material-ui/core/Typography';
  import CloseIcon from '@material-ui/icons/Close';
  import DialogContent from '@material-ui/core/DialogContent';
  import Grid from '@material-ui/core/Grid';
  import Button from '@material-ui/core/Button';
  import {useDispatch, useSelector} from 'react-redux'
  import CircularProgress from '@material-ui/core/CircularProgress';
  import {Alert} from '@material-ui/lab';
  import Form from "@rjsf/core";
  //import Form from '@rjsf/material-ui';


//   import { withTheme } from '@rjsf/core';
// import { Theme as MaterialUITheme } from '@rjsf/material-ui';


  import { CONTACT_FORM_ID } from 'config';
  import { handleSubmitContactForm, submitContactFormRemoveMessages } from 'actions/forms/contactForm';
  import { handleGetForm } from 'actions/forms/formsGenerator';
  //onst dispatch = useDispatch()
 // const Form = withTheme(MaterialUITheme);
  const useStyles = makeStyles((theme) => ({
      appBar: {
        position: 'relative',
      },
      title: {
      },
      paper:{
          marginTop: theme.spacing(8),
          marginBottom: theme.spacing(8),
          marginLeft: 15,
          marginRight: 15,
          [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
          },
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      content:{
          padding:15,
          paddingBottom:30,
          overflowY:"hidden"
      },
      paper:{
          backgroundColor:'#e0e0e0'
      },
      upload:{
          width:80,
          height:80
      }
    }));
    export default function AppForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const formStructure = useSelector(state=>state.formsGenerator.get_form.details);
    const spinner= useSelector(state=>state.formsGenerator.get_form.spinner);
    const properties= useSelector(state=>state.formsGenerator.get_form.properties);
    const required_1= useSelector(state=>state.formsGenerator.get_form.required);
    const required = formStructure.required? formStructure.required:[];
    const submit_data= useSelector(state=>state.formsGenerator.submit_contact_form);
    const uiSchema_1 = useSelector(state=>state.formsGenerator.get_form.uiSchema);
    const uiSchema =   uiSchema_1?  uiSchema_1:[];
  
  
    useEffect(()=>{
      dispatch(handleGetForm(CONTACT_FORM_ID))
    },[dispatch]);
    
  
    console.log("formStructure App", formStructure)
    console.log("required", formStructure.required)
    console.log("properties", formStructure.properties)
    console.log("uiSchema app", formStructure.uiSchema)
    
     const schema = {
      title: "Todo",
      type: "object",
      required: ["title"],
      properties: {
        title: {type: "string", title: "Title", default: "A new task"},
        done: {type: "boolean", title: "Done?", default: false}
      }
    };
  
    const Submission =(formData)=>{
      console.log(`formData`,formData)
    var virtual_state={
        email_to:'',
        status:false,
      }
      const data=formData.formData;
      var fields=[];
      Object.keys(formStructure.uiSchema).forEach((field)=>{
        if(typeof data[`${field}`]!='object'){
            return fields.push({value:data[`${field}`]||"" ,field_id:field, label:formStructure.properties[field].title})
        }
        else{
          var vall="";
          data[`${field}`].forEach((val)=>{
              return vall+=val+','
          });
            return fields.push({value:vall.trim().slice(0, -1),field_id:field, label:formStructure.properties[field].title})
        }
      },
      console.log("SUBMISSION", fields),
        dispatch(handleSubmitContactForm({fields:fields, is_submitted:"true"},{email_to:virtual_state.email_to || ""},CONTACT_FORM_ID))
      );
    
  }
   
     //console.log("formStructure.required.length", this.props.formStructure)
      const loader=<center><CircularProgress  color="primary"/></center>;
        return (
       
            <Grid container spacing={2}>
                 {spinner ? loader :
              <Grid item xs={12}>
               {/* {this.props.submit_data.message?<Alert severity="success" onClose={()=>dispatch(submitContactFormRemoveMessages())}>{this.props.submit_data.message}</Alert>:null}
                {this.props.submit_data.error?<Alert severity="error" onClose={()=>dispatch(submitContactFormRemoveMessages())}>{this.props.submit_data.error}</Alert>:null}
                   */}
                     <Form 
                        schema={
                          {
                            title:'',
                            description:'',
                            type:'object',
                            required:formStructure.required,
                            properties:formStructure.properties,
                        }}
                        uiSchema={uiSchema_1}
                        //uiSchema={formStructure.uiSchema}
                        showErrorList={false}
                        onSubmit={Submission}
                    >
                      <Grid item container xs={12} justify="flex-end">
                        {submit_data.spinner?loader:<Button color="primary" variant="contained" type="submit">Submit</Button>}
                      </Grid>
                      
                    </Form>
                    </Grid>
                    
               }
            </Grid>
    );
  }
  
  // function mapStateToProps(state){
  //     return {
  //       user: state.users,
  //     //   fields: state.dynamicform.fields,
  //     formStructure: state.formsGenerator.get_form.details,
  //       properties: state.formsGenerator.get_form.properties,
  //       uiSchema: state.formsGenerator.get_form.uiSchema,
  //       required:state.formsGenerator.get_form.required,
  //       error: state.formsGenerator.get_form.error,
  //       spinner: state.formsGenerator.get_form.spinner,
  //       submit_data:state.formsGenerator.submit_contact_form
  //     }
  //   }
    //export default connect(mapStateToProps, {handleGetForm})(Contact);
  