import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { getCompanySuccess, getCompanyError } from  "actions/companies/getCompanies";

const GetCompanyRequest = async (instance)=>
    await instance.get(`searches/search/results/organizations/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetCompany(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetCompanyRequest,axiosInstance, actions);
    
    if(data.data.results.organizations){

    console.log("Get Company",  data.data.results.organizations)
        yield put(getCompanySuccess(data.data.results.organizations));
    }else{
        yield put(getCompanyError(data.error));
    }
    
}
export function* handleGetCompany(){
    yield takeEvery("HANDLE_GET_COMPANY", GetCompany)
}
export default function* rootSaga() {
    yield all([fork(handleGetCompany)]);
}
