/***
 * Get keys with id
 */
export function handleGetKeysDashboard(data){
    return{
        type:"HANDLE_GET_KEYS_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetKeysDashboardSuccess(data){
    console.log(data,"GET_KEYS_DASHBOARD")
    return{
        type:"GET_KEYS_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetKeysDashboardError(data){
    return{
        type:"GET_KEYS_DASHBOARD_FAILLURE",
        payload:data
    }
}




/***
 * Get Best Talents with id
 */
export function handleGetBestTalentsDashboard(data){
    return{
        type:"HANDLE_GET_BEST_TALENTS_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetBestTalentsDashboardSuccess(data){
    console.log(data,"GET_BEST_TALENTS_DASHBOARD")
    return{
        type:"GET_BEST_TALENTS_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetBestTalentsDashboardError(data){
    return{
        type:"GET_BEST_TALENTS_DASHBOARD_FAILLURE",
        payload:data
    }
}



/***
 * Get Top Location with id
 */
export function handleGetTopLocationsDashboard(data){
    return{
        type:"HANDLE_GET_TOP_LOCATIONS_DASHBOARD", 
        data:data
    }
}
//Backend response data
export function GetTopLocationsDashboardSuccess(data){
    console.log(data,"GET_TOP_LOCATIONS_DASHBOARD")
    return{
        type:"GET_TOP_LOCATIONS_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetTopLocationsDashboardError(data){
    return{
        type:"GET_TOP_LOCATIONS_DASHBOARD_FAILLURE",
        payload:data
    }
}






/***
 * Get Best Talents with id
 */
export function handleGetFollowersDashboard(data){
    return{
        type:"HANDLE_GET_FOLLOWERS_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetFollowersDashboardSuccess(data){
    console.log(data,"GET_FOLLOWERS_DASHBOARD")
    return{
        type:"GET_FOLLOWERS_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetFollowersDashboardError(data){
    return{
        type:"GET_FOLLOWERS_DASHBOARD_FAILLURE",
        payload:data
    }
}



/***
 * Get Best Talents with id
 */
export function handleGetMatchingDashboard(data){
    return{
        type:"HANDLE_GET_MATCHING_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetMatchingDashboardSuccess(data){
    console.log(data,"GET_MATCHING_DASHBOARD")
    return{
        type:"GET_MATCHING_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetMatchingDashboardError(data){
    return{
        type:"GET_MATCHING_DASHBOARD_FAILLURE",
        payload:data
    }
}



/***
 * Get Best Talents with id
 */
export function handleGetOpportunitiesDashboard(data){
    return{
        type:"HANDLE_GET_OPPORTUNITIES_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetOpportunitiesDashboardSuccess(data){
    console.log(data,"GET_OPPORTUNITIES_DASHBOARD")
    return{
        type:"GET_OPPORTUNITIES_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetOpportunitiesDashboardError(data){
    return{
        type:"GET_OPPORTUNITIES_DASHBOARD_FAILLURE",
        payload:data
    }
}

/***
 * Get Opportunities ONLINE
 */
export function handleGetOpportunitiesOnlineDashboard(data){
    return{
        type:"HANDLE_GET_OPPORTUNITIES_ONLINE_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetOpportunitiesOnlineDashboardSuccess(data){
    console.log(data,"GET_OPPORTUNITIES_ONLINE_DASHBOARD")
    return{
        type:"GET_OPPORTUNITIES_ONLINE_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetOpportunitiesOnlineDashboardError(data){
    return{
        type:"GET_OPPORTUNITIES_ONLINE_DASHBOARD_FAILLURE",
        payload:data
    }
}


/***
 * Get Opportunities ONLINE
 */
export function handleGetOpportunitiesOfflineDashboard(data){
    return{
        type:"HANDLE_GET_OPPORTUNITIES_OFFLINE_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetOpportunitiesOfflineDashboardSuccess(data){
    console.log(data,"GET_OPPORTUNITIES_OFFLINE_DASHBOARD")
    return{
        type:"GET_OPPORTUNITIES_OFFLINE_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetOpportunitiesOfflineDashboardError(data){
    return{
        type:"GET_OPPORTUNITIES_OFFLINE_DASHBOARD_FAILLURE",
        payload:data
    }
}


/***
 * Get Opportunities DELETE
 */
export function handleGetOpportunitiesDeleteDashboard(data){
    return{
        type:"HANDLE_GET_OPPORTUNITIES_DELETE_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetOpportunitiesDeleteDashboardSuccess(data){
    console.log(data,"GET_OPPORTUNITIES_DELETE_DASHBOARD")
    return{
        type:"GET_OPPORTUNITIES_DELETE_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetOpportunitiesDeleteDashboardError(data){
    return{
        type:"GET_OPPORTUNITIES_DELETE_DASHBOARD_FAILLURE",
        payload:data
    }
}







/***
 * Get scores with id
 */
export function handleGetScoresDashboard(data){
    return{
        type:"HANDLE_GET_SCORES_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetScoresDashboardSuccess(data){
    console.log(data,"GET_SCORES_DASHBOARD")
    return{
        type:"GET_SCORES_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetScoresDashboardError(data){
    return{
        type:"GET_SCORES_DASHBOARD_FAILLURE",
        payload:data
    }
}





/***
 * Get Recent activities with id
 */
export function handleGetRecentActivitiesDashboard(data){
    return{
        type:"HANDLE_GET_RECENT_ACTIVITIES_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetRecentActivitiesDashboardSuccess(data){
    console.log(data,"GET_RECENT_ACTIVITIES_DASHBOARD")
    return{
        type:"GET_RECENT_ACTIVITIES_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetRecentActivitiesDashboardError(data){
    return{
        type:"GET_RECENT_ACTIVITIES_DASHBOARD_FAILLURE",
        payload:data
    }
}






/***
 * Get curent plan
 */
export function handleGetCurrentPlanDashboard(data){
    return{
        type:"HANDLE_GET_CURRENT_PLAN_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetCurrentPlanDashboardSuccess(data){
    console.log(data,"GET_CURRENT_PLAN_DASHBOARD")
    return{
        type:"GET_CURRENT_PLAN_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetCurrentPlanDashboardError(data){
    return{
        type:"GET_CURRENT_PLAN_DASHBOARD_FAILLURE",
        payload:data
    }
}



/***
 * Get Best Talents with id
 */
export function handleGetApplicationsDashboard(data){
    return{
        type:"HANDLE_GET_APPLICATIONS_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetApplicationsDashboardSuccess(data){
    console.log(data,"GET_APPLICATIONS_DASHBOARD")
    return{
        type:"GET_APPLICATIONS_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetApplicationsDashboardError(data){
    return{
        type:"GET_APPLICATIONS_DASHBOARD_FAILLURE",
        payload:data
    }
}





/***
 * Get response opportunities form  with id
 */
export function handleGetApplicationsDashboardForm(data){
    return{
        type:"HANDLE_GET_APPLICATIONS_DASHBOARD_FORM",
        data:data
    }
}
//Backend response data
export function GetApplicationsDashboardFormSuccess(data){
    console.log(data,"GET_APPLICATIONS_DASHBOARD_FORM")
    return{
        type:"GET_APPLICATIONS_DASHBOARD_FORM",
        payload:data
    }
}
//  Backend Error message
export function GetApplicationsDashboardFormError(data){
    return{
        type:"GET_APPLICATIONS_DASHBOARD_FORM_FAILLURE",
        payload:data
    }
}


/***
 * Get response opportunities form  with id
 */
export function handleGetApplicationsDashboardFormSelect(data){
    return{
        type:"HANDLE_GET_APPLICATIONS_DASHBOARD_FORM_SELECTED",
        data:data
    }
}
//Backend response data
export function GetApplicationsDashboardFormSelectSuccess(data){
    console.log(data,"GET_APPLICATIONS_DASHBOARD_FORM_SELECTED")
    return{
        type:"GET_APPLICATIONS_DASHBOARD_FORM_SELECTED",
        payload:data
    }
}
//  Backend Error message
export function GetApplicationsDashboardFormSelectError(data){
    return{
        type:"GET_APPLICATIONS_DASHBOARD_FORM_SELECTED_FAILLURE",
        payload:data
    }
}
/***
 * Get Shared Dashboard
 */
export function handleGetApplicationsSharedDashboard(data){
    return{
        type:"HANDLE_GET_APPLICATIONS_SHARED_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetApplicationsSharedDashboardSuccess(data){
    console.log(data,"GET_APPLICATIONS_SHARED_DASHBOARD")
    return{
        type:"GET_APPLICATIONS_SHARED_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetApplicationsSharedDashboardError(data){
    return{
        type:"GET_APPLICATIONS_SHARED_DASHBOARD_FAILLURE",
        payload:data
    }
}





/***
 * Get Profile Recommandation Dashboard
 */
export function handleGetApplicationsRecommendationsDashboard(data){
    return{
        type:"HANDLE_GET_APPLICATIONS_RECOMMENDATIONS_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetApplicationsRecommendationsDashboardSuccess(data){
    console.log(data,"GET_APPLICATIONS_RECOMMENDATIONS_DASHBOARD")
    return{
        type:"GET_APPLICATIONS_RECOMMENDATIONS_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetApplicationsRecommendationsDashboardError(data){
    return{
        type:"GET_APPLICATIONS_RECOMMENDATIONS_DASHBOARD_FAILLURE",
        payload:data
    }
}






/***
 * Get Selected Best Talents 
 */
export function handleGetApplicationsSelectedAppDashboard(data){
    return{
        type:"HANDLE_GET_APPLICATIONS_SELECTED_APP_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetApplicationsDashboardSelectedAppSuccess(data){
    console.log(data,"GET_APPLICATIONS_SELECTED_APP_DASHBOARD")
    return{
        type:"GET_APPLICATIONS_SELECTED_APP_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetApplicationsDashboardSelectedAppError(data){
    return{
        type:"GET_APPLICATIONS_SELECTED_APP_DASHBOARD_FAILLURE",
        payload:data
    }
}

/***
 * Get selected Dashboard
 */
export function handleGetApplicationsSelectedRecDashboard(data){
    return{
        type:"HANDLE_GET_APPLICATIONS_SELECTED_REC_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetApplicationsSelectedRecDashboardSuccess(data){
    console.log(data,"GET_APPLICATIONS_SELECTED_REC_DASHBOARD")
    return{
        type:"GET_APPLICATIONS_SELECTED_REC_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetApplicationsSelectedRecDashboardError(data){
    return{
        type:"GET_APPLICATIONS_SELECTED_REC_DASHBOARD_FAILLURE",
        payload:data
    }
}





/***
 * Get Profile SELECTED_SHARED Dashboard
 */
export function handleGetApplicationsSelectedSharedDashboard(data){
    return{
        type:"HANDLE_GET_APPLICATIONS_SELECTED_SHARED_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetApplicationsSelectedSharedDashboardSuccess(data){
    console.log(data,"GET_APPLICATIONS_SELECTED_SHARED_DASHBOARD")
    return{
        type:"GET_APPLICATIONS_SELECTED_SHARED_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetApplicationsSelectedSharedDashboardError(data){
    return{
        type:"GET_APPLICATIONS_SELECTED_SHARED_DASHBOARD_FAILLURE",
        payload:data
    }
}


/***
 * Get list apply spontaneous profil
 */
export function handleGetSpontaneousApplyDashboardSelected(data){
    return{
        type:"HANDLE_GET_SPONTANEOUS_APPLY_DASHBOARD_SELECTED",
        data:data
    }
}
//Backend response data
export function GetSpontaneousApplyDashboardSelectedSuccess(data){
    console.log(data,"GET_SPONTANEOUS_APPLY_SELECTED_DASHBOARD")
    return{
        type:"GET_SPONTANEOUS_APPLY_DASHBOARD_SELECTED",
        payload:data
    }
}
//  Backend Error message
export function GetSpontaneousApplyDashboardSelectedError(data){
    return{
        type:"GET_SPONTANEOUS_APPLY_DASHBOARD_SELECTED_FAILLURE",
        payload:data
    }
}

