
const initialState = {
    filter_company:{
        error:null,
        spinner:false,
        count:null,
        companies:[]
    },

 }

 export function companyFilter(state = initialState, action){
 
   switch(action.type) {

    case "GET_COMPANY_FILTER_FAILLURE":
            return {...state, filter_company:{
                ...state.filter_company, error:action.payload, spinner:false, companies:[]
            }}
   case "GET_COMPANY_FILTER":
           return {...state, filter_company:{
               ...state.filter_company, error:null, spinner:false, companies:action.payload.results
           }}
     default:
       return state;
   }
 }
