import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {useDispatch, useSelector} from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/lab/Pagination';
import Opportunity from 'components/uielements/Opportunity';
import CompaniesFilters from 'containers/filters/CompaniesFilters';
import {  handleOrganizationsSearch } from 'actions/search';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Company from './Company';
import ReactPaginate from 'react-paginate';
import IntlMessages from "util/IntlMessages";
const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding:15,
    paddingLeft:24,
    paddingRight:24,
    width:'100%',
  },
  ul:{
    'list-style-type': 'none',
    paddingLeft: 0,

  },
  btn:{
    backgroundColor:"#ff9801",
    '&:hover':{
      backgroundColor:"#ff9801",
    }
  }
}));

export default function CompaniesAll() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const state = useSelector(state=>state.company.organization);
    const companiesList=state.list.organizations?state.list.organizations:[];
    const RESULTS_PER_PAGE = 12;
    const count = useSelector(state=>state.company.organization.count);
   const [currentPage, setcurrentPage] = React.useState(1);
   console.log('companiesList',state)
    useEffect(()=>{
      dispatch(handleOrganizationsSearch({scope:"organization", search:`${window.location.search}`, page:`${currentPage}`}))
    },[dispatch])



 const handlePageClick=(newPage) =>{
  setcurrentPage(newPage.selected + 1)
  dispatch(handleOrganizationsSearch({scope:"organization",search:`${window.location.search}`,page:`${newPage.selected + 1}`}));
} 

    let pageCount;
    let onPageChange;


    pageCount = Math.ceil(count / RESULTS_PER_PAGE);
    onPageChange = handlePageClick;

   
    console.log("Companies List", state)
    return (
    <div className="container list-profile">
    
          
      <div className="container-filter">

      <Container maxWidth={false} className={classes.cont}>
            <Typography 
                component="h1"
                variant="h5"
                color="inherit"
                align="center"
                noWrap
                className={classes.title}>
              {<IntlMessages id="list_of_all_companies"/>}
          </Typography>
          </Container>

        <Grid  xs={12} className="total-companies text-center">
            <IntlMessages id="showing_count_results" values={{ count: state.count}}/>
        </Grid>
       
        <Grid className="container filter-opportunities">
        
          <CompaniesFilters/>
          
        </Grid>
      </div>
        <Grid container spacing={2}>
        {
          state.spinner?
          <Grid container item justify="center" xs={12}><CircularProgress size={80}/></Grid>:
          companiesList.map((item)=>{
            return(
              <Grid item md={4} xs={12}>
                  <Company data={item}/>
             </Grid>
            )
          })
        }
        </Grid>
        <Grid item md={12} xs={12} container justify="center" className="Listpagination">
        
              <ReactPaginate
                previousLabel={count > 0 ?<IntlMessages id="previous" />:null}
                nextLabel={count > 0 ?<IntlMessages id="next" />:null}
                pageCount={count > 0 ?pageCount:null}
                onPageChange={count > 0 ?handlePageClick:null}
                containerClassName={count > 0 ?"pagination":null}
                previousLinkClassName={count > 0 ?"pagination__link":null}
                nextLinkClassName={count > 0 ?"pagination__link":null}
                disabledClassName={count > 0 ?"pagination__link--disabled":null}
                activeClassName={count > 0 ?"pagination__link--active":null}
              />


        </Grid>
      
    </div>
  );
}

CompaniesAll.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};





















