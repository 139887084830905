import React,{useState,useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import {createMuiTheme} from "@material-ui/core/styles";
import SidebarRight from "components/Sidebar/SidebarRight";
import { CardImgOverlay } from "reactstrap";
import index from "react-typical";
import Slider from "react-slick";
import {useDispatch, useSelector} from 'react-redux'
import { handleGetKeysDashboard ,handleGetCurrentPlanDashboard, handleGetTopLocationsDashboard, handleGetBestTalentsDashboard,
  handleGetFollowersDashboard, handleGetScoresDashboard, handleGetOpportunitiesDashboard, handleGetMatchingDashboard, handleGetRecentActivitiesDashboard} from 'actions/dashboard/keysList';
import SiteVisitor from "components/dashboard/Misc/SiteVisitor";
import CardBox from "components/CardBox/index";
import CardMenu from 'components/dashboard/Common/CardMenu';
import {Area, AreaChart, Line, LineChart, ResponsiveContainer} from "recharts";
import ChartCard from "./ChartCard";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import {Link} from "react-router-dom";
import {Animated} from "react-animated-css";
import SalesGauge from "./SalesGauge";
import FollowersDashboardPage from "./FollowersDashboard"
import MatchingDashboardPage from "./MatchingDashboard"
import Aux from "util/Auxiliary.js"
import Widget from "components/Widget/index";
import Button from '@material-ui/core/Button';
import RecentActivity from "./RecentActivity";
import IntlMessages from 'util/IntlMessages';
import ProfileView from 'components/profile/ProfileHeader/ProfileView'
import {STORE_URL ,ACCOUNT_URL, PAGE_URL} from "config";
import Dashboard from "./dashboard"
import ContainerHeader from "../ContainerHeader"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import SidebarLeftApplicants from './SidebarLeftApplicants';
import SidebarLeftOpportunity from './SidebarLeftOpportunity';
import {LinkContainer } from 'react-router-bootstrap'; 
import {NavItem} from 'react-bootstrap';
import SideBar from 'containers/SideBar';

const useStyles = makeStyles((theme) => ({
  ul:{
    'list-style-type': 'none',
    paddingLeft: 0,
  },
  btn:{
    position:'absolute',
    left:'42%',
    backgroundColor:"#fdc000",
    '&:hover':{
      backgroundColor:"#fdc000",
    }
  },
 cardActions:{
      paddingLeft:15,
      paddingRight:15
  },
  avatar:{
    width: 50,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius:'100%',
  }, 
  flexEnd:{
      paddingLeft:'28%',
  },
  icon:{
      width:15,
      height:15
  },
  icons:{
    width:15,
    height:15,
    border:"solid 1px #c1c1c1"
  },
  link:{
    textDecoration:'none',
    color:'#4285f4',
    '&:hover':{
      textDecoration:'none'
    }
  
}
}));
var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};


export default function IndexDashboard(props) {
  const classes = useStyles();
  const [anchorEl,setanchorEl]=useState();
  const [menuState,setMenuState]=useState(false);

  

   const increamentData = [
    {name: 'Page A', pv: 200},
    {name: 'Page B', pv: 1200},
    {name: 'Page C', pv: 600},
    {name: 'Page D', pv: 1600},
    {name: 'Page D', pv: 1000},
    {name: 'Page H', pv: 2260},
    {name: 'Page K', pv: 800},
  ];
  
  const onOptionMenuSelect = event => {
    setMenuState(true);
    setanchorEl(event.currentTarget);
  };

  const handleRequestClose = () => {
    setMenuState(false);
  };
  const RESULTS_PER_PAGE = 12;
  const dispatch = useDispatch();
  const KeysList = useSelector(state=>state.keys.keysDashboard.list);
  const topLocationsDashboard = useSelector(state=>state.keys.topLocationsDashboard.list);
  const bestTalentDashboard = useSelector(state=>state.keys.bestTalents.list);

  const countMatching = useSelector(state=>state.keys.matchingDashboard.count);
  const countFollowers = useSelector(state=>state.keys.followersDashboard.count);
  const spinnerFollowersDashboard = useSelector(state=>state.keys.followersDashboard.spinner);
  const spinnerMatchingDashboard = useSelector(state=>state.keys.matchingDashboard.spinner);
  const MatchingDashboard = useSelector(state=>state.keys.matchingDashboard.list);
  const FollowersDashboard = useSelector(state=>state.keys.followersDashboard.list);
  const ScoresDashboard = useSelector(state=>state.keys.scoresDashboard.list);
  const RecentActivitiesDashboard = useSelector(state=>state.keys.recentActivitiesDashboard.list);
  const CurrentPlanDashboard = useSelector(state=>state.keys.currentPlanDashboard.list);
  const is_user_recruiter = useSelector(state=>state.users.userSettings.is_user_recruiter);
  const isAuthenticated =useSelector(state=>state.users.authenticated)
  const [currentPage, setcurrentPage] = React.useState(1);
  const [currentPageMatching, setcurrentPageMatching] = React.useState(1);
  const [currentPageFollowers, setcurrentPageFollowers] = React.useState(1);
  const [pages,setPages]= useState("20");
  const pagesScore = useSelector(state=>state.keys.scoresDashboard.pages);
  const KeysListopportunities = KeysList.opportunities;
  const KeysListFollowers = KeysList.followers;
  const KeysListMatching = KeysList.matching;
  const KeysListApplications = KeysList.applicants;
  const TotalViews = KeysList.organizations_views;

  const TestPage = () => {
    setPages = 90;
  };
  
    console.log("ScoresDashboard[0]", ScoresDashboard.pages)
    console.log("Rescent Activities", RecentActivitiesDashboard)
    console.log("Current Plan", CurrentPlanDashboard)
    console.log("is_user_recruiter", is_user_recruiter)
  
  const data = 

      [
        {
          "category": "Competitions",
          "value":   40,
          "full": 100
        },
        {
          "category": "Pages",
          "value":  80,
          "full": 100
        },
        {
          "category": "Opportunities",
          "value":   60,
          "full": 100
        },]  ;

  console.log("Score Pages" , parseInt(pages))
  useEffect(()=>{
    dispatch(handleGetKeysDashboard({}))
    dispatch(handleGetTopLocationsDashboard({}))
    dispatch(handleGetBestTalentsDashboard({}))
    dispatch(handleGetMatchingDashboard({search:`${window.location.search}`, page:`${currentPageMatching}`}))
    dispatch(handleGetScoresDashboard({}))
    dispatch(handleGetRecentActivitiesDashboard({}))
    dispatch(handleGetCurrentPlanDashboard({}))
    dispatch(handleGetFollowersDashboard({search:`${window.location.search}`, page:`${currentPageFollowers}`}))
  },[dispatch])

      const handlePageClickMatching=(newPage) =>{
        setcurrentPageMatching(newPage.selected + 1)
        dispatch(handleGetMatchingDashboard({page:`${newPage.selected + 1}`}));
      } 
          let pageCountMatching;
          let onPageChangeMatching;
          pageCountMatching = Math.ceil(countMatching / RESULTS_PER_PAGE);
          onPageChangeMatching = handlePageClickMatching;
   
          const handlePageClickFollowers=(newPage) =>{
            setcurrentPageFollowers(newPage.selected + 1)
            dispatch(handleGetFollowersDashboard({page:`${newPage.selected + 1}`}));
          } 
          
              let pageCountFollowers;
              let onPageChangeFollowers;
          
          
              pageCountFollowers = Math.ceil(countFollowers / RESULTS_PER_PAGE);
              onPageChangeFollowers = handlePageClickFollowers;
          
  console.log("KeysList" , KeysList)
  console.log("KeysListopportunities" , KeysListopportunities)
  console.log("topLocationsDashboard" , topLocationsDashboard)
  console.log("Best Talents" , bestTalentDashboard)
  console.log("MatchingDashboard" , MatchingDashboard)
  console.log("FollowersDashboard" , FollowersDashboard)
  console.log("ScoresDashboard" , ScoresDashboard)
    const settings = {
        dots: true,
        infinite: true,
        speed: 600,
        slidesToShow: 5,
        slidesToScroll: 5
      };

    
    
      
    return (
      <Router> 
      {/* <SideBar/> */}
  {/* 
  <ContainerHeader title={<IntlMessages id="dashboard"/>} match={props.match}/> 
  */}
    <div className="container-wrap container animated slideInUpTiny animation-duration-3 dashbord-bloc">   
        
        <div className="row col-md-12 top-20">
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 item">
          <LinkContainer to="/dashboard/opportunities/all" className="nav-item" onlyActiveOnIndex> 
          <NavItem eventKey="opportunities" xs={12} sm={12}>
          <ChartCard chartProperties={{
            title: <IntlMessages id="opportunities"/>,
            prize: KeysListopportunities,
            icon: 'stats',
            bgColor: 'indigo',
            styleName: 'up',
            desc: 'This week',
            percent: '03%',
          }}
                     children={<ResponsiveContainer width="100%" height={75}>
                       <AreaChart data={increamentData}
                                  margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                         <Area dataKey='pv' strokeWidth={0} stackId="2" stroke='#273894' fill="#273894"
                               fillOpacity={1}/>
                       </AreaChart>
                     </ResponsiveContainer>}
                     
          /><div className="fleche opportunities"></div></NavItem>
          </LinkContainer>
       </div>
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 item">
        
        <LinkContainer to="/dashboard/applicants/pipeline"  className="nav-item" >
           <NavItem eventKey="pipeline" xs={12} sm={12}>
        <ChartCard
            chartProperties={{
              title: <IntlMessages id="pipeline"/>,
              prize:  KeysListApplications,
              icon: 'stats',
              bgColor: 'pink accent-2',
              styleName: 'up',
              desc: '7 New cities this week',
              percent: '',
            }}
            children={<ResponsiveContainer width="100%" height={75}>
              <AreaChart data={increamentData}
                         margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                <Area dataKey='pv' type='monotone' strokeWidth={0} stackId="2" stroke='#da2361'
                      fill='#da2361'
                      fillOpacity={1}/>
              </AreaChart>
            </ResponsiveContainer>}
          /><div className="fleche pipeline"></div></NavItem></LinkContainer>
          
{/* //           :
//           <a href={`${STORE_URL}/plans/pricing/`}  target="blank"  className="blockNotConnect"> 
//           <ChartCard
//               chartProperties={{
//                 title: <IntlMessages id="applicants"/>,
//                 prize:  KeysListApplications,
//                 icon: 'stats',
//                 bgColor: 'pink accent-2',
//                 styleName: 'up',
//                 desc: '7 New cities this week',
//                 percent: '',
//               }}
//               children={<ResponsiveContainer width="100%" height={75}>
//                 <AreaChart data={increamentData}
//                            margin={{top: 0, right: 0, left: 0, bottom: 0}}>
//                   <Area dataKey='pv' type='monotone' strokeWidth={0} stackId="2" stroke='#da2361'
//                         fill='#da2361'
//                         fillOpacity={1}/>
//                 </AreaChart>
//               </ResponsiveContainer>}
//             /></a>
// } */}
        </div>
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 item">
        {is_user_recruiter?
        <LinkContainer to="/dashboard/matching"  className="nav-item" > 
         <NavItem eventKey="matching" xs={12} sm={12}>
        <ChartCard
            chartProperties={{
              title: <IntlMessages id="matching"/>,
              prize:  KeysListMatching,
              icon: 'stats',
              bgColor: 'info',
              styleName: 'down',
              desc: 'Avg. 327 visits daily',
              percent: '',
            }}
            children={<ResponsiveContainer width="100%" height={75}>
              <AreaChart data={increamentData}
                         margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                <Area dataKey='pv' strokeWidth={0} stackId="2" stroke='#0c8e9f' fill='#0c8e9f'
                      fillOpacity={1}/>
              </AreaChart>
            </ResponsiveContainer>}
          /><div className="fleche matching"></div></NavItem></LinkContainer>:
          <a href={`${STORE_URL}/plans/pricing/`}  target="blank"  className="blockNotConnect"> 
          <ChartCard
              chartProperties={{
                title:  <IntlMessages id="matching"/>,
                prize:  KeysListMatching,
                icon: 'stats',
                bgColor: 'info',
                styleName: 'down',
                desc: 'Avg. 327 visits daily',
                percent: '',
              }}
              children={<ResponsiveContainer width="100%" height={75}>
                <AreaChart data={increamentData}
                           margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                  <Area dataKey='pv' strokeWidth={0} stackId="2" stroke='#0c8e9f' fill='#0c8e9f'
                        fillOpacity={1}/>
                </AreaChart>
              </ResponsiveContainer>}
            /></a>
          }
        </div>


        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 item last">
       
        <LinkContainer to="/dashboard/followers"  className="nav-item" > 
         <NavItem eventKey="followers" xs={12} sm={12}>
         <ChartCard
            chartProperties={{
              title: <IntlMessages id="followers"/>,
              prize:  KeysListFollowers,
              icon: 'stats',
              bgColor: 'success',
              styleName: 'down',
              desc: 'from past month',
              percent: '39%',
            }}
            children={<ResponsiveContainer width="100%" height={75}>
              <AreaChart data={increamentData}
                         margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                <Area dataKey='pv' strokeWidth={0} stackId="2" stroke='#3a983e' fill='#3a983e'
                      fillOpacity={1}/>
              </AreaChart>
            </ResponsiveContainer>}
          />
        <div className="fleche followers"></div></NavItem></LinkContainer>
    {/**      :
          <a href={`${STORE_URL}/plans/pricing/`} target="blank" className="blockNotConnect">  
         <ChartCard
            chartProperties={{
              title:<IntlMessages id="followers"/>,
              prize:  KeysListFollowers,
              icon: 'stats',
              bgColor: 'success',
              styleName: 'down',
              desc: 'from past month',
              percent: '39%',
            }}
            children={<ResponsiveContainer width="100%" height={75}>
              <AreaChart data={increamentData}
                         margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                <Area dataKey='pv' strokeWidth={0} stackId="2" stroke='#3a983e' fill='#3a983e'
                      fillOpacity={1}/>
              </AreaChart>
            </ResponsiveContainer>}
          /></a>
          
          */ }


     

        </div>
        </div>
        <Switch>
        <Route exact path="/dashboard">
            <Dashboard/>
            </Route>
            <Route exact  path="/dashboard/opportunities/all">
            <SidebarLeftOpportunity title="Opportunities"/>
            </Route>
            <Route path="/dashboard/applicants/pipeline">
            <SidebarLeftApplicants title="Applicants"/>
            </Route>
            <Route path="/dashboard/matching">
            <MatchingDashboardPage title="Matching" MatchingDashboard={MatchingDashboard? MatchingDashboard:[]}
              handlePageClick={handlePageClickMatching}
              count={countMatching}
              pageCount={pageCountMatching}
              spinner={spinnerMatchingDashboard}/>
             </Route>
            <Route path="/dashboard/followers">
            <FollowersDashboardPage title="Followers" FollowersDashboard={FollowersDashboard? FollowersDashboard:[]}
              handlePageClick={handlePageClickFollowers}
              count={countFollowers}
              pageCount={pageCountFollowers}
              spinner={spinnerFollowersDashboard}/>
            </Route>
        </Switch>
        </div>
        </Router> 
    )
}




