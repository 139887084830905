import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import SortIcon from "@material-ui/icons/ArrowDownward";
import {useDispatch, useSelector} from 'react-redux'
import {  handleGetApplicationsDashboard, handleGetApplicationsDashboardForm, handleGetApplicationsDashboardFormSelect, handleGetApplicationsRecommendationsDashboard, handleGetApplicationsSelectedAppDashboard, handleGetApplicationsSelectedRecDashboard, handleGetApplicationsSelectedSharedDashboard, handleGetApplicationsSharedDashboard, handleGetFollowersDashboard, handleGetSpontaneousApplyDashboardSelected } from 'actions/dashboard/keysList';
import React,{useState,useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Button } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CardBox from "components/CardBox/index";
import CardMenu from 'components/dashboard/Common/CardMenu';
import {Area, AreaChart, Line, LineChart, ResponsiveContainer} from "recharts";
import ChartCard from "./ChartCard";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import ItemFollowers from './ItemFollowers'
import {arrayMove, SortableContainer} from 'react-sortable-hoc';
import ItemApplicants from "./ItemApplicationsSelected";
import ReactPaginate from "react-paginate";
import Grid from '@material-ui/core/Grid';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppModuleHeader from './AppModuleHeader';
import { Col, Nav, Row} from 'react-bootstrap';
import {LinkContainer } from 'react-router-bootstrap'; 
import {NavItem} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper'
import  Search  from "./Search";
import { handleGetSpontaneousApplyDashboardPipeline } from "actions/dashboard/profil";
import ItemApplicantForm from "./itemApplicationForm";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const SelectedDashboard = (props) => {
//const {ApplicationsDashboard} = props;

const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
const RESULTS_PER_PAGE = 20;
const dispatch = useDispatch();
const ApplicationsDashboard = useSelector(state=>state.keys.applicationsSelectedAppDashboard.list);
const countApplicants = useSelector(state=>state.keys.applicationsSelectedAppDashboard.count);

const applicationsDashboardForm = useSelector(state=>state.keys.applicationsDashboardFormSelect.list);
const countApplicantsForm = useSelector(state=>state.keys.applicationsDashboardFormSelect.count);
const countApplicantsFormSpinner = useSelector(state=>state.keys.applicationsDashboardFormSelect.spinner);



const applicationsSharedDashboard = useSelector(state=>state.keys.applicationsSelectedSharedDashboard.list);
const ApplicationsSpontaneousDashboard = useSelector(state=>state.keys.applicationsSpontaneousApplySelectedDashboard.list);
const countApplicationsSharedDashboard = useSelector(state=>state.keys.applicationsSelectedSharedDashboard.count);
const countApplicationsSpontaneousDashboard = useSelector(state=>state.keys.applicationsSpontaneousApplySelectedDashboard.count);

const ApplicationsRecommendationsDashboard = useSelector(state=>state.keys.applicationsSelectedRecDashboard.list);
const countApplicationsRecommendationsDashboard = useSelector(state=>state.keys.applicationsSelectedRecDashboard.count);
const spinnerRecommendationsDashboard = useSelector(state=>state.keys.applicationsSelectedRecDashboard.spinner);




const [currentPageApplicantsDashboardForm, setcurrentPageApplicantsDashboardForm] = React.useState(1); 
const [currentPageApplicants, setcurrentPageApplicants] = React.useState(1); 
const [currentPageApplicantsShare, setcurrentPageApplicantsShare] = React.useState(1); 
const [currentPageSpontaneousApplicants, setcurrentPageApplicantsSpontaneous] = React.useState(1); 
const [currentPageApplicationsRecommendations, setcurrentPageApplicationsRecommendations] = React.useState(1); 


const spinner = useSelector(state=>state.keys.applicationsSelectedAppDashboard.spinner);
const spinnerApplicationShare = useSelector(state=>state.keys.applicationsSelectedSharedDashboard.spinner);
const spinnerApplicationSpontaneous = useSelector(state=>state.keys.applicationsSpontaneousApplySelectedDashboard.spinner);


useEffect(()=>{
    dispatch(handleGetApplicationsSelectedAppDashboard({search:`${window.location.search}`, page:`${currentPageApplicants}`}))
    dispatch(handleGetApplicationsSelectedRecDashboard({search:`${window.location.search}`, page:`${currentPageApplicationsRecommendations}`}))
    dispatch(handleGetApplicationsSelectedSharedDashboard({search:`${window.location.search}`, page:`${currentPageApplicantsShare}`}))
    dispatch(handleGetApplicationsDashboardFormSelect({search:`${window.location.search}`, page:`${currentPageApplicants}`}))
    dispatch(handleGetSpontaneousApplyDashboardSelected({search:`${window.location.search}`, page:`${currentPageSpontaneousApplicants}`}))
  },[dispatch])

const handlePageClickApplicants=(newPage) =>{
    setcurrentPageApplicants(newPage.selected + 1)
    dispatch(handleGetApplicationsSelectedAppDashboard({page:`${newPage.selected + 1}`}));
  } 
  
  const handlePageClickApplicantsShared=(newPage) =>{
    setcurrentPageApplicantsShare(newPage.selected + 1)
    dispatch(handleGetApplicationsSelectedSharedDashboard({page:`${newPage.selected + 1}`}));
  } 

  const handlePageClickApplicantsSpontaneous=(newPage) =>{
    setcurrentPageApplicantsSpontaneous(newPage.selected + 1)
    dispatch(handleGetSpontaneousApplyDashboardSelected({page:`${newPage.selected + 1}`}));
  } 

  const handlePageClickApplicantsRecommendations=(newPage) =>{
    setcurrentPageApplicationsRecommendations(newPage.selected + 1)
    dispatch(handleGetApplicationsSelectedRecDashboard({page:`${newPage.selected + 1}`}));
  } 


  const handlePageClickApplicantsDashboardForm=(newPage) =>{
    setcurrentPageApplicantsDashboardForm(newPage.selected + 1)
    dispatch(handleGetApplicationsDashboardFormSelect({page:`${newPage.selected + 1}`}));
  } 
  
      let pageCountApplicants;
      let onPageChangeApplicants;
  
      let pageCountApplicantsShared;
      let onPageChangeApplicantsShared;

      let pageCountApplicantsSpontaneous;
      let onPageChangeApplicantsSpontaneous;


      let pageCountApplicantsRecommendations;
      let onPageChangeApplicantsRecommendations;


      let pageCountApplicantsForm;
      let onPageChangeApplicantsForm;
  

      pageCountApplicants = Math.ceil(countApplicants / 10);
      onPageChangeApplicants = handlePageClickApplicants;

      pageCountApplicantsShared = Math.ceil(countApplicationsSharedDashboard / RESULTS_PER_PAGE);
      onPageChangeApplicantsShared = handlePageClickApplicantsShared;
  

      pageCountApplicantsSpontaneous = Math.ceil(countApplicationsSpontaneousDashboard / RESULTS_PER_PAGE);
      onPageChangeApplicantsSpontaneous = handlePageClickApplicantsSpontaneous;
  

      pageCountApplicantsRecommendations = Math.ceil(countApplicationsRecommendationsDashboard / RESULTS_PER_PAGE);
      onPageChangeApplicantsRecommendations = handlePageClickApplicantsRecommendations;
  
      pageCountApplicantsForm = Math.ceil(countApplicantsForm / RESULTS_PER_PAGE);
      onPageChangeApplicantsForm = handlePageClickApplicantsDashboardForm;


const Contacts = SortableContainer(({contacts}) => {
  return (
    <div className="row">
      {spinner?
          <Grid container item justify="center" className="top-20" xs={12}><CircularProgress size={50}/></Grid>:
        ApplicationsDashboard.map((contact, index) => (
        <ItemApplicants key={index} index={index}
        matching={contact.matching} date_of_application={contact.date_of_application}
         profile={contact.profile_id} opportunity={contact.opportunity_id}
         datafile={contact.datafile} is_selected={contact.is_selected}
         selected_by={contact.selected_by}
         id={contact.id}
         formapp="formapp"
         ApplicationsDashboard="ApplicationsDashboard"
         />
      ))}
    </div>
  );
});


const ProfileShare = SortableContainer(({profileShare}) => {
  return (
    <div className="row">
      {spinnerApplicationShare?
        <Grid container item justify="center" className="top-20" xs={12}><CircularProgress size={50}/></Grid>:
          applicationsSharedDashboard.map((contact, index) => (
        <ItemApplicants key={index} index={index}
        matching={contact.matching} date_of_application={contact.date_of_application}
        profile={contact.profile_id} organization={contact.organization_id}
        datafile={contact.datafile}
        selected_by={contact.selected_by}
        id={contact.id}
        applicationsSharedDashboard="applicationsSharedDashboard"/>
      ))}
    </div>
  );
});



const ProfileSpontaneous = SortableContainer(({profileSpontaneous}) => {
  return (
    <div className="row">
      {spinnerApplicationSpontaneous?
          <Grid container item justify="center" className="top-20" xs={12}><CircularProgress size={50}/></Grid>:
          ApplicationsSpontaneousDashboard.map((contact, index) => (
        <ItemApplicants key={index} index={index}
        matching={contact.matching} 
        date_of_application={contact.date_of_application}
         profile={contact.profile_id} organization={contact.organization_id}
         datafile={contact.datafile}
         selected_by={contact.selected_by}
         id={contact.id}
         ApplicationsSpontaneousDashboard="ApplicationsSpontaneousDashboard"
         />
      ))}
    </div>
  );
});



const ProfileRecommendations = SortableContainer(({profileRecommendations}) => {
  return (
    <div className="row">
      {spinnerRecommendationsDashboard?
          <Grid container item justify="center" className="top-20" xs={12}><CircularProgress size={50}/></Grid>:
          ApplicationsRecommendationsDashboard.matching.map((contact, index) => (
        <ItemApplicants key={index} index={index}
        matching={contact.matching_score} 
        //date_of_application={contact.date_of_application}
         profile={contact.profile} organization={contact.organization}
         datafile={contact.datafile}
         selected_by={contact.selected_by}
         id={contact.id}
         ApplicationsRecommendationsDashboard="ApplicationsRecommendationsDashboard"
         />
      ))}
    </div>
  );
});




const ProfileFormApp = SortableContainer(({profileFormApp}) => {
  return (
    <div className="row">
      {countApplicantsFormSpinner?
          <Grid container item justify="center" className="top-20" xs={12}><CircularProgress size={50}/></Grid>:
          applicationsDashboardForm.map((appForm, index) => (
        <ItemApplicantForm key={index} index={index}
        appForm={appForm}
        profile={appForm.created_by}
        id={appForm.id}/>
      ))}
    </div>
  );
});

console.log("applicationsDashboardFormSelect " , applicationsDashboardForm)
console.log("ApplicationsSpontaneousDashboardSlect " , ApplicationsSpontaneousDashboard)
console.log("applicationsSharedDashboard " , applicationsSharedDashboard)

  const [contacts, setContacts] = useState(ApplicationsDashboard);
  const [profileShare, setProfileShare] = useState(applicationsSharedDashboard);
  const [profileSpontaneous, setprofileSpontaneous] = useState(ApplicationsSpontaneousDashboard);
  const [profileRecommendations, setprofileRecommendations] = useState(ApplicationsRecommendationsDashboard);
  const [profileFormApp, setProfileFormApp] = useState(applicationsDashboardForm);

  const onSortEnd = ({oldIndex, newIndex}) => {
    setContacts(arrayMove(contacts, oldIndex, newIndex))
  };

  const onSortEndProfileShared = ({oldIndex, newIndex}) => {
    setProfileShare(arrayMove(profileShare, oldIndex, newIndex))
  };

  const onSortEndProfileSpontaneous = ({oldIndex, newIndex}) => {
    setprofileSpontaneous(arrayMove(profileSpontaneous, oldIndex, newIndex))
  };

  const onSortEndProfileRecommendations = ({oldIndex, newIndex}) => {
    setprofileRecommendations(arrayMove(profileRecommendations, oldIndex, newIndex))
  };

  const onSortEndProfileFormApp = ({oldIndex, newIndex}) => {
    setProfileFormApp(arrayMove(profileFormApp, oldIndex, newIndex))
  };

  return (
    
    <div className="animated slideInUpTiny animation-duration-3 dashboard">
     <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label={<IntlMessages id="applicants_count"  values={{count: countApplicants}}/>} {...a11yProps(0)} />
          <Tab label={<IntlMessages id="shared_profiles" values={{count: countApplicationsSharedDashboard}}/>} {...a11yProps(1)} />
          <Tab label={<IntlMessages id="spontaneous_applications" values={{count: countApplicationsSpontaneousDashboard}}/>}{...a11yProps(2)} />
          <Tab label={<IntlMessages id="recommendations" values={{count: countApplicationsRecommendationsDashboard}} />} {...a11yProps(3)} />
          <Tab label={<IntlMessages id="formapp" values={{count: countApplicantsForm}} />} {...a11yProps(4)} />
      
        </Tabs>
      </AppBar>
      {/* <Search url={`/opportunities/applications/pipelined/list/`} action_scope={`GET_PIPELINE_APPLICANTS_LIST`} 
     ApplicationsDashboard={ApplicationsDashboard} keyword={`q`}/> */}
      <TabPanel value={value} index={0}>
          <div className="table-opp row">
            <div className="col-md-4 text-left"><IntlMessages id="first_name"/> & <IntlMessages id="last_name"/></div>
            <div className="col-md-3 text-left margin-left"><IntlMessages id="opportunity" /></div>
            <div className="col-md-2 text-center"><IntlMessages id="score" /></div>
            <div className="col-md-3 text-center"><IntlMessages id="actions" /></div>
          </div>
          <Contacts contacts={contacts} onSortEnd={onSortEnd} useDragHandle={true}/>
              <Grid item md={12} xs={12} container justify="center" className="Listpagination">
                <ReactPaginate
                previousLabel={<IntlMessages id="previous" />}
                nextLabel={<IntlMessages id="next" />}
                pageCount={pageCountApplicants}
                onPageChange={handlePageClickApplicants}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}/>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <div className="table-opp row">
            <div className="col-md-4 text-left"><IntlMessages id="first_name"/> & <IntlMessages id="last_name"/></div>
            <div className="col-md-3 text-left margin-left"><IntlMessages id="opportunity" /></div>
            <div className="col-md-2 text-center"><IntlMessages id="score" /></div>
            <div className="col-md-3 text-center"><IntlMessages id="actions" /></div>
          </div>
          <ProfileShare profileShare={profileShare} onSortEnd={onSortEndProfileShared} useDragHandle={true}/>
              <Grid item md={12} xs={12} container justify="center" className="Listpagination">
                <ReactPaginate
                previousLabel={<IntlMessages id="previous" />}
                nextLabel={<IntlMessages id="next" />}
                pageCount={pageCountApplicantsShared}
                onPageChange={handlePageClickApplicantsShared}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}/>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <div className="table-opp row">
            <div className="col-md-4 text-left"><IntlMessages id="first_name"/> & <IntlMessages id="last_name"/></div>
            <div className="col-md-3 text-left margin-left"><IntlMessages id="organization" /></div>
            <div className="col-md-2 text-center"><IntlMessages id="score" /></div>
            <div className="col-md-3 text-center"><IntlMessages id="actions" /></div>
          </div>
          <ProfileSpontaneous profileSpontaneous={profileSpontaneous} onSortEnd={onSortEndProfileSpontaneous} useDragHandle={true}/>
              <Grid item md={12} xs={12} container justify="center" className="Listpagination">
                <ReactPaginate
                previousLabel={<IntlMessages id="previous" />}
                nextLabel={<IntlMessages id="next" />}
                pageCount={pageCountApplicantsSpontaneous}
                onPageChange={handlePageClickApplicantsSpontaneous}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}/>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={3}>
      <div className="table-opp row">
            <div className="col-md-4 text-left"><IntlMessages id="first_name"/> & <IntlMessages id="last_name"/></div>
            <div className="col-md-3 text-left margin-left"><IntlMessages id="opportunity" /></div>
            <div className="col-md-2 text-center"><IntlMessages id="score" /></div>
            <div className="col-md-3 text-center"><IntlMessages id="actions" /></div>
          </div>
          <ProfileRecommendations profileRecommendations={profileRecommendations} onSortEnd={onSortEndProfileRecommendations} useDragHandle={true}/>
              <Grid item md={12} xs={12} container justify="center" className="Listpagination">
                <ReactPaginate
                previousLabel={<IntlMessages id="previous" />}
                nextLabel={<IntlMessages id="next" />}
                pageCount={pageCountApplicantsSpontaneous}
                onPageChange={handlePageClickApplicantsSpontaneous}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}/>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={4}>
      <div className="table-opp row">
            <div className="col-md-6 text-left"><IntlMessages id="first_name"/> & <IntlMessages id="last_name"/></div>
            <div className="col-md-2 text-center"><IntlMessages id="application_date" /></div>
            <div className="col-md-2 text-center"><IntlMessages id="actions" /></div>
          </div>
          <ProfileFormApp profileFormApp={profileFormApp} onSortEnd={onSortEndProfileFormApp} useDragHandle={true}/>
              <Grid item md={12} xs={12} container justify="center" className="Listpagination">
                <ReactPaginate
                previousLabel={<IntlMessages id="previous" />}
                nextLabel={<IntlMessages id="next" />}
                pageCount={pageCountApplicantsForm}
                onPageChange={handlePageClickApplicantsDashboardForm}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}/>
        </Grid>
      </TabPanel>
    </div>

    </div>
  );
};
export default  (withTranslation('translation')(SelectedDashboard));