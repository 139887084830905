const initialState = {
    upload_cv:{
        error:null,
        spinner:false,
        message:null
    },
    upload_cv_opp:{
    error:null,
    spinner:false,
    message:null
    }
 }

 export function uploadCv(state = initialState, action){
 
   switch(action.type) {
   case "HANDLE_UPLOAD_CV":
           return {...state, upload_cv:{
               ...state.upload_cv, error:null, spinner:true, message:null
           }}
   case "UPLOAD_CV_FAILLURE":
           return {...state, upload_cv:{
               ...state.upload_cv, error:action.payload, spinner:false, message:null
           }}
   case "UPLOAD_CV":
      return {...state, upload_cv:{
          ...state.upload_cv, error:null, spinner:false, message:action.payload
      }}
   case "REMOVE_UPLOAD_CV_MESSAGES":
       return{
           ...state, upload_cv:{
               ...state.upload_cv, error:null, spinner:false, message:null
           }
       }

    case "HANDLE_UPLOAD_CV_OPP":
           return {...state, upload_cv_opp:{
               ...state.upload_cv_opp, error:null, spinner:true, message:null
           }}
   case "UPLOAD_CV_FAILLURE_OPP":
           return {...state, upload_cv_opp:{
               ...state.upload_cv_opp, error:action.payload, spinner:false, message:null
           }}
   case "UPLOAD_CV_OPP":
      return {...state, upload_cv_opp:{
          ...state.upload_cv_opp, error:null, spinner:false, message:action.payload
      }}
   case "REMOVE_UPLOAD_CV_MESSAGES_OPP":
       return{
           ...state, upload_cv_opp:{
               ...state.upload_cv_opp, error:null, spinner:false, message:null
           }
       }
     default:
       return state;
   }
 }
