export function handleContractTypeSearch(data){
    return {
        type:"HANDLE_GET_CONTRACT_TYPE_SEARCH",
        payload:data
    }
}
export function handleSetSearchContractType(data){
    return {
        type:"GET_CONTRACT_TYPE_SEARCH",
        payload:data
    }
}

export function handleSetSearchContractTypeError(data){
    return{
        type:"GET_CONTRACT_TYPE_SEARCH_FAILLURE",
        payload:data
    }
}