import React,{useEffect} from 'react';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import { cleanStringQuery } from 'util/UtilFunctions';
import {useDispatch,useSelector} from 'react-redux'
import { handleAdminLocationsSearch } from 'actions/filters/locations';
import IntlMessages from "util/IntlMessages";
  
function Locations(props) {
  const dispatch = useDispatch();
  const locations = useSelector(state=>state.locations.search_admin_locations.locations);

  const handleSearchlocation=(data)=>{
    dispatch(handleAdminLocationsSearch({scope:"countries", search:`?country=${data.currentTarget.value}`}))
  }

  useEffect(()=>{
      dispatch(handleAdminLocationsSearch({scope: "countries", search:`${cleanStringQuery(window.location.search)?`?${cleanStringQuery(window.location.search)}`:''}`}))
  },[dispatch])

  const onChecked=(value)=>{
    if(props.keys.filter(obj=>obj.name===value).length!==0){
        props.delete({name:value, type:'country'})
    }else{
        props.addItem({name:value, type:'country'})
    }
   }
    return (
        <FormControl className="filter">
          <InputLabel htmlFor="select-multiple-chip">{`Locations`}</InputLabel>
          <Select
            label={<IntlMessages id="locations"/>}
            multiple
            value={[]}>
            <div className="search">
            <Input  className="filter" name="search" type="search" placeholder="Search" autocomplete="off" onChange={(data)=>handleSearchlocation(data)}/>
            </div>
            {locations.map((name,key) => (
              <MenuItem key={key} value={name.country_id__name}>
                <Checkbox checked={props.keys.filter(obj=>obj.name===name.country_id__name).length!==0} onClick={()=>onChecked(name.country_id__name)}/>
                <ListItemText primary={`${name.country_id__name}(${name.total})`} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
     
    );
}

export default Locations;
