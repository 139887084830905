/***
 * Get list connexions profil
 */
export function handleGetConnexionsDashboard(data){
    return{
        type:"HANDLE_GET_CONNEXIONS_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetConnexionsDashboardSuccess(data){
    console.log(data,"GET_CONNEXIONS_DASHBOARD")
    return{
        type:"GET_CONNEXIONS_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetConnexionsDashboardError(data){
    return{
        type:"GET_CONNEXIONS_DASHBOARD_FAILLURE",
        payload:data
    }
}



//............... Select profile pipeline....


export function HandleSelectPipelineProfile(data){
    return{
        type:"HANDLE_SELECT_PIPELINE_PROFILE",
        data:data
    }
}
export function SelectPipelineProfileSuccess(data){
    return{
        type:"SELECT_PIPELINE_PROFILE_SUCCESS",
        payload:data
    }
}
export function SelectPipelineProfileError(data){
    return{
        type:"SELECT_PIPELINE_PROFILE_FAILLURE",
        payload:data
    }
}
export function SelectPipelineProfileResetMessages(){
    return{
        type:"SELECT_PIPELINE_PROFILE_RESET_MESSAGES"
    }
}
/***
 * Get list apply opportunities profil
 */
export function handleGetOpportunitiesApplyDashboard(data){
    return{
        type:"HANDLE_GET_OPPORTUNITIES_APPLY_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetOpportunitiesApplyDashboardSuccess(data){
    console.log(data,"GET_OPPORTUNITIES_APPLY_DASHBOARD")
    return{
        type:"GET_OPPORTUNITIES_APPLY_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetOpportunitiesApplyDashboardError(data){
    return{
        type:"GET_OPPORTUNITIES_APPLY_DASHBOARD_FAILLURE",
        payload:data
    }
}



/***
 * Get list apply spontaneous profil
 */
export function handleGetSpontaneousApplyDashboard(data){
    return{
        type:"HANDLE_GET_SPONTANEOUS_APPLY_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetSpontaneousApplyDashboardSuccess(data){
    console.log(data,"GET_SPONTANEOUS_APPLY_DASHBOARD")
    return{
        type:"GET_SPONTANEOUS_APPLY_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetSpontaneousApplyDashboardError(data){
    return{
        type:"GET_SPONTANEOUS_APPLY_DASHBOARD_FAILLURE",
        payload:data
    }
}







/***
 * Get list apply spontaneous profil
 */
export function handleGetSpontaneousApplyDashboardPipeline(data){
    return{
        type:"HANDLE_GET_SPONTANEOUS_APPLY_DASHBOARD_PIPELINE",
        data:data
    }
}
//Backend response data
export function GetSpontaneousApplyDashboardPipelineSuccess(data){
    console.log(data,"GET_SPONTANEOUS_APPLY_PIPELINE_DASHBOARD")
    return{
        type:"GET_SPONTANEOUS_APPLY_DASHBOARD_PIPELINE",
        payload:data
    }
}
//  Backend Error message
export function GetSpontaneousApplyDashboardPipelineError(data){
    return{
        type:"GET_SPONTANEOUS_APPLY_DASHBOARD_PIPELINE_FAILLURE",
        payload:data
    }
}




/***
 * Get search appearence profil
 */
export function handleGetSearchesAppearenceDashboard(data){
    return{
        type:"HANDLE_GET_SEARCH_APPEARENCE_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetSearchesAppearenceDashboardSuccess(data){
    console.log(data,"GET_SEARCH_APPEARENCE_DASHBOARD")
    return{
        type:"GET_SEARCH_APPEARENCE_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetSearchesAppearenceDashboardError(data){
    return{
        type:"GET_SEARCH_APPEARENCE_DASHBOARD_FAILLURE",
        payload:data
    }
}





/***
 * Get view profil
 */
export function handleGetViewProfilDashboard(data){
    return{
        type:"HANDLE_GET_VIEW_PROFIL_DASHBOARD",
        data:data
    }
}
//Backend response data
export function GetViewProfilDashboardSuccess(data){
    console.log(data,"GET_VIEW_PROFIL_DASHBOARD")
    return{
        type:"GET_VIEW_PROFIL_DASHBOARD",
        payload:data
    }
}
//  Backend Error message
export function GetViewProfilDashboardError(data){
    return{
        type:"GET_VIEW_PROFIL_DASHBOARD_FAILLURE",
        payload:data
    }
}




