export function renderChoices(data){
   var choices='';
   Object.keys(data).forEach((item)=>{
        choices=choices+data[item]+"[(%";
   });
   return choices.trim().slice(0, -1).slice(0, -1).slice(0, -1)
}

export function  getFormStructToPreview(formData){
    return Object.keys(formData.fields).map((fieldId,key)=>{
        return {
          id:fieldId,
          field_type:formData.fieldsType[parseInt(formData.fields[fieldId].field_type)],
          label:formData.fields[fieldId].label,
          order:key,
          style_class:formData.fields[fieldId].style_class,
          description:formData.fields[fieldId].description,
          is_visible:formData.fields[fieldId].is_visible,
          help_text:formData.fields[fieldId].help_text,
          required:formData.fields[fieldId].required,
          'placeholder_text':formData.fields[fieldId].placeholder,
          choices:formData.fields[fieldId].choices?renderChoices(formData.fields[fieldId].choices):""
        }
    });
  }

