import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Link} from "react-router-dom";
import {Animated} from "react-animated-css";
import Button from '@material-ui/core/Button';
import IntlMessages from "util/IntlMessages";
import { FollowProfileError, FollowProfileSuccess, HandleFollowProfile } from 'actions/profile';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useDispatch, useSelector} from 'react-redux'
import { handleProfilesSearch } from 'actions/search';
import {Alert} from '@material-ui/lab';
import { store } from 'react-notifications-component';
import ReactNotification from 'react-notifications-component'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Notif from 'components/uielements/Notification';
import AlertUser from 'components/uielements/AlertUser';
const useStyles = makeStyles((theme) => ({
  ul:{
    'list-style-type': 'none',
    paddingLeft: 0,

  },
  btn:{
    position:'absolute',
    left:'42%',
    backgroundColor:"#fdc000",
    '&:hover':{
      backgroundColor:"#fdc000",
    }
  },
 cardActions:{
      paddingLeft:15,
      paddingRight:15
  },
  avatar:{
    width: 50,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius:'0%',
    marginLeft:'-15px',
  }, 
  flexEnd:{
      paddingLeft:'28%',
  },
  icon:{
      width:15,
      height:15
  },
  icons:{
    width:15,
    height:15,
    border:"solid 1px #c1c1c1"
  },
  link:{
    textDecoration:'none',
    color:'#4285f4',
    '&:hover':{
      textDecoration:'none'
    },isloadingOpp:{
      opacity:0.2,
      pointerEvents: 'none',
    },
  
}
}));
export default function Profil(props) {
  const index =props.index

  const classes = useStyles();
  const data =props.data
  const message =useSelector(state=>state.profile.followProfile.message)
  const error =useSelector(state=>state.profile.followProfile.error)
  const [open, setOpen]=React.useState(false)
  const [openAlert, setOpenAlert]=React.useState(false)
  const dispatch = useDispatch()
  const status =useSelector(state=>state.profile.followProfile.status)
  const Data={
    user_id:data.profile_id
  }

  const followProfile=()=>{
    dispatch(HandleFollowProfile(Data))
    setOpen(true)
  }
  const handleClose=()=>{
    setOpen(false)
    //dispatch(FollowProfileError())

  //  dispatch(handleProfilesSearch({scope:"profiles", search:`${window.location.search}`, page:`1`}))
   
    }
    const handleCloseAlert=()=>{
      setOpenAlert(false)
      }

    const AlertProfile=()=>{
      setOpenAlert(true)
    }


const loader =  <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />
console.log('Error following' ,error);

  return (
 <>
  <Notif open={open} title={<IntlMessages id="notification"/>} text={
        error?
        error :
        message?message:null}
        notification="notification"
        error={error? error : null}
        message={message ? "message" : null}
        handleClose={handleClose}/>

<AlertUser open={openAlert} title={<IntlMessages id="notification"/>} 
        handleClose={handleCloseAlert} followProfile={followProfile} />

    <Animated animationIn="fadeInUp" animationOut="fadeOut" key={index}>
 
       <Card className={`card-profile`}>
            <CardActions className={classes.cardActions}>
            <Grid container className="profiles">
            <Grid item xs={12}>
              
          { props.is_user_recruiter?
              <Link to={`/profiles/${data.slug}`} className="title-opportunity text-left">{data.first_name} {data.last_name}</Link>
                :
          data.is_connected === "true"?
              <Link to={`/profiles/${data.slug}`} className="title-opportunity text-left">{data.first_name} {data.last_name}</Link>
                :
                data.is_connected === "pending"?
              <Link onClick={followProfile} className="title-opportunity text-left">{data.first_name} {data.last_name}</Link>
                :
             
             <Link className="title-opportunity text-left" onClick={AlertProfile}>{data.first_name} {data.last_name}</Link>
              }
            </Grid>    
            <Grid xs={12} md={12} container >
                {/* <div className="col-md-12 padding-0 block-profil">
                <span className="title">{data.title}</span>
                </div> */}
                {/* <div className="col-md-6 text-right  padding-0 block-opp">
                <span>{data.publication_date}</span>
                </div> */}
                </Grid>


                <Grid item xs={2}>
                <ListItemAvatar> 
                    <Avatar alt="Travis Howard" src={data.photo}  className={classes.avatar}/>
                    </ListItemAvatar>
                        </Grid>

                <Grid item xs={10}>
                 <span className="current_position">{data.title.length > 35 ? data.title.substring(0,35)+'...' : data.title }</span><br/>
                <span className="profile-location">{data.country_name} {data.city_name ?  " - "+data.city_name  : null}</span>
                
                </Grid>

                <Grid xs={12} md={12} container className="footer-bloc-profile">
                <div className="col-md-6 padding-0 shared-connections">
                {data.mutual_friend + " shared connections"} 
                </div>
                <div className="col-md-6 text-right  padding-0">
                {props.is_user_recruiter ?
                   <Link to={`/profiles/${data.slug}`}> <Button className="btn-profile text-right view-profile" ><IntlMessages id="view_profile" /> </Button></Link>
                    :
                data.is_connected ==="pending" ?
                <Button className="btn-profile text-right pending" onClick={followProfile}><IntlMessages id="pending" /> </Button>
                  :
                  data.is_connected === "false" ? 
                  <div>
                  <Button key={index} className="btn-profile text-right connect" onClick={followProfile}>
                  <IntlMessages id="connect" />
                  </Button>
                 {/* {status === "creating"
                  loader : null}  */}
                  </div>
                   
                  : 
                  <Link to={`/profiles/${data.slug}`}> <Button className="btn-profile text-right view-profile" ><IntlMessages id="view_profile" /> </Button></Link>
                }
                </div>
                </Grid>
                {/* <Grid item xs={12} className="btn-profile text-right"><Button>Voir Profil</Button></Grid> */}
                {/*                 
                <Grid className="date-publication" item xs={12} container justify="flex-start"><AccessTimeIcon  className={classes.icon}/>{data.is_connected ? null : "Connecter" }</Grid>
                 */}
                </Grid>
            </CardActions>
        </Card>
                
        </Animated>
        </>
  );
}

Profil.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
