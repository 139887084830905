
import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import {createMuiTheme} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import  {useDispatch,useSelector} from 'react-redux';
import Widget from 'components/Widget';
import oia from '../../assets/imgs/oia.png';
import IntlMessages from "util/IntlMessages";

const useStyles = makeStyles((theme) => ({
    cont:{
      paddingTop:30,
      paddingBottom:30,
      zIndex:1,
      background:"#9b9b9b"
    },
    title:{
      paddingBottom:15,
      color:"#fff",
      fontWeight:'bold'
    },
    content:{
        paddingLeft:24,
        paddingRight:24,
        marginTop:15,
    },
    fieldset:{
        margin:0,
        padding:10,
        border:'solid 2px black',
      },
      legend:{
        display: 'block',
        fontSize: 15,
        width:'auto'
      },
    textAlignEnd:{
      textAlign:'end'
    },
    textAlignStart:{
      textAlign:'start'
    },
    textAlignCenter:{
      textAlign:'center'
    },
    margin:{
      margin:'7px !important'
    },
    icon:{
      width:70,
      height: 84,
    },
    upload:{
      backgroundColor:"#1269ea",
      color:"#fff"
    },
    isloadingOpp:{
      opacity:0.2,
      pointerEvents: 'none',
    },
    saved:{
      color:"green"
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '30%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    input: {
      display: 'block',
    },
    footer: {
      backgroundColor: "#fff",
      // marginTop:15,
      padding:15,
      paddingLeft:24,
      paddingRight:24,
      position: 'relative',
      width:'100%',
    },
  cardActions:{
      paddingLeft:15,
      paddingRight:15,
  },
  avatar:{
    width: 100,
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius:'0%',
    marginLeft:'-15px',
    marginRight:12,
    backgroundColor:'white'
  }, 
  cards:{
    backgroundColor:"#fff",
    marginBottom:"15px",
    [theme.breakpoints.down('md')]:{
      height:"60vw",
  }
  },
  card2:{
    backgroundColor:"#f1f1f1",
    marginBottom:"15px",
    [theme.breakpoints.down('md')]:{
      height:"60vw",
  }
  },
  card1:{
    backgroundColor:"#fff",
    marginBottom:"15px",
    [theme.breakpoints.down('md')]:{
      height:"60vw",
  }
  },
  btn:{
    color:'#2196f3'
  }
  }));
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#fdc000",
        backgroundColor: green[500],
      },
      secondary: {
        main: "#ff9801",
      },  
    },
  });


const SidebarRight = () => {
    const classes = useStyles();
    const dispatch =useDispatch();
    const last_updates =useSelector(state=>state.lastUpdates.last_updates)
    const card_1=last_updates.list[0];
    const card_2=last_updates.list[1];
    console.log("last_updates" ,last_updates)
    const card_3=last_updates.list[2];
    useEffect(()=>{
        dispatch({type:"HANDLE_GET_LAST_UPDATES", payload:{}})
    },[dispatch])


    return (
        <div className="">
            <div className="sidebar">
              <Grid>
                
        <Grid item md={12} xs={12}>
        <Grid item md={12} xs={12} className="card_3">
            <Widget styleName="jr-widget-bg jr-blue-cyan-gradient">
              <span className="jr-widget-badge"></span>
               <img
                className="rounded-xl w-100"
                alt="..."
                src={card_3?card_3.image:"https://cdn.wutiko.com/v3/preprod/medias/activities/latest-updates/images/b137acd7-7b4e-48d4-b5ba-e44c3e892c24.png"}
              />
            </Widget>
        </Grid>
      </Grid>


      <Grid item md={12} xs={12} className="card_2">
       
       <h1>{card_2?card_2.title:null}</h1>
       <p>   <img src={card_2?card_2.image:null}  />
      {card_2?card_2.content:null} </p>
      <Button className={classes.btn}>{<IntlMessages id="read_more"/>}</Button>

      </Grid>
       
        <Grid item md={12} xs={12} className="card_1">
       
                  
                    <h1>{card_1?card_1.title:null}</h1>
                    <p> 
                     <img src={card_1?card_1.image:null}  />
                     {card_1?card_1.content:null} </p>
                
                  <Button className={classes.btn}>{<IntlMessages id="read_more"/>}</Button>
              
        </Grid>
       

        </Grid>
       
             
              {/* <div className="social-medias">
              <h2 className="title">Social medias buttons</h2>
              <div className={`jr-btn-group ${classes.textAlignCenter}`}>
                <Button className={`jr-fab-btn bg-indigo lighten-1 text-white ${classes.margin}`}>
                    <i className="zmdi zmdi-facebook zmdi-hc-lg" />
                  </Button>

                  <Button className={`jr-fab-btn bg-light-blue accent-2 text-white ${classes.margin}`}>
                    <i className="zmdi zmdi-twitter zmdi-hc-lg" />
                  </Button>

                  <Button className={`jr-fab-btn bg-red lighten-1 text-white ${classes.margin}`}>
                    <i className="zmdi zmdi-google zmdi-hc-lg" />
                  </Button>
                  <Button className={`jr-fab-btn bg-light-blue darken-3 text-white ${classes.margin}`}>
                    <i className="zmdi zmdi-linkedin zmdi-hc-lg" />
                  </Button>
                </div>
              </div> */}
            </div>
          </div>
    )
}

export default SidebarRight;





