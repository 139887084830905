/**
 * This file contains redux actions and actions creators handling the sesssion of the user.
 */
// // import axios from 'axios'; 
// import  axios from "axios";
// import {  getHashUrlParameterByName } from '../util/UtilFunctions';
// import { CLIENT_ID } from "config";
// import { DEFAULT_REQUEST_TIMEOUT } from "config";
// import { GET_TOKEN_URL } from "config";
// import { API_BASE_URL } from "config";

// logout equivalent

//import {  useDispatch} from 'react-redux'
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const CREATE_BLANK_AXIOS_INSTANCE = 'CREATE_BLANK_AXIOS_INSTANCE';
export const CREATE_AXIOS_INSTANCE = 'CREATE_AXIOS_INSTANCE';
export const RESET_AXIOS_INSTANCE = 'RESET_AXIOS_INSTANCE';
//export const dispatch = useDispatch()

export function resetUserSession(callbackAfter){
    console.log("Reset Token")
    setTokenToStorage(null);
    setUserIdToStorage(null);
    //dispatch({ type: 'RESET_AXIOS_INSTANCE' })
   // dispatch({ type: 'LOGOUT' })
     //console.log("User Session Reset !")
    if(callbackAfter) {
      callbackAfter();
    }
    return {
      type: "RESET_USER_SESSION",
    };
}

export function getTokenFromStorage(){

  let token;

  try {
    token = JSON.parse(localStorage.getItem('sessionToken')) || undefined;
    console.log("getTokenFromStorage : ", token);
  } catch (e) {
    console.log("Unvalid stored Token : ", e);
  } finally {

    if(!token || token === '') {//if there is no token, dont bother
      return null;
    }
  }

  return token;
}

export function setTokenToStorage(token) {

  try {
    if (token) {
      localStorage.setItem('sessionToken', JSON.stringify(token));
    } else {
      localStorage.removeItem('sessionToken');
    }
  } catch(e) {
    console.log("Unable to access local storage : ", e);
  }
}

export function getUserIdFromStorage(){

  let userId;

  try {
    userId = JSON.parse(localStorage.getItem('sessionUserId')) || undefined;
    console.log("getUserIdFromStorage : ", userId);
  } catch (e){
    console.log("Unvalid stored userId : ", e);
  } finally {

    if(!userId || userId === '') {//if there is no token, dont bother
      return null;
    }
  }

  return userId;
}

export function setUserIdToStorage(userId) {

  try {
    if (userId) {
      localStorage.setItem('sessionUserId', JSON.stringify(userId));
    } else {
      localStorage.removeItem('sessionUserId');
    }
  } catch(e) {
    console.log("Unable to access local storage : ", e);
  }
}

export const SetUserSettings = (settings) => {
  return {
    type: "SET_USER_SETTINGS",
    payload: settings
  };
};

