export function splitStringQuery(STRING_QUERY){
    const RESPONSE=Object()
    if(STRING_QUERY.indexOf("&")!==-1){
        const STRINGS= STRING_QUERY.replace('?','').split('&');
        for (var I = 0; I < STRINGS.length ; I++){
            RESPONSE[STRINGS[I].split('=')[0]]=STRINGS[I].split('=')[1];
    }
    }else{
        RESPONSE[STRING_QUERY.replace('?','').split('=')[0]]= STRING_QUERY.replace('?','').split('=')[1];
    }
  return RESPONSE
}
export function constructLocationSearch(STRING_QUERY){
    var RESPONSE="";
    if(!STRING_QUERY){
        RESPONSE="";
    }else{
        RESPONSE=`?${STRING_QUERY.substring(1, STRING_QUERY.length)}`;
    }
  return RESPONSE
}

export function splitStringQueryAddState(STRING_QUERY){
    var RESP=[];
    if(STRING_QUERY.indexOf("&")!==-1){
        const STRINGS= STRING_QUERY.replace('?','').split('&');
        for (var I = 0; I < STRINGS.length ; I++){
            if(STRINGS[I].split('=')[1]){
                RESP.push({type:STRINGS[I].split('=')[0],name:decodeURIComponent(STRINGS[I].split('=')[1].split('%20').join(' '))}) 
            }

        }
    }else{
        if(STRING_QUERY.replace('?','').split('=')[1]){
            RESP.push({type:STRING_QUERY.replace('?','').split('=')[0],name:decodeURIComponent(STRING_QUERY.replace('?','').split('=')[1].split('%20').join(' '))})
        }

    }
  return RESP
}
export function cleanStringQuery(STRING_QUERY){
    var RESP="";
    if(STRING_QUERY.indexOf("&")!==-1){
        const STRINGS= STRING_QUERY.replace('?','').split('&');
        for (var I = 0; I < STRINGS.length ; I++){
            if(STRINGS[I].split('=')[1]){
                if(!RESP){
                    RESP+=`${STRINGS[I].split('=')[0]}=${STRINGS[I].split('=')[1]}`; 
                }else{
                    RESP+=`&${STRINGS[I].split('=')[0]}=${STRINGS[I].split('=')[1]}`
                }

            }

        }
    }else{
        if(STRING_QUERY.replace('?','').split('=')[1]){
            if(!RESP){
                RESP+=`${STRING_QUERY.replace('?','').split('=')[0]}=${STRING_QUERY.replace('?','').split('=')[1]}`
            }else{
               RESP+=`&${STRING_QUERY.replace('?','').split('=')[0]}=${STRING_QUERY.replace('?','').split('=')[1]}}`; 
            }
        }
    }
  return RESP
}
export function page(Item){
    try{
        var search=window.location.search;
        var data_split=search.split(Item);
        return parseInt(data_split[1].split("&")[0]);
    }catch(error) {
         return 1
    }
}
export function splitStringQuerywithoutPageParam(URL){
    var data = splitStringQuery(URL);
    delete data['page']
    return data
}
/**
* This function allows us to modify the number of the page in the url when the user handles the pagination
 * The content of the try runs when the url already points to a page
 * And the cach handle the first case
 * */
export function ChangePage(N){
    try{
            var first_part=window.location.search.split('page=')[0];
            var second_part=window.location.search.split('page=')[1].split('&')[1]
            return `${first_part}page=${N}${second_part?`&${second_part}`:""}`;
    }catch(error){
        if(window.location.search){
           return `${window.location.search}&page=${N}`
        }else{
            return `?page=${N}`;
        }
    }
}

    // var url =new URL(window.location.href);
    // var key =url.searchParams.get('keywords');
    // var whe =url.searchParams.get('where');
    // var data=this.state.keys;
    // if(key){data.push({type:'keywords',name:key})}
    // if(whe){data.push({type:'where',name:whe})}
    // this.setState({keys:data}); 