import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { getProfilePublicationSuccess, getProfilePublicationError } from  "actions/profile";

const GetProfilePublicationRequest = async (instance)=>
    await instance.get(`/profiles/publications/list/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetProfilePublication(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetProfilePublicationRequest,axiosInstance );
    if(data.data){
        yield put(getProfilePublicationSuccess(data.data));
    }else{
        yield put(getProfilePublicationError(data.error));
    }
    
}
export function* handleGetProfilePublication(){
    console.log("PublicationList" , "Publication")
    yield takeEvery("HANDLE_GET_PROFILE_PUBLICATION", GetProfilePublication)
}
export default function* rootSaga() {
    yield all([fork(handleGetProfilePublication)]);
}
//