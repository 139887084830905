
const initialState = {
    filter_matchingscore:{
        error:null,
        spinner:false,
        count:null,
        matchingscore:[]
    },

 }

 export function matchingscoreFilter(state = initialState, action){
 
   switch(action.type) {

    case "GET_MATCHING_SCORE_FILTER_FAILLURE":
            return {...state, filter_matchingscore:{
                ...state.filter_matchingscore, error:action.payload, spinner:false, matchingscore:[]
            }}
   case "GET_MATCHING_SCORE_FILTER":
           return {...state, filter_matchingscore:{
               ...state.filter_matchingscore, error:null, spinner:false, matchingscore:action.payload.results
           }}
     default:
       return state;
   }
 }
