import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { NEWS_LETTER_ID } from "config";
import { submibNewLetterData,submibNewLetterError} from "actions/newsLetter";

const setNewLetterData = async (instance,actions)=>
    await instance.post(`/profiles/newsletters/${NEWS_LETTER_ID}/add/`,actions)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            return error.response.data
        }
    })

function* setNewLetterList(actions){
    const axiosInstance = (state)=>state.users.blankAxiosInstance
    const instance =yield select(axiosInstance);
    const data     =yield call(setNewLetterData,instance,actions.payload);
    if(data.message){
            yield put(submibNewLetterData(data.message));
    }else{
        yield put(submibNewLetterError(data.error));
    }
    
}
export function* setNewLetter(){
    yield takeEvery("HANDLE_SET_NEWS_LETTER", setNewLetterList)
}
export default function* rootSaga() {
    yield all([fork(setNewLetter)]);
}