// eslint-disable import / first
import {useHistory} from 'react-router-dom';  
import React,{useEffect,Fragment, useState} from 'react';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { LocationSearchInput } from 'components/formsElements/FieldsComponents';
import DateFnsUtils from '@date-io/date-fns';
import { Col, Form,  Row, Button} from 'react-bootstrap';
import {renderDateTime, RichTextMarkdown, CheckboxGroup, renderSelectField,renderSubmitGroup, renderRadioGroup,renderTextField,
    renderPlaceAutocomplete, renderAsyncAutocomplete, renderFieldWithButton,renderRadioGroupTest, renderCheckbox} from '../components/formsElements/FormComponents';
import {  EditProfileResetMessages, handleEditProfile } from '../actions/profile';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from 'react-redux';
import Dialog2 from '@material-ui/core/Dialog';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { handleGetProfileDetails } from '../actions/profile';
import TextField from '@material-ui/core/TextField';
import IntlMessages from "util/IntlMessages";
import {Alert} from '@material-ui/lab';
import NativeSelect from '@material-ui/core/NativeSelect';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from "@date-io/moment";
import { EditCompanyResetMessages, handleEditCompany, handleGetEditCompanyDetails } from 'actions/companies/editCompany';
import CircularProgress from '@material-ui/core/CircularProgress';
import { addOpportunityDraftResetMessages, addOpportunityResetMessages, EditOpportunityResetMessages, handleAddOpportunity, handleAddOpportunityDraft, handleEditOpportunity,handleEditSaveOpportunity, handleGetEditOpportunityDetails } from 'actions/opportunities';
import RichTextEditor from 'react-rte';
import { handleSearchSkills, handleSearchCompany, handleSearchEducation, handleSearchIndustries, 
handleSearchLangue, handleSearchPosition, handleSearchArea, handleSearchOccupations, 
handleSearchContractType, handleSearchCategories, handleSearchBenefits, handleSearchCompanyAdmin } from 'actions/search';

//const browserHistory = useHistory();
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


const AddOpportunity  = props => {
    const { handleSubmit,  submitError,serverError, load, pristine, reset, submitting } = props
 const {
   slug,
   t} = props;

 const workHour=[
    {name: t("wutiko.full_time"), value:1},
    {name: t("wutiko.part_time"), value:2},
    {name:t("wutiko.fixed_hours"), value:3},
    {name:t("wutiko.flexible"), value:4},
  ]
  const NumberYearsOfExperience=[
    {name:t("wutiko.not_necessary"), value:0},
    {name:'1', value:1},
    {name:'2', value:2},
    {name:'3', value:3},
    {name:'4', value:4},
    {name:'5', value:5},
    {name:'6', value:6},
    {name:'7', value:7},
    {name:'8', value:8},
    {name:'9', value:9},
    {name:'10', value:10},
    {name:'11', value:11},
  ]
  const companySize=[
    {name:'< 250', value:1},
    {name:'< 50', value:2},
    {name:'< 10', value:3},
  ]

 const initState = { 
    title: "",
     about: "",
      description: "",
     responsibilities: "",
     qualifications: "",
      amount_min : "",
      amount_max : ""

 }

  const classes = useStyles();
  const [state, setState] = useState(initState);
  const Sectors = useSelector(state=>state.search.industries.list)
  const SectorsData = Sectors ? Sectors : null
  const skills = useSelector(state=>state.search.skills.list)
  const skillsData = skills ? skills : []
  const [tags, setTags] = useState([])
  const spinnerDraft = useSelector(state=>state.opportunities.add_opp_ano_draft.spinner)
  const [tagsAreas, setTagsAreas] = useState([]);
  const [tagsPosition, setTagsPosition] = useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [tagsEntreprise, setTagsEntreprise] = useState([]);
  const [tagsEducation, setTagsEducation] = useState([]);
  const [tagsCompany, setTagsCompany] = useState([]);
  const [tagsSectors, setTagsSectors] = useState([]);
  const [tagsLangue, setTagsLangue] = useState([]);
  const spinner = useSelector(state=>state.opportunities.add_opp_ano.spinner)
  const error = useSelector(state=>state.opportunities.add_opp_ano.error)
  const message = useSelector(state=>state.opportunities.add_opp_ano.message)
  const errorDraft = useSelector(state=>state.opportunities.add_opp_ano_draft.error)
  const messageDraft = useSelector(state=>state.opportunities.add_opp_ano_draft.message)
  const positionsData = useSelector(state=>state.search.positions.list)
  const companiesData = useSelector(state=>state.search.companiesAdmin.list);
  const educationData = useSelector(state=>state.search.educations.list)
  const occupationsData = useSelector(state=>state.search.occupations.list);
  const categoriesData = useSelector(state=>state.search.categories.list);
  const contractTypeData = useSelector(state=>state.search.contractType.list);
  const langue = useSelector(state=>state.search.langue.list)
  const [locations, setLocations]= useState([]);
  const positions = useSelector(state=>state.search.positions.list)
  const [inputValueSectors, setInputValueSectors] = React.useState('');
  const [inputValueContractType, setInputValueContractType] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const Areas = useSelector(state=>state.search.area.list)
  const AreasData = Areas ? Areas : null
  console.log("positions", positions? positions : null);
  const [selectedDate, setSelectedDate] = React.useState()
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(options[0]);
  const [organization_size, setOrganization_size] = React.useState();
  const [amount_min, setAmount_min] = React.useState();
  const [amount_max, setAmount_max] = React.useState();
  const [level_of_experience, setLevel_of_experience] = React.useState();
 const [number_years_of_experience, setNumber_years_of_experience] = React.useState();
 const [work_hours, setWork_hours] = React.useState();
 const [description, setDescription] = React.useState();
 const [about, setAbout] = React.useState();
 const [responsibilities, setResponsabilities] = React.useState();
 const [qualifications, setQualifications] = React.useState();
 const [inputValueCompany, setInputValueCompany] = React.useState();
 const [inputValueSkills, setInputValueSkills] = React.useState();
 const [inputValueAreas, setInputValueAreas] = React.useState('');
 const [tagsSkills, setTagsSkills] = useState([]);
 const [tagsOccupations, setTagsOccupations] = useState([]);
 const [tagsBenefits, setTagsBenefits] = useState([]);
 const [tagsCategories, setTagsCategories] = useState([]);
 const [tagsContractType, setTagsContractType] = useState([]);
 

 const handleSearchChangeAreas=(event)=>{
  dispatch(handleSearchArea({q:event.target.value}))
}
 const handleSelectSkills=(test,elt, reason)=>{
   setTagsSkills(elt);
 }

 const handleSelectOccupations=(test,elt, reason)=>{
  setTagsOccupations(elt);
}
const handleSelectCategories=(test,elt, reason)=>{
  setTagsCategories(elt);
}
const handleSelectContractType=(test,elt, reason)=>{
  setTagsContractType(elt);
}
const handleSelectBenefits=(test,elt, reason)=>{
  setTagsBenefits(elt);
}
 const [inputValueLangue, setInputValueLangue] = React.useState('');
 const handleSelectCompany=(test,elt, reason)=>{
   setTagsCompany(elt);
 }
 const handleSearchChangeCompany=(event)=>{
  dispatch(handleSearchCompany({q:event.target.value}))
}

const handleSearchChangeEducation=(event)=>{
    dispatch(handleSearchEducation({q:event.target.value}))
  }

  const handleSearchChangePosition=(event)=>{
    dispatch(handleSearchPosition({q:event.target.value}))
  }
const handleSearchChangeBenefits=(event)=>{
  dispatch(handleSearchBenefits({q:event.target.value}))
}
const handleSearchChangeCategories=(event)=>{
  dispatch(handleSearchCategories({q:event.target.value}))
}
const handleSearchChangeContractType=(event)=>{
  dispatch(handleSearchContractType({q:event.target.value}))
}
const handleSearchChangeOccupations=(event)=>{
  dispatch(handleSearchOccupations({q:event.target.value}))
}
const dispatch = useDispatch();
  
useEffect (()=>{
    dispatch(handleSearchCompany({scope:"companies",search:`${window.location.search}`}));
    dispatch(handleSearchEducation());
    dispatch(handleSearchPosition({q:""}));
    dispatch(handleSearchLangue({q:""}));
    dispatch(handleSearchCompanyAdmin({q:""}))
    dispatch(handleSearchSkills({q:""}))
    dispatch(handleSearchContractType({q:""}))
    dispatch(handleSearchIndustries({q:""}))
    dispatch(handleSearchOccupations({q:""}))
    dispatch(handleSearchCategories({q:""}))
    dispatch(handleSearchArea({q:""}))
    },[dispatch]);
    
 console.log("List companiesData",companiesData)
  const handleAddLocation=(item)=>{
    setLocations([...locations.filter(obj=>obj.place_id!==item.place_id), item])
}
const handleSelectSectors=(test,elt, reason)=>{
  setTagsSectors(elt);
}
  const handleChangeCS = (event) => {
    setOrganization_size(event.target.value);
  };
  const handleChangeDescription = (event) => {
    setDescription(event.toString('html'));
  };

  const handleChangeAbout = (event) => {
    setAbout(event.toString('html'));
  };
  const handleChangeResponsabilities = (event) => {
    setResponsabilities(event.toString('html'));
  };
  const handleChangeQualifications = (event) => {
    setQualifications(event.toString('html'));
  };

  const handleChangeMin = (event) => {
    setAmount_min(event.target.value);
  };

  const handleChangeMax = (event) => {
    setAmount_max(event.target.value);
  };

  const handleChangeLE = (event) => {
    setLevel_of_experience(event.target.value);
  };

  const handleChangeNE = (event) => {
    setNumber_years_of_experience(event.target.value);
  };
  
  const handleChangeWH = (event) => {
    setWork_hours(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChangeCheckbox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeField =(event)=>{
    setState({...state, [event.target.name]:event.target.value});
  }
  const handleSelectEducation=(test,elt, reason)=>{
    setTagsEducation(elt);
  }
  const handleSelectPosition=(test,elt, reason)=>{
    setTagsPosition(elt);
  }
  const handleSelectLangue=(test,elt, reason)=>{
    setTagsLangue(elt);
  }
  const handleSearchChangeLangue=(event)=>{
    dispatch(handleSearchLangue({q:event.target.value}))
  }

  const handleSearchChangeSectors=(event)=>{
    dispatch(handleSearchIndustries({q:event.target.value}))
  }
  
  const handleSearchChangeSkills=(event)=>{
    dispatch(handleSearchSkills({q:event.target.value}))
  }
  const handleSelectAreas=(test,elt, reason)=>{
    setTagsAreas(elt);
  }
  const handleFormReset = () => {
    setState(() => initState);
    reset()
    props.handleClose()
 }
 const dateFormatter = str => {
    return str;
  };
 const handleSumbit=(event, values)=>{
   event.preventDefault();
console.log("des", state.description)
    dispatch(handleAddOpportunity({...state,
        page:"1",
        slug:props.slug,
        locations:locations.map(item=>item.data),
        organization_id : tagsCompany.length === 0 ? inputValueCompany :tagsCompany.organization_id,
        end_date: moment.isMoment(selectedDate) ? 
        selectedDate.format('YYYY-MM-DD')  : selectedDate,
        about:about?about:"",
        description:description?description:"",
        qualifications:qualifications?qualifications:"",
        responsibilities:responsibilities?responsibilities:"",
        organization_size:organization_size,
        level_of_experience:level_of_experience,
        number_years_of_experience:number_years_of_experience,
        work_hours:work_hours,
        skill_id:tagsSkills.map(item=>item.skill_id),
        //benefit_id:this.state.benefit_id,
        publication_status:1,
        job_contract_type_id:tagsContractType?tagsContractType.job_contract_type_id:props.itemDetailsOpportunity.job_contract_type_id,
        area_id: tagsAreas.length===0?  []  : tagsAreas.map(item=>item.area_id),
        industry_id: tagsSectors.length===0?   [] : tagsSectors.map(item=>item.industry_id),
        position_id : tagsPosition.length===0?  [] : tagsPosition.map(item=>item.position_id),
        occupation_id: tagsOccupations.length===0? []  : tagsOccupations.map(item=>item.occupation_id),
        category_id: tagsCategories.length===0? [] : tagsCategories.map(item=>item.category_id),
        education_id : tagsEducation.length ===0?  []  : tagsEducation.map(item=>item.education_id),
        language_id : tagsLangue.length===0?  []  : tagsLangue.map(item=>item.language_id),
        }
      ))
  }


  const handleSaveSumbit=(event)=>{
    event.preventDefault();
    console.log(state, locations.map(item=>item.data))
    dispatch(handleAddOpportunityDraft({...state, 
        page:"1",
        slug:props.slug,
        locations:locations.map(item=>item.data),
        organization_id : tagsCompany.length === 0 ? inputValueCompany :tagsCompany.organization_id,
        end_date: moment.isMoment(selectedDate) ? 
        selectedDate.format('YYYY-MM-DD')  : selectedDate,
        about:about?about:"",
        description:description?description:"",
        qualifications:qualifications?qualifications:"",
        responsibilities:responsibilities?responsibilities:"",
        organization_size:organization_size,
        level_of_experience:level_of_experience,
        number_years_of_experience:number_years_of_experience,
        work_hours:work_hours,
        skill_id:tagsSkills.map(item=>item.skill_id),
        //benefit_id:this.state.benefit_id,
        publication_status:0,
        job_contract_type_id:tagsContractType?tagsContractType.job_contract_type_id:"",
        area_id: tagsAreas.length===0?  []  : tagsAreas.map(item=>item.area_id),
        industry_id: tagsSectors.length===0?   [] : tagsSectors.map(item=>item.industry_id),
        position_id : tagsPosition.length===0?  [] : tagsPosition.map(item=>item.position_id),
        occupation_id: tagsOccupations.length===0? []  : tagsOccupations.map(item=>item.occupation_id),
        category_id: tagsCategories.length===0? [] : tagsCategories.map(item=>item.category_id),
        education_id : tagsEducation.length===0?  []  : tagsEducation.map(item=>item.education_id),
        language_id : tagsLangue.length===0?  []  : tagsLangue.map(item=>item.language_id),
    }))
    }
 
  const loader=<CircularProgress  color="primary"/>;
const test =<IntlMessages id="formRechercheDemploi"/>
        const handleClickOpen = () => {
          setOpen(true);
          dispatch(handleSearchCompany({scope:"companies",search:`${window.location.search}`}));
          dispatch(handleSearchEducation({q:""}));
          dispatch(handleSearchPosition({q:""}));
          dispatch(handleSearchLangue({q:""}));
          dispatch(handleSearchContractType());
          dispatch(handleSearchIndustries({q:""}));
          dispatch(handleSearchSkills({q:""}))
          dispatch(handleSearchArea({q:""}))
          dispatch(handleSearchOccupations({q:""}))
         dispatch(handleSearchCategories({q:""}))
          dispatch(EditProfileResetMessages())
        };
        const handleClose = () => {
          dispatch(EditOpportunityResetMessages())
          dispatch(addOpportunityDraftResetMessages())
          dispatch(addOpportunityResetMessages())
          reset()
          setOpen(false);
        };

    return(
    <div className="PageEditOpportunity company col-md-12">
    {props.icon?
    <Button variant="outlined" color="primary" className="edit-opportunity"
    onClick={()=>handleClickOpen(slug)}>
      <EditIcon/></Button>:
    props.title?
    <Button  onClick={()=>handleClickOpen(slug)}  variant="outlined" color="primary" className="company-edit-opportunity" justify="right">
     {<IntlMessages id="post_an_opportunity"/> }</Button>
     :
      <Button  onClick={()=>handleClickOpen(slug)}  variant="outlined" color="primary" className="btn_y col-md-4 edit-opportunity" justify="right">
       <EditIcon/> {<IntlMessages id="edit_opportunity"/>}</Button>
      }
    <Dialog 
    className="EditOpportunity"
    onClose={handleClose} 
    aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
      <div className="">
      {props.title?<IntlMessages id="add_opportunity"/>:<IntlMessages id="edit_opportunity"/>}    </div>
      </DialogTitle>
      {error?<Alert severity="error" onClose={()=>dispatch(addOpportunityResetMessages())}>{error}</Alert>:null}
      {errorDraft?<Alert severity="error" onClose={()=>dispatch(addOpportunityDraftResetMessages())}>{errorDraft}</Alert>:null}
      {messageDraft?<Alert severity="success" onClose={()=>dispatch(addOpportunityDraftResetMessages())}>{messageDraft}</Alert>:null}
      {serverError?<Alert severity="error" onClose={()=>dispatch(addOpportunityResetMessages())}>{serverError}</Alert>:null}
              
            <DialogContent className="edit-opportunity">
               <DialogContentText>
                {message? 
                 <Alert severity="success">{message}</Alert>:
                 <Form  className="add-opportunity">
                <Row>
                  <Col sm={12}>
                   <TextField
                    required
                    id="title"
                    name="title"
                    label={<IntlMessages id="title"/>}
                    onChange={handleChangeField}
                    variant="outlined"
                    value={state.title}
                  />
                  </Col>
                 

               <Col md={6}>
                <Autocomplete
                fullWidth
                required
                id="organization_id"
                size="small"
                onChange={handleSelectCompany}
                options={companiesData}
                getOptionLabel={(option) => option.name}
                onInputChange={(event, newInputValue) => {
                setInputValueCompany(newInputValue);}}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.name}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params}  variant="outlined" 
                  label={<IntlMessages id="organization"/>}
                  margin="normal"
                  placeholder="Ex: Wutiko" />
                )}
              />
            </Col>
            <Col md={6}>  
            <Autocomplete
                    fullWidth
                    multiple
                    id="category_id"
                    size="small"
                    onChange={handleSelectCategories}
                    options={categoriesData}
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} onChange={handleSearchChangeCategories} variant="outlined" 
                      label={<IntlMessages id="opportunity_type"/>}
                      margin="normal"
                      placeholder="Ex: Job" />
                    )}
                  />
                  </Col>
                  <Col sm={12}>
                  {/* <Field
                      id="about"
                      name="about"
                      label={<IntlMessages id="about"/>}
                      component={RichTextMarkdown}
                      componentclassName="textarea"
                      onChange={handleChangeAbout}
                      value={state.about}
                      />  
                  </Col> */}
                  <Field
                      id="description"
                      name="description"
                      label={<IntlMessages id="job_description"/>}
                      component={RichTextMarkdown}
                      componentclassName="textarea"
                      onChange={handleChangeDescription}
                      value={state.description}
                      />  
                  {/* <Col sm={12}>
                 
                    <Field
                    id="responsibilities"
                      name="responsibilities"
                      label={<IntlMessages id="responsibilities"/>}
                      component={RichTextMarkdown}
                      componentclassName="textarea"
                      onChange={handleChangeResponsabilities}
                      value={state.responsibilities}
                      />
                  </Col> */}
                  {/* <Col sm={12}>
                 
                  <Field
                      id="qualifications"
                      name="qualifications"
                      label={<IntlMessages id="qualifications"/>}
                      component={RichTextMarkdown}
                      componentclassName="textarea"
                      onChange={handleChangeQualifications}
                      value={state.qualifications}
                      />
                  */}


                
                      </Col>
                        <Col sm={6}>
                        <TextField
                        id="amount_min"
                        name="amount_min"
                        label={<IntlMessages id="minimum_salary"/>}
                        onChange={handleChangeField}
                        variant="outlined"
                        value={state.minimum_salary}
                        />
                        </Col>

                        <Col sm={6}>
                        <TextField
                        id="amount_max"
                        name="amount_max"
                        label={<IntlMessages id="maximum_salary"/>}
                        onChange={handleChangeField}
                        variant="outlined"
                        value={state.maximum_salary}
                        />
                        </Col>

                        <Col sm={3}>
                        <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">{t("wutiko.experience_level")}</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="level_of_experience"
                        value={level_of_experience}
                        onChange={handleChangeLE}
                        >
                            <MenuItem value="0">{t("wutiko.not_applicable")}</MenuItem>
                            <MenuItem value="1">{t("wutiko.internship")}</MenuItem> 
                            <MenuItem value="2">{t("wutiko.junior")}</MenuItem> 
                            <MenuItem value="3">{t("wutiko.experienced")}</MenuItem> 
                            <MenuItem value="4">{t("wutiko.senior")}</MenuItem> 
                            <MenuItem value="5">{t("wutiko.expert")}</MenuItem> 
                        </Select>
                        </FormControl>
                        </Col>

                        <Col sm={3}>
                         <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">{t("wutiko.experience")}</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="number_years_of_experience"
                        onChange={handleChangeNE}
                        >
                            {NumberYearsOfExperience.map((item,key) => (
                                      <MenuItem key={key} value={item.name}>{`${item.name} ${t(`wutiko.years`)}`}</MenuItem> 
                                      ))}
                        </Select>
                        </FormControl> 
                        </Col>
                        <Col sm={3}>
                        <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">{t("wutiko.work_hours")}</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="work_hours"
                        onChange={handleChangeWH}
                        >
                            {workHour.map((item,key) => (
                                      <MenuItem key={key} value={item.value}>{`${item.name}`}</MenuItem> 
                                      ))}
                        </Select>
                        </FormControl> 
                        </Col>
                        <Col sm={3}>
                        <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">{t('wutiko.company_size')}</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="organization_size"
                        onChange={handleChangeCS}
                        >
                        {companySize.map((name,key) => (
                        <MenuItem key={key} value={name.value}>{name.name}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                        </Col>


                        <Col md={6}>       
                        <MuiPickersUtilsProvider  libInstance={moment} utils={MomentUtils}>
                        <Col md={12}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="end_date"
                            name="end_date"
                            label={<IntlMessages id="application_deadline"/>}
                            format='YYYY-MM-DD'
                            rifmFormatter={dateFormatter}
                            value={selectedDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />  
                        </Col>
                        </MuiPickersUtilsProvider> 

                        
                        </Col>

                        <Col sm={6}>
                        <Col sm={12}>
                              <Field
                                   multiple
                                  fullWidth
                                  value={state.location_id}
                                  component={LocationSearchInput}
                                  addLocation={handleAddLocation}
                                  placeholder="Location(s)"
                                  label="Location(s)"
                                 
                              />
                          </Col> 
                  </Col>
                  <Col sm={12}>
                              {
                                      locations.map(item=>{
                                          return(
                                            <Chip label={`${item.data.city_name} ${item.data.state_province_name} ${item.data.country_name}`} onDelete={()=>setLocations(locations.filter(obj=>obj.place_id!==item.place_id))}/>
                                          )
                                        })
                              }
                            </Col>
                 
                </Row> 

                <Col sm={12}>
                 <Autocomplete
                    fullWidth
                    id="job_contract_type_id"
                    size="small"
                    freeSolo
                    onChange={handleSelectContractType}
                    options={contractTypeData}
                    getOptionLabel={(option) => option.name}
                    onInputChange={(event, newInputValue) => {
                    setInputValueContractType(newInputValue);}}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} onChange={handleSearchChangeContractType} variant="outlined" 
                      label={<IntlMessages id="contract_type"/>}
                      margin="normal"
                      placeholder="Ex: CDD" />
                    )}
                  /> 
            </Col> 
                <Col sm={12}>
                <Autocomplete
                fullWidth
                multiple
                id="education_id"
                name="education_id"
                size="small"
                onChange={handleSelectEducation}
                options={educationData}
                getOptionLabel={ (option) => option.name}
                renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.name}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params}  variant="outlined" 
                  onChange={handleSearchChangeEducation}
                  label={<IntlMessages id="education"/>}
                  margin="normal"
                  placeholder="Ex: Informatique ..." />
                )}
              />
              
            </Col> 


            <Col sm={12}>
                <Autocomplete
                fullWidth
                multiple
                id="position_id"
                size="small"
                onChange={handleSelectPosition}
                options={positionsData}
                getOptionLabel={ (option) => option.title}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.title}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params}  variant="outlined" 
                  onChange={handleSearchChangePosition}
                  label={<IntlMessages id="position"/>}
                  margin="normal"
                  placeholder="Ex: Informatique ..." />
                )}
              />
            </Col> 
                 <Col sm={12}>
                      <Autocomplete
                          fullWidth
                          id="language_id"
                          size="small"
                          multiple
                          freeSolo
                          disableClearable
                          open={openDialog}
                          onOpen={() => {
                            setOpenDialog(true);
                          }}
                          onClose={() => {
                            setOpenDialog(false);
                          }}
                          onChange={handleSelectLangue}
                          options={langue}
                          getOptionLabel={(option) => option.name}
                          value={value}
                          inputValue={inputValueLangue}
                          onInputChange={(event, newInputValue) => {
                            setInputValueLangue(newInputValue);}}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option.name}
                                size="small"
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField {...params} 
                            variant="outlined"
                            onChange={handleSearchChangeLangue}  
                            label={<IntlMessages id="language"/>}
                            margin="normal"
                            placeholder="Ex: French" />
                          )}
                        /> 
                             
                 <Autocomplete
                    multiple
                    fullWidth
                    id="industry_id"
                    size="small"
                    freeSolo
                    onChange={handleSelectSectors}
                    options={SectorsData}
                    getOptionLabel={(option) => option.name}
                    value={value}
                    inputValue={inputValueSectors}
                    onInputChange={(event, newInputValue) => {
                      setInputValueSectors(newInputValue);}}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} onChange={handleSearchChangeSectors} variant="outlined" 
                      label={<IntlMessages id="sectors"/>}
                      margin="normal"
                      placeholder="Ex: Pétroles"
                      />
                    )}
                  />

              <Autocomplete
                    multiple
                    fullWidth
                    id="skill_id"
                    size="small"
                    freeSolo
                    onChange={handleSelectSkills}
                    options={skillsData}
                    getOptionLabel={(option) => option.name}
                    value={value}
                    inputValue={inputValueSkills}
                    onInputChange={(event, newInputValue) => {
                      setInputValueSkills(newInputValue);}}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} onChange={handleSearchChangeSkills} variant="outlined" 
                      label={<IntlMessages id="skills"/>}
                      margin="normal"
                      placeholder="Ex: Chef d'entreprise" />
                    )}
                  />


              <Autocomplete
                    multiple
                    fullWidth
                    id="area_id"
                    size="small"
                    freeSolo
                    onChange={handleSelectAreas}
                    options={AreasData}
                    getOptionLabel={(option) => option.name}
                    value={value}
                    inputValue={inputValueAreas}
                    onInputChange={(event, newInputValue) => {
                      setInputValueAreas(newInputValue);}}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} onChange={handleSearchChangeAreas} variant="outlined" 
                      label={<IntlMessages id="areas"/>}
                      margin="normal"
                      placeholder="Ex: Java" />
                    )}
                  />

              {/* <Autocomplete
                    multiple
                    fullWidth
                    id="benefit_id"
                    size="small"
                    freeSolo
                    onChange={handleSelectBenefits}
                    options={benefitsData}
                    getOptionLabel={(option) => option.name}
                    value={value}
                    // inputValue={inputValueAreas}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValueAreas(newInputValue);}}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} onChange={handleSearchChangeBenefits} variant="outlined" 
                      label={<IntlMessages id="add_benefits"/>}
                      margin="normal"
                      placeholder="Ex: Sport" />
                    )}
                  /> */}




                <Autocomplete
                    multiple
                    fullWidth
                    id="occupation_id"
                    size="small"
                    freeSolo
                    onChange={handleSelectOccupations}
                    options={occupationsData}
                    getOptionLabel={(option) => option.name}
                    value={value}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} onChange={handleSearchChangeOccupations} variant="outlined" 
                      label={<IntlMessages id="occupations"/>}
                      margin="normal"
                      placeholder="Ex: Sport" />
                    )}
                  />


              </Col>
                <Col sm={12}>
               
                <Grid item xs={12} className="bottom-20 row add-opportunity-btn">
                <div className="col-md-6"><Button  onClick={handleFormReset} className="cancel" variant="contained" type="submit" color="primary">{<IntlMessages id="cancel"/>} </Button></div>
                
                <div className="col-md-3 text-right">
                {spinnerDraft?loader:
                <Button  onClick={handleSaveSumbit} className="saveDraft text-right" variant="contained" type="submit" color="primary">{<IntlMessages id="draft"/>} </Button>}
               </div>
                <div className="col-md-3 text-right">
                {spinner?loader:<Button  onClick={handleSumbit} className="addopp" variant="contained" type="submit" color="primary">{<IntlMessages id="published_now"/>}</Button>
                 }
               </div>
                </Grid>
                </Col>
            </Form> }
              </DialogContentText> 
            </DialogContent> 
            {/* <DialogActions>
            
            </DialogActions>  */}
            </Dialog>
           
      </div>
    );
  
          }



export default connect(
)(reduxForm({
   form: 'AddOpportunity', // a unique identifier for this form
  enableReinitialize: true
})(withTranslation('translation')(AddOpportunity)))
