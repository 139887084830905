import React,{useEffect} from 'react';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import { cleanStringQuery } from 'util/UtilFunctions';
import {useDispatch,useSelector} from 'react-redux'
import { handleContractTypeSearch } from 'actions/filters/contractType';
import IntlMessages from "util/IntlMessages";
  
function ContractType(props) {
  const dispatch = useDispatch();
  const contract_type = useSelector(state=>state.contractType.search_contract_type.contract_type);

  const handleSearchContractType=(data)=>{
    dispatch(handleContractTypeSearch({scope:props.scope, search:`?FacetContractType=${data.currentTarget.value}`}))
  }

  useEffect(()=>{
      dispatch(handleContractTypeSearch({scope: props.scope, search:`${cleanStringQuery(window.location.search)?`?${cleanStringQuery(window.location.search)}`:''}`}))
  },[dispatch])

  const onChecked=(value)=>{
    if(props.keys.filter(obj=>obj.name===value).length!==0){
        props.delete({name:value, type:'FacetContractType'})
    }else{
        props.addItem({name:value, type:'FacetContractType'})
    }
   }
    return (
        <FormControl className="filter">
          <InputLabel htmlFor="select-multiple-chip">{<IntlMessages id="contract_type"/>}</InputLabel>
          <Select
            label={<IntlMessages id="contract_type"/>}
            multiple
            value={[]}
            >
            <div className="search">
            <Input  className="filter" name="search" 
            type="search" placeholder="Search"
            autocomplete="off" 
            onChange={(data)=>handleSearchContractType(data)}/>
            </div>
            {contract_type.map((name,key) => (
              <MenuItem key={key} value={name.contract_type}>
                <Checkbox checked={props.keys.filter(obj=>obj.name===name.contract_type).length!==0} 
                onClick={()=>onChecked(name.contract_type)}/>
                <ListItemText primary={`${name.contract_type}(${name.total})`} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>


    );
}

export default ContractType;
