import { Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import IntlMessages from "util/IntlMessages";
import React,{useEffect} from 'react';
import { useDispatch} from 'react-redux'
import IconButton from "@material-ui/core/IconButton";
import { Button} from 'reactstrap';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from "@material-ui/icons/Close";
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiDialogContent from '@material-ui/core/DialogContent';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { handleGetProfileDetailsForm } from 'actions/profile';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { Parser } from 'html-to-react';

const useStyles = makeStyles((theme) => ({
    toolbar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    header:{
      position:'fixed',
      top:0,
      width:'100%',
      background:'#ffffff',
      zIndex:10
    },
    toolbarTitle: {
      flex: 1,
    },
    toolbarSecondary: {
      justifyContent: 'space-between',
      overflowX: 'auto',
    },
    toolbarLink: {
      padding: theme.spacing(1),
      flexShrink: 0,
    }, 
    search:{
      paddingTop:0,
      paddingBottom:0
    }
  }));



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);




  const PesponseView = (props , t ) => {
    const itemDetails = useSelector(state=>state.profile.detailsForm.detailsForm);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const {ProfileSlug, idFormApp} = props;
    var parser = new Parser();
    const spinner = useSelector(state=>state.profile.detailsForm.spinner);
    const itemDetailsProfile = useSelector(state=>state.profile.detailsForm);
    const userId = useSelector(state => state.users.id)
   const slug = "dione-daba-282d7701ff5b"
   //const {slug} =useParams();
   console.log("spinner profil" , spinner)

   console.log("itemDetailsProfile" , itemDetails)

    const handleClickOpen = (idFormApp) => {
      dispatch(handleGetProfileDetailsForm(idFormApp));
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    console.log("ProfileSlug" , ProfileSlug);
    const dispatch = useDispatch();

    return(
     <div>
      <Button className="neutre" onClick={()=>handleClickOpen(idFormApp)}>
      <e> 
                        <IconButton>
                            <RemoveRedEyeIcon 
                            className={`green_color`}
                            color="#4caf50;"/>
                        </IconButton>
                    </e>
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}  className="List">
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
      {ProfileSlug?<h2>{ProfileSlug.first_name} {ProfileSlug.last_name}</h2>:null} 
      </DialogTitle>
      
          <DialogContent>
 
        {spinner?
        <Grid container item justify="center" xs={12}><CircularProgress size={60}/></Grid>:
            <DialogContentText id="alert-dialog-description">
      <div className="container">
        <Container maxWidth={false} className={classes.cont}>
        <div className={`animated slideInUpTiny animation-duration-3 didh ${classes.content}`}>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-12 animation slideInLeft bloc-profile">
            <div className="row">
                    
                    <div className="col-md-12 col-sm-12 col-12 animation slideInRight">
                      <div className="row"> 
                        <div className={`col-md-12 col-sm-4 col-4 animation slideInRight ${classes.textAlignEnd}`}>
                        <div className="col-12"> <label className="col-4">Prénom et Nom :</label> <span className="col-8">{itemDetails? itemDetails.created_by.first_name+' '+ itemDetails.created_by.last_name :null}</span></div>
                        <div className="col-12">  <label className="col-4">Choisir une civilité :</label>  <span className="col-8">{itemDetails? itemDetails.civility :null}</span></div>
                         <div className="col-12">  <label className="col-4">Date de naissance :</label>  <span className="col-8">{itemDetails? itemDetails.date_of_birth :null}</span></div>
                         <div className="col-12">  <label className="col-4">Email :</label>  <span className="col-8">{itemDetails? itemDetails.email :null}</span></div>
                         <div className="col-12">  <label className="col-4">Numéro Téléphone :</label>  <span className="col-8">{itemDetails? itemDetails.phone :null}</span></div>
                         <div className="col-12">  <label className="col-4">Nationalité :</label>  <span className="col-8">{itemDetails? itemDetails.nationality :null}</span></div>
                         <div className="col-12">  <label className="col-4">Pays :</label>  <span className="col-8">{itemDetails? itemDetails.country :null}</span></div>
                         <div className="col-12">  <label className="col-4">Address :</label>  <span className="col-8">{itemDetails? itemDetails.address :null}</span></div>
                         <div className="col-12">  <label className="col-4">Formation universitaire, merci de préciser le diplôme le plus éleve :</label>  <span className="col-8">{parser.parse(itemDetails? itemDetails.education :null)}</span></div>
                         <div className="col-12">  <label className="col-4">Fonctions actuelles :</label>  <span className="col-8">{parser.parse(itemDetails? itemDetails.current_position :null)}</span></div>
                         <div className="col-12">  <label className="col-4">Fonctions antérieures :</label>  <span className="col-8">{parser.parse(itemDetails? itemDetails.previous_position :null)}</span></div>
                         <div className="col-12">  <label className="col-4">Expériences dans le domaine des Droits de l'Homme : </label>  <p className="col-12">{parser.parse(itemDetails? itemDetails.experience :null)}</p></div>
                         <div className="col-12">  <label className="col-4">Motivations :</label>  <p className="col-12">{parser.parse(itemDetails? itemDetails.motivation :null)}</p></div>
                         <div className="col-12">  <label className="col-4">Souhaitez- vous un hébergement? :</label> <span className="col-8">{itemDetails? itemDetails.accommodation? "Oui" : "Non" :null}</span></div>
                         <div className="col-12">  <label className="col-4">Cv :</label> <span className="col-8"><a href={itemDetails? itemDetails.resume :null} target="blank">Curriculum vitae</a></span></div>
                         <div className="col-12">  <label className="col-4">Diplôme :</label> <span className="col-8"><a href={itemDetails? itemDetails.diploma :null}  target="blank"> Diplôme </a></span></div>
                          </div>
                      </div>
                    

                    </div>
            </div>



          </div>
         
        </div>
      </div>
      </Container>
      </div>
      </DialogContentText>
      
  }
        </DialogContent>
        </Dialog>
      
        </div>
    )
}
export default withTranslation('translation')(PesponseView);
// function mapStateToProps(state) {
//   return {
//     itemDetails: state.itemdetail.detail,
//     userId: state.user.userId,
//   }
// }
