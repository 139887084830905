import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton'
import {SortableElement, SortableHandle} from 'react-sortable-hoc';
import ProfileView from 'components/profile/ProfileHeader/ProfileView';
import { useDispatch} from 'react-redux'
import { useSelector} from 'react-redux'
import Check from '@material-ui/icons/Check'; 
import { HandleSelectPipelineProfile, SelectPipelineProfileResetMessages } from 'actions/dashboard/profil';
import AttachmentIcon from '@material-ui/icons/Attachment';
import Notif from 'components/uielements/Notification';
import IntlMessages from "util/IntlMessages";
import PesponseView from './ResponseApp'

const DragHandle = SortableHandle(() =>
  <i className="zmdi zmdi-menu draggable-icon" style={{fontSize: 25}}/>);

const ItemApplicantForm = (props) => {

  const [open, setOpen] = React.useState(false);
  const [openProfile, setOpenProfile] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [menuState, setMenuState] = useState();
  const itemDetailsProfile = useSelector(state=>state.profile.details.details);
  const message =useSelector(state=>state.opportunities.selectPipeline.message)
  const error =useSelector(state=>state.opportunities.selectPipeline.error)
  const [openNotif, setOpenNotif]=React.useState(false)
  const onContactOptionSelect = event => {
    setMenuState(true);
    setAnchorEl(event.currentTarget);
  };
  const handleRequestClose = () => {
    setMenuState(false);
  };
  const dispatch = useDispatch();
  
  const {contact} = props;
  console.log("TestContact", contact)
    const {profile, id, appForm} = props;
    

    const [publicationStatus, setPublicationStatus]=React.useState(appForm.is_selected? "select":"warning")
  






  const handleSelect=()=>{
    const data = `/opportunities/call-for-application/${id}/selection/add/`
    setOpenNotif(true)
     dispatch(HandleSelectPipelineProfile(data));
     error?
     setPublicationStatus(publicationStatus):
        publicationStatus === "select" ?
         setPublicationStatus("warning"):setPublicationStatus("select")
         dispatch(SelectPipelineProfileResetMessages())
   }
 
 
   const handleReject=()=>{
     const data = `/opportunities/applications/${id}/selection/add/`
      dispatch(HandleSelectPipelineProfile(data));
    }
   const handleClose = () => {
     setOpenProfile(false);
   };

   
    const options = [
      'Edit',
      'Delete',
    ];
    const handleCloseNotif=()=>{
      setOpenNotif(false)
     }

     console.log("Id log" , appForm.id)

    return (
      <div className="col-12 contact-item ripple row no-gutters align-items-center py-2 px-3 py-sm-4 px-sm-6">      
{/* 
      {error?<Alert severity="success" onClose={()=>dispatch(SelectPipelineProfileResetMessages())}>{error ?error : null}</Alert>:null}
          */}
       
       <Notif open={openNotif} title={<IntlMessages id="notification"/>} text={
        error?
        error :
        message?message:null}
        notification="notification"
        error={error? error : null}
        message={message ? "message" : null}
        handleClose={handleCloseNotif}/> 

        {/* <DragHandle/> */}
        <img className="rounded-circle size-40 mx-4" alt={profile.first_name} src={profile.photo}/>
         
        <div className="col text-truncate font-weight-bold">
        <ProfileView  
        ProfileSlug={profile} first_name={profile.first_name} last_name={profile.last_name}/>
       {appForm.resume?
       appForm.resume!==null?
        <a href={appForm.resume} target="blank"> CV : <AttachmentIcon className="green_color" color="#28a745;"/> </a>
       :null:null}<br/>
       {appForm.diploma?
       appForm.diploma!==null?
        <a href={appForm.diploma} target="blank"> Diplôme : <AttachmentIcon className="green_color" color="#28a745;"/> </a>
       :null:null}
       </div>
       <div className="col text-center">
          {appForm.created} 
        </div>
        {/* <div className="col text-center">
          {appForm.phone} 
        </div> */}
       {/* <div className="col email text-truncate px-1 d-none d-lg-flex">
       {organization?
        <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={organization.name}
                    secondary={
                      <React.Fragment>
                        <Typography component="span" variant="body2" color="textPrimary"><small>{created}</small></Typography>
                      </React.Fragment>
                    }
                  />
        </ListItem>
        :
        <ListItem alignItems="flex-start">
        <ListItemText
          primary={opportunity.title}
          secondary={
            <React.Fragment>
              <Typography component="span" variant="body2" color="textPrimary"><small>{created}</small></Typography>
            </React.Fragment>
          }
        />
      </ListItem>
                    }
        </div> */}
       
        <div className="col  text-center">
            <div className="col  text-center">
                 <e onClick={()=>handleSelect()}> 
                  <IconButton>
                      <Check 
                      className={`green_color ${publicationStatus}`}
                      color="#4caf50;"/>
                  </IconButton>
              </e> 
        
             
                <PesponseView  
                ProfileSlug={profile} idFormApp={id} first_name={profile.first_name} last_name={profile.last_name}/>
              
              </div>
       </div>
     </div>
    )
};

export default SortableElement(ItemApplicantForm);