import React,{useState, useEffect}from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {useDispatch} from 'react-redux'
import Skills from './Skills';
import { splitStringQueryAddState } from 'util/UtilFunctions';
import {useHistory} from "react-router-dom";
import { handleSearchSkills } from 'actions/filters/skills';
import Educations from './Educations';
import Positions from './Positions';
import Locations from './Locations';
import { handlePositionsSearch } from 'actions/filters/positions';
import { handleLocationsSearch } from 'actions/filters/locations';
import { handleEducationsSearch } from 'actions/filters/educations';
import { handleProfilesSearch } from 'actions/search';
import Chip from '@material-ui/core/Chip';
import IntlMessages from "util/IntlMessages";
export default function ProfilesFilters(props) {
  const [keys, setState]=useState([]);
  const dispatch = useDispatch()
  const browserHistory= useHistory();
  const constructUrl=()=>{
    var url ="";
    keys.map((item)=>{
        if(!url){
            return url+=`${item.type}=${item.name}`;
        }else{
            return url+=`&${item.type}=${item.name}`
        }
    })
    return url   
    }

  const handleDelete=(key)=>{
   setState([...keys.filter(obj=>obj.name!==key.name)]);
   setTimeout(
    ()=>{
      browserHistory.push(`${window.location.pathname}?${constructUrl()}`);
      dispatch(handleSearchSkills({scope:"profile", search:`?${constructUrl()}`}))
      dispatch(handleEducationsSearch({scope:"profile", search:`?${constructUrl()}`}))
      dispatch(handlePositionsSearch({scope:"profile", search:`?${constructUrl()}`}))
      dispatch(handleProfilesSearch({scope:"profiles", search:`${window.location.search}`, page:`1`}))
      // dispatch(handleLocationsSearch({scope:"profile", search:`?${constructUrl()}`}))
      
    },80
  )
  }
  //
  const addItem=(item)=>{
    setState([...keys.filter(obj=>obj.name!==item.name), item])
    setTimeout(
      ()=>{
        browserHistory.push(`${window.location.pathname}?${constructUrl()}`);
        dispatch(handleSearchSkills({scope:"profile", search:`?${constructUrl()}`}))
        dispatch(handleEducationsSearch({scope:"profile", search:`?${constructUrl()}`}))
        dispatch(handlePositionsSearch({scope:"profile", search:`?${constructUrl()}`}))
        dispatch(handleProfilesSearch({scope:"profiles", search:`${window.location.search}`, page:`1`}))
        // dispatch(handleLocationsSearch({scope:"profile", search:`?${constructUrl()}`}))
      },80
    )
  }
  useEffect(()=>{
    if(keys.length==0){
      setState(splitStringQueryAddState(window.location.search));
    }
  },[setState])

  return (
      <Grid container spacing={2}>
        <Grid item md={3} xs={4}>
          <h2> {<IntlMessages id="filters"/>} </h2>  
        </Grid>
        <Grid item md={3} xs={4}>
          <Skills scope="profile" addItem={addItem} delete={handleDelete} keys={keys}/>
        </Grid>
        <Grid item md={3} xs={4}>
            <Educations scope="profile" addItem={addItem} delete={handleDelete} keys={keys}/>
        </Grid>
        <Grid item md={3} container xs={4}>
          <Positions scope="profile" addItem={addItem} delete={handleDelete} keys={keys}/>
        </Grid>
        {/* <Grid item md={2} xs={4}>
          <Locations scope="profile" addItem={addItem} delete={handleDelete} keys={keys}/>
        </Grid> */}
        <Grid item xs={12}>
          {
            keys.map((item)=>{
              return(
                <Chip label={item.name} 
                 onDelete={()=>handleDelete(item)}
                />
              )
            })
          }
        </Grid>
      </Grid>
  );
}

ProfilesFilters.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
