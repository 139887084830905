import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {useDispatch, useSelector} from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/lab/Pagination';
import Opportunity from 'components/uielements/Opportunity';
import ProfilesFilters from 'containers/filters/ProfilesFilters';
import { handleProfilesSearch } from 'actions/search';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Profil from './Profiles';
import IntlMessages from "util/IntlMessages";
import ReactPaginate from 'react-paginate';

import { FollowProfileError, FollowProfileSuccess, HandleFollowProfile } from 'actions/profile';
import Notif from 'components/uielements/Notification';
const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding:15,
    paddingLeft:24,
    paddingRight:24,
    width:'100%',
  },
  ul:{
    'list-style-type': 'none',
    paddingLeft: 0,

  },isloadingOpp:{
    opacity:0.2,
    pointerEvents: 'none',
  },
  btn:{
    backgroundColor:"#ff9801",
    '&:hover':{
      backgroundColor:"#ff9801",
    }
  }
}));


export default function ProfilesAll() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const message =useSelector(state=>state.profile.followProfile.message)
    const error =useSelector(state=>state.profile.followProfile.error)
    const RESULTS_PER_PAGE = 12;
    const count = useSelector(state=>state.profile.all_profiles.count);
    const state = useSelector(state=>state.profile.all_profiles);
    const profileList=state.list.profiles? state.list.profiles:[];
    const status =useSelector(state=>state.profile.followProfile.status)
    const isAuthenticated = useSelector((state)=> state.users.authenticated)
    const  userSettings = useSelector((state)=> state.users.userSettings)
   const [currentPage, setcurrentPage] = React.useState(1);
    useEffect(()=>{
      dispatch(handleProfilesSearch({scope:"profiles", search:`${window.location.search}`, page:`1`}))
    },[dispatch])


 const handlePageClick=(newPage) =>{
  setcurrentPage(newPage.selected + 1)
  dispatch(handleProfilesSearch({scope:"profiles",search:`${window.location.search}`, page:`${newPage.selected + 1}`}));
} 

    let pageCount;
    let onPageChange;


    pageCount = Math.ceil(count / RESULTS_PER_PAGE);
    onPageChange = handlePageClick;


   
    return (
    <div className={`container list-profile`} >
     
      <div className="container-filter">

      <Container maxWidth={false} className={classes.cont}>
            <Typography 
                component="h1"
                variant="h5"
                color="inherit"
                align="center"
                noWrap
                className={classes.title}>
                {<IntlMessages id="list_of_all_profiles" />}
          </Typography>
          </Container>

        <Grid  xs={12} className="total-opportunities">
        {<IntlMessages id="showing_count_results" values={{count: state.count}}/>}
        </Grid>
        
        <Grid className="container filter-opportunities">
       <ProfilesFilters/>
            </Grid>
      </div>

      
     { isAuthenticated?
  
     <>
      {/* {message?<Alert severity="success" onClose={()=>dispatch(FollowProfileSuccess())}>{message ?message : null}</Alert>:null}
          {error?<Alert severity="danger" onClose={()=>dispatch(FollowProfileError())}>{error ?error : null}</Alert>:null}
      */}
        <Grid container spacing={2}>
        {
          state.spinner?
          <Grid container item justify="center" xs={12}><CircularProgress size={80}/></Grid>:
          profileList.map((item, index)=>{
            return(
              <Grid item md={4} xs={12}>
                   <Profil data={item} key={index} is_user_recruiter={userSettings? userSettings.is_user_recruiter:null}/> 
             </Grid>
            )
          })
        }
        </Grid>
        
        <Grid item md={12} xs={12} container justify="center" className="Listpagination">
        
              <ReactPaginate
                previousLabel={count > 0 ?<IntlMessages id="previous" />:null}
                nextLabel={count > 0 ?<IntlMessages id="next" />:null}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={count > 0 ?"pagination":null}
                previousLinkClassName={count > 0 ?"pagination__link":null}
                nextLinkClassName={count > 0 ?"pagination__link":null}
                disabledClassName={count > 0 ?"pagination__link--disabled":null}
                activeClassName={count > 0 ?"pagination__link--active":null}
              />
        </Grid>
       </>
      
       :
       <div className="block-vide">
        <p>{<IntlMessages id="reserved_only_for_members" />}</p>
       </div>
      }
    </div>
  );
}

ProfilesAll.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};





















