import { resetUserSession } from "actions/users";
import { GET_TOKEN_URL } from 'config';
import {  useDispatch} from 'react-redux'
//const loginUrl=  window.location.href=`${GET_TOKEN_URL}&state=/home`;

//const dispatch = useDispatch()
  const initialState = {
    blankAxiosInstance: {},
    axiosInstance: {},
    userSettings:{},
    userId:null,
    slug:null,
    authenticated:false,
  }
  // Possible status :
  // - registering
  // - validating_account
  // - authenticated
  // - logout
  // Possible acccount types :
  // - default
  // - ACCOUNT_TYPE_RECRUITER
  // There are two reducers for now because it wil be easier to merge with other code
  export function users(state = initialState, action){
  
    switch(action.type) {
    //   case 401: // unauthorized
    //   // token is now invalid, need to relogin
    //   // FIXME : that can also be because the user made an inappropriate request, how to tell that to him ?
    //  // dispatch(resetUserSession());
    //   window.location.href= window.location.href=`${GET_TOKEN_URL}&state=/home;`;
    //   break;

    case "LOGOUT":
      
       return {...state, blankAxiosInstance: {}, userSettings:{}, authenticated:false}
      case "SET_USER_SETTINGS":
      return {...state, userSettings:action.payload, userId:action.payload.id, slug:action.payload.slug, authenticated:true}
      
      case "CREATE_BLANK_AXIOS_INSTANCE":
        return {...state, blankAxiosInstance: action.payload, authenticated:false}
      case "LOGIN":
        return {...state, authenticated: true}
      case 'CREATE_AXIOS_INSTANCE':
        return {...state, axiosInstance: action.payload, authenticated:true}
      
      case `CREATE_AXIOS_INSTANCE_NO_PARAMS`:
        return {...state, axiosInstanceNoParams: action.payload}
  
      case 'RESET_AXIOS_INSTANCE':
        return {...state, axiosInstance: {}}
      case 'FIRST_ACCESS':
        return {...state, location:action.payload}
      default:
        return state;
    }
  }