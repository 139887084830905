import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { EditProfileSuccess,EditProfileError, handleGetProfileDetails} from "actions/profile";

const EditProfileData = async (instance,data)=>
    await instance.put(`/profiles/edit/`, data)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* EditProfile(actions){
    
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(EditProfileData,axiosInstance, actions.data);
    if(data.message){
        yield put({type:"HANDLE_GET_EDIT_PROFILE_DETAILS"})
        yield put(EditProfileSuccess(data.message));
    }else{
        console.log("data.error", data, data.error)
        yield put(EditProfileError(data.error));
    }
}
export function* handleEditProfile(){
    yield takeEvery("HANDLE_EDIT_PROFILE", EditProfile)
}
export default function* rootSaga() {
    yield all([fork(handleEditProfile)]);
}

