export function handleAddFielsd2Form(data){
    return{
        type:"HANDLE_ADD_FIELDS_2_FORM",
        data:data
    }
}

export function addFielsd2FormSuccess(data){
    return{
        type:"ADD_FIELDS_2_FORM",
        payload:data
    }
}

export function addFielsd2FormError(data){
    return{
        type:"ADD_FIELDS_2_FORM_FAILLURE",
        payload:data
    }
}

export function ddFielsd2FormResetMessages(){
    return{
        type:"ADD_FIELDS_2_FORM_RESET_MESSAGES",
        payload:{}
    }
}