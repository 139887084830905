import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {useDispatch, useSelector} from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import {Alert} from '@material-ui/lab';
import Form from "@rjsf/core";
import { APP_FORM_ID } from 'config';
import { handleSubmitContactForm, submitContactFormRemoveMessages } from 'actions/forms/contactForm';
import { handleGetForm } from 'actions/forms/formsGenerator';


const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    title: {
    },
    paper:{
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        marginLeft: 15,
        marginRight: 15,
        [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(2),
        },
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    content:{
        padding:15,
        paddingBottom:30,
        overflowY:"hidden"
    },
    paper:{
        backgroundColor:'#e0e0e0'
    },
    upload:{
        width:80,
        height:80
    }
  }));

export default function ApplicationForm(props) {
  const classes = useStyles();
  const formStructureApp = useSelector(state=>state.formsGenerator.get_form.details);
  const spinner= useSelector(state=>state.formsGenerator.get_form.spinner);
  const properties= useSelector(state=>state.formsGenerator.get_form.properties);
  const required_1= useSelector(state=>state.formsGenerator.get_form.required);
  const required = formStructureApp.required? formStructureApp.required:[];
  const submit_data= useSelector(state=>state.formsGenerator.submit_contact_form);
  const uiSchema_1 = useSelector(state=>state.formsGenerator.get_form.uiSchema);
  const uiSchema =   uiSchema_1?  uiSchema_1:[];

  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)

  const handleOpenModal= ()=>{
    setOpen(true)
  }

  useEffect(()=>{
    dispatch(handleGetForm(APP_FORM_ID))
  },[dispatch]);

  console.log("formStructureApp",formStructureApp)
  console.log("required",formStructureApp.required)
  console.log("properties",formStructureApp.properties)
  console.log("uiSchema",formStructureApp.uiSchema)
  
  const schema = {
    title: "Todo",
    type: "object",
    required: ["title"],
    properties: {
      title: {type: "string", title: "Title", default: "A new task"},
      done: {type: "boolean", title: "Done?", default: false}
    }
  };
  const Submission =(formData)=>{
      console.log(`formData`,formData)
    var virtual_state={
        email_to:'',
        status:false,
      }
      const data=formData.formData;
      var fields=[];
      Object.keys(formStructureApp.uiSchema).forEach((field)=>{
        if(typeof data[`${field}`]!='object'){
            return fields.push({value:data[`${field}`]||"" ,field_id:field, label:formStructureApp.properties[field].title})
        }
        else{
          var vall="";
          data[`${field}`].forEach((val)=>{
              return vall+=val+','
          });
            return fields.push({value:vall.trim().slice(0, -1),field_id:field, label:formStructureApp.properties[field].title})
        }
      },
      console.log("SUBMISSION", fields),
        dispatch(handleSubmitContactForm({fields:fields, is_submitted:"true"},{email_to:virtual_state.email_to || ""},APP_FORM_ID))
      );
  }
  console.log("formStructureApp.required.length", required.length)
  const loader=<center><CircularProgress  color="primary"/></center>;
  return (

    <React.Fragment>
    <Dialog fullScreen={false} maxWidth="sm" fullWidth={true} open={props.open} onClose={props.handleClose}>
        <AppBar className={classes.appBar}>
        <Toolbar>
            <Grid container>
                <Grid  item className="col-md-10 col-10" justify="center"><Typography variant="h6" className={classes.title}>Nous Contacter</Typography></Grid>
                <Grid  item className="col-md-2 col-2 closeName" justify="right"> 
                    <IconButton edge="start" color="inherit" aria-label="close" onClick={props.handleClose}>
                        <CloseIcon />
                    </IconButton>   
                </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
         {/*  */}
         <DialogContent >
         {submit_data.message?<Alert severity="success" onClose={()=>dispatch(submitContactFormRemoveMessages())}>{submit_data.message}</Alert>:null}
              {submit_data.error?<Alert severity="error" onClose={()=>dispatch(submitContactFormRemoveMessages())}>{submit_data.error}</Alert>:null}
          <Grid container spacing={2}>
           
             <Grid item xs={12}>
             
                {formStructureApp? 
                  <Form 
                      schema={
                        {
                          title:'',
                          description:'',
                          type:'object',
                          required:formStructureApp.required,
                          properties:formStructureApp.properties,
                      }}
                      uiSchema={formStructureApp.uiSchema}
                      showErrorList={false}
                      onSubmit={Submission}
                  >
                    <Grid item container xs={12} justify="flex-end">
                      {submit_data.spinner?loader:<Button color="primary" variant="contained" type="submit">Submit</Button>}
                    </Grid>
                    
                  </Form>:null}
             </Grid>
          </Grid>
          </DialogContent>
           {/*  */}
         </Dialog>   
      </React.Fragment>
  );
}
