/***
 * Get companies details with slug
 */
export function handleGetCompany(data){
    return{
        type:"HANDLE_GET_COMPANY",
        payload:data
    }
}
//Backend response data
export function getCompanySuccess(data){
    console.log(data,"GET_COMPANY")
    return{
        type:"GET_COMPANY",
        payload:data
    }
}
//  Backend Error message
export function getCompanyError(data){
    return{
        type:"GET_COMPANY_FAILLURE",
        payload:data
    }
}


/***
 * Get companies details with slug
 */
export function handleGetCompaniesAdmin(data){
    return{
        type:"HANDLE_GET_COMPANIES_ADMIN",
        payload:data
    }
}
//Backend response data
export function getCompaniesAdminSuccess(data){
    console.log(data,"GET_COMPANIES_ADMIN")
    return{
        type:"GET_COMPANIES_ADMIN",
        payload:data
    }
}
//  Backend Error message
export function getCompaniesAdminError(data){
    return{
        type:"GET_COMPANIES_ADMIN_FAILLURE",
        payload:data
    }
}
export function getCompaniesAdminResetMessage(data){
    return{
        type:"GET_COMPANIES_ADMIN_RESET_MESSAGE",
        payload:data
    }
}


/***
 * Get latest companies List with id
 */export function handleGetCompanyLatest(data) {
    return {type: "HANDLE_GET_COMPANY_LATEST", payload:data};
}
export function getCompaniesLatestListSuccess(data){
    return {
        type: "GET_COMPANIES_LATEST",
        payload:data
    }
}
export function getCompaniesLatestListError(data){
    return{
        type:"GET_COMPANIES_LATEST_FAILLURE",
        payload:data
    }
}



/***
 * Get profile List with id
 */export function setOrganizationsListData(data) {
    return {type: "GET_ORGANIZATIONS_LIST_DATA", payload:data};
}
export function setAllOrganizationsList(data){
    return {
        type: "SET_ALL_ORGANIZATIONS",
        payload:data
    }
}
export function setAllOrganizationsListError(data){
    return{
        type:"SET_ALL_ORGANIZATIONS_FAILLURE",
        payload:data
    }
}


/***
 * Get opportunity details with slug
 */
export function handleGetCompanyDetails(slug){
    return{
        type:"HANDLE_GET_COMPANY_DETAILS",
        slug:slug
    }
}
//Backend response data
export function getCompanyDetailsSuccess(data){
    console.log(data,"GET_COMPANY_DETAILS")
    return{
        type:"GET_COMPANY_DETAILS",
        payload:data
    }
}
//  Backend Error message
export function getCompanyDetailsError(data){
    return{
        type:"GET_COMPANY_DETAILS_FAILLURE",
        payload:data
    }
}


//...............SAVE Opportunity....

export function HandleSaveCompany(data){
    return{
        type:"HANDLE_SAVE_COMPANY",
        data:data
    }
}

export function SaveCompanySuccess(data){
    return{
        type:"SAVE_COMPANY_SUCCESS",
        payload:data
    }
}
// Backend Error message
export function SaveCompanyError(data){
    return{
        type:"SAVE_COMPANY_FAILLURE",
        payload:data
    }
}

export function saveCompanyResetMessages(){
    return{
        type:"SAVE_COMPANY_RESET_MESSAGES"
    }
}

//...............Apply COMPANY....

export function HandleApplyCompany(data){
    return{
        type:"HANDLE_APPLY_COMPANY",
        data:data
    }
}
export function ApplyCompanySuccess(data){
    return{
        type:"APPLY_COMPANY_SUCCESS",
        payload:data
    }
}
export function ApplyCompanyError(data){
    return{
        type:"APPLY_COMPANY_FAILLURE",
        payload:data
    }
}
export function ApplyCompanyResetMessages(){
    return{
        type:"APPLY_COMPANY_RESET_MESSAGES"
    }
}

//...............Share COMPANY....
export function shareCompanySuccess(data){
    return{
        type:"SHARE_COMPANY",
        payload:data
    }
}
export function shareCompanyError(data){
    console.log(data,'data-data')
    return{
        type:"SHARE_COMPANY_FAILLURE",
        payload:data
    }
}

export function handleSharecompany(data){
    return{
        type:"HANDLE_SHARE_COMPANY",
        data:data
    }
}

export function ShareCompanyResetMessages(){
    return{
        type:"SHARE_RESET_MESSAGES",
        payload:""
    }
}



/***
 * Get profile by companies with id
 */
export function handleGetProfilesByCompany(CompanyId){
    return{
        type:"HANDLE_GET_PROFILES_BY_COMPANY",
        CompanyId:CompanyId
    }
}
//Backend response data
export function getProfilesByCompanySuccess(data){
    console.log(data,"GET_PROFILES_BY_COMPANY_SUCCESS")
    return{
        type:"GET_PROFILES_BY_COMPANY_SUCCESS",
        payload:data
    }
}
//  Backend Error message
export function getProfilesByCompanyError(data){
    return{
        type:"GET_PROFILES_BY_COMPANY_FAILLURE",
        payload:data
    }
}



/***
 * Get opportunies by companies with id
 */
export function handleGetOpportunitiesByCompany(data){
    return{
        type:"HANDLE_GET_OPPORTUNITIES_BY_COMPANY",
        data:data
    }
}
//Backend response data
export function getOpportunitiesByCompanySuccess(data){
    console.log(data,"GET_OPPORTUNITIES_BY_COMPANY_SUCCESS")
    return{
        type:"GET_OPPORTUNITIES_BY_COMPANY_SUCCESS",
        payload:data
    }
}
//  Backend Error message
export function getOpportunitiesByCompanyError(data){
    return{
        type:"GET_OPPORTUNITIES_BY_COMPANY_FAILLURE",
        payload:data
    }
}


// Starting the process
export function handleResumeCheckCompany(data){
    return{
        type:"HANDLE_RESUME_COMPANY_CHECK",
        data: data
    }
}


//handle success
export function resumeCheckCompanySuccess(data){
    return{
        type:"RESUME_CHECK_COMPANY",
        payload: data
    }
}
//handle errors
export function resumeCheckCompanyError(data){
    return{
        type:"RESUME_CHECK_COMPANY_FAILLURE",
        payload: data
    }
}
// reset messages

export function resumeCheckCompanyResetMessages(){
    return{
        type:"RESUME_CHECK_COMPANY_RESET_MESSAGES",
        payload:{}
    }
}



