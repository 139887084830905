import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { getCompanyDetailsSuccess, getCompanyDetailsError } from "actions/companies/getCompanies";

const GetcompanyDetailsRequest = async (instance,slug)=>
    await instance.get(`/organizations/${slug}`)
    .then(response=>response)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetcompanyDetails(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetcompanyDetailsRequest,axiosInstance, actions.slug);
    if(data.data){
        yield put(getCompanyDetailsSuccess(data.data));
    }else{
        yield put(getCompanyDetailsError(data.error));
    }
    
}
export function* handleGetCompanyDetails(){
    yield takeEvery("HANDLE_GET_COMPANY_DETAILS", GetcompanyDetails)
}
export default function* rootSaga() {
    yield all([fork(handleGetCompanyDetails)]);
}
//
