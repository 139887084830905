export function handleEducationsSearch(data){
    return {
        type:"HANDLE_GET_EDUCATIONS_SEARCH",
        payload:data
    }
}
export function handleSetSearchEducationsSuccess(data){
    return {
        type:"GET_EDUCATIONS_SEARCH",
        payload:data
    }
}

export function handleSetSearchEducationsError(data){
    return{
        type:"GET_EDUCATIONS_SEARCH_FAILLURE",
        payload:data
    }
}
