import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import SortIcon from "@material-ui/icons/ArrowDownward";
import {useDispatch, useSelector} from 'react-redux'
import {  handleGetOpportunitiesDashboard } from 'actions/dashboard/keysList';
import React,{useState,useEffect} from 'react';
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Button } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CardBox from "components/CardBox/index";
import CardMenu from 'components/dashboard/Common/CardMenu';
import {Area, AreaChart, Line, LineChart, ResponsiveContainer} from "recharts";
import ChartCard from "./ChartCard";

const increamentData = [
  {name: 'Page A', pv: 200},
  {name: 'Page B', pv: 1200},
  {name: 'Page C', pv: 600},
  {name: 'Page D', pv: 1600},
  {name: 'Page D', pv: 1000},
  {name: 'Page H', pv: 2260},
  {name: 'Page K', pv: 800},
];


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const columns = [
  {
    name: "Title",
    selector: "title",
    sortable: true,
    cell: row => <div><div className="diaog title-opportunity"><Link  to={`/opportunity/${row.slug}`}>{row.title}</Link></div> <div  className="diaog title-company"><Link  to={`/companies/${row.organization_id.slug}`}>{row.organization_id.name}</Link></div></div>,
  },
  {
    name: "Type",
    selector: "opportunity_type",
    sortable: true
  },
  {
    name: "Views",
    selector: "total_views",
    sortable: true,
    right: true
  },
  {
    name: "Applications",
    selector: "total_applications",
    sortable: true,
    right: true
  },
  {
    name: "Recommendation",
    selector: "pipelined",
    sortable: true,
    right: true
  },
  {
    name: "Date",
    selector: "start_date",
    sortable: true,
    right: true
  }
];

const isIndeterminate = indeterminate => indeterminate;
const selectableRowsComponentProps = { indeterminate: isIndeterminate };

const OpportunitiesDashboard = (props) => {

const [open, setOpen] = React.useState(false);
const dispatch = useDispatch();
const OpportunitiesDashboard = useSelector(state=>state.keys.opportunitiesDashboard.list);
const {KeysListopportunities} = props;
useEffect(()=>{
    dispatch(handleGetOpportunitiesDashboard({}))
  },[dispatch])

console.log("OpportunitiesDashboard " , OpportunitiesDashboard)

const handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    console.log('Selected Rows: ', state.selectedRows);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
   
  return (
   
    <div className="container-wrap container animated slideInUpTiny animation-duration-3 dashboard">
      
      <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
        <h2 className="title mb-3 mb-sm-0">Dashboard</h2>
        <nav className="mb-0" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <Link to="/dashboard" className="breadcrumb-item">Dashboard</Link>
            <span href="/dashboard/opportunities/" className="active breadcrumb-item" aria-current="page">{props.title}</span>
            </ol></nav></div>

        <div className="row  top-20">
      <Card>
        <DataTable
          title="List of opportunities"
          columns={columns}
          data={OpportunitiesDashboard}
          defaultSortField="title"
          sortIcon={<SortIcon/>}
          pagination
          selectableRows
          selectableRowsComponent={Checkbox}
          selectableRowsComponentProps={selectableRowsComponentProps}
          onSelectedRowsChange={handleChange}
        />
      </Card>
    </div>
</div>
  );
};
export default  OpportunitiesDashboard;