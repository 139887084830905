
const initialState = {
    search_industries:{
        error:null,
        spinner:false,
        count:null,
        industries:[]
    },

 }

 export function industries(state = initialState, action){
 
   switch(action.type) {

    case "GET_INDUSTRIES_SEARCH_FAILLURE":
            return {...state, search_industries:{
                ...state.search_industries, error:action.payload, spinner:false, industries:[]
            }}
   case "GET_INDUSTRIES_SEARCH":
           return {...state, search_industries:{
               ...state.search_industries, error:null, spinner:false, industries:action.payload.results
           }}
     default:
       return state;
   }
 }
