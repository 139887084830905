import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { CLIENT_ID } from "config";
import { setFeaturedOppData } from "actions/opportunities";

const FeaturedOppData = async (instance)=>
    await instance.get(`/searches/search/results/featured/opportunities/?`)
    .then(response=>response.data.results.opportunities)
    .catch(error=>error)

function* getOpportunities(){
    const axiosInstance = (state)=>state.users.blankAxiosInstance
    const instance =yield select(axiosInstance);
    const data =yield call(FeaturedOppData,instance);
    yield put(setFeaturedOppData(data));
}
export function* getFeaturedOpportunities(){
    yield takeEvery("HANDLE_GET_FEATURED_OPPORTUNITIES", getOpportunities)
}
export default function* rootSaga() {
    yield all([fork(getFeaturedOpportunities)]);
}