import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles/index";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar/index";
import Tabs from "@material-ui/core/Tabs/index";
import Tab from "@material-ui/core/Tab/index";
import { Parser } from 'html-to-react';
import {
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import IntlMessages from "util/IntlMessages";

import {useSelector} from 'react-redux'
import CustomScrollbars from "util/CustomScrollbars";

const MapWithAMarker = withGoogleMap(props =>

  <GoogleMap
    defaultZoom={8}
    defaultCenter={{ lat: 59.95, lng: 30.33}}
  >
    <Marker
      position={{ lat:59.95 , lng: 30.33}}
      text="text"
    />
  </GoogleMap>
);


const AnyReactComponent = ({ text }) => <div>{text}</div>;

const style = {
  width: '100%',
  height: '200%'
}



function TabContainer({ children, dir }) {
  return (
    <div dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
};

class FullWidthTabs extends Component {
  state = {
    value: 0
  };

  

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  };
  
  render() {
    const { theme, opportunity, width} = this.props;
   
    var parser = new Parser();
    return (
      <div className="w-100">
         
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            scrollButtons="on"
          >
            <Tab className="tab" label={this.props.tab1} />
            <Tab className="tab" label={this.props.tab2} />
            <Tab className="tab" label={this.props.tab3} />
          </Tabs>
        </AppBar>
         
        {opportunity?
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
        >
         
          <TabContainer dir={theme.direction}>
          <div className="row">
          {/* {opportunity.about?
            <div className="col-12">
             <h3 className="">{<IntlMessages id="context_opportunity"/>}</h3>
              <p className="">
                {parser.parse(opportunity.about)}
              </p>
            </div>:null} */}
            {opportunity.description?
            <div className="col-12">
             <h3 className="">{<IntlMessages id="description"/>}</h3>
              <p className="">
                {parser.parse(opportunity.description)}
              </p>
            </div>:null}
            {/* {opportunity.position_summary?
            <div className="col-12">
             <h3 className=""> {<IntlMessages id="position_summary"/>}</h3>
              <p className="">
                {parser.parse(opportunity.position_summary)}
              </p>
            </div>:null}  */}
            {/* {opportunity.qualifications?
            <div className="col-12">
             <h3 className=""> {<IntlMessages id="qualification"/>}</h3>
              <p className="">
                {parser.parse(opportunity.qualifications)}
              </p>
            </div>:null}
            {opportunity.responsibilities?
             opportunity.responsibilities.length >= 10?
            <div className="col-12">
             <h3 className="">{<IntlMessages id="responsibilities"/>}</h3>
              <p className="">
              {
                parser.parse(opportunity.responsibilities)
              }
              </p>
            </div>:null:null}
            {opportunity.requirements?
            <div className="col-12">
             <h3 className="">{<IntlMessages id="requirements"/>}</h3>
             <p className="">
              {parser.parse(opportunity.requirements)}
              </p>
            </div>:null} */}
          </div>
          </TabContainer>
          <TabContainer dir={theme.direction}>
          {opportunity ?
           <div className="row">
            {/* {opportunity.opportunity_type?
            <div className="col-12">
             <h3 className="">{<IntlMessages id="opportunity_type"/>}</h3>
              <p className="">
                {opportunity.opportunity_type}
              </p>
            </div>
            :null} */}

          <div className="col-12">
            {opportunity.categories ?
            <div>
            {opportunity.categories.length !== 0 ?
            <div>
             <h3 className="">{<IntlMessages id="categories"/>}</h3>
              <p className="">
              {opportunity.categories.map((item)=>{
                      return <div>{item.category_id.name}</div>
                    })}
              </p></div>
              :null} 
              </div> 
              :null}
              
            </div>
            <div className="col-12">
            {opportunity.skills ?
            <div>
            {opportunity.skills.length !== 0 ?
            <div>
             <h3 className="">{<IntlMessages id="skills"/>}</h3>
              <p className="">
              {opportunity.skills.map((item)=>{
                      return <div>{item.skill_id.name}</div>
                    })}
              </p></div>
              :null} 
              </div> 
              :null}
              
            </div>

            <div className="col-12">
            {opportunity.educations ?
            <div>
            {opportunity.educations.length !== 0 ?
            <div>
             <h3 className="">{<IntlMessages id="educations"/>}</h3>
              <p className="">
              {opportunity.educations.map((item)=>{
                      return <div>{item.education_id.name}</div>
                    })}
              </p></div>
              :null} 
              </div> 
              :null}
              
            </div>


            <div className="col-12">
            {opportunity.occupations ?
            <div>
            {opportunity.occupations.length !== 0 ?
            <div>
             <h3 className="">{<IntlMessages id="occupations"/>}</h3>
              <p className="">
              {opportunity.occupations.map((item)=>{
                      return <div>{item.occupation_id.name}</div>
                    })}
              </p></div>
              :null} 
              </div> 
              :null}
              
            </div>



            <div className="col-12">
            {opportunity.positions ?
            <div>
            {opportunity.positions.length !== 0 ?
            <div>
             <h3 className="">{<IntlMessages id="positions"/>}</h3>
              <p className="">
              {opportunity.positions.map((item)=>{
                      return <div>{item.position_id.title}</div>
                    })}
              </p></div>
              :null} 
              </div> 
              :null}
              
            </div>


            <div className="col-12">
            {opportunity.areas ?
            <div>
            {opportunity.areas.length !== 0 ?
            <div>
             <h3 className="">{<IntlMessages id="positions"/>}</h3>
              <p className="">
              {opportunity.areas.map((item)=>{
                      return <div>{item.name},&ensp;</div>
                    })}
              </p></div>
              :null} 
              </div> 
              :null}
              
            </div>

            {opportunity.job_contract_type_id ?
            <div className="col-12">
             <h3 className="">{<IntlMessages id="contract_type"/>}</h3>
              <p className="">
                {opportunity.job_contract_type_id.name}
              </p>  
            </div>:null}
            
            {opportunity.employment_type ?
            <div className="col-12">
             <h3 className="">{<IntlMessages id="position_type"/>}</h3>
             <p className="">
                {opportunity.employment_type}
              </p>
            </div>
            :null}
            
            {opportunity.education_level ?
            <div className="col-12">
             <h3 className="">{<IntlMessages id="education_level"/>}</h3>
             <p className="">
                {opportunity.education_level}
              </p>
            </div>:null}
            {opportunity.level_of_experience ?
            <div className="col-12">
             <h3 className="">{<IntlMessages id="experience_level"/>}</h3>
             <p className="">
                {opportunity.level_of_experience}
              </p>
            </div>:null}

          </div>
          : null}

          </TabContainer>
          <TabContainer dir={theme.direction}>
          {opportunity ?
           <div className="row">
             {opportunity.organization_id.about ?
            <div className="col-12">
{/*               
                <MapWithAMarker
                  containerElement={<div style={{ height: `250px` }} />}
                 mapElement={<div style={{ height: `100%` }} />}/> 
              <br/> */}
              <h3 className="">{<IntlMessages id="about"/>} </h3>
             <p>{parser.parse(opportunity.organization_id.about)}</p>
            </div>:null}

            {opportunity.organization_id.website ?
            <div className="col-12 row">
             <h3 className="col-md-4"><label> {<IntlMessages id="website"/>} 
             : </label></h3>
             <p className="col-md-8">
             <a href={opportunity.organization_id.website} target="blank">{opportunity.organization_id.website}</a>
            </p>
            </div>
            :null}
            
            {/* {opportunity.organization_id.primary_email ?  
            <div className="col-12 row">
             <h3 className="col-md-4"><label>{<IntlMessages id="email"/>} : 
             </label></h3>
             
             <p className="col-md-8">
               </p>{opportunity.organization_id.primary_email}
               </p>
            </div>
            :null} */}
            
            {opportunity.organization_id.primary_phone ?
            <div className="col-12 row">
             <h3 className="col-md-4"><label>{<IntlMessages id="phone"/>} : </label> </h3>
             <p className="col-md-8">{opportunity.organization_id.primary_phone}</p>
            </div>:null}
            {opportunity.organization_id.size_range ?
            <div className="col-12 row">
             <h3 className="col-md-4"><label>{<IntlMessages id="size"/>} :  </label></h3>
             <p className="col-md-8">{opportunity.organization_id.size_range}</p>
            </div>:null}

            {opportunity.organization_id.location ?
            <div className="col-12 row">
             <h3 className="col-md-4"><label>{<IntlMessages id="location"/>} :</label> </h3>
             <p className="col-md-8">{opportunity.organization_id.location}</p>
            </div>:null}

            {opportunity.organization_id.headquarters ?
            <div className="col-12 row">
             <h3 className="col-md-4"><label>{<IntlMessages id="headquarters"/>} : </label> </h3>
             <p className="col-md-8">{opportunity.organization_id.headquarters}</p>
            </div>:null}

            {opportunity.organization_id.industry ?
            <div className="col-12 row">
             <h3 className="col-md-4"><label>{<IntlMessages id="industry"/>} :</label> </h3>
             <p className="col-md-8">{opportunity.organization_id.industry}</p>
            </div>:null}

            {opportunity.organization_id.founded_at?
            <div className="col-12 row">
             <h3 className="col-md-4"><label>{<IntlMessages id="founded"/>} :</label></h3>
             <p className="col-md-8">{opportunity.organization_id.founded_at}</p>
            </div>
            :null}
          </div>
          : null}


          </TabContainer>
        </SwipeableViews>
        :null}
      </div>
    );
  }
}

FullWidthTabs.propTypes = {
  theme: PropTypes.object.isRequired
};

export default withStyles(null, { withTheme: true })(FullWidthTabs);