export function handleFilterExperience(data){
    return {
        type:"HANDLE_GET_EXPERIENCE_FILTER",
        payload:data
    }
}
export function handleFilterExperienceSuccess(data){
    return {
        type:"GET_EXPERIENCE_FILTER",
        payload:data
    }
}

export function handleFilterExperienceError(data){
    return{
        type:"GET_EXPERIENCE_FILTER_FAILLURE",
        payload:data
    }
}
