import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Select from '@material-ui/core/Select';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {useDispatch, useSelector} from 'react-redux'
import {Alert} from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { handleResetuploadCvState } from 'actions/uploadCv';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Icon from '@material-ui/core/Icon';
import IntlMessages from "util/IntlMessages";


const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    title: {
    },
    content:{
        padding:15,
        paddingBottom:30,
        overflowY:"hidden"
    },
    paper:{
        backgroundColor:'#e0e0e0'
    },
    upload:{
        width:80,
        height:80
    },
    input: {
      display: 'none',
    },
  }));

const initState={
  datafile:null,
  email:null,
  first_name:null,
  last_name:null,
  name:null,
  document_type:null,
  current_file:null,
  datafile_coverletter:"",
  datafile_portfolio:""
}
export default function UploadCV(props) {

  const classes = useStyles();
  const [state, setState] =useState(initState);
  const dispatch = useDispatch()
  const addfile = props.addfile;
  const uploadCvState= useSelector(state=>state.uploadCv.upload_cv);
  const userState= useSelector(state=>state.users.authenticated);
  const loader=<CircularProgress  color="primary"/>;
  const handleSubmit=(event)=>{
    event.preventDefault();
    const formData = new FormData(); 
    if(props.opportunity_id) {
      formData.append('opportunity_id', props.opportunity_id);
    }
    if(state.first_name !== null){
      formData.append("first_name", state.first_name);
    }
    if(state.last_name !== null){
    formData.append("last_name", state.last_name);
    }
     if(state.phone_number !== null){
    formData.append("phone_number", state.phone_number);
    }
    if(state.email !== null){
      formData.append("email", state.email);
      }
      if(state.name !== null){
        formData.append("name", state.name);
      }
      if(state.document_type !== null){
        formData.append("document_type", state.document_type);
      }
    formData.append("datafile", state.datafile);
    formData.append("datafile_portfolio", state.datafile_portfolio)
     formData.append("datafile_coverletter", state.datafile_coverletter);
    dispatch({type:"HANDLE_UPLOAD_CV", data:formData})
  }
  const handleChange= (event) =>{
      setState({...state, [event.target.name]: event.target.value})
  }


  const handleChangeFile=(event)=>{
    setState({...state, [event.target.name]:event.target.files[0], current_file:event.target.value})
  }
  const {t}=props;
  return (
    <React.Fragment>
    <Dialog fullScreen={false} maxWidth="sm" fullWidth={true} open={props.open} onClose={props.handleClose}>
        <AppBar className={classes.appBar}>
        <Toolbar>
            <Grid container>
                <Grid md={10} xs={10} item container justify="left">
                {addfile ?
                  <Typography variant="h6" className={classes.title}>{<IntlMessages id="add_files"/>}</Typography>
                  :
                  <Typography variant="h6" className={classes.title}>{<IntlMessages id="upload_my_cv"/>}</Typography>
                    
                }
                    </Grid>
                <Grid  md={2}  xs={2} item container justify="flex-end"> 
                    <IconButton edge="start" color="inherit" aria-label="close" onClick={props.handleClose}>
                        <CloseIcon />
                    </IconButton>   
                </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
         {/*  */}
         <DialogContent >
         <form enctype="multipart/form-data" onSubmit={handleSubmit}>
        <Grid container spacing={3} className={classes.content}>
        <Grid item xs={12} sm={12}>
        {addfile ?null:
            <p>
              {<IntlMessages id="upload_my_cv_body"/>}
            </p>
        }
        </Grid>
        <Grid xs={12}>
        {uploadCvState.message?<Alert severity="success" onClose={()=>dispatch(handleResetuploadCvState())}>{uploadCvState.message}</Alert>:null}
        {uploadCvState.error?<Alert severity="error" onClose={()=>dispatch(handleResetuploadCvState())}>{uploadCvState.error}</Alert>:null}
        </Grid>
        {userState?null:<Grid item xs={12} sm={12}>
          <TextField
            required
            id="firstName"
            name="first_name"
            label={<IntlMessages id="first_name"/>}
            fullWidth
            autoComplete="given-name"
            value={state.first_name}
            onChange={handleChange}
          />
        </Grid>}
        {userState?null:<Grid item xs={12} sm={12}>
          <TextField
            required
            id="last_name"
            name="last_name"
            label={<IntlMessages id="last_name"/>}
            fullWidth
            autoComplete="family-name"
            value={state.last_name}
            onChange={handleChange}
          />
        </Grid>}
        {addfile?<Grid item xs={12} sm={12}>
          <TextField
            required
            id="name"
            name="name"
            label={<IntlMessages id="document_name"/>}
            fullWidth
            autoComplete="family-name"
            value={state.last_name}
            onChange={handleChange}
          />
        </Grid>: null}
        {addfile?<Grid item xs={12} sm={12}>
        <FormControl variant="outlined">
                    <InputLabel htmlFor="filled-age-native-simple">{<IntlMessages id="document_type"/>}</InputLabel>
                    <Select
                    native
                    value={state.document_type}
                    onChange={handleChange}
                    inputProps={{
                        name: 'document_type',
                        id: 'filled-age-native-simple',
                    }}
                    >
                    <option aria-label="None" value="" />
                    <option value={10}>{t("wutiko.cv")}</option>
                    <option value={10}>{t("wutiko.motivation_letter")}</option>
                    <option value={10}>{t("wutiko.certificate_diploma_degree")}</option>
                    <option value={20}>{t("wutiko.english_proficiency_certificate")} </option>
                    <option value={30}>{t("wutiko.others")}</option>
                    </Select>
                </FormControl>
        </Grid>: null}
        {userState?null:<Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="email"
            label={<IntlMessages id="email"/>}
            fullWidth
            autoComplete="Email"
            value={state.email}
            onChange={handleChange}
          />
        </Grid>}
         {userState?null:<Grid item xs={12}>
          <TextField
            required
            id="phone_number"
            name="phone_number"
            label={<IntlMessages id="phone_number"/>}
            fullWidth
            autoComplete="Phone Number"
            value={state.phone_number}
            onChange={handleChange}
          />
        </Grid>}
        <Grid item xs={12} sm={12}>
        <input accept=".pdf, .doc, .docx" name="datafile" className={classes.input} id="icon-button-file" type="file" onChange={handleChangeFile}/>
         <Paper className={classes.paper} htmlFor="icon-button-file">
            <Grid container spacing={3}>
                <Grid item container justify="center">
                <label htmlFor="icon-button-file">
                    <IconButton color="primary" aria-label="upload picture" component="span">
                        <CloudUploadIcon className={classes.upload}/>
                    </IconButton>
                </label>
                </Grid>
                <Grid item container justify="center">
                   <center>{state.current_file}</center>
                </Grid>
            </Grid>
         </Paper>   
        </Grid>
        {userState?null:
        <Grid item xs={12} sm={12}  className="terms_and_conditions">
            <center  className="UploadCv">
            {<IntlMessages id="upload_my_cv_footer"
            values={{ terms_and_conditions : <a target="blank" href="https://pages.wutiko.com/en/legal/terms-and-conditions/"> <IntlMessages id="terms_and_conditions"/> </a>}}
            /> 
            }
            </center>   
        </Grid>}
       
        <Grid item xs={12} className="top-20">
             {uploadCvState.spinner?loader:<Button variant="contained" type="submit" color="primary"> {<IntlMessages id="send"/>}</Button>}
        </Grid>
        </Grid>
        </form>
        </DialogContent>
         {/*  */}
      </Dialog>   
    </React.Fragment>
  );
}
UploadCV = (withTranslation('translation')(UploadCV));
