import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import SortIcon from "@material-ui/icons/ArrowDownward";
import {  handleGetFollowersDashboard } from 'actions/dashboard/keysList';
import React,{useState,useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Button } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CardBox from "components/CardBox/index";
import CardMenu from 'components/dashboard/Common/CardMenu';
import {Area, AreaChart, Line, LineChart, ResponsiveContainer} from "recharts";
import ChartCard from "./ChartCard";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import ItemFollowers from './ItemFollowers'
import {arrayMove, SortableContainer} from 'react-sortable-hoc';
import ItemOrganization from "./itemOrganization";
import { handleGetCompaniesAdmin } from "actions/companies/getCompanies";
import ReactPaginate from "react-paginate";
import Grid from '@material-ui/core/Grid';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function OrganizationDashboard(props) {
const dispatch = useDispatch();
const RESULTS_PER_PAGE = 12;
const state = useSelector(state=>state.company.all_companies_admin);
const spinner = useSelector(state=>state.company.all_companies_admin.spinner);
const companiesListAdmin =state.list?state.list:[];
const [currentPage, setcurrentPage] = React.useState("1");
const count = useSelector(state=>state.company.all_companies_admin.count);
const Contacts = SortableContainer(({contacts}) => {
  return (
    <div className="row">
      {props.spinner?
          <Grid container item justify="center" xs={12}><CircularProgress size={80}/></Grid>:
        companiesListAdmin.map((contact, index) => (
        <ItemOrganization key={index} index={index}  item={contact}/>
      ))} 
    </div>
  );
});

  const [contacts, setContacts] = useState(companiesListAdmin);

  console.log("contact 2 " , contacts)
  const onSortEnd = ({oldIndex, newIndex}) => {
    setContacts(arrayMove(contacts, oldIndex, newIndex))
  };
  useEffect(()=>{
    dispatch(handleGetCompaniesAdmin({page:`${currentPage}`}))
  },[dispatch])
  
  const handlePageClick=(newPage) =>{
    setcurrentPage(newPage.selected + 1)
    dispatch(handleGetCompaniesAdmin({page:`${newPage.selected + 1}`}));
  } 
  
      let pageCount;
      let onPageChange;
  
  
      pageCount = Math.ceil(count / RESULTS_PER_PAGE);
      onPageChange = handlePageClick;
  

  return (
    
    <div className="container-wrap container animated slideInUpTiny animation-duration-3 dashboard organization">
       <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
       <h2 className="title mb-3 mb-sm-0">{<IntlMessages id="my_organization" />}</h2>
        {/* <nav className="mb-0" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <Link to="/dashboard" className="breadcrumb-item">Dashboard</Link>
            <span href="#" className="active breadcrumb-item" aria-current="page">{props.title}</span>
            </ol></nav> */}
            </div>
        <div className="animated slideInUpTiny animation-duration-3">
        <Contacts contacts={contacts} onSortEnd={onSortEnd} useDragHandle={true}/>
        <Grid item md={12} xs={12} container justify="center" className="Listpagination">
        <ReactPaginate
        previousLabel={<IntlMessages id="previous" />}
        nextLabel={<IntlMessages id="next" />}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
        </Grid>
    </div>
    </div>
  );
};