import React, {Component} from 'react';
import {Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem} from 'reactstrap';
import IntlMessages from "util/IntlMessages";
import { Link } from '@material-ui/core';
import ContactForm from 'components/formsElements/ContactForm';
const items = [
    {
      id: 1,
      altText: <IntlMessages id="about_page_who_we_are_description"/>,
      caption:  <IntlMessages id="about_page_who_we_are_title"/>,
      link:"#",
      src: "https://cdn.wutiko.com/v3/static/images/slideshows/low/wutiko_slideshow_happyness.jpeg"
    },
    {
      id: 2,
      altText: <IntlMessages id="about_page_our_solutions_description"/>,
      caption:  <IntlMessages id="about_page_our_solutions_title"/>,
      link:"#",
      src: "https://cdn.wutiko.com/v3/static/images/slideshows/low/wutiko_slideshow_lagos_people.jpeg"
    }, {
      id: 3,
      altText: <IntlMessages id="about_page_how_does_it_work_description"/>,
      caption:  <IntlMessages id="about_page_how_does_it_work_title"/>,
      link:"#",
      src: "https://cdn.wutiko.com/v3/static/images/slideshows/low/wutiko_about_how_it_works_fr.jpg"
    }, 
    {
      id: 4,
      altText: <IntlMessages id="about_page_wutiko_for_business_description"/>,
      caption:  <IntlMessages id="about_page_wutiko_for_business_title"/>,
      link:"#",
      src: "https://cdn.wutiko.com/v3/static/images/slideshows/low/wutiko_about_dashboard_en.jpg"
    }, 
    {
      id: 5,
      altText: <IntlMessages id="about_page_our_locations_description"/>,
      caption:  <IntlMessages id="about_page_our_locations_title"/>,
      link:"#",
      src: "https://cdn.wutiko.com/v3/static/images/slideshows/low/wutiko_about_locations.jpg"
    }, 
    {
      id: 6,
      altText: <IntlMessages id="about_page_opportunities_in_africa_description"/>,
      caption:  <IntlMessages id="about_page_opportunities_in_africa_title"/>,
      link:"#",
      src: "https://cdn.wutiko.com/v3/static/images/slideshows/low/wutiko_slideshow_lagos_ikoyi_bridge_02.jpeg"
    }
  ];

class AboutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {activeIndex: 0, openModal: false};
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({activeIndex: nextIndex});
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({activeIndex: nextIndex});
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({activeIndex: newIndex});
  }

   handleOpenModal = () => {
    this.setState({openModal: true});
  }
   handleClose= () => {
    this.setState({openModal: false});
  }
  render() {
    const {activeIndex} = this.state;

    const slides = items.map((item) => {
      return (
        <CarouselItem
          key={item.id}
          onExiting={this.onExiting}
          onExited={this.onExited}>
        <div className="row slideshowHome about">
        <div className="col-md-6 col-6 slideshow">
        <CarouselCaption className="text-danger" captionText={item.altText} captionHeader={item.caption}/>
        <a href="#" onClick={this.handleOpenModal}><IntlMessages id="learn_more"/></a>
        </div>         
        <div className="col-md-6 col-6">
            <a href={item.link}>
            <div className="play-button-container">
             <div className="play-button">
                 <svg>
            <polygon points="10 33 10 1 34 17"></polygon>
            </svg>
            </div>
            </div></a>
            <img src={item.src} alt={item.altText}/></div>  
        </div>  
        </CarouselItem>
      );
    });

    return (
      
        <div className="about_page">
          <ContactForm open={this.state.openModal}  handleClose={this.handleClose}/>
        <div className="row slideshowHome about">
        <div className="col-md-6 col-6 slideshow">
        <h1> {items[0].caption}</h1>
        <div className="bottom"></div>
        <p>{items[0].altText} </p>
        <a href="#" onClick={this.handleOpenModal}><IntlMessages id="learn_more"/></a>
        </div>         
        <div className="col-md-6 col-6 img-slideshowHome">
        <a href="#">
        <div className="play-button-container">
        <div className="play-button">
        <svg>
        <polygon points="10 33 10 1 34 17"></polygon>
        </svg>
        </div>
        </div>
        </a>
        <img src={items[0].src} alt={items[0].altText}/>
        </div>  
        </div>  




        <div className="row slideshowHome about">
        <div className="col-md-6 col-6 img-slideshowHome">
        <a href="#">
        <div className="play-button-container">
        <div className="play-button">
        <svg>
        <polygon points="10 33 10 1 34 17"></polygon>
        </svg>
        </div>
        </div>
        </a>
        <img src={items[1].src} alt={items[1].altText}/>
        </div>  
        <div className="col-md-6 col-6 slideshow">
        <h1>{items[1].caption} </h1>
              <div className="bottom"></div>
        <p> {items[1].altText} </p>
        <a href="#" onClick={this.handleOpenModal}><IntlMessages id="learn_more"/></a>
        </div>   
        </div>  


        <div className="row slideshowHome about">
        <div className="col-md-6 col-6 slideshow">
        <h1> {items[2].caption}</h1>
              <div className="bottom"></div>
        <p>{items[2].altText}</p>
        <a href="#" onClick={this.handleOpenModal}><IntlMessages id="learn_more"/></a>
        </div>         
        <div className="col-md-6 col-6 img-slideshowHome">
            <a href="#">
            <div className="play-button-container">
             <div className="play-button">
                 <svg>
            <polygon points="10 33 10 1 34 17"></polygon>
            </svg>
            </div>
            </div></a>
            <img src={items[2].src} alt={items[2].altText}/>
        </div>  
        </div>  



            <div className="row slideshowHome about">
             <div className="col-md-6 col-6 img-slideshowHome">
                 <a href="#">
                 <div className="play-button-container">
                  <div className="play-button">
                      <svg>
                 <polygon points="10 33 10 1 34 17"></polygon>
                 </svg>
                 </div>
                 </div></a>
                 <img src={items[3].src} alt={items[3].altText}/>
             </div>  
             <div className="col-md-6 col-6 slideshow">
             <h1>{items[3].caption}</h1>
             <div className="bottom"></div>
             <p>{items[3].altText}</p>
             <a href="#" onClick={this.handleOpenModal}><IntlMessages id="learn_more"/></a>
             </div>   
             </div>  




        <div className="row slideshowHome about">
        <div className="col-md-6 col-6 slideshow">
        <h1> {items[4].caption} </h1>
        <div className="bottom"></div>
        <p>{items[4].altText}</p>
        <a href="#" onClick={this.handleOpenModal}><IntlMessages id="learn_more"/></a>
        </div>         
        <div className="col-md-6 col-6 img-slideshowHome">
        <a href="#">
        <div className="play-button-container">
        <div className="play-button">
        <svg>
        <polygon points="10 33 10 1 34 17"></polygon>
        </svg>
        </div>
        </div></a>
        <img src={items[4].src} alt={items[4].altText}/>
        </div>  
        </div> 




        <div className="row slideshowHome about">
             <div className="col-md-6 col-6 img-slideshowHome pont">
                 <a href="#">
                 <div className="play-button-container">
                  <div className="play-button">
                      <svg>
                 <polygon points="10 33 10 1 34 17"></polygon>
                 </svg>
                 </div>
                 </div></a>
                 <img src={items[5].src} alt={items[5].altText}/>
             </div>  
             <div className="col-md-6 col-6 slideshow">
             <h1>{items[5].caption}</h1>
             <div className="bottom"></div>
             <p>{items[5].altText}</p>
             <a href="#" onClick={this.handleOpenModal}><IntlMessages id="learn_more"/></a>
             </div>   
             </div>  
</div>  
    );
  }
}

export default AboutPage;