import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { handleFilterAssessedSuccess, handleFilterAssessedError } from "actions/filters/assessed";

// 
const setFilterAssessed = async (instance,actions)=>
    await instance.get(`/searches/search/assessed/pipelined/${actions.search}`,actions)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            return error.response.data
        }
    })

function* FilterAssessed(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(setFilterAssessed,axiosInstance,actions.payload);
    if(data.results){
            yield put(handleFilterAssessedSuccess(data));
    }else{
           yield put(handleFilterAssessedError(data.error));
    }
    
}

export function* handleFilterAssessed(){
    yield takeEvery("HANDLE_GET_ASSESSED_FILTER", FilterAssessed)
}

export default function* rootSaga() {
    yield all([fork(handleFilterAssessed)]);
}