import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { GetBestTalentsDashboardSuccess, GetBestTalentsDashboardError,GetKeysDashboardSuccess, 
GetKeysDashboardError, GetTopLocationsDashboardSuccess, GetTopLocationsDashboardError, 
GetApplicationsDashboardSuccess, GetApplicationsDashboardError, 
GetApplicationsSharedDashboardSuccess, GetApplicationsSharedDashboardError,
GetMatchingDashboardSuccess, GetOpportunitiesDashboardError, 
GetOpportunitiesDashboardSuccess, GetMatchingDashboardError, 
GetFollowersDashboardSuccess, GetFollowersDashboardError, 
GetScoresDashboardSuccess, GetScoresDashboardError, 
GetRecentActivitiesDashboardSuccess, GetRecentActivitiesDashboardError,
GetCurrentPlanDashboardSuccess, GetCurrentPlanDashboardError, GetApplicationsRecommendationsDashboardSuccess, GetApplicationsRecommendationsDashboardError, GetApplicationsDashboardSelectedAppSuccess, GetApplicationsDashboardSelectedAppError, GetApplicationsSelectedRecDashboardSuccess, GetApplicationsSelectedRecDashboardError, GetApplicationsSelectedSharedDashboardError, GetApplicationsSelectedSharedDashboardSuccess, GetSpontaneousApplyDashboardSelectedSuccess, GetSpontaneousApplyDashboardSelectedError,
GetOpportunitiesOnlineDashboardSuccess,
GetOpportunitiesOfflineDashboardSuccess,
GetOpportunitiesDeleteDashboardSuccess,
GetOpportunitiesOnlineDashboardError,
GetOpportunitiesOfflineDashboardError,
GetOpportunitiesDeleteDashboardError,
GetApplicationsDashboardFormSuccess,
GetApplicationsDashboardFormError,
GetApplicationsDashboardFormSelectSuccess,
GetApplicationsDashboardFormSelectError
} from  "actions/dashboard/keysList";
import {GetConnexionsDashboardSuccess ,GetConnexionsDashboardError, GetOpportunitiesApplyDashboardSuccess, GetOpportunitiesApplyDashboardError, GetSpontaneousApplyDashboardSuccess, GetSpontaneousApplyDashboardError, GetSearchesAppearenceDashboardSuccess,
    GetSearchesAppearenceDashboardError, GetViewProfilDashboardSuccess, GetViewProfilDashboardError, GetSpontaneousApplyDashboardPipelineSuccess, GetSpontaneousApplyDashboardPipelineError} from "actions/dashboard/profil"
const GetKeysDashboardRequest = async (instance)=>
    await instance.get(`/opportunities/recruiter/dashboard/applications/data/list/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetKeysDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetKeysDashboardRequest, axiosInstance, actions);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetKeysDashboardSuccess(data.data));
    }else{
        yield put(GetKeysDashboardError(data.error));
    }
    
}
export function* handleGetKeysDashboard(){
    yield takeEvery("HANDLE_GET_KEYS_DASHBOARD", GetKeysDashboard)
}



const GetBestTalentsDashboardRequest = async (instance)=>
    await instance.get(`/opportunities/best/talents/list/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetBestTalentsDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetBestTalentsDashboardRequest, axiosInstance, actions);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetBestTalentsDashboardSuccess(data.data));
    }else{
        yield put(GetBestTalentsDashboardError(data.error));
    }
    
}
export function* handleGetBestTalentsDashboard(){
    yield takeEvery("HANDLE_GET_BEST_TALENTS_DASHBOARD", GetBestTalentsDashboard)
}





const GetTopLocationsDashboardRequest = async (instance)=>
    await instance.get(`/opportunities/dashboard/top/locations/list`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetTopLocationsDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetTopLocationsDashboardRequest, axiosInstance, actions);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetTopLocationsDashboardSuccess(data.data));
    }else{
        yield put(GetTopLocationsDashboardError(data.error));
    }
    
}
export function* handleGetTopLocationsDashboard(){
    yield takeEvery("HANDLE_GET_TOP_LOCATIONS_DASHBOARD", GetTopLocationsDashboard)
}






const GetApplicationsDashboardRequest = async (instance,actions)=>
    await instance.get(`/opportunities/applications/pipelines/list/?${actions.search}&page=${actions.page}`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetApplicationsDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetApplicationsDashboardRequest, axiosInstance, actions.data);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetApplicationsDashboardSuccess(data.data));
    }else{
        yield put(GetApplicationsDashboardError(data.error));
    }
    
}
export function* handleGetApplicationsDashboard(){
    yield takeEvery("HANDLE_GET_APPLICATIONS_DASHBOARD", GetApplicationsDashboard)
}




const GetApplicationsDashboardFormRequest = async (instance,actions)=>
    await instance.get(`/opportunities/call/for/application/list/?${actions.search}&page=${actions.page}`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetApplicationsDashboardForm(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetApplicationsDashboardFormRequest, axiosInstance, actions.data);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetApplicationsDashboardFormSuccess(data.data));
    }else{
        yield put(GetApplicationsDashboardFormError(data.error));
    }
    
}
export function* handleGetApplicationsDashboardForm(){
    yield takeEvery("HANDLE_GET_APPLICATIONS_DASHBOARD_FORM", GetApplicationsDashboardForm)
}





const GetApplicationsDashboardFormSelectRequest = async (instance,actions)=>
    await instance.get(`/opportunities/call/for/application/selected/list/?${actions.search}&page=${actions.page}`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetApplicationsDashboardFormSelect(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetApplicationsDashboardFormSelectRequest, axiosInstance, actions.data);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetApplicationsDashboardFormSelectSuccess(data.data));
    }else{
        yield put(GetApplicationsDashboardFormSelectError(data.error));
    }
    
}
export function* handleGetApplicationsDashboardFormSelect(){
    yield takeEvery("HANDLE_GET_APPLICATIONS_DASHBOARD_FORM_SELECTED", GetApplicationsDashboardFormSelect)
}


const GetApplicationsSharedDashboardRequest = async (instance,actions)=>
    await instance.get(`/activities/profiles/was-shared/pipelined/list/?${actions.search}&page=${actions.page}`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetApplicationsSharedDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetApplicationsSharedDashboardRequest, axiosInstance, actions.data);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetApplicationsSharedDashboardSuccess(data.data));
    }else{
        yield put(GetApplicationsSharedDashboardError(data.error));
    }
    
}
export function* handleGetApplicationsSharedDashboard(){
    yield takeEvery("HANDLE_GET_APPLICATIONS_SHARED_DASHBOARD", GetApplicationsSharedDashboard)
}





const GetApplicationsRecommendationsDashboardRequest = async (instance,actions)=>
    await instance.get(`/opportunities/general/ranked-profiles/pipelines/list/?${actions.search}&page=${actions.page}`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetApplicationsRecommendationsDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetApplicationsRecommendationsDashboardRequest, axiosInstance, actions.data);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetApplicationsRecommendationsDashboardSuccess(data.data));
    }else{
        yield put(GetApplicationsRecommendationsDashboardError(data.error));
    }
    
}
export function* handleGetApplicationsRecommendationsDashboard(){
    yield takeEvery("HANDLE_GET_APPLICATIONS_RECOMMENDATIONS_DASHBOARD", GetApplicationsRecommendationsDashboard)
}





const GetOpportunitiesDashboardRequest = async (instance,actions)=>
actions.search?
    await instance.get(`/opportunities/admin/list/${actions.search}&page=${actions.page}`):
    await instance.get(`/opportunities/admin/list/?page=${actions.page}`)
    .then(response=>response)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetOpportunitiesDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data = yield call(GetOpportunitiesDashboardRequest, axiosInstance, actions.data);
    
    if(data.data){

    console.log("Get Data GetOpportunitiesDashboardSuccess",  data.data)
        yield put(GetOpportunitiesDashboardSuccess(data.data));
    }else{
        yield put(GetOpportunitiesDashboardError(data.error));
    }
    
}
export function* handleGetOpportunitiesDashboard(){
    yield takeEvery("HANDLE_GET_OPPORTUNITIES_DASHBOARD", GetOpportunitiesDashboard)
}




const GetOpportunitiesOnlineDashboardRequest = async (instance,actions)=>
actions.search?
    await instance.get(`/opportunities/admin/list/${actions.search}&status=online&page=${actions.page}`):
    await instance.get(`/opportunities/admin/list/?status=online&page=${actions.page}`)
    .then(response=>response)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetOpportunitiesOnlineDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data = yield call(GetOpportunitiesOnlineDashboardRequest, axiosInstance, actions.data);
    
    if(data.data){

    console.log("Get Data GetOpportunitiesOnlineDashboardSuccess",  data.data)
        yield put(GetOpportunitiesOnlineDashboardSuccess(data.data));
    }else{
        yield put(GetOpportunitiesOnlineDashboardError(data.error));
    }
    
}
export function* handleGetOpportunitiesOnlineDashboard(){
    yield takeEvery("HANDLE_GET_OPPORTUNITIES_ONLINE_DASHBOARD", GetOpportunitiesOnlineDashboard)
}




const GetOpportunitiesOfflineDashboardRequest = async (instance,actions)=>
actions.search?
    await instance.get(`/opportunities/admin/list/${actions.search}&status=offline&page=${actions.page}`):
    await instance.get(`/opportunities/admin/list/?status=offline&page=${actions.page}`)
    .then(response=>response)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetOpportunitiesOfflineDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data = yield call(GetOpportunitiesOfflineDashboardRequest, axiosInstance, actions.data);
    
    if(data.data){

    console.log("Get Data GetOpportunitiesOfflineDashboardSuccess",  data.data)
        yield put(GetOpportunitiesOfflineDashboardSuccess(data.data));
    }else{
        yield put(GetOpportunitiesOfflineDashboardError(data.error));
    }
    
}
export function* handleGetOpportunitiesOfflineDashboard(){
    yield takeEvery("HANDLE_GET_OPPORTUNITIES_OFFLINE_DASHBOARD", GetOpportunitiesOfflineDashboard)
}



const GetOpportunitiesDeleteDashboardRequest = async (instance,actions)=>
actions.search?
    await instance.get(`/opportunities/admin/list/${actions.search}&status=delete&page=${actions.page}`):
    await instance.get(`/opportunities/admin/list/?status=delete&page=${actions.page}`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetOpportunitiesDeleteDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data = yield call(GetOpportunitiesDeleteDashboardRequest, axiosInstance, actions.data);
    
    if(data.data){

    console.log("Get Data GetOpportunitiesDeleteDashboardSuccess",  data.data)
        yield put(GetOpportunitiesDeleteDashboardSuccess(data.data));
    }else{
        yield put(GetOpportunitiesDeleteDashboardError(data.error));
    }
    
}
export function* handleGetOpportunitiesDeleteDashboard(){
    yield takeEvery("HANDLE_GET_OPPORTUNITIES_DELETE_DASHBOARD", GetOpportunitiesDeleteDashboard)
}





const GetMatchingDashboardRequest = async (instance)=>
    await instance.get(`/opportunities/matching/list/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetMatchingDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetMatchingDashboardRequest, axiosInstance, actions);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetMatchingDashboardSuccess(data.data));
    }else{
        yield put(GetMatchingDashboardError(data.error));
    }
    
}
export function* handleGetMatchingDashboard(){
    yield takeEvery("HANDLE_GET_MATCHING_DASHBOARD", GetMatchingDashboard)
}




const GetFollowersDashboardRequest = async (instance)=>
    await instance.get(`/opportunities/favorites/profiles/list/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetFollowersDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetFollowersDashboardRequest, axiosInstance, actions);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetFollowersDashboardSuccess(data.data));
    }else{
        yield put(GetFollowersDashboardError(data.error));
    }
    
}
export function* handleGetFollowersDashboard(){
    yield takeEvery("HANDLE_GET_FOLLOWERS_DASHBOARD", GetFollowersDashboard)
}





const GetScoresDashboardRequest = async (instance)=>
    await instance.get(`/opportunities/scores/summary/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetScoresDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetScoresDashboardRequest, axiosInstance, actions);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetScoresDashboardSuccess(data.data));
    }else{
        yield put(GetScoresDashboardError(data.error));
    }
    
}
export function* handleGetScoresDashboard(){
    yield takeEvery("HANDLE_GET_SCORES_DASHBOARD", GetScoresDashboard)
}






const GetRecentActivitiesDashboardRequest = async (instance)=>
    await instance.get(`/profiles/recent/activities/list/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetRecentActivitiesDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetRecentActivitiesDashboardRequest, axiosInstance, actions);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetRecentActivitiesDashboardSuccess(data.data));
    }else{
        yield put(GetRecentActivitiesDashboardError(data.error));
    }
    
}
export function* handleGetRecentActivitiesDashboard(){
    yield takeEvery("HANDLE_GET_RECENT_ACTIVITIES_DASHBOARD", GetRecentActivitiesDashboard)
}



const GetCurrentPlanDashboardRequest = async (instance)=>
    await instance.get(`/profiles/current/plan/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetCurrentPlanDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetCurrentPlanDashboardRequest, axiosInstance, actions);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetCurrentPlanDashboardSuccess(data.data));
    }else{
        yield put(GetCurrentPlanDashboardError(data.error));
    }
    
}
export function* handleGetCurrentPlanDashboard(){
    yield takeEvery("HANDLE_GET_CURRENT_PLAN_DASHBOARD", GetCurrentPlanDashboard)
}





const GetConnexionsDashboardRequest = async (instance)=>
    await instance.get(`/profiles/connexions/list/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetConnexionsDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetConnexionsDashboardRequest, axiosInstance, actions);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetConnexionsDashboardSuccess(data.data));
    }else{
        yield put(GetConnexionsDashboardError(data.error));
    }
    
}
export function* handleGetConnexionsDashboard(){
    yield takeEvery("HANDLE_GET_CONNEXIONS_DASHBOARD", GetConnexionsDashboard)
}






const GetOpportunitiesApplyDashboardRequest = async (instance)=>
    await instance.get(`/opportunities/apply/list/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetOpportunitiesApplyDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetOpportunitiesApplyDashboardRequest, axiosInstance, actions);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetOpportunitiesApplyDashboardSuccess(data.data));
    }else{
        yield put(GetOpportunitiesApplyDashboardError(data.error));
    }
    
}
export function* handleGetOpportunitiesApplyDashboard(){
    yield takeEvery("HANDLE_GET_OPPORTUNITIES_APPLY_DASHBOARD", GetOpportunitiesApplyDashboard)
}







const GetSpontaneousApplyDashboardPipelinesRequest = async (instance, actions)=>
    await instance.get(`/profiles/organizations/spontaneous/applications/pipelined/list/?${actions.search}&page=${actions.page}`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetSpontaneousApplyDashboardPipeline(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetSpontaneousApplyDashboardPipelinesRequest, axiosInstance, actions.data);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetSpontaneousApplyDashboardPipelineSuccess(data.data));
    }else{
        yield put(GetSpontaneousApplyDashboardPipelineError(data.error));
    }
    
}
export function* handleGetSpontaneousApplyDashboardPipeline(){
    yield takeEvery("HANDLE_GET_SPONTANEOUS_APPLY_DASHBOARD_PIPELINE", GetSpontaneousApplyDashboardPipeline)
}



const GetSpontaneousApplyDashboardRequest = async (instance)=>
    await instance.get(`/profiles/organizations/spontaneous/applied/list/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetSpontaneousApplyDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetSpontaneousApplyDashboardRequest, axiosInstance, actions);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetSpontaneousApplyDashboardSuccess(data.data));
    }else{
        yield put(GetSpontaneousApplyDashboardError(data.error));
    }
    
}
export function* handleGetSpontaneousApplyDashboard(){
    yield takeEvery("HANDLE_GET_SPONTANEOUS_APPLY_DASHBOARD", GetSpontaneousApplyDashboard)
}






const GetSearchesAppearenceDashboardRequest = async (instance)=>
    await instance.get(`/profiles/searches/appearence/list/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetSearchesAppearenceDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data = yield call(GetSearchesAppearenceDashboardRequest, axiosInstance, actions);
    
    if(data.data){

    console.log("Get Data GetSearchesAppearenceDashboardSuccess ",  data.data)
        yield put(GetSearchesAppearenceDashboardSuccess(data.data));
    }else{
        yield put(GetSearchesAppearenceDashboardError(data.error));
    }
    
}
export function* handleGetSearchesAppearenceDashboard(){
    yield takeEvery("HANDLE_GET_SEARCH_APPEARENCE_DASHBOARD", GetSearchesAppearenceDashboard)
}







const GetViewProfilDashboardRequest = async (instance)=>
    await instance.get(`/profiles/views/list/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetViewProfilDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetViewProfilDashboardRequest, axiosInstance, actions);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetViewProfilDashboardSuccess(data.data));
    }else{
        yield put(GetViewProfilDashboardError(data.error));
    }
    
}
export function* handleGetViewProfilDashboard(){
    yield takeEvery("HANDLE_GET_VIEW_PROFIL_DASHBOARD", GetViewProfilDashboard)
}





const GetApplicationsSelectAppDashboardRequest = async (instance,actions)=>
    await instance.get(`/opportunities/applications/selected/list/?${actions.search}&page=${actions.page}`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetApplicationsSelectedAppDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetApplicationsSelectAppDashboardRequest, axiosInstance, actions.data);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetApplicationsDashboardSelectedAppSuccess(data.data));
    }else{
        yield put(GetApplicationsDashboardSelectedAppError(data.error));
    }
    
}
export function* handleGetApplicationsSelectedAppDashboard(){
    yield takeEvery("HANDLE_GET_APPLICATIONS_SELECTED_APP_DASHBOARD", GetApplicationsSelectedAppDashboard)
}





const GetApplicationsSelectRecDashboardRequest = async (instance,actions)=>
    await instance.get(`/opportunities/general/ranked-profiles/selected/list/?${actions.search}&page=${actions.page}`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetApplicationsSelectedRecDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetApplicationsSelectRecDashboardRequest, axiosInstance, actions.data);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetApplicationsSelectedRecDashboardSuccess(data.data));
    }else{
        yield put(GetApplicationsSelectedRecDashboardError(data.error));
    }
    
}
export function* handleGetApplicationsSelectedRecDashboard(){
    yield takeEvery("HANDLE_GET_APPLICATIONS_SELECTED_REC_DASHBOARD", GetApplicationsSelectedRecDashboard)
}





const GetApplicationsSelectSharedDashboardRequest = async (instance,actions)=>
    await instance.get(`/activities/profiles/was-shared/selected/list/?${actions.search}&page=${actions.page}`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetApplicationsSelectedSharedDashboard(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetApplicationsSelectSharedDashboardRequest, axiosInstance, actions.data);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetApplicationsSelectedSharedDashboardSuccess(data.data));
    }else{
        yield put(GetApplicationsSelectedSharedDashboardError(data.error));
    }
    
}
export function* handleGetApplicationsSelectedSharedDashboard(){
    yield takeEvery("HANDLE_GET_APPLICATIONS_SELECTED_SHARED_DASHBOARD", GetApplicationsSelectedSharedDashboard)
}




const GetSpontaneousApplyDashboardSelectedRequest = async (instance,actions)=>
    await instance.get(`/profiles/applications/spontaneous/responses/list/?${actions.search}&page=${actions.page}`)
    .then(response=>response)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetSpontaneousApplyDashboardSelected(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetSpontaneousApplyDashboardSelectedRequest, axiosInstance, actions.data);
    
    if(data.data){

    console.log("Get Data",  data.data)
        yield put(GetSpontaneousApplyDashboardSelectedSuccess(data.data));
    }else{
        yield put(GetSpontaneousApplyDashboardSelectedError(data.error));
    }
    
}
export function* handleGetSpontaneousApplyDashboardSelected(){
    yield takeEvery("HANDLE_GET_SPONTANEOUS_APPLY_DASHBOARD_SELECTED", GetSpontaneousApplyDashboardSelected)
}






export default function* rootSaga() {
    yield all([fork(handleGetKeysDashboard)]);
    yield all([fork(handleGetSpontaneousApplyDashboardPipeline)]);
    yield all([fork(handleGetBestTalentsDashboard)]);
    yield all([fork(handleGetTopLocationsDashboard)]);
    yield all([fork(handleGetApplicationsDashboardForm)]);
    yield all([fork(handleGetApplicationsDashboardFormSelect)]);
    yield all([fork(handleGetApplicationsDashboard)]);
    yield all([fork(handleGetApplicationsSharedDashboard)]);
    yield all([fork(handleGetApplicationsRecommendationsDashboard)]);
    yield all([fork(handleGetOpportunitiesDashboard)]);
    yield all([fork(handleGetOpportunitiesOfflineDashboard)]);
    yield all([fork(handleGetOpportunitiesOnlineDashboard)]);
    yield all([fork(handleGetOpportunitiesDeleteDashboard)]);
    yield all([fork(handleGetFollowersDashboard)]);
    yield all([fork(handleGetMatchingDashboard)]);
    yield all([fork(handleGetScoresDashboard)]);
    yield all([fork(handleGetRecentActivitiesDashboard)]);
    yield all([fork(handleGetCurrentPlanDashboard)]);
    yield all([fork(handleGetConnexionsDashboard)]);
    yield all([fork(handleGetOpportunitiesApplyDashboard)]);
    yield all([fork(handleGetSpontaneousApplyDashboard)]);
    yield all([fork(handleGetSearchesAppearenceDashboard)]);
    yield all([fork(handleGetViewProfilDashboard)]);
    yield all([fork(handleGetApplicationsSelectedAppDashboard)]);
    yield all([fork(handleGetApplicationsSelectedRecDashboard)]);
    yield all([fork(handleGetApplicationsSelectedSharedDashboard)]);
    yield all([fork(handleGetSpontaneousApplyDashboardSelected)]);
}
