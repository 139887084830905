
import DateFnsUtils from '@date-io/date-fns';
import { Field, reduxForm } from 'redux-form';
import { browserHistory } from 'react-router';
import get from 'cross-fetch';
import FormAutocompleteSelect from './FormAutocompleteSelect';
import React,{useEffect, useState} from 'react';
import { withTranslation } from 'react-i18next';
// import { translate } from 'react-i18next';
import { showItemDetails} from 'actions/index';
import { connect } from 'react-redux';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { Col, Form, Row, Panel, Modal, Button } from 'react-bootstrap';
import {  renderSelect,renderCheckbox, renderPlaceAutocomplete, renderDateTimePicker, renderAsyncAutocomplete ,renderDateTime, renderSubmitGroup, DatePickerField} from 'components/formsElements/FormComponents';
import {   handleEducationLevel, handleGetProfileDetails, handleAddEducation, AddEducationResetMessages, EditEducationResetMessages } from 'actions/profile'; 
import {Alert} from '@material-ui/lab';
import logo from '../assets/imgs/spinner.gif';
import {useParams} from 'react-router-dom';
import { LocationSearchInput } from 'components/formsElements/FieldsComponents';
import {useDispatch, useSelector} from 'react-redux';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { handleSearchCompany, handleSearchIndustries, handleSearchEducation } from 'actions/search';
import { handleGetCompany } from 'actions/companies/getCompanies';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { green } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {API_BASE_URL, CLIENT_ID} from 'config';
import IntlMessages from "util/IntlMessages";
const initState={
  organization_id: "",
  education_id: "",
  education_level_id: "",
  is_current: true,
  new_organization: "",
  new_education: "",
}

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function EducationForm({ props,submitError, serverError, t , params}) {



  const [state, setState] = useState(initState);
  const [tagsEducationLevel, setTagsEducationLevel] = useState([]);
  const [tagsOrganization, setTagsOrganization] = useState([]);
  const [tagsEducation, setTagsEducation] = useState([]);
  const [locations, setLocations]= useState([]);
  const [positionInput, setpositionInput]= useState([]);
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogEducation, setOpenDialogEducation] = React.useState(false);
  const searchData = useSelector(state=>state.search.industries.list)
  const spinner = useSelector(state=>state.profile.add_education.spinner)
  const error = useSelector(state=>state.profile.add_education.error)
  const editmessage = useSelector(state=>state.profile.edit_education.message)
  const message = useSelector(state=>state.profile.add_education.message)
  const companyData = useSelector(state=>state.search.companies.list)
  const educationData = useSelector(state=>state.search.educations.list)
  const data = useSelector(state=>state.opportunities.add_education)
  const [fieldsValues] =useState(initState);
  const educationLevels = useSelector(state=>state.profile.educationLevels.list)
  // const userId = useSelector(state => state.users.userSettings.id)
  const {userId} =useParams();
  console.log("userId", userId? userId : null)
  console.log("Companies List", companyData? companyData : null)
  const  dispatch =useDispatch()
  const loader=<CircularProgress  color="primary"/>;
  const [options, setOptions] = React.useState([]);
  const [education, setEducation] = React.useState([]);
  const loading = open && options.length === 0;
  const loadingEducation = open && education.length === 0;
  const [inputValue, setInputValue] = React.useState('');
  const [inputValueEducation, setInputValueEducation] = React.useState('');
  const [selectedStartDate, setSelectedStartDate] = React.useState(new Date('2014-08-18T21:11:54'));
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date('2014-08-18T21:11:54'));
  const [checked, setChecked] = React.useState(true);


  console.log("API_BASE_URL" , API_BASE_URL)

  const handleChangeCheckbox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  const handleDateStartChange = (date) => {
    setSelectedStartDate(date);
    console.log("date" , setSelectedStartDate(date))
  };
  const handleDateEndChange = (date) => {
    setSelectedEndDate(date);
  };

  const [value, setValue] = React.useState(options[0]);

  useEffect (()=>{
    dispatch(handleEducationLevel());
      },[dispatch]);

  const handleChange =(event)=>{
    setState({...state, [event.target.name]:event.target.value});
  }

  const handleAddLocation=(item)=>{
      setLocations([...locations.filter(obj=>obj.place_id!==item.place_id), item])
  }
  const handleSearchChange=(event, value)=>{
    dispatch(handleSearchCompany({q:event.target.value}))
    setState({positionInput: value})
  }
  const handleSearchChangeEducation=(event)=>{
    dispatch(handleSearchEducation({q:event.target.value}))
  }

 
  const handleSelectOrganization=(test, elt, reason)=>{
    setTagsOrganization(elt);
  }
  const handleSelectEducation=(test,elt, reason)=>{
    setTagsEducation(elt);
  }
  const handleSelectEducationLevel=(test,elt, reason)=>{
    setTagsEducationLevel(elt);
  }




  const handleSubmit=(event, values)=>{
    event.preventDefault();

    console.log(state, locations.map(item=>item.data))

    console.log("tagsOrganization", tagsOrganization)
    console.log("tagsEducation", tagsEducation)

    console.log("tagsEducationLevel", tagsEducationLevel)
    console.log("organization_id", {inputValue})
    console.log("education_id", {inputValueEducation})

    console.log(state, locations.map(item=>item.data))
    dispatch(handleAddEducation({...state, userId, 
          locations:locations.map(item=>item.data),
          organization_id:tagsOrganization.length !== 0 ? tagsOrganization.organization_id: "",
          education_id:tagsEducation.length !== 0 ? tagsEducation.education_id: "",
          education_level_id : tagsEducationLevel.length !== 0 ? tagsEducationLevel.id: "",
          new_organization:tagsOrganization.organization_id  ? "" : inputValue ,
          new_education:tagsEducation.education_id ? "" : inputValueEducation,
          end_date : selectedEndDate,
          start_date : selectedStartDate,
        })
          )
  }

  
const changeLabelToIRI = (event, value) => {
  setState({positionInput: value})
}

  React.useEffect(() => {
  let active = true;

  if (!loading) {
    return undefined;
  }
  if (!loadingEducation) {
    return undefined;
  }

  (async () => {

    const Searcheducations = await fetch(API_BASE_URL+'/en/v3/searches/search/educations/?q=&client='+CLIENT_ID);
    const organizations = await fetch(API_BASE_URL+'/en/v3/searches/search/results/organizations/?q=&client='+CLIENT_ID);

    console.log("organizationsList" , organizations)
    console.log("educationList" , Searcheducations)
    await sleep(1e3); // For demo purposes.
    const organizationsList = await organizations.json();
    const educationList = await Searcheducations.json();


    if (active) {
      setEducation(educationList.results)
      setOptions(organizationsList.results.organizations)
    }
  })();

  return () => {
    active = false;
    };
  }, [loading , loadingEducation]);


  React.useEffect(() => {
    if (!openDialog) {
      setOptions([]);
    }
  }, [openDialog]);

  React.useEffect(() => {
    if (!openDialogEducation) {
      setEducation([]);
    }
  }, [openDialogEducation]);

 

    return (

      <div className="education">
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
         <Icon className="fa fa-plus-circle" style={{ color: "#ccc"}} />
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {<IntlMessages id="add_education"/>}
        </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <div className="row">
                   {error ? <Alert severity="danger"   onClose={()=>dispatch(AddEducationResetMessages())}><b>{error}</b></Alert> : null}
                   {editmessage ? <Alert severity="success"   onClose={()=>dispatch(EditEducationResetMessages())}><b>{message}</b></Alert> : null}
                   {message ?  <Alert severity="success"   onClose={()=>dispatch(AddEducationResetMessages())}>{message}</Alert>: null}
                  {/* {this.state.error_education_field ? <Alert color="danger"><b>{this.state.error_education_field}</b></Alert> : null}
                  {this.state.error_company_field ? <Alert color="danger"><b>{this.state.error_company_field}</b></Alert> : null}
                  {this.state.submitError?<Alert>{submitError}</Alert>:null}
                  {serverError ? <Alert color="danger"><b>{serverError}</b></Alert> : null}
               */}
               <div className="col-lg-12">
              <Form onSubmit={handleSubmit}>
                <Row>
                <Col md={12}>
                <Autocomplete
                  id="organization_id"
                  freeSolo
                  disableClearable
                  open={openDialog}
                  onOpen={() => {
                    setOpenDialog(true);
                  }}
                  onClose={() => {
                    setOpenDialog(false);
                  }}
                  getOptionSelected={(option, value) => option.name === value.organization_id}
                  getOptionLabel={(option) => option.name}
                  options={options}
                  value={value}
                  onChange={handleSelectOrganization}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);}}
                  size="small"
                  loading={loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={<IntlMessages id="institution"/>}
                      variant="outlined"
                      margin="normal"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                /></Col>
              </Row>        
               
             <Row> 
             <Col md={6}>
                <Autocomplete
                  id="education_id"
                  freeSolo
                  disableClearable
                  open={openDialogEducation}
                  onOpen={() => {
                    setOpenDialogEducation(true);
                  }}
                  onClose={() => {
                    setOpenDialogEducation(false);
                  }}
                  getOptionSelected={(education, value) => education.name === value.education_id}
                  getOptionLabel={(education) => education.name}
                  options={education}
                  value={value}
                  inputValue={inputValueEducation}
                  onInputChange={(event, newInputValue) => {
                    setInputValueEducation(newInputValue);}}
                  onChange={handleSelectEducation}
                  loading={loadingEducation}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={<IntlMessages id="field_of_study"/>}
                      variant="outlined"
                      margin="normal"
                      size="small"
                      placeholder="Ex: Management, Computers, Finance, Science ..." 
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingEducation ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                /></Col> 

              

              <Col md={6}>
                <Autocomplete
                fullWidth
                id="education_level"
                size="small"
                onChange={handleSelectEducationLevel}
                options={educationLevels}
                getOptionLabel={(option) => option.name}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.name}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params}  variant="outlined" 
                  label={<IntlMessages id="education_level"/>}
                  margin="normal"
                  placeholder="Ex: Master, Licence ..." />
                )}
              />
            </Col> 
          </Row>

          <Row>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Col md={6}>
              <KeyboardDatePicker
                margin="normal"
                id="start_date"
                name="start_date"
                label={<IntlMessages id="start_date"/>}
                format="MM/dd/yyyy"
                value={selectedStartDate}
                selected={state.start_date}
                onChange={handleDateStartChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />  
               
              </Col>
              <Col md={6}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="end_date"
                    name="end_date"
                    label={<IntlMessages id="end_date"/>}
                    format="MM/dd/yyyy"
                    value={selectedEndDate}
                    selected={state.end_date}
                    onChange={handleDateEndChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />   
                   
              </Col>
            </MuiPickersUtilsProvider> 

            {/* <Field
                  name="start_date"
                  label={t("wutiko.start_date")}
                  component={renderDateTime}
                  format={null}
                  required={true}
                  hintText={t("wutiko.start_date")}
                  style={{width:'100%'}}
                /> */}
          </Row>
          <Row>
            <Col md={12}>
            {/* <FormControlLabel
                id="is_current"
                name="is_current"
                label="Est actuel"
                labelPlacement="Est actuel"
                value={state.is_current}
              />   */}
           <FormControlLabel
        control={<Checkbox checked={state.is_current} onChange={handleChangeCheckbox} name="is_current" />}
        label={<IntlMessages id="is_current"/>}
      />
            </Col> 

            
            <Col sm={12}>
              {
                    
                      locations.map(item=>{
                          return(
                            <Chip label={`${item.data.city_name} ${item.data.state_province_name} ${item.data.country_name}`} onDelete={()=>setLocations(locations.filter(obj=>obj.place_id!==item.place_id))}/>
                          )
                        })
              }
            </Col>
            <Col sm={12}>
                  <Field
                      fullWidth
                      value={state.location_id}
                      component={LocationSearchInput}
                      addLocation={handleAddLocation}
                      placeholder="Location(s)"
                      label={<IntlMessages id="locations"/>}
                  />
              </Col> 
            <Col md={12}   className="textarea">
              <TextField
                required
                id="activities_and_societies"
                name="activities_and_societies"
                label={<IntlMessages id="school_activities"/>}
                fullWidth
                autoComplete="family-name"
                onChange={handleChange}
                multiLine={true} rows={2}
                value={state.activities_and_societies}
              />
            </Col>
            <Col md={12}   className="textarea">

              <TextField
                required
                id="description"
                name="description"
                label={<IntlMessages id="description"/>}
                fullWidth
                autoComplete="family-name"
                onChange={handleChange}
                multiLine={true} rows={2}
                value={state.description}
          />
            </Col>
            <Col md={12}   className="textarea">
                <TextField
                required
                id="accomplishments_and_awards"
                name="accomplishments_and_awards"
                label={<IntlMessages id="accomplishments_and_awards"/>}
                hintText={<IntlMessages id="accomplishments_and_awards"/>}
                floatingLabelText="Prix"
                fullWidth
                autoComplete="family-name"
                multiLine={true} rows={2}
                onChange={handleChange}
                value={state.accomplishments_and_awards}
          />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              {spinner ?loader:
                <Field name="submit"
                  className="save"
                  component={renderSubmitGroup}
                  onCancel={e => handleClose()}
                  // onRemove={
                  //   // educationId ?
                  //   // e => removeForm(`/profiles/${userId}/educations/${educationId}/remove/`, REMOVE_EDUCATION)
                  //   // : null
                  // }
                  removeText={<i className="fa fa-trash-o"></i>}
                  // spinner={(this.props.serverStatus === 'creating' ||
                  //           this.props.serverStatus === 'updating' ||
                  //           this.props.serverStatus === 'removing') && !this.props.serverError} 
                  />}
            </Col>
          </Row>
        </Form>
              </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
     {/* <Modal  show={this.state.showModal} onHide={this.close}>
  
    <div className="bloc content-page-interne add-experience"  >

      <div className=""><h1 className="title">{this.props.educationId ? "Modifier" : "Ajouter"}
      </h1></div>

      </div>

       </Modal> */}
       </div> 
    );
  }

// function mapStateToProps(state) {
  
//   return {
//     //axiosInstance: state.user.axiosInstance,
//     //userId: state.user.userId,
   
//     //userId : state.users.userSettings.id,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//    //showItemDetails:(url) =>dispatch(showItemDetails(url)),

//   };
// }
EducationForm = reduxForm({
  form: 'EducationForm',
  enableReinitialize: true
})(EducationForm);
export default withTranslation('translation')(EducationForm);



