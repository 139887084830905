
const initialState = {
    search_contract_type:{
        error:null,
        spinner:false,
        count:null,
        contract_type:[]
    },

 }

 export function contractType(state = initialState, action){
 
   switch(action.type) {

    case "GET_CONTRACT_TYPE_SEARCH_FAILLURE":
            return {...state, search_contract_type:{
                ...state.search_contract_type, error:action.payload, spinner:false, contract_type:[]
            }}
   case "GET_CONTRACT_TYPE_SEARCH":
           return {...state, search_contract_type:{
               ...state.search_contract_type, error:null, spinner:false, contract_type:action.payload.results
           }}
     default:
       return state;
   }
 }
