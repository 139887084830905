/**
 * 
 * Create form actions 
 */
export function handleCreateForm(data){
    return{
        type:"HANDLE_CREATE_FORM",
        data:data
    }
}

export function createFormSuccess(data){
    return{
        type:"CREATE_FORM",
        payload:data
    }
}

export function createFormError(data){
    return{
        type:"CREATE_FORM_FAILLURE",
        payload:data
    }
}

export function createFormResetMessages(){
    return{
        type:"CREATE_FORM_RESET_MESSAGES",
        payload:{}
    }
}