export function handleFilterCompany(data){
    return {
        type:"HANDLE_GET_COMPANY_FILTER",
        payload:data
    }
}
export function handleFilterCompanySuccess(data){
    return {
        type:"GET_COMPANY_FILTER",
        payload:data
    }
}

export function handleFilterCompanyError(data){
    return{
        type:"GET_COMPANY_FILTER_FAILLURE",
        payload:data
    }
}
