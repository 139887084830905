/**
 * This file contains redux actions and actions creators handling the sesssion of the user.
 */

import axios from 'axios';
import {useHistory} from 'react-router-dom'
import i18next from '../i18n';
import {GET_TOKEN_URL} from '../config';
import { error as notifError } from 'react-notification-system-redux';

import { logError, getHashUrlParameterByName } from '../utils';
import { DEBUG, API_BASE_URL, DEFAULT_REQUEST_TIMEOUT, LANGUAGE_LIST, CLIENT_ID } from '../config.js';


export const INIT_ENDED = 'INIT_ENDED';

export const SET_SESSION_DATA = 'SET_SESSION_DATA';
export const SET_USER_RIGHT = 'SET_USER_RIGHT';
export const LOGOUT = 'LOGOUT';

export const CREATE_BLANK_AXIOS_INSTANCE = 'CREATE_BLANK_AXIOS_INSTANCE';
export const CREATE_AXIOS_INSTANCE = 'CREATE_AXIOS_INSTANCE';
export const RESET_AXIOS_INSTANCE = 'RESET_AXIOS_INSTANCE';

export const REGISTER = 'REGISTER';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';

export const CLOSE_ACCOUNT = 'CLOSE_ACCOUNT';
export const CLOSE_ACCOUNT_REQUEST = 'CLOSE_ACCOUNT_REQUEST';
export const CLOSE_ACCOUNT_FAILURE = 'CLOSE_ACCOUNT_FAILURE';
export const CLOSE_ACCOUNT_SUCCESS = 'CLOSE_ACCOUNT_SUCCESS';

export const DEACTIVATE_ACCOUNT = 'DEACTIVATE_ACCOUNT';
export const DEACTIVATE_ACCOUNT_REQUEST = 'DEACTIVATE_ACCOUNT_REQUEST';
export const DEACTIVATE_ACCOUNT_FAILURE = 'DEACTIVATE_ACCOUNT_FAILURE';
export const DEACTIVATE_ACCOUNT_SUCCESS = 'DEACTIVATE_ACCOUNT_SUCCESS';

export const ACTIVATE_ACCOUNT         = 'ACTIVATE_ACCOUNT';
export const ACTIVATE_ACCOUNT_FAILURE  = 'ACTIVATE_ACCOUNT_FAILURE';

export const GET_PROFILE_SETTINGS = 'GET_PROFILE_SETTINGS';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_ALL = 'GET_NOTIFICATIONS_ALL';
export const UPDATE_NOTIFICATIONS_ALL = 'UPDATE_NOTIFICATIONS_ALL';

export const UPDATE_DEFAULT_LANGUAGE = 'UPDATE_DEFAULT_LANGUAGE';
export const UPDATE_DEFAULT_LANGUAGE_REQUEST = 'UPDATE_DEFAULT_LANGUAGE_REQUEST';
export const UPDATE_DEFAULT_LANGUAGE_FAILURE = 'UPDATE_DEFAULT_LANGUAGE_FAILURE';
export const UPDATE_DEFAULT_LANGUAGE_SUCCESS = 'UPDATE_DEFAULT_LANGUAGE_SUCCESS';

export const GET_CURRENCY_LIST = 'GET_CURRENCY_LIST';
export const UPDATE_DEFAULT_SITTING = 'UPDATE_DEFAULT_SITTING';
export const UPDATE_DEFAULT_SITTING_REQUEST = 'UPDATE_DEFAULT_SITTING_REQUEST';
export const UPDATE_DEFAULT_SITTING_FAILURE = 'UPDATE_DEFAULT_SITTING_FAILURE';
export const UPDATE_DEFAULT_SITTING_SUCCESS = 'UPDATE_DEFAULT_SITTING_SUCCESS';

export const GET_COUNTRY_LIST = 'GET_COUNTRY_LIST';
export const UPDATE_DEFAULT_COUNTRY = 'UPDATE_DEFAULT_COUNTRY';
export const UPDATE_DEFAULT_COUNTRY_REQUEST = 'UPDATE_DEFAULT_COUNTRY_REQUEST';
export const UPDATE_DEFAULT_COUNTRY_FAILURE = 'UPDATE_DEFAULT_COUNTRY_FAILURE';
export const UPDATE_DEFAULT_COUNTRY_SUCCESS = 'UPDATE_DEFAULT_COUNTRY_SUCCESS';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const SEND_INVITATION="SEND_INVITATION";

export const CRETATE_OPPORTUNITY_ALERT='CRETATE_OPPORTUNITY_ALERT';


let token = getTokenFromStorage();
export const  CHECKOUT = 'https://api2.wutiko.com/en/payment/checkout/add';

export const CHECKOUT_URL = 
 `${CHECKOUT}/#access_token=${token ? token.access_token : null}&category_id=`;

export function initSession(urlWithToken){

  return ((dispatch, getState) => {
    let token = getTokenFromStorage();
    let userId = getUserIdFromStorage();

    if(token && userId) {

      // We must check token validity here by throwing a request
      // then we can decide to store the token and directly display logged homepage to the user

      axios.get(
        `${API_BASE_URL}/en/v3/profiles/account/${token.access_token}/view/`,
        {
          timeout: DEFAULT_REQUEST_TIMEOUT,
          headers: {
            'Authorization': 'Bearer ' + token.access_token,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => { // token is valid
        console.log("response testing stored token :", response);
        dispatch({
          type: SET_SESSION_DATA,
          userId: userId,
          picture: response.data.photo,
          last_name: response.data.last_name,
          email:response.data.email,
          first_name: response.data.first_name,
          adminRoles: response.data.profileadminroles,
          totalCompanies:response.data.total_companies,
          totalGroups:response.data.total_groups,
          shopping_carts:response.data.shopping_carts,
          profile:response.data,
        });
        
        if(response.data.profileaccounttypeperiods.length !== 0) {
          dispatch({ type: SET_USER_RIGHT, payload: response.data.profileaccounttypeperiods[0].account_type_id.name });
        }

        if(response.data.default_language &&
           response.data.default_language.iso_02 &&
           response.data.default_language.iso_02 !== i18next.languages[0] &&
           LANGUAGE_LIST.includes(response.data.default_language.iso_02)) {
          i18next.changeLanguage(response.data.default_language.iso_02);
        } // else keep the current language, the one from the browser
      })
      .catch( error => {
        console.log("error testing stored token :", error)
        dispatch(resetUserSession());
      })
      .then(() => {
        const axiosInstance = createAxiosInstance(dispatch, i18next.languages[0], token,false);
        const axiosInstanceNoParams = createAxiosInstance(dispatch, i18next.languages[0], token,true);
        const blankAxiosInstance = createAxiosInstance(dispatch, i18next.languages[0],false);
        dispatch({ type: `CREATE_AXIOS_INSTANCE_NO_PARAMS`, payload: axiosInstanceNoParams });
        dispatch({ type: CREATE_AXIOS_INSTANCE, payload: axiosInstance });
        dispatch({ type: CREATE_BLANK_AXIOS_INSTANCE, payload: blankAxiosInstance });

        dispatch({ type: INIT_ENDED })
      });

    } else if(urlWithToken.hash.trim() !== '') {
      // parse url to find token
      const token = {
        access_token: getHashUrlParameterByName('access_token', urlWithToken.hash),
        expires_in: getHashUrlParameterByName('expires_in', urlWithToken.hash),
        token_type: getHashUrlParameterByName('token_type', urlWithToken.hash),
        scope: getHashUrlParameterByName('scope', urlWithToken.hash),
        state: getHashUrlParameterByName('state', urlWithToken.hash),
      }

      // We must check token validity here by throwing a request
      // then we can decide to store the token and directly display logged homepage to the user

      axios.get(
        `${API_BASE_URL}/en/v1/profiles/account/${token.access_token}/view/`,
        {
          timeout: DEFAULT_REQUEST_TIMEOUT,
          headers: {
            'Authorization': 'Bearer ' + token.access_token,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => { // token is valid
        console.log("response getting userId with token :", response)
        setTokenToStorage(token);
        setUserIdToStorage(response.data.id);

        dispatch({
          type: SET_SESSION_DATA,
          userId: response.data.id,
          picture: response.data.photo,
          last_name: response.data.last_name,
          first_name: response.data.first_name,
          email:response.data.email,
          adminRoles: response.data.profileadminroles,
          totalCompanies:response.data.total_companies,
          totalGroups:response.data.total_groups,
          shopping_carts:response.data.shopping_carts,
          profile:response.data,
        });

        if(response.data.profileaccounttypeperiods.length !== 0) {
          dispatch({ type: SET_USER_RIGHT, payload: response.data.profileaccounttypeperiods[0].account_type_id.name });
        }

        if(response.data.default_language &&
           response.data.default_language.iso_02 &&
           response.data.default_language.iso_02 !== i18next.languages[0] &&
           LANGUAGE_LIST.includes(response.data.default_language.iso_02)) {
          i18next.changeLanguage(response.data.default_language.iso_02);

        } // else keep the current language, the one from the browser

        if(token.state) {

        const browserHistory = useHistory()
          browserHistory.push(token.state); // will be executed at the end of the function
        }
      })
      .catch( error => {
        console.log("error getting userId with token :", error)
        dispatch(resetUserSession());
      })
      .then(() => {
        const axiosInstance = createAxiosInstance(dispatch, i18next.languages[0], token,false);
        const axiosInstanceNoParams = createAxiosInstance(dispatch, i18next.languages[0], token,true);
        const blankAxiosInstance = createAxiosInstance(dispatch, i18next.languages[0],false);
        dispatch({ type: `CREATE_AXIOS_INSTANCE_NO_PARAMS`, payload: axiosInstanceNoParams });
        dispatch({ type: CREATE_AXIOS_INSTANCE, payload: axiosInstance });
        dispatch({ type: CREATE_BLANK_AXIOS_INSTANCE, payload: blankAxiosInstance });
        dispatch({ type: INIT_ENDED });
      });
    } else { // we make sure there is no token nor axios instance in the state that we can use
      dispatch(resetUserSession());
      const blankAxiosInstance = createAxiosInstance(dispatch, i18next.languages[0],false);
      dispatch({ type: CREATE_BLANK_AXIOS_INSTANCE, payload: blankAxiosInstance });
      dispatch({ type: INIT_ENDED })
    }
  });
}

function createAxiosInstance(dispatch, lang, token,noparams) {
  console.log("noparams",noparams);
  let loginUrl = GET_TOKEN_URL + '&state=/networking/timeline/';
  let instance;
  if(token) {
    if(noparams){
      instance = axios.create({
        baseURL: API_BASE_URL + '/' + lang +'/v1',
        timeout: DEFAULT_REQUEST_TIMEOUT,  
        params: { },
        headers: {
          'Authorization': 'Bearer ' + token.access_token
        }
      });
    }else{
      instance = axios.create({
        baseURL: API_BASE_URL + '/' + lang +'/v1',
        timeout: DEFAULT_REQUEST_TIMEOUT,  
        params: {
          client: CLIENT_ID,
      },
        headers: {
          'Authorization': 'Bearer ' + token.access_token
        }
      });
    }
  } else {
      if(noparams){
        instance = axios.create({
          baseURL: API_BASE_URL + '/' + lang +'/v1',
          params: { },
          timeout: DEFAULT_REQUEST_TIMEOUT,
        });
      }else{
        instance = axios.create({
          baseURL: API_BASE_URL + '/' + lang+'/v1',
          params: {
            client: CLIENT_ID
          },
          timeout: DEFAULT_REQUEST_TIMEOUT,
        });
      }
  }

  instance.interceptors.response.use(res => (res), error => {

    console.log('error intercepted', error);
    // token is now invalid, need to relogin
    // FIXME : that can also be because the user made an inappropriate request, how to tell that to him ?
    if(error.response){

      switch(error.response.status) {

        case 401: // unauthorized
          // token is now invalid, need to relogin
          // FIXME : that can also be because the user made an inappropriate request, how to tell that to him ?
          dispatch(resetUserSession());
          window.location.href= loginUrl;
          break;

        case 403:
          dispatch(notifError({ title: i18next.t('error403Title'), message: i18next.t('error403Msg') }));
          break;

        case 400: // bad request
          // the error in the request needs to be logged to be corrected
          if(!DEBUG) logError(window.location.href, JSON.stringify(error.response, null, 2));
          break;

        case 404: // not found
          // the error in the request needs to be logged to be corrected
          if(!DEBUG) logError(window.location.href, JSON.stringify(error.response, null, 2));
          break;

        case 500: // bad request
          // the error in the request needs to be logged to be corrected
          if(!DEBUG) logError(window.location.href, JSON.stringify(error.response, null, 2));
          break;

        default:
          break;
      }
    }

    return Promise.reject(error);
  });

  return instance;
}

// logout equivalent
export function resetUserSession(callbackAfter){

  return ((dispatch, getState) => {

    setTokenToStorage(null);
    setUserIdToStorage(null);
    dispatch({ type: RESET_AXIOS_INSTANCE })
    dispatch({ type: LOGOUT })
    console.log("User Session Reset !")
    if(callbackAfter) {
      callbackAfter();
    }
  })
}


export function updateAPILanguage(newLang) {

  return ((dispatch, getState) => {

    let instance = getState().user.axiosInstance;
    let blankInstance = getState().user.blankAxiosInstance;

    if(instance) {
      instance.defaults.baseURL = API_BASE_URL + '/' + newLang +'/v1';
      dispatch({ type: CREATE_AXIOS_INSTANCE, payload: instance });
    }

    if(blankInstance) {
      blankInstance.defaults.baseURL = API_BASE_URL + '/' + newLang +'/v1';
      console.log("test langue:" ,blankInstance.defaults.baseURL )
      dispatch({ type: CREATE_BLANK_AXIOS_INSTANCE, payload: blankInstance });

    }
  })
}


export function getTokenFromStorage(){

  let token;

  try {
    token = JSON.parse(localStorage.getItem('sessionToken')) || undefined;
    console.log("getTokenFromStorage : ", token);
  } catch (e) {
    console.log("Unvalid stored Token : ", e);
  } finally {

    if(!token || token === '') {//if there is no token, dont bother
      return null;
    }
  }

  return token;
}

function setTokenToStorage(token) {

  try {
    if (token) {
      localStorage.setItem('sessionToken', JSON.stringify(token));
    } else {
      localStorage.removeItem('sessionToken');
    }
  } catch(e) {
    console.log("Unable to access local storage : ", e);
  }
}

function getUserIdFromStorage(){

  let userId;

  try {
    userId = JSON.parse(localStorage.getItem('sessionUserId')) || undefined;
    console.log("getUserIdFromStorage : ", userId);
  } catch (e){
    console.log("Unvalid stored userId : ", e);
  } finally {

    if(!userId || userId === '') {//if there is no token, dont bother
      return null;
    }
  }

  return userId;
}

function setUserIdToStorage(userId) {

  try {
    if (userId) {
      localStorage.setItem('sessionUserId', JSON.stringify(userId));
    } else {
      localStorage.removeItem('sessionUserId');
    }
  } catch(e) {
    console.log("Unable to access local storage : ", e);
  }
}

export function updateProfile(url, requestTarget, data){
  return(dispatch, getState)=>{
    let instance=getState().user.axiosInstance;
    instance.put(url, data)
    .then((response)=>{
      console.log("update profile response", requestTarget, response)
      if (response.status!==201 && response.status !==200) {
         dispatch({ type: `${requestTarget}_FAILURE`, payload: response.data });
      }else{
        dispatch({ type: `${requestTarget}_SUCCESS` });
        window.location.reload();
      }
    }).catch(error=>{
       if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      }else{
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    });
  }

}

export  function dispatchPaymentStatus(requestTarget,callback){
  let token = getTokenFromStorage();
  const url="/profiles/account/"+token.access+"/view/";
  return (dispatch, getState) => {
    
    const instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ 
        type: requestTarget,
        userId: response.data.id,
        picture: response.data.photo,
        last_name: response.data.last_name,
        first_name: response.data.first_name,
        adminRoles: response.data.profileadminroles,
        totalCompanies:response.data.total_companies,
        totalGroups:response.data.total_groups,
        shopping_carts:response.data.shopping_carts
         });
        // window.location.href="/networking/profile";
    })
    .catch((error) => {
      console.log("dispatchPaymentStatus: ", requestTarget, error);
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}