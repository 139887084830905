import React, {Component} from 'react';
import {Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem} from 'reactstrap';
import IntlMessages from "util/IntlMessages";
import { Link } from '@material-ui/core';
const items = [
    {
      id: 1,
      altText: "wutiko.com",
      caption: <IntlMessages id="certified_opportunities_platform"/>,
      src: "https://cdn.wutiko.com/v3/static/images/slideshows/low/wutiko_slideshow_lagos_people.jpeg"
    },
    {
      id: 2,
      altText: 'Slide 2',
      caption: <IntlMessages id="start_for_free"/>,
      src: "https://cdn.wutiko.com/v3/static/images/slideshows/low/wutiko_slideshow_happyness.jpeg"
    }, {
      id: 3,
      altText: 'Slide 3',
      caption: <IntlMessages id="explore_more_opportunities"/>,
      src: "https://cdn.wutiko.com/v3/static/images/slideshows/low/wutiko_slideshow_lagos_ikoyi_bridge_02.jpeg"
    }
  ];

class Simple extends Component {
  constructor(props) {
    super(props);
    this.state = {activeIndex: 0};
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({activeIndex: nextIndex});
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({activeIndex: nextIndex});
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({activeIndex: newIndex});
  }

  render() {
    const {activeIndex} = this.state;

    const slides = items.map((item) => {
      return (
        <CarouselItem
          key={item.id}
          onExiting={this.onExiting}
          onExited={this.onExited}>
        <div className="row slideshowHome">
        <div className="col-md-6 slideshow  col-6">
             <CarouselCaption className="text-danger" 
             captionHeader={item.caption}/>
        </div>         
        <div className="col-md-6 col-6">
            <a href={item.altText}>
            <div className="play-button-container">
             <div className="play-button">
                 <svg>
            <polygon points="10 33 10 1 34 17"></polygon>
            </svg>
            </div>
            </div></a>
            <img src={item.src} alt={item.altText}/></div>  
        </div>  
        </CarouselItem>
      );
    });

    return (
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}>
        <CarouselIndicators className="container" items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex}/>
        {slides}
        {/* <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous}/>
        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next}/> */}
      </Carousel>
    );
  }
}

export default Simple;