import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import { reducer as formReducer } from 'redux-form'
import Settings from './Settings';
import Auth from './Auth';
import {users} from './users';
import {uploadCv} from './uploadCv'
import {opportunities}      from './opportunities'
import {lastUpdates}        from './lastUpdates'
import {testimonies}        from './testimonies'
import {partners}        from './partners'
import {newsLetter}         from './newsLetter'
import {skills}             from './filters/skills'
import {industries}         from './filters/industries';
import {contractType}       from './filters/contractType';
import { educations }         from './filters/educations';
import { positions }          from './filters/positions';
import {locations}          from './filters/locations';
import {opportunityType}    from './filters/opportunityType'
import {filteropportunity}    from './filters/opportunity'
import {companyFilter}    from './filters/company'
import {filterexperience}    from './filters/experience'
import { search }           from './search';
import { createCompany }    from './companies/createCompany';
import { formsGenerator }   from './forms/formsGenerator';
import { preViewForm }      from './forms/preViewForm';
import { addFields2Form }   from './forms/addFields2Form';
import { createForm }       from './forms/createForm';
import { profile }       from './profil/DetailProfil';
import { profil }       from './profil/profile';
import { user }       from './profil/user';
import { keys }       from './Dashboard/keys';
import { company }       from './companies/getCompany';
import { assessedFilter }       from './filters/assessed';
import { dateFilter }       from './filters/date';
import { matchingscoreFilter }       from './filters/matchingscore';

export default (history) => combineReducers({
    router: connectRouter(history),
    partners:partners,
    assessedFilter:assessedFilter,
    matchingscoreFilter:matchingscoreFilter,
    dateFilter:dateFilter,
    filterexperience:filterexperience,
    educations:educations,
    positions:positions,
    keys:keys,
    profile:profile,
    company:company,
    profil:profil,
    settings: Settings,
    auth: Auth,
    users:users,
    user:user,
    opportunities:opportunities,
    lastUpdates:lastUpdates,
    testimonies:testimonies,
    newsLetter:newsLetter,
    industries:industries,
    skills:skills,
    contractType:contractType,
    locations:locations,
    opportunityType:opportunityType,
    filteropportunity:filteropportunity,
    companyFilter:companyFilter,
    uploadCv:uploadCv,
    search:search,
    createCompany:createCompany,
    form: formReducer,
    formsGenerator:formsGenerator,
    preViewForm:preViewForm,
    addFields2Form:addFields2Form,
    createForm:createForm,
});
