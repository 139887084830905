import React from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
// import { Parser } from 'html-to-react';
import {  Button } from 'react-bootstrap';
// import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { GET_PROFILE_SKILLS, GET_PROFILE_SECTORS, handleDeleteSkills, handleDeleteSectors} from 'actions/profile';
import { getItem } from 'actions/index';
import Chip from '@material-ui/core/Chip';
import IntlMessages from "util/IntlMessages";
import {  useDispatch} from 'react-redux'
import AddSkills from 'containers/Profil/AddSkills';
import AddSectors from 'containers/Profil/AddSectors';
const DEFAULT_NUMBER_SKILL_DISPLAYED = 3;
const DEFAULT_NUMBER_INDUSTRY_DISPLAYED = 3;

class ProfileSkill extends React.Component{

  constructor(props) {
    super(props);    
     this.state = { 
      nSkill: DEFAULT_NUMBER_SKILL_DISPLAYED ,
     nIndusry: DEFAULT_NUMBER_INDUSTRY_DISPLAYED };
    this.handleRemoveSkill = this.handleRemoveSkill.bind(this);
    this.handleRemoveSector = this.handleRemoveSector.bind(this);
    this.showAll = this.showAll.bind(this);
    this.hideAll = this.hideAll.bind(this);
    this.showAllIndustry = this.showAllIndustry.bind(this);
    this.hideAllIndustry = this.hideAllIndustry.bind(this);
  }

  showAll() {
    this.setState({ nSkill: this.props.itemDetails.length })
  }

  hideAll() {
    this.setState({ nSkill: DEFAULT_NUMBER_SKILL_DISPLAYED })
  }

  showAllIndustry() {
    this.setState({ nIndusry: this.props.itemDetailsIndustry.length })
  }

  hideAllIndustry() {
    this.setState({ nIndusry: DEFAULT_NUMBER_INDUSTRY_DISPLAYED })
  }
 handleRemoveSector = (sectorId) =>{
    const saveData={
      userId:this.props.userId,
      sectorId:sectorId
    }
    console.log("removeSector response : ", saveData);
   this.props.handleDeleteSectors(saveData)
    }
    
handleRemoveSkill =( skillId) =>{
    
      const saveData={
        userId:this.props.userId,
        skillId:skillId
      }
      console.log("removeSector response : ", saveData);
      this.props.handleDeleteSkills(saveData)
      }
     
componentDidMount(){
    // const url = `/profiles/${this.props.userId}/skills/list/`
    // const url2 = `/profiles/${this.props.userId}/industries/list/`
    // this.props.getItem(url, GET_PROFILE_SKILLS);
    // this.props.getItem(url2, GET_PROFILE_SECTORS);
  }




  renderListSkill(n) {
        // var parser = new Parser();
  // const { t } = this.props;
    return this.props.itemDetails.slice(0,n).map((skill) => {
      return(
        <Chip label={skill.is_certified===false ? skill.skill_id.name : null} onDelete={e => this.handleRemoveSkill(skill.id)} color="#0066b4" variant="outlined"/>
      )
    })
  }

  renderListSkillPublic(n) {
    // var parser = new Parser();
// const { t } = this.props;
return this.props.itemDetailsPublic.slice(0,n).map((skill) => {
  return(
    <Chip label={skill.is_certified===false ? skill.skill_id.name : null}  color="#0066b4" variant="outlined"/>
  )
})
}
   renderListIndustry(n) {
    return this.props.itemDetailsIndustry.slice(0,n).map((industry) => {
      return(
        <Chip label={industry.industry_id.name} onDelete={e => this.handleRemoveSector(industry.id)} color="#0066b4" variant="outlined"/>
      )
    })
  }

  renderListIndustryPublic(n) {
    return this.props.itemDetailsIndustryPublic.slice(0,n).map((industry) => {
      return(
        <Chip label={industry.industry_id.name}  color="#0066b4" variant="outlined"/>
      )
    })
  }
  render() {

  console.log("test industry" , this.props.itemDetailsIndustry);
  console.log("UserId skill", this.props.userId)
    return (
      <div className="bloc content-page-interne skills">
       {this.props.myProfile ?
           <div className=""  data-aos="fade-up">

                <div className="row">
                    <div className="col-md-6 col-6"><h2  className="pull-left"> {<IntlMessages id="skills"/>} </h2></div>
             
                     <div className="col-md-6 col-6">{/*<a className="all-skill" href="">3 skills in review</a>*/}
                    <div className="pull-right">
                    </div>
                 </div>
                </div>
                <div className="skill"  data-aos="fade-up">
                    <div className="col-md-12 col-12">

                  <div className="skills-endorsed"  data-aos="fade-up">
                   
                    <div className="row">
                     <h4 className=" title col-md-6">{<IntlMessages id="other_skills_not_endorsed"/>} </h4>
                     <div className="text-right col-md-6 col-6 padding-right-0"> 
                    <AddSkills add={"add"} className="text-right add" /> 
                    </div> 
                    </div>
                    <div className="col-md-12 col-12">
                      {this.props.itemDetails ? this.renderListSkill(this.state.nSkill) : null}
                      {this.props.itemDetails? 
                      this.props.itemDetails.length > 3 ?
                      this.state.nSkill === DEFAULT_NUMBER_SKILL_DISPLAYED ?
                      <Button bsStyle="link" onClick={this.showAll} className="neutre">
                      {<IntlMessages id="see_all"/>}
                     </Button>
                    :
                      <Button bsStyle="link" onClick={this.hideAll} className="neutre">
                       {<IntlMessages id="hide"/>}
                      </Button>
                      :
                 null:null} 
              </div>
              </div>


            <div className="skills-endorsed"  data-aos="fade-up">
                   


                <div className="row">
                      <h4 className="title col-md-6">  {<IntlMessages id="industries"/>}</h4>
                      <div className="text-right col-md-6 col-6 padding-right-0">
                      <AddSectors add={"add"} className="text-right add" /> 
                      </div>
                    </div>
              <div className="col-md-12 col-12">

                  {this.props.itemDetailsIndustry ? this.renderListIndustry(this.state.nIndusry) : null}
                  {this.props.itemDetailsIndustry? 
                 this.props.itemDetailsIndustry.length > 3 ?
                  
                this.state.nIndusry === DEFAULT_NUMBER_INDUSTRY_DISPLAYED ?
                <Button bsStyle="link" onClick={this.showAllIndustry} className="neutre">
                <IntlMessages id="see_all"/>
               </Button>
              :
                <Button bsStyle="link" onClick={this.hideAllIndustry} className="neutre">
                 <IntlMessages id="hide"/>
                </Button>

               : null: null} 
              </div>
              </div>
              </div>
              </div>
        </div>
        :
        <div className="">
                <div className="row">
                    <div className="col-md-6 col-6"><h2  className="pull-left">  <IntlMessages id="skills"/></h2></div>
                </div>
                <div className="skill">
                    <div className="col-md-12 col-12">

                  <div className="skills-endorsed">
                    <h4 className="title col-md-12 col-12"> <IntlMessages id="other_skills_not_endorsed"/></h4>
                    <div className="col-md-12 col-12">
                      {this.props.itemDetailsPublic ? this.renderListSkillPublic(this.state.nSkill) : null}
                      {this.props.itemDetailsPublic? 
                      this.props.itemDetailsPublic.length > 3 ?
                      this.state.nSkill === DEFAULT_NUMBER_SKILL_DISPLAYED ?
                      <Button bsStyle="link" onClick={this.showAll} className="neutre">
                      {<IntlMessages id="see_all"/>}
                     </Button>
                    :
                      <Button bsStyle="link" onClick={this.hideAll} className="neutre">
                       {<IntlMessages id="hide"/>}
                      </Button>
                      :
                 null:null} 
              </div>
              </div>


            <div className="skills-endorsed">
              <h4 className="title col-md-12 col-12"> <IntlMessages id="industry"/></h4>
              <div className="col-md-12 col-12">
                 
                  <div className="col-md-12 col-12">

                  {this.props.itemDetailsIndustryPublic ? this.renderListIndustryPublic(this.state.nIndusry) : null}
                  {this.props.itemDetailsIndustryPublic? 
                  this.props.itemDetailsIndustryPublic.length > 3 ?

                  this.state.nIndusry === DEFAULT_NUMBER_INDUSTRY_DISPLAYED ?
                  <Button bsStyle="link" onClick={this.showAllIndustry} className="neutre">
                  <IntlMessages id="see_all"/>
                  </Button>
                  :
                  <Button bsStyle="link" onClick={this.hideAllIndustry} className="neutre">
                  <IntlMessages id="hide"/>
                  </Button>

                  : null: null} 
                  </div>
              </div>
              </div>
              </div>
              </div>
        </div>}</div>
   );
  }
}
function mapDispatchToProps(dispatch) {
    return {
        handleDeleteSkills: (saveData) => dispatch(handleDeleteSkills(saveData)),
        handleDeleteSectors: (saveData) => dispatch(handleDeleteSectors(saveData))
    }
    
}
function mapStateToProps(state) {
  return {
    itemDetailsIndustry: state.profile.sectors.list,
    itemDetails: state.profile.skills.list,
   // itemDetailsPublic: state.profile.details.details,
    userId: state.users.userSettings.userId,
    
    axiosInstance: state.users.axiosInstance,
  }
}
export default   (connect(mapStateToProps, mapDispatchToProps) (ProfileSkill));
