import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import NewsLetterValidation from 'formValidations/NewsLetterValidation';
import {Alert} from '@material-ui/lab';
import {useDispatch,useSelector} from 'react-redux'
import { handleResetMessages } from 'actions/newsLetter';
import IntlMessages from "util/IntlMessages";

const useStyles = makeStyles((theme) => ({
  ul:{
    'list-style-type': 'none',
    paddingLeft: 0,

  },
  icons:{
    fontSize:50,
  },
  rh:{
    align:'right',
    'list-style-type': 'none',
    paddingLeft: 0,
    [theme.breakpoints.down('sm')]:{
      align:'left',
      'list-style-type': 'none',
      paddingLeft: 0,
    }
  },
  terms:{
    justifyContent:'flex-end',
    [theme.breakpoints.down('sm')]:{
      justifyContent:'center',
    }
  },
  submit:{
    backgroundColor: theme.palette.text.primary,
    color:"white",
    "&hover":{
        backgroundColor: theme.palette.text.primary,
        color:"white"
    }
  },
  field:{
    background:'white',
    padding:0,
  }
}));
const INITIAL_STATE={
   first_name:"",
   last_name:"",
   email:"",
}
export default function NewsLetters() {
  const classes = useStyles();
  const {handleSubmit,handleChange,handleBlur,values, errors, isSubmitting}=NewsLetterValidation(INITIAL_STATE);
  const messages = useSelector(state=>state.newsLetter.set_news_letter);
  const dispatch = useDispatch()
  console.log("NEWS_LETTER", errors,values)
  const first_name = <IntlMessages id="first_name"/>;
  return (
    <footer className="newsletter">
  <div className="container">
  <h2 className="title-homepage">{<IntlMessages id="newsletter"/>}</h2>
      <p className="center">{<IntlMessages id="newsletter_description"/>} </p>
      <from onSubmit={handleSubmit} className="container">
        <Grid container spacing={2}>
        <Grid xs={12}>
        {messages.message?<Alert severity="success" onClose={()=>dispatch(handleResetMessages())}> 
                  {messages.message}
          </Alert>:null}
          {messages.error?
          <Alert severity="error" onClose={()=>dispatch(handleResetMessages())}> 
                  {messages.error}
          </Alert>:null}
        </Grid>
        <Grid item md={3} xs={6}>
        <TextField
                variant="outlined"
                required
                fullWidth
                error={errors.first_name}
                id="first_name"
                placeholder="Ex : Michel"
                name="first_name"
                autoComplete="lname"
                className={classes.field}
                inputProps={{
                    style: {
                        padding: 8
                    }
                    }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_name}
                  helperText={errors.first_name}
              />
        </Grid>
        <Grid item md={3} xs={6}>
        <TextField
                variant="outlined"
                required
                fullWidth
                id="last_name"
                placeholder="Ex : Diop"
                name="last_name"
                autoComplete="lname"
                className={classes.field}
                inputProps={{
                    style: {
                        padding: 8
                    }
                    }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                  error={errors.last_name}
                  helperText={errors.last_name}
              />
        </Grid>
        <Grid item md={3} xs={12}>
        <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                placeholder="Ex : michel@exemple.coom"
                name="email"
                autoComplete="lname"
                className={classes.field}
                                inputProps={{
                    style: {
                        padding: 8
                    }
                    }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={errors.email}
                  helperText={errors.email}
              />
        </Grid>
        <Grid item md={3} xs={12}>
        <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isSubmitting}
            className={classes.submit}
            onClick={handleSubmit}
          >
            {<IntlMessages id="subscribe_now"/>}
          </Button>
        </Grid>
      </Grid>
      </from>
      </div>
    </footer>
  );
}

NewsLetters.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};