// import React from 'react';

// const CountryListItem = ({country}) => {
//     const {flagCode, name, badge, badgeColor} = country;
//     return (
//         <div className=" d-flex align-items-center">
//             <i className={`flag flag-24 flag-${flagCode}`}/>
//             <div className="px-3">{name} </div>
//             <div className={`ml-auto px-3 badge badge-pill text-right text-white bg-${badgeColor}`}>{badge} </div>
//         </div>

//     )
// };

// export default CountryListItem;


import React from 'react';

const CountryListItem = ({country}) => {
    const {iso_2, country_name, total} = country;
    return (
        <div className=" d-flex align-items-center">
            <i className={`flag flag-24 flag-${iso_2}`}/> 
            <div className="px-3">{country_name} </div>
            <div className={`ml-auto px-3 badge badge-pill text-right text-white bg-primary`}>{total} </div>
        </div>

    )
};

export default CountryListItem;
