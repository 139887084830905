import {all, fork, put, takeEvery} from "redux-saga/effects";
import { Json2Form } from "components/formsElements/Json2Form";
import { preViewFormError, preViewFormSuccess } from "actions/forms/prevViewForm";

function* preViewForm(actions){
    var REQUIRED=[]; var UISCHEMA={}; var PROPERTIES={};
    try {
        actions.formStructure.forEach(field=>{
            Json2Form(field,[],REQUIRED, UISCHEMA,PROPERTIES)
        }, yield put(preViewFormSuccess({uiSchema:UISCHEMA, required:REQUIRED, properties:PROPERTIES})))
    } catch (error) {
        put(preViewFormError(error))
    }

}

export function* handlepreViewForm(){
    yield takeEvery("HANDLE_PRE_VIEW_FORM", preViewForm)
}
export default function* rootSaga() {
    yield all([fork(handlepreViewForm)]);
}
