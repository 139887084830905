
/**
 * Initiate contact form submission
 */
export function handleSubmitContactForm(data,entryData,formId){
    return{
        type:'HANDLE_SUBMIT_CONTACT_FORM',
        data:data,
        formId:formId,
        entryData:entryData
    }
}

/**
 * Handle contact form submission success messages
 */
export function SubmitContactFormSuccess(successMessage){
    return{
        type:"SUBMIT_CONTACT_FORM",
        payload:successMessage
    }
}
//
/**
 * Handle contact form submission errors
 */

 export function submitContactFormError(errorMessage){
     return{
         type:"SUBMIT_CONTACT_FORM_FAILLURE",
         payload:errorMessage
     }
 }

/**
  * Handle remove contact form submission messages
*/

export function submitContactFormRemoveMessages(){
    return{
        type:"SUBMIT_CONTACT_FORM_RESET_MESSAGES",
        payload:{}
    }
}