
export function handlePreViewForm(formStructure){
    return{
        type:"HANDLE_PRE_VIEW_FORM",
        formStructure:formStructure
    }
}
export function preViewFormSuccess(data){
    return{
        type:"PRE_VIEW_FORM",
        payload:data
    }
}

export function preViewFormError(data){
    return{
        type:"PRE_VIEW_FORM_FAILLURE",
        payload:data
    }
}