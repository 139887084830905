import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { setFormDataError,successSetFormData, setFormDataOppError,successSetFormDataOpp} from "actions/uploadCv";

const setFormData = async (instance,actions)=>
    await instance.post(`/profiles/upload/resume/add/`,actions)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            return error.response.data
        }
    })

function* setForm(actions){
   // const axiosInstance = (state)=>state.users.blankAxiosInstance

    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    //const instance =yield select(axiosInstance);
    const data     =yield call(setFormData,axiosInstance,actions.data);
    if(data.message){
            yield put(successSetFormData(data.message));
    }else{
        yield put(setFormDataError(data.error));
    }
    
}


const setFormDataOpp = async (instance,actions)=>
    await instance.post(`/opportunities/call/for/application/`,actions)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            return error.response.data
        }
    })

function* setFormOpp(actions){
   // const axiosInstance = (state)=>state.users.blankAxiosInstance

    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    //const instance =yield select(axiosInstance);
    const data     =yield call(setFormDataOpp,axiosInstance,actions.data);
    if(data.message){
            yield put(successSetFormDataOpp(data.message));
    }else{
        yield put(setFormDataOppError(data.error));
    }
    
}
export function* setUplaodCV(){
    yield takeEvery("HANDLE_UPLOAD_CV", setForm)
}
export function* setUplaodCVOpp(){
    yield takeEvery("HANDLE_UPLOAD_CV_OPP", setFormOpp)
}
export default function* rootSaga() {
    yield all([fork(setUplaodCV)]);
    yield all([fork(setUplaodCVOpp)]);
}