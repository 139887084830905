const initialState = {
    create_form:{
        error:null,
        spinner:false,
        message:null
    }
 }

 export function createForm(state = initialState, action){
 
   switch(action.type) {
   /**
    * Handle create form
    */
   case "HANDLE_CREATE_FORM":
           return {...state, create_form:{
               ...state.create_form, error:null, spinner:true, message:null 
           }}
   case "CREATE_FORM_FAILLURE":
            return {...state, create_form:{
                ...state.create_form, error:action.payload, spinner:false, message:null 
            }}
   case "CREATE_FORM":
        return {...state, create_form:{
            ...state.create_form, error:null, spinner:false, message:action.payload
        }}
   case "CREATE_FORM_RESET_MESSAGES":
        return {...state, create_form:{
            ...state.create_form, error:null, spinner:false, message:null
        }}

     default:
       return state;
   }
 }