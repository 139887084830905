import React,{useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {useDispatch, useSelector} from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import {Alert} from '@material-ui/lab';
import { ShareOpportunityResetMessages, handleShareopportunity } from 'actions/opportunities';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IntlMessages from "util/IntlMessages";
 const initState={
     opportunity_id:null, 
     email:{
         emails:['']
     }
    };

export default function ShareOpportunity(props) {
  const [open, setOpen] = React.useState(false);
  const [state, setState] = useState(initState);
  const [fieldsValues, setValue] =useState(initState);
  const share_opportunity = useSelector(state=>state.opportunities.share_opp);
  const dispatch =useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const loader=<CircularProgress  color="primary"/>;

 

 
  const handleSumbit=(event)=>{
    event.preventDefault();
    console.log("Submit values : ", fieldsValues);
    dispatch(handleShareopportunity({...fieldsValues, opportunity_id:props.opportunityId}))

  }
  const handleChange =(event)=>{
    setValue({...fieldsValues, email:{
        ...fieldsValues.email, emails:[event.target.value]
    }});
    console.log("Submit values : ", fieldsValues);
  }
  useEffect(()=>{
      console.log("Hello")
       setValue(initState);
  },[share_opportunity.message])
  return (
     <div>
    <IconButton edge="start" color="inherit" aria-label="close" onClick={handleClickOpen}><MailOutlineIcon/>  
    </IconButton>  {<IntlMessages id="email"/>}
     <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
       <DialogTitle id="form-dialog-title">{<IntlMessages id="subscribe"/>}</DialogTitle>
       <form onSubmit={handleSumbit}>
       <DialogContent>
         <DialogContentText>
         {<IntlMessages id="share_opportunity_placeholder"/>}
         {share_opportunity.message?<Alert severity="success" onClose={()=>dispatch(ShareOpportunityResetMessages())}>{share_opportunity.message}</Alert>:null}
         {share_opportunity.error?<Alert severity="error" onClose={()=>dispatch(ShareOpportunityResetMessages())}>{share_opportunity.error}</Alert>:null}
         </DialogContentText>
         <TextField
           autoFocus
           margin="dense"
           required
           id="email"
           name="Email_Address"
           label={<IntlMessages id="email_address"/>}
           type="text"
           onChange={handleChange}
           value={fieldsValues.email.emails[0]}
           fullWidth
         />
       </DialogContent>
       <DialogActions>
         <Button onClick={handleClose} color="primary">
         {<IntlMessages id="cancel"/>}
         </Button>
         <Button color="primary">
         {share_opportunity.spinner?loader:<Button variant="contained" type="submit" color="primary">{<IntlMessages id="save"/>}</Button>}
         </Button>
       </DialogActions>
       </form>
     </Dialog>
   </div>
  );
}