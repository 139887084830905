import { makeStyles } from '@material-ui/core/styles';
/**
 * Here are defined all styles related to forms constructions.
 * Each const represents the styles of a certain component.
 */
export const FieldStyle = makeStyles((theme) => ({
    button: {
        display: 'block',
        marginTop: theme.spacing.unit * 2,
      },
      formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
      },
    root: {
      flexGrow: 1,
      paddingLeft:'10%',
      paddingRight:'10%',
    },
    demo: {
      height: 240,
    },
    paper: {
      padding: theme.spacing.unit * 2,
      height: '100%',
      color: theme.palette.text.secondary,
    },
    card:{
        marginBottom:'15px'
    },
    control: {
      padding: theme.spacing.unit * 2,
    },
    actions: {
        display: 'flex',
      },
    remove: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
    clear_button:{
        padding:0,
        color:'red'
      },
    choices_field:{
        width:'20% !important'
    }
  }));

  export const FormBuilderStyles = makeStyles((theme) => ({
    title:{
      paddingBottom:15,
      color:'#fafafa',
      fontWeight:'bold'
    },
    btn_y:{
        backgroundColor:"#ff9801",
        color:'#fafafa',
        '&:hover':{
          backgroundColor:"#ff9801",
          color:'#fafafa',
        }
      },
      btn_w:{
        backgroundColor:"#fafafa",
        '&:hover':{
          backgroundColor:"#fafafa",
        },
        border:'black solid 2px'
      },
      main:{
          marginLeft:'12%',
          marginRight:'12%',
          [theme.breakpoints.down('xs')]:{
            marginLeft:'0%',
            marginRight:'0%'
          }
      },
      fieldset:{
        margin:0,
        padding:15,
        border:'solid 2px black',
      },
      legend:{
        display: 'block',
        fontSize: 15,
        width:'auto'
      },
      cont:{
        paddingTop:30,
        paddingBottom:30,
        zIndex:1,
        background:"#9b9b9b",
      },
  }));

  