import { Col } from 'react-bootstrap';
//import ProfileArea from './ProfileArea';
//import ProfileFile from './ProfileFile';
import ProfileExperience from './ProfileExperience';
// import ProfileContact from './ProfileContact';
import ProfileEducation from './ProfileEducation';
// import ProfileLangue from './ProfileLangue';
import ProfileSkillsPublic from './ProfileSkillsPublic';
// import ProfileSector from './ProfileSector';
// import ProfileFileBnt from './ProfileFileBnt';
import { withTranslation } from 'react-i18next';
import { useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {Alert} from '@material-ui/lab';
import { HandleFollowProfile, handleGetProfileDetails ,FollowProfileSuccess, FollowProfileResetMessages} from '../../../actions/profile';
import React,{useEffect} from 'react';
import { useDispatch} from 'react-redux'
import ProfileAreasPublic from './ProfileAreasPublic';
import IconButton from "@material-ui/core/IconButton";
import { Button,  Form} from 'reactstrap';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LaunchIcon from '@material-ui/icons/Launch';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { ka } from 'date-fns/locale';
import SidebarRight from 'components/Sidebar/SidebarRight';
import LatestCompanies from 'components/companies/LatestCompaniesList';
import IntlMessages from "util/IntlMessages";
import ProfileAchievement from './ProfileAchievement';
import Skilltest from './Skilltest';
import ProfileAreas from './ProfileAreas';
import DocumentFile from './ProfileFile'
// class MyProfile extends React.Component{

//   constructor(props) {
//     super(props);
//     this.updateProfileContent = this.updateProfileContent.bind(this);
//   }
const useStyles = makeStyles((theme) => ({
    toolbar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    header:{
      position:'fixed',
      top:0,
      width:'100%',
      background:'#ffffff',
      zIndex:10
    },
    toolbarTitle: {
      flex: 1,
    },
    toolbarSecondary: {
      justifyContent: 'space-between',
      overflowX: 'auto',
    },
    toolbarLink: {
      padding: theme.spacing(1),
      flexShrink: 0,
    }, 
    search:{
      paddingTop:0,
      paddingBottom:0
    }
  }));
export default function Profile({props, t }) {
    const itemDetails = useSelector(state=>state.profile.details.details);
    const classes = useStyles();
    const itemDetailsProfile = useSelector(state=>state.profile.details.details);
    const followProfile =useSelector(state=>state.opportunities.followProfile)
    const userId = useSelector(state => state.users.id)
   // const slug = useSelector(state => state.users.slug)
    const {slug} =useParams();

    console.log("slug" , slug);
    const dispatch = useDispatch();
    useEffect(()=>{
      dispatch(handleGetProfileDetails(slug));
        },[dispatch]);

    
    return(
      <div className="container">
        <Container maxWidth={false} className={classes.cont}>
        <div className={`animated slideInUpTiny animation-duration-3 ${classes.content}`}>
        <div className="row">
          <div className="col-md-8 col-sm-7 col-12 animation slideInLeft bloc-profile">
          {followProfile?<Alert severity="success" onClose={()=>dispatch(FollowProfileSuccess())}>{followProfile.followProfile.message ? followProfile.followProfile.message : null}</Alert>:null}
         {followProfile?<Alert severity="error" onClose={()=>dispatch(FollowProfileResetMessages())}>{followProfile.followProfile.error}</Alert>:null}
                
            <div className="row">
                    <div className="col-md-12 animation slideInLeft CoverPhoto">
                      {itemDetailsProfile?
                      <span className="cover-photo">
                        <img
                          src={itemDetailsProfile.cover_photo ?itemDetailsProfile.cover_photo: "https://cdn.wutiko.com/v3/static/images/default/low/wutiko_default_cover_photo.png"}
                          alt="Cover photo"
                        />
                      </span>:null}
                    </div>
                    <div className="col-md-12 photo-profil animation slideInLeft">
                      {itemDetailsProfile?
                      <span className="photo">
                        <img
                          src={itemDetailsProfile?itemDetailsProfile.photo:null}
                          alt="Cover photo"
                        />
                      </span>:null}
                    </div>
                    <div className="col-md-12 col-sm-12 col-12 animation slideInRight">
                      <div className="row"> 
                          <div className="col-md-5 col-sm-5 col-5 animation slideInLeft">
                              <b>{itemDetailsProfile?<h2>{itemDetailsProfile.first_name} {itemDetailsProfile.last_name}</h2>:null} </b>
                              <b>{itemDetailsProfile?<span>{itemDetailsProfile.title}</span>:null} </b>
                              <b>{itemDetailsProfile?<span>{itemDetailsProfile.location}</span>:null}</b>
                          </div>
                          <div className={`col-md-5 col-sm-5 col-5 animation slideInRight ${classes.textAlignEnd}`}>
                          {itemDetailsProfile?
                          <div>{itemDetailsProfile.phones.map(phone => phone.full_number ).join(' , ')}</div>
                          :null
                          }
                          </div>
                          <div className="col-md-2 col-sm-2 col-2 animation slideInRight">
                          { itemDetailsProfile ?
                            itemDetailsProfile.is_my_connexion === 4?
                               <p className="text-grey mb-3" onClick={()=>dispatch(HandleFollowProfile(itemDetailsProfile.id))}>
                                <Button fullWidth variant="contained" color="secondary">{<IntlMessages id="follow"/>}</Button>
                              </p>:
                               <p className="text-grey mb-3">
                              <Button fullWidth variant="contained" color="secondary">{<IntlMessages id="following"/>}</Button>
                               </p>
                               : null}
                          </div>
                      </div>
                      </div>
            </div>
            <div className="row col-md-12 top-20">
              {itemDetailsProfile?
              <div>
              <div><h2>{<IntlMessages id="about"/>}</h2></div>
              <p>{itemDetailsProfile.about_me}</p> 
              </div>
              :
              null
              }         
            </div>

            <div className="row">
            <DocumentFile id={userId}  files={itemDetails ? itemDetails.profile_documents : []} /> 
        <ProfileExperience  itemDetails={itemDetails ? itemDetails.positions : []}  />
        <ProfileEducation  itemDetailsEducations={itemDetails  ? itemDetails.educations : []}  /> 
        {/* <ProfileLangue id={userId} itemDetails={itemDetails} myProfile/> */}
        <ProfileAchievement 
        itemDetailsProfilePublications={itemDetails? itemDetails.publications : []} 
        itemDetailsProfileLanguages={itemDetails? itemDetails.languages : [] }/>
        <Skilltest  itemDetailsPublic={itemDetails ? itemDetails.skills : []} 
        itemDetailsIndustryPublic={itemDetails ? itemDetails.industries : []}/>
           <ProfileAreas itemDetailsPublic={itemDetails ? itemDetails.areas : []}/> 
   
 
            </div>  
            {/* <div className="row col-md-12">
                  <div className="col-md-5 col-sm-5 col-12">
                  <p className="text-grey mb-3">
                    Repport this Profile<IconButton><LaunchIcon/></IconButton>
                  </p> 
                  </div>
                  <div className="col-md-2 col-sm-2 col-6 animation slideInLeft">
                    <p className="text-grey mb-3">
                    <IconButton><FavoriteBorderIcon/></IconButton>{<IntlMessages id="save"/>}
                  </p>
                    </div>
                    <div className="col-md-2 col-sm-2 col-6 animation slideInRight">
                    <p className="text-grey mb-1">
                      <IconButton><MailOutlineIcon/></IconButton>{<IntlMessages id="email"/>} 
                  </p>
                    </div>
                  <div className="col-md-3 col-sm-3 col-12">
                       <Button fullWidth variant="contained" color="secondary">{<IntlMessages id="connect"/>} </Button>
                  </div>
            </div>
            <div className="row col-md-12">
              <div className={`col-xl-6 col-sm-12`}>
             
              </div>

              <div className="col-xl-6 col-sm-12">
               
              </div>
            </div>*/}
          </div> 
          <div className="col-md-4 col-sm-5 col-12 animation slideInRight">
          <LatestCompanies/>
          <SidebarRight/>
          </div>
        </div>
      </div>
      </Container>
      </div>
       
    )
}
Profile = withTranslation('translation')(Profile);
// function mapStateToProps(state) {
//   return {
//     itemDetails: state.itemdetail.detail,
//     userId: state.user.userId,
//   }
// }
