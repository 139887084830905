export function handleOpportunityTypeSearch(data){
    return {
        type:"HANDLE_GET_OPPORTUNITY_TYPE_SEARCH",
        payload:data
    }
}
export function handleSetSearchOpportunityType(data){
    return {
        type:"GET_OPPORTUNITY_TYPE_SEARCH",
        payload:data
    }
}

export function handleSetSearchOpportunityTypeError(data){
    return{
        type:"GET_OPPORTUNITY_TYPE_SEARCH_FAILLURE",
        payload:data
    }
}