import React from 'react';
import { connect } from 'react-redux';
import { Table} from 'react-bootstrap';
import { success, error } from 'react-notification-system-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {cleanStringQuery} from './functions/splitStringQuery';
import configureStore, {history} from 'store';
import { withTranslation } from 'react-i18next';
class ApplicationBusinessPipeLine extends React.Component {

constructor(props) {
    super(props);
       const {t}=props
       this.state = {};
       this.isActive=this.isActive.bind(this);
       this.handleChangeTab=this.handleChangeTab.bind(this)
      
} 
isActive(scope){
    return window.location.href.split(scope).length>=2
}

handleChangeTab(scope){
    history.push(`/dashboard/applicants/pipeline/${scope}${cleanStringQuery(window.location.search)?`?${cleanStringQuery(window.location.search)}`:''}`)
}
render() {
    const { t } = this.props;
    return(
      <Table>
        <tr>
          <th className={this.isActive("pipeline/applicants")?`active_piple_tab cursor_pointer`:`cursor_pointer`} onClick={()=>this.handleChangeTab(`applicants`)}>{t("wutiko.applicants")}
          {/* ({this.props.data.pipelined_normal}) */}
          </th>
          <th className={this.isActive("pipeline/sharedprofiles")?`active_piple_tab cursor_pointer`:`cursor_pointer`} onClick={()=>this.handleChangeTab(`sharedprofiles`)}>{t("wutiko.shared_profiles")}
          {/* ({this.props.data.pipelined_share}) */}
          </th>
          <th className={this.isActive("pipeline/recommendation")?`active_piple_tab cursor_pointer`:`cursor_pointer`} onClick={()=>this.handleChangeTab(`recommendation`)}>{t("wutiko.recommendation")}</th>
        </tr>
        <tr>
          <td colSpan={3}>
            {this.props.children}
          </td>
        </tr>
      </Table>
    )
  }
}
const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});

function mapStateToProps(state, ownProps) {
  return {
    userId: state.user.userId,
    //data:state.opportunity.statistics_data.data,
  }
}
function mapDispatchToProps(dispatch){
  return {
    // fetchItem: (url, type) => dispatch(fetchItem(url, type)),
    // getItem: (url, type) => dispatch(getItem(url, type)),
    // Empty: (type) => dispatch(Empty(type)),
    // notifSuccess: opts => dispatch(success(opts)),
    // notifError: opts => dispatch(error(opts)),
    // putItem:(url,type,data)=>dispatch(putItem(url,type,data))
  }
}
ApplicationBusinessPipeLine.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(withTranslation('translation')(connect(mapStateToProps,mapDispatchToProps)(ApplicationBusinessPipeLine)));

