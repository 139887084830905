const initialState = {
    industries:{
        error:null,
        spinner:false,
        list:[]
    },
    companies:{
        error:null,
        spinner:false,
        list:[]
    },
    companiesAdmin:{
        error:null,
        spinner:false,
        list:[]
    },
    educations:{
        error:null,
        spinner:false,
        list:[]
    },
    positions:{
        error:null,
        spinner:false,
        list:[]
    },
    skills:{
        error:null,
        spinner:false,
        list:[]
    },
    occupations:{
        error:null,
        spinner:false,
        list:[]
    },
    benefits:{
        error:null,
        spinner:false,
        list:[]
    },
    categories:{
        error:null,
        spinner:false,
        list:[]
    },
    contractType:{
        error:null,
        spinner:false,
        list:[]
    },
    langue:{
        error:null,
        spinner:false,
        list:[]
    },
    area:{
        error:null,
        spinner:false,
        list:[]
    }
 }

 export function search(state = initialState, action){
 
   switch(action.type) {
   case "HANDLE_SEARCH_INDUSTRIES":
           return {...state, industries:{
               ...state.industries, error:null, spinner:true
           }}
   case "SEARCH_INDUSTRIES_FAILLURE":
           return {...state, industries:{
               ...state.industries, error:action.payload, spinner:false, list:[]
           }}
   case "SEARCH_INDUSTRIES":
      return {...state, industries:{
          ...state.industries, error:null, spinner:false, list:action.payload.data.results
      }}
      

/***
 * Search company
 */

case "HANDLE_SEARCH_COMPANIES":
        return {...state, companies:{
            ...state.companies, error:null, spinner:true
        }}
case "SEARCH_COMPANY_FAILLURE":
        return {...state, companies:{
            ...state.companies, error:action.payload, spinner:false, list:[]
        }}
case "SEARCH_COMPANY":
   return {...state, companies:{
       ...state.companies, error:null, spinner:false, list:action.payload.data.results.organizations
   }}


/***
 * Search company
 */

case "HANDLE_SEARCH_COMPANIES_ADMIN":
    return {...state, companiesAdmin:{
        ...state.companiesAdmin, error:null, spinner:true
    }}
case "SEARCH_COMPANY_ADMIN_FAILLURE":
    return {...state, companiesAdmin:{
        ...state.companiesAdmin, error:action.payload, spinner:false, list:[]
    }}
case "SEARCH_COMPANY_ADMIN":
return {...state, companiesAdmin:{
   ...state.companiesAdmin, error:null, spinner:false, list:action.payload.data.results
}}

/***
 * Search education
 */

case "HANDLE_SEARCH_EDUCATION":
        return {...state, educations:{
            ...state.educations, error:null, spinner:true
        }}
case "SEARCH_EDUCATION_FAILLURE":
        return {...state, educations:{
            ...state.educations, error:action.payload, spinner:false, list:[]
        }}
case "SEARCH_EDUCATION":
   return {...state, educations:{
       ...state.educations, error:null, spinner:false, list:action.payload.data.results
   }}

/***
 * Search position
 */

case "HANDLE_SEARCH_POSITION":
        return {...state, positions:{
            ...state.positions, error:null, spinner:true
        }}
case "SEARCH_POSITION_FAILLURE":
        return {...state, positions:{
            ...state.positions, error:action.payload, spinner:false, list:[]
        }}
case "SEARCH_POSITION":
   return {...state, positions:{
       ...state.positions, error:null, spinner:false, list:action.payload.data.results
   }}


/***
 * Search skills
 */

case "HANDLE_SEARCH_SKILLS":
        return {...state, skills:{
            ...state.skills, error:null, spinner:true
        }}
case "SEARCH_SKILLS_FAILLURE":
        return {...state, skills:{
            ...state.skills, error:action.payload, spinner:false, list:[]
        }}
case "SEARCH_SKILLS":
   return {...state, skills:{
       ...state.skills, error:null, spinner:false, list:action.payload.data.results
   }}



/***
 * Search BENEFITS
 */

case "HANDLE_SEARCH_BENEFITS":
    return {...state, benefits:{
        ...state.benefits, error:null, spinner:true
    }}
case "SEARCH_BENEFITS_FAILLURE":
    return {...state, benefits:{
        ...state.benefits, error:action.payload, spinner:false, list:[]
    }}
case "SEARCH_BENEFITS":
return {...state, benefits:{
   ...state.benefits, error:null, spinner:false, list:action.payload.data.results
}}



/***
 * Search CATEGORIES
 */

case "HANDLE_SEARCH_CATEGORIES":
        return {...state, categories:{
            ...state.categories, error:null, spinner:true
        }}
case "SEARCH_CATEGORIES_FAILLURE":
        return {...state, categories:{
            ...state.categories, error:action.payload, spinner:false, list:[]
        }}
case "SEARCH_CATEGORIES":
   return {...state, categories:{
       ...state.categories, error:null, spinner:false, list:action.payload.data.results
   }}



/***
 * Search occupations
 */

case "HANDLE_SEARCH_OCCUPATIONS":
    return {...state, occupations:{
        ...state.occupations, error:null, spinner:true
    }}
case "SEARCH_OCCUPATIONS_FAILLURE":
    return {...state, occupations:{
        ...state.occupations, error:action.payload, spinner:false, list:[]
    }}
case "SEARCH_OCCUPATIONS":
return {...state, occupations:{
   ...state.occupations, error:null, spinner:false, list:action.payload.data.results
}}



/***
 * Search contractType
 */

case "HANDLE_SEARCH_CONTRACTTYPE":
    return {...state, contractType:{
        ...state.contractType, error:null, spinner:true
    }}
case "SEARCH_CONTRACTTYPE_FAILLURE":
    return {...state, contractType:{
        ...state.contractType, error:action.payload, spinner:false, list:[]
    }}
case "SEARCH_CONTRACTTYPE":
return {...state, contractType:{
   ...state.contractType, error:null, spinner:false, list:action.payload.data.results
}}


/***
 * Search area
 */

case "HANDLE_SEARCH_AREA":
        return {...state, area:{
            ...state.area, error:null, spinner:true
        }}
case "SEARCH_AREA_FAILLURE":
        return {...state, area:{
            ...state.area, error:action.payload, spinner:false, list:[]
        }}
case "SEARCH_AREA":
   return {...state, area:{
       ...state.area, error:null, spinner:false, list:action.payload.data.results
   }}


/***
 * Search langue
 */

case "HANDLE_SEARCH_LANGUE":
        return {...state, langue:{
            ...state.langue, error:null, spinner:true
        }}
case "SEARCH_LANGUE_FAILLURE":
        return {...state, langue:{
            ...state.langue, error:action.payload, spinner:false, list:[]
        }}
case "SEARCH_LANGUE":
   return {...state, langue:{
       ...state.langue, error:null, spinner:false, list:action.payload.data.results
   }}


     default:
       return state;
   }
 }
