const initialState = {
  error: null,
  details:{
    error: null,
    message: null,
    spinner: false,
  },
  detailsForm:{
    error: null,
    message: null,
    spinner: false,
  },
    get_profile_bassique: {
    details:{},
    status: null,
    error: null,
    message:null,
    }
,
  profile_areas:{
    error: null,
    message: null,
    spinner: false,
    list: [],
  },    
  sectors: {
    status: null,
    error: null,
    list: [],
  },
  educations: {
    status: null,
    error: null,
    list: [],
  },
  experiences: {
    status: null,
    error: null,
    list: [],
  },
  skills: {
    status: null,
    error: null,
    message: null,
    spinner: false,
    list: [],
  },
  add_education:{
    spinner:false,
    error:null,
    message:null
},
edit_education:{
    spinner:false,
    error:null,
    message:null
},
add_skills:{
    spinner:false,
    error:null,
    message:null
},
add_experience:{
    spinner:false,
    error:null,
    message:null
},
edit_profile:{
    spinner:false,
    error:null,
    message:null
},
edit_experience:{
    spinner:false,
    error:null,
    message:null
},
delete_experience:{
    spinner:false,
    error:null,
    message:null
},
delete_object:{
    spinner:false,
    error:null,
    message:null
},
delete_education:{
    spinner:false,
    error:null,
    message:null
},
add_file:{
    spinner:false,
    error:null,
    message:null
},
add_industries:{
    spinner:false,
    error:null,
    message:null
},
add_langue:{
    spinner:false,
    error:null,
    message:null
},
add_publication:{
    spinner:false,
    error:null,
    message:null
},
add_area:{
    spinner:false,
    error:null,
    message:null
},
educationLevels: {
    status: null,
    error: null,
    message: null,
    spinner: false,
    list: [],
  },
all_profiles:{
    list:{},
    spinner:false,
    error:null,
    count:0
},
langue:{
    list:[],
    spinner:false,
    error:null,
    count:0
},
publication:{
    list:[],
    spinner:false,
    error:null,
    count:0
},
files:{
    list:[],
    spinner:false,
    error:null,
    count:0
},
followProfile:{
    status: null,
    error: null,
    message:null,
},

}

export function profile(state = initialState, action){

 switch(action.type) {



/**
  * Edit profil
  */


 case "HANDLE_EDIT_PROFILE":
    return{
        ...state, edit_profile:{
            ...state.edit_profile, error:null, spinner:true, message:null
        }
}

case "EDIT_PROFILE":
    return{
        ...state, edit_profile:{
            ...state.edit_profile, error:null, spinner:false, message:action.payload
        }
    }

case "EDIT_PROFILE_FAILLURE":
    return{
        ...state, edit_profile:{
            ...state.edit_profile, error:action.payload, spinner:false, message:null
        }
    }
case "EDIT_PROFILE_RESET_MESSAGES":
    return{
        ...state, edit_profile:{
            ...state.edit_profile, error:null, spinner:false, message:null
        }
    } 




/**
  * Get Edit profil
  */

 case "HANDLE_GET_EDIT_PROFILE_DETAILS":
     return{
         ...state, get_profile_bassique:{
             ...state.get_profile_bassique, error:null, spinner:true
         }
 }
 case "GET_EDIT_PROFILE":
     return{
         ...state, get_profile_bassique:{
             ...state.get_profile_bassique, error:null, spinner:false, details:action.payload
         }
 }
 case "GET_EDIT_PROFILE_FAILLURE":
         return{
             ...state, get_profile_bassique:{
                 ...state.get_profile_bassique, error:action.payload, spinner:true, details:null
             }
     }

case "GET_EDIT_PROFILE_RESET_MESSAGES":
    return{
        ...state, get_profile_bassique:{
            ...state.get_profile_bassique, error:null, spinner:false, message:null
        }
    } 
 /**
  * Get  details with slug
  */

 case "HANDLE_FOLLOW_PROFILE":
            return {...state, followProfile: {...state.followProfile , status: 'creating',  message: null  }};
    
    case "FOLLOW_PROFILE_SUCCESS":
        return {...state, followProfile: 
            {...state.followProfile , status: 'creating',  
                message: action.payload }};
    case "FOLLOW_PROFILE_RESET_MESSAGES":
         return {
            ...state, followProfile:{
                ...state.followProfile,
                status:null, error:null, message:null
            }
         }
    case "FOLLOW_PROFILE_FAILLURE":
         return {...state, followProfile: 
                {...state.followProfile , 
                    status: 'creating', 
                    error: action.payload || action.payload.err}}
        

 case "SET_ALL_PROFILES":
    return {
        ...state, all_profiles:{
            ...state.all_profiles, error:null, spinner:false, list:action.payload.results, count:action.payload.count
        }
    }
case "SET_ALL_PROFILES_FAILLURE":
    return {
        ...state, all_profiles:{
            ...state.all_profiles, error:action.payload, spinner:false, list:{}, count:0
        }
    }
case "HANDLE_SEARCH_PROFILES":
    return{
        ...state, all_profiles:{
            ...state.all_profiles, error:null, spinner:true, list:{}, count:0
    }}



    
 case "HANDLE_GET_PROFILE_DETAILS":
     return{
         ...state, details:{
             ...state.details, error:null, spinner:true
         }
 }
 case "GET_PROFILE_DETAILS":
     return{
         ...state, details:{
             ...state.details, error:null, spinner:false, details:action.payload
         }
 }
 case "GET_PROFILE_DETAILS_FAILLURE":
         return{
             ...state, details:{
                 ...state.details, error:action.payload, spinner:true, details:null
             }
     }




     case "HANDLE_GET_PROFILE_DETAILS_FORM":
        return{
            ...state, detailsForm:{
                ...state.detailsForm, error:null, spinner:true
            }
    }
    case "GET_PROFILE_DETAILS_FORM":
        return{
            ...state, detailsForm:{
                ...state.detailsForm, error:null, spinner:false, detailsForm:action.payload
            }
    }
    case "GET_PROFILE_DETAILS_FORM_FAILLURE":
            return{
                ...state, detailsForm:{
                    ...state.detailsForm, error:action.payload, spinner:true, detailsForm:null
                }
        }
 /**
  * Get Profil areas with slug
  */
case "HANDLE_GET_PROFILE_AREAS":
        return{
            ...state, profile_areas:{
                ...state.profile_areas, error:null, spinner:true
            }
    }
case "GET_PROFILE_AREAS":
    return {
    ...state, profile_areas:{
        ...state.profile_areas, error:null, spinner:false, list:action.payload}
    }
    
case "GET_PROFILE_AREAS_FAILLURE":
            return{
                ...state, profile_areas:{
                    ...state.profile_areas, error:action.payload, spinner:true, list:null
                }
        }
 /**
  * Get sectors with slug
  */
    case "HANDLE_GET_PROFILE_SECTORS":
            return{
                ...state, sectors:{
                    ...state.sectors, error:null, spinner:true
                }
        }
    case "GET_PROFILE_SECTORS":
        return {
        ...state, sectors:{
            ...state.sectors, error:null, spinner:false, list:action.payload}
        }
        
    case "GET_PROFILE_SECTORS_FAILLURE":
                return{
                    ...state, sectors:{
                        ...state.sectors, error:action.payload, spinner:true, list:null
                    }
            }

 /**
  * Get skills with slug
  */
 case "HANDLE_GET_PROFILE_SKILLS":
    return{
        ...state, skills:{
            ...state.skills, error:null, spinner:true
        }
}
case "GET_PROFILE_SKILLS":
return {
...state, skills:{
    ...state.skills, error:null, spinner:false, list:action.payload}
}

case "GET_PROFILE_SKILLS_FAILLURE":
        return{
            ...state, skills:{
                ...state.skills, error:action.payload, spinner:true, list:null
            }
    }

 /**
  * Add Education profil
  */


    case "HANDLE_ADD_EDUCATION":
        return{
            ...state, add_education:{
                ...state.add_education, error:null, spinner:true, message:null
            }
    }

    case "ADD_EDUCATION":
        return{
            ...state, add_education:{
                ...state.add_education, error:null, spinner:false, message:action.payload
            }
        }

    case "ADD_EDUCATION_FAILLURE":
        return{
            ...state, add_education:{
                ...state.add_education, error:action.payload, spinner:false, message:null
            }
        }
    case "ADD_EDUCATION_RESET_MESSAGES":
        return{
            ...state, add_education:{
                ...state.add_education, error:null, spinner:false, message:null
            }
        }    

/**
  * DELETE Education profil
  */


 case "HANDLE_DELETE_EDUCATION":
    return{
        ...state, delete_education:{
            ...state.delete_education, error:null, spinner:true, message:null
        }
}

case "DELETE_EDUCATION":
    return{
        ...state, delete_education:{
            ...state.delete_education, error:null, spinner:false, message:action.payload
        }
    }

case "DELETE_EDUCATION_FAILLURE":
    return{
        ...state, delete_education:{
            ...state.delete_education, error:action.payload, spinner:false, message:null
        }
    }
case "DELETE_EDUCATION_RESET_MESSAGES":
    return{
        ...state, delete_education:{
            ...state.delete_education, error:null, spinner:false, message:null
        }
    }   


/**
  * DELETE Education profil
  */


 case "HANDLE_DELETE_EXPERIENCE":
    return{
        ...state, delete_experience:{
            ...state.delete_experience, error:null, spinner:true, message:null
        }
}

case "DELETE_EXPERIENCE":
    return{
        ...state, delete_experience:{
            ...state.delete_experience, error:null, spinner:false, message:action.payload
        }
    }

case "DELETE_EXPERIENCE_FAILLURE":
    return{
        ...state, delete_experience:{
            ...state.delete_experience, error:action.payload, spinner:false, message:null
        }
    }
case "DELETE_EXPERIENCE_RESET_MESSAGES":
    return{
        ...state, delete_experience:{
            ...state.delete_experience, error:null, spinner:false, message:null
        }
    }  



/**
  * DELETE Object 
  */


 case "HANDLE_DELETE_OBJECT":
    return{
        ...state, delete_object:{
            ...state.delete_object, error:null, spinner:true, message:null
        }
}

case "DELETE_OBJECT":
    return{
        ...state, delete_object:{
            ...state.delete_object, error:null, spinner:false, message:action.payload
        }
    }

case "DELETE_OBJECT_FAILLURE":
    return{
        ...state, delete_object:{
            ...state.delete_object, error:action.payload, spinner:false, message:null
        }
    }
case "DELETE_OBJECT_RESET_MESSAGES":
    return{
        ...state, delete_object:{
            ...state.delete_object, error:null, spinner:false, message:null
        }
    }  
/**
  * Edit Education profil
  */


 case "HANDLE_EDIT_EDUCATION":
    return{
        ...state, edit_education:{
            ...state.edit_education, error:null, spinner:true, message:null
        }
}

case "EDIT_EDUCATION":
    return{
        ...state, edit_education:{
            ...state.edit_education, error:null, spinner:false, message:action.payload
        }
    }

case "EDIT_EDUCATION_FAILLURE":
    return{
        ...state, edit_education:{
            ...state.edit_education, error:action.payload, spinner:false, message:null
        }
    }
case "EDIT_EDUCATION_RESET_MESSAGES":
    return{
        ...state, edit_education:{
            ...state.edit_education, error:null, spinner:false, message:null
        }
    } 

 /**
  * Add Education profil
  */


 case "HANDLE_ADD_AREA":
    return{
        ...state, add_area:{
            ...state.add_area, error:null, spinner:true, message:null
        }
}

case "ADD_AREA":
    return{
        ...state, add_area:{
            ...state.add_area, error:null, spinner:false, message:action.payload
        }
    }

case "ADD_AREA_FAILLURE":
    return{
        ...state, add_area:{
            ...state.add_area, error:action.payload, spinner:false, message:null
        }
    }
case "ADD_AREA_RESET_MESSAGES":
    return{
        ...state, add_area:{
            ...state.add_area, error:null, spinner:false, message:null
        }
    }    
/**
  *  Education profil level
  */
           

     case "HANDLE_EDUCATION_LEVEL":
            return{
                ...state, educationLevels:{
                    ...state.educationLevels, error:null, spinner:true, message:null
                }
        }
    
    case "EDUCATION_LEVEL":
            return{
                ...state, educationLevels:{
                    ...state.educationLevels, error:null, spinner:false, list:action.payload
                }
            }
    
    case "EDUCATION_LEVEL_FAILLURE":
            return{
                ...state, educationLevels:{
                    ...state.educationLevels, error:action.payload, spinner:false, message:null
                }
            }
    case "AEDUCATION_LEVEL_MESSAGES":
            return{
                ...state, educationLevels:{
                    ...state.educationLevels, error:null, spinner:false, message:null
                }
            }    
  
            

 /**
  * Get Experience with slug
  */
 case "HANDLE_GET_PROFILE_EXPERIENCES":
    return{
        ...state, experiences:{
            ...state.experiences, error:null, spinner:true
        }
}
case "GET_PROFILE_EXPERIENCES":
return {
...state, experiences:{
    ...state.experiences, error:null, spinner:false, list:action.payload.results}
}

case "GET_PROFILE_EXPERIENCES_FAILLURE":
        return{
            ...state, experiences:{
                ...state.experiences, error:action.payload, spinner:true, list:null
            }
    }



 /**
  * Get Education with slug
  */
 case "HANDLE_GET_PROFILE_EDUCATIONS":
    return{
        ...state, educations:{
            ...state.educations, error:null, spinner:true
        }
}
case "GET_PROFILE_EDUCATIONS":
return {
...state, educations:{
    ...state.educations, error:null, spinner:false, list:action.payload.results}
}

case "GET_PROFILE_EDUCATIONS_FAILLURE":
        return{
            ...state, educations:{
                ...state.educations, error:action.payload, spinner:true, list:null
            }
    }

/**
  * Add Experience profil
  */


 case "HANDLE_ADD_EXPERIENCE":
    return{
        ...state, add_experience:{
            ...state.add_experience, error:null, spinner:true, message:null
        }
}

case "ADD_EXPERIENCE":
    return{
        ...state, add_experience:{
            ...state.add_experience, error:null, spinner:false, message:action.payload
        }
    }

case "ADD_EXPERIENCE_FAILLURE":
    return{
        ...state, add_experience:{
            ...state.add_experience, error:action.payload, spinner:false, message:null
        }
    }
case "ADD_EXPERIENCE_RESET_MESSAGES":
    return{
        ...state, add_experience:{
            ...state.add_experience, error:null, spinner:false, message:null
        }
    }   

    /**
  * Edit Experience profil
  */


 case "HANDLE_EDIT_EXPERIENCE":
    return{
        ...state, edit_experience:{
            ...state.edit_experience, error:null, spinner:true, message:null
        }
}

case "EDIT_EXPERIENCE":
    return{
        ...state, edit_experience:{
            ...state.edit_experience, error:null, spinner:false, message:action.payload
        }
    }

case "EDIT_EXPERIENCE_FAILLURE":
    return{
        ...state, edit_experience:{
            ...state.edit_experience, error:action.payload, spinner:false, message:null
        }
    }
case "EDIT_EXPERIENCE_RESET_MESSAGES":
    return{
        ...state, edit_experience:{
            ...state.edit_experience, error:null, spinner:false, message:null
        }
    }   

/**
  * Add Skill profil
  */


 case "HANDLE_ADD_SKILLS":
    return{
        ...state, add_skills:{
            ...state.add_skills, error:null, spinner:true, message:null
        }
}

case "ADD_SKILLS":
    return{
        ...state, add_skills:{
            ...state.add_skills, error:null, spinner:false, message:action.payload
        }
    }

case "ADD_SKILLS_FAILLURE":
    return{
        ...state, add_skills:{
            ...state.add_skills, error:action.payload, spinner:false, message:null
        }
    }
case "ADD_SKILLS_RESET_MESSAGES":
    return{
        ...state, add_skills:{
            ...state.add_skills, error:null, spinner:false, message:null
        }
    }    





/**
  * Add Sector profil
  */


 case "HANDLE_ADD_SECTORS":
    return{
        ...state, add_industries:{
            ...state.add_industries, error:null, spinner:true, message:null
        }
}

case "ADD_SECTORS":
    return{
        ...state, add_industries:{
            ...state.add_industries, error:null, spinner:false, message:action.payload
        }
    }

case "ADD_SECTORS_FAILLURE":
    return{
        ...state, add_industries:{
            ...state.add_industries, error:action.payload, spinner:false, message:null
        }
    }
case "ADD_SECTORS_RESET_MESSAGES":
    return{
        ...state, add_industries:{
            ...state.add_industries, error:null, spinner:false, message:null
        }
    }   





/**
  * Add file profil
  */

 case "HANDLE_UPLOAD_DOC":
           return {...state, add_file:{
               ...state.add_file, error:null, spinner:true, message:null
           }}
   case "UPLOAD_DOC_FAILLURE":
           return {...state, add_file:{
               ...state.add_file, error:action.payload, spinner:false, message:null
           }}
   case "UPLOAD_DOC":
      return {...state, add_file:{
          ...state.add_file, error:null, spinner:false, message:action.payload
      }}
   case "REMOVE_UPLOAD_DOC_MESSAGES":
       return{
           ...state, add_file:{
               ...state.add_file, error:null, spinner:false, message:null
           }
       }
    case "ADD_EXPERIENCE_RESET_MESSAGES":
        return{
            ...state, add_file:{
                ...state.add_file, error:null, spinner:false, message:null
            }
        }
        


 /**
  * Get files with slug
  */
 case "HANDLE_GET_PROFILE_FILE":
    return{
        ...state, files:{
            ...state.files, error:null, spinner:true
        }
}
case "GET_PROFILE_FILE":
return {
...state, files:{
    ...state.files, error:null, spinner:false, list:action.payload}
}

case "GET_PROFILE_FILE_FAILLURE":
        return{
            ...state, files:{
                ...state.files, error:action.payload, spinner:true, list:null
            }
    }



/**
  * Add langue profil
  */


 case "HANDLE_ADD_LANGUE":
    return{
        ...state, add_langue:{
            ...state.add_langue, error:null, spinner:true, message:null
        }
}

case "ADD_LANGUE":
    return{
        ...state, add_langue:{
            ...state.add_langue, error:null, spinner:false, message:action.payload
        }
    }

case "ADD_LANGUE_FAILLURE":
    return{
        ...state, add_langue:{
            ...state.add_langue, error:action.payload, spinner:false, message:null
        }
    }
case "ADD_LANGUE_RESET_MESSAGES":
    return{
        ...state, add_langue:{
            ...state.add_langue, error:null, spinner:false, message:null
        }
    }

 /**
  * Get langue with slug
  */
 case "HANDLE_GET_PROFILE_LANGUE":
    return{
        ...state, langue:{
            ...state.langue, error:null, spinner:true
        }
}
case "GET_PROFILE_LANGUE":
return {
...state, langue:{
    ...state.langue, error:null, spinner:false, list:action.payload.results}
}

case "GET_PROFILE_LANGUE_FAILLURE":
        return{
            ...state, langue:{
                ...state.langue, error:action.payload, spinner:true, list:null
            }
    }


    




/**
  * Add PUBLICATION profil
  */


 case "HANDLE_ADD_PUBLICATION":
    return{
        ...state, add_publication:{
            ...state.add_publication, error:null, spinner:true, message:null
        }
}

case "ADD_PUBLICATION":
    return{
        ...state, add_publication:{
            ...state.add_publication, error:null, spinner:false, message:action.payload
        }
    }

case "ADD_PUBLICATION_FAILLURE":
    return{
        ...state, add_publication:{
            ...state.add_publication, error:action.payload, spinner:false, message:null
        }
    }
case "ADD_PUBLICATION_RESET_MESSAGES":
    return{
        ...state, add_publication:{
            ...state.add_publication, error:null, spinner:false, message:null
        }
    }

 /**
  * Get langue with slug
  */
 case "HANDLE_GET_PROFILE_PUBLICATION":
    return{
        ...state, publication:{
            ...state.publication, error:null, spinner:true
        }
}
case "GET_PROFILE_PUBLICATION":
return {
...state, publication:{
    ...state.publication, error:null, spinner:false, list:action.payload.results}
}

case "GET_PROFILE_PUBLICATION_FAILLURE":
        return{
            ...state, publication:{
                ...state.publication, error:action.payload, spinner:true, list:null
            }
    }
default:
return state;
      
}
}
