import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Slider from "react-slick";
import {useDispatch, useSelector} from 'react-redux'
import {Animated} from "react-animated-css";
// import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { Image } from "react-atomic-molecule";
import { Carousel, CarouselAnimation } from "organism-react-carousel";
import $ from 'jquery';
const useStyles = makeStyles((theme) => ({
  footer: {
    // backgroundColor:'#ffffff',
    // marginTop:15,
    marginLeft:24,
    marginRight:24,
    padding:15,
    paddingLeft:24,
    paddingRight:24,
    // width:'100%',
  },
  ul:{
    'list-style-type': 'none',
    paddingLeft: 0,

  },
  inline: {
    display: 'inline',
  },
  avatar:{
    width: 100,
    height: 100,
    borderRadius:'0%',
    marginRight: 24

  }, 

}));
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000
   
  };

  const Styles = {
    container: {
      margin: "0 10%",
    },
    carousel: {
      width: "100%",
      height: 300,
      background: "#00558B",
    },
    img: {
      maxWidth: "100%",
      verticalAlign: "bottom",
    },
  };
  
    
export default function Testimonies() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const  testimonies = useSelector(state=>state.testimonies.testimonies)
  useEffect(()=>{
    dispatch({type:'HANDLE_GET_TESTIMONIES', paylaod:{}})
  },[dispatch])

  
  return (
    <div className=" testimonies">
      <div className="container">
      <Animated animationIn="fadeInUp" animationOut="fadeOut">
    <footer className={classes.footer}>
      <Slider {...settings}>
        {
          testimonies.list.map((item)=>{
            return(
              <div class="user-list d-flex flex-row row" style={{backgroundColor:'#f4f4f7 !important'}}>
                  {/* <div class="img-testimonies col-md-4">
                  <img alt="domnic-harris" src={item.image?item.image:"https://via.placeholder.com/150x150"}
                      class="user-avatar "/>  </div> */}
                       <div className="footer-testimonie col-md-3 col-12">
                        <img src={item.image} className="img-responsive" />
 
                        <h4><strong>{item.first_name} {item.last_name}</strong></h4>
                        <p className="testimonial_subtitle"><span>{item.position} | {item.organization}</span>
                        </p>
                    </div>


                  <div class="description col-md-9 col-12">
                    <div className="title-testimonie">
                      <h5>{item.title}</h5>
                      <div className="bottom-testimonie"></div>
                      </div>
                     
                     
                    <div className="body-testimonie">
                      <p class="text-muted">{item.content}</p>
                      </div>
                      
                  </div>
                  
              </div>
            )
          })
        }
        </Slider>

        
    </footer>
    </Animated>
    </div>
    </div>

  );
}

Testimonies.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};


// <Carousel>
//         <img
//           style={Styles.img}
//           src="https://c1.staticflickr.com/9/8567/16584613141_d984a3680a_b.jpg"
//         />
//       </Carousel>

/**
 * 
 *               <ListItem alignItems="flex-start">
                <Grid container spacing={3}>
                  <Grid item  xs={1}>
                      <ListItemAvatar>
                           <Avatar alt="Remy Sharp" src={item.image} className={classes.avatar}/>
                    </ListItemAvatar>
                  </Grid>
                  <Grid item xs={11}>
                      <ListItemText
                      primary={item.title}
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            {item.content}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>
              

 */




 