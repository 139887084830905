import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { shareCompanySuccess, shareCompanyError} from "actions/companies/getCompanies";

const shareCompanyData = async (instance, company_id, data)=>
    await instance.post(`/organizations/${company_id}/share/`, data)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* shareCompany(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(shareCompanyData,axiosInstance, actions.data.company_id, actions.data.email);
    if(data.message){
        yield put(shareCompanySuccess(data.message));
    }else{
        console.log("data.error", data, data.error)
        yield put(shareCompanyError(data.error));
    }
    
}
export function* handleShareCompany(){
    yield takeEvery("HANDLE_SHARE_COMPANY", shareCompany)
}
export default function* rootSaga() {
    yield all([fork(handleShareCompany)]);
}

