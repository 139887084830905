export function createCompanySetFormDataError(data){
    return {
        type:"CREATE_COMPANY_FAILLURE",
        payload:data
    }
}

export function successcreateCompanySetFormData(data){
    return{
        type:"CREATE_COMPANY",
        payload:data
    }
}

export function handleResetcreateCompany(){
    return{
        type:"REMOVE_CREATE_COMPANY_MESSAGES",
        payload:{}
    }
}