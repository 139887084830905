import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import IntlMessages from "util/IntlMessages";
import AddAchievement from 'pages/AddAchievement';
import AddPublication from 'pages/AddPublication';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import { connect } from 'react-redux';
const DEFAULT_NUMBER_DISPLAYED = 3;
const DEFAULT_NUMBER_LANGUE_DISPLAYED = 3;
class ProfileAchievement extends React.Component {

 constructor(props) {
    super(props);    
    this.state = { nLangue: DEFAULT_NUMBER_LANGUE_DISPLAYED };
    this.state = { nPub: DEFAULT_NUMBER_DISPLAYED };
    this.showAllLangue = this.showAllLangue.bind(this);
    this.hideAllLangue = this.hideAllLangue.bind(this);

    this.showAllPub = this.showAllPub.bind(this);
    this.hideAllPub = this.hideAllPub.bind(this);
  }

  showAllLangue() {
    this.setState({ nLangue: this.props.itemDetailsProfileLanguages.length })
  }

  hideAllLangue() {
    this.setState({ nLangue: DEFAULT_NUMBER_LANGUE_DISPLAYED })
  }

  showAllPub() {
    this.setState({ nPub: this.props.itemDetailsProfilePublications.length })
  }

  hideAllPub() {
    this.setState({ nPub: DEFAULT_NUMBER_DISPLAYED })
  }

  renderListLangue(n) {
  // var parser = new Parser();
  // const { userId } = this.props;
  return this.props.itemDetailsProfileLanguages.slice(0,n).map((langue) =>{
  return (                            
        
    <div className="row" key={langue.language_id.id}> 
    <div className="row col-md-12">

      <span className="col-md-4">
      {/* <DeleteIcon  onClick={e => this.handleRemoveLangue(langue.language_id.id)} style={{ color: "#ccc"}} /> */}
        {langue.language_id.name}</span>
      <Box className="col-md-8" component="fieldset" mb={3} borderColor="transparent">
          <Rating name="disabled" value={langue.language_level_id.level} disabled />
        </Box>
    </div>
    </div>
         )
    })
  }                                        
      
  renderListPublication(n) {
  const { t } = this.props;
  console.log("langue" , this.props.itemDetailsProfileLanguages)
  console.log("publication" , this.props.itemDetailsProfilePublications)
  // var parser = new Parser();
  return this.props.itemDetailsProfilePublications.slice(0,n).map((publication, index) =>{
  return (                            
        
           <div className="col-md-12" key={index}>
           <small>
           {publication.title}, {publication.publication_date ? publication.publication_date.substring(0,4): null }  
            <a href={publication.publication_url} target="blank"> {<IntlMessages id="open"/>}</a>
            </small>
            </div>
                
         )
    })
  }                      

  render() {
 const { t } = this.props;
    return (
      <div  className="bloc content-page-interne Achievements">
      {this.props.myProfile ?
           <div  data-aos="fade-up">
                <div className="row langues "  data-aos="fade-up">
                      <div className="col-md-6 col-6">
                        <h2  className="pull-left">  {<IntlMessages id="languages"/>} </h2></div>
                    <div className="col-md-6 col-6 text-right">
                            <AddAchievement itemDetailsProfileLanguages={this.props.LangueList}/>
                           </div>
                   
                </div>

                  <div className="langue-profil col-md-12"  data-aos="fade-up">
                   <div className="col-md-12 col-12 itemLangue">
                                            
                {this.props.itemDetailsProfileLanguages ? this.renderListLangue(this.state.nLangue) : null}
                
                 {this.props.itemDetailsProfileLanguages.length > 3 ?
                 this.state.nLangue === DEFAULT_NUMBER_LANGUE_DISPLAYED ?
                    <Button bsStyle="link" onClick={this.showAllLangue} className="neutre">
                      {<IntlMessages id="see_more"/>}
                   </Button>
                  :
                    <Button bsStyle="link" onClick={this.hideAllLangue} className="neutre">
                      {<IntlMessages id="see_more"/>}
                    </Button>

                  : null} 

              </div>
              </div>


                  <div className="row Publication"  data-aos="fade-up">
                      <div className="col-md-6 col-6">
                            <h2 className="title">{<IntlMessages id="publications"/>}</h2>
                        </div>
                      <div className="col-md-6 col-6 text-right">
                            <AddPublication publications={this.props.PublicationList}/>
                        </div>
                        <div className="col-md-12 col-12 itemLangue">
                        {this.props.itemDetailsProfilePublications ? this.renderListPublication(this.state.nPub) : null}
                        {this.props.itemDetailsProfilePublications.length > 3 ?
                        this.state.nPub === DEFAULT_NUMBER_DISPLAYED ?
                        <Button bsStyle="link" onClick={this.showAllPub} className="neutre">
                          {<IntlMessages id="see_more"/>}
                        </Button>
                        :
                        <Button bsStyle="link" onClick={this.hideAllPub} className="neutre">
                           {<IntlMessages id="see_more"/>}
                        </Button>

                        : null} </div>
              </div>
              
        </div>


        :

           <div className="row">
                <div className="row langues col-md-12">
                      <div className="col-md-6 col-6">
                        <h2  className="pull-left">{<IntlMessages id="achievements"/>} </h2></div>
                   
                </div>

                  <div className="langue-profil col-md-12">
                   <div className="col-md-12 col-12 itemLangue">  
                

                {this.props.itemDetailsProfileLanguages ? this.renderListLangue(this.state.nLangue) : null}
                
                 {this.props.itemDetailsProfileLanguages.length > 3 ?
                 this.state.nLangue === DEFAULT_NUMBER_LANGUE_DISPLAYED ?
                    <Button bsStyle="link" onClick={this.showAllLangue} className="neutre">
                      {<IntlMessages id="see_more"/>}
                   </Button>
                  :
                    <Button bsStyle="link" onClick={this.hideAllLangue} className="neutre">
                       {<IntlMessages id="see_more"/>}
                    </Button>

                  : null} 

              </div>
              </div>


                  <div className="Publication col-md-12">
                  {this.props.itemDetailsProfilePublications ?
                  <div className="col-md-12 col-12">
                     
                      {this.props.itemDetailsProfilePublications.length < 1 ?null:
                        <div>  <h4 className="title"> {<IntlMessages id="publications"/>}</h4></div>
                        
                      }
                      <div className="col-md-12 col-12 itemLangue">
                        {this.props.itemDetailsProfilePublications ? this.renderListPublication(this.state.nPub) : null}
                        {this.props.itemDetailsProfilePublications.length > 3 ?
                        this.state.nPub === DEFAULT_NUMBER_DISPLAYED ?
                       <Button bsStyle="link" onClick={this.showAllPub} className="col-md-12 text-right neutre">
                          {<IntlMessages id="see_more"/>}
                        </Button>
                        :
                        <Button bsStyle="link" onClick={this.hideAllPub} className="col-md-12 text-right neutre">
                           {<IntlMessages id="see_more"/>}
                        </Button>

                        : null} 
              </div></div>:null}
              </div>
        </div>}</div>
   );
  }
}
function mapStateToProps(state) {
  return {
    LangueList: state.profile.langue.list,
    PublicationList: state.profile.publication.list
  }
}
export default connect(mapStateToProps)(ProfileAchievement);