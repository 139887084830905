export function setFeaturedOppData(data) {
    return {type: "GET_FEATURED_OPPORTUNITIES", payload:data};
}
export function setAllopportunities(data){
    return {
        type: "SET_ALL_OPPORTUNITIES",
        payload:data
    }
}
export function setAllopportunitiesError(data){
    return{
        type:"SET_ALL_OPPORTUNITIES_FAILLURE",
        payload:data
    }
}
/**
 * 
 * Add opportunity anonymosly
 */
export function addOpportunitySuccess(data){
    return{
        type:"ADD_ANONYMOUSLY_OPPORTUNITY",
        payload:data
    }
}
export function addOpportunityError(data){
    console.log(data,'data-data')
    return{
        type:"ADD_ANONYMOUSLY_OPPORTUNITY_FAILLURE",
        payload:data
    }
}

export function handleAddOpportunity(data){
    return{
        type:"HANDLE_ADD_ANONYMOUSLY_OPPORTUNITY",
        data:data
    }
}


export function handleAddOpportunityHome(data){
    return{
        type:"HANDLE_ADD_ANONYMOUSLY_OPPORTUNITY_HOME",
        data:data
    }
}

export function handleAddOpportunityDraftHome(data){
    return{
        type:"HANDLE_ADD_ANONYMOUSLY_OPPORTUNITY_DRAFT_HOME",
        data:data
    }
}


export function addOpportunityResetMessages(){
    return{
        type:"ADD_ANONYMOUSLY_OPPORTUNITY_RESET_MESSAGES",
        payload:""
    }
}


/**
 * 
 * Add opportunity anonymosly DRAFT
 */
export function addOpportunityDraftSuccess(data){
    return{
        type:"ADD_ANONYMOUSLY_OPPORTUNITY_DRAFT",
        payload:data
    }
}
export function addOpportunityDraftError(data){
    console.log(data,'data-data')
    return{
        type:"ADD_ANONYMOUSLY_OPPORTUNITY_DRAFT_FAILLURE",
        payload:data
    }
}

export function handleAddOpportunityDraft(data){
    return{
        type:"HANDLE_ADD_ANONYMOUSLY_OPPORTUNITY_DRAFT",
        data:data
    }
}

export function addOpportunityDraftResetMessages(){
    return{
        type:"ADD_ANONYMOUSLY_OPPORTUNITY_DRAFT_RESET_MESSAGES",
        payload:""
    }
}

/***
 * Get opportunity details with slug
 */
export function handleGetopportunityDetails(slug){
    return{
        type:"HANDLE_GET_OPPORTUNITY_DETAILS",
        slug:slug
    }
}
//Backend response data
export function getOpportunityDetailsSuccess(data){
    console.log(data,"GET_OPPORTUNITY_DETAILS")
    return{
        type:"GET_OPPORTUNITY_DETAILS",
        payload:data
    }
}
//  Backend Error message
export function getOpportunityDetailsError(data){
    return{
        type:"GET_OPPORTUNITY_DETAILS_FAILLURE",
        payload:data
    }
}

//...............SAVE Opportunity....

export function HandleSaveOpportunity(data){
    return{
        type:"HANDLE_SAVE_OPPORTUNITY",
        data:data
    }
}

export function SaveOpportunitySuccess(data){
    return{
        type:"SAVE_OPPORTUNITY_SUCCESS",
        payload:data
    }
}
// Backend Error message
export function SaveOpportunityError(data){
    return{
        type:"SAVE_OPPORTUNITY_FAILLURE",
        payload:data
    }
}

export function saveOpportunityResetMessages(){
    return{
        type:"SAVE_OPPORTUNITY_RESET_MESSAGES"
    }
}

//...............Apply Opportunity....

export function HandleApplyOpportunity(data){
    return{
        type:"HANDLE_APPLY_OPPORTUNITY",
        data:data
    }
}
export function ApplyOpportunitySuccess(data){
    return{
        type:"APPLY_OPPORTUNITY_SUCCESS",
        payload:data
    }
}
export function ApplyOpportunityError(data){
    return{
        type:"APPLY_OPPORTUNITY_FAILLURE",
        payload:data
    }
}
export function ApplyOpportunityResetMessages(){
    return{
        type:"APPLY_OPPORTUNITY_RESET_MESSAGES"
    }
}



//............... Opportunity Status....


export function HandleStatusOpportunity(data){
    return{
        type:"HANDLE_STATUS_OPPORTUNITY",
        data:data
    }
}
export function StatusOpportunitySuccess(data){
    return{
        type:"STATUS_OPPORTUNITY_SUCCESS",
        payload:data
    }
}
export function StatusOpportunityError(data){
    return{
        type:"STATUS_OPPORTUNITY_FAILLURE",
        payload:data
    }
}
export function StatusOpportunityResetMessages(){
    return{
        type:"STATUS_OPPORTUNITY_RESET_MESSAGES"
    }
}
//...............Share Opportunity....
export function shareOpportunitySuccess(data){
    return{
        type:"SHARE_OPPORTUNITY",
        payload:data
    }
}
export function shareOpportunityError(data){
    console.log(data,'data-data')
    return{
        type:"SHARE_OPPORTUNITY_FAILLURE",
        payload:data
    }
}

export function handleShareopportunity(data){
    return{
        type:"HANDLE_SHARE_OPPORTUNITY",
        data:data
    }
}

export function ShareOpportunityResetMessages(){
    return{
        type:"SHARE_RESET_MESSAGES",
        payload:""
    }
}

// Starting the process
export function handleResumeCheck(data){
    return{
        type:"HANDLE_RESUME_CHECK",
        data: data
    }
}

//handle success
export function resumeCheckSuccess(data){
    return{
        type:"RESUME_CHECK",
        payload: data
    }
}
//handle errors
export function resumeCheckError(data){
    return{
        type:"RESUME_CHECK_FAILLURE",
        payload: data
    }
}
// reset messages

export function resumeCheckResetMessages(){
    return{
        type:"RESUME_CHECK_RESET_MESSAGES",
        payload:{}
    }
}










/***
 * Get profile details with id
 */
export function handleGetProfileDetails(slug){
    return{
        type:"HANDLE_GET_PROFILE_DETAILS",
        slug:slug
    }
}
//Backend response data
export function getProfileDetailsSuccess(data){
    console.log(data,"GET_PROFILE_DETAILS")
    return{
        type:"GET_PROFILE_DETAILS",
        payload:data
    }
}
//  Backend Error message
export function getProfileDetailsError(data){
    return{
        type:"GET_PROFILE_DETAILS_FAILLURE",
        payload:data
    }
}




/***
 * Get profile details with id
 */
export function handleGetProfileDetailsForm(slug){
    return{
        type:"HANDLE_GET_PROFILE_DETAILS_FORM",
        slug:slug
    }
}
//Backend response data
export function getProfileDetailsFormSuccess(data){
    console.log(data,"GET_PROFILE_DETAILS_FORM")
    return{
        type:"GET_PROFILE_DETAILS_FORM",
        payload:data
    }
}
//  Backend Error message
export function getProfileDetailsFormError(data){
    return{
        type:"GET_PROFILE_DETAILS_FORM_FAILLURE",
        payload:data
    }
}


/***
 * Edit opportunity
 */
export function handleEditOpportunity(data){
    return{
        type:"HANDLE_EDIT_OPPORTUNITY",
        data:data
    }
  }
  //Backend response data
  export function EditOpportunitySuccess(data){
    console.log(data,"EDIT_OPPORTUNITY")
    return{
        type:"EDIT_OPPORTUNITY",
        payload:data
    }
  }
  //  Backend Error message
  export function EditOpportunityError(data){
    return{
        type:"EDIT_OPPORTUNITY_FAILLURE",
        payload:data
    }
  }
  export function EditOpportunityResetMessages(data){
    return{
        type:"EDIT_OPPORTUNITY_RESET_MESSAGES",
        payload:data
    }
  }



/***
 * Edit opportunity
 */
export function handleEditSaveOpportunity(data){
    return{
        type:"HANDLE_SAVE_EDIT_OPPORTUNITY",
        data:data
    }
  }
  //Backend response data
  export function EditSaveOpportunitySuccess(data){
    console.log(data,"EDIT_SAVE_OPPORTUNITY")
    return{
        type:"EDIT_SAVE_OPPORTUNITY",
        payload:data
    }
  }
  //  Backend Error message
  export function EditSaveOpportunityError(data){
    return{
        type:"EDIT_SAVE_OPPORTUNITY_FAILLURE",
        payload:data
    }
  }
  export function EditSaveOpportunityResetMessages(data){
    return{
        type:"EDIT_SAVE_OPPORTUNITY_RESET_MESSAGES",
        payload:data
    }
  }
    /***
 * Get Edit company 
 */
export function handleGetEditOpportunityDetails(slug){
    return{
        type:"HANDLE_GET_EDIT_OPPORTUNITY_DETAILS",
        slug:slug
    }
  }
  //Backend response data
  export function getEditOpportunityDetailsSuccess(data){
    console.log(data,"GET_EDIT_OPPORTUNITY")
    return{
        type:"GET_EDIT_OPPORTUNITY",
        payload:data
    }
  }
  //  Backend Error message
  export function getEditOpportunityDetailsError(data){
    return{
        type:"GET_EDIT_OPPORTUNITY_FAILLURE",
        payload:data
    }
  }
  export function getEditOpportunityDetailsResetMessages(data){
    return{
        type:"GET_EDIT_OPPORTUNITY_RESET_MESSAGES",
        payload:data
    }
  }