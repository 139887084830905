const initialState = {
    error: null,
    spinner:false,
    details:{
    },
    company:{
      error: null,
      message: null,
      spinner: false,
      list: [],
    },    
    all_companies:{
        list:[],
        spinner:false,
        error:null,
        count:0
    },
    list_companies:{
        list:[],
        spinner:false,
        error:null,
        count:0
    },
    all_companies_admin:{
        list:[],
        spinner:false,
        error:null,
        count:0
    },
    get_company_details:{
        list:{},
        spinner:false,
        error:null,
        count:0
    },
    shareCompany:{
        spinner:false,
        error:null,
        message:null
    },
    saveCompany:{
        status: null,
        error: null,
        message:null,
    },
    applyCompany:{
        status: null,
        error: null,
        message:null,
    
     },
     all_opportunities_by_company:{
        list:[],
        spinner:false,
        error:null,
        count:0
    },
    all_profiles_by_company:{
        list:[],
        spinner:false,
        error:null,
        count:0
    },
    organization:{
        list:[],
        spinner:false,
        error:null,
        count:0
    },
    resume_check_company:{
        error:null,
        spinner:false,
        results:[],
        data:{}
    },
    company_latest:{
        list:[],
        spinner:false,
        error:null,
        count:0
    },
    edit_company:{
        spinner:false,
        error:null,
        message:null
    },
    edit_company_photo:{
        spinner:false,
        error:null,
        message:null
    },
    edit_company_cover_photo:{
        spinner:false,
        error:null,
        message:null
    },
    get_edit_company:{
        details:{},
        status: null,
        error: null,
        message:null,
    },
  }
  
  export function company(state = initialState, action){
  
   switch(action.type) {



/**
  * Get Edit company
  */

 case "HANDLE_GET_EDIT_COMPANY_DETAILS":
     return{
         ...state, get_edit_company:{
             ...state.get_edit_company, error:null, spinner:true
         }
 }
 case "GET_EDIT_COMPANY":
     return{
         ...state, get_edit_company:{
             ...state.get_edit_company, error:null, spinner:false, details:action.payload
         }
 }
 case "GET_EDIT_COMPANY_FAILLURE":
         return{
             ...state, get_edit_company:{
                 ...state.get_edit_company, error:action.payload, spinner:true, details:null
             }
     }

case "GET_EDIT_COMPANY_RESET_MESSAGES":
    return{
        ...state, get_edit_company:{
            ...state.get_edit_company, error:null, spinner:false, message:null
        }
    } 

/**
  * Edit company
  */


 case "HANDLE_EDIT_COMPANY":
    return{
        ...state, edit_company:{
            ...state.edit_company, error:null, spinner:true, message:null
        }
}

case "EDIT_COMPANY":
    return{
        ...state, edit_company:{
            ...state.edit_company, error:null, spinner:false, message:action.payload
        }
    }

case "EDIT_COMPANY_FAILLURE":
    return{
        ...state, edit_company:{
            ...state.edit_company, error:action.payload, spinner:false, message:null
        }
    }
case "EDIT_COMPANY_RESET_MESSAGES":
    return{
        ...state, edit_company:{
            ...state.edit_company, error:null, spinner:false, message:null
        }
    } 



/**
  * Edit company photo
  */


 case "HANDLE_EDIT_PHOTO_COMPANY":
    return{
        ...state, edit_company_photo:{
            ...state.edit_company_photo, error:null, spinner:true, message:null
        }
}

case "EDIT_PHOTO_COMPANY":
    return{
        ...state, edit_company_photo:{
            ...state.edit_company_photo, error:null, spinner:false, message:action.payload
        }
    }

case "EDIT_PHOTO_COMPANY_FAILLURE":
    return{
        ...state, edit_company_photo:{
            ...state.edit_company_photo, error:action.payload, spinner:false, message:null
        }
    }
case "EDIT_PHOTO_COMPANY_RESET_MESSAGES":
    return{
        ...state, edit_company_photo:{
            ...state.edit_company_photo, error:null, spinner:false, message:null
        }
    } 




/**
  * Edit company cover photo
  */


 case "HANDLE_EDIT_COVER_PHOTO_COMPANY":
    return{
        ...state, edit_company_cover_photo:{
            ...state.edit_company_cover_photo, error:null, spinner:true, message:null
        }
}

case "EDIT_COVER_PHOTO_COMPANY":
    return{
        ...state, edit_company_cover_photo:{
            ...state.edit_company_cover_photo, error:null, spinner:false, message:action.payload
        }
    }

case "EDIT_COVER_PHOTO_COMPANY_FAILLURE":
    return{
        ...state, edit_company_cover_photo:{
            ...state.edit_company_cover_photo, error:action.payload, spinner:false, message:null
        }
    }
case "EDIT_COVER_PHOTO_COMPANY_RESET_MESSAGES":
    return{
        ...state, edit_company_cover_photo:{
            ...state.edit_company_cover_photo, error:null, spinner:false, message:null
        }
    } 
/**
     * company resume check
     */

    case "HANDLE_RESUME_COMPANY_CHECK":
        return{
            ...state, resume_check_company:{
                ...state.resume_check_company, error:null, spinner:true, data:{}, results:[]
            }
    }
    case "RESUME_CHECK_COMPANY":
        return{
            ...state, resume_check_company:{
                ...state.resume_check_company, error:null, spinner:false,
                 data:action.payload,
                 results:action.payload.message.results
            }
    }
    
    case "RESUME_CHECK_COMPANY_FAILLURE":
            return{
                ...state, resume_check_company:{
                    ...state.resume_check_company, error:action.payload, spinner:false, data:{},
                    results:action.payload.message.results
                }
        }
    case "RESUME_CHECK_COMPANY_RESET_MESSAGES":
        return{
            ...state, resume_check_company:{
                ...state.resume_check_company, error:null, spinner:false, data:{},
                results:[]
            }
        }
    


    
 
   /**
    * Get company list with slug
    */
        case "HANDLE_GET_COMPANY_LATEST":
        return{
             ...state, company_latest:{
              ...state.company_latest, error:null, spinner:true
        }
        }
        case "GET_COMPANIES_LATEST":
        return {
        ...state, company_latest:{
            ...state.company_latest, error:null, spinner:false, list:action.payload}
        }

        case "GET_COMPANIES_LATEST_FAILLURE":
                return{
                    ...state, company_latest:{
                        ...state.company_latest, error:action.payload, spinner:true, list:null
                    }
            }

        case "COMPANY_MESSAGES_LATEST":
                return{
                    ...state, company_latest:{
                        ...state.company_latest, error:null, spinner:false, message:null
                    }
                }  

                


                case "HANDLE_GET_COMPANIES_ADMIN":
                    return{
                         ...state, all_companies_admin:{
                          ...state.all_companies_admin, error:null, spinner:true,count:0
                    }
                    }
                    case "GET_COMPANIES_ADMIN":
                    return {
                    ...state, all_companies_admin:{
                        ...state.all_companies_admin, error:null, spinner:false, list:action.payload.results, count:action.payload.count}
                    }
            
                    case "GET_COMPANIES_ADMIN_FAILLURE":
                            return{
                                ...state, all_companies_admin:{
                                    ...state.all_companies_admin, error:action.payload, spinner:true, list:null,count:0
                                }
                        }
            
                    case "GET_COMPANIES_ADMIN_RESET_MESSAGE":
                            return{
                                ...state, all_companies_admin:{
                                    ...state.all_companies_admin, error:null, spinner:false, message:null, count:0
                                }
                            }  

                            
    case "SET_ALL_COMPANIES":
        return {
            ...state, all_companies:{
                ...state.all_companies, error:null, spinner:false, list:action.payload.results, count:action.payload.count
            }
        }
    case "SET_ALL_COMPANIES_FAILLURE":
        return {
            ...state, all_companies:{
                ...state.all_companies, error:action.payload, spinner:false, list:[], count:0
            }
        }
    case "HANDLE_SEARCH_COMPANIES":
        return{
            ...state, all_companies:{
                ...state.all_companies, error:null, spinner:true, list:[], count:0
        }}
    



        case "SET_ALL_ORGANIZATIONS":
            return {
                ...state, organization:{
                    ...state.organization, error:null, spinner:false, list:action.payload.results, count:action.payload.count
                }
            }
        case "SET_ALL_ORGANIZATIONS_FAILLURE":
            return {
                ...state, organization:{
                    ...state.organization, error:action.payload, spinner:false, list:{}, count:0
                }
            }
        case "HANDLE_SEARCH_ORGANIZATIONS":
            return{
                ...state, organization:{
                    ...state.organization, error:null, spinner:true, list:{}, count:0
            }}
        

    

    case "HANDLE_GET_OPPORTUNITIES_BY_COMPANY":
            return{
                ...state, all_opportunities_by_company:{
                    ...state.all_opportunities_by_company, error:null, spinner:true, list:action.payload, count:0
            }}

    case "GET_OPPORTUNITIES_BY_COMPANY_SUCCESS":
        return {
            ...state, all_opportunities_by_company:{
                ...state.all_opportunities_by_company, error:null, spinner:false, list:action.payload.results, count:action.payload.count
            }
        }
    case "GET_OPPORTUNITIES_BY_COMPANY_FAILLURE":
        return {
            ...state, all_opportunities_by_company:{
                ...state.all_opportunities_by_company, error:action.payload, spinner:false, list:null, count:0
            }
        }
   
    
    


        case "GET_PROFILES_BY_COMPANY_SUCCESS":
        return {
            ...state, all_profiles_by_company:{
                ...state.all_profiles_by_company, error:null, spinner:false, list:action.payload, count:action.payload.count
            }
        }
    case "GET_PROFILES_BY_COMPANY_FAILLURE":
        return {
            ...state, all_profiles_by_company:{
                ...state.all_profiles_by_company, error:action.payload, spinner:false, list:null, count:0
            }
        }
    case "HANDLE_GET_PROFILES_BY_COMPANY":
        return{
            ...state, all_profiles_by_company:{
                ...state.all_profiles_by_company, error:action.payload, spinner:true, list:action.payload, count:0
        }}
    
    
    
   /**
    * Get Profil company with slug
    */
  case "HANDLE_GET_COMPANY":
          return{
              ...state, company:{
                  ...state.company, error:null, spinner:true
              }
      }
  case "GET_COMPANY":
      return {
      ...state, company:{
          ...state.company, error:null, spinner:false, list:action.payload}
      }
      
  case "GET_COMPANY_FAILLURE":
              return{
                  ...state, company:{
                      ...state.company, error:action.payload, spinner:true, list:null
                  }
          }
 

    case "COMPANY_MESSAGES":
              return{
                  ...state, company:{
                      ...state.company, error:null, spinner:false, message:null
                  }
              }    
  



    /**
     * Get COMPANY details with slug
     */
    case "HANDLE_GET_COMPANY_DETAILS":
        return{
            ...state, get_company_details:{
                ...state.get_company_details, error:null, spinner:true
            }
    }
    case "GET_COMPANY_DETAILS":
        return{
            ...state, get_company_details:{
                ...state.get_company_details, error:null, spinner:false, details:action.payload
            }
    }
    case "GET_COMPANY_DETAILS_FAILLURE":
            return{
                ...state, get_company_details:{
                    ...state.get_company_details, error:action.payload, spinner:true, details:null
                }
        }



  case "HANDLE_SAVE_COMPANY":
            return {...state, saveCompany: {...state.saveCompany , status: 'creating',  message: null  }};
    
    case "SAVE_COMPANY_SUCCESS":
        return {...state, saveCompany: 
            {...state.saveCompany , status: 'creating',  
                message: action.payload }};
    case "SAVE_COMPANY_RESET_MESSAGES":
         return {
            ...state, saveCompany:{
                ...state.saveCompany,
                status:null, error:null, message:null
            }
         }
    case "SAVE_COMPANY_FAILLURE":
         return {...state, saveCompany: 
                {...state.saveCompany , 
                    status: 'creating', 
                    error: action.payload.error || action.payload.message}}
        
    case "HANDLE_APPLY_COMPANY":
                    return {...state, applyCompany: {...state.applyCompany , status: 'creating',  message: null  }};
            
            case "APPLY_COMPANY_SUCCESS":
                return {...state, applyCompany: 
                    {...state.applyCompany , status: null,  
                        message: action.payload }};
            case "APPLY_COMPANY_RESET_MESSAGES":
                 return {
                    ...state, applyCompany:{
                        ...state.applyCompany,
                        status:null, error:null, message:null
                    }
                 }
            case "APPLY_COMPANY_FAILLURE":
                    return {...state, applyCompany: 
                        {...state.applyCompany , 
                            status: null, 
                            error: action.payload || action.payload}};
              
                        
     /**
     * Share Opportunities
     */
    case "HANDLE_SHARE_COMPANY":
        return{
            ...state, shareCompany:{
                ...state.shareCompany, error:null, spinner:true, message:null
            }
    }

    case "SHARE_COMPANY":
        return{
            ...state, shareCompany:{
                ...state.shareCompany, error:null, spinner:false, message:action.payload
            }
        }

    case "SHARE_COMPANY_FAILLURE":
        return{
            ...state, shareCompany:{
                ...state.shareCompany, error:action.payload, spinner:false, error:null
            }
        }
    case "SHARE_COMPANY_RESET_MESSAGES":
        return{
            ...state, shareCompany:{
                ...state.shareCompany, error:null, spinner:false, message:null
            }
        }  
                    
  

       default:
        return state;
        
  }
  }
  