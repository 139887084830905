const initialState = {
    last_updates:{
        error:null,
        spinner:false,
        list:[]
    }
 }

 export function lastUpdates(state = initialState, action){
 
   switch(action.type) {
   case "HANDLE_GET_LAST_UPDATES":
           return {...state, last_updates:{
               ...state.last_updates, error:null, spinner:true, list:[]
           }}
   case "GET_LAST_UPDATES_FAILLURE":
           return {...state, last_updates:{
               ...state.last_updates, error:action.payload, spinner:false, list:[]
           }}
   case "GET_LAST_UPDATES":
      return {...state, last_updates:{
          ...state.last_updates, error:null, spinner:false, list:action.payload
      }}
     default:
       return state;
   }
 }
