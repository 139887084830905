// import React from 'react';
import PropTypes from 'prop-types';
import React,{useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import logo from '../../assets/imgs/wutiko-logo-black-yellow.png';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import ContactForm from 'components/formsElements/ContactForm';
import { handleGetForm } from 'actions/forms/formsGenerator';
import { CONTACT_FORM_ID } from 'config';
import IntlMessages from "util/IntlMessages";
import {STORE_URL , PAGE_URL} from "config";
import UploadCV from '../uploadCV/UploadCV';
import CreateCompany from '../companies/CreateCompany';
import { GET_TOKEN_URL } from 'config';
import AddOpportunityNotAuth from '../opportunities/AddOpportunityNotAuth';
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import {switchLanguage, toggleCollapsedNav} from "actions";
import {useDispatch, useSelector} from 'react-redux' ;
import LanguageSwitcher from "components/LanguageSwitcher/index";
import {createFormResetMessages} from 'actions/forms/createForm';
function Copyright() {
  return (

    <Typography variant="body2" className="Copyright" color="textSecondary">
    {'©'+new Date().getFullYear()},
    <Link color="inherit" href="https://www.wutiko.com/">
      Wutiko.
    </Link>
    {' '}{'All right reserved'}
    </Typography>
    );
    }
const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft:24,
    paddingRight:24,
    paddingTop:24,
    paddingBottom:24,
    width:'100%',
  },
  ul:{
    'list-style-type': 'none',
    paddingLeft: 0,

  },
  icons:{
    fontSize:50,
  },
  rh:{
    align:'right',
    'list-style-type': 'none',
    paddingLeft: 0,
    [theme.breakpoints.down('sm')]:{
      align:'left',
      'list-style-type': 'none',
      paddingLeft: 0,
    }
  },
  terms:{
    justifyContent:'flex-end',
    paddingLeft: 5,
    paddingRight: 5,
    [theme.breakpoints.down('sm')]:{
      justifyContent:'center',
      paddingLeft: 5,
      paddingRight: 5,
    }
  },
  btn:{
    backgroundColor:"#fdc000",
    '&:hover':{
      backgroundColor:"#fdc000",
    },
    marginTop: 10,
    marginBottom: 10,
  }

}));

export default function Footer(props) {
  const classes = useStyles();
  // const dispatch =useDispatch()
  const [open, setOpen]=React.useState(false)
  const [opencv, setOpencv]=React.useState(false)
  const [opencompany, setopenCompany]=React.useState(false)
  const [openOpportunity, setopenOpportunity]=React.useState(false)
  const dispatch = useDispatch()
  const formStructure= useSelector(state=>state.formsGenerator.get_form);
  const spinner= useSelector(state=>state.formsGenerator.get_form.spinner);
  const properties= useSelector(state=>state.formsGenerator.get_form.properties);
  const required_1= useSelector(state=>state.formsGenerator.get_form.required);
  const required = required_1? required_1:[];
  const submit_data= useSelector(state=>state.formsGenerator.submit_contact_form);
  const uiSchema_1 = useSelector(state=>state.formsGenerator.get_form.uiSchema);
  const uiSchema =   uiSchema_1?  uiSchema_1:[];
  const [langSwitcher, setLangSwitcher] = useState(false);
  const {locale} = useSelector(({settings}) => settings);
  const isAuthenticated =useSelector(state=>state.users.authenticated)

  const onLangSwitcherSelect = () => {
    setLangSwitcher(!langSwitcher);
  };
  const onSwitchLanguage = (lang) => {
    dispatch(switchLanguage(lang))
  };

  const handleRequestClose = () => {
    setLangSwitcher(false);
  };
  const handleOpenModal= ()=>{
    setOpen(true)
  }
  const handleClose=()=>{
    setOpen(false)
    dispatch(createFormResetMessages())
  }
  const gotoLogin = ()=>{
    window.location.href=`${GET_TOKEN_URL}&state=/home`;
  }
  useEffect(()=>{
    dispatch(handleGetForm(CONTACT_FORM_ID))
  },[dispatch]);

  console.log("required" , required)
  return (
    <footer className="footer-footer">

    <div className="container">
    <ContactForm open={open}  handleClose={handleClose}/>
          <UploadCV open={opencv} handleClose={()=>setOpencv(false)}/>
          <CreateCompany open={opencompany} handleClose={()=>setopenCompany(false)}/>
          <AddOpportunityNotAuth open={openOpportunity} handleClose={()=>setopenOpportunity(false)}/>
      <Grid container>
        <Grid item md={4} xs={12} container>
            <Grid item md={12} xs={12} container>
            <Typography variant="h6" align="left" gutterBottom>
              {<IntlMessages id="about"/>}
              <div className="bottom"></div>
            </Typography>
            <p className="about">
            {<IntlMessages id="about_wutiko_short"/>}
            
              </p>
            </Grid>
            <div className="social-network row">
            <Grid xs={1}></Grid>
            <Grid xs={2}><a href="https://www.linkedin.com/company/wutiko" target="blank"><img className="rs" src="https://cdn.wutiko.com/v3/static/images/icons/medium/png/round/linkedin.png"/></a></Grid>
            <Grid xs={2}><a href="https://www.facebook.com/wutiko" target="blank"><img className="rs" src="https://cdn.wutiko.com/v3/static/images/icons/medium/png/round/facebook.png"/></a></Grid>
            <Grid xs={2}><a href="https://www.twitter.com/wutiko" target="blank"><img className="rs" src="https://cdn.wutiko.com/v3/static/images/icons/medium/png/round/twitter.png"/></a></Grid>
            <Grid xs={2}><a href="https://www.youtube.com/wutiko" target="blank"><img className="rs" src="https://cdn.wutiko.com/v3/static/images/icons/medium/png/round/youtube.png"/></a></Grid>
            <Grid xs={2}><a href="https://www.instagram.com/wutiko" target="blank"><img className="rs" src="https://cdn.wutiko.com/v3/static/images/icons/medium/png/round/instagram.png"/></a></Grid>
            <Grid xs={1}></Grid>
            </div>
        </Grid>
        <Grid item md={2} xs={6}>
        <Typography variant="h6" align="left" gutterBottom>
              {<IntlMessages id="talents"/>}
              <div className="bottom"></div>
            </Typography>
            <ul className={classes.ul}>
              <li><a href="#" onClick={()=>setOpencv(true)} >{<IntlMessages id="upload_cv"/>}</a></li>
              <li><a href="/opportunities">{<IntlMessages id="seize_an_opportunity"/>}</a></li>
              <li><a href={`${STORE_URL}/plans/pricing/`}>{<IntlMessages id="upgrade_to_premium"/>}</a></li>
              <li><a href={`${STORE_URL}`}>Wutiko STORE</a></li>
            </ul>
        </Grid>
        <Grid item md={2} xs={6}>
        <Typography variant="h6" align="left" gutterBottom>
        {<IntlMessages id="entreprises"/>}
              <div className="bottom"></div>
            </Typography>
            <ul className={classes.ul}>
              <li><a href="#" onClick={()=>setopenCompany(true)} >{<IntlMessages id="create_my_page"/>}</a></li>
              <li><a href="#" onClick={()=>setopenOpportunity(true)}>{<IntlMessages id="post_an_opportunity"/>}</a></li>
              <li> {isAuthenticated?
              <a href="/dashboard">{<IntlMessages id="access_to_dashboard"/>}</a>
              :
              <a href={`${GET_TOKEN_URL}&state=/home` }>{<IntlMessages id="access_to_dashboard"/>}</a>
              }</li>
              <li><a href={`${STORE_URL}/plans/pricing/`}>{<IntlMessages id="pricing"/>}</a></li>
            </ul>
        </Grid>
        <Grid item md={2} xs={6}>
        <Typography variant="h6" align="left" gutterBottom>
              {<IntlMessages id="resources"/>}
              <div className="bottom"></div>
            </Typography>
            <ul className={classes.ul}>
              {/* <li><a href={`${PAGE_URL}/blogs`}>{<IntlMessages id="blogs"/>}</a></li>
              <li><a href={`${PAGE_URL}/faq/`}>{<IntlMessages id="faq"/>}</a></li>
              <li><a href={`${PAGE_URL}/about/media-kit/`}>{<IntlMessages id="media_kit"/>}</a></li>
              <li><a href={`${PAGE_URL}/about/developers/`}>{<IntlMessages id="developers"/>}</a></li> */}
              <li><a href="#">{<IntlMessages id="blogs"/>}</a></li>
              <li><a href="#">{<IntlMessages id="faq"/>}</a></li>
              <li><a href="#">{<IntlMessages id="media_kit"/>}</a></li>
              <li><a href="#">{<IntlMessages id="developers"/>}</a></li>
            </ul>
        </Grid>
        <Grid item md={2} xs={6}>
            <Typography variant="h6" className={classes.rh} gutterBottom>
                {<IntlMessages id="contact"/>}
                <div className="bottom"></div>
            </Typography>
            <ul className={classes.rh}>
            <li><LocalPhoneIcon/> +221 76 849 16 16</li>
              <li>
                <Button variant="contained" className={classes.btn} onClick={handleOpenModal}> {<IntlMessages id="contact_us"/>}</Button>
              </li>
            </ul>
        </Grid>
      </Grid>
      <Grid container className="Copyright-footer">
        <Grid item md={4} xs={8}>
          <Copyright/>
        </Grid>
        <Grid item md={4} xs={4} container>
        <Grid item xs={12} sm={12} md={12} className="langue-footer">
        <Dropdown
                className="menu-langue langue"
                isOpen={langSwitcher}
                toggle={onLangSwitcherSelect}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <Button className="langue connect">{`${locale.name}`}</Button>
                </DropdownToggle>

                <DropdownMenu right className="w-50">
                  <LanguageSwitcher switchLanguage={onSwitchLanguage}
                                    handleRequestClose={handleRequestClose}/>
                </DropdownMenu>
              </Dropdown> 
        </Grid>
        </Grid>
        <Grid  md={4} xs={12} className="terms-conditions d-none  d-sm-none d-md-block d-lg-block">
          <Link href="https://pages.wutiko.com/en/legal/terms-and-conditions/" target="_blank">{<IntlMessages id="terms_and_conditions"/>}</Link> |
          <Link href="https://pages.wutiko.com/en/legal/terms-and-conditions/"  target="_blank">{<IntlMessages id="privacy_policy"/>}</Link>
        </Grid>
      </Grid>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
