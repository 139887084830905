import {useState,useEffect} from 'react'
import {useDispatch} from 'react-redux'
/*
Example of form submission with redux-saga associated to Hooks
https://github.com/jcolemorrison/redux-sagas-authentication-app/tree/master/src/signup
*/
const validate = (values,props) => {
    const errors = {};
  
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if(!values.first_name){
        errors.first_name = 'Required';    
    }
    if(!values.last_name){
        errors.last_name = 'Required';
    }
    return errors;
  };
function NewsLetterValidation(initialState){
    const [values, setValues] =useState(initialState);
    const [errors, setErrors] =useState(initialState);
    const [isSubmitting, setSubmitting] =useState(false);
    const dispatch = useDispatch()
    useEffect(()=>{
        if(isSubmitting){
            const noErrors = Object.keys(errors).length===0;
            if(noErrors){
                console.log("authenticated", values);
                setSubmitting(false)
            }else{
                setSubmitting(false)
            }
        }
    },[errors])
    const handleChange=(event)=>{
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }
    const handleSubmit=(event)=>{
        event.preventDefault();
        const ValidationErrors = validate(values);
        setErrors(ValidationErrors)
        console.log(values)
        setSubmitting(true)
        dispatch({type:"HANDLE_SET_NEWS_LETTER", payload:values})
    }
    const handleBlur=()=>{
        const ValidationErrors = validate(values);
        setErrors(ValidationErrors)
    }
    return{handleSubmit,handleChange,handleBlur,values, errors, isSubmitting}
}

export default NewsLetterValidation;