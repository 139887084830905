import React,{useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FeaturedOpportunities from 'components/uielements/FeaturedOpportunities';
import {useParams} from 'react-router-dom'
import {useDispatch,useSelector} from 'react-redux'
import { handleGetCompanyDetails,handleGetOpportunitiesByCompany , handleGetProfilesByCompany, saveCompanyResetMessages, HandleSaveCompany, HandleApplyCompany, ApplyCompanyResetMessages, SaveCompanySuccess, handleResumeCheckCompany } from 'actions/companies/getCompanies';
import CompanyDetailsTab from './CompanyDetailsTab';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import IconButton from '@material-ui/core/IconButton';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LaunchIcon from '@material-ui/icons/Launch';
import { Button} from '@material-ui/core';
import HorizontalItemCardCustom from 'components/dashboard/Common/HorizontalItemCardCustom';
import GetAppIcon from '@material-ui/icons/GetApp';
import HorizontalItemCardCustomShare from 'components/dashboard/Common/HorizontalItemCardCustomShare';
import {Alert} from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import {createMuiTheme} from "@material-ui/core/styles";
// import ShareOpportunity from './ShareOpportunity';
import {Link} from "react-router-dom";
import { company } from 'reducers/companies/getCompany';
import ShareCompany from './ShareCompany';
import SidebarRight from 'components/Sidebar/SidebarRight';
import {   resumeCheckCompanyResetMessages } from 'actions/companies/getCompanies';
import LatestCompanies from './LatestCompaniesList';
import IntlMessages from "util/IntlMessages";
import AlertDialog from "components/uielements/Alert"
import UploadCV from 'components/uploadCV/UploadCV';
import EditPhotoCompany from './EditPhotoCompany';
import { handleEditCoverPhotoCompany, handleEditPhotoCompany, handleGetEditCompanyDetails } from 'actions/companies/editCompany';
import EditCompanyPage from 'pages/CompanyFormEdit';
import Notif from 'components/uielements/Notification';
import BrowserLimitations from 'components/opportunities/BrowserLimitations';
import EditCoverPhoto from './EditCoverPhoto';
import { addOpportunityResetMessages } from 'actions/opportunities';
import AddOpportunityNotAuth from 'components/opportunities/AddOpportunityNotAuth';
import { page } from 'util/UtilFunctions';
const useStyles = makeStyles((theme) => ({
    cont:{

      paddingTop:0,
      paddingBottom:0,
      zIndex:1,
      background:"#fdc000"
    },
    title:{
      paddingBottom:15,
      color:"#000",
      fontWeight:'bold'
    },
    content:{
        paddingLeft:24,
        paddingRight:24,
        marginTop:15,
    },
    fieldset:{
        margin:0,
        padding:10,
        border:'solid 2px black',
      },
      legend:{
        display: 'block',
        fontSize: 15,
        width:'auto'
      },
    textAlignEnd:{
      textAlign:'end'
    },
    textAlignStart:{
      textAlign:'start'
    },
    textAlignCenter:{
      textAlign:'center'
    },
    margin:{
      margin:'7px !important'
    },
    icon:{
      width:70,
      height: 84,
    },
    upload:{
      backgroundColor:"#1269ea",
      color:"#fff"
    },
    isloadingOpp:{
      opacity:0.2,
      pointerEvents: 'none',
    },
    saved:{
      color:"green"
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '30%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    input: {
      display: 'block',
    },
  }));
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#fdc000",
        backgroundColor: green[500],
      },
      secondary: {
        main: "#fdc000",
        backgroundColor: "fdc000"
      },  
    },
  });


function CompanyDetails({ match }){
    const classes = useStyles();
    const {slug} =useParams();
    const [opencv, setOpencv]=React.useState(false)
    const company = useSelector(state=>state.company.get_company_details)
    const isAuthenticated =useSelector(state=>state.users.authenticated)
   // const state = useSelector(state=>state.opportunities.all_opportunities);
    const save_company =useSelector(state=>state.company.saveCompany)
    const apply_company =useSelector(state=>state.company.applyCompany)
   // const companies=state.list.opportunities?state.list.opportunities:[];
    const [openSpinner, setopenSpinner]=React.useState(false)
    const resume_check_company=useSelector(state=>state.company.resume_check_company)

    const ResultsResume_check_company=useSelector(state=>state.company.resume_check_company.results)
    const spinnerResumecheck=useSelector(state=>state.company.resume_check_company)
    const opportunitesbycompany = useSelector(state=>state.company.all_opportunities_by_company.list);
    const count = useSelector(state=>state.company.all_opportunities_by_company.count);
    const profilesbycompany = useSelector(state=>state.company.all_profiles_by_company.list);
    const spinnerOpp = useSelector(state=>state.company.all_opportunities_by_company.spinner);
    const spinnerProfile = useSelector(state=>state.company.all_profiles_by_company.spinner);
    const EditCompany = useSelector(state=>state.company.edit_company_photo)
    const EditCoverPhotoCompany = useSelector(state=>state.company.edit_company_cover_photo)
    const itemDetailsCompany = useSelector(state=>state.company.get_edit_company.details)
    const [openOpportunity, setopenOpportunity]=React.useState(false)
    const [fileState, setState]= useState({})
    const dispatch = useDispatch()
    console.log("spinnerResumecheck" , spinnerResumecheck);
    console.log("List opportunities By company" , opportunitesbycompany);
    console.log("List profiles By company" , profilesbycompany);
    const [open, setOpen]=React.useState(false)
    const [current_page, setCurrent_page] = React.useState(1);
    const RESULTS_PER_PAGE = 4;
    const saveData={
      slug:slug,
      companyId:company.details? company.details.id : null
    }
    console.log("test count", count)
    const spinner=apply_company.status;
    const loader =  <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />
    useEffect(()=>{
      dispatch(handleGetCompanyDetails(slug))
      dispatch(handleGetOpportunitiesByCompany({slug:slug , page:"1"}))
     if(isAuthenticated) {
         dispatch(handleGetProfilesByCompany(slug))
      dispatch(handleGetEditCompanyDetails(slug));  
     }
    },[dispatch]);

    const handleCloseNotif=()=>{
      setOpen(false)
      dispatch(resumeCheckCompanyResetMessages())
      }



    const handlePageClick=(newPage) =>{
      setCurrent_page(newPage.selected + 1)
     // const datapage = `organizations/${slug}/opportunities/list/?page=${newPage.selected + 1}`
      dispatch(handleGetOpportunitiesByCompany({slug:slug , page:`${newPage.selected + 1}`}));
    } 

        let pageCount;
        let onPageChange;


    pageCount = Math.ceil(count / RESULTS_PER_PAGE);
    onPageChange = handlePageClick;



     const handleCloseOpp=()=>{
        setopenOpportunity(false)
        dispatch(addOpportunityResetMessages())
      }
    
  const test = resume_check_company.data.message? openSpinner : setopenSpinner;
    const handleChangeFile=(event)=>{
      let formData = new FormData(); 
      const fileD = event.target.files[0];
      formData.append("datafile",event.target.files[0]);
      dispatch(handleResumeCheckCompany({companyId:company.details?company.details.id:'',fileData:formData}))
      setOpen(true)
    }
  
    const SubmitCoverPhoto=(value)=>{
      // event.preventDefault();
       const data = new FormData();
       data.append('cover_photo', value.cover_photo)
       dispatch(handleEditCoverPhotoCompany({data, slug:company.details?company.details.slug:''}))
        }

    const SubmitPhoto=(value)=>{
      // event.preventDefault();
       const data = new FormData();
       data.append('logo', value.photo)
       dispatch(handleEditPhotoCompany({data, slug:company.details?company.details.slug:''}))
        }

    return (
        <>
        <Container maxWidth={false} className={classes.cont}>
        
          {/* <Typography 
                component="h1"
                variant="h5"
                color="000"
                align="center"
                noWrap
                className="detail-opportunites">
                    {opportunity.details?opportunity.details.title:null}
          </Typography> */}
          </Container>
        <div className={`animated slideInUpTiny detail-opportunity animation-duration-3 container ${classes.content}`}>
        <div className="row">
        <div className="col-md-8  animation slideInLeft ">
          {/* <div className="col-md-4 col-sm-5 col-12 animation slideInLeft sidebar-opportunities">
            <div className="sidebar">
              {
                opportunities.map(item=>{
                  return(
                    <HoriOpportunity data={item} isActive={slug===item.slug}/>
                  )
                })
              }
            </div>
          </div> */}
          {/* =========================DISPLAY OPPORTUNITY======================================== */}
          <div className={`company-detail col-md-12 col-sm-12 col-12 animation slideInRight ${company.spinner?classes.isloadingOpp:''}`}>
            <div className="row">
            <Notif open={open} title={<IntlMessages id="notification"/>} text={
              resume_check_company.error?
              resume_check_company.error :
              resume_check_company.data.message?
              <div>{resume_check_company.data.message}
              {resume_check_company.data.results?

                resume_check_company.data.results.map(
                (item)=>{
                  return <div><a href={`/opportunity/${item.opportunity_id.slug}`} className="title-job-resume">{item.opportunity_id.title}: {item.matching_percentage}</a></div>
                  })
                  :null
              }
              </div>
              :null}
              notification="notification"
              error={resume_check_company.error? "error" : null}
              message={resume_check_company.data.message ? "message" : null}
              handleClose={handleCloseNotif}/>
            <UploadCV open={opencv} handleClose={()=>setOpencv(false)}/>
                <div className="animation slideInLeft cover-photo-company col-md-12">
                  {company.details?
                  <span className="cover-photo">
                    <img src={company.details.cover_photo?company.details.cover_photo:"https://cdn.wutiko.com/v3/static/images/default/low/wutiko_default_cover_photo.png"} alt="Cover photo"/>
                    {company.details?
                    company.details.is_admin?
                    <EditCoverPhoto 
                        SubmitCoverPhoto={SubmitCoverPhoto} 
                        spinner={EditCoverPhotoCompany.spinner}
                        message={EditCoverPhotoCompany.message}
                        error={EditCoverPhotoCompany.error}
                        />:null:null}
                      </span>:null}
                      </div>
                      
                <div className="col-md-2 col-sm-2 col-3 animation slideInLeft">
                      {company.details?
                      <span className="company-img">
                        <img
                          src={company.details.logo?company.details.logo:"https://cdn.wutiko.com/v3/static/images/default/medium/wutiko.png"}
                          alt={<IntlMessages id="cover_photo"/>}
                        />
                        {company.details.is_admin?
                         <EditPhotoCompany 
                        SubmitPhoto={SubmitPhoto} 
                        spinner={EditCompany.spinner}
                        message={EditCompany.message}
                        error={EditCompany.error}
                        />:null}
                      </span>:null}
                    </div>
                    <div className="col-md-10 col-sm-10 col-9 animation slideInRight">
                      <div className="row"> 
                          <div className="col-md-7 col-sm-8 col-12 animation slideInLeft">
                               <h4 className="title-company"> {company.details?company.details.name:null}</h4>
                               <div className="location">{company.details?company.details.location:null}</div>
                          </div>

                          <div className="col-md-5 col-sm-4 col-12 animation slideInRight text-right">
                          {company.details?
                          company.details.is_admin?
                          <EditCompanyPage slug={company.details.slug}  
                           name={company.details.name} itemDetailsCompany={company? company.details:{}}/>
                           : null:null}
                           </div>
                          </div>
                          </div>
                          </div>

                        
                      <div className="row"> 
                           {/* <div className="col-md-8 top-15 industries col-sm-8 col-12 animation slideInLeft">
                           {company.details?company.details.industries.map(
                             item=>item.industry_id?item.industry_id.name:' '
                           ).join(" , "):null}
                          </div>  */}
                          <div className="col-md-4 col-sm-4 col-12 animation slideInRight">
                             {/*<div className="row">
                               <div className="col-md-6 col-sm-6 col-6 animation slideInLeft">
                                 Full time
                               </div>
                               <div className={`col-md-6 col-sm-6 col-6 animation slideInRight ${classes.textAlignEnd}`}>
                               {opportunity.details?opportunity.details.location:null}
                               </div> 
                            </div>*/}
                          </div>
                      </div> 
                   


             
               <div className="col-12 animation slideInLeft">
                {save_company.message?<Alert severity="success" onClose={()=>dispatch(SaveCompanySuccess())}>{save_company.message ?save_company.message : null}</Alert>:null}
                {save_company.error?<Alert severity="error" onClose={()=>dispatch(saveCompanyResetMessages())}>{save_company.error}</Alert>:null}
                {apply_company.message?<Alert severity="success" onClose={()=>dispatch(ApplyCompanyResetMessages())}>{apply_company.message}</Alert>:null}
                {apply_company.error?<Alert severity="error" onClose={()=>dispatch(ApplyCompanyResetMessages())}>{apply_company.error}</Alert>:null}
                    </div>

                         
                      <div className="row animation top-20 slideInRight">
                         <div className="col-md-6 col-sm-6 col-12">
                           <div className="row">
                           {
                            isAuthenticated ?
                              company.details ?
                              <>
                               {/* {company.details.is_following_this_organization === true?
                               <p className="text-grey col-md-6 col-sm-6 col-6" onClick={()=>dispatch(HandleSaveCompany(saveData))}>
                                <IconButton variant="contained" color="primary"><FavoriteBorderIcon/></IconButton>{<IntlMessages id="following"/>} 
                              </p>:
                               <p className="text-grey  col-md-6 col-sm-6  col-6" onClick={()=>dispatch(HandleSaveCompany(saveData))}>
                               <IconButton><FavoriteBorderIcon/></IconButton>{<IntlMessages id="follow"/>} 
                               </p>}
                               
                                */}
                               </>
                             : null
                            :null}

                            {/* <p className="text-grey  col-md-4 col-4">
                                
                                <ShareCompany open={openCompany} handleClose={()=>setopenCompany(false)} companyId={company.details? company.details.id : null}/> 
 
                             </p> */}
                               </div>
                               </div>
                         {
                          isAuthenticated ?
                        <div className="col-md-6 col-sm-6 col-12">
                          {/* {company.details?
                          <div>
                          {company.details.has_spontaneously_applied_to_this_organization === true ?
                            
                            <Button fullWidth variant="contained" color="secondary" disabled >{<IntlMessages id="spontaneous_application"/>} </Button>
                          :
                            <div> 
                            <Button fullWidth variant="contained" color="primary" onClick={()=>dispatch(HandleApplyCompany(saveData))}>{<IntlMessages id="spontaneous_application"/>}</Button>
                            {spinner === <IntlMessages id="creating"/> ?
                          loader : null}
                          </div>
                          }</div> :null} */}
                            </div>
                          
                           :
                           <div className="col-md-6 col-sm-6 col-12 animation slideInRight">
                           {/* <AlertDialog title={<IntlMessages id="spontaneous_application"/>} text={"Vous n êtes pas autorisé à faire cette action"}
                         login={<IntlMessages id="login"/>} signup={<IntlMessages id="singup"/>}/> */}
                           <Button className="ol-md-8 col-sm-8 applyNotconnect col-12 animation slideInRight text-right" onClick={()=>setOpencv(true)}>{<IntlMessages id="spontaneous_application"/>}</Button>
                           </div> }
                           </div>
         
            <div className="row comp-detail">
          
                 <div className="col-md-12 col-sm-12 col-12">
                  <CompanyDetailsTab tab2={<IntlMessages id="about"/>}
                    tab1={<IntlMessages id="opportunities"/>} 
                    tab3={<IntlMessages id="followers"/>}
                  //  tab4={`Location`}  
                   handlePageClick={handlePageClick}
                   pageCount={pageCount}
                   setopenOpportunity={setopenOpportunity}
                   openOpportunity={openOpportunity}
                   handleCloseOpp={handleCloseOpp}
                   is_admin={company.details?company.details.is_admin:null}
                    company={company.details}  
                    opportunitiesList={opportunitesbycompany ? opportunitesbycompany : []} spinnerOpp={spinnerOpp} 
                     profilesList={profilesbycompany ? profilesbycompany:[]} spinnerProfile={spinnerProfile ? spinnerProfile : null}
                    isAuthenticated={isAuthenticated}/>
                </div>                   
            </div>
            <div className="row  col-md-12 left-15">
                 
                  {
                    isAuthenticated ?
                    <div className="col-md-7 col-sm-7 col-12">
                      <div className="row">
                   <div className="col-md-8 col-sm-8 col-12">
                  <p className="text-grey mb-3">
                    Repport this opportunity <IconButton><LaunchIcon/></IconButton>
                  </p> 
                  </div>
                  <div className="col-md-4 col-sm-4 padding-0 col-6 animation slideInLeft">
                    
                    {/* <p className="text-grey mb-3 ">
                    {
                    company.details ?
                              <div>
                               {company.details.is_following_this_organization === true ?
                               <p className="text-grey mb-3" onClick={()=>dispatch(HandleSaveCompany(saveData))}>
                                <IconButton variant="contained" color="primary"><FavoriteBorderIcon/></IconButton>{<IntlMessages id="following"/>}
                              </p>:
                               <p className="text-grey mb-3" onClick={()=>dispatch(HandleSaveCompany(saveData))}>
                               <IconButton><FavoriteBorderIcon/></IconButton>{<IntlMessages id="follow"/>}
                               </p>}</div>
                             : null
                            }
                  </p> */}
                    </div></div></div>:
                     <div className="col-md-6 col-sm-6 col-12">
                     <p className="text-grey mb-3">
                       Repport this opportunity <IconButton><LaunchIcon/></IconButton>
                     </p> 
                     </div>}
                    {/* {
                    isAuthenticated ?
                    <div className="col-md-2 col-sm-2  padding-0 col-6 animation slideInRight">
                    <p className="text-grey mb-1">
                    <ShareCompany open={openCompany} handleClose={()=>setopenCompany(false)} opportunityId={company.details? company.details.id : null}/> 
                    </p> 
                    </div>
                    :null} */}
                    {
                    isAuthenticated ?
                  <div className="col-md-5 col-sm-5 col-12">
                  {/* {company.details?
                          <div>
                          {company.details.has_spontaneously_applied_to_this_organization !== true?
                          <div> 
                          <Button fullWidth variant="contained" color="primary" onClick={()=>dispatch(HandleApplyCompany(saveData))}>{<IntlMessages id="spontaneous_application"/>}</Button>
                          {spinner === "creating" ?
                          loader : null}
                          </div>
                            : 
                          <Button fullWidth variant="contained" color="secondary" disabled  onClick={()=>dispatch(HandleApplyCompany(saveData))}>{<IntlMessages id="spontaneous_application"/>}</Button>
                          }</div> :null} */}
                  </div>
                  :
                  <div className="col-md-6 col-sm-6 col-12 animation slideInRight">
                  <AlertDialog title={<IntlMessages id="spontaneous_application"/>} text={"Vous n êtes pas autorisé à faire cette action"}
                login={<IntlMessages id="login"/>} signup={<IntlMessages id="singup"/>}/>
                  </div> }
            </div>
            <div className="row  col-md-12 left-15 resume">
               <div className={`col-xl-12 col-sm-12 bloc- ${spinnerResumecheck.spinner?classes.isloadingOpp:null}`}>
               <div className="col-md-12">
               {resume_check_company.data.message?<Alert severity="success" onClose={()=>dispatch(resumeCheckCompanyResetMessages())}>{resume_check_company.data.message}</Alert>:null}
                {resume_check_company.error?<Alert severity="error" onClose={()=>dispatch(resumeCheckCompanyResetMessages())}>{resume_check_company.error}</Alert>:null} 
                </div>
                <HorizontalItemCardCustom
                  styleTitle="mb-4"
                  styleName={classes.upload}
                  styleName="p-4"
                  title="Resume check"
                  detail={<IntlMessages id="resume_check_body"/>}
                  chartPosition="align-self-end"
                >
                <form enctype="multipart/form-data">
                <label htmlFor="icon-button-file">
                {resume_check_company.spinner?loader:
                //       <IconButton  aria-label="upload picture">
                //   <input accept=".png, .jpg, .jpeg, .pdf, .doc" name="datafile" className={classes.input} id="icon-button-file" type="file" onChange={handleChangeFile}/>
                
                // <GetAppIcon className={classes.icon}/></IconButton>
                <BrowserLimitations  handleChangeFile={handleChangeFile} />  
                    }
                  </label>
                </form>
                {fileState.current_file} 
                </HorizontalItemCardCustom>
              </div> 

              {/* <div className="col-xl-6 col-sm-12 bloc-opportunities">
                <HorizontalItemCardCustomShare
                  styleTitle="mb-4"
                  styleName="p-4"
                  title={'Share this'}
                  detail={''}
                >
                <div className={`jr-btn-group share ${classes.textAlignCenter}`}>
                <Button className={`jr-fab-btn bg-indigo lighten-1 text-white ${classes.margin}`}>
                    <i className="zmdi zmdi-facebook zmdi-hc-lg" />
                  </Button>

                  <Button className={`jr-fab-btn bg-light-blue accent-2 text-white ${classes.margin}`}>
                    <i className="zmdi zmdi-twitter zmdi-hc-lg" />
                  </Button>

                  <Button className={`jr-fab-btn bg-red lighten-1 text-white ${classes.margin}`}>
                    <i className="zmdi zmdi-google zmdi-hc-lg" />
                  </Button>
                  <Button className={`jr-fab-btn bg-light-blue darken-3 text-white ${classes.margin}`}>
                    <i className="zmdi zmdi-linkedin zmdi-hc-lg" />
                  </Button>
                </div>
                </HorizontalItemCardCustomShare>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-5 col-12 animation slideInRight">
        <LatestCompanies/>
          <SidebarRight/>
          </div>
          </div>
      </div>
      </>
    );
  };
  
  export default CompanyDetails;