import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { deleteFileSuccess, deleteFileError } from  "actions/profile";

const DeleteFileRequest = async (instance,data)=>
    await instance.put(`/documents/${data.documentId}/profile/remove/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* DeleteFile(actions){
    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(DeleteFileRequest,axiosInstance, actions.data);
    if(data){
        yield put(deleteFileSuccess(data.message));
        yield put({type:"HANDLE_GET_PROFILE_FILE"});
    }else{
        yield put(deleteFileError(data.error));
    }
    
}
export function* handleDeleteFile(){
    console.log("DELETE File" , "DELETE File")
    yield takeEvery("HANDLE_DELETE_FILE", DeleteFile)
}
export default function* rootSaga() {
    yield all([fork(handleDeleteFile)]);
}
