export function handleGetForm(formId){
    return{
        type:"HANDLE_GET_FORM",
        formId:formId
    }
}
export function getFormSuccess(data){
    return{
        type:"GET_FORM",
        payload:data
    }
}

export function getFormError(data){
    return{
        type:"GET_FORM_FAILLURE",
        payload:data
    }
}