import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { setgetPartners } from "actions/partners";

const getPartnersData = async (instance)=>
    await instance.get(`/ads/partners-logos/list/`)
    .then(response=>response.data)
    .catch(error=>error)

function* getPartnersList(){
    const axiosInstance = (state)=>state.users.blankAxiosInstance
    const instance =yield select(axiosInstance);
    const data =yield call(getPartnersData,instance);
    console.log("/ads/partners-logos/list/",data)
    yield put(setgetPartners(data));
}
export function* getPartners(){
    yield takeEvery("HANDLE_GET_PARTNERS", getPartnersList)
}
export default function* rootSaga() {
    yield all([fork(getPartners)]);
}