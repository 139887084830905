import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Link} from "react-router-dom";
import {Animated} from "react-animated-css";
import Button from '@material-ui/core/Button';
import IntlMessages from "util/IntlMessages";
import { HandleSaveCompany } from 'actions/companies/getCompanies';
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import UploadCV from 'components/uploadCV/UploadCV';
import { store } from 'react-notifications-component';
import Notif from "components/uielements/Notification"
import { saveCompanyResetMessages} from 'actions/companies/getCompanies';
//import notifier from "simple-react-notifications";
const useStyles = makeStyles((theme) => ({
  ul:{
    'list-style-type': 'none',
    paddingLeft: 0,

  },
  btn:{
    position:'absolute',
    left:'42%',
    backgroundColor:"#fdc000",
    '&:hover':{
      backgroundColor:"#fdc000",
    }
  },
 cardActions:{
      paddingLeft:15,
      paddingRight:15
  },
  avatar:{
    width: 45,
    height: 45,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius:'0%',
    marginLeft:'0px',
  }, 
  flexEnd:{
      paddingLeft:'28%',
  },
  icon:{
      width:15,
      height:15
  },
  icons:{
    width:15,
    height:15,
    border:"solid 1px #c1c1c1"
  },
  link:{
    textDecoration:'none',
    color:'#4285f4',
    '&:hover':{
      textDecoration:'none'
    }
  
}
}));
export default function LatestCompaniesDetail(props) {
  const classes = useStyles()
  const data =props.data
  const isAuthenticated = props.isAuthenticated
  const dispatch = useDispatch()
  const [opencv, setOpencv]=React.useState(false)
  const [open, setOpen]=React.useState(false)
  const {slug} =useParams();
  const error =useSelector(state=>state.company.saveCompany.error)
  const message =useSelector(state=>state.company.saveCompany.message)
  
  console.log('Test latest' , data)

  const saveData={
    slug:slug,
    companyId:data? data.id : null
  }
  const SaveCompany=()=>{
  dispatch(HandleSaveCompany(saveData));
  setOpen(true)
  }

  const handleCloseSc=()=>{
  setOpen(false)
  dispatch(saveCompanyResetMessages())
  }

  return (
 <> 

  <Notif open={open} title={<IntlMessages id="notification"/>} text={
    error?
    error :
    message?message:null}
    error={error? error : null}
    message={message ? "message" : null}
    handleClose={handleCloseSc}
    notification="notification"/>
                
  <div className="container">
    <UploadCV open={opencv} handleClose={()=>setOpencv(false)}/>
    </div>
    <Animated animationIn="fadeInUp" animationOut="fadeOut">
       <Card className=" company">
            <CardActions className={classes.cardActions}>
            <Grid container className="item-sidebar">
        
            <Grid item xs={2} className="logo-item-sidebar">
                <ListItemAvatar> 
                    <Avatar alt="Travis Howard" src={data.logo}  className={classes.avatar}/>
                </ListItemAvatar>
            </Grid>
            <Grid item xs={7}>
                     <a href={`/companies/${data.slug}`} className="title-opportunity text-left">
                       {/* {data.name}  */}
                       {data.name.length >= 25 ? data.name.substring(0, 25)+"..." : data.name}
                       </a>
                     <div className="col-md-12 padding-0 block-profil">
                    {/* { data.title?
                   <span className="title">
                   {data.title.length >= 25 ? data.title.substring(0, 2)+"..." : data.title}</span>
                   :null} */}
                </div>
                {/* <span className="current_position">{data.current_position ? data.current_position : "Wutiko" }</span><br/>
                <span className="profile-location">{data.country_name} {data.city_name ?  " - "+data.city_name  : null}</span> */}
                
            </Grid>    
                <Grid xs={2} md={2}  className="footer-bloc-profile">
                <div className="text-right  padding-0">
                

                {
                        isAuthenticated ?
                        data ?
                              <>
                               {data.is_following_this_organization === "true"?
                               <Button className="btn-profile text-right" 
                               onClick={SaveCompany}
                                //onClick={() => {
        //notifier.success("Your items have been updated");
        // notifier.error("Something went wrong, try again.");
      //}
      //}
>{<IntlMessages id="following"/>}</Button>
                              :
                               
                                <Button className="btn-profile text-right" 
                                //onClick={()=>dispatch(HandleSaveCompany(saveData))}
                                onClick={SaveCompany}
                                >
                                  {<IntlMessages id="follow"/>}</Button>
                               }</>
                             : null
                            :
                            <a href={`/companies/${data.slug}`}><Button className="btn-profile text-right">{<IntlMessages id="view"/>}</Button></a>}
                </div>
                </Grid>
                {/* <Grid item xs={12} className="btn-profile text-right"><Button>Voir Profil</Button></Grid> */}
                {/*                 
                <Grid className="date-publication" item xs={12} container justify="flex-start"><AccessTimeIcon  className={classes.icon}/>{data.is_connected ? null : "Connecter" }</Grid>
                 */}
                </Grid>
            </CardActions>
        </Card>
        </Animated></>
  );
}

LatestCompaniesDetail.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
