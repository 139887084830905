import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
//import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { STORE_URL } from 'config';
import { REGISTER_URL } from 'config';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntlMessages from "util/IntlMessages";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);
  
 
  
  
  
export default function AlertUser(props) {
  const [open, setOpen] = React.useState(false);


  const gotoRegister= ()=>{
    window.location.href=REGISTER_URL;
  }

  const become_recruiter = ()=>{
    window.location.href=`${STORE_URL}/plans/pricing/`;
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (

    <div className="">
      <Dialog onClose={props.handleClose}  open={props.open}>
      <DialogContentText id="alert-dialog-description" style={{backgroundColor:'#fdc000'}}>
            <div className="row col-md-12 alertUser">
            <div className="col-md-10 ">
               <p><IntlMessages id="alert" /></p> 
              </div>
              <div className="col-md-2">  <DialogTitle  onClose={props.handleClose} >
            </DialogTitle></div></div>
             
            </DialogContentText>
          <DialogContent >
            <p><IntlMessages id="alert_view_user"/></p> 
          </DialogContent>
          <DialogActions className="row col-md-12 user_alert">
          <p className="col-md-2"></p>
          <Button onClick={become_recruiter}  className="become-recruite col-md-4">
           {<IntlMessages id="become_recruiter" />}
          </Button>
          <Button onClick={props.followProfile}  className="connect-alert col-md-4">
          {<IntlMessages id="connect" />}
           </Button>
           <p className="col-md-2"></p>
         </DialogActions>
        </Dialog>
        </div>
  );
}