import { connect } from 'react-redux';

import { getItem,postItem,Empty,putItem, showItemDetails} from '../../actions/index'
import { setExperienceToProfile, handleAddExperience } from '../../actions/profile';

import IntlMessages from "util/IntlMessages";
import ExperienceForm from 'pages/ExperienceForm';


function mapStateToProps(state, router) {
  return {
    companies: state.search.companies,
    positions: state.search.positions,
    //sectors: state.search.sectors,
    // serverError: state.profile.experience.error,
    // serverStatus: state.profile.experience.status,
    // userId: state.user.userId,
     //axiosInstance: state.users.axiosInstance,
    error: state.profile.add_experience.error,
    message: state.profile.add_experience.message,
    spinner: state.profile.add_experience.spinner,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleAddExperience: (data) => dispatch(handleAddExperience(data)),
    setExperienceToProfile: (company, position, data, itemId) => dispatch(setExperienceToProfile(company, position, data, itemId)),
    getItem: (url, type) => dispatch(getItem(url, type)),
    postItem: (url, type,data) => dispatch(postItem(url, type,data)),
    putItem: (url, type,data) => dispatch(putItem(url, type,data)),
    showItemDetails: (url) => dispatch(showItemDetails(url)),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExperienceForm);
