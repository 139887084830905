import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import {Link} from "react-router-dom";
import CardActions from '@material-ui/core/CardActions';
import Divider from './Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import  {useDispatch,useSelector} from 'react-redux';
import Widget from 'components/Widget';
import oia from '../../assets/imgs/oia.png';
import IntlMessages from "util/IntlMessages";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#fff",
    // marginTop:15,
    padding:15,
    paddingLeft:24,
    paddingRight:24,
    position: 'relative',
    width:'100%',
  },
cardActions:{
    paddingLeft:15,
    paddingRight:15,
},
avatar:{
  width: 100,
  height: 100,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius:'0%',
  marginLeft:'-15px',
  marginRight:12,
  backgroundColor:'white'
}, 
cards:{
  backgroundColor:"#fff",
  [theme.breakpoints.down('md')]:{
    height:"180px",
}
},
card2:{
  backgroundColor:"#f1f1f1",
  [theme.breakpoints.down('md')]:{
    height:"180px",
}
},
card1:{
  backgroundColor:"#fff",
  [theme.breakpoints.down('md')]:{
    height:"180px",
}
},
btn:{
  color:'#2196f3'
}
}));

export default function LastUpdates(props) {
  const classes = useStyles();
  const dispatch =useDispatch();
  const last_updates =useSelector(state=>state.lastUpdates.last_updates)
  const card_1=last_updates.list[0];
  const card_2=last_updates.list[1];
  console.log("last_updates" ,last_updates)
  const card_3=last_updates.list[2];
  useEffect(()=>{
      dispatch({type:"HANDLE_GET_LAST_UPDATES", payload:{}})
  },[dispatch])
  return (
    <footer  className="last-update home">
      <div className="container">
       <h2 className="title-homepage last-update">
        <div>Latest updates</div>
       <div className="bottom-title last-update"></div></h2>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12} className="card_2">
       
                      <h1>{card_2?card_2.title:null}</h1>
                      <p>   <img src={card_2?card_2.image:null}  />
                     {card_2?card_2.content:null} </p>
                     <a href="https://www.facebook.com/Consortium-Jeunesse-Senegal-104797334524092" target="_blank">
                     <Button className={classes.btn}>{<IntlMessages id="read_more"/>}</Button>
                     </a>

        </Grid>

        <Grid item md={4} xs={12} className="card_1">
       
                  
                    <h1>{card_1?card_1.title:null}</h1>
                    <p> 
                     <img src={card_1?card_1.image:null}  />
                     {card_1?card_1.content:null} </p>
                
                     <Link to="/opportunities">
                       <Button className={classes.btn}>{<IntlMessages id="read_more"/>}</Button>
                       </Link>
              
        </Grid>
        <Grid item md={4} xs={12} className="card_3">
            <Widget styleName="jr-widget-bg jr-blue-cyan-gradient">
              <span className="jr-widget-badge"></span>
              <a href="https://opportunitiesinafrica.org/" target="_blank"><img
                className="rounded-xl w-100"
                alt="..."
                src={card_3?card_3.image:"https://cdn.wutiko.com/v3/preprod/medias/activities/latest-updates/images/b137acd7-7b4e-48d4-b5ba-e44c3e892c24.png"}
              /></a> 
            </Widget>
        </Grid>
      </Grid>
      </div>
    </footer>
  );
}

LastUpdates.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
