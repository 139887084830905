import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import SortIcon from "@material-ui/icons/ArrowDownward";
import {  handleGetFollowersDashboard, handleGetOpportunitiesOnlineDashboard } from 'actions/dashboard/keysList';
import React,{useState,useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Button } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CardBox from "components/CardBox/index";
import CardMenu from 'components/dashboard/Common/CardMenu';
import {Area, AreaChart, Line, LineChart, ResponsiveContainer} from "recharts";
import ChartCard from "./ChartCard";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import ItemFollowers from './ItemFollowers'
import {arrayMove, SortableContainer} from 'react-sortable-hoc';
import ItemOpportunities from "./ItemOpportunities";
import ReactPaginate from "react-paginate";
import Grid from '@material-ui/core/Grid';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useDispatch, useSelector} from 'react-redux'
const OpportunitiesOnlineDashboard = (props , t ) => {
//const {OpportunitiesDashboard} = props;
const RESULTS_PER_PAGE = 12;
const dispatch = useDispatch();
const OpportunitiesDashboard = useSelector(state=>state.keys.opportunitiesOnlineDashboard.list);
const count = useSelector(state=>state.keys.opportunitiesOnlineDashboard.count);
const spinnerOpportunitiesDashboard = useSelector(state=>state.keys.opportunitiesOnlineDashboard.spinner);
const [currentPage, setcurrentPage] = React.useState(1);

const Contacts = SortableContainer(({contacts}) => {
  return (
    <div className="">
      
      {spinnerOpportunitiesDashboard?
          <Grid container item justify="center" className="top-20" xs={12}><CircularProgress size={50}/></Grid>:
          OpportunitiesDashboard.map((contact, index) => (
        <ItemOpportunities key={index} index={index} contact={contact}
        all={props.all?props.all:null}
        search={props.search?props.search:null} 
        handlePageClick={handlePageClick} OpportunitiesDashboard={OpportunitiesDashboard}/>
      ))}
    </div>
  );
});

useEffect(()=>{
    dispatch(handleGetOpportunitiesOnlineDashboard({search: window.location.search, page:`${currentPage}`}))
  },[dispatch])

console.log("OpportunitiesDashboard " , OpportunitiesDashboard)

const handleChange = (state) => {
    console.log('Selected Rows: ', state.selectedRows);
  };


  const [contacts, setContacts] = useState(OpportunitiesDashboard);

  console.log("contact 2 " , contacts)
  const onSortEnd = ({oldIndex, newIndex}) => {
    setContacts(arrayMove(contacts, oldIndex, newIndex))
  };


  const handlePageClick=(newPage) =>{
    setcurrentPage(newPage.selected + 1)
    dispatch(handleGetOpportunitiesOnlineDashboard({search: window.location.search, page:`${newPage.selected + 1}`}));
  } 
      let pageCount;
      let onPageChange;
  
  
      pageCount = Math.ceil(count / RESULTS_PER_PAGE);
      onPageChange = handlePageClick;
  
  return (
    
        <div className="animated slideInUpTiny animation-duration-3 dashboard">
          <div className="table-opp roww">
            <div className="col-md-6 text-left"><IntlMessages id="title"/> </div>
            {/* <div className="col-md-2 text-left"><IntlMessages id="categories" /></div> */}
            <div className="col-md-2 text-center"><IntlMessages id="views" /></div>
            <div className="col-md-2 text-center"><IntlMessages id="applicants" /></div>
            <div className="col-md-2 text-left"><IntlMessages id="actions" /></div>
          </div>
        <Contacts contacts={contacts} onSortEnd={onSortEnd} useDragHandle={true}/>
        <Grid item md={12} xs={12} container justify="center" className="Listpagination">
        <ReactPaginate
        previousLabel={<IntlMessages id="previous" />}
        nextLabel={<IntlMessages id="next" />}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
        </Grid>
    </div>
  );
};
export default  OpportunitiesOnlineDashboard;