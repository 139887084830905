
import {useDispatch, useSelector} from 'react-redux'
import React,{useState,useEffect} from 'react';
import { Link } from "react-router-dom";
import ItemMatching from "./ItemMatching"
import {arrayMove, SortableContainer} from 'react-sortable-hoc';
import ReactPaginate from "react-paginate";
import Grid from '@material-ui/core/Grid';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
const MatchingDashboard = (props , t ) => {
const [open, setOpen] = React.useState(false);
const dispatch = useDispatch();
const {KeysListMatching, MatchingDashboard} = props;

const handleClickOpen = () => {
  setOpen(true);
};
const handleClose = () => {
  setOpen(false);
};

console.log("MatchingDashboard " , MatchingDashboard)

const handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    console.log('Selected Rows: ', state.selectedRows);
  };
  const Items = SortableContainer(({items}) => {
    return (
      <div className="row col-md-12">
         {props.spinner?
          <Grid container item justify="center" xs={12}><CircularProgress size={80}/></Grid>:
           props.MatchingDashboard.map((item, index) => (
          <ItemMatching key={index} index={index} opportunity={item.opportunity}
          organization={item.organization} 
          matching_score={item.matching_score} profile={item.profile}
          />
        ))}
      </div>
    );
  });

  const [items, setItems] = useState(MatchingDashboard);

  const onSortEnd = ({oldIndex, newIndex}) => {
    setItems(arrayMove(items, oldIndex, newIndex))
  };

   
  return (
    <div className="container-wrap container animated slideInUpTiny animation-duration-3 dashboard">
       <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
       <h2 className="title mb-3 mb-sm-0"><IntlMessages id="matching"/></h2>
        <nav className="mb-0" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <Link to="/dashboard" className="breadcrumb-item">Dashboard</Link>
            <span href="#" className="active breadcrumb-item" aria-current="page">{props.title}</span>
            </ol></nav></div>
        <div className="animated slideInUpTiny animation-duration-3">  
        <Items items={items} onSortEnd={onSortEnd} useDragHandle={true}/>
        <Grid item md={12} xs={12} container justify="center" className="Listpagination">
        <ReactPaginate
        previousLabel={<IntlMessages id="previous" />}
        nextLabel={<IntlMessages id="next" />}
        pageCount={props.pageCount}
        onPageChange={props.handlePageClick}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
        />
        </Grid>
    </div>
    </div>
  );
}
export default  MatchingDashboard;