import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { EducationLevelSuccess,EducationLevelError} from "actions/profile";


    const GetEducationLevelData = async (instance)=>
    await instance.get(`/profiles/educationlevels/list/`)
    .then(response=>response)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response)

            return error.response
        }
    })

function* EducationLevel(actions){
    const state = yield select((state)=>state.users);

    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetEducationLevelData, axiosInstance, actions);

    console.log("Test Education Level", data.data.results)
    if(data.data){
        yield put(EducationLevelSuccess(data.data.results));
    }else{
        console.log("data.error", data.error)
        yield put(EducationLevelError(data.error));
    }
}
export function* handleEducationLevel(){
    yield takeEvery("HANDLE_EDUCATION_LEVEL", EducationLevel)

}
export default function* rootSaga() {
    yield all([fork(handleEducationLevel)]);
}

