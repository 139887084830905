import React from "react";
import Avatar from '@material-ui/core/Avatar';
import { Link } from "react-router-dom";
import ActivityItem from "./ActivityItem";


function getName(task, shape) {
  if (task.avatar === '') {
    let nameSplit = task.name.split(" ");
    if (task.name.split(" ").length === 1) {
      const initials = nameSplit[0].charAt(0).toUpperCase();
      return <Avatar
        className={shape === 'circle' ? 'size-40 bg-primary mr-3 rounded' : 'size-40 bg-primary mr-3'}>{initials}</Avatar>
    } else {
      const initials = nameSplit[0].charAt(0).toUpperCase() + nameSplit[1].charAt(0).toUpperCase();
      return <Avatar
        className={shape === 'circle' ? 'size-40 bg-cyan mr-3 rounded' : 'size-40 bg-cyan mr-3'}>{initials}</Avatar>
    }
  } else {
    return <Avatar className={shape === 'circle' ? 'size-40 mr-3 rounded' : 'size-40 mr-3'} alt="..."
                   src={task.avatar}/>;
  }
}
const onLoadMore =()=> {
  console.log("loadMore")
};

const RecentActivity =(props)=> {

    const {recentList, shape , is_user_recruiter} = props;
    const limit = window.innerWidth < 575 ? 1 : 3;
    return (
      <div className="jr-entry-sec mb-0">
        <h2 className="jr-entry-title">Recent Activities</h2>
        {recentList.opportunity_applications?
          <div className="jr-timeline-info">
            <h4 className="jr-timeline-info-day">Apllications</h4>
            <div className="recent-activity">
              {recentList.opportunity_applications.map((task, index) => {
                return <div className="media user-profile" key={"taskId-" + task.id}>
                  {is_user_recruiter? 
                  <div className="media-body align-self-center row ">
                  <Avatar className={shape === 'circle' ? 'size-40 mr-3 rounded col-4' : 'size-40 mr-3'} alt="..."
                   src={task.profile_id.logo}/>
                    <p className="mb-0 col-md-8 col-8 activity"><a href={`/profiles/${task.profile_id.slug}`}>{task.profile_id.first_name} </a> 
                      has applied to <a href={`/opportunity/${task.opportunity_id.slug}`}>{task.opportunity_id.title}</a></p>
                   </div>
                   :

                  <div className="media-body align-self-center row ">
                  <Avatar className={shape === 'circle' ? 'size-40 mr-3 rounded col-4' : 'size-40 mr-3'} alt="..."
                  src={task.profile_id.logo}/>
                    <p className="mb-0 col-md-8 col-8 activity"><Link to="#">{task.profile_id.first_name} </Link> 
                     has applied to <Link to="#">{task.opportunity_id.name}</Link></p>
                  </div>
              }
                </div>
              })}
            </div>
          </div>
          :null}

          {recentList.spontanous_applications?
          <div className="jr-timeline-info">
            <h4 className="jr-timeline-info-day">Spontanous Applications</h4>
            <div className="recent-activity">
              {recentList.spontanous_applications.map((task, index) => {
                return <div className="media user-profile" key={"taskId-" + task.id}>
                  {is_user_recruiter? 
                  <div className="media-body align-self-center row ">
                  <Avatar className={shape === 'circle' ? 'size-40 mr-3 rounded col-4' : 'size-40 mr-3'} alt="..."
                   src={task.profile_id.logo}/>
                    <p className="mb-0 col-md-8 col-8 activity"> <a href={`/profiles/${task.profile_id.slug}`}>{task.profile_id.first_name}  </a>
                      has spontaneously applied to <a href={`/companies/${task.organization_id.slug}`}>{task.organization_id.name}</a> </p>
                   </div>
                   :
                   <div className="media-body align-self-center row ">
                   <Avatar className={shape === 'circle' ? 'size-40 mr-3 rounded col-4' : 'size-40 mr-3'} alt="..."
                    src={task.profile_id.logo}/>
                     <p className="mb-0 col-md-8 col-8 activity"> <Link to="#">{task.profile_id.first_name}  </Link>
                       has spontaneously applied to <Link to="#">{task.organization_id.name}</Link> </p>
                    </div>
              }
                </div>
              })}
            </div>
          </div>
          :null}

          {recentList.followers?
          <div className="jr-timeline-info">
            <h4 className="jr-timeline-info-day">Followers</h4>
            <div className="recent-activity">
              {recentList.followers.map((task, index) => {
                return <div className="media user-profile" key={"taskId-" + task.id}>
                  {is_user_recruiter? 
                  <div className="media-body align-self-center row ">
                  <Avatar className={shape === 'circle' ? 'size-40 mr-3 rounded col-4' : 'size-40 mr-3'} alt="..."
                   src={task.profile_id.logo}/>
                    <p className="mb-0 col-md-8 col-8 activity">
                    <a href={`/profiles/${task.profile_id.slug}`}> {task.profile_id.first_name}  </a>  is now following <a href={`/companies/${task.organization_id.slug}`}>{task.organization_id.name}</a> </p>
                   </div>
                   :
                   <div className="media-body align-self-center row ">
                  <Avatar className={shape === 'circle' ? 'size-40 mr-3 rounded col-4' : 'size-40 mr-3'} alt="..."
                   src={task.profile_id.logo}/>
                    <p className="mb-0 col-md-8 col-8 activity">
                    <Link to="#"> {task.profile_id.first_name}  </Link>  is now following <Link to="#">{task.organization_id.name}</Link> </p>
                   </div>
              }
                </div>
              })}
            </div>
          </div>
          :null}
        {/* <span className="jr-link mt-n2 d-block" onClick={onLoadMore}>Load More</span> */}
      </div>
    );
  };

export default RecentActivity;
