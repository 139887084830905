import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Link} from "react-router-dom";
import {Animated} from "react-animated-css";
import {useDispatch} from 'react-redux'
import Button from '@material-ui/core/Button';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import Menu from '@material-ui/core/Menu';
import IntlMessages from "util/IntlMessages";
import {useSelector} from 'react-redux'
import { HandleSaveCompany } from 'actions/companies/getCompanies';
import Notif from 'components/uielements/Notification';
import { saveCompanyResetMessages} from 'actions/companies/getCompanies';

const useStyles = makeStyles((theme) => ({
  ul:{
    'list-style-type': 'none',
    paddingLeft: 0,

  },
  btn:{
    position:'absolute',
    left:'42%',
    backgroundColor:"#fdc000",
    '&:hover':{
      backgroundColor:"#fdc000",
    }
  },
 cardActions:{
      padding:20
  },
  avatar:{
    width: 50,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius:'0%',
    marginLeft:'-15px',
  }, 
  flexEnd:{
      paddingLeft:'28%',
  },
  icon:{
      width:15,
      height:15
  },
  icons:{
    width:15,
    height:15,
    border:"solid 1px #c1c1c1"
  },
  link:{
    textDecoration:'none',
    color:'#4285f4',
    '&:hover':{
      textDecoration:'none'
    }
  
}
}));
export default function Company(props) {
  const classes = useStyles();
  const data =props.data
  const dispatch = useDispatch();
  const isAuthenticated =useSelector(state=>state.users.authenticated)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const error =useSelector(state=>state.company.saveCompany.error)
  const message =useSelector(state=>state.company.saveCompany.message)
  const [open, setOpen]=React.useState(false)
 
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const saveData={
    slug:data? data.slug : null,
    companyId:data? data.organization_id : null
  }
  const SaveCompany=()=>{
    dispatch(HandleSaveCompany(saveData));
    setOpen(true)
    }
  
    const handleCloseSc=()=>{
    setOpen(false)
    dispatch(saveCompanyResetMessages())
    }
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
 
    <Animated animationIn="fadeInUp" animationOut="fadeOut">
      <Notif open={open} title={<IntlMessages id="notification"/>} text={
      error?
      error :
      message?message:null}
      notification="notification"
      error={error? error : null}
      message={message ? "message" : null}
      handleClose={handleCloseSc}/>
       <Card className={classes.card}>
            <CardActions className={classes.cardActions}>
            <Grid container className="company">
            <Grid item xs={12}>
                     <Link to={`/companies/${data.slug}`} className="title-opportunity text-left">{data.name}</Link>
            </Grid> 

              
                
                {/* <Grid item xs={8}>
                     <Link to={`/companies/${data.slug}`} className="title-companies text-left">{data.name}</Link><br/>
                     {/* <span className="title">{data.name}</span><br/> */}
                     {/* <span className="location">{data.location}</span>
                    
                     <Grid item xs={12}>
                {
                    data.industries.map((item)=>{
                      return <div className="tag"><Link to={`/companies?FacetIndustry=${item}`} className={classes.link}>{item}</Link></div>
                    })
                  } 
                </Grid>
                </Grid> */}
               

               <Grid item xs={12}  className="industries">
                <ListItem alignItems="flex-start">
                <ListItemAvatar> 
                    <Avatar alt="Travis Howard" src={data.organization_logo}  className={classes.avatar}/>
                    </ListItemAvatar>
                    <ListItemText
                    primary={data.organization_name}
                    secondary={
                        <React.Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                        >

                            {data.industries.length === 0 ? null
                            :
                            data.industries.length !== 1 ?
                                <div>
                                <Button aria-controls="" aria-haspopup="true" onClick={handleClick}>
                                {data.industries[0]+ " , +" }
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                            {
                                                data.industries.map((item)=>{
                                                return <a href={`/companies?FacetCategory=${item}`} className="opportunity-tag">{item + " "}</a>
                                                })
                                            }
                                </Menu>  </div>
                                :
                                <a href={`/companies?FacetCategory=${data.industries[0]}`} className="opportunity-tag">{data.industries[0]}</a>  
                            }
                       <div className="nbr-opp">  <BusinessCenterIcon/>  {data.total_opportunities}</div>
                        </Typography>
                       </React.Fragment>
                     }
                    />
                </ListItem>

                </Grid>
                


                <Grid xs={12} md={12} container className="footer-bloc-profile">
                <div className="col-md-6 padding-0 shared-connections">
                {data.total_followers} {<IntlMessages id="shared_connections"/>} 
                </div>
                <div className="col-md-6 text-right  padding-0">
                  {isAuthenticated?
                  data.is_followed === true?
                  <Button className="btn-company text-right" onClick={SaveCompany}>{<IntlMessages id="follow"/>}</Button>
                   :
                   <Link to={`/companies/${data.slug}`}><Button className="btn-company text-right following " >{<IntlMessages id="following"/>}</Button></Link>
                  :
                  <Link to={`/companies/${data.slug}`}><Button className="btn-company text-right">{<IntlMessages id="view"/>}</Button></Link>
                  }
                </div>
                </Grid>
                {/*                 
                <Grid className="date-publication" item xs={12} container justify="flex-start"><AccessTimeIcon  className={classes.icon}/>{data.is_connected ? null : "Connecter" }</Grid>
                 */}
                </Grid>
            </CardActions>
        </Card>
        </Animated>
  );
}

Company.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
