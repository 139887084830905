import React from 'react';
import {countryList1} from "app/routes/dashboard/data";
// import VectorMap, {
//   Layer,
//   Legend,
//   Source,
//   Tooltip
// } from 'devextreme-react/vector-map';

import {VectorMap} from "react-jvectormap";
import CountryListItem from "../Common/CountryListItem";
import index from 'react-typical';


// import React from 'react';

// const CountryListItem = ({country}) => {
//     const {flagCode, name, badge, badgeColor} = country;
//     return (
//         <div className=" d-flex align-items-center">
//             <i className={`flag flag-24 flag-${flagCode}`}/>
//             <div className="px-3">{name} </div>
//             <div className={`ml-auto px-3 badge badge-pill text-right text-white bg-${badgeColor}`}>{badge} </div>
//         </div>

//     )
// };

// export default CountryListItem;


const markersList = [
      
      {
        latLng: [121.5, 31.2],
        name: 'Shanghai',
        value: 24150
      },
      {
        latLng: [-43.18, -22.9],
        name: 'Rio de Janeiro',
        value: 6429
      },
      {
        latLng: [31.23, 30.05],
        name: 'Cairo',
        value: 8922
      },
      {
        latLng: [28.95, 41],
        name: 'Istanbul',
        value: 14160
      },
      {
        latLng: [127, 37.55],
        name: 'Seoul',
        value: 10388
      },
      {
        latLng: [139.68, 35.68],
        name: 'Tokyo',
        value: 9071
      },
      {
        latLng: [103.83, 1.28],
        name: 'Singapore',
        value: 5399
      },
      {
        latLng: [30.3, 59.95],
        name: 'Saint Petersburg',
        value: 5131
      },
      {
        latLng: [28.03, -26.2],
        name: 'Johannesburg',
        value: 4434
      }
    ]
  
  


const SiteVisitor = (props) => {

    const topLocationsdashboard = props.topLocationsDashboard;
    const testTop =[topLocationsdashboard];
    console.log("testTop" , topLocationsdashboard)


    const listLoc = topLocationsdashboard.map((item, index) =>{
     
    })
    console.log("List Log" , listLoc) 
    
    
    return (
        <div className="row">
            <div className="col-md-6 col-12">
                <div className="mb-3">Lorem ipsum is dummy content Cenas in erat accumsan, hendrerit
                    lorem vel, pulvinar odio. Quisque
                    eu conva. hendrerit lorem vel, pulvinar odio. Quisque eu conva.
                </div>
                <h4 className="mb-2 text-muted">Countries</h4>
                <div className="row">
                     <div className="col-lg-6 col-sm-6 col-12 mb-5 mb-md-1">
                        {topLocationsdashboard.map((country, index) => <CountryListItem key={index} country={country}/>)}
                    </div> 
                    {/*  <div className="col-lg-6 col-sm-6 col-12 mb-5 mb-md-1">
                        {countryList1.map((country, index) => <CountryListItem key={index} country={country}/>)}
                    </div>  */}
                </div>
            </div>


            <div className="col-md-6 col-12">
                <div style={{width: '100%', height: 553}}>
                    <VectorMap map={'world_mill'}
                               backgroundColor="#FFF"
                               containerStyle={{
                                   width: '100%',
                                   height: '100%'
                               }}
                               regionStyle={{
                                   initial: {
                                       fill: "#DEE4E8"
                                   }
                               }}
                               markerStyle={{
                                   initial: {
                                       fill: "#3258EF",
                                       stroke: 'rgba(50, 88, 239, 0.2)',
                                   }
                               }}
                               zoomButtons={false}
                               zoomOnScroll={false}
                               containerClassName="map"

                               markers={[markersList]
                              } />
                </div>
            </div>
        </div>
    )
};

export default SiteVisitor;


