import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {SortableElement, SortableHandle} from 'react-sortable-hoc';
import ProfileView from 'components/profile/ProfileHeader/ProfileView';
import { handleGetProfileDetails } from 'actions/profile';
import { useDispatch} from 'react-redux'
import { useSelector} from 'react-redux'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Check from '@material-ui/icons/Check'; 
import PermIdentity from '@material-ui/icons/PermIdentity';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import { HandleSelectPipelineProfile, SelectPipelineProfileResetMessages } from 'actions/dashboard/profil';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AttachmentIcon from '@material-ui/icons/Attachment';
import Notif from 'components/uielements/Notification';
import IntlMessages from "util/IntlMessages";
import {Alert} from '@material-ui/lab';
const DragHandle = SortableHandle(() =>
  <i className="zmdi zmdi-menu draggable-icon" style={{fontSize: 25}}/>);

const ItemApplicants = (props) => {

  const [open, setOpen] = React.useState(false);
  const [openProfile, setOpenProfile] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [menuState, setMenuState] = useState();
  const itemDetailsProfile = useSelector(state=>state.profile.details.details);
  const message =useSelector(state=>state.opportunities.selectPipeline.message)
  const error =useSelector(state=>state.opportunities.selectPipeline.error)
  const [openNotif, setOpenNotif]=React.useState(false)

  const onContactOptionSelect = event => {
    setMenuState(true);
    setAnchorEl(event.currentTarget);
  };
  const handleRequestClose = () => {
    setMenuState(false);
  };
  const dispatch = useDispatch();
  
  const handleSelect=()=>{
   const data = `/opportunities/applications/${id}/selection/add/`
   setOpenNotif(true)
    dispatch(HandleSelectPipelineProfile(data));
    error?
    setPublicationStatus(publicationStatus):
       publicationStatus === "select" ?
        setPublicationStatus("warning"):setPublicationStatus("select")
        dispatch(SelectPipelineProfileResetMessages())
  }


  const handleSelectapplicationsShared=()=>{
    const data = `/activities/shared/${id}/selection/add/`
    setOpenNotif(true)
     dispatch(HandleSelectPipelineProfile(data));
     error?
     setPublicationStatus(publicationStatus):
        publicationStatus === "select" ?
        setPublicationStatus("warning"):setPublicationStatus("select")
        dispatch(SelectPipelineProfileResetMessages())
  }


  const handleSelectApplicationsSpontaneous=()=>{
    const data = `/profiles/applications/spontaneous/${id}/selection/add/`
    setOpenNotif(true)
     dispatch(HandleSelectPipelineProfile(data));
     error?
     setPublicationStatus(publicationStatus):
        publicationStatus === "select" ?
         setPublicationStatus("warning"):setPublicationStatus("select")
         dispatch(SelectPipelineProfileResetMessages())
    } 
 
  const handleSelectApplicationsRecommendations=()=>{
    const data = `/profiles/recommendations/${id}/selection/add/`
    setOpenNotif(true)
     dispatch(HandleSelectPipelineProfile(data));
     error?
     setPublicationStatus(publicationStatus):
        publicationStatus === "select" ?
         setPublicationStatus("warning"):setPublicationStatus("select")
         dispatch(SelectPipelineProfileResetMessages())}


  const {contact} = props;
  console.log("TestContact", contact)
    const {id,profile,opportunity,organization,matching, datafile,date_of_application,is_selected, thumb,starred} = props;
    

    const [publicationStatus, setPublicationStatus]=React.useState(is_selected? "select":"warning")
  

    const DataSelected={
      profile_id:profile?profile.id:null,
      opportunity_id:opportunity?  opportunity.id.id:null,
    }

    const DataRejete={
      profile_id:profile?profile.id:null,
      opportunity_id:opportunity?  opportunity.id.id:null,
    }
    const options = [
      'Edit',
      'Delete',
    ];
    const handleCloseNotif=()=>{
      setOpenNotif(false)
     }

    return (
      <div className="col-12 contact-item ripple row no-gutters align-items-center py-2 px-3 py-sm-4 px-sm-6">      
{/* 
      {error?<Alert severity="success" onClose={()=>dispatch(SelectPipelineProfileResetMessages())}>{error ?error : null}</Alert>:null}
          */}
       
       <Notif open={openNotif} title={<IntlMessages id="notification"/>} text={
        error?
        error :
        message?message:null}
        notification="notification"
        error={error? error : null}
        message={message ? "message" : null}
        handleClose={handleCloseNotif}/> 

     
        {/* <DragHandle/> */}
        <img className="rounded-circle size-40 mx-4" alt={profile.first_name} src={profile.photo}/>
         
        <div className="col text-truncate font-weight-bold">
        <ProfileView  
        ProfileSlug={profile} first_name={profile.first_name} last_name={profile.last_name}/>
       {datafile?
       datafile!==null?
        <a href={datafile} target="blank"> CV : <AttachmentIcon className="green_color" color="#28a745;"/> </a>
       :null:null}
       </div>

       <div className="col email text-truncate px-1 d-none d-lg-flex">
       {organization?
        <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={organization.name}
                    secondary={
                      <React.Fragment>
                        <Typography component="span" variant="body2" color="textPrimary"><small>{date_of_application}</small></Typography>
                      </React.Fragment>
                    }
                  />
        </ListItem>
        :
        <ListItem alignItems="flex-start">
        <ListItemText
          primary={opportunity.title}
          secondary={
            <React.Fragment>
              <Typography component="span" variant="body2" color="textPrimary"><small>{date_of_application}</small></Typography>
            </React.Fragment>
          }
        />
      </ListItem>
                    }
        </div>
        <div className="col text-center">
          {matching === 0 ? "0" : matching} 
        </div>
        <div className="col  text-center">
       
            <div className="col  text-center">
            {props.ApplicationsDashboard?
                     <e onClick={()=>handleSelect(DataSelected)}> 
                        <IconButton>
                            <Check 
                            className={`green_color ${publicationStatus}`}
                            color="#4caf50;"/>
                        </IconButton>
                    </e>
            :null}
            
         
            </div>

            {props.applicationsSharedDashboard?
            <div className="col  text-center">
                <e onClick={()=>handleSelectapplicationsShared(DataSelected)}> 
                  <IconButton>
                      <Check 
                      className={`green_color ${publicationStatus}`}
                      color="#4caf50;"/>
                  </IconButton>
              </e>
              </div>:null}

              {props.ApplicationsSpontaneousDashboard?
                <div className="col  text-center">
                <e onClick={()=>handleSelectApplicationsSpontaneous(DataSelected)}> 
                  <IconButton>
                      <Check 
                      className={`green_color ${publicationStatus}`}
                      color="#4caf50;"/>
                  </IconButton>
              </e> 
              </div>:null
              }

              {props.ApplicationsRecommendationsDashboard?
              <div className="col  text-center">
              <e onClick={()=>handleSelectApplicationsRecommendations(DataSelected)}> 
                <IconButton>
                  <Check 
                  className={`green_color ${publicationStatus}`}
                  color="#4caf50;"/>
                </IconButton>
              </e> 
              </div>:null
            }
       </div>
     </div>
    )
};

export default SortableElement(ItemApplicants);