export function handleFilterDate(data){
    return {
        type:"HANDLE_GET_DATE_FILTER",
        payload:data
    }
}
export function handleFilterDateSuccess(data){
    return {
        type:"GET_DATE_FILTER",
        payload:data
    }
}

export function handleFilterDateError(data){
    return{
        type:"GET_DATE_FILTER_FAILLURE",
        payload:data
    }
}
