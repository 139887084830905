import {all, fork, put, takeEvery} from "redux-saga/effects";
import { resetUserSession } from "actions/users";
import { ACCOUNT_URL} from "config";
function* logoutFlow(){
    yield put(resetUserSession());
    yield put({type:'FIRST_ACCESS', payload:{}});
}
export function* handleLogout(){
    yield takeEvery("LOGOUT", logoutFlow)
   
}
export default function* rootSaga() {
    yield all([fork(handleLogout)]);
}