import React,{useEffect, useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../../assets/imgs/Wutiko_Logo_Black.png';
import {Link} from "react-router-dom";
import { GET_TOKEN_URL } from 'config';
import { REGISTER_URL } from 'config';
import { useSelector, useDispatch} from 'react-redux'
import { handleLogout } from 'actions/header';
import Avatar from '@material-ui/core/Avatar';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import { DriveEtaSharp } from '@material-ui/icons';
import IntlMessages from "util/IntlMessages";
import {STORE_URL ,ACCOUNT_URL, PAGE_URL} from "config";
import ContactForm from 'components/formsElements/ContactForm';
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import {switchLanguage, toggleCollapsedNav} from "actions";
import LanguageSwitcher from "components/LanguageSwitcher/index";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.white,
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color:theme.palette.common.black,
    },
  }));
  
function CTooltip(props) {
    const classes = useStylesBootstrap();
  
    return <Tooltip arrow classes={classes} {...props} />;
  }
  
const useStyles = makeStyles({
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
});

export default function MenuLayout(props) {
  const classes = useStyles();
  const [open, setOpen]=React.useState(false)
  const [stateMenu, setStateMenu] = React.useState({
    left: false,
  });

  const stateprofil =useSelector((state)=>state)
  const isAuthenticated=stateprofil.users.authenticated
  const dispatch = useDispatch();
  
  const [langSwitcher, setLangSwitcher] = useState(false);

  const {locale} = useSelector(({settings}) => settings);

  const onLangSwitcherSelect = () => {
    setLangSwitcher(!langSwitcher);
  };
  const onSwitchLanguage = (lang) => {
    dispatch(switchLanguage(lang))
  };

  const handleRequestClose = () => {
    setLangSwitcher(false);
  };
  const gotoRegister= ()=>{
    window.location.href=REGISTER_URL;
  }
  const gotoLogin = ()=>{
    window.location.href=`${GET_TOKEN_URL}&state=/home`;
  }
  const gotoLogout = ()=>{
    dispatch(handleLogout())
    window.location.href=`${ACCOUNT_URL}/logout/`
  }

  
  const handleOpenModal= ()=>{
    setOpen(true)
  }
  const handleClose=()=>{
    setOpen(false)
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setStateMenu({ ...stateMenu, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className="MenuLayout">
      <div className="LogoMenuLayout">
        <Link to="/">
        <img src={logo} alt="wtk"></img>
        </Link>
        </div>
      </List> 
      <Divider />

      <ContactForm open={open}  handleClose={handleClose}/>
      {isAuthenticated?
        <div>
        <List className="user-connect">
        <div className="user">
        <ListItem> <Link to={`/profile/`+stateprofil.users.userSettings.slug} className="photo_profil">
       <div className="row">
       <div className="col-md-3 col-4">
        {stateprofil.users.userSettings.photo? <img alt="Remy Sharp" src={stateprofil.users.userSettings.photo} />:null} 
       </div>
       <div  className="user_name col-md-7 col-8">{stateprofil.users.userSettings.first_name}<br/> {stateprofil.users.userSettings.last_name}
       <br/><div className="title"> {stateprofil.users.userSettings.title}</div>
       </div>
       </div>
       </Link>
       </ListItem>
       <Divider/>
       
        <ListItem><Link to={`/profile/`+stateprofil.users.userSettings.slug}>
        {<IntlMessages id="my_profile"/>}
        </Link></ListItem>
        <ListItem>
        {isAuthenticated?
        <a href="/dashboard">
        {<IntlMessages id="access_to_dashboard"/>}
        </a>
        : <a href={`${GET_TOKEN_URL}&state=/home` }>{<IntlMessages id="access_to_dashboard"/>}</a>
        }
        </ListItem>
        <ListItem>
          <a href="/admin-organization">{<IntlMessages id="manage_companies"/>}</a>
        </ListItem>
        <ListItem><a href={`${STORE_URL}/plans/pricing/`}>
        {<IntlMessages id="upgrade_to_premium"/>}
        </a></ListItem>
        <ListItem><a href={`${ACCOUNT_URL}/settings/`}>
        {<IntlMessages id="settings"/>}
        </a></ListItem>
        <ListItem>
        <a href="#"  onClick={gotoLogout}>{<IntlMessages id="logout"/>}</a>
        </ListItem>
        </div>
       </List>
       <Divider/>

          <List className="ReseauxSocial">
          <div className="menulayout">
              <div><a href="https://www.linkedin.com/company/wutiko" target="blank"><img className="rs" src="https://cdn.wutiko.com/v3/static/images/icons/medium/png/round/linkedin.png"/></a></div>
              <div><a href="https://www.facebook.com/wutiko" target="blank"><img className="rs" src="https://cdn.wutiko.com/v3/static/images/icons/medium/png/round/facebook.png"/></a></div>
              <div><a href="https://www.twitter.com/wutiko" target="blank"><img className="rs" src="https://cdn.wutiko.com/v3/static/images/icons/medium/png/round/twitter.png"/></a></div>
              <div><a href="https://www.instagram.com/wutiko" target="blank"><img className="rs" src="https://cdn.wutiko.com/v3/static/images/icons/medium/png/round/instagram.png"/></a></div>
              <div><a href="https://www.youtube.com/wutiko" target="blank"><img className="rs" src="https://cdn.wutiko.com/v3/static/images/icons/medium/png/round/youtube.png"/></a></div>
              </div>
          <div className="copyrate">
              <span >©2020, <a href="/">Wutiko.</a>{<IntlMessages id="all_rights_reserved"/>}.</span>
          </div>
          <div className="container col-md-12 langue-menulayout">
          <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">
          {/* <i className={`flag flag-24 flag-${locale.icon}`}/>{` ${locale.name}`} */}
          <div className="d-flex align-items-center">
                <i className={`flag flag-24 flag-${locale.icon}`}/>
                <h4 className="mb-0 ml-2">{locale.name}</h4>
            </div>
          </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
        >
           <LanguageSwitcher switchLanguage={onSwitchLanguage}
                                    handleRequestClose={handleRequestClose}/>
        </Select>
      </FormControl>
          </div> 
          </List>
     </div>
      :
      <div className="user">
         <List>

         <ListItem > <a  href="#" onClick={gotoLogin}>
           {<IntlMessages id="log_in"/>}
        </a></ListItem>

        <ListItem > <a href="#" onClick={gotoRegister} className="signup">
        {<IntlMessages id="sign_up"/>}
        </a></ListItem>

         <ListItem> <a href="/about">
          {<IntlMessages id="about"/>}
        </a></ListItem>
        <ListItem><a href={`${STORE_URL}/plans/pricing/`}>
             {<IntlMessages id="pricing"/>}
        </a></ListItem>
        {/* <ListItem><a href={`${PAGE_URL}/careers`}>
            {<IntlMessages id="careers"/>}
        </a></ListItem> 
        <ListItem> <Link to="#" onClick={handleOpenModal}>
        {<IntlMessages id="contact"/>}
        </Link></ListItem>*/}
        </List>

        <Divider/>

        {/* <List>
        <ListItem><a href={`${PAGE_URL}/blogs`}>{<IntlMessages id="blogs"/>}</a></ListItem>   
        <ListItem><a href={`${PAGE_URL}/faq/`}>{<IntlMessages id="faq"/>}</a></ListItem>
        <ListItem><a href={`${PAGE_URL}/about/media-kit/`}>{<IntlMessages id="media_kit"/>}</a></ListItem>
        <ListItem><a href={`${PAGE_URL}/about/developers/`}>{<IntlMessages id="developers"/>}</a></ListItem>
        </List> */}
        <Divider/>
        <List className="ReseauxSocial">
        <div className="menulayout">
            <div><a href="https://www.linkedin.com/company/wutiko" target="blank"><img className="rs" src="https://cdn.wutiko.com/v3/static/images/icons/medium/png/round/linkedin.png"/></a></div>
            <div><a href="https://www.facebook.com/wutiko" target="blank"><img className="rs" src="https://cdn.wutiko.com/v3/static/images/icons/medium/png/round/facebook.png"/></a></div>
            <div><a href="https://www.twitter.com/wutiko" target="blank"><img className="rs" src="https://cdn.wutiko.com/v3/static/images/icons/medium/png/round/twitter.png"/></a></div>
            <div><a href="https://www.instagram.com/wutiko" target="blank"><img className="rs" src="https://cdn.wutiko.com/v3/static/images/icons/medium/png/round/instagram.png"/></a></div>
            <div><a href="https://www.youtube.com/wutiko" target="blank"><img className="rs" src="https://cdn.wutiko.com/v3/static/images/icons/medium/png/round/youtube.png"/></a></div>
             </div>
        <div className="copyrate">
            <span >©2020, <a href="/">Wutiko.</a> {<IntlMessages id="all_rights_reserved"/>}</span>
        </div>
        <div className="container col-md-12 langue-menulayout">
        
        <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">
          {/* <i className={`flag flag-24 flag-${locale.icon}`}/>{` ${locale.name}`} */}
          <div className="d-flex align-items-center">
                <i className={`flag flag-24 flag-${locale.icon}`}/>
                <h4 className="mb-0 ml-2">{locale.name}</h4>
            </div>
          </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
        >
           <LanguageSwitcher switchLanguage={onSwitchLanguage}
                                    handleRequestClose={handleRequestClose}/>
        </Select>
      </FormControl>
        </div>
        </List>
      </div>
      }
      
     
    </div>
  );

  return (
    <div>
    <div className="d-none d-md-block d-lg-block">
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}><MenuIcon /></Button>
          <Drawer anchor={anchor} open={stateMenu[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
     <div className="search d-xl-none d-md-none col-2">
     {['left'].map((anchor) => (
       <React.Fragment key={anchor}>
         <Button onClick={toggleDrawer(anchor, true)}><MenuIcon /></Button>
         <Drawer anchor={anchor} open={stateMenu[anchor]} onClose={toggleDrawer(anchor, false)}>
           {list(anchor)}
         </Drawer>
       </React.Fragment>
     ))}
   </div>
   </div>
  );
}
