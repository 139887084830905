import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { CLIENT_ID } from "config";
import {createCompanySetFormDataError,  successcreateCompanySetFormData} from "actions/companies/createCompany";

const createCompanySetFormData = async (instance,actions)=>
    await instance.post(`/organizations/add/?client=${CLIENT_ID}`,actions)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            return error.response.data
        }
    })

function* createCompanySetForm(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data     =yield call(createCompanySetFormData,axiosInstance,actions.data);
    if(data.message){
            yield put(successcreateCompanySetFormData(data.message));
           window.location.href=`/companies/${data.slug}/`
    }else{
        yield put(createCompanySetFormDataError(data.error));
    }
    
}
export function* createCompany(){
    yield takeEvery("HANDLE_CREATE_COMPANY", createCompanySetForm)
}
export default function* rootSaga() {
    yield all([fork(createCompany)]);
}