
/***
 * Edit Company 
 */
export function handleEditCompany(data){
    return{
        type:"HANDLE_EDIT_COMPANY",
        data:data
    }
  }
  //Backend response data
  export function EditCompanySuccess(data){
    console.log(data,"EDIT_COMPANY")
    return{
        type:"EDIT_COMPANY",
        payload:data
    }
  }
  //  Backend Error message
  export function EditCompanyError(data){
    return{
        type:"EDIT_COMPANY_FAILLURE",
        payload:data
    }
  }
  export function EditCompanyResetMessages(data){
    return{
        type:"EDIT_COMPANY_RESET_MESSAGES",
        payload:data
    }
  }
  
  
  /***
   * Edit Company  photo
   */
  export function handleEditPhotoCompany(data){
    return{
        type:"HANDLE_EDIT_PHOTO_COMPANY",
        data:data
    }
  }
  //Backend response data
  export function EditPhotoCompanySuccess(data){
    console.log(data,"EDIT_PROFILE")
    return{
        type:"EDIT_PHOTO_COMPANY",
        payload:data
    }
  }
  //  Backend Error message
  export function EditPhotoCompanyError(data){
    return{
        type:"EDIT_PHOTO_COMPANY_FAILLURE",
        payload:data
    }
  }
  export function EditPhotoCompanyResetMessages(data){
    return{
        type:"EDIT_PHOTO_COMPANY_RESET_MESSAGES",
        payload:data
    }
  }
  



  /***
   * Edit Company  photo
   */
  export function handleEditCoverPhotoCompany(data){
    return{
        type:"HANDLE_EDIT_COVER_PHOTO_COMPANY",
        data:data
    }
  }
  //Backend response data
  export function EditCoverPhotoCompanySuccess(data){
    return{
        type:"EDIT_COVER_PHOTO_COMPANY",
        payload:data
    }
  }
  //  Backend Error message
  export function EditCoverPhotoCompanyError(data){
    return{
        type:"EDIT_COVER_PHOTO_COMPANY_FAILLURE",
        payload:data
    }
  }
  export function EditCoverPhotoCompanyResetMessages(data){
    return{
        type:"EDIT_COVER_PHOTO_COMPANY_RESET_MESSAGES",
        payload:data
    }
  }
  /***
 * Get Edit company 
 */


export function handleGetEditCompanyDetails(slug){
    return{
        type:"HANDLE_GET_EDIT_COMPANY_DETAILS",
        slug:slug
    }
  }
  //Backend response data
  export function getEditCompanyDetailsSuccess(data){
    console.log(data,"GET_EDIT_COMPANY")
    return{
        type:"GET_EDIT_COMPANY",
        payload:data
    }
  }
  //  Backend Error message
  export function getEditCompanyDetailsError(data){
    return{
        type:"GET_EDIT_COMPANY_FAILLURE",
        payload:data
    }
  }
  export function getEditCompanyDetailsResetMessages(data){
    return{
        type:"GET_EDIT_COMPANY_RESET_MESSAGES",
        payload:data
    }
  }
  
  