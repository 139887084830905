const languageData = [

    {
        languageId: 'french',
        locale: 'fr',
        code: 'FR',
        name: 'French',
        icon: 'fr'
    },
    {
        languageId: 'english',
        locale: 'en',
        code: 'EN',
        name: 'English',
        icon: 'us'
    }
];
export default languageData;
