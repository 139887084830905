import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { getProfileEducationsSuccess, getProfileEducationsError } from  "actions/profile";

const GetProfileEducationsRequest = async (instance)=>
    await instance.get(`/profiles/educations/list/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetProfileEducations(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetProfileEducationsRequest,axiosInstance );
    if(data.data){
        yield put(getProfileEducationsSuccess(data.data));
    }else{
        yield put(getProfileEducationsError(data.error));
    }
    
}
export function* handleGetProfileEducations(){
    console.log("Educations List" , "Educations")
    yield takeEvery("HANDLE_GET_PROFILE_EDUCATIONS", GetProfileEducations)
}
export default function* rootSaga() {
    yield all([fork(handleGetProfileEducations)]);
}
//