import React from 'react';
import ReactDOM from 'react-dom';
import ReactNotification from 'react-notifications-component'
import ScrollToTop from './ScrollToTop';
import 'react-notifications-component/dist/theme.css'
const rootEl = document.getElementById('app-site');


window.scrollTo(0, 0);
var elmnt = document.getElementById("app-site");

elmnt.scrollIntoView(); 

// Create a reusable render method that we can call more than once
let render = () => {
    // Dynamically import our main App component, and render it
    const MainApp = require('./MainApp').default;
    ReactDOM.render(
        <MainApp/>,
        rootEl
    );
};

if (module.hot) {
    module.hot.accept('./MainApp', () => {
        const MainApp = require('./MainApp').default;
        render(
            <ReactNotification />,
            <MainApp/>,
            rootEl
            
        );
    });
}

render();
