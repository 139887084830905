import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { getEditProfileDetailsSuccess, getEditProfileDetailsError } from  "actions/profile";

const GetEditProfileDetailsRequest = async (instance,slug)=>
    await instance.get(`/profiles/edit/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetEditProfileDetails(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetEditProfileDetailsRequest,axiosInstance, actions.slug);
    if(data.data){
        yield put(getEditProfileDetailsSuccess(data.data));
    }else{
        yield put(getEditProfileDetailsError(data.error));
    }
}
export function* handleGetEditProfileDetails(){
    yield takeEvery("HANDLE_GET_EDIT_PROFILE_DETAILS", GetEditProfileDetails)
}
export default function* rootSaga() {
    yield all([fork(handleGetEditProfileDetails)]);
}
//