import React from 'react';
import ShortText from '@material-ui/icons/ShortText';
import DateRange from '@material-ui/icons/DateRange';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import CheckBox from '@material-ui/icons/CheckBox';
import AttachFile from '@material-ui/icons/AttachFile';
import ArrowDropDownCircle from '@material-ui/icons/ArrowDropDownCircle';
import TextFields from '@material-ui/icons/TextFields';

export const initState={
    fields:{

    },
    fields_copy:{},
    title:null,
    is_unique_response:false,
    description:null,
    open:false,
    choices:[
        {name:"Single line", icon:<ShortText/>,value:1},
        {name:"Multiple ligne", icon:<TextFields/>,value:2},
        {name:"Radio button", icon:<RadioButtonChecked/>,value:8},
        {name:"Check boxes", icon:<CheckBox/>,value:5},
        {name:"File", icon:<AttachFile/>,value:9},
        {name:"Date", icon:<DateRange/>,value:10},
        {name:"Drop down", icon:<ArrowDropDownCircle/>,value:6},
    ],
    fields_type:{
      "Single line text":1,
      "Multi line text":2,
      "Radio buttons":8,
      "Check boxes":5,
      "File upload":9,
      "Date":10,
      "Drop down":6,
    },
    fields_icons:{
      1:<ShortText color="#0088ca" />,
      2:<TextFields color="#0088ca" />,
      8:<RadioButtonChecked color="#0088ca"/>,
      5:<CheckBox color="#0088ca"/>,
      9:<AttachFile color="#0088ca"/>,
      10:<DateRange color="#0088ca"/>,
      6:<ArrowDropDownCircle color="#0088ca"/>,
   },
   fieldsType:{
    1:"Single line text",
    2:"Multi line text",
    8:"Radio buttons",
    5:"Check boxes",
    9:"File upload",
    10:"Date",
    6:"Drop down",
  },
    tempory_form_structure:[],
    form_data:null,
    formToView:false,
}