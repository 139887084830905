import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Opportunity from './Opportunity';
import Divider from './Divider';
import {useDispatch, useSelector} from 'react-redux'
import Skeleton from '@material-ui/lab/Skeleton';
import Link from '@material-ui/core/Link';
import IntlMessages from "util/IntlMessages";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding:15,
    paddingLeft:24,
    paddingRight:24,
    width:'100%',
  },
  ul:{
    'list-style-type': 'none',
    paddingLeft: 0,

  },
  btn:{
    backgroundColor:"#fff",
    '&:hover':{
      backgroundColor:"#fff",
    }
  },
  link:{
    textDecoration:'none',
    '&:hover':{
      textDecoration:'none'
    }
}
}));

export default function FeaturedOpportunity() {
  const classes = useStyles();
  const dispatch = useDispatch()
  const featuredOpp= useSelector(state=>state.opportunities.featured_opportunities)
  useEffect(()=>{
      dispatch({type:"HANDLE_GET_FEATURED_OPPORTUNITIES", payload:{}})
  },[dispatch])
  return (
    <div className="opportunies-home-page">
   <div className="container">
   {featuredOpp.list.length !== 0?
   <footer className={classes.footer}>
    {/* <h2 className="title-homepage">
      <div>{<IntlMessages id="Featured_opportunities"/>}</div> <div className="bottom-title"></div></h2> */}
      <div className="d-none  d-sm-none d-md-block d-lg-block">
      <Grid container spacing={2}>
          {(featuredOpp.spinner ? Array.from(new Array(9)) : featuredOpp.list).map((item, index) =>{
              if(item){
                return(
                  <Grid item md={4} xs={12} sm={6}>
                       <Opportunity data={item}/>
                  </Grid>
                )
              }else{
                return(
                  <Grid item md={4} xs={12} sm={6}>
                  <Skeleton variant="rect" fullWidth height={118} />
                </Grid>
                )
              }
          })
        }
        <Grid item md={12} xs={12} container justify="center" >
                <Link href="/opportunities" className={classes.link}>
                    <Button className={classes.btn}>
                        {<IntlMessages id="view_all_opportunities"/>}
                    </Button>
                </Link>
        </Grid>
      </Grid>
      </div>


      <div className="d-xl-none d-md-none">
      <Grid container spacing={2}>
          {(featuredOpp.spinner ? Array.from(new Array(9)) : featuredOpp.list).slice(0,4).map((item, index) =>{
              if(item){
                return(
                  <Grid item md={4} xs={12} sm={6}>
                       <Opportunity data={item}/>
                  </Grid>
                )
              }else{
                return(
                  <Grid item md={4} xs={12} sm={6}>
                  <Skeleton variant="rect" fullWidth height={118} />
                </Grid>
                )
              }
          })
        }
        <Grid item md={12} xs={12} container justify="center" >
                <Link href="/opportunities" className={classes.link}>
                    <Button className={classes.btn}>
                        {<IntlMessages id="view_all_opportunities"/>}
                    </Button>
                </Link>
        </Grid>
      </Grid>
      </div>
    </footer>
     :
     null}
    </div>
    </div>
  );
}

FeaturedOpportunity.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
