

import Skills from "containers/filters/Skills";
//export const GET_COMPANY_SEARCH = 'GET_COMPANY_SEARCH';


export const GET_POSITION_SEARCH = 'GET_POSITION_SEARCH';
export const GET_COMPANY_SEARCH = 'GET_COMPANY_SEARCH';

export const GET_EDUCATION_SEARCH = 'GET_EDUCATION_SEARCH';

export function setSearchResults(data){
    return{
        type:"SEARCH",
        payload:data
    }
}
export function setSearchError(data){
    return{
        type:"SEARCH_FAILLURE",
        payload: data
    }
}

export function handleOpportunitiesSearch(data){
    return{
        type:"HANDLE_SEARCH",
        data:data
    }
}
export function handleProfilesSearch(data){
    return{
        type:"HANDLE_SEARCH_PROFILES",
        data:data
    }
}

export function handleOrganizationsSearch(data){
    return{
        type:"HANDLE_SEARCH_ORGANIZATIONS",
        data:data
    }
}

export function handleCompaniesSearch(data){
    return{
        type:"HANDLE_COMPANIES_COMPANIES",
        data:data
    }
}
/**
 * 
 * Simple search method no related 
 * to filtes
 */

export function handleSearchIndustries(data){
    return {
        type:"HANDLE_SEARCH_INDUSTRIES",
        payload:data
    }
}

export function SearchIndustriesSuccess(data){
    return {
        type:"SEARCH_INDUSTRIES",
        payload:data
    }
}

export function SearchIndustriesError(data){
    return {
        type:"SEARCH_INDUSTRIES_FAILLURE",
        payload:data
    }
}
/**
 * 
 * Simple search method no related 
 * LIST
 * Company
 */

export function SearchCompaniesList(data){
    return {
        type:"HANDLE_COMPANIES_SEARCH",
        payload:data
    }
}

export function SearchCompaniesSucess(data){
    return {
        type:"SET_COMPANIES_SEARCH",
        payload:data
    }
}

export function SearchCompaniesError(data){
    return {
        type:"SET_COMPANIES_SEARCH_FAILLURE",
        payload:data
    }
}

/**
 * 
 * Simple search method no related 
 * to filtes
 * Company
 */
export function handleSearchCompany(data){
    return {
        type:"HANDLE_SEARCH_COMPANIES",
        payload:data
    }
}

export function SearchCompanySucess(data){
    return {
        type:"SEARCH_COMPANY",
        payload:data
    }
}

export function SearchCompanyError(data){
    return {
        type:"SEARCH_COMPANY_FAILLURE",
        payload:data
    }
}




/**
 * 
 * Simple search method no related 
 * to filtes
 * Company ADMIN
 */
export function handleSearchCompanyAdmin(data){
    return {
        type:"HANDLE_SEARCH_COMPANIES_ADMIN",
        payload:data
    }
}

export function SearchCompanyAdminSucess(data){
    return {
        type:"SEARCH_COMPANY_ADMIN",
        payload:data
    }
}

export function SearchCompanyAdminError(data){
    return {
        type:"SEARCH_COMPANY_ADMIN_FAILLURE",
        payload:data
    }
}

/**
 * 
 * Simple search method no related 
 * to filtes
 * Education
 */

export function handleSearchEducation(data){
    return {
        type:"HANDLE_SEARCH_EDUCATION",
        payload:data
    }
}

export function SearchEducationSucess(data){
    return {
        type:"SEARCH_EDUCATION",
        payload:data
    }
}

export function SearchEducationError(data){
    return {
        type:"SEARCH_EDUCATION_FAILLURE",
        payload:data
    }
}


/**
 * 
 * Simple search method no related 
 * to filtes
 * Position
 */
export function handleSearchPosition(data){
    return {
        type:"HANDLE_SEARCH_POSITION",
        payload:data
    }
}

export function SearchPositionSucess(data){
    return {
        type:"SEARCH_POSITION",
        payload:data
    }
}

export function SearchPositionError(data){
    return {
        type:"SEARCH_POSITION_FAILLURE",
        payload:data
    }
}

/**
* 
* Simple search method no related 
* to filtes
* Skills
*/
export function handleSearchSkills(data){
   return {
       type:"HANDLE_SEARCH_SKILLS",
       payload:data
   }
}

export function SearchSkillsSucess(data){
   return {
       type:"SEARCH_SKILLS",
       payload:data
   }
}

export function SearchSkillsError(data){
   return {
       type:"SEARCH_SKILLS_FAILLURE",
       payload:data
   }
}



/**
* 
* Simple search method no related 
* to filtes
* contract Type
*/
export function handleSearchContractType(data){
    return {
        type:"HANDLE_SEARCH_CONTRACTTYPE",
        payload:data
    }
 }
 
 export function SearchContractTypeSucess(data){
    return {
        type:"SEARCH_CONTRACTTYPE",
        payload:data
    }
 }
 
 export function SearchContractTypeError(data){
    return {
        type:"SEARCH_CONTRACTTYPE_FAILLURE",
        payload:data
    }
 }

/**
* 
* Simple search method no related 
* to filtes
* occupations
*/
export function handleSearchOccupations(data){
    return {
        type:"HANDLE_SEARCH_OCCUPATIONS",
        payload:data
    }
 }
 
 export function SearchOccupationsSucess(data){
    return {
        type:"SEARCH_OCCUPATIONS",
        payload:data
    }
 }
 
 export function SearchOccupationsError(data){
    return {
        type:"SEARCH_OCCUPATIONS_FAILLURE",
        payload:data
    }
 }


 /**
* 
* Simple search method no related 
* to filtes
* benefits
*/
export function handleSearchBenefits(data){
    return {
        type:"HANDLE_SEARCH_BENEFITS",
        payload:data
    }
 }
 
 export function SearchBenefitsSucess(data){
    return {
        type:"SEARCH_BENEFITS",
        payload:data
    }
 }
 
 export function SearchBenefitsError(data){
    return {
        type:"SEARCH_BENEFITS_FAILLURE",
        payload:data
    }
 }


 /**
* 
* Simple search method no related 
* to filtes
* Categories
*/
export function handleSearchCategories(data){
    return {
        type:"HANDLE_SEARCH_CATEGORIES",
        payload:data
    }
 }
 
 export function SearchCategoriesSucess(data){
    return {
        type:"SEARCH_CATEGORIES",
        payload:data
    }
 }
 
 export function SearchCategoriesError(data){
    return {
        type:"SEARCH_CATEGORIES_FAILLURE",
        payload:data
    }
 }
/**
* 
* Simple search method no related 
* to filtes
* langue
*/
export function handleSearchLangue(data){
    return {
        type:"HANDLE_SEARCH_LANGUE",
        payload:data
    }
 }
 
 export function SearchLangueSucess(data){
    return {
        type:"SEARCH_LANGUE",
        payload:data
    }
 }
 
 export function SearchLangueError(data){
    return {
        type:"SEARCH_LANGUE_FAILLURE",
        payload:data
    }
 }


/**
* 
* Simple search method no related 
* to filtes
* Area
*/
export function handleSearchArea(data){
    return {
        type:"HANDLE_SEARCH_AREA",
        payload:data
    }
 }
 
 export function SearchAreaSucess(data){
    return {
        type:"SEARCH_AREA",
        payload:data
    }
 }
 
 export function SearchAreaError(data){
    return {
        type:"SEARCH_AREA_FAILLURE",
        payload:data
    }
 }