import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { getProfilesByCompanySuccess, getProfilesByCompanyError } from  "actions/companies/getCompanies";

const GetProfilesByCompanyRequest = async (instance, CompanyId)=>
    await instance.get(`organizations/${CompanyId}/profile-favorites/list/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetProfilesByCompany(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetProfilesByCompanyRequest,axiosInstance, actions.CompanyId);
    
    if(data.data.results){

    console.log("Get Profiles By Company",  data.data.results)
        yield put(getProfilesByCompanySuccess(data.data.results));
    }else{
        yield put(getProfilesByCompanyError(data.error));
    }
    
}
export function* handleGetProfilesByCompany(){
    yield takeEvery("HANDLE_GET_PROFILES_BY_COMPANY", GetProfilesByCompany)
}
export default function* rootSaga() {
    yield all([fork(handleGetProfilesByCompany)]);
}
