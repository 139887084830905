import React, { useState } from 'react';
import { Grid, TextField, Select, Button,MenuItem } from '@material-ui/core';
import { createFormInitState } from './utils/createFormInitSate';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {useDispatch, useSelector} from 'react-redux'
import { handleCreateForm, createFormResetMessages } from 'actions/forms/createForm';
import { Alert } from '@material-ui/lab';
 
export function CreateForm(){
    const [state, setState] = useState(createFormInitState);
    const  submissionData =useSelector(state=>state.createForm.create_form)
    const dispatch = useDispatch()
    const handleChanges=(event)=>{
        setState({...state, [event.target.name]:event.target.value})
    }
    const handleCheckChanges=(event)=>{
        setState({...state, [event.target.name]: !state[event.target.name]})
    }
    const handleSubmit = (event)=>{
        dispatch(handleCreateForm(state))
    }
    return(
        <Grid container spacing={2}>
            <Grid item xs={12}>
            {submissionData.message?<Alert severity="success" onClose={()=>dispatch(createFormResetMessages())}>{submissionData.message}</Alert>:null}
            {submissionData.error?<Alert severity="error" onClose={()=>dispatch(createFormResetMessages())}>{submissionData.error}</Alert>:null}
            </Grid>
            <Grid item xs={6}>
                <TextField name="title" value={state.title} label="Title" fullWidth onChange={handleChanges}/>
            </Grid>
            <Grid item xs={6}>
                <Select name="form_type" value={state.form_type} label="Form type" fullWidth onChange={handleChanges}>
                    <MenuItem value={0}>Application</MenuItem>
                    <MenuItem value={1}>Evaluation</MenuItem>
                    <MenuItem value={2}>Contact</MenuItem>
                    <MenuItem value={3}>Confirmation</MenuItem>
                    <MenuItem value={4}>Adhesion</MenuItem>
                    <MenuItem value={5}>Participation</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={6}>
             <TextField name="intro" value={state.intro} multipleline={true} label="Introduction" fullWidth onChange={handleChanges}/>
            </Grid>
            <Grid item xs={6}>
              <TextField name="button_text" value={state.button_text} label="Submit button text" fullWidth onChange={handleChanges}/>
            </Grid>
            <Grid item xs={6}>
               <TextField name="response" value={state.response} label="Response" fullWidth onChange={handleChanges}/>
            </Grid>
            <Grid item xs={6}>
               <TextField name="redirect_url" value={state.redirect_url} label="Redirect url" fullWidth onChange={handleChanges}/>
            </Grid>
            <Grid item xs={6}>
               <Select name="status" value={state.status} label="Form type" fullWidth onChange={handleChanges}>
                    <MenuItem value={1}>Drafy</MenuItem>
                    <MenuItem value={2}>Published</MenuItem>
                    <MenuItem value={3}>Admin only</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={6}>
               <TextField name="publish_date" type="datetime-local" value={state.publish_date} label="Publish date" fullWidth onChange={handleChanges}/>
            </Grid>
            <Grid item xs={6}>
               <TextField name="expiry_date" type="datetime-local" value={state.expiry_date} label="Expiry date" fullWidth onChange={handleChanges}/>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel fullWidth control={<Checkbox name="is_scored" checked={state.is_scored} onChange={handleCheckChanges}/>} label="Is scored" />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel fullWidth control={<Checkbox name="login_required" checked={state.login_required} onChange={handleCheckChanges}/>} label="Login required" />
            </Grid>
            <Grid item xs={6}>
                <FormControlLabel fullWidth control={<Checkbox name="send_email" checked={state.send_email} onChange={handleCheckChanges}/>} label="Send email" />
            </Grid>
            <Grid item xs={6}>
                <TextField name="email_from" value={state.email_from} label="Email from" fullWidth onChange={handleChanges}/>
            </Grid>
            <Grid item xs={6}>
               <TextField name="email_copies" value={state.email_copies} label="Email copies" fullWidth onChange={handleChanges}/>
            </Grid>
            <Grid item xs={6}>
              <TextField name="email_subject" value={state.email_subject} label="Email subject" fullWidth onChange={handleChanges}/>
            </Grid>
            <Grid item xs={6}>
              <TextField name="email_message" value={state.email_message} label="Email message" fullWidth onChange={handleChanges}/>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel fullWidth control={<Checkbox name="send_delivery" checked={state.send_delivery} onChange={handleCheckChanges}/>} label="Send delivery" />
            </Grid>
            <Grid item xs={6}>
              <TextField name="delivery_to" value={state.delivery_to} label="Delivery to" fullWidth onChange={handleChanges}/>
            </Grid>
            <Grid item xs={6}>
              <TextField name="delivery_from" value={state.delivery_from} label="Delivery from" fullWidth onChange={handleChanges}/>
            </Grid>
            <Grid item xs={6}>
              <TextField name="delivery_copies" value={state.delivery_copies} label="Delivery copies" fullWidth onChange={handleChanges}/>
            </Grid>
            <Grid item xs={6}>
              <TextField name="delivery_message" value={state.delivery_message} label="Delivery message" fullWidth onChange={handleChanges}/>
            </Grid>
            <Grid item container justify="flex-end">
                <Button variant="contained" color="primary" disabled={submissionData.spinner} onClick={handleSubmit}>Submit</Button>
            </Grid>
        </Grid>
    )
}