// eslint-disable import / first
import {useHistory} from 'react-router-dom';  
import {useParams} from 'react-router-dom';
import React,{useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { handleGetProfileDetails } from '../../../actions/profile';
import { addGroup, addCompany,CreateAnAlertAnonymously,CreateAnAlert,CRETATE_itemDetailsProfile_ALERT,GET_ALERTS_LIST,Empty,getItem,UPDATE_ALERT,updateAlert} from '../../../actions/index';
import { connect } from 'react-redux';
//import SidebarProfil from './SidebarProfil.jsx';
import { Link } from 'react-router-dom'; 
import { Col} from 'react-bootstrap'
import { renderSelectField, renderTextField} from '../../formsElements/FormComponents';
import MenuItem from '@material-ui/core/MenuItem';
import { withTranslation } from 'react-i18next';
import {UPDATE_PROFILE, updateProfile} from '../../../actions/user'
import { success } from 'react-notification-system-redux';
import { Field, reduxForm } from 'redux-form';
import {  RIEInput, RIETextArea } from 'riek'
import { Helmet } from 'react-helmet';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog2 from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import { ProgressBar } from 'react-bootstrap';
//import EditPhoto from './EditPhoto';
//import EditProfilePage from '../../../pages/ProfileForm';
import { Button } from 'reactstrap';
import logo from '../../../assets/imgs/spinner.gif';
import {Alert} from '@material-ui/lab';
import { useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import FullWidthTabs from 'components/FullWidthTabs';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LaunchIcon from '@material-ui/icons/Launch';
import IntlMessages from "util/IntlMessages";
import Container from '@material-ui/core/Container';
import ProfileExperience from './ProfileExperience';
import ProfileEducation from './ProfileEducation';
import ProfileAchievement from './ProfileAchievement';
import ProfileAreas from './ProfileAreas';
import ProfileSkills from './ProfileSkills';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  header:{
    position:'fixed',
    top:0,
    width:'100%',
    background:'#ffffff',
    zIndex:10
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  }, 
  search:{
    paddingTop:0,
    paddingBottom:0
  }
}));

export default function HeaderProfile({props, t,handleSubmit }) {
    const classes = useStyles();
    const {userId} =useParams();
    const state = useSelector((state)=>state)
  //  const userId = useSelector(state => state.users.id)
    const slug = useSelector(state => state.users.slug)
    const isAuthenticated=state.users.authenticated
    const itemDetailsProfile = useSelector(state=>state.profile.details.details);
    const dispatch = useDispatch();
    useEffect(()=>{
      dispatch(handleGetProfileDetails(userId));
        },[dispatch]);
    console.log("UserId" , userId);
    console.log("Slug" , slug);
    console.log("showItemDetails" , {itemDetailsProfile});
    //let ProfilePercentage = itemDetailsProfile.profile_filling_in_rate ? '0.'+itemDetailsProfile.profile_filling_in_rate : null;
    // console.log("rating" , ProfilePercentage);
    // require('jquery-circle-progress');
    // var $ = require('jquery');
    // $('#circle').circleProgress({
    //   value:  ProfilePercentage,
    //   size: 150,
    //   fill: {
    //       gradient: [ "#009dd9", "#0064b2"]
    //     }
  
    // });
  
  // let ProfilePercentage1 = itemDetailsProfile.profile_filling_in_rate ? itemDetailsProfile.profile_filling_in_rate : null;  
  // let look;
  
  //     if (ProfilePercentage1<50){
  //        look="danger";
  //      } else if (ProfilePercentage1<75) {
  //        look="warning";
  //      } else {
  //        look="success";
  //     }
    // const FirstName =itemDetailsProfile.first_name || "";
    // const LastName =itemDetailsProfile.last_name || "";
    // const AboutMe  = itemDetailsProfile.about_me || "";
 

  return (
    <div className="container">
        <div className="animated slideInUpTiny animation-duration-3">
        <div className="container">
          <div className="col-md-8 col-sm-7 col-12 animation slideInLeft">
            <div className="row">
                    <div className="col-md-12 animation slideInLeft">
                      {itemDetailsProfile?
                      <span className="cover-photo">
                        <img
                          src={itemDetailsProfile?itemDetailsProfile.cover_photo:null}
                          alt="Cover photo"
                        />
                      </span>:null}
                    </div>
                    <div className="col-md-12 photo-profil animation slideInLeft">
                      {itemDetailsProfile?
                      <span className="photo">
                        <img
                          src={itemDetailsProfile?itemDetailsProfile.photo:null}
                          alt="Cover photo"
                        />
                      </span>:null}
                    </div>
                    <div className="col-md-12 col-sm-12 col-12 animation slideInRight">
                      <div className="row"> 
                          <div className="col-md-4 col-sm-4 col-4 animation slideInLeft">
                              <b>{itemDetailsProfile?<h2>{itemDetailsProfile.first_name} {itemDetailsProfile.last_name}</h2>:null} </b>
                              <b>{itemDetailsProfile?<span>{itemDetailsProfile.title}</span>:"title"} </b>
                              <b>{itemDetailsProfile?<span>{itemDetailsProfile.location}</span>:null}</b>
                          </div>
                          <div className={`col-md-4 col-sm-4 col-4 animation slideInRight ${classes.textAlignEnd}`}>
                          {itemDetailsProfile?
                          <div>{itemDetailsProfile.phones.map(phone => phone.full_number ).join(' , ')}</div>
                          :null
                          }
                          </div>
                          <div className="col-md-4 col-sm-4 col-4 animation slideInLeft">
                          <p className="text-grey mb-1">
                                 <IconButton><MailOutlineIcon/></IconButton>{<IntlMessages id="email" />}
                              </p>
                          </div>
                      </div>
                      <div className="row key_dashbord">
                      {itemDetailsProfile? 
                          <div className="col-md-12 col-sm-12 col-12 animation slideInLeft">
                            <h2>{<IntlMessages id="your_dashboard" />}</h2>
                            <span>{<IntlMessages id="private_to_you" />}</span>
                           {/* {itemDetailsProfile?itemDetailsProfile.industries.map(
                             item=>item.industry_id?item.industry_id.name:''
                           ).toString():null} */}
                         
                              <div className="row">
                              <div className="col-md-4 col-sm-4 col-4 animation slideInRight">
                              <span className="count">{itemDetailsProfile.total_searches_appearence_7}</span><br/>
                              <span>{<IntlMessages id="search_appearances" />}</span>
                              </div>
                              <div className="col-md-4 col-sm-4 col-4 animation slideInLeft">
                              <span className="count">{itemDetailsProfile.total_views_90}</span><br/><span>{<IntlMessages id="who_interested_profile" />}</span>
                              </div>
                              <div className={`col-md-4 col-sm-4 col4 animation slideInRight ${classes.textAlignEnd}`}>
                               <span className="count">{itemDetailsProfile.total_connexions}</span><br/><span>{<IntlMessages id="connections" />}</span>
                              </div>
                            </div>
                        </div>
                        :null}
                      </div>
                      <div className="row"> 
                          <div className="col-md-12 col-sm-8 col-12 animation slideInLeft">
                          <div className="row">
                            <div className="col-md-6 col-sm-6 col-6 animation slideInLeft">
                                <p className="text-grey mb-3">
                                Repport this Profile<IconButton><LaunchIcon/></IconButton>
                              </p> 
                            </div>
                            <div className="col-md-2 col-sm-2 col-2 animation slideInLeft">
                               <p className="text-grey mb-3">
                                <IconButton><FavoriteBorderIcon/></IconButton>{<IntlMessages id="save" />}
                              </p>
                            </div>
                            <div className="col-md-2 col-sm-2 col-2 animation slideInRight">
                               <p className="text-grey mb-1">
                                 <IconButton><MailOutlineIcon/></IconButton> {<IntlMessages id="email" />}
                              </p>
                            </div>
                         
                          <div className="col-md-2 col-sm-2 col-2 animation slideInRight">
                            <Button fullWidth variant="contained" color="secondary"> {<IntlMessages id="connect" />}</Button>
                          </div>
                      </div>
                      </div>
                      </div>
                    </div>
            </div>
            <div className="row">
              {itemDetailsProfile?
              <div>
              <div><h2>{<IntlMessages id="about" />}</h2></div>
              <p>{itemDetailsProfile.about_me}</p> 
              </div>
              :
              null
              }         
            </div>
            <div className="row">
            <ProfileExperience id={userId} itemDetails={itemDetailsProfile? itemDetailsProfile.positions : [] } myProfile/>
            <ProfileEducation id={userId} itemDetailsEducations={itemDetailsProfile? itemDetailsProfile.educations : [] } myProfile/>
            <ProfileAchievement  id={userId} itemDetailsProfileLanguages={itemDetailsProfile? itemDetailsProfile.languages: null} 
            itemDetailsProfilePublications={itemDetailsProfile? itemDetailsProfile.publications :null}  myProfile />
            <ProfileAreas myProfile /> 
            <ProfileSkills myProfile/>
            </div>  
            <div className="row">
                  <div className="col-md-5 col-sm-5 col-12">
                  <p className="text-grey mb-3">
                    Repport this Profile<IconButton><LaunchIcon/></IconButton>
                  </p> 
                  </div>
                  <div className="col-md-2 col-sm-2 col-6 animation slideInLeft">
                    <p className="text-grey mb-3">
                    <IconButton><FavoriteBorderIcon/></IconButton>{<IntlMessages id="save" />}
                  </p>
                    </div>
                    <div className="col-md-2 col-sm-2 col-6 animation slideInRight">
                    <p className="text-grey mb-1">
                      <IconButton><MailOutlineIcon/></IconButton>{<IntlMessages id="email" />} 
                  </p>
                    </div>
                  <div className="col-md-3 col-sm-3 col-12">
                       <Button fullWidth variant="contained" color="secondary">{<IntlMessages id="connect" />} </Button>
                  </div>
            </div>
            <div className="row">
              <div className={`col-xl-6 col-sm-12`}>
             
              </div>

              <div className="col-xl-6 col-sm-12">
               
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-5 col-12 animation slideInRight">
            <div className="sidebar">
            <fieldset className={classes.fieldset}>
              <legend className={classes.legend}>{<IntlMessages id="follow_company_pages" />} </legend>
              <div className="card  border-0 p-4">
         
                <h3 className="text-uppercase letter-spacing-base mb-3">
                  Categories
                </h3>
  
                <ul className="categories-list list-unstyled">
                  <li>
                    <span className="jr-link">Technology</span>
                  </li>
                  <li>
                    <span className="jr-link">Branding</span>
                  </li>
                  <li>
                    <span className="jr-link">Holly</span>
                  </li>
                  <li className="active">
                    <span className="jr-link">Stories</span>
                  </li>
                  <li>
                    <span className="jr-link">Designing</span>
                  </li>
                  <li>
                    <span className="jr-link">Programing</span>
                  </li>
                </ul>
                
              </div>
              </fieldset>
              <fieldset className={classes.fieldset}>
              <legend className={classes.legend}>Ads banner</legend>
                   <span className="article-image jr-link">
                      <img
                        src="https://via.placeholder.com/500x330"
                        alt="Articales Post"
                      />
                    </span>
              </fieldset>
  
            </div>
          </div>
        </div>
      </div>
      </div>      
      );
  }

  // render() {

  //   const { t} = this.props;
  //   return(
  //     <div>
  //       <Helmet>
  //         <title>
  //           {t('wutiko.profile')} | wutiko
  //         </title>
  //       </Helmet>
  //        <div className="section-page-interne  tab-content">
  //             <div className="row-tab">
  //             <div className="col-md-9">
  //       {this.renderBody()}
  //         </div>
  //           {/* <SidebarProfil/> */}
  //         </div>
  //         </div>
  //       {/*  */}
  //       {/* <Alert createAlertModal={this.handleOpenAlert} createAlertModalState={this.state.alert}/>  */}
  //         {/* alertList */}
  //         </div>
  //   )
  // }
//}


// function mapStateToProps(state) {
//   const InitiaValues={
//     //about:state.showItemDetails.detail.about_me
//   };
  
//   return {
//     itemDetailsProfile: state.users.userSettings,
//     ItemDetails : state.users.userSettings,
//       userId: state.users.id
      

//     // initialValues:InitiaValues,
//     // axiosInstance: state.user.axiosInstance,
//     // userStatus: state.user.status,
//     // spinner:state.user.alerts.spinner,
//     // message:state.user.alerts.message,
//     // list:state.user.alerts.list,
//     // error:state.user.alerts.error,
//     // list_spinner:state.user.alerts.list_spinner,
//     // update_message:state.user.alerts.update_message,
//     // update_error:state.user.alerts.update_error,
//     // profile:state.user.profile,
//   }
// }

// function mapDispatchToProps(dispatch) {
  
//   return {
//     // addGroup: (url, requestTarget, data) => dispatch(addGroup(url, requestTarget, data)),
//     // addCompany: (url, requestTarget, data) => dispatch(addCompany(url, requestTarget, data)),
//       updateProfile:(url, requestTarget, data) =>  dispatch(UPDATE_PROFILE, updateProfile(url, requestTarget, data )),
//    //   showItemDetails:showItemDetails,
      
//     // notifSuccess: (opts) => dispatch(success(opts)),
//     // CreateAnAlertAnonymously:(url, type, data) =>dispatch(CreateAnAlertAnonymously(url,type, data)),
//     // CreateAnAlert:(url, type, data) =>dispatch(CreateAnAlert(url,type, data)),
//     // updateAlert:(url, type, data) =>dispatch(updateAlert(url,type, data)),
//     getItem:(url,type) =>dispatch(getItem(url,type)),
//     Empty:(type) =>dispatch(Empty(type)),
//   };
// }
// HeaderProfile = reduxForm({
//   form: 'HeaderProfile',
//   enableReinitialize: true
// })(HeaderProfile);
// export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HeaderProfile));

HeaderProfile.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};
HeaderProfile = (withTranslation()(HeaderProfile));