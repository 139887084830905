import { Col } from 'react-bootstrap';
//import ProfileArea from './ProfileArea';
//import ProfileFile from './ProfileFile';
import ProfileExperience from './ProfileExperience';
// import ProfileContact from './ProfileContact';
import ProfileEducation from './ProfileEducation';
import ProfileAchievement from './ProfileAchievement';
import ProfileSkills from './ProfileSkills';
// import ProfileSector from './ProfileSector';
// import ProfileFileBnt from './ProfileFileBnt';
import { withTranslation } from 'react-i18next';
import { useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import { handleGetProfileDetails, handleGetProfileEducations, 
  handleGetProfileSkills , handleGetProfileSectors,
  handleGetProfileExperiences, handleGetProfilePublication,handleGetProfileLangue, handleGetProfileAreas } from '../../../actions/profile';
import React,{useEffect} from 'react';
import { useDispatch} from 'react-redux'
import IntlMessages from "util/IntlMessages";
import ProfileAreas from './ProfileAreas';
import IconButton from "@material-ui/core/IconButton";
import { Button,  Form} from 'reactstrap';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LaunchIcon from '@material-ui/icons/Launch';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { ka } from 'date-fns/locale';
import SidebarRight from 'components/Sidebar/SidebarRight';
import LatestCompanies from 'components/companies/LatestCompaniesList';
import EditIcon from '@material-ui/icons/Edit';
import EditProfilePage from 'pages/ProfileForm';
import EditProfile from 'pages/Profile';
import { handleGetConnexionsDashboard, handleGetOpportunitiesApplyDashboard, handleGetSearchesAppearenceDashboard, handleGetSpontaneousApplyDashboard, handleGetViewProfilDashboard } from 'actions/dashboard/profil';
import ListOfConnexionsDashboard from './ListOfConnexions';
import ListOfSearchesAppearenceDashboard from './ListOfSearchesAppearence';
import ListOfViewProfilDashboard from './ListOfViewProfil';
import ListOfSpontaneousApplyDashboard from './ListOfSpontaneousApply';
import ListOfOpportunitiesApplyDashboard from './ListOfOpportunitiesApply';
import DocumentFile from './ProfileFile'
import EditPhoto from './EditPhoto'
import Skilltest from './Skilltest'
import CircularProgress from '@material-ui/core/CircularProgress';
import { handleGetEditProfileDetails } from 'actions/profile';
const useStyles = makeStyles((theme) => ({
    toolbar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    header:{
      position:'fixed',
      top:0,
      width:'100%',
      background:'#ffffff',
      zIndex:10
    },
    toolbarTitle: {
      flex: 1,
    },
    toolbarSecondary: {
      justifyContent: 'space-between',
      overflowX: 'auto',
    },
    toolbarLink: {
      padding: theme.spacing(1),
      flexShrink: 0,
    }, 
    search:{
      paddingTop:0,
      paddingBottom:0
    }
  }));
export default function MyProfile({props, t }) {
    const itemDetails = useSelector(state=>state.profile.details.details);
    const classes = useStyles();
    const itemDetailsProfile = useSelector(state=>state.profile.details.details);

    const DetailsProfileBassique = useSelector(state=>state.profile.get_profile_bassique.details);
    const OpportunitiesApplyDashboard = useSelector(state=>state.keys.opportunitiesApplyDashboard.list);
    const SpontaneousApplyDashboard = useSelector(state=>state.keys.spontaneousApplyDashboard.list);
    const ConnexionsDashboard = useSelector(state=>state.keys.connexionsDashboard.list);
    const SearchesAppearenceDashboard = useSelector(state=>state.keys.searchesAppearenceDashboard.list);
    const ViewProfilDashboard = useSelector(state=>state.keys.viewProfilDashboard.list);
    const publication = useSelector(state=>state.profile.publication.list)
    const experiences = useSelector(state=>state.profile.experiences.list)
    const educations = useSelector(state=>state.profile.educations.list)
    const Skills = useSelector(state=>state.profile.skills.list);
    const skills = useSelector(state=>state.profile.skills.list);
    const Sectors = useSelector(state=>state.profile.sectors.list);
    const publications = publication ? publication :[]
    const educationsList = educations ? educations :[]

    const Langue = useSelector(state=>state.profile.langue.list)
  const LangueList = Langue ? Langue:[]
  const PublicationList = publication? publication:[]


    const userId = useSelector(state => state.users.id)
    const spinner = useSelector(state=>state.profile.edit_profile.spinner)
    const error = useSelector(state=>state.profile.edit_profile.error)
    const message = useSelector(state=>state.profile.edit_profile.message)
    const [open, setOpen] = React.useState(false);
    const slug = useSelector(state =>  state.users.userSettings.slug)
   // const {slug} =useParams();

    console.log("slug" , slug);
    const dispatch = useDispatch();
    useEffect(()=>{
      dispatch(handleGetProfileDetails(slug));
      dispatch(handleGetEditProfileDetails());
      dispatch(handleGetConnexionsDashboard());
      dispatch(handleGetOpportunitiesApplyDashboard());
      dispatch(handleGetSpontaneousApplyDashboard());
      dispatch(handleGetSearchesAppearenceDashboard());
      dispatch(handleGetViewProfilDashboard());
        dispatch(handleGetProfilePublication());
        dispatch(handleGetProfileEducations());
        dispatch(handleGetProfileExperiences());
         dispatch(handleGetProfileSkills());
        dispatch(handleGetProfileSectors());
        dispatch(handleGetProfileLangue());
        dispatch(handleGetProfileAreas());
        },[dispatch]);


        
        // const SubmitPhoto=(value)=>{
        //   const data = new FormData();
        //   data.append('photo', value.photo);
        //   this.props.updateProfile('/profiles/'+this.props.itemDetailsProfile.id+'/edit/', UPDATE_PROFILE, data);
        //   setTimeout(
        //     ()=>{
        //       this.updateProfileContent();
        //     },2500
        //   )
        // }

        const SubmitPhoto=(value)=>{
         // event.preventDefault();
          const data = new FormData();
          {value.cover_photo?
          data.append('cover_photo', value.cover_photo)
          :
          data.append('photo', value.photo)
          }
          console.log("Data Photo" ,value.cover_photo)
          console.log("Data Photo" ,data )
              dispatch({type : "HANDLE_EDIT_PROFILE" , data})
              
           }

        console.log("OpportunitiesApplyDashboard" , OpportunitiesApplyDashboard);
        console.log("SpontaneousApplyDashboard" , SpontaneousApplyDashboard);
        console.log("ConnexionsDashboard" , ConnexionsDashboard);
        console.log("SearchesAppearenceDashboard" , SearchesAppearenceDashboard);
        console.log("ViewProfilDashboard" , ViewProfilDashboard);
        console.log("List Education" , educations);
        console.log("Get Details Profile Bassique" , DetailsProfileBassique);
        console.log("List experiences" , experiences);
    return(
      <div className="container">
        <Container maxWidth={false} className={classes.cont}>
        <div className={`animated slideInUpTiny animation-duration-3 ${classes.content}`}>
        <div className="row">
          <div className="col-md-8 col-sm-7 col-12 animation slideInLeft bloc-profile">
            <div className="row">
                    <div className="col-md-12 animation slideInLeft CoverPhoto">
                      {DetailsProfileBassique?
                      <span className="cover-photo">
                        <img
                          //src={itemDetailsProfile?itemDetailsProfile.cover_photo:"https://cdn.wutiko.com/v3/static/images/default/low/wutiko_default_cover_photo.png"}
                          src={DetailsProfileBassique.cover_photo?DetailsProfileBassique.cover_photo:"https://cdn.wutiko.com/v3/static/images/default/low/wutiko_default_cover_photo.png"}
                          alt={<IntlMessages id="cover_photo"/>}
                        />
                        <EditPhoto SubmitPhoto={SubmitPhoto} 
                        logo={DetailsProfileBassique.photo}
                        spinner={spinner}
                        cover_photo="cover_photo"
                        message={message} 
                        error={error}
                        /> 
                      </span>:null}
                     
                      
                    </div>
                    <div className="col-md-12 photo-profil animation slideInLeft">
                      {DetailsProfileBassique?
                      <span className="photo">
                        <img
                          src={DetailsProfileBassique.photo?DetailsProfileBassique.photo:"https://cdn.wutiko.com/v3/static/images/default/medium/wutiko.png"}
                          alt={<IntlMessages id="photo"/>}
                        />
                         <EditPhoto SubmitPhoto={SubmitPhoto} 
                        logo={DetailsProfileBassique.photo}  
                        spinner={spinner} 
                        message={message} 
                        error={error} /> 
                      </span>:null}
                    </div>

                    <div className="col-md-12 col-sm-12 col-12 animation slideInRight">
                      <div className="row"> 
                          <div className="col-md-4 col-sm-4 col-4 animation slideInLeft">
                              <b>{DetailsProfileBassique?<h2>{DetailsProfileBassique.first_name} {DetailsProfileBassique.last_name}</h2>:null} </b>
                              <span>{DetailsProfileBassique?DetailsProfileBassique.title:null} </span><br/>
                              {DetailsProfileBassique?<span>{DetailsProfileBassique.location}</span>:null}
                          </div>
                          <div className={`col-md-4 col-sm-4 col-4 animation slideInRight ${classes.textAlignEnd}`}>
                          {/* {itemDetailsProfile?
                          <div>{itemDetailsProfile.phones.map(phone => phone.full_number ).join(' , ')}</div>
                          :null
                          } */}
                          </div>
                          <div className="col-md-4 col-sm-4 col-4 animation slideInLeft text-right">
                           <p className="text-grey mb-1">
                           {itemDetailsProfile?
                           <EditProfilePage userId={userId} positions={itemDetailsProfile.positions} itemDetailsProfile={DetailsProfileBassique} 
                           first_name={DetailsProfileBassique.first_name} />: null}
                              </p>  
                          </div>
                      </div>


                      {/* {itemDetailsProfile?
                      <div className="progress">
                        <div class="progress-bar" role="progressbar" aria-valuenow={`${itemDetailsProfile.profile_filling_in_rate}%`}
                        aria-valuemin="0" aria-valuemax="100" style={{width:`${itemDetailsProfile.profile_filling_in_rate}%`}}>
                        {itemDetailsProfile.profile_filling_in_rate}%  {<IntlMessages id="profile_filling_rate"/>}
                        </div>
                      </div>:null} */}

                      {itemDetailsProfile?
                      <div className="roww">
                        <div className="col-md-2 score">
                        {itemDetailsProfile.profile_filling_in_rate > 0  ? itemDetailsProfile.profile_filling_in_rate : "0" } / 100<br></br>  
                          <span><IntlMessages id="profile_filling_rate"/></span> 
                        </div>
                      <div className="progress col-md-10">
                          <div className="progress-bar progress-bar-striped bg-info " role="progressbar" aria-valuenow={`${itemDetailsProfile.profile_filling_in_rate}%`}
                        aria-valuemin="0" aria-valuemax="100" style={{width:`${itemDetailsProfile.profile_filling_in_rate}%`}}>
                      {itemDetailsProfile.profile_filling_in_rate > 0 ? itemDetailsProfile.profile_filling_in_rate :"0" }% 
                        </div>
                      </div>
                      </div>:null}


                      <div className="row key_dashbord">
                      {itemDetailsProfile? 
                          <div className="col-md-12 col-sm-12 col-12 animation slideInLeft">
                            <h2>{<IntlMessages id="your_dashboard"/>}</h2>
                            <span>{<IntlMessages id="private_to_you"/>}</span>
                           {/* {itemDetailsProfile?itemDetailsProfile.industries.map(
                             item=>item.industry_id?item.industry_id.name:''
                           ).toString():null} */}
                         
                              <div className="row">
                              <div className="col-md-2 col-sm-2 col-3 animation slideInRight">
                              <ListOfSearchesAppearenceDashboard total_searches_appearence_7={itemDetailsProfile.total_searches_appearence_7} SearchesAppearenceDashboard={SearchesAppearenceDashboard} />
                            
                              </div>
                              <div className="col-md-2 col-sm-2 col-3 animation slideInLeft">
                              <ListOfViewProfilDashboard total_views_90={itemDetailsProfile.total_views_90} ViewProfilDashboard={ViewProfilDashboard}/>
                               </div>
                              <div className={`col-md-2 col-sm-2 col-3 animation slideInRight ${classes.textAlignEnd}`}>
                               <ListOfConnexionsDashboard total_connexions={itemDetailsProfile.total_connexions} ConnexionsDashboard={ConnexionsDashboard}/>
                              </div>
                              <div className={`col-md-2 col-sm-2 col-3 animation slideInRight ${classes.textAlignEnd}`}>
                              <ListOfSpontaneousApplyDashboard SpontaneousApplyDashboard={SpontaneousApplyDashboard}  total_organization_spontaneous_applications={itemDetailsProfile.total_organization_spontaneous_applications}/>
                              </div>
                              <div className={`col-md-2 col-sm-2 col-3 animation slideInRight ${classes.textAlignEnd}`}>
                              <ListOfOpportunitiesApplyDashboard total_applications={itemDetailsProfile.total_applications} OpportunitiesApplyDashboard={OpportunitiesApplyDashboard} />
                               </div>
                            </div>
                        </div>
                        :null}
                      </div>
                      {/* <div className="row"> 
                          <div className="col-md-12 col-sm-8 col-12 animation slideInLeft">
                          <div className="row">
                            <div className="col-md-6 col-sm-6 col-6 animation slideInLeft">
                                <p className="text-grey mb-3">
                                Repport this Profile<IconButton><LaunchIcon/></IconButton>
                              </p> 
                            </div>
                            <div className="col-md-2 col-sm-2 col-2 animation slideInLeft">
                               <p className="text-grey mb-3">
                                <IconButton><FavoriteBorderIcon/></IconButton>Save
                              </p>
                            </div>
                            <div className="col-md-2 col-sm-2 col-2 animation slideInRight">
                               <p className="text-grey mb-1">
                                 <IconButton><MailOutlineIcon/></IconButton> Email
                              </p>    
                            </div>                                                                                                                                                                                                                                                                                                        
                         
                          <div className="col-md-2 col-sm-2 col-2 animation slideInRight">
                            <Button fullWidth variant="contained" color="secondary">CONNECT</Button>
                          </div>
                      </div>
                      </div>
                      </div> */}
                    </div>
            </div>
            <div className="row col-md-12 top-20">
              {DetailsProfileBassique ?
             
              
             DetailsProfileBassique.about_me?
                <div>
                <div><h2>{<IntlMessages id="about"/>}</h2></div>
              <p>{DetailsProfileBassique.about_me}</p>  
              </div> :null 
              
              :
              null
              }         
              </div>
            <div className="row">

         
         <DocumentFile id={userId} myProfile/> 
        <ProfileExperience userId={userId} itemDetails={experiences ? experiences : []} myProfile />
        <ProfileEducation userId={userId} itemDetailsEducations={educationsList  ? educationsList : []} myProfile /> 
       
        <ProfileAchievement id={userId} itemDetailsProfilePublications={itemDetails? itemDetails.publications : []  }
        itemDetailsProfileLanguages={LangueList? LangueList : [] }
        itemDetailsProfilePublications={publications? publications : [] } myProfile/> 
        {/* <ProfileSkills userId={userId} itemDetails={itemDetails ? itemDetails : []} myProfile/>  */}
        <Skilltest myProfile/>
        {/* <ProfileSector id={userId} itemDetails={itemDetails} myProfile/> */}
        <ProfileAreas userId={userId}  myProfile />  
   
 
            </div>  
            {/* <div className="row">
                  <div className="col-md-5 col-sm-5 col-12">
                  <p className="text-grey mb-3">
                    Repport this Profile<IconButton><LaunchIcon/></IconButton>
                  </p> 
                  </div>
                  <div className="col-md-2 col-sm-2 col-6 animation slideInLeft">
                    <p className="text-grey mb-3">
                    <IconButton><FavoriteBorderIcon/></IconButton>Save
                  </p>
                    </div>
                    <div className="col-md-2 col-sm-2 col-6 animation slideInRight">
                    <p className="text-grey mb-1">
                      <IconButton><MailOutlineIcon/></IconButton> Email
                  </p>
                    </div>
                  <div className="col-md-3 col-sm-3 col-12">
                       <Button fullWidth variant="contained" color="secondary">CONNECT</Button>
                  </div>
            </div> */}
            <div className="row">
              <div className={`col-xl-6 col-sm-12`}>
             
              </div>

              <div className="col-xl-6 col-sm-12">
               
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-5 col-12 animation slideInRight">
          <LatestCompanies/>
          <SidebarRight/>
          </div>
        </div>
      </div>
      </Container>
      </div>
       
    )
}
MyProfile = withTranslation('translation')(MyProfile);
// function mapStateToProps(state) {
//   return {
//     itemDetails: state.itemdetail.detail,
//     userId: state.user.userId,
//   }
// }
