
import React,{useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'; 
import { withTranslation } from 'react-i18next';
import { Button} from 'react-bootstrap'
import FontAwesome from 'react-fontawesome';
import { REMOVE_DOCUMENT , GET_DOCUMENT_FORM, handleGetProfileFile, handleDeleteFile} from 'actions/profile';
import { updateForm } from 'actions/index'
import { getItem } from 'actions/index';
import {useParams} from 'react-router-dom'
import { useSelector, useDispatch} from 'react-redux'
import DocumentForm from 'pages/DocumentForm';
import Chip from '@material-ui/core/Chip';
import UploadCV from 'components/uploadCV/UploadCV';
import Icon from '@material-ui/core/Icon';
import IntlMessages from "util/IntlMessages";
const DEFAULT_NUMBER_FILE_DISPLAYED = 3;

const DocumentFile = (props) => {
    const {userId} =useParams();
    
    const files = useSelector(state=>state.profile.files.list);
    const [open, setOpen]=React.useState(false)
console.log("files" , files)
const dispatch = useDispatch();

useEffect(()=>{
        dispatch(handleGetProfileFile());
          },[dispatch]);



const handleRemoveFile = (documentId) =>{
    const saveData={
      documentId:documentId
    }
    console.log("remove File response : ", saveData);
    dispatch(handleDeleteFile(saveData))
    }
    const handleOpenModal= ()=>{
        setOpen(true);
        
      }
    const handleClose=()=>{
        setOpen(false)
      }

const renderDocumentList=(n)=>{
    return files.map((file) => {
    return (   
            <Chip label={ <a href={file.datafile} target="blank">{file.name}</a>}  onDelete={e => handleRemoveFile(file.id)} color="#0066b4" variant="outlined"/>
             )
            })
        }

const renderDocumentListPublic=(n)=>{
  return props.files? props.files.map((file) => {
  return (   
          <Chip label={ <a href={file.datafile} target="blank">{file.name}</a>}  onDelete={e => handleRemoveFile(file.id)} color="#0066b4" variant="outlined"/>
            )
          })
          :null }
    return (
           <div className="bloc content-page-interne education">
            <div className=""  data-aos="fade-up">
              <div className="row">
              <div className="col-md-11 col-9"><h2 className="text-left title">{<IntlMessages id="documents"/>}</h2></div>
              
              
              <div className="col-md-1 col-3">
              {props.myProfile ?
                <>
                <DocumentForm open={open} handleClose={handleClose} add={"add"} className="pull-right add" addfile={"addfile"} />
                <Button variant="outlined" color="" onClick={handleOpenModal}>
                  <Icon className="fa fa-plus-circle" style={{ color: "#ccc"}} /> 
                </Button>
                </>
                :null} 
                </div> 
                <div className="col-md-1 col-1 all-document">
                <i className="fa fa-paperclip f-5x"></i>
                  {/* { files.length>DEFAULT_NUMBER_FILE_DISPLAYED?
                    this.state.nFile === DEFAULT_NUMBER_FILE_DISPLAYED?
                      <Button bsStyle="link" onClick={this.showAllFile} className="neutre">{t("wutiko.all")}</Button>
                     :<Button bsStyle="link" onClick={this.hideAllFile} className="neutre">{t("wutiko.hide")}</Button>
                    :null
                    }   
                  */}
              </div>
                <div className="col-md-11 col-11 doc">
                     <span>{<IntlMessages id="recently_added"/>}:</span>
                     {props.myProfile ?
                     renderDocumentList()
                     :
                     renderDocumentListPublic()
                     }
                     
                </div>
                
             </div>
            </div>
            </div>
    );

}

// function mapStateToProps(state) {
//   return {
//     userId: state.user.userId,
//      accountType: state.user.accountType,
//      files: state.itemDetails,
//      axiosInstance: state.user.axiosInstance,
//   }
// }
    

export default (withTranslation('translation')(DocumentFile));