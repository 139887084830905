import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { resumeCheckCompanySuccess,resumeCheckCompanyError} from "actions/companies/getCompanies";

const resumeCheckCompanyData = async (instance,companyId,data)=>
    await instance.post(`/profiles/${companyId}/organization/resume/checker/add/`, data)
    .then(response=>response.data)
    .catch((error)=>{
        try {
            if(error.response.status===500 || error.response.status===404){
                return {error:error.response.statusText}
            }else{
                console.log(error.response.data)
                return error.response.data
            }
        } catch (err) {
            console.log(error)
            return{
                error:"Error"
            }
        }
    })

function* resumeCheckCompany(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(resumeCheckCompanyData,axiosInstance,actions.data.companyId, actions.data.fileData);
    if(data.message){
        yield put(resumeCheckCompanySuccess(data));
    }else{
        console.log("data.error", data, data.error)
        yield put(resumeCheckCompanyError(data.error));
    }
    
}

export function* handleresumeCheckCompany(){
    yield takeEvery("HANDLE_RESUME_COMPANY_CHECK", resumeCheckCompany)
}
export default function* rootSaga() {
    yield all([fork(handleresumeCheckCompany)]);
}