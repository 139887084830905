import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {useDispatch, useSelector} from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/lab/Pagination';
import Opportunity from 'components/uielements/Opportunity';
import OpportunitiesFilters from 'containers/filters/OpportunitiesFilters';
import { handleGetCompanyLatest } from 'actions/companies/getCompanies';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import LatestCompaniesDetail from './LatestCompanyDetail';
import IntlMessages from "util/IntlMessages";
const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding:15,
    paddingLeft:24,
    paddingRight:24,
    width:'100%',
  },
  ul:{
    'list-style-type': 'none',
    paddingLeft: 0,

  },
  btn:{
    backgroundColor:"#ff9801",
    '&:hover':{
      backgroundColor:"#ff9801",
    }
  }
}));

export default function LatestCompanies() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const state = useSelector(state=>state.company.company_latest);
    const companyLatestList=state.list?state.list:[];
    const isAuthenticated =useSelector(state=>state.users.authenticated)
    useEffect(()=>{
      dispatch(handleGetCompanyLatest({}))
    },[dispatch])

    console.log("Latest :", companyLatestList)
    return (
    <div className="">
     
      <div className="itemSidebar">

      <Container maxWidth={false} className={classes.cont}>
            <Typography 
                component="h1"
                variant="h5"
                color="inherit"
                align="center"
                noWrap
                className={classes.title}>
              {<IntlMessages id="follow_company_pages"/>}
          </Typography>
          </Container>


          <Grid container spacing={2}>
        {
         
          companyLatestList?
          companyLatestList.map((item)=>{
            return(
              <Grid item md={12} xs={12}>
                  <LatestCompaniesDetail data={item} isAuthenticated={isAuthenticated}/>
             </Grid>
            )
            }) : null
        }
        </Grid>

      </div>
       
    </div>
  );
}

LatestCompanies.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};





















