import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { CLIENT_ID } from "config";
import { StatusOpportunitySuccess, StatusOpportunityError} from "actions/opportunities";
import { SelectPipelineProfileSuccess, SelectPipelineProfileError} from "actions/dashboard/profil";

const StatusOpportunity = async (instance, opportunity_id, action)=>
    await instance.put(`/opportunities/${opportunity_id}/${action}/`, {})
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })
function* StatusOpportunityAction(actions){
        const state = yield select((state)=>state.users);
        let axiosInstance;
        if(state.authenticated){
            axiosInstance=state.axiosInstance
        }else{
            axiosInstance=state.blankAxiosInstance
        }
        const data =yield call(StatusOpportunity,axiosInstance, actions.data.opportunity_id, actions.data.action);
        if(data.message){
           // yield put({type:"HANDLE_GET_OPPORTUNITIES_DASHBOARD"})
            yield put(StatusOpportunitySuccess(data.message));
        }else{
            console.log("data.error", data, data.error)
            yield put(StatusOpportunityError(data.error));
        }
        
    }




const  SelectPipelineProfile = async (instance, data)=>
    await instance.put(`${data}`, {})
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })
function* SelectPipelineProfileAction(actions){
        const state = yield select((state)=>state.users);
        let axiosInstance;
        if(state.authenticated){
            axiosInstance=state.axiosInstance
        }else{
            axiosInstance=state.blankAxiosInstance
        }
        const data =yield call(SelectPipelineProfile,axiosInstance, actions.data);
        if(data.message){
           // yield put({type:"HANDLE_GET_OPPORTUNITIES_DASHBOARD"})
            yield put(SelectPipelineProfileSuccess(data.message));
        }else{
            console.log("data.error", data, data.error)
            yield put(SelectPipelineProfileError(data.error));
        }
        
    }



function* HandleSelectPipelineProfile(){
    yield takeEvery("HANDLE_SELECT_PIPELINE_PROFILE", SelectPipelineProfileAction)
}

function* HandleStatusOpportunity(){
    yield takeEvery("HANDLE_STATUS_OPPORTUNITY", StatusOpportunityAction)
}
export default function* rootSaga() {
    yield all([fork(HandleSelectPipelineProfile)]);
    yield all([fork(HandleStatusOpportunity)]);
}