import React from 'react';
import Form from "react-jsonschema-form";
import {useSelector} from 'react-redux'
import { Button } from '@material-ui/core';

export function PreViewForm (){
  
  const formData =useSelector(state=>state.preViewForm.pre_view_form)
  return(
          <div className="col-md-12">
              {formData.spinner?null:<Form 
                  schema={{
                    properties:formData.properties,
                    required:formData.required
                  }} 
                  uiSchema={formData.uiSchema} 
                  onSubmit={()=>{}} 
                  >
                  <Button hidden={true} color="primary" variant="contained">SUBMIT</Button>
            </Form>}
          </div>     
  ) 
}; 

