import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { handleSetSearchPositions, handleSetSearchPositionsError } from "actions/filters/positions";

// 
const setSearchPositions = async (instance,actions)=>
    await instance.get(`/searches/filter/${actions.scope}/positions/${actions.search}`,actions)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            return error.response.data
        }
    })

function* SearchPositions(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(setSearchPositions,axiosInstance,actions.payload);
    if(data.results){
            yield put(handleSetSearchPositions(data));
    }else{
           yield put(handleSetSearchPositionsError(data.error));
    }
    
}

export function* handleSearchPositions(){
    yield takeEvery("HANDLE_GET_POSITIONS_SEARCH", SearchPositions)
}

export default function* rootSaga() {
    yield all([fork(handleSearchPositions)]);
}