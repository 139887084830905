import React, { Component } from 'react';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import moment from 'moment';
import { Col, Form,  Row, Button} from 'react-bootstrap';
import { renderField, renderSubmitGroup, StaticError,
  renderImageInput, renderDateTime, renderSelect, renderCheckbox} from '../components/formsElements/FormComponents'
import { GET_PROFILE_FORM, UPDATE_PROFILE,GET_POSITIONS_FORM } from '../actions/profile';
import { getForm, getItem, updateForm,showItemDetails } from '../actions/index';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog2 from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import IntlMessages from "util/IntlMessages";
import Select from '@material-ui/core/Select';
class EditProfilePage extends Component {

  constructor(props) {
    super(props);
    this.state = { location: {}, showLocationAutocomplete: false ,
         openForm: false, scroll: 'paper', location_list:[]};
    this.submit = this.submit.bind(this);
    this.updateLocationId = this.updateLocationId.bind(this);
    this.UpdateData = this.UpdateData.bind(this);
    this.handleDeletelocation=this.handleDeletelocation
    this.Close = this.Close.bind(this);
    this.Open = this.Open.bind(this);
  }



  componentDidMount(props) {
    this.UpdateData();
  }

  UpdateData(){
    // this.props.getForm(`/profiles/${this.props.userId}/edit/`, GET_PROFILE_FORM);  
    // const url = `/profiles/${this.props.userId}/positions/list/`
    // this.props.getItem(url, GET_POSITIONS_FORM);

  }

  

  Open = scroll => () => { 
    this.setState({openForm: true, scroll});
  };

  Close = () => {
    this.setState({openForm: false});
  };
  componentWillReceiveProps(nextProps){

    // if (nextProps.serverStatus === 'available' && this.props.serverStatus === 'updating') {

    //      this.setState({openForm: false}); 
    //       this.props.showItemDetails(`/profiles/${this.props.userId}/view/`);
    //       browserHistory.push('/networking/profile');
    //       this.UpdateData();
    //      this.render(); 
    // }else{
    //       window.scrollTo(0, 0);
    // }
  }

  updateLocationId(data,name) {
    const data_s=this.state.location_list.filter(obj=>obj.city_id!==data.city_id);
    data_s.push({ country_id:data.country_id,city_id: data.city_id,stateprovince_id: data.stateprovince_id,name:`${name.city_name}, ${name.country_name}, ${name.stateprovince_name}`});
    this.setState({ 
      location: data,
      location_list:data_s
    });
  }
  handleDeletelocation(city_id){
    const data=this.state.location_list.filter(obj=>obj.city_id!==city_id);
    this.setState({location_list:data});
  }
  
  submit(values, props)  {

    const { t } = props;
    console.log("submit values ", values);
    var countries=[];
    if(values.photo && values.photo.size > 5000000) {
      throw new SubmissionError({ photo: t('wutiko.tooLargeError'), _error: t('wutiko.submitError') })
    }
    if(values.photo && values.photo.type !== 'image/jpeg' && values.photo.type !== 'image/png' && values.photo.type !== 'image/jpg') {
      throw new SubmissionError({ photo: t('wutiko.badFormatError'), _error: t('wutiko.submitError') })
    }

    this.state.location_list.map((loc)=>{
      return countries.push({city_id:loc.city_id,country_id:loc.country_id,state_province_id:loc.stateprovince_id,is_primary_location:""})
    })
    let data = new FormData();
    data.append('first_name', values.first_name);
    data.append('last_name', values.last_name);
    data.append('date_of_birth', moment.isMoment(values.date_of_birth) ? values.date_of_birth.format('YYYY-MM-DD') : "");
    if(values.is_hide_age) {
      data.append('is_hide_age', values.is_hide_age);
    }  
     if(values.is_disabled) {
      data.append('is_disabled', values.is_disabled);
    }
    if(values.gender) {
      data.append('gender', values.gender);
    }
    if(values.about_me) {
      data.append('about_me', values.about_me);
    }
     if(values.current_status) {
      data.append('current_status', values.current_status);
    }
     if(values.current_position) {
      data.append('current_position', values.current_position);
    }
    data.append('username', values.username);
    data.append('title', values.title);
    if(values.photo) {
      data.append('photo', values.photo)
    }
    data.append('location_id',countries);

    const url = `/profiles/${this.props.userId}/edit/`
    this.props.updateForm(url, UPDATE_PROFILE, data);
  }

  render() {

   console.log("test positions: ", this.props.profilePositions)

    const { error, serverError, handleSubmit, t } = this.props;
    return(
      <div>
         <Button className="edit-profile" onClick={this.Open('paper')}>"wutiko.edit_profile"</Button>
          <Dialog2 size={400}  modal={false} open={this.state.openForm} onRequestClose={this.Close} style={{"max-width":'80%', 'border-radius':0,height:'100%','max-height':'80%','top':'10%','left':'10%',position:'center'}} fullScreen>
          <DialogTitle id="scroll-dialog-title" style={{backgroundColor:'#0065b3'}}>
           <div className="">"wutiko.edit_profile"</div>
           <div className="pull-rigth">
           <IconButton
                color="#fff"
                onClick={this.Close}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton></div>
              </DialogTitle>
            <DialogContent>
              <DialogContentText>
              <Form onSubmit={handleSubmit(this.submit)}>
              <Row className="top-50">
<Col sm={12}>
<Row>
  <Col sm={6}>
    <Field name="first_name" component={renderField} label='wutiko.first_name' />
  </Col>
  <Col sm={6}>
    <Field name="last_name" component={renderField} label='wutiko.last_name'/>
  </Col>
</Row>

<Row>
  <Col sm={12}>
    <Field name="title" component={renderField} label='wutiko.title' />
  </Col>
</Row>
</Col>
</Row>
<FormControl variant="outlined" className="formControl">
                            <InputLabel id="demo-simple-select-outlined-label">{<IntlMessages id="formStatus"/>}</InputLabel>
                           <Select
                              fullWidth
                              name="current_status"
                              label="text"
                              inputProps={{
                                name: 'current_status',
                                id: 'demo-controlled-open-select',
                              }}
                                >
                              <MenuItem>--</MenuItem>
                              <MenuItem value={0}>{<IntlMessages id="formRechercheDemploi"/>}</MenuItem>
                              <MenuItem value={1}>{<IntlMessages id="formRechercheStage"/>}</MenuItem>
                              <MenuItem value={2}>{<IntlMessages id="formRechercheOpportunite"/>}</MenuItem>
                              <MenuItem value={3}>{<IntlMessages id="formRechercheMarche"/>}</MenuItem>
                              <MenuItem value={4}>{<IntlMessages id="formRecherchePartenariat"/>}</MenuItem>
                              <MenuItem value={5}>{<IntlMessages id="formRechercheFinancement"/>}</MenuItem>
                            </Select> 
                           
                          </FormControl>

                          <Col sm={12}>
            <Field name="current_status" component={renderSelect} label='wutiko.formStatus'>
                <optgroup>
                <option value="0">--</option>
                  <option value="1">'wutiko.formRechercheDemploi'</option>
                  <option value="2">'wutiko.formRechercheStage' </option>
                  <option value="3">'wutiko.formRechercheOpportunite' </option>
                  <option value="4">'wutiko.formRechercheMarche' </option>
                  <option value="5">'wutiko.formRecherchePartenariat' </option>
                  <option value="6">'wutiko.formRechercheFinancement' </option>
                </optgroup>
              </Field>
            </Col>

              </Form>
              </DialogContentText>
            </DialogContent> 
            {/* <DialogActions>
            
            </DialogActions>  */}
            </Dialog2>
      </div>
    )
  }
}

function mapStateToProps(state) {

  const originalData = state.profile.details.details;
  const city_name = originalData.city_id ? originalData.city_id.name : null;
  const city_id = originalData.city_id ? originalData.city_id.id : null;
  const stateprovince_name = originalData.city_id ? originalData.city_id.state_province_id.name :  originalData.state_province_id ? originalData.state_province_id.name : null;
  const stateprovince_id = originalData.city_id ? originalData.city_id.state_province_id.id : originalData.state_province_id ? originalData.state_province_id.id : null;
  const country_name = originalData.city_id ? originalData.city_id.state_province_id.country_id.name :  originalData.state_province_id ? originalData.state_province_id.country_id.name : null;
  const country_id = originalData.city_id ? originalData.city_id.state_province_id.country_id.id : originalData.state_province_id ? originalData.state_province_id.country_id.id : null;
  const location_name = city_name ? city_name + ', ' + stateprovince_name + ', ' + country_name : stateprovince_name ? stateprovince_name + ', ' + country_name : "";

  const customInitialValues = {
    first_name: originalData.first_name,
    last_name: originalData.last_name,
    title: originalData.title,
    about_me: originalData.about_me,
    current_status:originalData.current_status,
    current_position:originalData.current_position,
    location_name: location_name,
    city_id: city_id,
    stateprovince_id: stateprovince_id,
    country_id: country_id,
    is_hide_age: originalData.is_hide_age,
    is_disabled: originalData.is_disabled,
    date_of_birth: originalData.date_of_birth,
    gender: originalData.gender,
  }

  return {
    initialValues: customInitialValues,
    serverError: state.profile.edit_profile.error,
    serverStatus: state.profile.edit_profile.spinner,
    profilePositions: state.search.positions.list,
    axiosInstance: state.users.axiosInstance,
    userId: state.users.userId,
    photo:originalData.photo
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getForm: (url, requestTarget) => dispatch(getForm(url, requestTarget)),
    updateForm: (url, requestTarget, data) => dispatch(updateForm(url, requestTarget, data)),
    getItem: (url, requestType) => dispatch(getItem(url, requestType)),
    showItemDetails:(url) =>dispatch(showItemDetails(url)),
  };
}

EditProfilePage = reduxForm({
  form: 'EditProfilePage',
  enableReinitialize: true,
})(EditProfilePage);

export default connect(mapStateToProps, mapDispatchToProps)(EditProfilePage);