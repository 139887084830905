import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Slider from "react-slick";

import React,{useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import { Link } from '@material-ui/core';
import IntlMessages from "util/IntlMessages";


const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.text.disabled,
    // marginTop:15,
    marginLeft:24,
    marginRight:24,
    padding:15,
    paddingLeft:24,
    paddingRight:24,
    // width:'100%',
  },
  ul:{
    'list-style-type': 'none',
    paddingLeft: 0,

  },

}));
var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
export default function Partners(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const partnersList = useSelector(state=>state.partners.listpartners.list)
  console.log("partnersList" ,partnersList)
  useEffect(()=>{
    dispatch({type:'HANDLE_GET_PARTNERS', paylaod:{}})
  },[dispatch])

  return (
    <footer className="partners-logo">
      <div className="container">
        <h2 className=" title-homepage">
          <div>{<IntlMessages id="our_partners"/>}</div>
      <div className="bottom-title partners"></div></h2>
      </div>
      <Slider {...settings} className="container">
      {
          partnersList.map((item)=>{
            return(
            <figure className="c4-izmir c4-border-bottom-left c4-image-rotate-right c4-gradient-bottom-right" tabindex="0">
              <img src={item.organization_logo_url ? item.organization_logo_url : null} alt="Sample Image"/>
              <figcaption className="c4-layout-bottom-left">
                  <div className="c4-reveal-right c4-delay-100">
                    <h3>
                    <a href={item.organization_url ? item.organization_url :null} target={item.target_blank? "blank" : null }>
                      {/* {item? item.organization_name : null} */}
                      </a>
                    </h3>
                  </div>
              </figcaption>
            </figure>
            )
          }
          )
        }
        </Slider>
        
    </footer>
  );
}

Partners.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};