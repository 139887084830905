import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {reduxForm} from 'redux-form';
import {Col,Row} from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import {getItem,Empty} from 'actions';

import IconButton from "@material-ui/core/IconButton";
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear'
import { withTranslation } from 'react-i18next';
const styles = theme => ({
  
  list: {
    marginBottom: theme.spacing.unit * 2,
  },
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 3
  },
  root: {
    padding: '2px 4px',
    marginTop:'10px',
    marginBottom:'10px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  fontFamily: "sans-serif",
  textAlign: "center"
});
class Search extends React.Component {
  constructor(props){
   super(props);
   this.state = {
        cropResult: null,
        tags:[],
        value:""
         };
    this.handleOnChange=this.handleOnChange.bind(this);
    this.Clean=this.Clean.bind(this);
  }

  Clean(){
    this.setState({value:""})
    if(this.props.clean){
      this.props.Empty(`RESET_${this.props.action_scope}_MESSAGES`);
    }else{
      this.props.getItem(`${this.props.url}${this.props.close?`?${this.props.close}`:``}`,`${this.props.action_scope}`); 
    }
    
  }



handleOnChange(value){
    this.setState({value:value.target.value});
    if(value.target.value){
      const url = `${this.props.url}?${this.props.keyword}=${value.target.value}${this.props.close?`&${this.props.close}`:``}`;
      this.props.getItem(url,`${this.props.action_scope}`);
      }else{
        this.props.getItem(`${this.props.url}${this.props.close?`?${this.props.close}`:``}`,`${this.props.action_scope}`);   
      }
  }
  render() {
    const { t,classes} = this.props;
    console.log(this.tags)
    return (
        <Col md={12}>
        <Paper className={`${this.props.custom_style?`search_style`:classes.root}`} elevation={1}>
            <InputBase className={classes.input} placeholder={t("wutiko.search")}
                onChange={this.handleOnChange}
                autocomplete="off"
                value={this.state.value}
            />
            <IconButton className={classes.iconButton} aria-label="Search">
            <SearchIcon />
            </IconButton>
            {this.state.value?<Divider className={classes.divider} />:null}
            {this.state.value?<IconButton color="secondary" className={classes.iconButton} aria-label="Directions" onClick={this.Clean}>
            <Clear />
            </IconButton>:null}
        </Paper>
        </Col>
    );
  }
}
function mapStateToProps(state){
   return {
    list:state.keys.applicationsDashboard.list,
   } 
  }
Search.propTypes = {
  classes: PropTypes.object.isRequired
};
Search = reduxForm({
  form: 'Search',
  enableReinitialize: true
})(Search);
export default connect(mapStateToProps,{getItem,Empty})(withStyles(styles)(withTranslation('translation')(Search)));



