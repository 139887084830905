
import React from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
// import { Parser } from 'html-to-react';
import {  Button } from 'react-bootstrap';
// import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import {  handleDeleteArea} from 'actions/profile';
import { getItem } from 'actions/index';
import Chip from '@material-ui/core/Chip';
import IntlMessages from "util/IntlMessages";
import {  useDispatch} from 'react-redux'
import AddAreasForm from 'containers/Profil/AddAreas'
const DEFAULT_NUMBER_AREAS_DISPLAYED = 3;

class ProfileAreas extends React.Component{

  constructor(props) {
    super(props);    
     this.state = { 
      nArea: DEFAULT_NUMBER_AREAS_DISPLAYED ,
     }
    this.handleRemoveArea = this.handleRemoveArea.bind(this);
    this.showAll = this.showAll.bind(this);
    this.hideAll = this.hideAll.bind(this);
  }

  showAll() {
    this.setState({ nArea: this.props.itemDetails.length })
  }

  hideAll() {
    this.setState({ nArea: DEFAULT_NUMBER_AREAS_DISPLAYED })
  }

  
  handleRemoveArea = (areaId) =>{
    const saveData={
      areaId:areaId
    }
   this.props.handleDeleteArea(saveData)
    }

  renderListArea(n) {
    return this.props.itemDetails.slice(0,n).map((area) => {
      return(
        <Chip label={area.profile_area_of_interest_id.name} onDelete={e => this.handleRemoveArea(area.id)} color="#0066b4" variant="outlined"/>  )
    })
  }
  renderListAreaPublic(n) {
    return this.props.itemDetailsPublic.slice(0,n).map((area) => {
      return(
        <Chip label={area.profile_area_of_interest_id.name}  color="#0066b4" variant="outlined"/>  )
    })
  }
  render() {

    return (
     
      <div className="bloc content-page-interne skills">
       {this.props.myProfile ?
           <div className=""  data-aos="fade-up">

                <div className="row">
                    <div className="col-md-6 col-6"><h2  className="pull-left"> {<IntlMessages id="areas"/>} </h2></div>
             
                     <div className="col-md-6 col-6">
                    <div className="pull-right">
                    <AddAreasForm/>
                    </div>
                 </div>
                </div>
                <div className="skill"  data-aos="fade-up">
                    <div className="col-md-12 col-12">

                  <div className="skills-endorsed"  data-aos="fade-up">
                   
                    <div className="row">
                      <div className="text-right col-md-12 col-12 padding-right-0"> 
                   
                    </div> 
                    </div>
                    <div className="col-md-12 col-12">
                      {this.props.itemDetails ? this.renderListArea(this.state.nArea) : null}
                      {this.props.itemDetails? 
                      this.props.itemDetails.length > 3 ?
                      this.state.nArea === DEFAULT_NUMBER_AREAS_DISPLAYED ?
                      <Button bsStyle="link" onClick={this.showAll} className="neutre">
                      {<IntlMessages id="see_all"/>}
                     </Button>
                    :
                      <Button bsStyle="link" onClick={this.hideAll} className="neutre">
                       {<IntlMessages id="hide"/>}
                      </Button>
                      :
                 null:null} 
              </div>
              </div>


              </div>
              </div>
        </div>
        :
        <div className="">
                <div className="row">
                    <div className="col-md-6 col-6"><h2  className="pull-left">  <IntlMessages id="areas"/></h2></div>
                </div>
                <div className="skill">
                    <div className="col-md-12 col-12">

                  <div className="skills-endorsed">
                    <div className="col-md-12 col-12">
                      {this.props.itemDetailsPublic ? this.renderListAreaPublic(this.state.nArea) : null}
                      {this.props.itemDetailsPublic? 
                      this.props.itemDetailsPublic.length > 3 ?
                      this.state.nArea === DEFAULT_NUMBER_AREAS_DISPLAYED ?
                      <Button bsStyle="link" onClick={this.showAll} className="neutre">
                      {<IntlMessages id="see_all"/>}
                     </Button>
                    :
                      <Button bsStyle="link" onClick={this.hideAll} className="neutre">
                       {<IntlMessages id="hide"/>}
                      </Button>
                      :
                 null:null} 
              </div>
              </div>
           
              </div>
              </div>
        </div>}</div>
   );
  }
}
function mapDispatchToProps(dispatch) {
    return {
        handleDeleteArea: (saveData) => dispatch(handleDeleteArea(saveData))
    }
    
}
function mapStateToProps(state) {
  return {
    itemDetails: state.profile.profile_areas.list,
    userId: state.users.userSettings.userId,
    
    axiosInstance: state.users.axiosInstance,
  }
}
export default   (connect(mapStateToProps, mapDispatchToProps) (ProfileAreas));
