
import React,{useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import UploadCV from '../uploadCV/UploadCV';
import CreateCompany from '../companies/CreateCompany';
import AddOpportunityNotAuth from '../opportunities/AddOpportunityNotAuth';
import { Container } from '@material-ui/core';
import Testimonies from './Testimonies';
import FeaturedOpportunities from './FeaturedOpportunities';
import {Link} from "react-router-dom";
import Typical from 'react-typical';
import {Animated} from "react-animated-css";
import LastUpdates from './LastUpdates';
import { Helmet } from 'react-helmet';
import Partners from './Partners';
import NewsLetters from './NewsLetters';
import { withTranslation } from 'react-i18next';
import IntlMessages from "util/IntlMessages";
import Simple from "./SlideShow"
import About from "./About"
import { CONTACT_FORM_ID } from 'config';
import { handleGetForm } from 'actions/forms/formsGenerator';
import { handleResetcreateCompany } from 'actions/companies/createCompany';
import {  addOpportunityResetMessages } from 'actions/opportunities';
import { handleResetuploadCvState } from 'actions/uploadCv';
/**
 * For icons, check the urls below, this choice is related to the template used
 */
// https://zavoloklom.github.io/material-design-iconic-font/examples.html
// https://zavoloklom.github.io/material-design-iconic-font/v1/
const useStyles = makeStyles((theme) => ({
    title:{
      paddingBottom:15,
      color:'#000',
      fontWeight:'bold'
    },
    btn_y:{
        backgroundColor:"#fdc000",
        color:'#000',
        '&:hover':{
          backgroundColor:"#fdc000",
          color:'#000',
        }
      },
      btn_w:{
        backgroundColor:"#fafafa",
        '&:hover':{
          backgroundColor:"#fafafa",
        },
        border:'black solid 2px'
      },
      main:{
          marginLeft:'12%',
          marginRight:'12%',
          [theme.breakpoints.down('xs')]:{
            marginLeft:'0%',
            marginRight:'0%'
          }
      },
      fieldset:{
        margin:0,
        padding:15,
        border:'solid 2px black',
      },
      legend:{
        display: 'block',
        fontSize: 15,
        width:'auto',
        color:"555"
      },
      cont:{
        paddingTop:30,
        paddingBottom:30,
        zIndex:1,
        background:"#9b9b9b",
      },
  }));
function Content({t}) {
  const classes = useStyles();
  const [open, setOpen]=React.useState(false)
  const [openompany, setopenCompany]=React.useState(false)
  const [openOpportunity, setopenOpportunity]=React.useState(false)
  const state =useSelector((state)=>state.users)
  const formStructure = useSelector(state=>state.formsGenerator.get_form);
  const dispatch = useDispatch()
const Test = <IntlMessages id="certified_opportunities_platform"/>;
  const handleOpenModal= ()=>{
    setOpen(true);
    
  }
  const handleClose=()=>{
    setOpen(false)
    dispatch(handleResetuploadCvState())
  }
  const handleCloseComp=()=>{
    setopenCompany(false)
    dispatch(handleResetcreateCompany())
  }
  const handleCloseOpp=()=>{
    setopenOpportunity(false)
    dispatch(addOpportunityResetMessages())
  }

  useEffect(()=>{
    dispatch(handleGetForm(CONTACT_FORM_ID))
  },[dispatch])
  
  

  console.log("SETTINGS Test", state.userSettings)
  return (
  <div className="">
      <Helmet>
      <title> Wutiko | Certified Opportunities Platform</title>
      <meta name="description" content="description" />
</Helmet>

        <Simple/>
        <div maxWidth={true} className="makeStyles-cont-111">
        <main className="container">
          <UploadCV open={open} handleClose={handleClose}/>
          <CreateCompany open={openompany} handleClose={handleCloseComp}  />
          <AddOpportunityNotAuth open={openOpportunity} handleClose={handleCloseOpp}/>
          <Grid container spacing={4}>
          <Grid item xs={12} md={6} xs={12} sm={6}>
          <Animated  animationIn="fadeInUp" animationOut="fadeOut">
                  <Card className="card-1">
                    <CardActions>
                    <fieldset className="bloc-home">
                    <legend className={classes.legend}>{<IntlMessages id="i_m_looking_for_an_opportunity"/>} </legend>
                    <Grid item container spacing={2}> 
                    <Grid item md={12} xs={12}  sm={12} container justify="flex-start">
                    </Grid>
                        <Grid item md={7} xs={12}  sm={6} container justify="flex-start" className="list-opp">
                        <Link to="/opportunities">
                        <Button fullWidth variant="contained" className={classes.btn_y} color="warning" >{<IntlMessages id="seize_an_opportunity"/>} </Button>
                        </Link>
                        </Grid>
                        <Grid item md={5} xs={12}  sm={6} container justify="flex-end">
                        <Button onClick={handleOpenModal} fullWidth variant="contained" className="transp" justify="center">{<IntlMessages id="upload_my_cv"/>}</Button>
                        </Grid>
                    </Grid>
                    </fieldset>
                    </CardActions>
                  </Card>
                </Animated>
            </Grid>

        <Grid item xs={12} md={6} xs={12} sm={6}>
          <Animated  animationIn="fadeInUp" animationOut="fadeOut">
            <Card className="card-2"> 
                <CardActions>
                  <fieldset className="bloc-home">
                    <legend className={classes.legend}>{<IntlMessages id="i_m_providing_an_opportunity"/>} </legend>
                    <Grid item container spacing={2}> 
                    <Grid item md={12} xs={12} container justify="flex-start">
                    </Grid>
                        <Grid item md={5} xs={12} sm={6} container justify="flex-start">
                        <Button onClick={()=>setopenCompany(true)} fullWidth variant="contained"  className="transp" color="warning"> {<IntlMessages id="create_my_page"/>}</Button>
                        </Grid>
                        <Grid item md={7} xs={12} sm={6} container justify="flex-end">
                            <Button onClick={()=>setopenOpportunity(true)} fullWidth variant="contained" className="btn_y" justify="center">{<IntlMessages id="post_an_opportunity"/>}</Button>
                        </Grid>
                    </Grid>                    
                  </fieldset>
                </CardActions>
            </Card>
        </Animated>
        </Grid>
        </Grid>
        </main>
    </div>
    <FeaturedOpportunities/> 
    <About/>
     <LastUpdates/> 
    <Partners/>
    <Testimonies/>
    <NewsLetters/> 
    </div>
  );
}

export default (Content)



