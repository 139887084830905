import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { CLIENT_ID } from "config";
import { SaveOpportunitySuccess, SaveOpportunityError} from "actions/opportunities";

const SaveOpportunity = async (instance, opportunity_id)=>
    await instance.post(`/opportunities/${opportunity_id}/profile-favorite/add/`, {})
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })
function* SaveOpportunityAction(actions){
        const state = yield select((state)=>state.users);
        let axiosInstance;
        if(state.authenticated){
            axiosInstance=state.axiosInstance
        }else{
            axiosInstance=state.blankAxiosInstance
            console.log("Test state", state)
        }
        const data =yield call(SaveOpportunity,axiosInstance, actions.data.opportunityId);
        if(data.message){
            yield put({type:"HANDLE_GET_OPPORTUNITY_DETAILS", slug:actions.data.slug})
            yield put(SaveOpportunitySuccess(data.message));
        }else{
            console.log("data.error", data, data.error)
            yield put(SaveOpportunityError(data.error));
        }
        
    }
function* HandleSaveOpportunity(){
    
    yield takeEvery("HANDLE_SAVE_OPPORTUNITY", SaveOpportunityAction)
}
export default function* rootSaga() {
    yield all([fork(HandleSaveOpportunity)]);
}