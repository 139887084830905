import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { deleteLangueSuccess, deleteLangueError } from  "actions/profile";

const DeleteLangueRequest = async (instance,data)=>
    await instance.put(`/profiles/languages/${data.langueId}/remove/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* DeleteLangue(actions){
    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(DeleteLangueRequest,axiosInstance, actions.data);
    if(data){

    console.log("Delete" , "data.message")
        yield put(deleteLangueSuccess(data.message));
        yield put({type:"HANDLE_GET_PROFILE_LANGUE"})
        console.log("HANDLE_GET_PROFILE_LANGUE")
    }else{
        yield put(deleteLangueError(data.error));
    }
    
}
export function* handleDeleteLangue(){
    console.log("Delete" , "Delete Langue")
    yield takeEvery("HANDLE_DELETE_LANGUE", DeleteLangue)
}
export default function* rootSaga() {
    yield all([fork(handleDeleteLangue)]);
}
//