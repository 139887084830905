import React from 'react';
import { Link } from 'react-router-dom'; 
import { translate } from 'react-i18next';
import { Parser } from 'html-to-react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
//import AddExperience from 'pages/AddExperience';
import AddExperience from 'containers/Profil/AddExperience';
// import  EditExperience  from "../../../pages/ExperienceForm";
import IntlMessages from "util/IntlMessages";

const DEFAULT_NUMBER_POSITION_DISPLAYED = 2;

class ProfileExperience extends React.Component{

    constructor(props) {
    super(props);
    this.state = { nPositions: DEFAULT_NUMBER_POSITION_DISPLAYED };
    this.showAllPosition = this.showAllPosition.bind(this);
    this.hideAllPosition = this.hideAllPosition.bind(this);
  }

  showAllPosition() {
    this.setState({ nPositions: this.props.itemDetails.length })
  }

  hideAllPosition() {
    this.setState({ nPositions: DEFAULT_NUMBER_POSITION_DISPLAYED })
  }

  renderListPosition(n) {
        var parser = new Parser();
        const { t } = this.props;
  return this.props.itemDetails.slice(0,n).map((position) => {
      return(
      <div key={position.position_id.id}>
      {this.props.myProfile ?
       <div className="row experience"  data-aos="fade-up">
         <div className="col-md-2 col-3 text-center icon-experience">
         {position.organization_id?
         <Link to={"/"+position.organization_id.slug}><img width="90" height="85" className="img-funding" src={position.organization_id.logo ?position.organization_id.logo:'https://static.wutiko.com/back/static/default/default_wutiko_2x.png'} alt="me" /></Link>
         :null}
         </div>
         <div className="col-md-10 col-9">
         <div className="header-experience row">
           <div className="pull-left col-md-10 col-10">
                  <div><span className="title">{position.position_id.title}</span></div>
                  <div>
                    {position.organization_id?
                    <Link className="company-experience"  to={"/companies/"+position.organization_id.slug}>{position.organization_id.name}</Link>
                    :null}</div>
                  <div><span className="date-experience">{position.start_date}  {position.is_current === "true" ? "- present" : position.end_date ? '- '+position.end_date : null}</span></div>
                  <div><span className="location-date">
                  {position.city_id ?
                    position.city_id.name + ', ' + position.city_id.state_province_id.name + ', ' + position.city_id.state_province_id.country_id.name : 
                    position.state_province_id ? position.state_province_id.name + ', ' + position.state_province_id.country_id.name : null
                  }</span></div>
            </div>
           
            <div className="text-right col-md-2 col-2">
             <AddExperience positionId={position.id} position={position}  add={"edit"} className="pull-right edit"/>  
             </div>
         </div>
            <div className="body-experience">
              {position.description?
              <div className="description col-md-12 col-12">
                    {<IntlMessages id="description"/>}:
                  <p>{parser.parse(position.description)}</p>
              </div>
              :null}
              {position.accomplishments?
              <div className="description col-md-12 col-12">
                    {<IntlMessages id="accomplishments"/>}:
                  <p>{parser.parse(position.accomplishments)}</p>
              </div>
              :null}
              {position.responsibilities?
              <div className="description col-md-12 col-12">
                {<IntlMessages id="responsibilities"/>}:
                  <p>{parser.parse(position.responsibilities)}</p>
              </div>
              :null}
          </div>
         </div>
       </div>
       :<div className="row experience">
         <div className="col-md-2 col-3 text-center icon-experience">
         <Link to={"/companies/"+position.organization_id.slug}  ><img width="70" height="60" className="img-funding" src={position.organization_id? position.organization_id.logo :'https://static.wutiko.com/back/static/default/default_wutiko_2x.png'} alt="me" /></Link></div>
         <div className="col-md-10 col-9">
         <div className="header-experience row">
           <div className="pull-left col-md-10 col-10">
                  <div><span className="title">{position.position_id.title}</span></div>
                  <div><Link className="company-experience"  to={"/companies/"+position.organization_id.slug}  >  {position.organization_id.name}</Link></div>
                  <div><span className="date-experience">{position.start_date}  {position.is_current === "true" ? "- present" : position.end_date ? '- '+position.end_date : null}</span></div>
                  <div><span className="location-date">
                  {position.city_id ?
                    position.city_id.name + ', ' + position.city_id.state_province_id.name + ', ' + position.city_id.state_province_id.country_id.name : 
                    position.state_province_id ? position.state_province_id.name + ', ' + position.state_province_id.country_id.name : null
                  }</span></div>
            </div>
         
         </div>
           <div className="body-experience">
              {position.description?
              <div className="description col-md-12 col-12">
                     {<IntlMessages id="description"/>}:
                  <p>{parser.parse(position.description)}</p>
              </div>
              :null}
              {position.accomplishments?
              <div className="description col-md-12 col-12">
                     {<IntlMessages id="accomplishments"/>}:
                  <p>{parser.parse(position.accomplishments)}</p>
              </div>
              :null}
              {position.responsibilities?
              <div className="description col-md-12 col-12">
                     {<IntlMessages id="responsibilities"/>}:
                  <p>{parser.parse(position.responsibilities)}</p>
              </div>
              :null}
          </div>
         </div>
       </div>
     } </div>
      )
    })
  }
/*
"description": "Je suis Ingenieur Je suis Ingenieur",
"accomplishments": "",
"responsibilities": "Je suis Ingenieur Je suis Ingenieur",
*/
render(){
    const { t } = this.props;
    return (
       <div className="bloc content-page-interne experience">

                <div className="row">
                    <div className="col-md-6 col-6">
                    <h2 className="text-left title">{<IntlMessages id="experiences"/>}</h2></div>
                    {this.props.myProfile ?
                    <div className="text-right col-md-6 col-6">
                    {/* <Link className='btn' to='/profile/add-experience/' activeClassName="active" className="pull-right">
                      <i className="fa fa-plus f-5x"> Add</i>
                      
                    </Link> */}
                    <AddExperience  add={"add"} className="pull-right add" /> 
                    </div>

                  : null}
                </div>

            {this.props.myProfile ?   
            
              <div> {this.props.itemDetails ? this.renderListPosition(this.state.nPositions) : null}
                 
                {this.props.itemDetails.length > 2 ?
                
                this.state.nPositions === DEFAULT_NUMBER_POSITION_DISPLAYED ?
                <Button bsStyle="link"  className="items-pending text-center col-md-12" onClick={this.showAllPosition}>
                  {<IntlMessages id="see_more"/>}
               </Button>
              :
                <Button bsStyle="link"  className="items-pending text-center col-md-12" onClick={this.hideAllPosition}>
                 {<IntlMessages id="hide"/>}
                </Button>
                  : null}
                </div>
              :
               this.props.itemDetails ? this.renderListPosition(this.props.itemDetails.length) : null}
            </div>
   );
  }
}
function mapStateToProps(state) {
  return {
    userId: state.user.userId
  }
}
export default connect(mapStateToProps)(withTranslation('translation')(ProfileExperience));