import React,{useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FeaturedOpportunities from 'components/uielements/FeaturedOpportunities';
import {useParams} from 'react-router-dom'
import {useDispatch,useSelector} from 'react-redux'
import { handleGetopportunityDetails, handleResumeCheck,saveOpportunityResetMessages, resumeCheckResetMessages, HandleSaveOpportunity, HandleApplyOpportunity, ApplyOpportunityResetMessages, SaveOpportunitySuccess, handleGetEditOpportunityDetails } from 'actions/opportunities';
import FullWidthTabs from 'components/FullWidthTabs';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import IconButton from '@material-ui/core/IconButton';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LaunchIcon from '@material-ui/icons/Launch';
import { Button, Grid} from '@material-ui/core';
import HorizontalItemCardCustom from 'components/dashboard/Common/HorizontalItemCardCustom';
import GetAppIcon from '@material-ui/icons/GetApp';
import HorizontalItemCardCustomShare from 'components/dashboard/Common/HorizontalItemCardCustomShare';
import HoriOpportunity from './HoriOpportunity';
import { handleOpportunitiesSearch } from 'actions/search';
import {Alert} from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import {createMuiTheme} from "@material-ui/core/styles";
import ShareOpportunity from './ShareOpportunity';
import {Link} from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import AlertDialog from "components/uielements/Alert"
import UploadCV from 'components/uploadCV/UploadCV';
import AppForm from 'components/uploadCV/AppForm';
import Notif from 'components/uielements/Notification';
import BrowserLimitations from './BrowserLimitations';
import ReactPaginate from 'react-paginate';
import CustomScrollbars from 'util/CustomScrollbars';
import EditOpportunities from 'pages/EditOpportunities';
import { Parser } from 'html-to-react';
import ApplicationForm from 'components/formsElements/ApplicationForm';
import {createFormResetMessages} from 'actions/forms/createForm';

// import InfiniteScroll from 'react-infinite-scroller';
const useStyles = makeStyles((theme) => ({
    cont:{
      paddingTop:0,
      paddingBottom:0,
      zIndex:1,
      background:"#fdc000"
    },
    title:{
      paddingBottom:15,
      color:"#000",
      fontWeight:'bold'
    },
    content:{
        paddingLeft:24,
        paddingRight:24,
        marginTop:15,
    },
    fieldset:{
        margin:0,
        padding:10,
        border:'solid 2px black',
      },
      legend:{
        display: 'block',
        fontSize: 15,
        width:'auto'
      },
    textAlignEnd:{
      textAlign:'end'
    },
    textAlignStart:{
      textAlign:'start'
    },
    textAlignCenter:{
      textAlign:'center'
    },
    margin:{
      margin:'7px !important'
    },
    icon:{
      width:70,
      height: 84,
    },
    upload:{
      backgroundColor:"#1269ea",
      color:"#fff"
    },
    isloadingOpp:{
      opacity:0.2,
      pointerEvents: 'none',
    },
    saved:{
      color:"green"
    },
    buttonProgress: {
      color: "#fdc000",
      position: 'absolute',
      top: '30%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    input: {
      display: 'block',
    },
  }));
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#fdc000",
        backgroundColor: green[500],
      },
      secondary: {
        main: "#fdc000",
        backgroundColor: "fdc000"
      },  
    },
  });


function OpportunityDetails({ match }){
    const classes = useStyles();
    const {slug} =useParams();
    const DetailsOpportunityEdit = useSelector(state=>state.opportunities.get_edit_opportunity.details)
    const opportunity = useSelector(state=>state.opportunities.get_opp_details)
    const state = useSelector(state=>state.opportunities.all_opportunities);
    const spinnerList = useSelector(state=>state.opportunities.all_opportunities.spinner);
    const save_opportunity =useSelector(state=>state.opportunities.saveOpportunity)
    const apply_opportunity =useSelector(state=>state.opportunities.applyOpportunity)
    const opportunities=state.list.opportunities?state.list.opportunities:[];
    const count=state.count?state.count:"1";
    const [openOpportunity, setopenOpportunity]=React.useState(false)
    const  authenticated =useSelector(state=>state.users)
   const isAuthenticated = authenticated? authenticated.authenticated : null;
    const resumeCheck=useSelector(state=>state.opportunities.resume_check)
    const [fileState, setState]= useState({})
    const dispatch = useDispatch()
    const [opencv, setOpencv]=React.useState(false)
    const [open, setOpen]=React.useState(false)
    const [openAppform, setOpenAppform]=React.useState(false)
    
    const [currentPage, setcurrentPage] = React.useState(1);
    const {width} = useSelector(({settings}) => settings);
    const RESULTS_PER_PAGE = 12;
    const saveData={
      slug:slug,
      opportunityId:opportunity.details? opportunity.details.id : null
    }
    const spinner=apply_opportunity.status;
    const loader =  <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />
   
    const handleChangeFile=(event)=>{
      event.preventDefault();
      let formData = new FormData(); 
      const fileD=event.target.files[0];
      formData.append("datafile",event.target.files[0]);
      dispatch(handleResumeCheck({opportunityId:opportunity.details?opportunity.details.id:'',fileData:formData}))
      setOpen(true)
    }


    useEffect(()=>{
      dispatch(handleGetEditOpportunityDetails(slug));
      dispatch(handleGetopportunityDetails(slug));
      dispatch(handleOpportunitiesSearch({scope:"opportunity", search:`${window.location.search}`, page:"1"}))
    },[dispatch]);


    const handleCloseAppForm=()=>{
      setOpenAppform(false)
      dispatch(createFormResetMessages())
    }
    const handlePageClick=(newPage) =>{
      dispatch(handleOpportunitiesSearch({scope:"opportunity",search:`${window.location.search}`, page:`${newPage.selected + 1}`}));
      setcurrentPage(newPage.selected + 1)
    } 
    var parser = new Parser();

    let pageCount;
    let onPageChange;
    pageCount = Math.ceil(count / RESULTS_PER_PAGE);
    onPageChange = handlePageClick;


    const handleCloseNotif=()=>{
      setOpen(false)
      dispatch(resumeCheckResetMessages())
      }
    console.log("isAuthenticated test" , isAuthenticated)
    return (
        <>
        <Container maxWidth={false} className={classes.cont}>
      
          {/* <Typography 
                component="h1"
                variant="h5"
                color="000"
                align="center"
                noWrap
                className="detail-opportunites">
                    {opportunity.details?opportunity.details.title:null}
          </Typography> */}
          </Container>
        <div className={`animated slideInUpTiny detail-opportunity animation-duration-3 container ${classes.content}`}>
        <div className="row">
          <div className="col-md-4 col-sm-5 col-12 animation slideInLeft sidebar-opportunities d-none d-md-block d-lg-block">
          <CustomScrollbars className="module-side-scroll scrollbar"
                            style={{height: width >= 1200 ? 'calc(200vh - 200px)' : 'calc(150vh - 80px)'}}>
            <div className="sidebar">
              {
                spinnerList?
                  <Grid container item justify="center" xs={12}><CircularProgress size={50}/></Grid>:
               
                opportunities.map(item=>{
                  return(
                    <HoriOpportunity data={item} isActive={slug===item.slug}/>
                  )
                }
                )
              }
              

            {/* <InfiniteScroll
              pageStart={0}
              loadMore={loadItems}
              hasMore={this.props.hasMoreItems}
              loader={loader}>
              
              <div className="content-page-interne Professionals funding">
               <small className="title-result">{t('wutiko.showing_count_results' , {count : count})}</small>
                  <div className="" data-aos="fade-up">
                      {opportunities.length !== 0 ?
                      
                        opportunities.map(item=>{
                          return(
                            <HoriOpportunity data={item} isActive={slug===item.slug}/>
                          )
                        })
                      
                          :
                          null
                        }
                    </div>
              </div>
          </InfiniteScroll> */}
            <Grid item md={12} xs={12} container justify="center" className="Listpagination">
                   <ReactPaginate
                          previousLabel="←"
                          nextLabel="→"
                          pageCount={pageCount}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          previousLinkClassName={"pagination__link"}
                          nextLinkClassName={"pagination__link"}
                          disabledClassName={"pagination__link--disabled"}
                          activeClassName={"pagination__link--active"}
                        /> </Grid>
            </div>
            </CustomScrollbars>
          </div>
          {/* =========================DISPLAY OPPORTUNITY======================================== */}
         
          {/* <div className={`opportunity-detail col-md-8 col-sm-12 col-12 animation slideInRight    
          ${opportunity.spinner?
            classes.isloadingOpp:
            ''}
            `
          }
          > */}

         
          <div className={`opportunity-detail col-md-8 col-sm-12 col-12 animation slideInRight`}> 
          {opportunity.spinner?
            //<CircularProgress size={50}/>
            <CircularProgress size={50} className={classes.buttonProgress} />: 
          <>
          <div className="opportunity-detail-scrol">
          <Notif open={open} title={<IntlMessages id="notification"/>} text={
          resumeCheck.error?
          resumeCheck.error :
          resumeCheck.data.message?resumeCheck.data.message:null}
          notification="notification"
          error={resumeCheck.error? resumeCheck.error : null}
          message={resumeCheck.data.message ? "message" : null}
          handleClose={handleCloseNotif}/>
         
         <AppForm open={openAppform}  handleClose={handleCloseAppForm}/>
          <UploadCV open={opencv} handleClose={()=>setOpencv(false)} 
          opportunity_id={opportunity.details?opportunity.details.id:null}/>

                {save_opportunity.message?<Alert severity="success" onClose={()=>dispatch(SaveOpportunitySuccess())}>{save_opportunity.message ?save_opportunity.message : null}</Alert>:null}
                {save_opportunity.error?<Alert severity="error" onClose={()=>dispatch(saveOpportunityResetMessages())}>{save_opportunity.error}</Alert>:null}
                {apply_opportunity.message?<Alert severity="success" onClose={()=>dispatch(ApplyOpportunityResetMessages())}>{apply_opportunity.message}</Alert>:null}
                {apply_opportunity.error?<Alert severity="error" onClose={()=>dispatch(ApplyOpportunityResetMessages())}>{apply_opportunity.error}  
                {/* {apply_opportunity.click_to_upload_your_cv} */}
                </Alert>:null}
               

                {/* {apply_opportunity.error?
                <Alert severity="error" onClose={()=>dispatch(saveOpportunityResetMessages())}>{opportunity.details? opportunity.details.can_apply.message : null}
                </Alert>:null} */}
           
            <div className="row">
                <div className="col-12 mobile d-xl-none d-md-none">
                  <div className="row">
                  <div className="col-sm-3 col-3 animation slideInLeft">
                      {opportunity.details?
                      <span className="opportunite-img">
                         <Link to={`/companies/${opportunity.details.organization_id?
                        opportunity.details.organization_id.slug:null}`}><img
                          src={opportunity.details?opportunity.details.organization_id.logo:null}
                          alt="Articales Post"
                        /></Link> 
                      </span>:null}
                  </div>
                  <div className="col-sm-9 col-9 animation slideInRight">
                      <div className="row"> 
                      {opportunity.details?
                          <div className="col-md-8 col-sm-12 col-12 animation slideInLeft">
                               <h4 className="title-opportunity"> {opportunity.details?opportunity.details.title:null}</h4>
                               <Link  to={`/companies/${opportunity.details.organization_id?
                                opportunity.details.organization_id.slug:null}`} className="title-company">
                                {opportunity.details.organization_id?opportunity.details.organization_id.name:null}</Link> 
                          </div>:null}
                      </div>
                      </div>
                    </div>
                    </div>
                    <div className="col-12 mobile-btn d-xl-none d-md-none"> 

                  {
                    isAuthenticated ?
                  
                    <div className="col-sm-12 col-12 animation slideInRight top-20">
                          {opportunity.details?
                          <div>
                          {opportunity.details.has_applied_to_this_opportunity !== true?
                          <div> 
                            <Button fullWidth className="btn-apply" variant="contained" color="primary" onClick={()=>dispatch(HandleApplyOpportunity(saveData))}> 
                            {<IntlMessages id="apply_now"/>} 
                            </Button>
                            {spinner === "creating" ?
                          loader : null}
                          </div>
                            : 
                            <Button fullWidth variant="contained" color="secondary" disabled>
                               {<IntlMessages id="you_have_already_applied_to_this_job_opportunity"/>} 
                              </Button>
                          }</div> :null}  
                          </div>
                          :
                          <div className="col-sm-12 col-12 animation slideInRight top-20">
                            {/* <AlertDialog title={<IntlMessages id="apply_now"/>} text={opportunity.details? opportunity.details.can_apply.message:null}
                          login={<IntlMessages id="login"/>} signup={<IntlMessages id="singup"/>}/> */}
                           <Button className="applyNotconnect col-md-12  col-12 animation slideInRight text-right" onClick={()=>setOpencv(true)}>{<IntlMessages id="apply_now"/>}</Button>
                            </div>
                        }


                    
                          <div className="col-sm-12 col-12 animation slideInLeft">
                          {
                            isAuthenticated ?

                          <div className="row">
                               <div className="col-md-6 col-sm-6 col-6 animation slideInLeft">
                               {opportunity.details ?
                              <div>
                               {opportunity.details.has_this_opportunity_as_fovorite === true?
                               <p className="text-grey mb-3" onClick={()=>dispatch(HandleSaveOpportunity(saveData))}>
                                <IconButton variant="contained" color="primary"><FavoriteBorderIcon/></IconButton>{<IntlMessages id="save"/>}
                              </p>:
                               <p className="text-grey mb-3" onClick={()=>dispatch(HandleSaveOpportunity(saveData))}>
                               <IconButton><FavoriteBorderIcon/></IconButton>{<IntlMessages id="save"/>}
                               </p>}
                               </div>
                                : null
                                }
                               </div>
                               <div className="col-md-6 col-sm-6 col-6 animation slideInRight">
                               <p className="text-grey mb-1">
                                 <ShareOpportunity open={openOpportunity} handleClose={()=>setopenOpportunity(false)} opportunityId={opportunity.details? opportunity.details.id : null}/>
                              </p>
                               </div>
                            </div>
                            :
                            <div className="row opp">
                           <div className="col-md-12 col-sm-12 col-12 SaveAndShare">
                                  <div className="col-sm-6  col-6">
                                   <AlertDialog btnSave={<IntlMessages id="save"/>} title={<IntlMessages id="save"/>} text={opportunity.details? opportunity.details.can_apply.message:null}
                                   login={<IntlMessages id="login"/>} signup={<IntlMessages id="signup"/>}/>
                                   </div>
                                  <div className="col-sm-6 col-6 text-right">
                                  <AlertDialog btnShare={<IntlMessages id="email"/>} title={<IntlMessages id="email"/>} text={opportunity.details? opportunity.details.can_apply.message:null}
                                  login={<IntlMessages id="login"/>} signup={<IntlMessages id="signup"/>}/>
                            </div>
                           </div>
                           </div>
                          }</div>
                          
                          
                  </div>
                  <div className="col-md-2 col-sm-3 col-3 animation slideInLeft d-none d-sm-none d-md-block d-lg-block">
                    {opportunity.details?
                   <span className="opportunite-img">
                       <Link to={`/companies/${opportunity.details.organization_id.slug}`}><img
                          src={opportunity.details?opportunity.details.organization_id.logo:null}
                          alt="Articales Post"/>
                        </Link> 
                      </span>:null}
                  </div>
                    <div className="col-md-10 col-sm-9 col-9 animation slideInRight d-none d-sm-none d-md-block d-lg-block">
                      <div className="row"> 
                      {opportunity.details?
                          <div className="col-md-8 col-sm-12 col-12 animation slideInLeft">
                               <Link to="#"> 
                               <h4 className="title-opportunity">{opportunity.details?opportunity.details.title:null}</h4></Link>
                               <Link to={`/companies/${opportunity.details.organization_id.slug}`} className="title-company">{opportunity.details?opportunity.details.organization_id.name:null}</Link>
                          </div> :null}
                      </div>
                      <div className="row"> 
                        {isAuthenticated ?
                          <div className="col-md-7 col-sm-12 col-12 animation slideInLeft">
                          <div className="row">
                               <div className="col-md-6  col-sm-6 col-6 animation slideInLeft">
                               {
                              opportunity.details ?
                              <div>
                               {opportunity.details.has_this_opportunity_as_fovorite === true?
                               <p className="text-grey mb-3" onClick={()=>dispatch(HandleSaveOpportunity(saveData))}>
                                <IconButton variant="contained" color="primary"><FavoriteBorderIcon/></IconButton>{<IntlMessages id="save"/>}
                              </p>:
                               <p className="text-grey mb-3" onClick={()=>dispatch(HandleSaveOpportunity(saveData))}>
                               <IconButton><FavoriteBorderIcon/></IconButton>{<IntlMessages id="save"/>}
                               </p>}
                               </div>
                                : null}
                               </div>
                               <div className="col-md-6 col-sm-6 col-6 animation slideInRight">
                                <p className="text-grey mb-1">
                                 <ShareOpportunity open={openOpportunity} handleClose={()=>setopenOpportunity(false)} opportunityId={opportunity.details? opportunity.details.id : null}/>
                                </p>
                               </div>
                            </div>
                          </div>:
                           <div className="col-md-7 col-sm-7 col-12 row opp">
                           <div className="col-md-12 col-sm-12 col-12 SaveAndShare">
                              <div className="col-md-6 enregistrer col-sm-6 col-12">
                                 <AlertDialog btnSave={<IntlMessages id="save"/>} title={<IntlMessages id="save"/>} text={opportunity.details? opportunity.details.can_apply.message:null}
                                   login={<IntlMessages id="login"/>} signup={<IntlMessages id="signup"/>}/>
                                  </div>
                             <div className="col-md-6 col-sm-6 col-12">
                               <AlertDialog btnShare={<IntlMessages id="email"/>} title={<IntlMessages id="email"/>} text={opportunity.details? opportunity.details.can_apply.message:null}
                                   login={<IntlMessages id="login"/>} signup={<IntlMessages id="signup"/>}/>
                             </div>
                           </div>
                         
                          </div>}
                          
                          
                         
                          {  
                          opportunity.details?

                          !isAuthenticated?
                          <div className="col-md-5 col-sm-5 col-12 animation slideInRight opp">
                          {opportunity.details.has_application_form ?
                          <Button fullWidth className="btn-apply" variant="contained" color="primary" onClick={()=>setOpenAppform(true)}> 
                          {<IntlMessages id="apply_now"/>} 
                          </Button>
                           :
                          <Button className="applyNotconnect col-md-12 animation slideInRight text-right" onClick={()=>setOpencv(true)}>{<IntlMessages id="apply_now"/>}</Button>
                         
                          }
                          </div>
                          :

                          opportunity.details.is_admin?
                          <div className="col-md-5 text-right">
                          <EditOpportunities 
                          itemDetailsOpportunity={DetailsOpportunityEdit?DetailsOpportunityEdit:null}
                          slug={opportunity.details.slug} /></div>:
                          <div className="col-md-5 col-sm-5 col-12 animation slideInRight ">
                          {opportunity.details?
                          <div>
                          {
                          
                          opportunity.details.has_applied_to_this_opportunity !== true?
                          opportunity.details.has_application_form?
                          <Button fullWidth className="btn-apply" variant="contained" color="primary" onClick={()=>setOpenAppform(true)}> 
                          {<IntlMessages id="apply_now"/>} 
                          </Button>:
                          <div> 
                            <Button fullWidth className="btn-apply" variant="contained" color="primary" onClick={()=>dispatch(HandleApplyOpportunity(saveData))}> 
                            {<IntlMessages id="apply_now"/>} 
                            </Button>
                            {spinner === "creating" ?
                          loader : null}
                          </div>
                            : 
                            <Button fullWidth variant="contained" color="secondary" disabled  onClick={()=>dispatch(HandleApplyOpportunity(saveData))}>
                               {<IntlMessages id="you_have_already_applied_to_this_job_opportunity"/>} 
                              </Button>
                          }
                          </div>
                          :null}
                       </div>
                       
                     :null}
                      
                      </div>
                    </div>
            </div>

         













           
            {opportunity.details?
             opportunity.details.is_admin?null:
             isAuthenticated?
             <div className="roww">
               <div className="col-md-2 score">
                 {opportunity.details.matching_score >0? opportunity.details.matching_score:"0"} / 100<br></br>  
                <span><IntlMessages id="matching_score"/></span> 
               </div>
            <div className="progress col-md-10">
                 <div className="progress-bar progress-bar-striped bg-info " role="progressbar" aria-valuenow={`${opportunity.details.matching_score}%`}
              aria-valuemin="0" aria-valuemax="100" style={{width:`${opportunity.details.matching_score}%`}}>
              {opportunity.details.matching_score >0? opportunity.details.matching_score:"0"}%  
              </div>
            </div>
            </div>:null
            :null}
            <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <FullWidthTabs width={width} tab1={<IntlMessages id="details"/>} tab2={<IntlMessages id="highlight"/>}  
                  tab3={<IntlMessages id="company"/>} opportunity={opportunity.details}
                  latitude={opportunity.details? opportunity.details.organization_id.latitude:""} longitude={opportunity.details?opportunity.details.organization_id.longitude:""}/>
                </div>                  
            </div>

          
                <div className="row  col-md-12 left-15">
                  <div className="col-md-5 col-sm-5 col-12">
                  <p className="text-grey mb-3">
                    Repport this opportunity <IconButton><LaunchIcon/></IconButton>
                  </p> 
                  </div>
                  {  
                   isAuthenticated ?
                 
                   <div className="col-md-7 col-sm-7 col-12 animation slideInLeft SaveAndShare-opp">
                    <div className="col-md-4 col-sm-2 col-4 animation slideInLeft">
                    <p className="text-grey mb-3">
                    {opportunity.details ?
                              <div>
                               {opportunity.details.has_this_opportunity_as_fovorite === true?
                               <p className="text-grey mb-3" onClick={()=>dispatch(HandleSaveOpportunity(saveData))}>
                                <div className="d-none d-sm-none d-md-block d-lg-block">
                                <IconButton variant="contained" color="primary"><FavoriteBorderIcon/></IconButton>{<IntlMessages id="save" />}
                                </div>
                                <div className="d-none d-sm-none  d-xl-none d-md-none">
                                <IconButton variant="contained" color="primary"><FavoriteBorderIcon/></IconButton>
                                </div>
                              </p>:
                               <p className="text-grey mb-3" onClick={()=>dispatch(HandleSaveOpportunity(saveData))}>
                                  <div className="d-none d-sm-none d-md-block d-lg-block">
                               <IconButton><FavoriteBorderIcon/></IconButton>{<IntlMessages id="save" />}
                               </div>
                               <div className="d-sm-none  d-xl-none d-md-none d-lg-none">
                               <IconButton><FavoriteBorderIcon/></IconButton>
                               </div>
                              </p>
                               }</div>
                             : null
                            }
                  </p>
                    </div>
                    <div className="col-md-4 col-sm-2 col-4 animation slideInRight">
                    <p className="text-grey mb-1">
                    <ShareOpportunity open={openOpportunity} handleClose={()=>setopenOpportunity(false)} opportunityId={opportunity.details? opportunity.details.id : null}/>
                    </p>
                    </div>
                  <div className="col-md-4 col-sm-4 col-4">

                      {opportunity.details?
                        opportunity.details.is_admin?
                        <div className="text-right">
                        <EditOpportunities icon="icon"
                                itemDetailsOpportunity={DetailsOpportunityEdit?DetailsOpportunityEdit:null}
                                slug={opportunity.details.slug}
                                skills={DetailsOpportunityEdit? DetailsOpportunityEdit.skills:[]}/>
                               
                        </div>
                        :
                          <div>
                          {opportunity.details.has_applied_to_this_opportunity !== true?
                          <div> 
                            <Button fullWidth variant="contained" color="primary" onClick={()=>dispatch(HandleApplyOpportunity(saveData))}><IntlMessages id="apply_now"/></Button>
                            {spinner === "creating" ?
                          loader : null}
                          </div>
                            : 
                            <Button fullWidth variant="contained" color="secondary" disabled  onClick={()=>dispatch(HandleApplyOpportunity(saveData))}>
                              {/* {<IntlMessages id="you_have_already_applied_to_this_job_opportunity"/>} */}
                              <IntlMessages id="apply_now"/>
                              </Button>
                          }</div> :null
                          }
                  </div></div>
                  
                  :
                 

                <div className="col-md-7 col-sm-7 col-12 row opp">
                  <div className="col-md-8 col-sm-8 col-12 SaveAndShare">
                     <div className="col-md-6 enregistrer col-sm-6 col-12">
                        <AlertDialog btnSave={<IntlMessages id="save"/>} title={<IntlMessages id="save"/>} text={opportunity.details? opportunity.details.can_apply.message:null}
                          login={<IntlMessages id="login"/>} signup={<IntlMessages id="signup"/>}/>
                         </div>
                    <div className="col-md-6 col-sm-6 col-12">
                      <AlertDialog btnShare={<IntlMessages id="email"/>} title={<IntlMessages id="email"/>} text={opportunity.details? opportunity.details.can_apply.message:null}
                          login={<IntlMessages id="login"/>} signup={<IntlMessages id="signup"/>}/>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-12 BtnNotconnect">
                    <Button className="applyNotconnect  animation slideInRight text-right" onClick={()=>setOpencv(true)}>{<IntlMessages id="apply_now"/>}</Button>
                 </div>
                 </div>
                  }
            </div>
          
            <div className="row  col-md-12 left-15 resume">
             
              <div className={`col-xl-12 col-sm-12 bloc- ${resumeCheck.spinner?classes.isloadingOpp:null}`}>
             <div className="col-md-12">
{/*              
              {resumeCheck.data.message?<Alert severity="success" onClose={()=>dispatch(resumeCheckResetMessages())}>{resumeCheck.data.message}</Alert>:null}
                {resumeCheck.error?<Alert severity="error" onClose={()=>dispatch(resumeCheckResetMessages())}>{resumeCheck.error}</Alert>:null}
                 */}
                </div>
                <HorizontalItemCardCustom
                  styleTitle="mb-4"
                  styleName={classes.upload}
                  styleName="p-4"
                  title={<IntlMessages id="resume_check"/>}
                  detail={<IntlMessages id="resume_check_body"/>}
                  chartPosition="align-self-end"
                >
                <form enctype="multipart/form-data">
                <label htmlFor="icon-button-file">
                      {resumeCheck.spinner?loader:
                 <BrowserLimitations opportunity_id={opportunity.details?opportunity.details.id:null}
                 handleChangeFile={handleChangeFile} />
                 }
                   
                  </label>

                  
            
        
                </form> 
                {fileState.current_file} 
                </HorizontalItemCardCustom>
              </div>

              {/* <div className="col-xl-6 col-sm-12 bloc-opportunities">
                <HorizontalItemCardCustomShare
                  styleTitle="mb-4"
                  styleName="p-4"
                  title={<IntlMessages id="share_this"/>}
                  detail={''}
                >
                <div className={`jr-btn-group share col-12 row ${classes.textAlignCenter}`}>
                <Button className={`jr-fab-btn bg-indigo lighten-1 text-white col-2 ${classes.margin}`}>
                    <i className="zmdi zmdi-facebook zmdi-hc-lg" />
                  </Button>

                  <Button className={`jr-fab-btn bg-light-blue accent-2 col-2 text-white ${classes.margin}`}>
                    <i className="zmdi zmdi-twitter zmdi-hc-lg" />
                  </Button>

                  <Button className={`jr-fab-btn bg-red lighten-1 text-white col-2 ${classes.margin}`}>
                    <i className="zmdi zmdi-google zmdi-hc-lg" />
                  </Button>
                  <Button className={`jr-fab-btn bg-light-blue darken-3 text-white col-2 ${classes.margin}`}>
                    <i className="zmdi zmdi-linkedin zmdi-hc-lg" />
                  </Button>
                </div>
                </HorizontalItemCardCustomShare>
              </div> */}
            </div>
           </div>
          
         
          <div className="col-md-4 col-sm-5 col-12 animation slideInLeft sidebar-opportunities d-xl-none d-md-none">
            <div className="sidebar">
              {
                opportunities.map(item=>{
                  return(
                    <HoriOpportunity data={item} isActive={slug===item.slug}/>
                  )
                })
              }
            </div>
          </div>
 </>}     
        </div>

        </div>
      </div>
      </>
    );
  };
  
  export default OpportunityDetails;