const initialState = {
    create_company:{
        error:null,
        spinner:false,
        message:null
    }
 }

 export function createCompany(state = initialState, action){
 
   switch(action.type) {
   case "HANDLE_CREATE_COMPANY":
           return {...state, create_company:{
               ...state.create_company, error:null, spinner:true, message:null
           }}
   case "CREATE_COMPANY_FAILLURE":
           return {...state, create_company:{
               ...state.create_company, error:action.payload, spinner:false, message:null
           }}
   case "CREATE_COMPANY":
      return {...state, create_company:{
          ...state.create_company, error:null, spinner:false, message:action.payload
      }}
   case "REMOVE_CREATE_COMPANY_MESSAGES":
       return{
           ...state, create_company:{
               ...state.create_company, error:null, spinner:false, message:null
           }
       }
     default:
       return state;
   }
 }
