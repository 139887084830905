import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { getProfileSkillsSuccess, getProfileSkillsError } from  "actions/profile";

const DeleteSkillsRequest = async (instance,data)=>
    await instance.put(`/profiles/skills/${data.skillId}/remove/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* DeleteSkills(actions){
    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(DeleteSkillsRequest,axiosInstance, actions.data);
    if(data){
        yield put(getProfileSkillsSuccess(data.message));
        yield put({type:"HANDLE_GET_PROFILE_SKILLS"})
    }else{
        yield put(getProfileSkillsError(data.error));
    }
    
}
export function* handleDeleteSkills(){
    console.log("DELETE SKILLS" , "DELETE SKILLS")
    yield takeEvery("HANDLE_DELETE_SKILLS", DeleteSkills)
}
export default function* rootSaga() {
    yield all([fork(handleDeleteSkills)]);
}
//