
import axios from 'axios';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const PRINCING = 'PRINCING';
export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';
export const GET_PROFILE_FORM = 'GET_PROFILE_FORM';
export const GET_MYPROFILE = 'GET_MYPROFILE';
export const GET_POSITIONS_FORM = 'GET_POSITIONS_FORM';

export const CREATE_PHONE = 'CREATE_PHONE';
export const CREATE_PHONE_REQUEST = 'CREATE_PHONE_REQUEST';
export const CREATE_PHONE_SUCCESS = 'CREATE_PHONE_SUCCESS';
export const CREATE_PHONE_FAILURE = 'CREATE_PHONE_FAILURE';

export const GET_PHONE_NUMBERS = 'GET_PHONE_NUMBERS';
export const GET_MAIL = 'GET_MAIL';

export const GET_INFOPHONE_FORM = 'GET_INFOPHONE_FORM';

export const CREATE_PUBLICATION = 'CREATE_PUBLICATION';
export const CREATE_PUBLICATION_REQUEST = 'CREATE_PUBLICATION_REQUEST';
export const CREATE_PUBLICATION_SUCCESS = 'CREATE_PUBLICATION_SUCCESS';
export const CREATE_PUBLICATION_FAILURE = 'CREATE_PUBLICATION_FAILURE';
export const GET_PROFILE_PUBLICATION = 'GET_PROFILE_PUBLICATION';



export const CREATE_MAIL = 'CREATE_MAIL';
export const CREATE_MAIL_REQUEST = 'CREATE_MAIL_REQUEST';
export const CREATE_MAIL_SUCCESS = 'CREATE_MAIL_SUCCESS';
export const CREATE_MAIL_FAILURE = 'CREATE_MAIL_FAILURE';

export const CREATE_EDUCATION = 'CREATE_EDUCATION';
export const CREATE_EDUCATION_REQUEST = 'CREATE_EDUCATION_REQUEST';
export const CREATE_EDUCATION_SUCCESS = 'CREATE_EDUCATION_SUCCESS';
export const CREATE_EDUCATION_FAILURE = 'CREATE_EDUCATION_FAILURE';

export const UPDATE_EDUCATION = 'UPDATE_EDUCATION';
export const UPDATE_EDUCATION_REQUEST = 'UPDATE_EDUCATION_REQUEST';
export const UPDATE_EDUCATION_SUCCESS = 'UPDATE_EDUCATION_SUCCESS';
export const UPDATE_EDUCATION_FAILURE = 'UPDATE_EDUCATION_FAILURE';

export const REMOVE_EDUCATION = 'REMOVE_EDUCATION';
export const REMOVE_EDUCATION_REQUEST = 'REMOVE_EDUCATION_REQUEST';
export const REMOVE_EDUCATION_SUCCESS = 'REMOVE_EDUCATION_SUCCESS';
export const REMOVE_EDUCATION_FAILURE = 'REMOVE_EDUCATION_FAILURE';

export const GET_EDUCATION_FORM = 'GET_EDUCATION_FORM';
export const GET_EDUCATION_LEVELS = 'GET_EDUCATION_LEVELS';

export const CREATE_EXPERIENCE = 'CREATE_EXPERIENCE';
export const CREATE_EXPERIENCE_REQUEST = 'CREATE_EXPERIENCE_REQUEST';
export const CREATE_EXPERIENCE_SUCCESS = 'CREATE_EXPERIENCE_SUCCESS';
export const CREATE_EXPERIENCE_FAILURE = 'CREATE_EXPERIENCE_FAILURE';

export const UPDATE_EXPERIENCE = 'UPDATE_EXPERIENCE';
export const UPDATE_EXPERIENCE_REQUEST = 'UPDATE_EXPERIENCE_REQUEST';
export const UPDATE_EXPERIENCE_SUCCESS = 'UPDATE_EXPERIENCE_SUCCESS';
export const UPDATE_EXPERIENCE_FAILURE = 'UPDATE_EXPERIENCE_FAILURE';

export const REMOVE_EXPERIENCE = 'REMOVE_EXPERIENCE';
export const REMOVE_EXPERIENCE_REQUEST = 'REMOVE_EXPERIENCE_REQUEST';
export const REMOVE_EXPERIENCE_SUCCESS = 'REMOVE_EXPERIENCE_SUCCESS';
export const REMOVE_EXPERIENCE_FAILURE = 'REMOVE_EXPERIENCE_FAILURE';

export const GET_EXPERIENCE_FORM = 'GET_EXPERIENCE_FORM';

export const CREATE_LANGUAGE = 'CREATE_LANGUAGE';
export const CREATE_LANGUAGE_REQUEST = 'CREATE_LANGUAGE_REQUEST';
export const CREATE_LANGUAGE_SUCCESS = 'CREATE_LANGUAGE_SUCCESS';
export const CREATE_LANGUAGE_FAILURE = 'CREATE_LANGUAGE_FAILURE';

export const GET_LANGUAGE_LIST = 'GET_LANGUAGE_LIST';
export const GET_LANGUAGE_LEVELS = 'GET_LANGUAGE_LEVELS';
export const GET_PROFILE_LANGUAGES = 'GET_PROFILE_LANGUAGES';

export const CREATE_SKILLS_REQUEST = 'CREATE_SKILLS_REQUEST';
export const CREATE_SKILLS_SUCCESS = 'CREATE_SKILLS_SUCCESS';
export const CREATE_SKILLS_FAILURE = 'CREATE_SKILLS_FAILURE';

export const GET_PROFILE_SKILLS = 'GET_PROFILE_SKILLS';

export const CREATE_SECTORS_REQUEST = 'CREATE_SECTORS_REQUEST';
export const CREATE_SECTORS_SUCCESS = 'CREATE_SECTORS_SUCCESS';
export const CREATE_SECTORS_FAILURE = 'CREATE_SECTORS_FAILURE';
export const GET_PROFILE_SECTORS = 'GET_PROFILE_SECTORS';

export const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
export const CREATE_DOCUMENT_REQUEST = 'CREATE_DOCUMENT_REQUEST';
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_FAILURE = 'CREATE_DOCUMENT_FAILURE';

export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const UPDATE_DOCUMENT_REQUEST = 'UPDATE_DOCUMENT_REQUEST';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_FAILURE = 'UPDATE_DOCUMENT_FAILURE';

export const REMOVE_DOCUMENT = 'REMOVE_DOCUMENT';
export const REMOVE_DOCUMENT_REQUEST = 'REMOVE_DOCUMENT_REQUEST';
export const REMOVE_DOCUMENT_SUCCESS = 'REMOVE_DOCUMENT_SUCCESS';
export const REMOVE_DOCUMENT_FAILURE = 'REMOVE_DOCUMENT_FAILURE';

export const GET_DOCUMENT_FORM = 'GET_DOCUMENT_FORM';

export const CREATE_EXTERNAL_INVITATION = 'CREATE_EXTERNAL_INVITATION';
export const CREATE_EXTERNAL_INVITATION_REQUEST = 'CREATE_EXTERNAL_INVITATION_REQUEST';
export const CREATE_EXTERNAL_INVITATION_SUCCESS = 'CREATE_EXTERNAL_INVITATION_SUCCESS';
export const CREATE_EXTERNAL_INVITATION_FAILURE = 'CREATE_EXTERNAL_INVITATION_FAILURE';





/***
 * Get profile List with id
 */export function setProfilesListData(data) {
  return {type: "GET_PROFILES_LIST_DATA", payload:data};
}
export function setAllProfilesList(data){
    return {
        type: "SET_ALL_PROFILES",
        payload:data
    }
}
export function setAllProfilesListError(data){
    return{
        type:"SET_ALL_PROFILES_FAILLURE",
        payload:data
    }
}


/***
 * Get profile details with id
 */
export function handleGetProfileDetails(slug){
    return{
        type:"HANDLE_GET_PROFILE_DETAILS",
        slug:slug
    }
}
//Backend response data
export function getProfileDetailsSuccesss(data){
    console.log(data,"GET_PROFILE_DETAILS")
    return{
        type:"GET_PROFILE_DETAILS",
        payload:data
    }
}
//  Backend Error message
export function getProfileDetailsErrors(data){
    return{
        type:"GET_PROFILE_DETAILS_FAILLURE",
        payload:data
    }
}



/***
 * Get profile details with id
 */
export function handleGetProfileDetailsForm(slug){
  return{
      type:"HANDLE_GET_PROFILE_DETAILS_FORM",
      slug:slug
  }
}
//Backend response data
export function getProfileDetailsFormSuccess(data){
  console.log(data,"GET_PROFILE_DETAILS_FORM")
  return{
      type:"GET_PROFILE_DETAILS_FORM",
      payload:data
  }
}
//  Backend Error message
export function getProfileDetailsFormError(data){
  return{
      type:"GET_PROFILE_DETAILS_FORM_FAILLURE",
      payload:data
  }
}


/***
 * Get profile areas with id
 */
export function handleGetProfileAreas(data){
    return{
        type:"HANDLE_GET_PROFILE_AREAS",
        data:data
    }
}
//Backend response data
export function getProfileAreasSuccess(data){
    console.log(data,"GET_PROFILE_AREAS")
    return{
        type:"GET_PROFILE_AREAS",
        payload:data
    }
}
//  Backend Error message
export function getProfileAreasError(data){
    return{
        type:"GET_PROFILE_AREAS_FAILLURE",
        payload:data
    }
}


/***
 * Get profile areas with id
 */
export function handleGetProfileSectors(data){
    return{
        type:"HANDLE_GET_PROFILE_SECTORS",
        data:data
    }
}
//Backend response data
export function getProfileSectorsSuccess(data){
    console.log(data,"GET_PROFILE_SECTORS")
    return{
        type:"GET_PROFILE_SECTORS",
        payload:data
    }
}
//  Backend Error message
export function getProfileSectorsError(data){
    return{
        type:"GET_PROFILE_SECTORS_FAILLURE",
        payload:data
    }
}




/***
 * Get profile Educations with id
 */
export function handleGetProfileEducations(data){
  return{
      type:"HANDLE_GET_PROFILE_EDUCATIONS",
      data:data
  }
}
//Backend response data
export function getProfileEducationsSuccess(data){
  console.log(data,"GET_PROFILE_EDUCATIONS")
  return{
      type:"GET_PROFILE_EDUCATIONS",
      payload:data
  }
}
//  Backend Error message
export function getProfileEducationsError(data){
  return{
      type:"GET_PROFILE_EDUCATIONS_FAILLURE",
      payload:data
  }
}



/***
 * Get profile Position with id
 */
export function handleGetProfileExperiences(data){
  return{
      type:"HANDLE_GET_PROFILE_EXPERIENCES",
      data:data
  }
}
//Backend response data
export function getProfileExperiencesSuccess(data){
  console.log(data,"GET_PROFILE_EXPERIENCES")
  return{
      type:"GET_PROFILE_EXPERIENCES",
      payload:data
  }
}
//  Backend Error message
export function getProfileExperiencesError(data){
  return{
      type:"GET_PROFILE_EXPERIENCES_FAILLURE",
      payload:data
  }
}


/***
 * Get profile areas with id
 */
export function handleGetProfileSkills(data){
    return{
        type:"HANDLE_GET_PROFILE_SKILLS",
        data:data
    }
}
//Backend response data
export function getProfileSkillsSuccess(data){
    console.log(data,"GET_PROFILE_SKILLS")
    return{
        type:"GET_PROFILE_SKILLS",
        payload:data
    }
}
//  Backend Error message
export function getProfileSkillsError(data){
    return{
        type:"GET_PROFILE_SKILLS_FAILLURE",
        payload:data
    }
}

/***
 * Add Eduction Profile 
 */
export function handleAddEducation(data){
    return{
        type:"HANDLE_ADD_EDUCATION",
        data:data
    }
}
//Backend response data
export function AddEducationSuccess(data){
    console.log(data,"ADD_EDUCATION")
    return{
        type:"ADD_EDUCATION",
        payload:data
    }
}
//  Backend Error message
export function AddEducationError(data){
    return{
        type:"ADD_EDUCATION_FAILLURE",
        payload:data
    }
}
export function AddEducationResetMessages(data){
  return{
      type:"ADD_EDUCATION_RESET_MESSAGES",
      payload:data
  }
}

/***
 * Edit Eduction Profile 
 */
export function handleEditEducation(data){
  return{
      type:"HANDLE_EDIT_EDUCATION",
      data:data
  }
}
//Backend response data
export function EditEducationSuccess(data){
  console.log(data,"EDIT_EDUCATION")
  return{
      type:"EDIT_EDUCATION",
      payload:data
  }
}
//  Backend Error message
export function EditEducationError(data){
  return{
      type:"EDIT_EDUCATION_FAILLURE",
      payload:data
  }
}
export function EditEducationResetMessages(data){
  return{
      type:"EDIT_EDUCATION_RESET_MESSAGES",
      payload:data
  }
}



/***
 * Edit position Profile 
 */
export function handleEditExperience(data){
  return{
      type:"HANDLE_EDIT_EXPERIENCE",
      data:data
  }
}
//Backend response data
export function EditExperienceSuccess(data){
  console.log(data,"EDIT_EXPERIENCE")
  return{
      type:"EDIT_EXPERIENCE",
      payload:data
  }
}
//  Backend Error message
export function EditExperienceError(data){
  return{
      type:"EDIT_EXPERIENCE_FAILLURE",
      payload:data
  }
}


/***
 * Add Eduction Level Profile 
 */
export function handleEducationLevel(data){
    return{
        type:"HANDLE_EDUCATION_LEVEL",
        data:data
    }
}
//Backend response data
export function EducationLevelSuccess(data){
    console.log(data,"EDUCATION_LEVEL")
    return{
        type:"EDUCATION_LEVEL",
        payload:data
    }
}
//  Backend Error message
export function EducationLevelError(data){
    return{
        type:"EDUCATION_LEVEL_FAILLURE",
        payload:data
    }
}


/***
 * Add Experience Profile 
 */
export function handleAddExperience(data){
  return{
      type:"HANDLE_ADD_EXPERIENCE",
      data:data
  }
}
//Backend response data
export function AddExperienceSuccess(data){
  console.log(data,"ADD_EXPERIENCE")
  return{
      type:"ADD_EXPERIENCE",
      payload:data
  }
}
//  Backend Error message
export function AddExperienceError(data){
  return{
      type:"ADD_EXPERIENCE_FAILLURE",
      payload:data
  }
}
export function AddExperienceResetMessages(data){
  return{
      type:"ADD_EXPERIENCE_RESET_MESSAGES",
      payload:data
  }
}
export function EditExperienceResetMessages(data){
  return{
      type:"EDIT_EXPERIENCE_RESET_MESSAGES",
      payload:data
  }
}


/***
 * Delete Experience Profile 
 */
export function handleDeleteExperience(data){
  return{
      type:"HANDLE_DELETE_EXPERIENCE",
      data:data
  }
}
//Backend response data
export function deleteExperienceSuccess(data){
  console.log(data,"DELETE_EXPERIENCE")
  return{
      type:"DELETE_EXPERIENCE",
      payload:data
  }
}
//  Backend Error message
export function deleteExperienceError(data){
  return{
      type:"DELETE_EXPERIENCE_FAILLURE",
      payload:data
  }
}


/***
 * Delete Education Profile 
 */
export function handleDeleteEducation(data){
  return{
      type:"HANDLE_DELETE_EDUCATION",
      data:data
  }
}
//Backend response data
export function deleteEducationSuccess(data){
  console.log(data,"DELETE_EDUCATION")
  return{
      type:"DELETE_EDUCATION",
      payload:data
  }
}
//  Backend Error message
export function deleteEducationError(data){
  return{
      type:"DELETE_EDUCATION_FAILLURE",
      payload:data
  }
}

/***
 * Add Skills Profile 
 */
export function handleAddSkills(data){
  return{
      type:"HANDLE_ADD_SKILLS",
      data:data
  }
}
//Backend response data
export function AddSkillsSuccess(data){
  console.log(data,"ADD_SKILLS")
  return{
      type:"ADD_SKILLS",
      payload:data
  }
}
//  Backend Error message
export function AddSkillsError(data){
  return{
      type:"ADD_SKILLS_FAILLURE",
      payload:data
  }
}
export function AddSkillsResetMessages(data){
  return{
      type:"ADD_SKILLS_RESET_MESSAGES",
      payload:data
  }
}

/***
 * Delete Skills Profile 
 */
export function handleDeleteSkills(data){
  return{
      type:"HANDLE_DELETE_SKILLS",
      data:data
  }
}
//Backend response data
export function DeleteSkillsSuccess(data){
  console.log(data,"DELETE_SKILLS")
  return{
      type:"DELETE_SKILLS",
      payload:data
  }
}
//  Backend Error message
export function DeleteSkillsError(data){
  return{
      type:"DELETE_SKILLS_FAILLURE",
      payload:data
  }
}



/***
 * Add Sectors Profile 
 */
export function handleAddSectors(data){
  return{
      type:"HANDLE_ADD_SECTORS",
      data:data
  }
}
//Backend response data
export function AddSectorsSuccess(data){
  console.log(data,"ADD_SECTORS")
  return{
      type:"ADD_SECTORS",
      payload:data
  }
}
//  Backend Error message
export function AddSectorsError(data){
  return{
      type:"ADD_SECTORS_FAILLURE",
      payload:data
  }
}
export function AddSectorsResetMessages(data){
  return{
      type:"ADD_SECTORS_RESET_MESSAGES",
      payload:data
  }
}
/***
 * Delete Sectors Profile 
 */
export function handleDeleteSectors(data){
  return{
      type:"HANDLE_DELETE_SECTORS",
      data:data
  }
}
//Backend response data
export function deleteSectorsSuccess(data){
  console.log(data,"DELETE_SECTORS")
  return{
      type:"DELETE_SECTORS",
      payload:data
  }
}
//  Backend Error message
export function deleteSectorsError(data){
  return{
      type:"DELETE_SECTORS_FAILLURE",
      payload:data
  }
}



/***
 * Add File Profile 
 */


export function successSetFormData(data){
  return{
      type:"UPLOAD_DOC",
      payload:data
  }
}
export function setFormDataError(data){
  return{
      type:"UPLOAD_DOC_FAILLURE",
      payload: data
  }
}
export function handleResetuploadDocState(){
  return{
      type:"REMOVE_UPLOAD_DOC_MESSAGES",
      payload:{}
  }
}
/***
 * Delete File Profile 
 */
export function handleDeleteFile(data){
  return{
      type:"HANDLE_DELETE_FILE",
      data:data
  }
}
//Backend response data
export function deleteFileSuccess(data){
  console.log(data,"DELETE_FILE")
  return{
      type:"DELETE_FILE",
      payload:data
  }
}
//  Backend Error message
export function deleteFileError(data){
  return{
      type:"DELETE_FILE_FAILLURE",
      payload:data
  }
}

/***
 * Get profile files with id
 */
export function handleGetProfileFile(data){
  return{
      type:"HANDLE_GET_PROFILE_FILE",
      data:data
  }
}
//Backend response data
export function getProfileFileSuccess(data){
  console.log(data,"GET_PROFILE_FILE")
  return{
      type:"GET_PROFILE_FILE",
      payload:data
  }
}
//  Backend Error message
export function getProfileFileError(data){
  return{
      type:"GET_PROFILE_FILE_FAILLURE",
      payload:data
  }
}





/***
 * Add Langue Profile 
 */
export function handleAddLangue(data){
  return{
      type:"HANDLE_ADD_LANGUE",
      data:data
  }
}
//Backend response data
export function AddLangueSuccess(data){
  console.log(data,"ADD_LANGUE")
  return{
      type:"ADD_LANGUE",
      payload:data
  }
}
//  Backend Error message
export function AddLangueError(data){
  return{
      type:"ADD_LANGUE_FAILLURE",
      payload:data
  }
}
export function AddLanguageResetMessages(data){
  return{
      type:"ADD_LANGUE_RESET_MESSAGES",
      payload:data
  }
}

/***
 * Delete Langue Profile 
 */
export function handleDeleteLangue(data){
  return{
      type:"HANDLE_DELETE_LANGUE",
      data:data
  }
}
//Backend response data
export function deleteLangueSuccess(data){
  console.log(data,"DELETE_LANGUE")
  return{
      type:"DELETE_LANGUE",
      payload:data
  }
}
//  Backend Error message
export function deleteLangueError(data){
  return{
      type:"DELETE_LANGUE_FAILLURE",
      payload:data
  }
}

/***
 * Get profile Langue with id
 */
export function handleGetProfileLangue(data){
  return{
      type:"HANDLE_GET_PROFILE_LANGUE",
      data:data
  }
}
//Backend response data
export function getProfileLangueSuccess(data){
  console.log(data,"GET_PROFILE_LANGUE")
  return{
      type:"GET_PROFILE_LANGUE",
      payload:data
  }
}
//  Backend Error message
export function getProfileLangueError(data){
  return{
      type:"GET_PROFILE_LANGUE_FAILLURE",
      payload:data
  }
}



/***
 * Add area Profile 
 */

export function handleAddArea(data){
  return{
      type:"HANDLE_ADD_AREA",
      data:data
  }
}
//Backend response data
export function AddAreaSuccess(data){
  console.log(data,"ADD_AREA")
  return{
      type:"ADD_AREA",
      payload:data
  }
}
//  Backend Error message
export function AddAreaError(data){
  return{
      type:"ADD_AREA_FAILLURE",
      payload:data
  }
}
export function AddAreaResetMessages(data){
  return{
      type:"ADD_AREA_RESET_MESSAGES",
      payload:data
  }
}
/***
 * Delete Area Profile 
 */
export function handleDeleteArea(data){
  return{
      type:"HANDLE_DELETE_AREA",
      data:data
  }
}
//Backend response data
export function deleteAreaSuccess(data){
  console.log(data,"DELETE_AREA")
  return{
      type:"DELETE_AREA",
      payload:data
  }
}
//  Backend Error message
export function deleteAreaError(data){
  return{
      type:"DELETE_AREA_FAILLURE",
      payload:data
  }
}



/***
 * Add Publication Profile 
 */
export function handleAddPublication(data){
  return{
      type:"HANDLE_ADD_PUBLICATION",
      data:data
  }
}
//Backend response data
export function AddPublicationSuccess(data){
  console.log(data,"ADD_PUBLICATION")
  return{
      type:"ADD_PUBLICATION",
      payload:data
  }
}
//  Backend Error message
export function AddPublicationError(data){
  return{
      type:"ADD_PUBLICATION_FAILLURE",
      payload:data
  }
}
export function AddPublicationResetMessages(data){
  return{
      type:"ADD_PUBLICATION_RESET_MESSAGES",
      payload:data
  }
}

/***
 * Delete Publication Profile 
 */
export function handleDeletePublication(data){
  return{
      type:"HANDLE_DELETE_PUBLICATION",
      data:data
  }
}
//Backend response data
export function deletePublicationSuccess(data){
  console.log(data,"DELETE_PUBLICATION")
  return{
      type:"DELETE_PUBLICATION",
      payload:data
  }
}
//  Backend Error message
export function deletePublicationError(data){
  return{
      type:"DELETE_PUBLICATION_FAILLURE",
      payload:data
  }
}

/***
 * Get profile Langue with id
 */
export function handleGetProfilePublication(data){
  return{
      type:"HANDLE_GET_PROFILE_PUBLICATION",
      data:data
  }
}
//Backend response data
export function getProfilePublicationSuccess(data){
  console.log(data,"GET_PROFILE_PUBLICATION")
  return{
      type:"GET_PROFILE_PUBLICATION",
      payload:data
  }
}
//  Backend Error message
export function getProfilePublicationError(data){
  return{
      type:"GET_PROFILE_PUBLICATION_FAILLURE",
      payload:data
  }
}


//...............Follow profile....

export function HandleFollowProfile(data){
  return{
      type:"HANDLE_FOLLOW_PROFILE",
      data:data
  }
}

export function FollowProfileSuccess(data){
  return{
      type:"FOLLOW_PROFILE_SUCCESS",
      payload:data
  }
}
// Backend Error message
export function FollowProfileError(data){
  return{
      type:"FOLLOW_PROFILE_FAILLURE",
      payload:data
  }
}

export function FollowProfileResetMessages(){
  return{
      type:"FOLLOW_PROFILE_RESET_MESSAGES"
  }
}

/***
 * Edit Profile 
 */
export function handleEditProfile(data){
  return{
      type:"HANDLE_EDIT_PROFILE",
      data:data
  }
}
//Backend response data
export function EditProfileSuccess(data){
  console.log(data,"EDIT_PROFILE")
  return{
      type:"EDIT_PROFILE",
      payload:data
  }
}
//  Backend Error message
export function EditProfileError(data){
  return{
      type:"EDIT_PROFILE_FAILLURE",
      payload:data
  }
}
export function EditProfileResetMessages(data){
  return{
      type:"EDIT_PROFILE_RESET_MESSAGES",
      payload:data
  }
}





/***
 * Get Edit Profile 
 */
export function handleGetEditProfileDetails(data){
  return{
      type:"HANDLE_GET_EDIT_PROFILE_DETAILS",
      data:data
  }
}
//Backend response data
export function getEditProfileDetailsSuccess(data){
  console.log(data,"GET_EDIT_PROFILE")
  return{
      type:"GET_EDIT_PROFILE",
      payload:data
  }
}
//  Backend Error message
export function getEditProfileDetailsError(data){
  return{
      type:"GET_EDIT_PROFILE_FAILLURE",
      payload:data
  }
}
export function getEditProfileDetailsResetMessages(data){
  return{
      type:"GET_EDIT_PROFILE_RESET_MESSAGES",
      payload:data
  }
}


/***
 * Delete Object 
 */
export function handleDeleteObject(data){
  return{
      type:"HANDLE_DELETE_OBJECT",
      data:data
  }
}
//Backend response data
export function deleteObjectSuccess(data){
  console.log(data,"DELETE_OBJECT")
  return{
      type:"DELETE_OBJECT",
      payload:data
  }
}
//  Backend Error message
export function deleteObjectError(data){
  return{
      type:"DELETE_OBJECT_FAILLURE",
      payload:data
  }
}
export function deleteObjectResetMessages(data){
  return{
      type:"DELETE_OBJECT_RESET_MESSAGES",
      payload:data
  }
}





























export function addSectorsToProfile(sectors, profileId){

  // data = array of skills
  return ((dispatch, getState) => {

    console.log("addSectorsToProfile entry : ", sectors, profileId)
    // we can receive multiple skills at once
    if(sectors.length === 0 || !sectors){
      return;

    } else {

      let axiosInstance = getState().user.axiosInstance;
      dispatch({ type: CREATE_SECTORS_REQUEST });
      let axiosRequests = [];

      // For now, if the first skill added is ok, and the second fails, the store will effectively
      // contains the 'error' info, but the page will have already changed.
      sectors.forEach((sector) => {

        const data = {
          is_active: true,
          industry_id: sector.industry_id,
          profile_id: profileId,
        }

        axiosRequests.push(axiosInstance.post(`/profiles/${profileId}/industries/add/`, data))
      });

      axios.all(axiosRequests)
      .then((responses) => {
        console.log("addSectorsToProfile responses", responses);
        dispatch({ type: CREATE_SECTORS_SUCCESS })
      })
      .catch((error) => {
        console.log("addSectorsToProfile error", error);
        if(error.response && error.response.data) {
          dispatch({ type: CREATE_SECTORS_FAILURE, payload: error.response.data });
        } else {
          dispatch({ type: CREATE_SECTORS_FAILURE, payload: error });
        }
      })
    }
  })
}


export function addSkillsToProfile(url, data){

  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance;
        instance.post(url, data)
        .then((response) => {
          if(response.status!==200 && response.status!==201){
            dispatch({ type: CREATE_SKILLS_FAILURE, payload:response.data.error})
          }else{
            dispatch({ type: CREATE_SKILLS_SUCCESS, payload:response.data.message})
          }
        })
        .catch((error) => {
          if(error.response && error.response.data) {
              dispatch({ type: CREATE_SKILLS_FAILURE, payload: error.response.data });
          } else {
              dispatch({ type: CREATE_SKILLS_FAILURE, payload: error });
          }
        })
}
}


function createExperience(axiosInstance, dispatch, companyId, positionId, data) {
  const data2 = {
    is_active: true,
    description: data.description,
    responsibilities: data.responsibilities,
    accomplishements: data.accomplishements,
    city_id : data.city_id,
    state_province_id : data.state_province_id,
    country_id : data.country_id,
    start_date: data.start_date,
    end_date: data.end_date,
    is_current: data.is_current,
    profile_id: data.profile_id,
    position_id: positionId,
    company_id: companyId,
  }
  console.log('test', data2);

  dispatch({ type: CREATE_EXPERIENCE_REQUEST });
  axiosInstance.post(`/profiles/${data.profile_id}/positions/add/`, data2)
  .then((response) => {
    console.log('createExperience response', response);
    dispatch({ type: CREATE_EXPERIENCE_SUCCESS })
  })
  .catch((error) => {
    console.log('createExperience error', error);
    if(error.response && error.response.data) {
      dispatch({ type: CREATE_EXPERIENCE_FAILURE, payload: error.response.data });
    } else {
      dispatch({ type: CREATE_EXPERIENCE_FAILURE, payload: error });
    }
  })
}

function updateExperience(axiosInstance, dispatch, companyId, positionId, data, itemId) {


  const data2 = {
    is_active: true,
    description: data.about,
    responsibilities: data.responsibilities,
    accomplishements: data.accomplishements,
    city_id : data.city_id,
    state_province_id : data.state_province_id,
    country_id : data.country_id,
    start_date: data.start_date,
    end_date: data.end_date,
    is_current: data.is_current,
    profile_id: data.profile_id,
    position_id: positionId,
    company_id: companyId,
  }

  dispatch({ type: UPDATE_EXPERIENCE_REQUEST });
  axiosInstance.put(`/profiles/${data.profile_id}/positions/${itemId}/edit/`, data2)
  .then((response) => {
    console.log('updateExperience response', response);
    dispatch({ type: UPDATE_EXPERIENCE_SUCCESS });
  })
  .catch((error) => {
    console.log('updateExperience error', error);
    if(error.response && error.response.data) {
      dispatch({ type: CREATE_EXPERIENCE_FAILURE, payload: error.response.data });
    } else{
      dispatch({ type: CREATE_EXPERIENCE_FAILURE, payload: error });
    }
  })
}

/**
 * This functions will add a experience to a profile, with a company and an position created if
 * they do not exists yet.
 * Arguments :
 * - company : { customOption: bool, company_name: string } if it needs to be created or
 * { company_id: string } if it already exists
 * - position : { customOption: bool, title: string } if it needs to be created or
 * { position_id: string } if it already exists
 * - data : {
 *    description: string,
 *    start_date: date,
 *    end_date: date,
 *    is_current: bool,
 *    profile_id: string,
 *  }
 * - itemId : id of the associative table of profile-position. To be given only if we are editing
 * the experience
 */
export function setExperienceToProfile(company, position, data, itemId){

  return ((dispatch, getState) => {
    const LANG=localStorage.getItem('i18nextLng');
    // we can receive multiple skills at once
    if(!company || !position ){
      console.log("setExperienceToProfile input", company, position, data);
      return;

    } else {

      let axiosInstance = getState().user.axiosInstance;

      // the company needs to be created in the database
      if(company.customOption) {
        if(position.customOption){
          const data2 = LANG==='fr'?{title_fr: position.title,is_active: true}:{title_en: position.title,is_active: true};
          const data3 = {name: company.company_name,is_company_page: false}
  
          axiosInstance.post('/companies/add/', data3)
          .then((response) => {
            axiosInstance.post('/profiles/positions/add/', data2)
            .then((position_resp)=>{
              if(itemId) {
                updateExperience(axiosInstance, dispatch, response.data.id, position_resp.id, data, itemId);
              } else {
                createExperience(axiosInstance, dispatch, response.data.id, position_resp.data.id, data);
              }

            })
            .catch((position_error)=>{
              if(position_error.response && position_error.response.data){
               if(itemId) {
                 updateExperience(axiosInstance, dispatch, response.data.id, position_error.response.data.id, data, itemId);
               } else {
                 createExperience(axiosInstance, dispatch, response.data.id, position_error.response.data.id, data);
               }
              }else{
                 console.log('position_error')
              }
           })
          })
          .catch((error) => {
            if(error.response && error.response.data) {
              axiosInstance.post('/profiles/positions/add/', data2)
                .then((position_resp)=>{
                  if(itemId) {
                    updateExperience(axiosInstance, dispatch, error.response.data.id, position_resp.id, data, itemId);
                  } else {
                    createExperience(axiosInstance, dispatch, error.response.data.id, position_resp.data.id, data);
                  }
    
                })
                .catch((position_error)=>{
                   if(position_error.response && position_error.response.data){
                    if(itemId) {
                      updateExperience(axiosInstance, dispatch, error.response.data.id, position_error.response.data.id, data, itemId);
                    } else {
                      createExperience(axiosInstance, dispatch, error.response.data.id, position_error.response.data.id, data);
                    }
                   }else{
                      console.log('position_error')
                   }
                })
            } else {
              console.log("error", error);
            }
          })
        }else{
          const data3 = {
            name: company.company_name,
            is_company_page: false,
          }
  
          axiosInstance.post('/companies/add/', data3)
          .then((response) => {
            if(itemId) {
              updateExperience(axiosInstance, dispatch, response.data.id, position.position_id, data, itemId);
            } else {
              createExperience(axiosInstance, dispatch, response.data.id, position.position_id, data);
            }
          })
          .catch((error) => {
            if(error.response && error.response.data) {
              if(itemId) {
                updateExperience(axiosInstance, dispatch, error.response.data.id, position.position_id, data, itemId);
              } else {
                createExperience(axiosInstance, dispatch, error.response.data.id, position.position_id, data);
              }
            }else{
              console.log("error")
            }
          });

        }
      }else if(position.customOption){
        const data2 = LANG==='fr'?{title_fr: position.title,is_active: true}:{title_en: position.title,is_active: true};
        axiosInstance.post('/profiles/positions/add/', data2)
        .then((position_resp)=>{
          if(itemId) {
            updateExperience(axiosInstance, dispatch, company.company_id, position_resp.data.id, data, itemId);
          } else {
            createExperience(axiosInstance, dispatch, company.company_id, position_resp.data.id, data);
          }
        })        
        .catch((error) => {
            if(error.response && error.response.data) {
              if(itemId) {
                updateExperience(axiosInstance, dispatch, company.company_id,  error.response.data.id, data, itemId);
              } else {
                createExperience(axiosInstance, dispatch, company.company_id,  error.response.data.id, data);
              }
            }else{
              console.log("error")
            }
          });
      } else {
        if(itemId) {
          updateExperience(axiosInstance, dispatch, company.company_id, position.position_id, data, itemId);
        } else {
          createExperience(axiosInstance, dispatch, company.company_id, position.position_id, data);
        }
      }
    }
  })
}

function createEducationOnServer(axiosInstance, dispatch, companyId, educationId, data) {

  const data2 = {
    is_active: true,
    activities_and_societies: data.activities_and_societies,
    description: data.description,
    city_id : data.city_id,
    state_province_id : data.state_province_id,
    country_id : data.country_id,
    start_date: data.start_date,
    end_date: data.end_date,
    is_current: data.is_current,
    profile_id: data.profile_id,
    education_id: educationId,
    company_id: companyId,
    education_level_id: data.education_level_id
  }

  dispatch({ type: CREATE_EDUCATION_REQUEST });
  axiosInstance.post(`/profiles/${data.profile_id}/educations/add/`, data2)
  .then((response) => {
    console.log('createEducationOnServer response', response);
    dispatch({ type: CREATE_EDUCATION_SUCCESS });
  })
  .catch((error) => {
    console.log('createEducationOnServer error', error);
    if(error.response && error.response.data) {
      dispatch({ type: CREATE_EDUCATION_FAILURE, payload: error.response.data });
    } else {
      dispatch({ type: CREATE_EDUCATION_FAILURE, payload: error });
    }
  })
}

function updateEducationOnServer(axiosInstance, dispatch, companyId, educationId, data, itemId) {

  const data2 = {
    is_active: true,
   activities_and_societies: data.activities_and_societies,
    description: data.description,
    start_date: data.start_date,
    end_date: data.end_date,
    is_current: data.is_current,
    profile_id: data.profile_id,
    education_id: educationId,
    company_id: companyId,
    education_level_id: data.education_level_id,
    city_id : data.city_id,
    state_province_id : data.state_province_id,
    country_id : data.country_id
  }

  dispatch({ type: UPDATE_EDUCATION_REQUEST });
  axiosInstance.put(`/profiles/${data.profile_id}/educations/${itemId}/edit/`, data2)
  .then((response) => {
    console.log('updateEducationOnServer response', response);
    dispatch({ type: UPDATE_EDUCATION_SUCCESS });
  })
  .catch((error) => {
    console.log('updateEducationOnServer error', error);
    if(error.response && error.response.data) {
      dispatch({ type: UPDATE_EDUCATION_FAILURE, payload: error.response.data });
    } else {
      dispatch({ type: UPDATE_EDUCATION_FAILURE, payload: error });
    }
  })
}

/**
 * This functions will add an education to a profile, with a company and an education created if
 * they do not exists yet.
 * Arguments :
 * - company : { customOption: bool, company_name: string } if it needs to be created or
 * { company_id: string } if it already exists
 * - education : { customOption: bool, name: string } if it needs to be created or
 * { education_id: string } if it already exists
 * - data : {
 *    is_active: bool,
 *    diploma: string,
 *    description: string,
 *    start_date: data,
 *    end_date: date,
 *    profile_id: string,
 *    education_level_id: string,
 *  }
 * - itemId : id of the associative table of profile-education. To be given only if we are editing
 * the profile
 */

 
// export function setEducationToProfile(company, education, data, itemId){

//   return ((dispatch, getState) => {

//     // we can receive multiple skills at once
//     if(!company || !education){
//       return;

//     } else {

//       let axiosInstance = getState().user.axiosInstance;

//       // the company needs to be reated in the database
//       if(company.customOption) {

//         const data3 = {
//           name: company.company_name,
//           is_company_page: false,
//         }

//         axiosInstance.post('/companies/add/', data3)
//         .then((response) => {
//           console.log("setEducationToProfile responses", response)
//           if(itemId) {
//             updateEducationOnServer(axiosInstance, dispatch, response.data.id, response.data.id, data, itemId);
//           } else {
//             createEducationOnServer(axiosInstance, dispatch, response.data.id, response.data.id, data);
//           }
//         })
//         .catch((error) => {
//           console.log("setEducationToProfile error", error);
//         })

//       // we can directly create the experience
//       } else {
//         if(itemId) {
//           updateEducationOnServer(axiosInstance, dispatch, company.company_id, education.education_id, data, itemId);
//         } else {
//           createEducationOnServer(axiosInstance, dispatch, company.company_id, education.education_id, data);
//         }
//       }
//     }
//   })
// }


export function setEducationToProfile(company, education, data, itemId){

  return ((dispatch, getState) => {
    const LANG=localStorage.getItem('i18nextLng');
    // we can receive multiple skills at once
    if(!company || !education ){
      console.log("----setEducationToProfile input----", company, education, data);
      return;

    } else {

      let axiosInstance = getState().user.axiosInstance;

      // the company needs to be created in the database
      if(company.customOption) {
        if(education.customOption){
          const data2 = LANG==='fr'?{name_fr: education.name,is_active: true}:{name_en: education.name,is_active: true};
          const data3 = {name: company.company_name}
  
          axiosInstance.post('/companies/add/', data3)
          .then((response) => {
            axiosInstance.post('/profiles/educations/add/', data2)
            .then((education_resp)=>{
              if(itemId) {
                  updateEducationOnServer(axiosInstance, dispatch, response.data.id, education_resp.id, data, itemId);
              } else {
                  createEducationOnServer(axiosInstance, dispatch, response.data.id, education_resp.data.id, data);
              }

            })
            .catch((education_error)=>{
              if(education_error.response && education_error.response.data){
               if(itemId) {
                  updateEducationOnServer(axiosInstance, dispatch, response.data.id, education_error.response.data.id, data, itemId);
               } else {
                  createEducationOnServer(axiosInstance, dispatch, response.data.id, education_error.response.data.id, data);
               }
              }else{
                 console.log('education_error')
              }
           })
          })
          .catch((error) => {
            if(error.response && error.response.data) {
              axiosInstance.post('/profiles/educations/add/', data2)
                .then((education_resp)=>{
                  if(itemId) {
                      updateEducationOnServer(axiosInstance, dispatch, error.response.data.id, education_resp.id, data, itemId);
                  } else {
                      createEducationOnServer(axiosInstance, dispatch, error.response.data.id, education_resp.data.id, data);
                  }
    
                })
                .catch((education_error)=>{
                   if(education_error.response && education_error.response.data){
                    if(itemId) {
                      updateEducationOnServer(axiosInstance, dispatch, error.response.data.id, education_error.response.data.id, data, itemId);
                    } else {
                      createEducationOnServer(axiosInstance, dispatch, error.response.data.id, education_error.response.data.id, data);
                    }
                   }else{
                      console.log('education_error')
                   }
                })
            } else {
              console.log("error", error);
            }
          })
        }else{
          const data3 = {
            name: company.company_name,
            is_company_page: false,
          }
  
          axiosInstance.post('/companies/add/', data3)
          .then((response) => {
            if(itemId) {
              updateEducationOnServer(axiosInstance, dispatch, response.data.id, education.education_id, data, itemId);
            } else {
              createEducationOnServer(axiosInstance, dispatch, response.data.id, education.education_id, data);
            }
          })
          .catch((error) => {
            if(error.response && error.response.data) {
              if(itemId) {
                  updateEducationOnServer(axiosInstance, dispatch, error.response.data.id, education.education_id, data, itemId);
              } else {
                  createEducationOnServer(axiosInstance, dispatch, error.response.data.id, education.education_id, data);
              }
            }else{
              console.log("error")
            }
          });

        }
      }else if(education.customOption){
        const data2 = LANG==='fr'?{name_fr: education.name,is_active: true}:{name_en: education.name,is_active: true};
        axiosInstance.post('/profiles/educations/add/', data2)
        .then((education_resp)=>{
          if(itemId) {
              updateEducationOnServer(axiosInstance, dispatch, company.company_id, education_resp.data.id, data, itemId);
          } else {
              createEducationOnServer(axiosInstance, dispatch, company.company_id, education_resp.data.id, data);
          }
        })        
        .catch((error) => {
            if(error.response && error.response.data) {
              if(itemId) {
                  updateEducationOnServer(axiosInstance, dispatch, company.company_id,  error.response.data.id, data, itemId);
              } else {
                  createEducationOnServer(axiosInstance, dispatch, company.company_id,  error.response.data.id, data);
              }
            }else{
              console.log("error")
            }
          });
      } else {
        if(itemId) {
          updateEducationOnServer(axiosInstance, dispatch, company.company_id, education.education_id, data, itemId);
        } else {
          createEducationOnServer(axiosInstance, dispatch, company.company_id, education.education_id, data);
        }
      }
    }
  })
}
