import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
export default function Choices(props) {

  return (
        <FormControl className={``}>
            <InputLabel htmlFor="demo-controlled-open-select">Add a field</InputLabel>
                <Select
                    open={props.open}
                    onClose={props.handleClose}
                    onOpen={props.handleOpen}
                    value={''}
                    onChange={props.onChange}
                    >
                    {
                        props.choices.map((item)=>{
                            return (
                                <MenuItem value={item.value}>{item.icon} {item.name}</MenuItem>
                            )
                        })
                    }
                </Select>
        </FormControl>
        );
    }
