import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { getCompaniesAdminSuccess, getCompaniesAdminError } from  "actions/companies/getCompanies";

const GetCompaniesAdminRequest = async (instance, actions)=>
    await instance.get(`/profiles/organizations-admin/list/?&page=${actions.page}`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetCompaniesAdmin(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetCompaniesAdminRequest,axiosInstance, actions.payload);
    
    if(data.data){

    console.log("Get Company",  data.data.results)
        yield put(getCompaniesAdminSuccess(data.data));
    }else{
        yield put(getCompaniesAdminError(data.error));
    }
    
}
export function* handleGetCompaniesAdmin(){
    yield takeEvery("HANDLE_GET_COMPANIES_ADMIN", GetCompaniesAdmin)
}
export default function* rootSaga() {
    yield all([fork(handleGetCompaniesAdmin)]);
}
