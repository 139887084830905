import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { getProfileDetailsSuccesss, getProfileDetailsErrors, getProfileDetailsFormSuccess, getProfileDetailsFormError, } from  "actions/profile";

const GetProfileDetailsRequest = async (instance,slug)=>
    await instance.get(`/profiles/${slug}/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetProfileDetails(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetProfileDetailsRequest,axiosInstance, actions.slug);
    if(data.data){
        yield put(getProfileDetailsSuccesss(data.data));
    }else{
        yield put(getProfileDetailsErrors(data.error));
    }
}
export function* handleGetProfileDetails(){
    yield takeEvery("HANDLE_GET_PROFILE_DETAILS", GetProfileDetails)
}



const GetProfileDetailsFormRequest = async (instance,slug)=>
    await instance.get(`/opportunities/call/for/application/${slug}/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetProfileDetailsForm(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetProfileDetailsFormRequest,axiosInstance, actions.slug);
    if(data.data){
        yield put(getProfileDetailsFormSuccess(data.data));
    }else{
        yield put(getProfileDetailsFormError(data.error));
    }
}
export function* handleGetProfileDetailsForm(){
    yield takeEvery("HANDLE_GET_PROFILE_DETAILS_FORM", GetProfileDetailsForm)
}


export default function* rootSaga() {
    yield all([fork(handleGetProfileDetails)]);
    yield all([fork(handleGetProfileDetailsForm)]);
}
//