import { Field, reduxForm } from 'redux-form';
import { browserHistory } from 'react-router';
import DateFnsUtils from '@date-io/date-fns';
// import { translate } from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {useParams} from 'react-router-dom';
import { Col, Form, Row, Panel, Modal, Button } from 'react-bootstrap';
import { renderTextField,renderSubmitGroup, renderDates,renderAsyncAutocomplete,renderPlaceAutocomplete, renderDateTime, renderFieldWithButton } from '../components/formsElements/FormComponents';
import { showItemDetails} from '../actions/index';
import { connect } from 'react-redux';
import { handleSearchCompany, handleSearchPosition} from '../actions/search';
import { handleAddExperience, handleEditExperience, handleDeleteExperience , AddExperienceResetMessages , EditExperienceResetMessages} from '../actions/profile';
import {Alert} from '@material-ui/lab';
import logo from '../assets/imgs/spinner.gif';
import FontAwesome from 'react-fontawesome';
import { LocationSearchInput } from 'components/formsElements/FieldsComponents';
import TextField from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import React,{useEffect, useState} from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import IntlMessages from "util/IntlMessages";
import {API_BASE_URL, CLIENT_ID} from 'config';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);





  function ExperienceForm(props,submitError, serverError, error, params , handleSubmit) {
    const position = props.position;
    const positionId = props.positionId;
    const initState={
      description: position ? position.description : "",
      responsibilities: position ?  position.responsibilities : "",
      accomplishments:position ?  position.accomplishments: "",
      new_organization: "",
      new_position: ""
    }
    const [state, setState] = useState(initState);
    const [tagsPosition, setTagsPosition] = useState([]);
    const [tagsEntreprise, setTagsEntreprise] = useState([]);
    const [locations, setLocations]= useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogPosition, setOpenDialogPosition] = React.useState(false);
    const spinner = useSelector(state=>state.profile.add_experience.spinner)
    const Editspinner = useSelector(state=>state.profile.edit_experience.spinner)
    const errorEx = useSelector(state=>state.profile.add_experience.error)
    const message = useSelector(state=>state.profile.add_experience.message)
    const deleteMessage = useSelector(state=>state.profile.delete_experience.message)
    const editMessage = useSelector(state=>state.profile.edit_experience.message)
    const [open, setOpen] = React.useState(false);
   // const {userId} =useParams();
    const userId = useSelector(state =>  state.users.userSettings.slug)
    const  dispatch =useDispatch();
    const [inputValuePosition, setInputValuePosition] = React.useState('');
    const [inputValueOrganization, setInputValueOrganization] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const [value, setValue] = React.useState(options[0]);
    const [selectedStartDate, setSelectedStartDate] = React.useState(new Date(position ? position.start_date: '2014-08-18T21:11:54'));
    const [selectedEndDate, setSelectedEndDate] = React.useState(new Date(position ? position.end_date : '2014-08-18T21:11:54'));
    const [organizations, setOrganizations] = React.useState([]);
    const [positions, setPositions] = React.useState([]);
    const loading = open && organizations.length === 0;
    const loadingPosition = open && positions.length === 0;
    console.log("Position edit" , position)
    const handleChange =(event)=>{
      setState({...state, [event.target.name]:event.target.value});
    }
  
    const handleAddLocation=(item)=>{
      setLocations([...locations.filter(obj=>obj.place_id!==item.place_id), item])
  }
    const handleSelectPosition=(test,elt, reason)=>{
      setTagsPosition(elt);
    }
    const handleSelectEntreprise=(test,elt, reason)=>{
      setTagsEntreprise(elt);
    }
    const handleSearchChangePosition=(event)=>{
      dispatch(handleSearchPosition({q:event.target.value}))
    }
    const handleSearchChangeCompany=(event)=>{
      dispatch(handleSearchCompany({q:event.target.value}))
    }

    const handleDateStartChange = (date) => {
      setSelectedStartDate(date);
      console.log("date" , setSelectedStartDate(date))
    };
    const handleDateEndChange = (date) => {
      setSelectedEndDate(date);
    };

  const handleCompanySearch =(query) =>{

    if (!query) {
      return;
    }
    const url = `/search/companies-positions/?q=${query}`
   // getItem(url, GET_COMPANY_SEARCH);
  }

  const handlePositionSearch =(query) => {

    if (!query) {
      return;
    }
    const url = `/search/positions/?q=${query}`
   // getItem(url, GET_POSITION_SEARCH);
  }

  const updateLocationId=(data) =>{
    setState({ location: data, });
  }
  const loader=<CircularProgress  color="primary"/>;

  const handleRemoveExperience = (positionId) =>{
    const saveData={
      positionId:positionId,
      userId:userId
    }
    console.log("remove Education response : ", saveData);
    dispatch(handleDeleteExperience(saveData))
    }

  const submit=(event)=>{
    event.preventDefault();
    


   const data={
    locations:locations.map(item=>item.data),
    organization_id:tagsEntreprise.length !== 0 ? tagsEntreprise.organization_id: "",
    position_id:tagsPosition.length !== 0 ? tagsPosition.position_id:" ",
    description: state.description?state.description:"null",
    responsibilities:state.responsibilities?state.responsibilities:"",
    accomplishments:state.accomplishments?state.accomplishments:"",end_date : selectedEndDate,
    start_date : selectedStartDate,
    new_organization:tagsEntreprise.organization_id  ? "" : inputValueOrganization ,
    new_position:tagsPosition.position_id  ? "" : inputValuePosition ,
   }
   console.log("inputValueOrganization" , inputValueOrganization)
   console.log("inputValuePosition" , inputValuePosition)
   console.log("Data" , data)
  
   dispatch(handleAddExperience({...state,
    locations:locations.map(item=>item.data),
    organization_id:tagsEntreprise.length !== 0 ? tagsEntreprise.organization_id: "",
    position_id:tagsPosition.length !== 0 ? tagsPosition.position_id:" ",
    description: state.description?state.description:"null",
    responsibilities:state.responsibilities?state.responsibilities:"",
    accomplishments:state.accomplishments?state.accomplishments:"",
    end_date : selectedEndDate,
    start_date : selectedStartDate,
    new_organization:tagsEntreprise.organization_id  ? "" : inputValueOrganization ,
    new_position:tagsPosition.position_id  ? "" : inputValuePosition ,
   }))
 
  }

  const Editsubmit=(event)=>{
    event.preventDefault();
    


   const data={
    locations:locations.map(item=>item.data),
    organization_id:tagsEntreprise.length !== 0 ? tagsEntreprise.organization_id: position.organization_id.id,
    position_id:tagsPosition.length !== 0 ? tagsPosition.position_id: position.position_id.id,
    description: state.description?state.description:position.description,
    responsibilities:state.responsibilities?state.responsibilities:position.responsibilities,
    accomplishments:state.accomplishments?state.accomplishments:position.accomplishments,
    end_date : selectedEndDate,
    start_date : selectedStartDate,
    new_organization:tagsEntreprise.organization_id  ? "" : inputValueOrganization ,
    new_position:tagsPosition.position_id  ? "" : inputValuePosition ,
   }
   console.log("inputValueOrganization" , inputValueOrganization)
   console.log("inputValuePosition" , inputValuePosition)
   console.log("Data" , data)
 
    
   dispatch(handleEditExperience({...state, positionId,
    locations:locations.map(item=>item.data),
    organization_id:tagsEntreprise.length !== 0 ? tagsEntreprise.organization_id: position.organization_id.id,
    position_id:tagsPosition.length !== 0 ? tagsPosition.position_id: position.position_id.id,
    description: state.description?state.description:position.description,
    responsibilities:state.responsibilities?state.responsibilities:position.responsibilities,
    accomplishments:state.accomplishments?state.accomplishments:position.accomplishments,
    end_date : selectedEndDate,
    start_date : selectedStartDate,
    new_organization:tagsEntreprise.organization_id  ? "" : inputValueOrganization ,
    new_position:tagsPosition.position_id  ? "" : inputValuePosition ,
   }))
  }
  

  React.useEffect(() => {
    let active = true;
  
    if (!loading) {
      return undefined;
    }
    if (!loadingPosition) {
      return undefined;
    }
  
    (async () => {
      const positionList = await fetch(API_BASE_URL+'/en/v3/searches/search/positions/?q=&client='+CLIENT_ID);
      const organizations = await fetch(API_BASE_URL+'/en/v3/searches/search/results/organizations/?q=&client='+CLIENT_ID);
      console.log("organizationsList" , organizations)
      console.log("positionList" , positionList)
      await sleep(1e3); // For demo purposes.
      const organizationsList = await organizations.json();
      const positions = await positionList.json();
  
  
      if (active) {
        setPositions(positions.results)
        setOrganizations(organizationsList.results.organizations)
      }
    })();
  
    return () => {
      active = false;
      };
    }, [loading , loadingPosition]);
  
  
    React.useEffect(() => {
      if (!openDialog) {
        setOrganizations([]);
      }
    }, [openDialog]);
  
    React.useEffect(() => {
      if (!openDialogPosition) {
        setPositions([]);
      }
    }, [openDialogPosition]);
  
   

    

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

    return (
      
      <div className="">
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
      {props.positionId ?
      <Icon className="fas fa-pen" style={{ color: "#ccc"}} /> 
      :
      <Icon className="fa fa-plus-circle" style={{ color: "#ccc"}} />
      }
    </Button>
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
       {props.positionId ? <IntlMessages id="edit_experience"/> :  <IntlMessages id="add_experience"/>} 
      </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <div className="row">
                 {errorEx ?<Alert severity="danger" onClose={()=>dispatch(AddExperienceResetMessages())}>{errorEx}</Alert>: null}
                {error ?<Alert severity="danger" onClose={()=>dispatch(AddExperienceResetMessages())}>{error}</Alert>: null}
                {serverError ?<Alert severity="danger" onClose={()=>dispatch(AddExperienceResetMessages())}>{serverError.message}</Alert>: null}
                {message?<Alert  severity="success"  onClose={()=>dispatch(AddExperienceResetMessages())}>{message}</Alert>: null}
                {deleteMessage?<Alert severity="danger"  onClose={()=>dispatch(AddExperienceResetMessages())}>{deleteMessage}</Alert>: null}
                {editMessage?<Alert severity="success"  onClose={()=>dispatch(EditExperienceResetMessages())}>{editMessage}</Alert>: null}
               <div className="col-lg-12">
               <Form onSubmit={props.positionId ? Editsubmit : submit}>                            
                        <Autocomplete
                          fullWidth
                          id="position_autocomplete"
                          size="small"
                          freeSolo
                          disableClearable
                          open={openDialogPosition}
                          onOpen={() => {
                            setOpenDialogPosition(true);
                          }}
                          onClose={() => {
                            setOpenDialogPosition(false);
                          }}
                          onChange={handleSelectPosition}
                          options={positions}
                          getOptionLabel={(option) => option.title}
                          value={value}
                          inputValue={inputValuePosition}
                          onInputChange={(event, newInputValue) => {
                            setInputValuePosition(newInputValue);}}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option.title}
                                size="small"
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField {...params} onChange={handleSearchChangePosition} variant="outlined" 
                            label="Titre"
                            margin="normal"
                            placeholder="Ex: Chef d'entreprise" />
                          )}
                        /> 
                   

                        <Row> 
                          <Col sm={12}>
                          <Autocomplete
                            fullWidth
                            id="organization_id"
                            name="organization_id"
                            size="small"
                            freeSolo
                            disableClearable
                            open={openDialog}
                            onOpen={() => {
                              setOpenDialog(true);
                            }}
                            onClose={() => {
                              setOpenDialog(false);
                            }}
                            onChange={handleSelectEntreprise}
                            options={organizations}
                            getOptionLabel={(option) => option.name}
                            value={value}
                            inputValue={inputValueOrganization}
                            onInputChange={(event, newInputValue) => {
                            setInputValueOrganization(newInputValue);}}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  label={option.name}
                                  size="small"
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField {...params} onChange={handleSearchChangeCompany} variant="outlined" 
                              label={<IntlMessages id="entreprise"/>}
                              margin="normal"
                              placeholder="Ex: Wutiko" />
                            )}
                          />
                          </Col>
                          <Col md={12}>
                          <Row>   
                        
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <Col md={6}>
                                  <KeyboardDatePicker
                                    margin="normal"
                                    id="start_date"
                                    name="start_date"
                                    label={<IntlMessages id="start_date"/>}
                                    format="MM/dd/yyyy"
                                    value={selectedStartDate}
                                    selected={state.start_date}
                                    onChange={handleDateStartChange}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                  />  
                                  
                                  </Col>
                                  <Col md={6}>
                                      <KeyboardDatePicker
                                        margin="normal"
                                        id="end_date"
                                        name="end_date"
                                        label={<IntlMessages id="end_date"/>}
                                        format="MM/dd/yyyy"
                                        value={selectedEndDate}
                                        selected={state.end_date}
                                        onChange={handleDateEndChange}
                                        KeyboardButtonProps={{
                                          'aria-label': 'change date',
                                        }}
                                      />   
                                
                            </Col>
                          </MuiPickersUtilsProvider> 
                         
                        </Row>
                        </Col>
                        <Col sm={12}>
                          {
                                
                                  locations.map(item=>{
                                      return(
                                        <Chip label={`${item.data.city_name} ${item.data.state_province_name} ${item.data.country_name}`} onDelete={()=>setLocations(locations.filter(obj=>obj.place_id!==item.place_id))}/>
                                      )
                                    })
                          }
                        </Col>
                    <Col sm={12}>
                          <Field
                              fullWidth
                              value={state.location_id}
                              component={LocationSearchInput}
                              addLocation={handleAddLocation}
                              placeholder="Location(s)"
                              label="Location(s)"
                          />
                      </Col> 
                      </Row>
                      <Row>
                      <Col sm={12}   className="textarea">
                        <TextField
                            id="description"
                            name="description"
                            label={<IntlMessages id="description"/>}
                            fullWidth
                            hintText={<IntlMessages id="about"/>}
                            floatingLabelText={<IntlMessages id="about"/>}
                            autoComplete={<IntlMessages id="description"/>}
                            onChange={handleChange}
                            multiLine={true} rows={2}
                            value={state.description}
                          />
                      </Col>
                      </Row>

                      <Row>
                      <Col sm={12}   className="textarea">
                        
                       <TextField
                            id="responsibilities"
                            name="responsibilities"
                            label={<IntlMessages id="responsibilities"/>}
                            fullWidth
                            autoComplete={<IntlMessages id="responsibilities"/>}
                            onChange={handleChange}
                            multiLine={true} rows={2}
                            value={state.responsibilities}
                          />
                      </Col>
                      </Row> 

                      <Row>
                      <Col sm={12}   className="textarea">
                       
                          <TextField
                            id="accomplishments"
                            name="accomplishments"
                            label={<IntlMessages id="accomplishments"/>}
                            fullWidth
                            floatingLabelText={<IntlMessages id="achievements"/>}
                            autoComplete={<IntlMessages id="accomplishments"/>}
                            onChange={handleChange}
                            multiLine={true} rows={2}
                            value={state.accomplishments}
                          />
                      </Col>
                      </Row>
                      
                    {props.positionId ?
                    Editspinner ?loader:<Field name="submit"
                    className="save"
                    component={renderSubmitGroup}
                    onCancel={e => handleClose()}
                    onRemove={
                      e => handleRemoveExperience(positionId)
                      }
                    removeText={<i class="far fa-trash-alt"></i>}
                    />
                      :
                    spinner ?loader:<Field name="submit"
                    className="save"
                    component={renderSubmitGroup}
                    onCancel={e => handleClose()}
                    />}
                    </Form>

              </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>

      {/* <Modal  show={state.showModal} onHide={close}>
    <div className="bloc content-page-interne add-experience"  >

      <div className=""></div>

      </div>
      </Modal> */}
      </div>
    );
  }


// function mapStateToProps(state) {

//   return {
//     axiosInstance: state.user.axiosInstance,
//     userId: state.user.userId,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//    showItemDetails:(url) =>dispatch(showItemDetails(url)),
//    handleAddExperience: (data) => dispatch(handleAddExperience(data)),
//   };
// }
ExperienceForm = reduxForm({
  form: 'ExperienceForm',
  enableReinitialize: true
})(ExperienceForm);

export default (ExperienceForm);