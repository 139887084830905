const initialState = {
    set_news_letter:{
        error:null,
        spinner:false,
        message:null,
        data:{}
    },

 }

 export function newsLetter(state = initialState, action){
 
   switch(action.type) {
   case "HANDLE_SET_NEWS_LETTER":
           return {...state, set_news_letter:{
               ...state.set_news_letter, error:null, spinner:true, message:null, data:action.payload
           }}
   case "SET_NEWS_LETTER_FAILLURE":
           return {...state, set_news_letter:{
               ...state.set_news_letter, error:action.payload, spinner:false, message:null, data:{}
           }}
   case "SET_NEWS_LETTER":
      return {...state, set_news_letter:{
          ...state.set_news_letter, error:null, spinner:false, message:action.payload, data:{}
      }}
   case "RESET_NEWS_LETTER_MESSAGES":
        return {...state, set_news_letter:{
            ...state.set_news_letter, error:null, spinner:false, message:null, data:{}
        }}
     default:
       return state;
   }
 }
