import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import SortIcon from "@material-ui/icons/ArrowDownward";
import {useDispatch, useSelector} from 'react-redux'
import {  handleGetApplicationsDashboard, handleGetFollowersDashboard } from 'actions/dashboard/keysList';
import React,{useState,useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Button } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CardBox from "components/CardBox/index";
import CardMenu from 'components/dashboard/Common/CardMenu';
import {Area, AreaChart, Line, LineChart, ResponsiveContainer} from "recharts";
import ChartCard from "./ChartCard";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import ItemFollowers from './ItemFollowers'
import {arrayMove, SortableContainer} from 'react-sortable-hoc';
import ItemApplicants from "./ItemApplicants";
import ReactPaginate from "react-paginate";
import Grid from '@material-ui/core/Grid';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppModuleHeader from './AppModuleHeader';
import Tab from 'react-bootstrap/Tab'
import { Col, Nav, Row} from 'react-bootstrap';
import {LinkContainer } from 'react-router-bootstrap'; 
import {NavItem} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import ApplicationDashboardPage from "./ApplicationsDashboard"
import SelectedDashboardPage from "./SelectedDashboard"
import InterviewDashboardPage from "./InterviewDashboard"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import FilterCompaniesAdmin from "./filters/FilterCompanies";
import FilterPipelineAdmin from "./filters/FilterPipeline";

const SidebarLeftApplicants = (props) => {

    
  const {t}=props;
  return (
    
    <div className="container-wrap container animated slideInUpTiny animation-duration-3 dashboard">
      <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
       <h2 className="title mb-3 mb-sm-0"><IntlMessages id="list_of_applicants" /> </h2>
        <nav className="mb-0" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <Link to="/dashboard" className="breadcrumb-item">Dashboard</Link>
            <span href="#" className="active breadcrumb-item" aria-current="page">{props.title}</span>
            </ol></nav></div>
             
             <FilterPipelineAdmin/> 
             <Router>
              <div className="animated slideInUpTiny animation-duration-3 row">
              <div className="col-md-3 sibebar dashboard module-side">
              <ul className="container nav  module-nav">
                <LinkContainer to='/dashboard/applicants/pipeline' className="nav-item" onlyActiveOnIndex>
                  <NavItem eventKey="pipeline" xs={12} sm={12}><IntlMessages id="all" /></NavItem>
                </LinkContainer>     
                <LinkContainer to='/dashboard/applicants/selected' className="nav-item">
                  <NavItem eventKey="selected" xs={12} sm={12}><IntlMessages id="selected" /></NavItem>
                </LinkContainer>
                <LinkContainer to='/dashboard/applicants/interviews' className="nav-item">
                  <NavItem eventKey="interviews" xs={12} sm={12}><IntlMessages id="interviews" /></NavItem>
                </LinkContainer>
                <LinkContainer to='/dashboard/applicants/recruited' className="nav-item">
                  <NavItem eventKey="recruited" xs={12} sm={12}><IntlMessages id="recruited" /></NavItem>
                </LinkContainer>
                </ul>
            </div>
            <Switch>
            <div className="col-md-9 dashboard-col-9">
            <Route exact  path="/dashboard/applicants/pipeline/">
            <ApplicationDashboardPage title="All"
            /> 
            </Route>
            <Route path="/dashboard/applicants/selected/">
            <SelectedDashboardPage title="Selected"/>
            </Route>
            <Route path="/dashboard/applicants/interviews/">
            {/* <InterviewDashboardPage title="Interviews"/>  */}
             </Route>
            <Route path="/dashboard/applicants/recruited/">
            {/* <ApplicationDashboardPage title="Recruited"/>  */}
            </Route>
            </div>
        </Switch>
        </div>
        </Router> 
    </div>
  );
};
export default  (SidebarLeftApplicants);