import axios from 'axios';
import { API_BASE_URL, DEFAULT_AXIOS_PARAMS } from './config';
//import browser from 'detect-browser';
import * as Bowser from "bowser"

// var jQuery = require('jquery') ;
import $ from 'jquery';

export function logError(url, message) {
  
  const browser = require("bowser");
  /* Regexp to remove the password from the logs
    - / : delimiter
    - password : expression we seek
    - . : any char after 'password' expression, aka the password itself
    - *? : use the shortest match possible
    - (,|\}) : possible ending chars in an object, comma or curly brace
    -/ : delimiter
    - gi : flags, ignore case and replace password for all matching expressions
    - $1 : get the content of the parenthesis, aka "," or "}", depending on the case
  */
  const msg = message.replace(/password.*?(,|\})/gi, 'password:"****"$1')
  const browserData = JSON.stringify("Browser : " + browser.name + browser.version + "\n");

  const data = {
    url: url,
    message: browserData + msg,
  }

  axios.post(API_BASE_URL + '/en/v3/profiles/logs/', data, DEFAULT_AXIOS_PARAMS);
 }

export function getHashUrlParameterByName(name, url) {

  let match = RegExp('[#&]' + name + '=([^&]*)').exec(url);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export function passwordReplacer(key, value) {

  switch(key) {
    case 'password': case 'Password':
      return '';

    default:
      return value;
  }
}


export function isEmpty(obj) {
  for(var key in obj) {
    if(obj.hasOwnProperty(key))
      return false;
  }
  return true;

}




$(document).ready(function(){

      $('.show-password').click(function() {
        if($(this).prev('input').prop('type') === 'password') {
          //Si c'est un input type password
          $(this).prev('input').prop('type','text');
          $(this).text('cacher');
        } else {
          //Sinon
          $(this).prev('input').prop('type','password');
          $(this).text('afficher');
        }
      });

    });

