import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { CLIENT_ID } from "config";
import { SaveCompanySuccess, SaveCompanyError} from "actions/companies/getCompanies";

const SaveCompany = async (instance, companyId)=>
    await instance.post(`/organizations/${companyId}/profile-favorite/add/`, {})
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })
function* SaveCompanyAction(actions){
        const state = yield select((state)=>state.users);
        let axiosInstance;
        if(state.authenticated){
            axiosInstance=state.axiosInstance
        }else{
            axiosInstance=state.blankAxiosInstance
        }
        const data =yield call(SaveCompany,axiosInstance, actions.data.companyId);
        if(data.message){
           yield put({type:"HANDLE_GET_COMPANY_DETAILS", slug:actions.data.slug})
            yield put(SaveCompanySuccess(data.message));
        }else{
            console.log("data.error", data, data.error)
            yield put(SaveCompanyError(data.error));
        }
    }
function* HandleSaveCompany(){
    
    yield takeEvery("HANDLE_SAVE_COMPANY", SaveCompanyAction)
}
export default function* rootSaga() {
    yield all([fork(HandleSaveCompany)]);
}