
const initialState = {
    search_education:{
        error:null,
        spinner:false,
        count:null,
        educations:[]
    },

 }

 export function educations(state = initialState, action){
 
   switch(action.type) {

    case "GET_EDUCATIONS_SEARCH_FAILLURE":
            return {...state, search_education:{
                ...state.search_education, error:action.payload, spinner:false, educations:[]
            }}
   case "GET_EDUCATIONS_SEARCH":
           return {...state, search_education:{
               ...state.search_education, error:null, spinner:false, educations:action.payload.results
           }}
     default:
       return state;
   }
 }
