
  export const createFormInitState={
    title: "",
    title_en: "",
    title_fr: "",
    intro: "",
    button_text: "",
    response: "",
    redirect_url: "",
    status:null,
    publish_date: null,
    is_scored: false,
    expiry_date: null,
    login_required: false,
    send_email: false,
    email_from: "",
    email_copies: "",
    email_subject: "",
    email_message: "",
    send_delivery: false,
    delivery_to: "",
    delivery_from: "",
    delivery_copies: "",
    delivery_message: "",
    form_linked: null,
    form_type: null
  }