import { INIT_ENDED, SET_SESSION_DATA, SET_USER_RIGHT, LOGOUT,
    CREATE_BLANK_AXIOS_INSTANCE, CREATE_AXIOS_INSTANCE, RESET_AXIOS_INSTANCE,
    REGISTER_REQUEST, REGISTER_FAILURE, REGISTER_SUCCESS,
    CLOSE_ACCOUNT_REQUEST, CLOSE_ACCOUNT_SUCCESS, CLOSE_ACCOUNT_FAILURE,
    DEACTIVATE_ACCOUNT_REQUEST, DEACTIVATE_ACCOUNT_SUCCESS, DEACTIVATE_ACCOUNT_FAILURE,
    GET_PROFILE_SETTINGS, GET_CURRENCY_LIST, GET_COUNTRY_LIST,
    UPDATE_DEFAULT_LANGUAGE_REQUEST, UPDATE_DEFAULT_LANGUAGE_SUCCESS, UPDATE_DEFAULT_LANGUAGE_FAILURE,
    UPDATE_DEFAULT_SITTING_REQUEST, UPDATE_DEFAULT_SITTING_SUCCESS, UPDATE_DEFAULT_SITTING_FAILURE,
    UPDATE_DEFAULT_COUNTRY_REQUEST, UPDATE_DEFAULT_COUNTRY_SUCCESS, UPDATE_DEFAULT_COUNTRY_FAILURE,
    GET_NOTIFICATIONS, GET_NOTIFICATIONS_ALL, UPDATE_NOTIFICATIONS_ALL,ACTIVATE_ACCOUNT,ACTIVATE_ACCOUNT_FAILURE,SEND_INVITATION,CRETATE_OPPORTUNITY_ALERT} from '../../actions/user';
  
  
  const initialState = {
    initEnded: false,
    status: null,
    error: null,
    profile:{},
    blankAxiosInstance: {},
    axiosInstance: {},
    userId: null,
    accountType: 'default', 
    profilePictureUrl: '',
    first_name: '',
    last_name: '',
    adminJobs: [],
    adminCompanies: [],
    adminGroups: [],
    adminEvents: [],
    registration_error:null,
    registration_message:null,
    acccount_activation:{
      success:'',
      error:'',
      data:{}
    },
    conversation_messages:{
      next:null,
      hasMoreItems:true,
      messages:[],
      success:null,
      error:null,
      new_conversation:null
    },
    conversation:{
      messages_conversations:[],
      next:null,
      hasMoreItems:true,
      participants:[]
    },
    send_interview:{
      error:null,
      success:null,
      spinner:false,
    },
    settings: {
      closingAccountStatus: null,
      closingAccountError: null,
      deactivatingAccountStatus: null,
      deactivatingAccountError: null,
      data: {},
      defaultLanguageStatus: null,
      defaultLanguageError: null,
      currencyList: [],
      defaultStatus: null,
      defaultError: null,
      countryList: [],
      defaultCountryStatus: null,
      defaultCountryError: null,
    },
    notifications: {
      list: [],
      count: 0,
    },
    notificationsAll: {
      list: [],
      count: 0,
      next: null,
      hasMoreItems: true,
    },
    totalCompanies:0,
    totalGroups:0,
    alerts:{
     message:null,
     error:null,
     spinner:false,
     list:[],
     list_spinner:false,
     update_spinner:false,
     update_message:null,
     update_error:null
    },
     pending_applications:{
      list:[],
      error:null,
      success:null,
      spinner:false,
      count:null,
      next:null
    },
    applications_responses:{
      list:[],
      error:null,
      success:null,
      spinner:false,
      count:null,
      next:null,
      accept_error: null,
      rejet_error: null,
      rejet_message: null,
      accept_message: null,
      accept_spinner: false,
      rejet_spinner: false,
    },
    applications_interviews:{
      list:[],
      error:null,
      success:null,
      spinner:false,
      count:null,
      next:null
    },
    applications_archived:{
      list:[],
      error:null,
      success:null,
      spinner:false,
      count:null,
      next:null
  },
    add_alert:{
      message:null,
      error:null,
      spinner:false,
    },
    remove_alert:{
      message:null,
      error:null,
      spinner:false,
    },
  
    // SPONTANEOUS
    pending_applications_sponta:{
      list:[],
      error:null,
      success:null,
      spinner:false,
      count:null,
      next:null
    },
    applications_responses_sponta:{
      list:[],
      error:null,
      success:null,
      spinner:false,
      count:null,
      next:null,
      accept_error: null,
      accept_message: null,
      accept_spinner: false,
    },
    applications_interviews_sponta:{
      list:[],
      error:null,
      success:null,
      spinner:false,
      count:null,
      next:null
    },
    applications_archived_sponta:{
      list:[],
      error:null,
      success:null,
      spinner:false,
      count:null,
      next:null
    },
    application_feed_back:{
      error:null,
      message:null,
      spinner:false,
    },
    send_interview_rejection:{
      error:null,
      message:null,
      spinner:false,
    },
    application_feed_back:{
      error:null,
      message:null,
      spinner:false,
    },
    get_interview:{
      data:{},
      error:null,
      spinner:null
    },
    select_applicant:{
      error:null,
      success:null,
      spinner:false,
    },
    reject_applicant:{
      error:null,
      success:null,
      spinner:false,
    },
    create_event:{
      error:null,
      message:null,
      data:null,
      spinner:false
    },
    handle_remove_application_archived:{
      error:null,
      spinner:false,
      message:null
    },
  
  }
  
  // Possible status :
  // - registering
  // - validating_account
  // - authenticated
  // - logout
  
  // Possible acccount types :
  // - default
  // - ACCOUNT_TYPE_RECRUITER
  
  // There are two reducers for now because it wil be easier to merge with other code
  export function user(state = initialState, action){
  
    switch(action.type) {
  
      case REGISTER_REQUEST:return {...state, status: 'registering', error: null}
      case REGISTER_SUCCESS:return {...state, status: 'validating_account', error: null,registration_message:action.payload.message,registration_error:null}
      case `RESET_REGISTER_MESSAGES`:return {...state, status: 'validating_account', error: null,registration_message:null,registration_error:null}
      case REGISTER_FAILURE: return {...state, status: 'registering', error: action.payload.error || action.payload.message,registration_message:null,registration_error:action.payload.error}
  
      case `SELECT_APPLICANT`: return {...state,select_applicant:{...state.select_applicant,error:null, success:action.payload.message, spinner:false}}
      case `SELECT_APPLICANT_FAILURE`: return {...state,select_applicant:{...state.select_applicant,error:action.payload.error, success:null, spinner:false}}
      case `RESET_SELECT_APPLICANT_MESSAGES`: return {...state,select_applicant:{...state.select_applicant,error:null, success:null, spinner:false}}
      case `HANDLE_SELECT_APPLICANT`: return {...state,select_applicant:{...state.select_applicant,error:null, success:null, spinner:true}}
  
      case `REJECT_APPLICANT`: return {...state,reject_applicant:{...state.reject_applicant,error:null, success:action.payload.message, spinner:false}}
      case `REJECT_APPLICANT_FAILURE`: return {...state,reject_applicant:{...state.reject_applicant,error:action.payload.error, success:null, spinner:false}}
      case `RESET_REJECT_APPLICANT_MESSAGES`: return {...state,reject_applicant:{...state.reject_applicant,error:null, success:null, spinner:false}}
      case `HANDLE_REJECT_APPLICANT`: return {...state,reject_applicant:{...state.reject_applicant,error:null, success:null, spinner:true}}
      case `REMOVE_ARCHIVED_APPLICATION`:return {...state,handle_remove_application_archived:{...state.handle_remove_application_archived,error:null, message:action.payload.message,spinner:false}}
      case `REMOVE_ARCHIVED_APPLICATION_FAILURE`:return {...state,handle_remove_application_archived:{...state.handle_remove_application_archived,error:action.payload.error, message:null,spinner:false}}
      case `HANDLE_REMOVE_ARCHIVED_APPLICATION`:return {...state,handle_remove_application_archived:{...state.handle_remove_application_archived,error:null, message:null,spinner:true}}
      case `RESET_REMOVE_ARCHIVED_APPLICATION_MESSAGES`:return {...state,handle_remove_application_archived:{...state.handle_remove_application_archived,error:null, message:null,spinner:false}}
      case `GET_APPLICATION_INTERVIEW_INSTANCE`:
        return {
          ...state, get_interview:{
            ...state.get_interview, data:action.payload, error:null, spinner:false
          }
        }
      case `GET_APPLICATION_INTERVIEW_INSTANCE_FAILURE`:
        return {
          ...state, get_interview:{
            ...state.get_interview, data:{}, error:action.payload.error, spinner:false
          }
        }
      case `HANDLE_GET_APPLICATION_INTERVIEW_INSTANCE`:
        return {
          ...state, get_interview:{
            ...state.get_interview, data:{}, error:null, spinner:true
          }
        }
      case `RESET_GET_APPLICATION_INTERVIEW_INSTANCE_MESSAGES`:
        return {
          ...state, get_interview:{
            ...state.get_interview, data:{}, error:null, spinner:false
          }
        }
        case `HANDLE_CREATE_EVENT`:
        return {...state,create_event:{...state.create_event,data:null, spinner:true, message:null, error:null}}
        case `GET_EVENTS`:
         return {...state,events:{...state.alerts, spinner:false, next: action.payload.next,count:action.payload.count, list:action.payload.results}}
         case `CREATE_EVENT`:
         return {...state,create_event:{...state.create_event, spinner:false, message:action.payload.message,data:action.payload.data}}
         case `CREATE_EVENT_FAILURE`:
         return {...state,create_event:{...state.create_event, spinner:false, error:action.payload.error}}
         case `GET_EVENTS_FAILURE`:
         return {...state,events:{...state.alerts, spinner:false, error:action.payload.error}}
         case 'RESET_CREATE_EVENT_MESSAGES':
           return {...state,create_event:{...state.create_event, spinner:false, message:null,error:null}}
      // SPONTANEOUS
  
      case 'GET_APPLICATIONS_ARCHIVED_SPONTA':
      return {...state, applications_archived_sponta:{...state.applications_archived_sponta, spinner:false, list:action.payload.results, error:null, next:action.payload.next, count:action.payload.count,previous:action.payload.previous}}
    case 'HANDLE_GET_APPLICATIONS_ARCHIVED_SPONTA':
      return {...state, applications_archived_sponta:{...state.applications_archived_sponta, spinner:true,  error:null}}
      case 'GET_APPLICATIONS_ARCHIVED_FAILURE_SPONTA':
      return {...state, applications_archived_sponta:{...state.applications_archived_sponta, spinner:false,  error:action.payload.error}}
      // -------------------
      case 'GET_APPLICATIONS_INTERVIEWS_SPONTA':
      return {...state, applications_interviews_sponta:{...state.applications_interviews_sponta, spinner:false, list:action.payload.results, error:null, next:action.payload.next, count:action.payload.count,previous:action.payload.previous}}
    case 'HANDLE_GET_APPLICATIONS_INTERVIEWS_SPONTA':
      return {...state, applications_interviews_sponta:{...state.applications_interviews_sponta, spinner:true,  error:null}}
      case 'GET_APPLICATIONS_INTERVIEWS_FAILURE_SPONTA':
      return {...state, applications_interviews_sponta:{...state.applications_interviews_sponta, spinner:false,  error:action.payload.error}}
      // -------------------
      case 'GET_APPLICATIONS_RESPONSE_SPONTA':
      return {...state, applications_responses_sponta:{...state.applications_responses_sponta, spinner:false, list:action.payload.results, error:null, next:action.payload.next, count:action.payload.count,previous:action.payload.previous}}
    case 'HANDLE_GET_APPLICATIONS_RESPONSE_SPONTA':
      return {...state, applications_responses_sponta:{...state.applications_responses_sponta, spinner:true,  error:null}}
      case 'GET_APPLICATIONS_RESPONSE_FAILURE_SPONTA':
      return {...state, applications_responses_sponta:{...state.applications_responses_sponta, spinner:false,  error:action.payload.error}}
      // ------------------
      case 'GET_PENDING_APPLICATIONS_SPONTA':
      return {...state, pending_applications_sponta:{...state.pending_applications_sponta, spinner:false, list:action.payload.results, error:null, next:action.payload.next, count:action.payload.count,previous:action.payload.previous}}
    case 'HANDLE_GET_PENDING_APPLICATIONS_SPONTA':
      return {...state, pending_applications_sponta:{...state.pending_applications_sponta, spinner:true,  error:null}}
      case 'GET_PENDING_APPLICATIONS_FAILURE_SPONTA':
      return {...state, pending_applications_sponta:{...state.pending_applications_sponta, spinner:false,  error:action.payload.error}}
      // 
      case `ADD_INTERVIEW_FEEDBACK`:
      return {
        ...state, application_feed_back:{
          ...state.application_feed_back, message:action.payload.message, error:null, spinner:false
        }
      }
   case `ADD_INTERVIEW_FEEDBACK_FAILURE`:
      return {
        ...state, application_feed_back:{
          ...state.application_feed_back, message:null, error:action.payload.error
        }
      }
   case `HANDLE_ADD_INTERVIEW_FEEDBACK`:
      return {
        ...state, application_feed_back:{
          ...state.application_feed_back, message:null, error:null, spinner:null
        }
      }
   case `RESET_ADD_INTERVIEW_FEEDBACK_MESSAGES`:
      return {
        ...state, application_feed_back:{
          ...state.application_feed_back, message:null, error:null, spinner:false
        }
      }
      
      // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------
  
   case `SEND_INTERVIEW_REJECTION`:
     return {
         ...state, send_interview_rejection:
           { 
               ...state.send_interview_rejection, error:null, message:action.payload.message, spinner:false
         }}
   case `HANDLE_SEND_INTERVIEW_REJECTION`:
     return {
       ...state, send_interview_rejection:
         {
           ...state.send_interview_rejection, error:null, message:null, spinner:true
         }}
   case `SEND_INTERVIEW_REJECTION_FAILURE`:
     return {
         ...state, send_interview_rejection:
           {
           ...state.send_interview_rejection, error:action.payload.error, message:null, spinner:false
       }}
   case `RESET_SEND_INTERVIEW_REJECTION_MESSAGES`:
         return {
             ...state, send_interview_rejection:
               { 
             ...state.send_interview_rejection, error:null, message:null, spinner:false
           }}
      // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------ 
  
      case 'GET_APPLICATIONS_ARCHIVED':
      return {...state, applications_archived:{...state.applications_archived, spinner:false, list:action.payload.results, error:null, next:action.payload.next, count:action.payload.count,previous:action.payload.previous}}
      case 'HANDLE_GET_APPLICATIONS_ARCHIVED':
      return {...state, applications_archived:{...state.applications_archived, spinner:true,  error:null}}
      case 'GET_APPLICATIONS_ARCHIVED_FAILURE':
      return {...state, applications_archived:{...state.applications_archived, spinner:false,  error:action.payload.error}}
  
      // case `SEND_INTERVIEW_INVITATION_FAILURE`:
      //   return {...state, send_interview:{...state.send_interview, spinner:true, error:null, success:null}}
  
      case `SEND_INTERVIEW_INVITATION_FAILURE`:return {...state, send_interview:{...state.send_interview, spinner:false, error:action.payload.error, success:null}}
      case `SEND_INTERVIEW_INVITATION`:return {...state,send_interview:{...state.send_interview, spinner:false, error:null, success:action.payload.message}}
      case `RESET_SEND_INTERVIEW_INVITATION_MESSAGES`:return {...state,send_interview:{...state.send_interview, spinner:false, error:null, success:null}}
      case `HANDLE_SEND_INTERVIEW_INVITATION`:return {...state,send_interview:{...state.send_interview, spinner:true, error:null, success:null}}
     
  
      case 'OPPORTUNITY_APPLICATIONS_INTERVIEW_ACCEPT_FAILURE':
      return {...state, applications_responses:{...state.applications_responses, accept_spinner:false, accept_message:null,accept_error:action.payload.error}}
  
      case 'HANDLE_OPPORTUNITY_APPLICATIONS_INTERVIEW_ACCEPT':
      return {...state, applications_responses:{...state.applications_responses, accept_spinner:true, accept_message:null,accept_error:null}}
  
      case 'OPPORTUNITY_APPLICATIONS_INTERVIEW_ACCEPT':
      return {...state, applications_responses:{...state.applications_responses, accept_spinner:false, accept_message:action.payload.message}}
     
      case 'OPPORTUNITY_APPLICATIONS_INTERVIEW_REJET_FAILURE':
      return {...state, applications_responses:{...state.applications_responses, rejet_spinner:false, accept_message:null,accept_error:action.payload.error}}
  
      case 'HANDLE_OPPORTUNITY_APPLICATIONS_INTERVIEW_REJET':
      return {...state, applications_responses:{...state.applications_responses, rejet_spinner:true, accept_message:null,accept_error:null}}
  
      case 'OPPORTUNITY_APPLICATIONS_INTERVIEW_REJET':
      return {...state, applications_responses:{...state.applications_responses, rejet_spinner:false, accept_message:action.payload.message}}
     
      case 'GET_APPLICATIONS_INTERVIEWS':
      return {...state, applications_interviews:{...state.applications_interviews, spinner:false, list:action.payload.results, error:null, next:action.payload.next, count:action.payload.count,previous:action.payload.previous}}
    case 'HANDLE_GET_APPLICATIONS_INTERVIEWS':
      return {...state, applications_interviews:{...state.applications_interviews, spinner:true,  error:null}}
      case 'GET_APPLICATIONS_INTERVIEWS_FAILURE':
      return {...state, applications_interviews:{...state.applications_interviews, spinner:false,  error:action.payload.error}}
  
      case 'GET_APPLICATIONS_RESPONSE':
        return {...state, applications_responses:{...state.applications_responses, spinner:false, list:action.payload.results, error:null, next:action.payload.next, count:action.payload.count,previous:action.payload.previous}}
      case 'HANDLE_GET_APPLICATIONS_RESPONSE':
        return {...state, applications_responses:{...state.applications_responses, spinner:true,  error:null}}
        case 'GET_APPLICATIONS_RESPONSE_FAILURE':
        return {...state, applications_responses:{...state.applications_responses, spinner:false,  error:action.payload.error}}
  
  
      case 'GET_PENDING_APPLICATIONS':
        return {...state, pending_applications:{...state.pending_applications, spinner:false, list:action.payload.results, error:null, next:action.payload.next, count:action.payload.count,previous:action.payload.previous}}
      case 'HANDLE_GET_PENDING_APPLICATIONS':
        return {...state, pending_applications:{...state.pending_applications, spinner:true,  error:null}}
        case 'GET_PENDING_APPLICATIONS_FAILURE':
        return {...state, pending_applications:{...state.pending_applications, spinner:false,  error:action.payload.error}}
  
      case 'REMOVE_ALERT':
      return {...state, remove_alert:{...state.remove_alert, error: null,  message: action.payload.message, spinner:false}}
    case 'REMOVE_ALERT_FAILURE':
      return {...state, remove_alert:{...state.remove_alert, error: action.payload.error,  message: null, spinner:false}}  
    case 'HANDLE_REMOVE_ALERT':
      return {...state, remove_alert:{...state.remove_alert, error: null,  message: null, spinner:true}}
  
      case 'ADD_ALERT':
      return {...state, add_alert:{...state.add_alert, error: null,  message: action.payload.message, spinner:false}}
    case 'ADD_ALERT_FAILURE':
      return {...state, add_alert:{...state.add_alert, error: action.payload.error,  message: null, spinner:false}}  
    case 'ADD_ALERT_ALERT':
      return {...state, add_alert:{...state.add_alert, error: null,  message: null, spinner:true}}
    case 'EMPTY_ALERTS_MESSAGES_ERROR':
      return {...state, add_alert:{...state.add_alert, error: null,  message: null}}
  
      case 'EMPTY_PARTICIPANTS_LIST':
      return { ...state,conversation:{...state.conversation, participants:[]}} 
      // Alerts List
      case 'GET_PARTICIPANTS_CONVERSATION_LIST':
      return {
        ...state,conversation:{...state.conversation, participants:action.payload.participants}} 
      case `HANDLE_UPDATE_ALERT`:
        return {...state,alerts:{...state.alerts, update_spinner:true}}
  
      case `UPDATE_ALERT_SUCCESS`:
         return {...state,alerts:{...state.alerts, update_spinner:false,update_message:action.payload.message}}
  
      case `UPDATE_ALERT_FAILURE`:
         return {...state,alerts:{...state.alerts, update_spinner:false,update_error:action.payload.error}}
  
      case `HANDLE_GET_ALERTS_LIST`:
        return {...state,alerts:{...state.alerts, list_spinner:true}}
  
      case 'GET_ALERTS_LIST':
        return {...state,alerts:{...state.alerts,list_spinner:false, list:action.payload.results}}
      // Alerts
      case `${CRETATE_OPPORTUNITY_ALERT}_SUCCESS`:
      return {...state,alerts:{...state.alerts, message:action.payload.message,spinner:false}}
  
      case `${CRETATE_OPPORTUNITY_ALERT}`:
      return {...state,alerts:{...state.alerts, message:action.payload.message,spinner:false}}
  
      case `${CRETATE_OPPORTUNITY_ALERT}_FAILURE`:
      return {...state,alerts:{...state.alerts, error:action.payload.error,spinner:false}}
      case `HANDLE_${CRETATE_OPPORTUNITY_ALERT}`:
         return {...state,alerts:{...state.alerts, error:null, message:null, spinner:true}}
      // Conversations
      case "SET_CONVERSATION_MESSAGES_EMPTY":
      return {...state,conversation_messages:{...state.conversation_messages, messages:[]}}
  
    case LOGOUT:
      return {...state, userId: null, status: 'logout', error: null}
    case "MESSAGES_PROFILES_CONVERSATIONS_LIST":
    if(action.payload.next){
      return {
        ...state,conversation:{...state.conversation, messages_conversations:state.conversation.messages_conversations.concat(action.payload.results),
        next:action.payload.next,
        hasMoreItems:true
      }}
    }else if(action.payload.previous){
      return {
        ...state,conversation:{...state.conversation, messages_conversations:state.conversation.messages_conversations.concat(action.payload.results),
        next:action.payload.next,
        hasMoreItems:false
      }}
    }else{
      return {...state, conversation:{...state.conversation,
        messages_conversations:action.payload.results,
        next:action.payload.next,
        hasMoreItems:false
      }}
    }
  
    case "MESSAGES_PROFILES_ADD_SUCCESS":
      return {...state,conversation_messages:{...state.conversation_messages, 
        new_conversation:{
          id:action.payload.data.conversation_id,
          message_id:action.payload.data.id, 
          message:action.payload.data.content,
          receiver_profile_id: action.payload.data.receiver_profile_id
        }
        }}
    case "SET_MESSAGES_EMPTY":
      return  {...state,conversation_messages:{...state.conversation_messages, success:null, error:null}}
  
    case "MESSAGES_CONVERSATION_ADD_SUCCESS":
       return {...state,conversation_messages:{...state.conversation_messages, success:action.payload.message}}
  
    case "PROFILES_CONVERSATION_MESSAGES_LIST":
      if(action.payload.next || action.payload.previous){
        return {...state,conversation_messages:{...state.conversation_messages, messages:state.conversation_messages.messages.concat(action.payload.results), next:action.payload.next, hasMoreItems:true}}
      }else if(action.payload.previous){
        return {...state,conversation_messages:{...state.conversation_messages, messages:state.conversation_messages.messages.concat(action.payload.results), next:action.payload.next, hasMoreItems:false}}
      }else{
        return {...state,conversation_messages:{...state.conversation_messages, messages:action.payload.results, next:action.payload.next, hasMoreItems:false}}
      }
  
      // End Conversations
      case ACTIVATE_ACCOUNT:
        return {...state, acccount_activation: {...state.acccount_activation, success:action.payload.message,data:action.payload.data}}
      case ACTIVATE_ACCOUNT_FAILURE:
        return {...state, acccount_activation: {...state.acccount_activation, error:action.payload.error}}
      case INIT_ENDED:
        return {...state, initEnded: true}
  
  
  
  
      // case REGISTER_FAILURE:
      //   return {...state, status: 'registering', error: action.payload.error || action.payload.detail}
      // case LOGOUT:
      //   return {...state, userId: null, status: 'logout', error: null}
  
      case "SET_SESSION_DATA_AFTER_PAYMENT":
        const business_new= action.shopping_carts.filter(obj=>obj.paid_product_id.category_id.name==="Business")
        const pro_new= action.shopping_carts.filter(obj=>obj.paid_product_id.category_id.name==="Pro")
        return {
          ...state,
          shopping_carts:{
            business:{
              status:business_new.length!==0?true:false,
              infos:business_new.length!==0?business_new[0]:null
            },
            pro:{
              status:pro_new.length!==0?true:false,
              infos:pro_new.length!==0?pro_new[0]:null
            },
          },
          payment_message:action.message        
        }
      case "SET_SESSION_DATA_AFTER_PAYMENT_FAILURE":
        return{
          ...state,payment_error:action.payload.error
        }
    
      case SET_SESSION_DATA:
        const jobs = action.adminRoles.filter(obj => obj.table_type === 0);
        const companies = action.adminRoles.filter(obj => obj.table_type === 1);
        const events = action.adminRoles.filter(obj => obj.table_type === 3);
        const groups = action.adminRoles.filter(obj => obj.table_type === 2);
        const business= action.shopping_carts.filter(obj=>obj.paid_product_id.category_id.name==="Business");
        const pro= action.shopping_carts.filter(obj=>obj.paid_product_id.category_id.name==="Pro");
        return {
            ...state,
            status: 'authenticated',
            error: null,
            profile:action.profile,
            userId: action.userId,
            profilePictureUrl: action.picture,
            last_name: action.last_name,
            first_name: action.first_name,
            email:action.email,
            adminJobs: jobs,
            adminCompanies: companies,
            adminGroups: groups,
            adminEvents: events,
            totalCompanies:action.totalCompanies,
            totalGroups:action.totalGroups,
            shopping_carts:{
              business:{
                status:business.length!==0?true:false,
                infos:business.length!==0?business[0]:null
              },
              pro:{
                status:pro.length!==0?true:false,
                infos:pro.length!==0?pro[0]:null
              }
            },
           invitations:{
            error:null,
            message:null,
            spinner:false
           }
          }
      case SET_USER_RIGHT:
        return {...state, accountType: action.payload};
  
  
      case CREATE_BLANK_AXIOS_INSTANCE:
        return {...state, blankAxiosInstance: action.payload}
  
      case CREATE_AXIOS_INSTANCE:
        return {...state, axiosInstance: action.payload}
      
      case `CREATE_AXIOS_INSTANCE_NO_PARAMS`:
        return {...state, axiosInstanceNoParams: action.payload}
  
      case RESET_AXIOS_INSTANCE:
        return {...state, axiosInstance: {}}
  
  
      case CLOSE_ACCOUNT_REQUEST:
        return {...state, settings: {...state.settings, closingAccountStatus: 'removing', closingAccountError: null}}
  
      case CLOSE_ACCOUNT_SUCCESS:
        return {...state, settings: {...state.settings, closingAccountStatus: 'removed', closingAccountError: null}}
  
      case CLOSE_ACCOUNT_FAILURE:
        return {...state, settings: {...state.settings, closingAccountStatus: 'removing', closingAccountError: action.payload.error || action.payload.message}}
  
  
      case DEACTIVATE_ACCOUNT_REQUEST:
        return {...state, settings: {...state.settings, deactivatingAccountStatus: 'removing', deactivatingAccountError: null}}
  
      case DEACTIVATE_ACCOUNT_SUCCESS:
        return {...state, settings: {...state.settings, deactivatingAccountStatus: 'removed', deactivatingAccountError: null}}
  
      case DEACTIVATE_ACCOUNT_FAILURE:
        return {...state, settings: {...state.settings, deactivatingAccountStatus: 'removing', deactivatingAccountError: action.payload.error || action.payload.message}}
  
  
      case GET_PROFILE_SETTINGS:
        return {...state, settings: {...state.settings, data: action.payload}}
  
      case UPDATE_DEFAULT_LANGUAGE_REQUEST:
        return {...state, settings: {...state.settings, defaultLanguageStatus: 'updating', defaultLanguageError: null}}
  
      case UPDATE_DEFAULT_LANGUAGE_SUCCESS:
        return {...state, settings: {...state.settings, defaultLanguageStatus: 'available', defaultLanguageError: null}}
  
      case UPDATE_DEFAULT_LANGUAGE_FAILURE:
        return {...state, settings: {...state.settings, defaultLanguageStatus: 'updating', defaultLanguageError: action.payload.error || action.payload.message}}
  
  
      case GET_CURRENCY_LIST:
        return {...state, settings: {...state.settings, currencyList: action.payload}}
  
      case UPDATE_DEFAULT_SITTING_REQUEST:
        return {...state, settings: {...state.settings, defaultStatus: 'updating', defaultError: null}}
  
      case UPDATE_DEFAULT_SITTING_SUCCESS:
        return {...state, settings: {...state.settings, defaultStatus: 'available', defaultError: null}}
  
      case UPDATE_DEFAULT_SITTING_FAILURE:
        return {...state, settings: {...state.settings, defaultStatus: 'updating', defaultError: action.payload.error || action.payload.message}}
  
  
      case GET_COUNTRY_LIST:
        return {...state, settings: {...state.settings, countryList: action.payload}}
  
      case UPDATE_DEFAULT_COUNTRY_REQUEST:
        return {...state, settings: {...state.settings, defaultCountryStatus: 'updating', defaultCountryError: null}}
  
      case UPDATE_DEFAULT_COUNTRY_SUCCESS:
        return {...state, settings: {...state.settings, defaultCountryStatus: 'available', defaultCountryError: null}}
  
      case UPDATE_DEFAULT_COUNTRY_FAILURE:
        return {...state, settings: {...state.settings, defaultCountryStatus: 'updating', defaultCountryError: action.payload.error || action.payload.message}}
  
  
      case GET_NOTIFICATIONS:
        return {...state, notifications: {...state.notifications, list: action.payload.results, count: action.payload.count}}
  
      case GET_NOTIFICATIONS_ALL:
        if(action.payload.next) {
          return {
            ...state,
            notificationsAll: {
              ...state.notificationsAll,
              list: action.payload.results,
              count: action.payload.count,
              next: action.payload.next,
              hasMoreItems: true
            }
          }
        } else {
          return {
            ...state,
            notificationsAll: {
              ...state.notificationsAll,
              list: action.payload.results,
              count: action.payload.count,
              next: null,
              hasMoreItems: false
            }
          }
        }
  
      case UPDATE_NOTIFICATIONS_ALL:
        if(action.payload.next) {
          return {
            ...state,
            notificationsAll: {
              ...state.notificationsAll,
              list: state.notificationsAll.list.concat(action.payload.results),
              count: action.payload.count,
              next: action.payload.next,
              hasMoreItems: true
            }
          }
  
        } else {
          return {
            ...state,
            notificationsAll: {
              ...state.notificationsAll,
              list: state.notificationsAll.list.concat(action.payload.results),
              count: action.payload.count,
              next: null,
              hasMoreItems: false
            }
          }
        }
      case `${SEND_INVITATION}_SUCCESS`:
         return {...state, invitations:{...state.invitations, message:action.payload, spinner:false}}
      case `${SEND_INVITATION}_FAILURE`:
         return {...state, invitations:{...state.invitations, error:action.payload, spinner:false}}
      default:
        return state;
    }
  }