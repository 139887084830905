import i18next from 'i18next';
import XHR from 'i18next-xhr-backend';
import LngDetector from 'i18next-browser-languagedetector';
import { LANGUAGE_DEBUG, LANGUAGE_LIST } from './config';

/**
 * Some documentation about internationalization
 * We separate the namespaces between "main tabs" of Wutiko's website :
 * - common : common components shared between URL, include the header / footer, login bar, search
 * bar, and some generic components : FormComponents, ...
 * - logout : components that can be accessed without connection from the user AND that are not part
 * of the others tabs, like the homepage, the contact page, pages related with forgotten password, ...
 * - profile : the "home" tabs, containing all of the user's profile, left menu, timeline. Some
 * generic components concerning the user are also here : ListPost / Comment (reused in groups),
 * ProfileContact/Education/Experience (reused in profiles with PublicProfile) and some concerning the
 * user directly like right side bar.
 * - jobs : specific jobs components and forms
 * - profiles : (with an 's'), components concerning the public profiles that have not been dfined in
 * the previous component
 * - companies : specific companies components and forms
 * - groups : specific groups components and forms
 *
 * The number one rule : in the json translation file, the first key represents the root components
 * of a given URL, and it it uses some child components, the translations must be stored in another
 * sub-level. This makes its very easy to follow the component tree to find a translation later
 */
i18next
  .use(LngDetector)
  .use(XHR)
  .init({
    debug: LANGUAGE_DEBUG,
    ns: ['translation'],
    defaultNS: 'translation',
    fallbackLng: 'fr',
    whitelist: LANGUAGE_LIST,
    load: 'languageOnly', // if browser language is like en-US, allows the i18next.languages[0] property to contain only 'en'
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    saveMissing: LANGUAGE_DEBUG,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      useSuspense: false,
      wait: true, // set to true if you like to wait for loaded in every translated hoc
      nsMode: 'default' // set it to fallback to let passed namespaces to translated hoc act as fallbacks
    },
  })

export default i18next;