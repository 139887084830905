import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { submitContactFormError,SubmitContactFormSuccess} from "actions/forms/contactForm";

const submitContactFormDataRequest = async (instance,data,entryId,formId)=>
    await instance.post(`/forms/${formId}/fields/entries/${entryId}/add/`,data)
    .then(response=>{
        return response
    })
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

const submitContactFormRequest = async (instance,data,entryData,formId)=>
    await instance.post(`/forms/${formId}/entries/add/`,entryData)
    .then(response=>{
         return response.data
    })
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* submitContactForm(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const entryData =yield call(submitContactFormRequest,axiosInstance,actions.data,actions.entryData,actions.formId);
 
    if(entryData){
        const data =yield call(submitContactFormDataRequest,axiosInstance,actions.data,entryData.id,actions.formId);
     
        if(data.message){

            put(SubmitContactFormSuccess(data.message))
        }else{
            yield put(submitContactFormError(data.error));
        }
        
    }else{
        yield put(submitContactFormError(entryData.error));
    }
    
}
export function* handleSubmitContactForm(){
    yield takeEvery("HANDLE_SUBMIT_CONTACT_FORM", submitContactForm)
}
export default function* rootSaga() {
    yield all([fork(handleSubmitContactForm)]);
}
