import React,{useState} from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { TextField } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));
/**
 * This file is supposed to contain, all fields componenets usable with redux-form 
 */
export function LocationSearchInput (props) {
const classes = useStyles();
const [address, setState] =useState();
const   handleChange = address => {
    setState(address);
  };

  const handleSelect = address => {
    var city_name;
    var state_province_name;
    var country_name;
    var city_lat;
    var city_long;
    var state_province_lat;
    var state_province_long;
    var country_lat;
    var country_long;

    let full_location_name;
    let place_id;
    geocodeByAddress(address)
    .then(results => {
        full_location_name=results[0].formatted_address;
        place_id=results[0].place_id;
        city_name=full_location_name.split(',')[0];
        state_province_name=full_location_name.split(',')[full_location_name.split(',').length-2];
        country_name=full_location_name.split(',')[full_location_name.split(',').length-1];
    
        geocodeByAddress(address)
        .then(result => getLatLng(result[0]))
        .then(latLng =>{
            city_lat=latLng.lat;
            city_long=latLng.lng;
            geocodeByAddress(state_province_name)
            .then(results => getLatLng(results[0]))
            .then(latLng =>{
              state_province_lat=latLng.lat;
              state_province_long=latLng.lng;
              geocodeByAddress(country_name)
              .then(results =>getLatLng(results[0]))
              .then(latLng =>{
                country_lat=latLng.lat;
                country_long=latLng.lng;
                const data = {
                  country_name: country_name,
                  country_lat: country_lat,
                  country_lng: country_long,
                  state_province_name: state_province_name,
                  state_province_lat: state_province_lat,
                  state_province_lng: state_province_long,
                  city_name: city_name,
                  city_lat: city_lat,
                  city_lng: city_long,
                }
                props.addLocation({place_id:place_id, data:data})
              })
              .catch(error => console.error('Error', error));
            })
            .catch(error => console.error('Error', error));
        })
        .catch(error => console.error('Error', error));
        console.log(results[0])
        console.log(results[0].formatted_address)
      })
     };
    return (
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            {props.renderItems}
            <TextField
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
              fullWidth
              label={props.label}
              placeholder={props.placeholder}
              InputProps={{
            endAdornment: <InputAdornment position="end">
                    <IconButton>
                          {address?<ClearIcon onClick={()=>setState('')}/>:null}
                    </IconButton>  
            </InputAdornment>,
             }}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                console.log("suggestion",suggestion)
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <Grid container alignItems="center" 
                  {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                  <Grid item>
                    <LocationOnIcon className={classes.icon} />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2" color="textSecondary">
                       {suggestion.description}
                    </Typography>
                  </Grid>
                </Grid>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
}