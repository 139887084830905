
const initialState = {
    filter_experience:{
        error:null,
        spinner:false,
        count:null,
        experiences:[]
    },

 }

 export function filterexperience(state = initialState, action){
 
   switch(action.type) {
  
    case "GET_EXPERIENCE_FILTER_FAILLURE":
            return {...state, filter_experience:{
                ...state.filter_experience, error:action.payload, spinner:false, experiences:[]
            }}
   case "GET_EXPERIENCE_FILTER":
           return {...state, filter_experience:{
               ...state.filter_experience, error:null, spinner:false, experiences:action.payload.results
           }}
     default:
       return state;
   }
 }
