import { Col } from 'react-bootstrap';
//import ProfileArea from './ProfileArea';
//import ProfileFile from './ProfileFile';
import ProfileExperience from './ProfileExperience';
// import ProfileContact from './ProfileContact';
import ProfileEducation from './ProfileEducation';
// import ProfileLangue from './ProfileLangue';
import ProfileSkillsPublic from './ProfileSkillsPublic';
// import ProfileSector from './ProfileSector';
// import ProfileFileBnt from './ProfileFileBnt';
import { withTranslation } from 'react-i18next';
import { useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import IntlMessages from "util/IntlMessages";
import { handleGetProfileDetails } from '../../../actions/profile';
import React,{useEffect} from 'react';
import { useDispatch} from 'react-redux'

import ProfileAreas from './ProfileAreas';
import IconButton from "@material-ui/core/IconButton";
import { Button,  Form} from 'reactstrap';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LaunchIcon from '@material-ui/icons/Launch';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { ka } from 'date-fns/locale';
import SidebarRight from 'components/Sidebar/SidebarRight';
import LatestCompanies from 'components/companies/LatestCompaniesList';
import CloseIcon from "@material-ui/icons/Close";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiDialogContent from '@material-ui/core/DialogContent';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import DocumentFile from './ProfileFile'
import ProfileAchievement from './ProfileAchievement';
import Skilltest from './Skilltest';
// class MyProfile extends React.Component{

//   constructor(props) {
//     super(props);
//     this.updateProfileContent = this.updateProfileContent.bind(this);
//   }
const useStyles = makeStyles((theme) => ({
    toolbar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    header:{
      position:'fixed',
      top:0,
      width:'100%',
      background:'#ffffff',
      zIndex:10
    },
    toolbarTitle: {
      flex: 1,
    },
    toolbarSecondary: {
      justifyContent: 'space-between',
      overflowX: 'auto',
    },
    toolbarLink: {
      padding: theme.spacing(1),
      flexShrink: 0,
    }, 
    search:{
      paddingTop:0,
      paddingBottom:0
    }
  }));



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);




  const ProfileView = (props , t ) => {
    const itemDetails = useSelector(state=>state.profile.details.details);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const {ProfileSlug} = props;

    const spinner = useSelector(state=>state.profile.details.spinner);
    const itemDetailsProfile = useSelector(state=>state.profile.details.details);
    const userId = useSelector(state => state.users.id)
   const slug = "dione-daba-282d7701ff5b"
   //const {slug} =useParams();
   console.log("spinner profil" , spinner)

   console.log("itemDetailsProfile" , itemDetails)

    const handleClickOpen = (slug) => {
      dispatch(handleGetProfileDetails(slug));
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    console.log("ProfileSlug" , ProfileSlug);
    const dispatch = useDispatch();

    return(
     <div>
      <Button className="neutre" onClick={()=>handleClickOpen(ProfileSlug.slug)}>
      {props.first_name?
      <a>{props.first_name} {props.last_name}</a>:null}
      {ProfileSlug?
      props.item?
      <ListItem alignItems="flex-start">
      <a>{ProfileSlug.first_name} {ProfileSlug.last_name}</a>
        :
       <ListItemText
         primary={<a>{ProfileSlug.first_name} {ProfileSlug.last_name}</a>}
         secondary={
           <React.Fragment>
             <Typography
               component="span"
               variant="body2"
               color="textPrimary"
             >
               {ProfileSlug.title}
             </Typography>
             <Typography component="span" variant="body2" color="textPrimary">
                <small><IntlMessages id="profile_filling_rate"/> </small>:  
               {ProfileSlug.profile_filling_in_rate}% 
               </Typography>
           </React.Fragment>
         }
       />
     </ListItem>
      :null:null} 

      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}  className="List">
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
      {ProfileSlug?<h2>{ProfileSlug.first_name} {ProfileSlug.last_name}</h2>:null} 
      </DialogTitle>
      
          <DialogContent>

        {spinner?
        <Grid container item justify="center" xs={12}><CircularProgress size={60}/></Grid>:
            <DialogContentText id="alert-dialog-description">
      <div className="container">
        <Container maxWidth={false} className={classes.cont}>
        <div className={`animated slideInUpTiny animation-duration-3 ${classes.content}`}>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-12 animation slideInLeft bloc-profile">
            <div className="row">
                    <div className="col-md-12 animation slideInLeft CoverPhoto">
                      {itemDetailsProfile?
                      <span className="cover-photo">
                        <img
                          src={itemDetailsProfile.cover_photo ?itemDetailsProfile.cover_photo: "https://cdn.wutiko.com/v3/static/images/default/low/wutiko_default_cover_photo.png"}
                          alt="Cover photo"
                        />
                      </span>:null}
                    </div>
                    <div className="col-md-12 photo-profil animation slideInLeft">
                      {itemDetailsProfile?
                      <span className="photo">
                        <img
                          src={itemDetailsProfile?itemDetailsProfile.photo:"https://cdn.wutiko.com/v3/static/images/default/medium/wutiko.png"}
                          alt="Cover photo"
                        />
                      </span>:null}
                    </div>
                    <div className="col-md-12 col-sm-12 col-12 animation slideInRight">
                      <div className="row"> 
                          <div className="col-md-4 col-sm-4 col-4 animation slideInLeft">
                              <b>{itemDetailsProfile?<h2>{itemDetailsProfile.first_name} {itemDetailsProfile.last_name}</h2>:null} </b>
                              <b>{itemDetailsProfile?<span>{itemDetailsProfile.title}</span>:null} </b>
                              <b>{itemDetailsProfile?<span>{itemDetailsProfile.location}</span>:null}</b>
                          </div>
                          <div className="col-md-2 col-sm-2 col-2 animation slideInLeft">
                               <p className="text-grey mb-3">
                                <IconButton><FavoriteBorderIcon/></IconButton>{<IntlMessages id="save"/>}
                              </p>
                            </div>
                          <div className={`col-md-6 col-sm-4 col-4 animation slideInRight ${classes.textAlignEnd}`}>
                          {itemDetailsProfile?
                          <div>{itemDetailsProfile.phones.map(phone => phone.full_number ).join(' , ')}</div>
                          :null
                          }
                          </div>
                          {/* <div className="col-md-4 col-sm-4 col-4 animation slideInLeft">
                          <p className="text-grey mb-1">
                                 <IconButton><MailOutlineIcon/></IconButton>{<IntlMessages id="email"/>}
                              </p>
                          </div> */}
                      </div>
                    
                      {/* <div className="row"> 
                          <div className="col-md-12 col-sm-8 col-12 animation slideInLeft">
                          <div className="row">
                            <div className="col-md-6 col-sm-6 col-6 animation slideInLeft">
                                <p className="text-grey mb-3">
                                Repport this Profile<IconButton><LaunchIcon/></IconButton>
                              </p> 
                            </div>
                            <div className="col-md-2 col-sm-2 col-2 animation slideInLeft">
                               <p className="text-grey mb-3">
                                <IconButton><FavoriteBorderIcon/></IconButton>{<IntlMessages id="save"/>}
                              </p>
                            </div>
                            <div className="col-md-2 col-sm-2 col-2 animation slideInRight">
                               <p className="text-grey mb-1">
                                 <IconButton><MailOutlineIcon/></IconButton> {<IntlMessages id="email"/>}
                              </p>    
                            </div>                                                                                                                                                                                                                                                                                                        
                         
                          <div className="col-md-2 col-sm-2 col-2 animation slideInRight">
                            <Button fullWidth variant="contained" color="secondary">{<IntlMessages id="connect"/>}</Button>
                          </div>
                      </div>
                      </div>
                      </div> */}
                    </div>
            </div>
            <div className="row col-md-12 top-20">
              {itemDetailsProfile?
              <div>
              <div><h2>{<IntlMessages id="about"/>}</h2></div>
              <p>{itemDetailsProfile.about_me}</p> 
              </div>
              :
              null
              }         
            </div>
            <div className="row">
            <DocumentFile id={userId}  files={itemDetails ? itemDetails.profile_documents : []} /> 
        <ProfileExperience  itemDetails={itemDetails ? itemDetails.positions : []}  />
        <ProfileEducation  itemDetailsEducations={itemDetails  ? itemDetails.educations : []}  /> 
        <ProfileAchievement 
        itemDetailsProfilePublications={itemDetails? itemDetails.publications : []} 
        itemDetailsProfileLanguages={itemDetails? itemDetails.languages : [] }/>
        <Skilltest  itemDetailsPublic={itemDetails ? itemDetails.skills : []} 
        itemDetailsIndustryPublic={itemDetails ? itemDetails.industries : []}/>
           <ProfileAreas itemDetailsPublic={itemDetails ? itemDetails.areas : []}/> 
   
 
            </div>  
            <div className="row col-md-12">
                  <div className="col-md-5 col-sm-5 col-12">
                  <p className="text-grey mb-3">
                    Repport this Profile<IconButton><LaunchIcon/></IconButton>
                  </p> 
                  </div>
                  <div className="col-md-2 col-sm-2 col-6 animation slideInLeft">
                    <p className="text-grey mb-3">
                    <IconButton><FavoriteBorderIcon/></IconButton>{<IntlMessages id="save"/>}
                  </p>
                    </div>
                    <div className="col-md-2 col-sm-2 col-6 animation slideInRight">
                    <p className="text-grey mb-1">
                      <IconButton><MailOutlineIcon/></IconButton>{<IntlMessages id="email"/>}
                  </p>
                    </div>
                  <div className="col-md-3 col-sm-3 col-12">
                       <Button fullWidth variant="contained" color="secondary">{<IntlMessages id="connect"/>}</Button>
                  </div>
            </div>
            <div className="row col-md-12">
              <div className={`col-xl-6 col-sm-12`}>
             
              </div>

              <div className="col-xl-6 col-sm-12">
               
              </div>
            </div>
          </div>
         
        </div>
      </div>
      </Container>
      </div>
      </DialogContentText>
      
  }
        </DialogContent>
        </Dialog>
      
        </div>
    )
}
export default withTranslation('translation')(ProfileView);
// function mapStateToProps(state) {
//   return {
//     itemDetails: state.itemdetail.detail,
//     userId: state.user.userId,
//   }
// }
