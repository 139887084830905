import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Select from '@material-ui/core/Select';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {useDispatch, useSelector} from 'react-redux'
import {Alert} from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { handleResetuploadCvStateOpp } from 'actions/uploadCv';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Icon from '@material-ui/core/Icon';
import IntlMessages from "util/IntlMessages";
import NativeSelect from '@material-ui/core/NativeSelect';
import { Field, reduxForm } from 'redux-form'
import HorizontalItemCardCustom from 'components/dashboard/Common/HorizontalItemCardCustom';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import moment from 'moment';
import { RichTextMarkdown } from 'components/formsElements/FormComponents';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import BrowserLimitations from 'components/opportunities/BrowserLimitations';
import {useDropzone} from 'react-dropzone';

const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    title: {
    },
    content:{
        padding:15,
        paddingBottom:30,
        overflowY:"hidden"
    },
    paper:{
        backgroundColor:'#e0e0e0'
    },
    upload:{
        width:80,
        height:80
    },
    input: {
      display: 'none',
    },
  }));

const initState={
  datafile:null,
  email:null,
  first_name:null,
  last_name:null,
  name:null,
  document_type:null,
  resume_file:null,
  diploma_file:null,
  datafile_portfolio:'',
  datafile_coverletter:""
  
}
 function AppForm(props) {
  const classes = useStyles();
  const [state, setState] =useState(initState);
  const dispatch = useDispatch()
  const uploadCvState= useSelector(state=>state.uploadCv.upload_cv_opp);
  const userState= useSelector(state=>state.users.authenticated);
  const loader=<CircularProgress  color="primary"/>;
  const [selectedDate, setSelectedDate] = React.useState("1984-01-01")
  const [civility, setCivility] = React.useState();
  const [experience, setExperience] = React.useState();
  const [accommodation, setAccommodation] = React.useState('');
  const [motivation, setMotivation] = React.useState();
  const dateFormatter = str => {
    return str;
  };  
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: '.pdf, .doc, .docx'
  });
  const handleChangeExperience = (event) => {
    setExperience(event.toString('html'));
  };
    
  const handleChangeMotivation = (event) => {
    setMotivation(event.toString('html'));
  };
  const handleChangeCS = (event) => {
    setCivility(event.target.value);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSubmit=(event)=>{
    event.preventDefault();
    const formData = new FormData(); 
    if(props.opportunity_id) {
      formData.append('opportunity_id', props.opportunity_id);
    } 
    if(state.civility !== null){
        formData.append("civility", civility);
      }
    if(state.first_name !== null){
      formData.append("first_name", state.first_name);
    }
    if(state.last_name !== null){
    formData.append("last_name", state.last_name);
    }
    if(state.email !== null){
    formData.append("email", state.email);
    }
    if(state.date_of_birth !== null){
    formData.append("date_of_birth", moment.isMoment(selectedDate) ? 
    selectedDate.format('YYYY-MM-DD') : selectedDate,);
    }
    if(state.phone !== null){
    formData.append("phone", state.phone);
    }
    if(state.address !== null){
    formData.append("address", state.address);
    }
    if(state.nationality !== null){
    formData.append("nationality", state.nationality);
    }
    if(state.country !== null){
    formData.append("country", state.country);
    }
    if(state.accommodation !== null){
    formData.append("accommodation", accommodation);
    }
    if(state.motivation !== null){
    formData.append("motivation", motivation);
    }
    if(state.education !== null){
    formData.append("education", state.education);
    }
    if(state.experience !== null){
    formData.append("experience", experience);
    }
    if(state.previous_position !== null){
    formData.append("previous_position", state.previous_position);
    }
    if(state.current_position !== null){
    formData.append("current_position", state.current_position);
    }
    formData.append("resume", state.resume);
    formData.append("diploma", state.diploma);
    formData.append("datafile_portfolio", state.datafile_portfolio);
    formData.append("datafile_coverletter", state.datafile_coverletter);
    dispatch({type:"HANDLE_UPLOAD_CV_OPP", data:formData})
  }
  const handleChange= (event) =>{
      setState({...state, [event.target.name]: event.target.value})
  }
  const handleChangeRadioBtn = (event) => {
    setAccommodation(event.target.value);
  };
  


  const handleChangeFile=(event)=>{
    setState({...state, [event.target.name]:event.target.files[0], resume_file:event.target.value})
  }
  const handleChangeFile1=(event)=>{
    setState({...state, [event.target.name]:event.target.files[0], diploma_file:event.target.value})
  }
  const {t}=props;
  return (
    <React.Fragment className="appform">
    <Dialog className="appform" fullScreen={false} maxWidth="sm" fullWidth={true} open={props.open} onClose={props.handleClose}>
        <AppBar className={classes.appBar}>
        <Toolbar>
            <Grid container>
                <Grid md={10} xs={10} item container justify="left">
                  <Typography variant="h6" className={classes.title}>Droit International Pénal (DIP), Droit International des Droits de l’Homme et Droit des Réfugiés.</Typography>
                </Grid>

              
                <Grid  md={2}  xs={2} item container justify="flex-end"> 
                    <IconButton edge="start" color="inherit" aria-label="close" onClick={props.handleClose}>
                        <CloseIcon />
                    </IconButton>   
                </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
         {/*  */}
         <DialogContent >
         <form enctype="multipart/form-data" onSubmit={handleSubmit} >
        <Grid container spacing={3} className={classes.content}>
        <Grid item xs={12} sm={12}>

        <p className="nb-body">8ème édition de la session de formation en Droit International des Droits de l’Homme<br></br>
                   Bamako, 14 - 19 Juin 2021.<br></br>
                   <a href="/2021-01-25-note-de-concept-session-de-bamako-DIP-2021.pdf" target="blank">Cliquez ici pour avoir les termes de références</a>
                   </p> 
                   <p className="nb-body">
             NB: en raison de la crise sanitaire liée à la Covid-19, le nombre de participants de l'édition 2021 sera limité à 50, afin de pouvoir respecter les gestes barrières et le protocole sanitaire
            </p>

            <p className="nb-body ">
              <h2 className="text-center partners">Nos partenaires</h2>
            <div className="row">
            <img src="../FRCNouveaulogo2020.png" className="col-md-3 partnersDidh frc"/>
            <img src="../FNF_Englisch_Farbe_Screen.jpg" className="col-md-3 partnersDidh"/>
            <img src="../Minisma.png" className="col-md-3 partnersDidh minisma"/>
            <img src="../republique.png" className="col-md-3 partnersDidh"/>
            </div>
            </p>
        </Grid>
        <Grid xs={12}>
        {uploadCvState.message?<Alert severity="success" onClose={()=>dispatch(handleResetuploadCvStateOpp())}>{uploadCvState.message}</Alert>:null}
        {uploadCvState.error?<Alert severity="error" onClose={()=>dispatch(handleResetuploadCvStateOpp())}>{uploadCvState.error}</Alert>:null}
        </Grid>
        <Grid item xs={12} sm={4}>
        <FormControl className={classes.formControl}>
                    <InputLabel  id="demo-simple-select-label" >Choisir une civilité</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                      id="civility"
                      name="civility"
                      value={civility}
                      onChange={handleChangeCS}
                    >
                      <MenuItem value={0}>Madame</MenuItem>
          <MenuItem value={1}>Monsieur</MenuItem>
          <MenuItem value={2}>Autre</MenuItem>
                      
                    </Select>
                  </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            id="firstName"
            name="first_name"
            label="Prénoms"
            fullWidth
            autoComplete="given-name"
            value={state.first_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            id="last_name"
            name="last_name"
            label="Nom"
            fullWidth
            autoComplete="family-name"
            value={state.last_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            
                            <KeyboardDatePicker
                              margin="normal"
                              id="date_of_birth"
                              name="date_of_birth"
                              label="Date de naissance"
                              format="YYYY-MM-DD"
                              rifmFormatter={dateFormatter}
                              value={selectedDate}
                              onChange={handleDateChange}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />   
                          </MuiPickersUtilsProvider>  
              

        </Grid>
      
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="email"
            name="email"
            label={<IntlMessages id="email"/>}
            fullWidth
            autoComplete="Email"
            value={state.email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="phone"
            name="phone"
            label="Téléphone"
            fullWidth
            autoComplete="phone"
            value={state.phone}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="nationality"
            name="nationality"
            label="Nationalité"
            fullWidth
            autoComplete="Nationalité"
            value={state.nationality}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            name="country"
            label="Pays"
            fullWidth
            autoComplete="Pays"
            value={state.country}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="address"
            name="address"
            label="Adresse"
            fullWidth
            autoComplete="address"
            value={state.address}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="education"
            name="education"
            label="Formation universitaire, merci de préciser le diplôme le plus éleve"
            fullWidth
            autoComplete="education"
            value={state.education}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="current_position"
            name="current_position"
            label="Fonctions actuelles"
            fullWidth
            autoComplete="current_position"
            value={state.current_position}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            id="previous_position"
            name="previous_position"
            label="Fonctions antérieures"
            fullWidth
            autoComplete="previous_position"
            value={state.previous_position}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
        <Field
                      id="experence"
                      name="experence"
                      label="Expériences dans le domaine des Droits de l'Homme"
                      component={RichTextMarkdown}
                      componentclassName="textarea"
                      onChange={handleChangeExperience}
                      value={state.experence}
                      />   
        </Grid>
        <Grid item xs={12}>
        <Field
                      id="motivation"
                      name="motivation"
                      label="Motivations"
                      component={RichTextMarkdown}
                      componentclassName="textarea"
                      onChange={handleChangeMotivation}
                      value={state.motivation}
                      />  
        </Grid>
        <Grid item xs={12}>
        <FormControl component="fieldset">
            <FormLabel component="hebergement">Souhaitez- vous un hébergement?</FormLabel>
            <RadioGroup aria-label="accommodation" name="accommodation" value={accommodation} onChange={handleChangeRadioBtn}>
                <FormControlLabel value="true" control={<Radio />} label="Oui" />
                <FormControlLabel value="false" control={<Radio />} label="Non" />
            </RadioGroup>
        </FormControl>
        </Grid>
        
       <Grid item xs={12} sm={6}  className="resume-opp">
         <div className="dropzone-card">
                <div className="dropzone">
                <div {...getRootProps({className: ''})}>
                   <input {...getInputProps()} name="diploma" onChange={handleChangeFile1} />
                    <Paper className={classes.paper} htmlFor="icon-button-file">
                 <Grid container spacing={3}>
                  <Grid item container className="col-md-12" justify="center">
                    <label htmlFor="icon-button-file">
                    <IconButton color="primary" aria-label="upload picture" component="span">
                    <p>Veuillez joindre une copie de votre dernier diplôme obtenu ...</p>
                    <CloudUploadIcon className={classes.upload}/>
                    </IconButton>
                   <Grid item container justify="center" className="top--20">
                   <center>{state.diploma_file}</center>
                   </Grid>
                </label>
                </Grid>
                </Grid>
               </Paper>
                </div>
                </div>
            </div>
         </Grid>  

         <Grid item xs={12} sm={6} className="resume-opp">
         <input accept=".pdf, .doc, .docx" name="resume" className={classes.input} id="icon-button-file" type="file" onChange={handleChangeFile}/>
         <Paper className={classes.paper} htmlFor="icon-button-file">
            <Grid container spacing={3}>
                <Grid item container className="col-md-12" justify="center">
                <label htmlFor="icon-button-file">
                    <IconButton color="primary" aria-label="upload picture" component="span">
                    <p>Veuillez joindre au formulaire düment rempli votre CV ...</p>
                        <CloudUploadIcon className={classes.upload}/>
                    </IconButton>
                </label>
                </Grid>
                <Grid item container justify="center"  className="top--20">
                   <center>{state.resume_file}</center>
                </Grid>
            </Grid>
         </Paper>
         </Grid> 
        {userState?null:
        <Grid item xs={12} sm={12}  className="terms_and_conditions">
            <center  className="UploadCv">
            {<IntlMessages id="upload_my_cv_footer"
            values={{ terms_and_conditions : <a target="blank" href="https://pages.wutiko.com/en/legal/terms-and-conditions/"> <IntlMessages id="terms_and_conditions"/> </a>}}
            /> 
            }
            </center>   
        </Grid>}
        <Grid item xs={12} sm={12} >
        {uploadCvState.message?<Alert severity="success" onClose={()=>dispatch(handleResetuploadCvStateOpp())}>{uploadCvState.message}</Alert>:null}
        {uploadCvState.error?<Alert severity="error" onClose={()=>dispatch(handleResetuploadCvStateOpp())}>{uploadCvState.error}</Alert>:null}
        </Grid>
       
        <Grid item xs={12} className="top-20">
             {uploadCvState.spinner?loader:<Button variant="contained" type="submit" color="primary"> Envoyer</Button>}
        </Grid>
        </Grid>
        </form>
        </DialogContent>
         {/*  */}
      </Dialog>   
    </React.Fragment>
  );
}
export default reduxForm({
    form: 'AppForm',
    enableReinitialize: true,
  })(withTranslation('translation')(AppForm));
  
//AppForm = (withTranslation('translation')(AppForm));
