import React from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import { Parser } from 'html-to-react';
import { Button} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import Education from 'pages/EducationForm';
import AddEducation from 'containers/Profil/AddEducation';
import EducationForm from 'pages/EditEducation';
import IntlMessages from "util/IntlMessages";
// import FontAwesome from 'react-fontawesome';

const DEFAULT_NUMBER_EDUCATION_DISPLAYED = 2;
class ProfileEducation extends React.Component{


   constructor(props) {
    super(props);
    this.state = { nEducations: DEFAULT_NUMBER_EDUCATION_DISPLAYED,
      openEducation : false,
      setopenEducation : false,
     };
    this.showAllEducations = this.showAllEducations.bind(this);
    this.hideAllEducations = this.hideAllEducations.bind(this);
  }

  showAllEducations() {
    this.setState({ nEducations: this.props.itemDetailsEducations.length })
  }

  hideAllEducations() {
    this.setState({ nEducations: DEFAULT_NUMBER_EDUCATION_DISPLAYED })
  }

  renderListEducation(n) {
  var parser = new Parser();
  const { t } = this.props;
  return this.props.itemDetailsEducations.slice(0,n).map((education , index) => {
  return(
    <div key={education.education_id.id}>
    {this.props.myProfile ?
      <div className="row education"  data-aos="fade-up" >
           <div className="col-md-2 col-3 text-center icon-education">
           {education.organization_id?
           <Link to={"/companies/"+education.organization_id.slug}><img width="100" height="90" className="img-funding" src={education.organization_id ? education.organization_id.logo : 'https://static.wutiko.com/back/static/default/default_wutiko_2x.png'} alt="me" /></Link>:
          null
            }
            </div>
           <div className="col-md-10 col-9">
           <div className="header-education row">
             <div className="pull-left col-md-10 col-10">
                    <div>
                    {education.organization_id?
                    <Link className="company-education" to={"/companies/"+education.organization_id.slug}  > {education.organization_id.name}</Link>
                    :null}</div>
                    <div><span className="date-education">{education.education_id.name}</span></div>
                    <div><span className="location-date">
                    {education.start_date} {education.is_current === "true" ? "- present" : education.end_date ? '- '+education.end_date : null}</span></div>
              </div>
              <div className="text-right col-md-2 col-2">
              <EducationForm  indexEdu={index} itemEducation={education} education_id={education.education_id.id} openDialog={this.state.openEducation} handleClose={()=>this.setState.setopenEducation(false)} add={"edit"} className="pull-right edit" /> 
                
                {/*                 
                <Link to={`/profile/education/${education.id}/edit`} className="pull-right"><i className="fa fa-pencil f-5x"></i></Link>
                */}      
             </div>
            </div>
            <div className="body-education">
              {education.description?
              <div className="description col-md-12 col-12">
                     {<IntlMessages id="description"/>}:
                  <p>{parser.parse(education.description)}</p>
              </div>
              :null}
              {education.accomplishments_and_awards?
              <div className="description col-md-12 col-12">
                     {<IntlMessages id="accomplishments_awards"/>}:
                  <p>{parser.parse(education.accomplishments_and_awards)}</p>
              </div>
              :null}
              {education.activities_and_societies?
              <div className="description col-md-12 col-12"> 
                     {<IntlMessages id="school_activities"/>}:
                  <p>{parser.parse(education.activities_and_societies)}</p>
              </div>
              :null}
          </div>
          </div>
         </div>
         :
            <div className="row education">
           <div className="col-md-2 col-3 text-center icon-education">
           <Link to={"/companies/"+education.organization_id.slug}>
           <img width="70" height="60" className="img-funding" src={education.organization_id.logo ? education.organization_id.logo : 'https://static.wutiko.com/back/static/default/default_wutiko_2x.png'} alt="me" /></Link></div>
           <div className="col-md-10 col-9">
           <div className="header-education row">
             <div className="pull-left col-md-10 col-10">
                    <div><Link className="company-education" to={"/companies/"+education.organization_id.slug}  >{education.organization_id.name}</Link></div>
                    <div><span className="date-education">{education.education_id.name}</span></div>
                    <div><span className="location-date">{education.start_date} {education.is_current === "true" ? "- present" : education.end_date ? '- '+education.end_date : null}</span></div>
              </div>
            </div>
           <div className="body-education">
              {education.description?
              <div className="description col-md-12 col-12">
                     {<IntlMessages id="description"/>}:
                  <p>{parser.parse(education.description)}</p>
              </div>
              :null}
              {education.accomplishments_and_awards?
              <div className="description col-md-12 col-12">
                     {<IntlMessages id="accomplishments_and_awards"/>}:
                  <p>{parser.parse(education.accomplishments_and_awards)}</p>
              </div>
              :null}
              {education.activities_and_societies?
              <div className="description col-md-12 col-12">
                     {<IntlMessages id="activities_and_societies"/>}:
                  <p>{parser.parse(education.activities_and_societies)}</p>
              </div>
              :null}
          </div>
          </div>
         </div>
       }</div>
      )
    })
  }

  render() {
    const { t } = this.props;

    return (
         <div className="bloc content-page-interne education ">

                    <div className="row">
                    <div className="col-md-6 col-6">
                      <h2 className="text-left title">{<IntlMessages id="educations"/>}</h2></div>
                    {this.props.myProfile ?
                    <div className="text-right col-md-6 col-6">
                    {/* <Link className='btn' to='/profile/add-education/' activeClassName="active" className="pull-right">
                      <i className="fa fa-plus f-5x"></i>Add
                    </Link> */}
                       <Education openDialog={this.state.openEducation} handleClose={()=>this.setState.setopenEducation(false)} add={"add"} className="pull-right add" /> 
                    </div>
                  : null}
                </div>

            {this.props.myProfile ?  
              
             

            <div> 
            {this.props.itemDetailsEducations ? this.renderListEducation(this.state.nEducations) : null}
             
              {this.props.itemDetailsEducations.length > 2 ?
               
               this.state.nEducations === DEFAULT_NUMBER_EDUCATION_DISPLAYED ?
                
                <Button bsStyle="link"  className="items-pending text-center col-md-12" onClick={this.showAllEducations}>
                 {<IntlMessages id="see_more"/>}
               </Button>
              :
                <Button bsStyle="link"  className="items-pending text-center col-md-12" onClick={this.hideAllEducations}>
                 {<IntlMessages id="hide"/>}
                </Button>
                  : null}
                </div>
              
              
              :

              this.props.itemDetailsEducations ? this.renderListEducation(this.props.itemDetailsEducations.length) : null  }


                </div>
         
   );
  }
}

export default withTranslation('translation')(ProfileEducation);