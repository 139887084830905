import React,{useEffect} from 'react';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import { cleanStringQuery } from 'util/UtilFunctions';
import {useDispatch,useSelector} from 'react-redux'
import IntlMessages from "util/IntlMessages";
import { handleFilterAssessed } from 'actions/filters/assessed';
  
function FilterAssessed(props) {
  const dispatch = useDispatch();
  const assesseds = useSelector(state=>state.assessedFilter.filter_assessed.assesseds);

  const handleSearchAssessed =(data)=>{
    dispatch(handleFilterAssessed ({search:`?assessed=${data.currentTarget.value}`}))
  }

  useEffect(()=>{
      dispatch(handleFilterAssessed({ search:`${cleanStringQuery(window.location.search)?`?${cleanStringQuery(window.location.search)}`:''}`}))
  },[dispatch])

  const onChecked=(value)=>{
    if(props.keys.filter(obj=>obj.name===value.assessed).length!==0){
        props.delete({name:value.assessed, type:'assessed', fullname:`${value.assessed}`})
    }else{
        props.addItem({name:value.assessed, type:'assessed', fullname:`${value.assessed}`})
    }
   }
    return (
        <FormControl className="filter">
          <InputLabel htmlFor="select-multiple-chip">{<IntlMessages id="assessed"/>}</InputLabel>
          <Select
            label={<IntlMessages id="assessed"/>}
            multiple
            value={[]}>
            <div className="search">
            <Input  className="filter" name="search" type="search" placeholder="Search" autocomplete="off" onChange={(data)=>handleSearchAssessed(data)}/>
            </div>
             {assesseds.map((name, key) => (
              <MenuItem key={key} value={name.label}>
                <Checkbox checked={props.keys.filter(obj=>obj.name===name.assessed).length!==0} onClick={()=>onChecked(name)}/>
                <ListItemText primary={`${name.label}(${name.total})`} />
              </MenuItem>
            ))} 
          </Select>
        </FormControl>
    );
}

export default FilterAssessed;
