import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { setAllopportunities, setAllopportunitiesError } from "actions/opportunities";
import { setAllProfilesList, setAllProfilesListError } from "actions/profile";
import { SearchIndustriesError,SearchIndustriesSuccess, SearchCompanyError,SearchCompanySucess, SearchEducationError,
    SearchEducationSucess, SearchPositionSucess, SearchPositionError, SearchSkillsSucess, SearchSkillsError, 
    SearchLangueSucess, SearchLangueError, SearchAreaSucess, SearchAreaError,
    SearchBenefitsSucess,SearchBenefitsError,SearchOccupationsSucess,SearchOccupationsError,
    SearchCategoriesSucess,SearchCategoriesError,SearchContractTypeSucess,SearchContractTypeError,
    SearchCompanyAdminSucess,SearchCompanyAdminError } from "actions/search";
import Industries from "containers/filters/Industries";
import { setAllOrganizationsList, setAllOrganizationsListError } from "actions/companies/getCompanies";


// 


const setSearch = async (instance,actions)=>
    await instance.get(
        actions.search ? 
        `/searches/search/results/opportunities/${actions.search}&page=${actions.page}`:
        `/searches/search/results/opportunities/?page=${actions.page}`)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            return error.response.data
        }
    })

function* Search(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(setSearch,axiosInstance,actions.data);
    if(data.results){
            yield put(setAllopportunities(data));
    }else{
           yield put(setAllopportunitiesError(data.error));
    }
    
}
/**
 * 
 */


 // 

 /**
 * search profiles
 */ 
const setSearchProfiles = async (instance,actions)=>

await instance.get(
    actions.search ?
    `/searches/search/results/profiles/${actions.search}&page=${actions.page}`:
    `/searches/search/results/profiles/?page=${actions.page}`)
.then(response=>response.data)
.catch((error)=>{
    if(error.response.status===500 || error.response.status===404){
        return {error:error.response.statusText}
    }else{
        return error.response.data
    }
})

function* SearchProfiles(actions){
const state = yield select((state)=>state.users);
let axiosInstance;
if(state.authenticated){
    axiosInstance=state.axiosInstance
}else{
    axiosInstance=state.blankAxiosInstance
}
const data =yield call(setSearchProfiles, axiosInstance,actions.data);
if(data.results){
        yield put(setAllProfilesList(data));
        console.log("List des profiles", data )
}else{
       yield put(setAllProfilesListError(data.error));
}

}



 /**
 * search companies List
 */ 
const setSearchOrganizations = async (instance,actions)=>
await instance.get(
    actions.search ?
`/searches/search/results/organizations/${actions.search}&page=${actions.page}`:
`/searches/search/results/organizations/?page=${actions.page}`)
.then(response=>response.data)
.catch((error)=>{
    if(error.response.status===500 || error.response.status===404){
        return {error:error.response.statusText}
    }else{
        return error.response.data
    }
})

function* SearchOrganizations(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(setSearchOrganizations, axiosInstance,actions.data);

    console.log("ORG LIST", data )
    if(data.results){
            yield put(setAllOrganizationsList(data));
    }else{
           yield put(setAllOrganizationsListError(data.error));
    }
    
    }




const setSearchIndustries = async (instance,actions)=>
    await instance.get(`/searches/search/industries/?q=${actions.q}`)
    .then(response=>response)
    .catch((error)=>{
        try {
            if(error.response.status===500 || error.response.status===404){
                return {error:error.response.statusText}
            }else{
                return error.response.data
            }
        } catch (err) {
             console.log(error,err)
             return {error:"Error"}
        }

    })
function* searchIndustries(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(setSearchIndustries,axiosInstance,actions.payload);
    if(data.data){
            console.log("Industries" , data.results)
            yield put(SearchIndustriesSuccess(data));
    }else{
           yield put(SearchIndustriesError(data.error));
    }

}


/**
 * SearchCompany company
 */
const setSearchCompany = async (instance, actions)=>
    await instance.get(`/searches/search/results/organizations/?q=${actions.q}`)
    .then(response=>response)
    .catch((error)=>{
        try {
            if(error.response.status===500 || error.response.status===404){
                return {error:error.response.statusText}
            }else{
                return error.response.data
            }
        } catch (err) {
             console.log(error,err)
             return {error:"Error"}
        }

    })
function* searchCompany(actions){

    console.log("Search company" , "Search company")
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(setSearchCompany,axiosInstance, actions.payload);
    if(data.data){
        console.log("SearchCompany",data.data.results)
            yield put(SearchCompanySucess(data));
    }else{
           yield put(SearchCompanyError(data.error));
    }

}
export function* handleSearchCompany(){
    console.log("Search company" ," data.data.results")
    yield takeEvery("HANDLE_SEARCH_COMPANIES", searchCompany)
}




/**
 * SearchCompany company
 */
const setSearchCompanyAdmin = async (instance, actions)=>
    await instance.get(`/searches/search/organizations-opportunity-posting/?q=${actions.q}`)
    .then(response=>response)
    .catch((error)=>{
        try {
            if(error.response.status===500 || error.response.status===404){
                return {error:error.response.statusText}
            }else{
                return error.response.data
            }
        } catch (err) {
             console.log(error,err)
             return {error:"Error"}
        }

    })
function* searchCompanyAdmin(actions){

    console.log("Search company" , "Search company")
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(setSearchCompanyAdmin,axiosInstance, actions.payload);
    if(data.data){
        console.log("SearchCompany",data.data.results)
            yield put(SearchCompanyAdminSucess(data));
    }else{
           yield put(SearchCompanyAdminError(data.error));
    }

}
export function* handleSearchCompanyAdmin(){
    console.log("Search company" ," data.data.results")
    yield takeEvery("HANDLE_SEARCH_COMPANIES_ADMIN", searchCompanyAdmin)
}


/**
 * Search Education
 */
const setSearchEducation = async (instance, actions)=>
    await instance.get(`/searches/search/educations/`)
    //await instance.get(`/searches/search/educations/?q=${actions.q}`)
    .then(response=>response)
    .catch((error)=>{
        try {
            if(error.response.status===500 || error.response.status===404){
                return {error:error.response.statusText}
            }else{
                return error.response.data
            }
        } catch (err) {
             console.log(error,err)
             return {error:"Error"}
        }

    })
function* searchEducation(actions){

    console.log("Search Education" , "Search Education")
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(setSearchEducation,axiosInstance, actions.payload);
    if(data.data){
        console.log("SearchEducation",data.data.results)
            yield put(SearchEducationSucess(data));
    }else{
           yield put(SearchEducationError(data.error));
    }

}


/**
 * Search Position
 */
const setSearchPosition = async (instance, actions)=>
  //  await instance.get(`/searches/search/positions/`)
   await instance.get(`/searches/search/positions/?q=${actions.q}`)
    .then(response=>response)
    .catch((error)=>{
        try {
            if(error.response.status===500 || error.response.status===404){
                return {error:error.response.statusText}
            }else{
                return error.response.data
            }
        } catch (err) {
             console.log(error,err)
             return {error:"Error"}
        }

    })
function* searchPosition(actions){


    console.log("Search Position" , "Search Position")
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(setSearchPosition,axiosInstance, actions.payload);
    if(data.data){
        console.log("SearchPosition",data.data)
            yield put(SearchPositionSucess(data));
    }else{
           yield put(SearchPositionError(data.error));
    }

}




/**
 * Search Area
 */
const setSearchArea = async (instance, actions)=>
    await instance.get(`/searches/search/areas/?q=${actions.q}`)
    .then(response=>response)
    .catch((error)=>{
        try {
            if(error.response.status===500 || error.response.status===404){
                return {error:error.response.statusText}
            }else{
                return error.response.data
            }
        } catch (err) {
             console.log(error,err)
             return {error:"Error"}
        }

    })
function* searchArea(actions){


    console.log("Search Area" , "Search Area")
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(setSearchArea,axiosInstance, actions.payload);
    if(data.data){
        console.log("SearchArea",data.data)
            yield put(SearchAreaSucess(data));
    }else{
           yield put(SearchAreaError(data.error));
    }

}




/**
 * Search Skills
 */
const setSearchSkills = async (instance, actions)=>
    await instance.get(`/searches/search/skills/?q=${actions.q}`)
    .then(response=>response)
    .catch((error)=>{
        try {
            if(error.response.status===500 || error.response.status===404){
                return {error:error.response.statusText}
            }else{
                return error.response.data
            }
        } catch (err) {
             console.log(error,err)
             return {error:"Error"}
        }

    })
function* searchSkills(actions){


    console.log("Search Skills" , "Search Skills")
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(setSearchSkills,axiosInstance, actions.payload);
    if(data.data){
        console.log("SearchSkills",data.data)
            yield put(SearchSkillsSucess(data));
    }else{
           yield put(SearchSkillsError(data.error));
    }

}






/**
 * Search Benefits
 */
const setSearchBenefits = async (instance, actions)=>
    await instance.get(`/searches/search/benefits/?q=${actions.q}`)
    .then(response=>response)
    .catch((error)=>{
        try {
            if(error.response.status===500 || error.response.status===404){
                return {error:error.response.statusText}
            }else{
                return error.response.data
            }
        } catch (err) {
             console.log(error,err)
             return {error:"Error"}
        }

    })
function* searchBenefits(actions){


    console.log("Search Benefits" , "Search Benefits")
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(setSearchBenefits,axiosInstance, actions.payload);
    if(data.data){
        console.log("SearchBenefits",data.data)
            yield put(SearchBenefitsSucess(data));
    }else{
           yield put(SearchBenefitsError(data.error));
    }

}





/**
 * Search Occupations
 */
const setSearchOccupations = async (instance, actions)=>
    await instance.get(`/searches/search/occupations/?q=${actions.q}`)
    .then(response=>response)
    .catch((error)=>{
        try {
            if(error.response.status===500 || error.response.status===404){
                return {error:error.response.statusText}
            }else{
                return error.response.data
            }
        } catch (err) {
             console.log(error,err)
             return {error:"Error"}
        }

    })
function* searchOccupations(actions){


    console.log("Search Occupations" , "Search Occupations")
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(setSearchOccupations,axiosInstance, actions.payload);
    if(data.data){
        console.log("SearchOccupations",data.data)
            yield put(SearchOccupationsSucess(data));
    }else{
           yield put(SearchOccupationsError(data.error));
    }

}





/**
 * Search Categories
 */
const setSearchCategories = async (instance, actions)=>
    await instance.get(`/searches/search/categories/?q=${actions.q}`)
    .then(response=>response)
    .catch((error)=>{
        try {
            if(error.response.status===500 || error.response.status===404){
                return {error:error.response.statusText}
            }else{
                return error.response.data
            }
        } catch (err) {
             console.log(error,err)
             return {error:"Error"}
        }

    })
function* searchCategories(actions){


    console.log("Search Categories" , "Search Categories")
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(setSearchCategories,axiosInstance, actions.payload);
    if(data.data){
        console.log("SearchCategories",data.data)
            yield put(SearchCategoriesSucess(data));
    }else{
           yield put(SearchCategoriesError(data.error));
    }

}




/**
 * Search Categories
 */
const setSearchContractType = async (instance, actions)=>
    await instance.get(`/searches/search/contract-types/`)
    .then(response=>response)
    .catch((error)=>{
        try {
            if(error.response.status===500 || error.response.status===404){
                return {error:error.response.statusText}
            }else{
                return error.response.data
            }
        } catch (err) {
             console.log(error,err)
             return {error:"Error"}
        }

    })
function* searchContractType(actions){


    console.log("Search ContractType" , "Search ContractType")
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(setSearchContractType,axiosInstance, actions.payload);
    if(data.data){
        console.log("SearchContractType",data.data)
            yield put(SearchContractTypeSucess(data));
    }else{
           yield put(SearchContractTypeError(data.error));
    }

}





/**
 * Search langue
 */
const setSearchLangue = async (instance, actions)=>
    await instance.get(`/searches/search/languages/?q=${actions.q}`)
    .then(response=>response)
    .catch((error)=>{
        try {
            if(error.response.status===500 || error.response.status===404){
                return {error:error.response.statusText}
            }else{
                return error.response.data
            }
        } catch (err) {
             console.log(error,err)
             return {error:"Error"}
        }

    })
function* searchLangue(actions){


    console.log("Search Langue" , "Search Langue")
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(setSearchLangue,axiosInstance, actions.payload);
    if(data.data){
        console.log("Search langue",data.data)
            yield put(SearchLangueSucess(data));
    }else{
           yield put(SearchLangueError(data.error));
    }

}

export function* handleSearchArea(){
    console.log("Search Area" ," data.data.results")
    yield takeEvery("HANDLE_SEARCH_AREA", searchArea)
}


export function* handleSearchLangue(){
    console.log("Search Langue" ," data.data.results")
    yield takeEvery("HANDLE_SEARCH_LANGUE", searchLangue)
}

export function* handleSearchEducation(){
    console.log("Search Education" ," data.data.results")
    yield takeEvery("HANDLE_SEARCH_EDUCATION", searchEducation)
}
export function* handleSearch(){
    yield takeEvery("HANDLE_SEARCH", Search)
}

export function* handleSearchProfiles(){
    yield takeEvery("HANDLE_SEARCH_PROFILES", SearchProfiles)
}

export function* handleOrganizationsSearch(){
    
    yield takeEvery("HANDLE_SEARCH_ORGANIZATIONS", SearchOrganizations)
}

export function* handleSearchIndustries(){

    console.log("Industries" , "ok")
    yield takeEvery("HANDLE_SEARCH_INDUSTRIES", searchIndustries)
}
export function* handleSearchPosition(){
    console.log("Search Position" ,"data.data.results")
    yield takeEvery("HANDLE_SEARCH_POSITION", searchPosition)
}
export function* handleSearchSkills(){
    console.log("Search Skills" ,"data.data.results")
    yield takeEvery("HANDLE_SEARCH_SKILLS", searchSkills)
}

export function* handleSearchBenefits(){
    yield takeEvery("HANDLE_SEARCH_BENEFITS", searchBenefits)
}
export function* handleSearchOccupations(){
    yield takeEvery("HANDLE_SEARCH_OCCUPATIONS", searchOccupations)
}
export function* handleSearchCategories(){
    console.log("Search Skills" ,"data.data.results")
    yield takeEvery("HANDLE_SEARCH_CATEGORIES", searchCategories)
}


export function* handleSearchContractType(){
    console.log("Search cont" ,"data.data.results")
    yield takeEvery("HANDLE_SEARCH_CONTRACTTYPE", searchContractType)
}









export default function* rootSaga() {
    yield all([
        fork(handleSearch),
        fork(handleSearchProfiles),
        fork(handleSearchCompanyAdmin),
        fork(handleSearchContractType),
        fork(handleOrganizationsSearch),
        fork(handleSearchIndustries),
        fork(handleSearchCompany),
        fork(handleSearchEducation),
        fork(handleSearchPosition),
        fork(handleSearchSkills),
        fork(handleSearchLangue),
        fork(handleSearchArea),
        fork(handleSearchCategories),
        fork(handleSearchOccupations),
        fork(handleSearchBenefits)
    ]);
}



