import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { getProfileAreasSuccess, getProfileAreasError } from  "actions/profile";

const GetProfileAreasRequest = async (instance)=>
    await instance.get(`/profiles/areaofinterests/list/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetProfileAreasDetails(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetProfileAreasRequest,axiosInstance );
    if(data.data){
        yield put(getProfileAreasSuccess(data.data));
    }else{
        yield put(getProfileAreasError(data.error));
    }
    
}
export function* handleGetProfileAreas(){
    console.log("test" , "test_area")
    yield takeEvery("HANDLE_GET_PROFILE_AREAS", GetProfileAreasDetails)
}
export default function* rootSaga() {
    yield all([fork(handleGetProfileAreas)]);
}
//