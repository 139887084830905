import { handleResumeCheck, resumeCheckResetMessages } from 'actions/opportunities';
import React from 'react';
import {useDropzone} from 'react-dropzone';
import IntlMessages from "util/IntlMessages";
import {useDispatch,useSelector} from 'react-redux'
import Notif from 'components/uielements/Notification';


function BrowserLimitations(props) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: '.pdf, .doc, .docx'
  });

  const [open, setOpen]=React.useState(false)
  const resumeCheck=useSelector(state=>state.opportunities.resume_check)
  const dispatch = useDispatch()

  const handleChangeFile=(event)=>{
    event.preventDefault();
    let formData = new FormData(); 
    const fileD=event.target.files[0];
    formData.append("datafile",event.target.files[0]);
    dispatch(handleResumeCheck({opportunityId:props.opportunity_id?props.opportunity_id:'',fileData:formData}))
    setOpen(true)
  }
  const handleCloseNotif=()=>{
    setOpen(false)
    dispatch(resumeCheckResetMessages())
    }
  return (
<div>
<Notif open={open} title={<IntlMessages id="notification"/>} text={
          resumeCheck.error?
          resumeCheck.error :
          resumeCheck.data.message?resumeCheck.data.message:null}
          notification="notification"
          error={resumeCheck.error? 'error' : null}
          message={resumeCheck.data.message ? "message" : null}
          handleClose={handleCloseNotif}/>

    <div className="dropzone-card">
     
      <div className="dropzone">
        <div {...getRootProps({className: 'dropzone-file-btn'})}>
        <form onSubmit={props.handleChangeFile}>
          <input {...getInputProps()} name="datafile" type="file" onChange={props.handleChangeFile}/>
          {isDragAccept && (<p>All files will be accepted</p>)}
          {isDragReject && (<p>Some files will be rejected</p>)}
          {!isDragActive && (
          props.upload_resume?
            <p> {props.upload_resume} </p>
            :
            <p>{<IntlMessages id="upload_resume"/>} ...</p>)}
          </form>
        </div>
      </div>
    </div>
    </div>
  );

}

export default BrowserLimitations;