export function handleFilterAssessed(data){
    return {
        type:"HANDLE_GET_ASSESSED_FILTER",
        payload:data
    }
}
export function handleFilterAssessedSuccess(data){
    return {
        type:"GET_ASSESSED_FILTER",
        payload:data
    }
}

export function handleFilterAssessedError(data){
    return{
        type:"GET_ASSESSED_FILTER_FAILLURE",
        payload:data
    }
}
