import React,{useEffect} from 'react';
import { Route, Switch ,Redirect} from "react-router-dom";
import { withRouter } from "react-router";
import Content from "components/uielements/Content";
import Opportunities from "containers/opportunities/Opportunities";
import OpportunityDetails from "components/opportunities/OpportunityDetails";
import FormBuilder from "components/formsElements/formConstruction/FormBuilder";
import { CreateForm } from "components/formsElements/formConstruction/CreateForm";
import  ProfileHeader  from "components/profile/ProfileHeader"; 
import MyProfile from "components/profile/ProfileHeader/MyProfile";
import Profile from "components/profile/ProfileHeader/Profile";
import EducationForm from "pages/EducationForm";
import ProfilesAll from "components/profile/ListProfil/ProfilesList";
import CompaniesAll from "components/companies/ListCompanies";
import CompanyDetails from "components/companies/CompanyDetails";
import IndexDashboard from "components/uielements/Dashboard/index";
import OpportunitiesDashboard from "components/uielements/Dashboard/Opportunities"
import ApplicationsDashboard from "components/uielements/Dashboard/ApplicationsDashboard"
import MatchingDashboard from "components/uielements/Dashboard/MatchingDashboard"
import OrganizationDashboard from "components/uielements/Dashboard/OrganizationDashboard"
import DragNDrop from "components/uielements/Dashboard/dnd";
import AboutPage from "components/uielements/AboutPage";
import AppForm from "components/formsElements/Contact";
import { useSelector, useDispatch} from 'react-redux'
import ApplicationBusinessPipeline from 'components/uielements/Dashboard/ApplicationBusinessPipeLine';
import AppFormPage from 'components/uploadCV/AppFormPage';
import asyncComponent from '../../../util/asyncComponent';
import Contact from "components/formsElements/Contact";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-48427508-1');


function logPageView() {
  ReactGA.set({ page: window.location.pathname + window.location.search });
  ReactGA.pageview(window.location.pathname + window.location.search);
}


const HomeRoutes = ({ match }) =>


  <Switch onUpdate={() => window.scrollTo(0, 0)} >

      <Route path={`${match.url}`} exact component={Content}/>
        <Route path="/createform" exact component={CreateForm}/>
        <Route path="/addfieldstoform/:formId" exact component={FormBuilder}/>
        <Redirect from='/?' to='/home'/> 
        <Redirect from='/formation-en-droit-international-des-droits-de-lhomme-bamako-2021' to='/'/> 
            <Route path="/opportunities" component={Opportunities}/>

            <Route path="/opportunities?FacetCategory=Training" component={Opportunities}/>
            <Route path="/opportunities?FacetCategory=Funding" component={Opportunities}/>
            <Route path="/opportunities?FacetCategory=Job" component={Opportunities}/>

            <Route path="/opportunity/:slug" component={OpportunityDetails}/>
            <Route path="/formbuilder" exact component={FormBuilder}/>
            <Route path="/profile/:slug" exact component={MyProfile}/>
            <Route path="/profile/" exact component={MyProfile}/>
            <Route path="/home/" exact component={MyProfile}/>
            <Route path="/profiles/:slug" exact component={Profile}/>
            <Route path="/add-education" exact component={EducationForm}/>
            <Route path="/profiles" exact component={ProfilesAll}/>
            <Route path="/companies" exact component={CompaniesAll}/>
            <Route path="/companies/:slug" exact component={CompanyDetails}/>
            <Route path="/dashboard" exact component={IndexDashboard}/>
            <Route path="/dashboard/opportunities" exact component={IndexDashboard}/>
            <Route path="/admin-organization" exact component={OrganizationDashboard}/>
            <Route path="/dashboard/applicants" exact component={IndexDashboard}/>
            <Route path="/dashboard/matching" exact component={IndexDashboard}/>
            <Route path="/dashboard/followers" exact component={IndexDashboard}/>
            <Route path="/about" exact component={AboutPage}/>
            <Route path="/contact" exact component={AppForm}/>
            <Route path="/dashboard/applicants/pipeline" exact component={IndexDashboard}/>
            <Route path="/dashboard/opportunities/all/" exact component={IndexDashboard}/>
            <Route path="/dashboard/opportunities/online/" exact component={IndexDashboard}/>
            <Route path="/dashboard/opportunities/offline/" exact component={IndexDashboard}/>
            <Route path="/dashboard/opportunities/deleted/" exact component={IndexDashboard}/>
            {/* <Route path="/formation-en-droit-international-des-droits-de-lhomme-bamako-2021" exact component={AppFormPage}/> */}
            <Route component={asyncComponent(() => import("../extraPages/routes/404"))}/>
                      {/* <Route path='/dashboard/applicants/pipeline/applicants'  component={IndexDashboard}/>
                      <Route path='sharedprofiles'  component={IndexDashboard}/>
                      <Route path='recommendation'  component={IndexDashboard}/>  */}
        
            
      
        
  </Switch>;
export default withRouter(HomeRoutes);
