import React,{useState, useEffect}from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {useDispatch} from 'react-redux'
import Skills from './Skills';
import { splitStringQueryAddState } from 'util/UtilFunctions';
import {useHistory} from "react-router-dom";
import { handleSearchSkills } from 'actions/filters/skills';
import ContractType from './ContractsType';
import Industries from './Industries';
import Locations from './Locations';
import OpportunityType from './OpportunitiesType';
import { handleOpportunityTypeSearch } from 'actions/filters/opportunityType';
import { handleLocationsSearch } from 'actions/filters/locations';
import { handleIndustriesSearch } from 'actions/filters/industries';
import { handleContractTypeSearch } from 'actions/filters/contractType';
import { handleOpportunitiesSearch } from 'actions/search';
import Chip from '@material-ui/core/Chip';
import IntlMessages from "util/IntlMessages";
export default function OpportunitiesFilters(props) {
  const [keys, setKey]=useState([]);
  const dispatch = useDispatch()
  const browserHistory= useHistory();
  const constructUrl=()=>{
    var url ="";
    keys.map((item)=>{
        if(!url){
            return url+=`${item.type}=${item.name}`;
        }else{
            return url+=`&${item.type}=${item.name}`
        }
    })
    return url   
    }

  const handleDelete=(key)=>{
    setKey([...keys.filter(obj=>obj.name!==key.name)]);
   setTimeout(
    ()=>{
      browserHistory.push(`${window.location.pathname}?${constructUrl()}`);
      dispatch(handleSearchSkills({scope:"opportunity", search:`?${constructUrl()}`}))
      dispatch(handleOpportunityTypeSearch({scope:"opportunity", search:`?${constructUrl()}`}))
      dispatch(handleLocationsSearch({scope:"opportunity", search:`?${constructUrl()}`}))
      dispatch(handleIndustriesSearch({scope:"opportunity", search:`?${constructUrl()}`}))
      dispatch(handleContractTypeSearch({scope:"opportunity", search:`?${constructUrl()}`}))
      dispatch(handleOpportunitiesSearch({scope:"opportunity", search:`?${constructUrl()}`, page:`1`} ))
      //
    },1
  )
  }
  //
  const addItem=(item)=>{
    // var keys = keys.filter(obj=>obj.name!==item.name);
    // keys.push(item);
    // setKey({keys})
    setKey([...keys.filter(obj=>obj.name!==item.name), item])
    setTimeout(
      ()=>{
        browserHistory.push(`${window.location.pathname}?${constructUrl()}`);
        dispatch(handleSearchSkills({scope:"opportunity", search:`?${constructUrl()}`}))
        dispatch(handleOpportunityTypeSearch({scope:"opportunity", search:`?${constructUrl()}`}))
        dispatch(handleLocationsSearch({scope:"opportunity", search:`?${constructUrl()}`}))
        dispatch(handleIndustriesSearch({scope:"opportunity", search:`?${constructUrl()}`}))
        dispatch(handleContractTypeSearch({scope:"opportunity", search:`?${constructUrl()}`}))
        dispatch(handleOpportunitiesSearch({scope:"opportunity", search:`?${constructUrl()}`, page:`1`}))
      }
    )
  }
  useEffect(()=>{
    if(keys.length==1){
      setKey(splitStringQueryAddState(window.location.search));
    }
  },[setKey])

  return (
      <Grid container spacing={2}>
        <Grid item md={2} xs={4}>
          <h2> {<IntlMessages id="filters"/>} </h2>  
        </Grid>
        <Grid item md={2} xs={4}>
          <Skills scope="opportunity" addItem={addItem} keys={keys ? keys:null} delete={handleDelete} />
        </Grid>
        <Grid item md={2} xs={4}>
            <ContractType scope="opportunity" addItem={addItem} delete={handleDelete} keys={keys}/>
        </Grid>
        <Grid item md={2} xs={4}>
          <Industries scope="opportunity" addItem={addItem} delete={handleDelete} keys={keys}/>
        </Grid>
        <Grid item md={2} xs={4}>
          <Locations scope="opportunity" addItem={addItem} delete={handleDelete} keys={keys}/>
        </Grid>
        <Grid item md={2} container xs={4}>
          <OpportunityType scope="opportunity" addItem={addItem} delete={handleDelete} keys={keys}/>
        </Grid>
        <Grid item xs={12}>
          {
           
           

            keys.map((item)=>{
              return(
                <Chip label={item.name} 
                 onDelete={()=>handleDelete(item)}
                />
              )
            })
          }

          
        </Grid>
      </Grid>
  );
}

OpportunitiesFilters.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
