import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import SortIcon from "@material-ui/icons/ArrowDownward";
import {useDispatch, useSelector} from 'react-redux'
import {  handleGetFollowersDashboard } from 'actions/dashboard/keysList';
import React,{useState,useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiDialogContent from '@material-ui/core/DialogContent';

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


const columns = [
  {
    name: "Organization",
    selector: "profile_id.photo",
    sortable: true,
    cell: row => <div  style={{ width: 560 }}><Link  to={`/companies/${row.opportunity_id.organization_id.slug}`}><img className="img-profil" alt="Remy Sharp" src={row.opportunity_id.organization_id.logo}></img> {row.opportunity_id.organization_id.name}</Link></div>,
},
  {
    name: "Opportunity",
    selector: "opportunity_id.title",
    sortable: true,
    cell: row => <div><Link  to={`/opportunity/${row.opportunity_id.slug}`}>{row.opportunity_id.title}</Link></div>,
  },
  {
    name: "Contract type",
    selector: "opportunity_id.job_contract_type_id",
    sortable: true,
    right: true
  }
];

const isIndeterminate = indeterminate => indeterminate;
const selectableRowsComponentProps = { indeterminate: isIndeterminate };

const ListOfOpportunitiesApplyDashboard = (props , t ) => {
const [open, setOpen] = React.useState(false);
const {OpportunitiesApplyDashboard, total_applications} = props;

const handleClickOpen = () => {
  setOpen(true);
};
const handleClose = () => {
  setOpen(false);
};



const handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    console.log('Selected Rows: ', state.selectedRows);
  };
   
  return (
    <div className="">
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
      <span className="count">{total_applications}</span><br/><span>{<IntlMessages id="opportunities_applications"/>}</span>
    </Button>
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}  className="List">
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
      {<IntlMessages id="opportunities_applications"/>}
      </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
    <div className="container-wrap container animated slideInUpTiny animation-duration-3 dashboard">
        <div className="row">
      <Card>
        <DataTable
          //title={<span className="title-list-dialog">Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page.</span>}
          columns={columns}
          data={OpportunitiesApplyDashboard}
          defaultSortField="title"
          sortIcon={<SortIcon />}
          pagination
          selectableRows
          selectableRowsComponent={Checkbox}
          selectableRowsComponentProps={selectableRowsComponentProps}
          onSelectedRowsChange={handleChange}
        />
      </Card>
    </div>
    </div>
    </DialogContentText>
          </DialogContent>
        </Dialog>
</div>
  );
};
export default withTranslation('translation')(ListOfOpportunitiesApplyDashboard);