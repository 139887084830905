import { Field, reduxForm } from 'redux-form';
import { browserHistory } from 'react-router';
import DateFnsUtils from '@date-io/date-fns';
// import { translate } from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {useParams} from 'react-router-dom';
import Select from '@material-ui/core/Select';
import { withTranslation } from 'react-i18next';
import { Col, Form, Row, Panel, Modal, Button } from 'react-bootstrap';
import { renderTextField,renderSubmitGroup, renderDates,renderSelectField, renderFileInput,renderField,  renderAsyncAutocomplete,renderPlaceAutocomplete, renderDateTime, renderFieldWithButton } from '../components/formsElements/FormComponents';
import {  AddLanguageResetMessages, handleAddExperience, handleAddFile, handleAddLangue, handleDeleteLangue } from '../actions/profile';
import { UncontrolledAlert } from 'reactstrap';
import logo from '../assets/imgs/spinner.gif';
import FontAwesome from 'react-fontawesome';
import { LocationSearchInput } from 'components/formsElements/FieldsComponents';
import TextField from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import React,{useEffect, useState} from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import {API_BASE_URL, CLIENT_ID} from 'config';
import { handleSearchLangue } from 'actions/search';
import { Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {Alert} from '@material-ui/lab';
import IntlMessages from "util/IntlMessages";
//var Rating = require('react-rating');
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const loader=<CircularProgress  color="primary"/>;

const initState={
  level:" "
}


  function sleep(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  }
  function AddAchievement(props,submitError, serverError , params , handleSubmit) {

    const [state, setState] = useState(initState);
    const [openDialog, setOpenDialog] = React.useState(false);
    const spinner = useSelector(state=>state.profile.add_langue.spinner)
    const error = useSelector(state=>state.profile.add_langue.error)
    const message = useSelector(state=>state.profile.add_langue.message)
    const langue = useSelector(state=>state.search.langue.list)
    const [open, setOpen] = React.useState(false);
    const [valueRate, setValueRate] = React.useState(2);
    const {userId} =useParams();
    const [inputValue, setInputValue] = React.useState('');
    const  dispatch =useDispatch();
    const [tagsLangue, setTagsLangue] = useState([]);
    // //const [langue, setLangue] = React.useState([]);
    // const loading = open && langue.length === 0;
    const [options, setOptions] = React.useState([]);
    const [value, setValue] = React.useState(options[0]);
   
   
    const handleClickOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
        dispatch(AddLanguageResetMessages())
      };
      console.log("la list des langues" , langue)

    
      const handleSelectLangue=(test,elt, reason)=>{
        setTagsLangue(elt);
      }
      const handleSearchChangeLangue=(event)=>{
        dispatch(handleSearchLangue({q:event.target.value}))
      }
    


    
  
    const handleChange =(event)=>{
        setState({...state, [event.target.name]:event.target.value});
      }

  const submit=(event, values)=>{
    event.preventDefault();
    


  
   
   console.log("Data" , state)
   dispatch(handleAddLangue({...state, 
     language_id:tagsLangue.length !== 0 ? tagsLangue.language_id: "",
     level: valueRate}))
  }


  const handleRemoveLangue =(langueId)=> {
    const saveData={
     // userId:this.props,
      langueId:langueId
    }
    console.log("removeSector response : ", saveData);
    dispatch(handleDeleteLangue(saveData))
    
    }
  const renderField = ( input, type ) =>{
    if(type=== 'text') {
            return (<input {...input}  type={type} />)
    } else {
      return (<div>
        <Rating {...input} 
        initialRate={parseInt(input.value , 10)}
        emptySymbol="fa fa-circle-thin fa-2x"
        fullSymbol="fa fa-circle fa-3x"/>
        </div>)
    }           
  }
  const {t}= props;

    return (
      
      <div className="">
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
      <Icon className="fa fa-plus-circle" style={{ color: "#ccc"}} /> 
    </Button>
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
      {<IntlMessages id="add_language"/>}
      </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {message ?<Alert severity="success"  onClose={()=>dispatch(AddLanguageResetMessages())}>{message}</Alert>: null}
          {serverError ?<Alert  severity="success"   onClose={()=>dispatch(AddLanguageResetMessages())}>{serverError.message}</Alert>: null}
          {error ?<Alert severity="danger"  onClose={()=>dispatch(AddLanguageResetMessages())}>{error}</Alert>: null}
            
            <div className="row">
               <div className="col-lg-12">
               <Grid item xs={12}>
               <h4 className="title">{t('wutiko.languages')}</h4>
              {props.itemDetailsProfileLanguages? props.itemDetailsProfileLanguages.map((langue) =>{
                  return (      
                          <div className="row" key={langue.language_id}> 
                          <div className="row col-md-12">

                            <span className="col-md-4">
                            <DeleteIcon  onClick={e => handleRemoveLangue(langue.id)} style={{ color: "#ccc"}} /> 
                            {langue.language_id.name}</span>
                            <Box className="col-md-8" component="fieldset" mb={3} borderColor="transparent">
                                <Rating name="disabled" value={langue.language_level_id.level} disabled />
                              </Box>
                          
             
                          </div>
                          </div>
                          
                        )
        
                }): null}
                </Grid>
               <Form onSubmit={submit}>                            
               <Row>
             <Col xs={6}  sm={6} lg={6} md={6}>
             <Autocomplete
                          fullWidth
                          id="language_id"
                          size="small"
                          freeSolo
                          disableClearable
                          open={openDialog}
                          onOpen={() => {
                            setOpenDialog(true);
                          }}
                          onClose={() => {
                            setOpenDialog(false);
                          }}
                          onChange={handleSelectLangue}
                          options={langue}
                          getOptionLabel={(option) => option.name}
                          value={value}
                          inputValue={inputValue}
                          onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);}}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option.title}
                                size="small"
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField {...params} onChange={handleSearchChangeLangue} variant="outlined" 
                            label={<IntlMessages id="add_new_language"/>}
                            margin="normal"
                            placeholder="Ex: French" />
                          )}
                        /> 
  
              </Col>
              <Col xs={6}  sm={6} lg={6} md={6}>
              <Box component="fieldset" mb={3} borderColor="transparent">
              <Typography component="legend"> <IntlMessages id="level"/> </Typography>
               <Rating
                id="rate"
                name="rate"
                value={valueRate}
                onChange={(event, newValue) => {
                  setValueRate(newValue);
                }}
              /> 
              {/* <Field className="Rating" id="level" value={state.level} name="level"  component={renderField}/> */}
            </Box>
             </Col>
            
             
           </Row>
           
           <Row>
           <div className="col-md-12">
                  {spinner?loader:
                  <button name="submit" type="submit" 
                  className="register-training save  pull-right">
                    {<IntlMessages id="save"/>}</button>}
                 
             </div>
           </Row>
          <br/>
        </Form>

              </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>

   
      </div>
    );
  }


  AddAchievement = reduxForm({
  form: 'AddAchievement',
  enableReinitialize: true
})(AddAchievement);

export default withTranslation('translation')(AddAchievement);