import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import SortIcon from "@material-ui/icons/ArrowDownward";
import {useDispatch, useSelector} from 'react-redux'
import {  handleGetFollowersDashboard } from 'actions/dashboard/keysList';
import React,{useState,useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiDialogContent from '@material-ui/core/DialogContent';

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { HandleFollowProfile } from "actions/profile";
import Notif from "components/uielements/Notification";


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);






const isIndeterminate = indeterminate => indeterminate;
const selectableRowsComponentProps = { indeterminate: isIndeterminate };

  const ListOfViewProfilDashboard = (props , t ) => {
const [open, setOpen] = React.useState(false);
const {ViewProfilDashboard, total_views_90} = props;
const dispatch = useDispatch()
const message =useSelector(state=>state.profile.followProfile.message)
const error =useSelector(state=>state.profile.followProfile.error)
const [openNotif, setOpenNotif]=React.useState(false)

const handleClickOpen = () => {
  setOpen(true);
};
const handleClose = () => {
  setOpen(false);
};

// const Data={
//   user_id:row.id
// }

const followProfile=(user_id)=>{
    const Data={
      user_id:user_id
    }
  dispatch(HandleFollowProfile(Data))
  setOpenNotif(true)
}
const handleCloseNotif=()=>{
  setOpenNotif(false)
  //dispatch(FollowProfileError())
  }


const handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    console.log('Selected Rows: ', state.selectedRows);
  };



  const columns = [
    {
      name: "Photo",
      selector: "photo",
      sortable: true,
      cell: row => <div><Link  
      to={row.connected === "true" ?
        `/profiles/${row.slug}` :"#"}>
        <Avatar className="img-profil" alt="Remy Sharp" src={row.photo}></Avatar>
      </Link>
      </div>,
  },
    {
      name: "Name",
      selector: "first_name",
      sortable: true,
      cell: row => <div>
        {row.connected === "true" ?
        <Link  to={`/profiles/${row.slug}`}>{row.first_name} {row.last_name}</Link>
        :
        <Link  to="#">{row.first_name} {row.last_name}</Link>
        }
        </div>,
    },
    {
      name: "Title",
      selector: "title",
      sortable: true,
      right: true
    },
    {
      name: "Mutual friend",
      selector: "mutual_friend",
      sortable: true,
      right: true,
      cell: row => <div>{row.mutual_friend}<span>shared connections</span> </div>,
    },
    {
      name: "Connexion",
      selector: "connected",
      sortable: true,
      right: true,
      cell: row => <div>{row.connected === "true" ?  
      <Link to={`/profiles/${row.slug}`}> <Button className="btn-profile text-right view-profile" ><IntlMessages id="view_profile" /> </Button></Link>
      :
        row.connected === "pending" ? 
        <Button className="btn-profile text-right pending" disabled><IntlMessages id="pending" /> </Button>
        :
        <Button className="btn-profile text-right connect" onClick={e => followProfile(row.id)}>
                    <IntlMessages id="connect" />
                    </Button>
        }
         
         </div>,
    }
  ];
   
  return (
    <div className="">
      <Notif open={openNotif} title={<IntlMessages id="notification"/>} text={
        error?
        error :
        message?message:null}
        notification="notification"
        error={error? error : null}
        message={message ? "message" : null}
        handleClose={handleCloseNotif}/>
        
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
      <span className="count">{total_views_90}</span><br/><span> <span>{<IntlMessages id="who_are_interested_in_your_profile"/>}</span></span>
    </Button>
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}  className="List">
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
      {<IntlMessages id="who_are_interested_in_your_profile"/>}
      </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
    <div className="container-wrap container animated slideInUpTiny animation-duration-3 dashboard">
        <div className="row">
      <Card>
        <DataTable
       // title={<span className="title-list-dialog">Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page.</span>}
          columns={columns}
          data={ViewProfilDashboard}
          defaultSortField="title"
          sortIcon={<SortIcon />}
          pagination
          selectableRows
          selectableRowsComponent={Checkbox}
          selectableRowsComponentProps={selectableRowsComponentProps}
          onSelectedRowsChange={handleChange}
        />
      </Card>
    </div>
    </div>
    </DialogContentText>
          </DialogContent>
        </Dialog>
</div>
  );
};
export default withTranslation('translation')(ListOfViewProfilDashboard);