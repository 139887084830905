import React,{useEffect} from 'react';
import Header from 'components/uielements/Header';
import Footer from 'components/uielements/Footer';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import HomeRoutes from 'app/routes/home/index'
import MenuPrincipal from 'components/uielements/MenuPrincipal';
import { HOMEPAGE_URL } from '../config.js'

import { useSelector, useDispatch} from 'react-redux'
import { CircularProgress } from 'material-ui';
import SideBar from './SideBar/index.js';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  cont:{
    paddingTop:30,
    paddingBottom:30,
    zIndex:1,
    background:"#9b9b9b",
  },
  title:{
    paddingBottom:15,
  }
}));

console.log("test url home" , window.location.pathname)

const Home = (props) => {
    const classes = useStyles();

  const featuredOpp= useSelector(state=>state.opportunities.featured_opportunities)
    useEffect(()=>{
      window.scrollTo(0, 0)
      console.log("PROPS-HOME", props)
    })
    return (
        <div className="app-container">
         {/* {
          window.location.pathname === '/dashboard/' ?
         <SideBar/>:null
         }  */}

            <div className="app-main-container">
           
                <div className="app-header">

                    <Header {...props}/>
                    {  
                    window.location.pathname === '/' ?
                    null :
                    <div className="header-menu">
                    <MenuPrincipal/>
                    </div>
                     } 
                </div>     
                {featuredOpp?
                <main className="app-main-content-wrapper ">
                 <HomeRoutes/>  
                  <div className="app-main-content">
                    </div>
                    <Footer/>
                </main>:<CircularProgress size={80}/>}
            </div>
        </div>
    );
};
export default Home;




