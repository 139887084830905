import React from 'react';
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import configureStore, {history} from './store';
import './firebase/firebase';
import App from './containers/App';
import ScrollToTop from './ScrollToTop';
import i18n from './i18n';
import {createMuiTheme} from "@material-ui/core/styles";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-48427508-1');




function logPageView() {
  ReactGA.set({ page: window.location.pathname + window.location.search });
  ReactGA.pageview(window.location.pathname + window.location.search);
}

window.scrollTo(0, 0);
window.i18n = i18n;
export const store = configureStore();
const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#fdc000",
      },
      secondary: {
        main: "#ff9801",
      },
    },
  });
const MainApp = () =>
<I18nextProvider i18n={i18n} theme={theme} >
    <Provider store={store}>
        <ConnectedRouter onUpdate={logPageView} history={history}>
        <ScrollToTop>
            <Switch>
              
                <Route path="/" component={App}/>
            </Switch>
            </ScrollToTop>
        </ConnectedRouter>
    </Provider>
    </I18nextProvider>
export default MainApp;