import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { getProfileLangueSuccess, getProfileLangueError } from  "actions/profile";

const GetProfileLangueRequest = async (instance)=>
    await instance.get(`/profiles/languages/list/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetProfileLangue(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetProfileLangueRequest,axiosInstance );
    if(data.data){
        yield put(getProfileLangueSuccess(data.data));
    }else{
        yield put(getProfileLangueError(data.error));
    }
    
}
export function* handleGetProfileLangue(){
    console.log("test" , "test_Langue")
    yield takeEvery("HANDLE_GET_PROFILE_LANGUE", GetProfileLangue)
}
export default function* rootSaga() {
    yield all([fork(handleGetProfileLangue)]);
}
//