


// eslint-disable import / first
import fileSaver from 'file-saver';
import { useHistory } from "react-router-dom"
export const SHOW_USERS = 'SHOW_USERS'
export const PRICING = 'PRICING';
export const UPDATE_USERS = 'UPDATE_USERS'
export const SHOW_USERSSIDEBAR = 'SHOW_USERSSIDEBAR'
export const SHOW_JOBSSIDEBAR = 'SHOW_JOBSSIDEBAR'
export const SHOW_ITEMDETAILS = 'SHOW_ITEMDETAILS'
export const SHOW_ITEMLIST = 'SHOW_ITEMLIST'
export const SHOW_ITEMGROUPLIST = 'SHOW_ITEMGROUPLIST'
export const SHOW_ITEMCONNEXIONLIST = 'SHOW_ITEMCONNEXIONLIST'
export const SHOW_COMPANIESSIDEBAR = 'SHOW_COMPANIESSIDEBAR'
export const SHOW_GROUPSSIDEBAR = 'SHOW_GROUPSSIDEBAR'
export const SHOW_EVENTSSIDEBAR = 'SHOW_EVENTSSIDEBAR'
export const SHOW_EVENTS = 'SHOW_EVENTS'
export const SHOW_FAVORITEOBJECT = 'SHOW_FAVORITEOBJECT'
export const SHOW_JOBFAVORITEOBJECT = 'SHOW_JOBFAVORITEOBJECT'
export const SHOW_JOBAPPLIEDLIST = 'SHOW_JOBAPPLIEDLIST'
export const SHOW_APPLYCATIONSPONTANEOUSLIST = 'SHOW_APPLYCATIONSPONTANEOUSLIST'
export const SHOW_THREEITEMLIST = 'SHOW_THREEITEMLIST'
export const SHOW_THREEITEMLISTGROUP = 'SHOW_THREEITEMLISTGROUP'
export const SHOW_ITEMCONNEXIONGROUPPENDINGLIST = 'SHOW_ITEMCONNEXIONGROUPPENDINGLIST'
export const SHOW_ITEMCONNEXIONGROUPPENDINGLATEST = 'SHOW_ITEMCONNEXIONGROUPPENDINGLATEST'
export const SHOW_CONNEXIONLIST = 'SHOW_CONNEXIONLIST'

export const SHOW_SEARCHAPPLIST = 'SHOW_SEARCHAPPLIST'
export const SHOW_SEARCHAPPLIST_REQUEST = 'SHOW_SEARCHAPPLIST_REQUEST'
export const SHOW_SEARCHAPPLIST_SUCCESS = 'SHOW_SEARCHAPPLIST_SUCCESS'
export const SHOW_SEARCHAPPLIST_FAILURE = 'SHOW_SEARCHAPPLIST_FAILURE'


export const SHOW_SUGGESTIONLIST = 'SHOW_SUGGESTIONLIST'
export const SHOW_SUGGESTIONLIST_REQUEST = 'SHOW_SUGGESTIONLIST_REQUEST'
export const SHOW_SUGGESTIONLIST_SUCCESS = 'SHOW_SUGGESTIONLIST_SUCCESS'
export const SHOW_SUGGESTIONLIST_FAILURE = 'SHOW_SUGGESTIONLIST_FAILURE'



export const SHOW_FRIENDSUGGESTIONLIST = 'SHOW_FRIENDSUGGESTIONLIST'
export const SHOW_GROUPLIST = 'SHOW_GROUPLIST'
export const SHOW_CONNEXIONGROUPALLLIST = 'SHOW_CONNEXIONGROUPALLLIST'
export const SHOW_COMPANIESLOGOLIST = 'SHOW_COMPANIESLOGOLIST';
export const SHOW_PUB = 'SHOW_PUB';
export const SHOW_NOTIFICATIONS = 'SHOW_NOTIFICATIONS';
export const GET_ALERT_LIST = 'GET_ALERT_LIST';

export const GET_LIST_PRICING = 'GET_LIST_PRICING';
export const GET_LIST_PRICING_BUSINESS = 'GET_LIST_PRICING_BUSINESS';
export const GET_LIST_PRICING_AD = 'GET_LIST_PRICING_AD';

export const SHOW_SECTORS = 'SHOW_SECTORS';
export const SHOW_EDUCATIONS = 'SHOW_EDUCATIONS';
export const SHOW_LANGUES = 'SHOW_LANGUES';
export const SHOW_POSITIONS = 'SHOW_POSITIONS';
export const SHOW_SKILLS = 'SHOW_SKILLS';
export const GET_LIST_GROUP = 'GET_LIST_GROUP';
export const GET_LIST_INTEREST = 'GET_LIST_INTEREST';

export const CRETATE_OPPORTUNITY_ALERT='CRETATE_OPPORTUNITY_ALERT';
export const GET_ALERTS_LIST='GET_ALERTS_LIST';
export const UPDATE_ALERT='UPDATE_ALERT';
export const CREATE_FORM='CREATE_FORM';
// export const PushOnTheState = PushOnTheState;   

export * from './Setting';
export * from './Auth';


export function showUsers(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_USERS, payload: response.data })
    })
  }
}

export function showLangues(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_LANGUES, payload: response.data})
    })
  }
}

export function showPositions(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_POSITIONS, payload: response.data })
    })
  }
}

export function showEducations(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_EDUCATIONS, payload: response.data })
    })
  }
}

export function showSkills(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_SKILLS, payload: response.data })
    })
  }
}

export function showSectors(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_SECTORS, payload: response.data })
    })
  }
}

export function updateUsers(url) {

  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance

    instance.get(url)
    .then((response) => {
      dispatch({ type: UPDATE_USERS, payload: response.data })
    })
  }
}
export function getProjectsXls(url, requestTarget, data,name){
  return (dispatch, getState)=>{
    let instance=getState().user.axiosInstance;
    instance.post(url,data,{ responseType: 'arraybuffer' })
    .then((response)=>{
      console.log("getProjectsXls", requestTarget, response, data);
        var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        fileSaver.saveAs(blob, `${name}.xls`);
        dispatch({type :`${requestTarget}_SUCCESS`, payload: response.data});
    }).catch(error=>{
      console.log("getProjectsXls", requestTarget, error, data);
    if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      }else{
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
  }
}
export function showUsersSidebar(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_USERSSIDEBAR, payload: response.data.results })
    })
  }
}

export function showJobsSidebar(url) {

  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_JOBSSIDEBAR, payload: response.data.results })
    })
  }
}

export function showItemDetails(instance, url) {
    
  return (dispatch, getState) => {
    let instance = getState().state.axiosInstance;
    console.log("error showItemDetails", "showItemDetails");
    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_ITEMDETAILS, payload: response.data })
    })
    .catch(function (error) {
      console.log("error showItemDetails", url, error);
    });
  }
}

//axiosInstance=state.axiosInstance



export function showGroupList(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_GROUPLIST, payload: response.data.results })
    })
    .catch(function (error) {
      console.log("error showGroupList", url, error);
    });
  }
}

export function showConnexionGroupAllList(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_CONNEXIONGROUPALLLIST, payload: response.data.results })
    })
    .catch(function (error) {
      console.log("error showConnexionGroupAllList", url, error);
    });
  }
}

export function showItemList(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_ITEMLIST, payload: response.data.results })
    })
    .catch(function (error) {
      console.log("error showItemList", url, error);
    });
  }
}

export function showNotifications(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_NOTIFICATIONS, payload: response.data.results })
    })
    .catch(function (error) {
      console.log("error showNotifications", url, error);
    });
  }
}
export function showItemGroupList(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_ITEMGROUPLIST, payload: response.data.results })
    })
    .catch(function (error) {
      console.log("error showItemGroupList", url, error);
    });
  }
}

export function showItemConnexionList(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_ITEMCONNEXIONLIST, payload: response.data.results })
    })
    .catch(function (error) {
      console.log("error showItemConnexionList", url, error);
    });
  }
}


export function showItemConnexionGroupPendingList(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_ITEMCONNEXIONGROUPPENDINGLIST, payload: response.data.results })
    })
    .catch(function (error) {
      console.log("error showItemConnexionGroupPendingList", url, error);
    });
  }
}
export function showItemConnexionGroupPendingLatest(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_ITEMCONNEXIONGROUPPENDINGLATEST, payload: response.data.results })
    })
    .catch(function (error) {
      console.log("error showItemConnexionGroupPendingLatest", url, error);
    });
  }
}
export function showConnexionList(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_CONNEXIONLIST, payload: response.data})
    })
    .catch(function (error) {
      console.log("error showConnexionList", url, error);
    });
  }
}


export function showFriendSuggestionList(url) {

  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_FRIENDSUGGESTIONLIST, payload: response.data })
    })
    .catch(function (error) {
      console.log("error showFriendSuggestionList", url, error);
    });
  }
}


export function showCompaniesLogoList(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.blankAxiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_COMPANIESLOGOLIST, payload: response.data.results})
    })
    .catch(function (error) {
      console.log("error showCompaniesLogoList", url, error);
    });
  }
}

export function showPub(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.blankAxiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_PUB, payload: response.data })
    })
    .catch(function (error) {
      console.log("error showPub", url, error);
    });
  }
}

export function showThreeItemList(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_THREEITEMLIST, payload: response.data.results })
    })
    .catch(function (error) {
      console.log("error showThreeItemList", url, error);
    });
  }
}


export function showThreeItemListGroup(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_THREEITEMLISTGROUP, payload: response.data.results })
    })
    .catch(function (error) {
      console.log("error showThreeItemListGroup", url, error);
    });
  }
}

export function showApplycationSpontaneousList(url) {
    
  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_APPLYCATIONSPONTANEOUSLIST, payload: response.data.results })
    })
    .catch(function (error) {
      console.log("error showApplycationSpontaneousList", url, error);
    });
  }
}

export function showCompaniesSidebar(url) {
 
  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance;
    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_COMPANIESSIDEBAR, payload: response.data.results })
    })
  }
}


export function showGroupsSidebar(url) {

  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_GROUPSSIDEBAR, payload: response.data.results })
    })
  }
}

export function showEvents(url) {

  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_EVENTS, payload: response.data.results })
    })
  }
}

export function showEventsSidebar(url) {

  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_EVENTSSIDEBAR, payload: response.data.results })
    })
  }
}

export function showFavoriteObject(url) {

  return (dispatch, getState) => {
    
    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_FAVORITEOBJECT, payload: response.data })
    })
  }
}

export function showJobFavoriteObject(url) {

  return (dispatch, getState) => {
    
    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_JOBFAVORITEOBJECT, payload: response.data.results})
    })
  }
}

export function showAppliedJobList(url) {

  return (dispatch, getState) => {
    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      dispatch({ type: SHOW_JOBAPPLIEDLIST, payload: response.data.results })
    })
    .catch(function (error) {
      console.log("error showAppliedJobList", url, error);
    });
  }
}
export function getItemnoparams(url, itemType, callback) {

  return (dispatch, getState) => {
    const instance = getState().user.axiosInstanceNoParams;
    dispatch({ type: `HANDLE_${itemType}`});
    // dispatch({ type: `${itemType}_REQUEST`});
    instance.get(url)
    .then((response) => {
      console.log('getItemnoparams response : ', itemType, response)
      console.log(response.data)
      dispatch({ type: itemType, payload: response.data })
    })
    .catch(error => {
      if (error.response && error.response.data) {
        dispatch({ type: `${itemType}_FAILURE`, payload: error.response.data });
        console.log(error.response.data)
      } else {
        dispatch({ type: `${itemType}_FAILURE`, payload: error });
        console.log(error)
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}

export function getItem(url, itemType, callback) {

  return (dispatch, getState) => {
    const instance = getState().user.axiosInstance;
    dispatch({ type: `HANDLE_${itemType}`});
    // dispatch({ type: `${itemType}_REQUEST`});
    instance.get(url)
    .then((response) => {
      console.log('getItem response : ', itemType, response)
      console.log(response.data)
      dispatch({ type: itemType, payload: response.data })
    })
    .catch(error => {
      if (error.response && error.response.data) {
        dispatch({ type: `${itemType}_FAILURE`, payload: error.response.data });
        console.log(error.response.data)
      } else {
        dispatch({ type: `${itemType}_FAILURE`, payload: error });
        console.log(error)
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}
export function postItem(url, itemType,data, callback) {

  return (dispatch, getState) => {
    const instance = getState().user.axiosInstance;
    dispatch({ type: `HANDLE_${itemType}`});
    instance.post(url,data)
    .then((response) => {
      console.log('postItem response : ', itemType, response)
      console.log(`${itemType}`,response.data)
      dispatch({ type: itemType, payload: response.data })
    })
    .catch(error => {
      if (error.response && error.response.data) {
        dispatch({ type: `${itemType}_FAILURE`, payload: error.response.data });
        console.log(`${itemType}_FAILURE`,error.response.data)
      } else {
        dispatch({ type: `${itemType}_FAILURE`, payload: error });
        console.log(`${itemType}_FAILURE`,error)
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}
export function postItemNoParams(url, itemType,data, callback) {

  return (dispatch, getState) => {
    const instance = getState().user.axiosInstanceNoParams;
    dispatch({ type: `HANDLE_${itemType}`});
    instance.post(url,data)
    .then((response) => {
      console.log('postItem response : ', itemType, response)
      console.log(`${itemType}`,response.data)
      dispatch({ type: itemType, payload: response.data })
    })
    .catch(error => {
      if (error.response && error.response.data) {
        dispatch({ type: `${itemType}_FAILURE`, payload: error.response.data });
        console.log(`${itemType}_FAILURE`,error.response.data)
      } else {
        dispatch({ type: `${itemType}_FAILURE`, payload: error });
        console.log(`${itemType}_FAILURE`,error)
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}
// axiosInstanceNoParams
export function putItemNoParams(url, itemType,data, callback) {

  return (dispatch, getState) => {
    const instance = getState().user.axiosInstanceNoParams;
    dispatch({ type: `HANDLE_${itemType}`})
    instance.put(url,data)
    .then((response) => {
      console.log('puttItem response : ', itemType, response)
      console.log(response.data)
      dispatch({ type: itemType, payload: response.data })
    })
    .catch(error => {
      if (error.response && error.response.data) {
        dispatch({ type:`${itemType}_FAILURE`,payload: error.response.data });
        console.log(`${itemType}_FAILURE`,error.response.data)
      } else {
        dispatch({type:`${itemType}_FAILURE`, payload: error});
        console.log(`${itemType}_FAILURE`,error)
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}

export function putItem(url, itemType,data, callback) {

  return (dispatch, getState) => {
    const instance = getState().user.axiosInstance;
    dispatch({ type: `HANDLE_${itemType}`})
    instance.put(url,data)
    .then((response) => {
      console.log('puttItem response : ', itemType, response)
      console.log(response.data)
      dispatch({ type: itemType, payload: response.data })
    })
    .catch(error => {
      if (error.response && error.response.data) {
        dispatch({ type:`${itemType}_FAILURE`,payload: error.response.data });
        console.log(`${itemType}_FAILURE`,error.response.data)
      } else {
        dispatch({type:`${itemType}_FAILURE`, payload: error});
        console.log(`${itemType}_FAILURE`,error)
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}
export function postItemAnonymous(url, itemType,data, callback) {

  return (dispatch, getState) => {
    const instance = getState().user.blankAxiosInstance;
    instance.post(url,data)
    .then((response) => {
      console.log('postItemAnonymous response : ', itemType, response)
      console.log(response.data)
      dispatch({ type: itemType, payload: response.data })
    })
    .catch(error => {
      if (error.response && error.response.data) {
        dispatch({ type: `${itemType}_FAILURE`, payload: error.response.data });
        console.log(error.response.data)
      } else {
        dispatch({ type: `${itemType}_FAILURE`, payload: error });
        console.log(error)
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}
// Messages
export function messagesProfilesAdd(url, requestTarget, data){
  return(dispatch, getState)=>{
    setMessagesEmpty();
    let instance=getState().user.axiosInstance;
    instance.post(url,data)
    .then((response)=>{
      if (response.status!==201 && response.status !==200) {
        console.log("messagesProfilesAdd", requestTarget, response);
        dispatch({type :`${requestTarget}_FAILURE`, payload: response.data});
      }
      else{
        console.log("messagesProfilesAdds", requestTarget, response.data);
        dispatch({type :`${requestTarget}_SUCCESS`, payload: response.data});

      }
    }).catch(error=>{
      console.log("messagesProfilesAdd", requestTarget, error, data);
    if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      }else{
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
  }
}
export function messagesConversationAdd(url, requestTarget, data){
  return(dispatch, getState)=>{
    setMessagesEmpty();
    let instance=getState().user.axiosInstance;
    instance.post(url,data)
    .then((response)=>{
      if(response.data.data){
      instance.get(`/messages/profiles/conversations/${response.data.data.conversation_id}/messages/list/`)
      .then((response_listMessages) => {
        console.log('getItem response : ', "PROFILES_CONVERSATION_MESSAGES_LIST", response_listMessages)
        console.log(response_listMessages.data)
        dispatch({ type: "PROFILES_CONVERSATION_MESSAGES_LIST", payload: response_listMessages.data });
        console.log("messagesProfilesAdds", requestTarget, response.data);
        dispatch({type :`${requestTarget}_SUCCESS`, payload: response.data});
      })
      .catch((error) => {
        console.log("getItem error : ", "PROFILES_CONVERSATION_MESSAGES_LIST", error);
      })
    }
    }).catch(error=>{
      console.log("messagesProfilesAdd", requestTarget, error, data);
    if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      }else{
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
  }
}
export function setMessagesEmpty(){
  return(dispatch, getState)=>{
    dispatch({type :"SET_MESSAGES_EMPTY", payload: null});
  }
}

export function setConversationMessagesListEmpty(){
  return(dispatch, getState)=>{
    console.log("SET_CONVERSATION_MESSAGES_EMPTY")
    dispatch({type :"SET_CONVERSATION_MESSAGES_EMPTY", payload: null});
  }
}
// End Messages

export function getItemAnonymous(url, itemType, callback) {

  return (dispatch, getState) => {

    const instance = getState().user.blankAxiosInstance;
    dispatch({ type: `HANDLE_${itemType}`});
    dispatch({ type: `${itemType}_REQUEST`});
    instance.get(url)
    .then((response) => {
      console.log('getItemAnonymous response : ', itemType, response)
      dispatch({ type: itemType, payload: response.data })
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        dispatch({ type: `${itemType}_FAILURE`, payload: error.response.data});
      } else {
        dispatch({ type: `${itemType}_FAILURE`, payload: error });
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}
export function ActivateAccountRequest(url, itemType, callback) {

  return (dispatch, getState) => {

    const instance = getState().user.blankAxiosInstance;
    instance.get(url)
    .then((response) => {
      console.log('getItemAnonymous response : ', itemType, response)
      dispatch({ type: itemType, payload: response.data })
    })
    .catch((error) => {
      console.log("getItemAnonymous error : ", itemType, error.response.data);
      if (error.response && error.response.data) {
        dispatch({ type: `${itemType}_FAILURE`, payload: error.response.data});
      } else {
        dispatch({ type: `${itemType}_FAILURE`, payload: error });
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}

export function fetchItem(url, requestTarget, callback) {

  return (dispatch, getState) => {

    const instance = getState().user.axiosInstance;

    dispatch({ type: `${requestTarget}_REQUEST`});
    instance.get(url)
    .then((response) => {
      console.log('fetchItem response : ', url, response);
      dispatch({ type: `${requestTarget}_SUCCESS`, payload: response.data });
    })
    .catch((error) => {
      console.log('fetchItem error : ', url, error);
      if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data });
      } else {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error });
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}

export function fetchItem1(url, requestTarget, callback) {

  return (dispatch, getState) => {

    const instance = getState().user.axiosInstance;

    dispatch({ type: `${requestTarget}_REQUEST`});
    instance.get(url)
    .then((response) => {
      console.log('fetchItem response : ', url, response);
      dispatch({ type: `${requestTarget}_SUCCESS`, payload: response.data.results });
    })
    .catch((error) => {
      console.log('fetchItem error : ', url, error);
      if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data.results });
      } else {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error });
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}


export function fetchItemAnonymous(url, requestTarget, callback) {

  return (dispatch, getState) => {

    const instance = getState().user.blankAxiosInstance;

    dispatch({ type: `${requestTarget}_REQUEST`});
    instance.get(url)
    .then((response) => {
      console.log('fetchItem response : ', url, response);
      dispatch({ type: `${requestTarget}_SUCCESS`, payload: response.data });
    })
    .catch((error) => {
      console.log('fetchItem error : ', url, error);
      if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data });
      } else {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error });
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}

export function patchItem(url, data, responseCallback, errorCallback) {

  return (dispatch, getState) => {

    const instance = getState().user.axiosInstance;

    instance.patch(url, data)
    .then((response) => {
      console.log('patchItem response : ', url, response);
      if(responseCallback) {
        responseCallback();
      }
    })
    .catch((error) => {
      console.log('patchItem error : ', url, error);
      if(errorCallback) {
        errorCallback();
      }
    })
  }
}


export function removeItem(url, itemType, callback) {

  return (dispatch, getState) => {

    const instance = getState().user.axiosInstance;

    instance.put(url)
    .then((response) => {
      console.log("removeItem response : ", itemType, response);
      dispatch({ type: itemType })
    })
    .catch((error) => {
      console.log("removeItem error : ", itemType, error);
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}

export function deleteForm(url, itemType, callback) {

  return (dispatch, getState) => {

    const instance = getState().user.axiosInstance;

    instance.delete(url)
    .then((response) => {
      console.log("deleteForm response : ", itemType, response);
      dispatch({ type: itemType })
    })
    .catch((error) => {
      console.log("deleteForm error : ", itemType, error);
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}


export function getForm(url, requestTarget, callback){

  return (dispatch, getState) => {

    let instance = getState().user.axiosInstance;

    instance.get(url)
    .then((response) => {
      console.log("getForm response", requestTarget, response);
      dispatch({ type: requestTarget, payload: response.data });
    })
    .catch(error => {
      console.log("getForm error", requestTarget, error);
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}

export function addForm(url, requestTarget, data, callback){

  return (dispatch, getState) => {

    let instance = getState().user.axiosInstance;

    dispatch({ type: `${requestTarget}_REQUEST` });

    instance.post(url, data)
    .then((response) => {
      console.log("addForm response", requestTarget, response)
      if(response.status !== 201 && response.status !== 200){
        dispatch({ type: `${requestTarget}_FAILURE`, payload: response.data });

      } else {
        dispatch({ type: `${requestTarget}_SUCCESS`, payload: response.data});
       
      }
    })
    .catch(error => {
      console.log("addForm error", `${requestTarget}_FAILURE`, error)
      if (error.response && error.response.data) {
        console.log("addForm error1", `${requestTarget}_FAILURE`,error, error.response.data)
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      } else {
        console.log("addForm error2", `${requestTarget}_FAILURE`, error)
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}
export function shareAnOpportunity(url, requestTarget, data,callback){
  return (dispatch, getState) => {
    // blankAxiosInstance
    let instance = getState().user.axiosInstance;

    instance.post(url, data)
    .then((response) => {
      console.log("share opportunity", requestTarget, response)
      if(response.status !== 201 && response.status !== 200){
        dispatch({ type: `${requestTarget}_FAILURE`, payload: response.data });

      } else {
        dispatch({ type: `${requestTarget}_SUCCESS`, payload: response.data});
       
      }
    })
    .catch(error => {
      console.log("addForm error", requestTarget, error)
      if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      } else {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}
export function createFormEntryAnonymous(requestTarget, data,requestTarget2,form_id,client_id, submit_form_data){
  return(dispatch, getState)=>{
    let instance=getState().user.blankAxiosInstance;
    
    instance.post('forms/'+form_id+'/entries/add/?client='+client_id, data)
    .then((response)=>{
      if (response.status!==201 && response.status !==200) {
        console.log("Create Form entry", requestTarget, response);
        dispatch({type :`${requestTarget}_FAILURE`, payload: response.data});
        window.scrollTo(0, 0);
        
      }
      else{
        dispatch({type :`${requestTarget}_SUCCESS`, payload: response.data});
        console.log(response.data);

        /**/
        instance.post('forms/'+form_id+'/fields/entries/'+response.data.id+'/add/?client='+client_id,submit_form_data)
        .then((response)=>{
          if (response.status!==201 && response.status !==200) {
            console.log("Add entry field", requestTarget2, response);
            dispatch({type :`${requestTarget2}_FAILURE`, payload: response.data});
            window.scrollTo(0, 0);
          }
          else{
            console.log("Create Form success", requestTarget2, response.data);
            dispatch({type :`${requestTarget2}_SUCCESS`, payload: response.data});
             window.scrollTo(0, 0);
          }
        }).catch(error=>{
          console.log("Create Form", requestTarget2, error, submit_form_data);
        if (error.response && error.response.data) {
            dispatch({ type: `${requestTarget2}_FAILURE`, payload: error.response.data });
                      window.scrollTo(0, 0)
          }else{
            dispatch({ type: `${requestTarget2}_FAILURE`, payload: error });
                      window.scrollTo(0, 0);
          }
        })
        /**/
      }
    }).catch(error=>{
      console.log("Create Form entry", requestTarget, error)
    if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      }else{
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
  }
}
export function UpdateFormEntry(requestTarget,entries_id, client_id,form_id,data){
  return(dispatch, getState)=>{
    let instance=getState().user.axiosInstance;
    instance.post('forms/'+form_id+'/fields/entries/'+entries_id+'/add/?client='+client_id,data)
    .then((response)=>{
      if (response.status!==201 && response.status !==200) {
        console.log("Update entry field", requestTarget, response);
        dispatch({type :`${requestTarget}_FAILURE`, payload: response.data});
      }
      else{
        console.log("Update Form success", requestTarget, response.data);
        dispatch({type :`${requestTarget}_SUCCESS`, payload: response.data});
      }
    }).catch(error=>{
      console.log("Update Form", requestTarget, error, data);
    if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      }else{
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
  }
}
export function createFormEntry(requestTarget, data,requestTarget2,form_id,client_id, submit_form_data){
  return(dispatch, getState)=>{
    let instance=getState().user.axiosInstance;
    
    instance.post('forms/'+form_id+'/entries/add/?client='+client_id, data)
    .then((response)=>{
      if (response.status!==201 && response.status !==200) {
        console.log("Create Form entry", requestTarget, response);
        dispatch({type :`${requestTarget}_FAILURE`, payload: response.data});
        window.scrollTo(0, 0);
        
      }
      else{
        dispatch({type :`${requestTarget}_SUCCESS`, payload: response.data});
        console.log(response.data);

        /**/
        instance.post('forms/'+form_id+'/fields/entries/'+response.data.id+'/add/?client='+client_id,submit_form_data)
        .then((response)=>{
          if (response.status!==201 && response.status !==200) {
            console.log("Add entry field", requestTarget2, response);
            dispatch({type :`${requestTarget2}_FAILURE`, payload: response.data});
            window.scrollTo(0, 0);
          }
          else{
            console.log("Create Form success", requestTarget2, response.data);
            dispatch({type :`${requestTarget2}_SUCCESS`, payload: response.data});
            dispatch({type :`${requestTarget2}`, payload: response.data});
            window.scrollTo(0, 0);
          }
        }).catch(error=>{
          console.log("Create Form", requestTarget2, error, submit_form_data);
        if (error.response && error.response.data) {
            dispatch({ type: `${requestTarget2}_FAILURE`, payload: error.response.data });
                      window.scrollTo(0, 0)
          }else{
            dispatch({ type: `${requestTarget2}_FAILURE`, payload: error });
                      window.scrollTo(0, 0);
          }
        })
        /**/
      }
    }).catch(error=>{
      console.log("Create Form entry", requestTarget, error)
    if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      }else{
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
  }
}
export function createFormEntryEval(requestTarget, data,requestTarget2,form_id,client_id, submit_form_data,applicationId){
  return(dispatch, getState)=>{
    let instance=getState().user.axiosInstance;
    
    instance.post('forms/'+form_id+'/entries/add/?client='+client_id, data)
    .then((response)=>{
      if (response.status!==201 && response.status !==200) {
        console.log("Create Form entry", requestTarget, response);
        dispatch({type :`${requestTarget}_FAILURE`, payload: response.data});
        window.scrollTo(0, 0);
        
      }
      else{
        dispatch({type :`${requestTarget}_SUCCESS`, payload: response.data});
        console.log(response.data);

        /**/
        instance.post('forms/'+form_id+'/fields/entries/'+response.data.id+'/add/?client='+client_id,submit_form_data)
        .then((response1)=>{
          if (response1.status!==201 && response1.status !==200) {
            console.log("Add entry field", requestTarget2, response1);
            dispatch({type :`${requestTarget2}_FAILURE`, payload: response1.data});
            window.scrollTo(0, 0);
          }
          else{
            console.log("Create Form success", requestTarget2, response1.data);
            dispatch({type :`${requestTarget2}_EVAL_SUCCESS`, payload: response1.data});
            instance.post(`/opportunities/applications/${applicationId}/entries/${response.data.id}/evaluations/add/`,{})
            .then((response2)=>{
              dispatch({type :`${requestTarget2}_SUCCESS`, payload: response2.data});
              // getNewGrade();
            }).catch((error)=>{
              console.log(error.response);
            });
            // applications/(?P<application_id>[a-z0-9\-]+)/entries/(?P<entry_id>[a-z0-9\-]+)/evaluations/add/
            // window.location.href='/profil/soumettre-votre-projet/'
            window.scrollTo(0, 0);
          }
        }).catch(error=>{
          console.log("Create Form", requestTarget2, error, submit_form_data);
        if (error.response && error.response.data) {
            dispatch({ type: `${requestTarget2}_FAILURE`, payload: error.response.data });
                      window.scrollTo(0, 0)
          }else{
            dispatch({ type: `${requestTarget2}_FAILURE`, payload: error });
                      window.scrollTo(0, 0);
          }
        })
        /**/
      }
    }).catch(error=>{
      console.log("Create Form entry", requestTarget, error)
    if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      }else{
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
  }
}
export function shareAnOpportunityAnonymously(url, requestTarget, data,callback){
  return (dispatch, getState) => {
    // blankAxiosInstance
    let instance = getState().user.blankAxiosInstance;

    instance.post(url, data)
    .then((response) => {
      console.log("share opportunity", requestTarget, response)
      if(response.status !== 201 && response.status !== 200){
        dispatch({ type: `${requestTarget}_FAILURE`, payload: response.data });

      } else {
        dispatch({ type: `${requestTarget}_SUCCESS`, payload: response.data});
       
      }
    })
    .catch(error => {
      console.log("addForm error", requestTarget, error)
      if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      } else {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}

export function Empty(requestTarget){
  return (dispatch,getState)=>{
    dispatch({ type: requestTarget});
  } 
}
export function addFormAnonymousnoparams(url, requestTarget, data, callback){

  return (dispatch, getState) => {

    let instance = getState().user.axiosInstanceNoParams;

    dispatch({ type: `${requestTarget}_REQUEST` });

    instance.post(url, data)
    .then((response) => {
      console.log("addForm response", requestTarget, response)
      dispatch({ type: `${requestTarget}_SUCCESS`,payload: response.data });
    })
    .catch(error => {
      console.log("addForm error", requestTarget, error)
      if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      } else {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}

export function addFormAnonymous(url, requestTarget, data, callback){

  return (dispatch, getState) => {

    let instance = getState().user.blankAxiosInstance;

    dispatch({ type: `${requestTarget}_REQUEST` });

    instance.post(url, data)
    .then((response) => {
      console.log("addForm response", requestTarget, response)
      dispatch({ type: `${requestTarget}_SUCCESS`,payload: response.data });
    })
    .catch(error => {
      console.log("addForm error", requestTarget, error)
      if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      } else {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}


export function updateForm(url, requestTarget, data, callback){

  return (dispatch, getState) => {

    let instance = getState().user.axiosInstance;

    dispatch({ type: `${requestTarget}_REQUEST` });

    instance.put(url, data)
    .then((response) => {
      console.log("updateForm response", requestTarget, response)
      if(response.status !== 200){
        dispatch( { type: `${requestTarget}_FAILURE`, payload: response.data });

      } else {
        dispatch({ type: `${requestTarget}_SUCCESS` });
      }
    })
    .catch(error => {
      console.log("updateForm error", requestTarget, error)
      if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      } else {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}

export function patchForm(url, requestTarget, data, callback){

  return (dispatch, getState) => {

    let instance = getState().user.axiosInstance;

    dispatch({ type: `${requestTarget}_REQUEST` });

    instance.patch(url, data)
    .then((response) => {
      console.log("patchForm response", requestTarget, response)
      if(response.status !== 200){
        dispatch( { type: `${requestTarget}_FAILURE`, payload: response.data });

      } else {
        dispatch({ type: `${requestTarget}_SUCCESS` });
      }
    })
    .catch(error => {
      console.log("patchForm error", requestTarget, error)
      if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      } else {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}

export function removeForm(url, requestTarget, callback){

  return (dispatch, getState) => {

    let instance = getState().user.axiosInstance;

    dispatch({ type: `${requestTarget}_REQUEST` });

    instance.put(url)
    .then((response) => {
      console.log("removeForm response", requestTarget, response)
      if(response.status !== 200){
        dispatch( { type: `${requestTarget}_FAILURE`, payload: response.data });

      } else {
        dispatch({ type: `${requestTarget}_SUCCESS` });
      }
    })
    .catch(error => {
      console.log("removeForm error", requestTarget, error)
      if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      } else {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}



export function addCompany(url, requestTarget, data, callback){

  return (dispatch, getState) => {

    let instance = getState().user.axiosInstance;
    dispatch({ type: `${requestTarget}_REQUEST` });
    let history = useHistory();
    instance.post(url, data)
    .then((response) => {
      console.log("addCompany response", requestTarget, response)
      if(response.status !== 201 && response.status !== 200){
        dispatch({ type: `${requestTarget}_FAILURE`, payload: response.data });

      } else {
        dispatch({ type: `${requestTarget}_SUCCESS` });
        history.push('networking/company/'+response.data.slug+'/edit');
      }
    })
    .catch(error => {
      console.log("addForm error", requestTarget, error)
      if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      } else {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}

export function addGroup(url, requestTarget, data, callback){

  return (dispatch, getState) => {

    let instance = getState().user.axiosInstance;
    let history = useHistory();
    dispatch({ type: `${requestTarget}_REQUEST` });

    instance.post(url, data)
    .then((response) => {
      console.log("addGroup response", requestTarget, response)
      if(response.status !== 201 && response.status !== 200){
        dispatch({ type: `${requestTarget}_FAILURE`, payload: response.data });

      } else {
        history.push('networking/group/'+response.data.id+'/edit');
        dispatch({ type: `${requestTarget}_SUCCESS` });
      }
    })
    .catch(error => {
      console.log("addGroupForm error", requestTarget, error)
      if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      } else {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}

export function CreateAnAlert(url, requestTarget, data, callback){

  return (dispatch, getState) => {

    let instance = getState().user.axiosInstance;
    instance.post(url, data)
    .then((response) => {
      console.log("CreateAnAlert response", requestTarget, response)
      if(response.status !== 201 && response.status !== 200){
        dispatch({ type: `${requestTarget}_FAILURE`, payload: response.data});

      } else {
        dispatch({ type: `${requestTarget}_SUCCESS`, payload:response.data});
      }
    })
    .catch(error => {
      console.log("CreateAnAlert error", requestTarget, error)
      if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      } else {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}

export function CreateAnAlertAnonymously(url, requestTarget, data, callback){

  return (dispatch, getState) => {

    let instance = getState().user.blankAxiosInstance;
    instance.post(url, data)
    .then((response) => {
      console.log("CreateAnAlertAnonymously response", requestTarget, response)
      if(response.status !== 201 && response.status !== 200){
        dispatch({ type: `${requestTarget}_FAILURE`, payload: response.data });

      } else {
        dispatch({ type: `${requestTarget}_SUCCESS` });
      }
    })
    .catch(error => {
      console.log("CreateAnAlertAnonymously error", requestTarget, error)
      if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      } else {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}

export function addOpportunity(url, requestTarget, data, callback){

  return (dispatch, getState) => {

    let instance = getState().user.axiosInstance;
    let history = useHistory();
    dispatch({ type: `${requestTarget}_REQUEST` });

    instance.post(url, data)
    .then((response) => {
      console.log("addOpportunity response", requestTarget, response)
      if(response.status !== 201 && response.status !== 200){
        dispatch({ type: `${requestTarget}_FAILURE`, payload: response.data });

      } else {
        dispatch({ type: `${requestTarget}_SUCCESS`, payload: response.data });
        
          response.data.data.opportunity_type ==="1" ?
          history.push('/networking/application/business/opportunities/fundings/'+response.data.data.slug)
          :
          history.push('/networking/application/business/opportunities/'+response.data.data.opportunity_type+'s/'+response.data.data.slug)
    
        
        }
    })
    .catch(error => {
      console.log("addOpportunity error", requestTarget, error)
      if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      } else {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}
export function updateAlert(url, requestTarget, data, callback){
  return (dispatch, getState)=>{
    let instance = getState().user.axiosInstance;
    instance.put(url, data)
    .then((response) => {
      console.log("updateAlert response", requestTarget, response)
      if(response.status !== 200){
        dispatch( { type: `${requestTarget}_FAILURE`, payload: response.data });

      } else {
        dispatch({ type: `${requestTarget}_SUCCESS` , payload: response.data});     
      }
    })
    .catch(error => {
      console.log("updateAlert error", requestTarget, error)
      if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      } else {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });

  }
}

export function updateFormOpportunity(url, requestTarget, data, callback){

  return (dispatch, getState) => {

    let instance = getState().user.axiosInstance;
    let history = useHistory();
    dispatch({ type: `${requestTarget}_REQUEST` });

    instance.put(url, data)
    .then((response) => {
      console.log("updateFormOpportunity response", requestTarget, response)
      if(response.status !== 200){
        dispatch( { type: `${requestTarget}_FAILURE`, payload: response.data });

      } else {
        dispatch({ type: `${requestTarget}_SUCCESS` , payload: response.data});
        history.push('/networking/application/business/opportunities/'+response.data.data.opportunity_type+'s/'+response.data.data.slug);
     
      }
    })
    .catch(error => {
      console.log("updateFormOpportunity error", requestTarget, error)
      if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      } else {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
    .then(() => {
      if(callback) {
        callback();
      }
    });
  }
}

export function updateGroup(url, requestTarget, data){
  return(dispatch, getState)=>{
    let instance=getState().user.axiosInstance;
    instance.put(url, data)
    .then((response)=>{
      console.log("updateGroup response", requestTarget, response)
      if (response.status!==201 && response.status !==200) {
         dispatch({ type: `${requestTarget}_FAILURE`, payload: response.data });
      }else{
        dispatch({ type: `${requestTarget}_SUCCESS` });
        window.location.reload();
        // history.push('networking/group/'+response.data.id);
      }
    }).catch(error=>{
       console.log("updateGroup response", requestTarget, error.response);
       console.log("updateGroup response", requestTarget, error.response.data);
       if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      }else{
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    });
  }

}
export function updateCompany(url, requestTarget, data){
  return(dispatch, getState)=>{
    let instance=getState().user.axiosInstance;
    instance.put(url, data)
    .then((response)=>{
      console.log("updateCompany response", requestTarget, response)
      if (response.status!==201 && response.status !==200) {
         dispatch({ type: `${requestTarget}_FAILURE`, payload: response.data });
      }else{
        dispatch({ type: `${requestTarget}_SUCCESS` });
        window.location.reload();
      }
    }).catch(error=>{
      //  console.log("updateCompany response", requestTarget, error.response);
      //  console.log("updateCompany response", requestTarget, error.response.data);
       if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      }else{
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    });
  }
}

export  function addCompanylocation(url, requestTarget, data){
    return(dispatch, getState)=>{
    let instance=getState().user.axiosInstance;
    instance.put(url, data)
    .then((response)=>{
      if (response.status!==201 && response.status !==200) {
        console.log("Addlocation response", requestTarget, response)
         dispatch({ type: `${requestTarget}_FAILURE`, payload: response.data });
      }else{
        dispatch({ type: `${requestTarget}_SUCCESS` });
      }
    }).catch(error=>{
       console.log("Addlocation response", requestTarget, error);
       if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      }else{
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    });
  }

}

export function createFormEntrie(requestTarget, data,requestTarget2,form_id,client_id, submit_form_data){
  return(dispatch, getState)=>{
    let instance=getState().user.axiosInstance;
    instance.post('forms/'+form_id+'/entries/add/?client='+client_id, data)
    .then((response)=>{
      if (response.status!==201 && response.status !==200) {
        console.log("Create Form entrie", requestTarget, response);
        dispatch({type :`${requestTarget}_FAILURE`, payload: response.data});
      }
      else{
        dispatch({type :`${requestTarget}_SUCCESS`, payload: response.data});
        console.log(response.data);
        instance.post('forms/'+form_id+'/fields/entries/'+response.data.id+'/add/?client='+client_id,submit_form_data)
        .then((response)=>{
          if (response.status!==201 && response.status !==200) {
            console.log("Add entrie field", requestTarget2, response);
            dispatch({type :`${requestTarget2}_FAILURE`, payload: response.data});
          }
          else{
            console.log("Create Form success", requestTarget2, response.data);
            dispatch({type :`${requestTarget2}_SUCCESS`, payload: response.data});
          }
        }).catch(error=>{
          console.log("Create Form", requestTarget2, error, submit_form_data);
        if (error.response && error.response.data) {
            dispatch({ type: `${requestTarget2}_FAILURE`, payload: error.response.data })
          }else{
            dispatch({ type: `${requestTarget2}_FAILURE`, payload: error })
          }
        })
        /**/
      }
    }).catch(error=>{
      console.log("Create Form entrie", requestTarget, error)
    if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      }else{
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
  }
}
export function createFormEntrieLoginNotRequired(requestTarget, data,requestTarget2,form_id,client_id, submit_form_data){
  return(dispatch, getState)=>{
    let instance=getState().user.blankAxiosInstance;
    instance.post('forms/'+form_id+'/entries/add/?client='+client_id, data)
    .then((response)=>{
      if (response.status!==201 && response.status !==200) {
        console.log("Create Form entrie", requestTarget, response);
        dispatch({type :`${requestTarget}_FAILURE`, payload: response.data});
      }
      else{
        dispatch({type :`${requestTarget}_SUCCESS`, payload: response.data});
        console.log(response.data);
        /**/
        instance.post('forms/'+form_id+'/fields/entries/'+response.data.id+'/add/?client='+client_id,submit_form_data)
        .then((response)=>{
          if (response.status!==201 && response.status !==200) {
            console.log("Add entrie field", requestTarget2, response);
            dispatch({type :`${requestTarget2}_FAILURE`, payload: response.data});
          }
          else{
            console.log("Create Form success", requestTarget2, response.data);
            dispatch({type :`${requestTarget2}_SUCCESS`, payload: response.data});
          }
        }).catch(error=>{
          console.log("Create Form", requestTarget2, error, submit_form_data);
        if (error.response && error.response.data) {
            dispatch({ type: `${requestTarget2}_FAILURE`, payload: error.response.data })
          }else{
            dispatch({ type: `${requestTarget2}_FAILURE`, payload: error })
          }
        })
        /**/
      }
    }).catch(error=>{
      console.log("Create Form entrie", requestTarget, error)
    if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      }else{
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
  }
}
export function createForm(requestTarget, form_data, fields_data, client_id){
  return(dispatch, getState)=>{
    let instance=getState().user.axiosInstance;
    instance.post('/forms/form/add/?client='+client_id, form_data)
    .then((response)=>{
      if (response.status!==201 && response.status !==200) {
        console.log("Create Form", requestTarget, response);
        dispatch({type :`${requestTarget}_FAILURE`, payload: response.data});
      }
      else{
        dispatch({type :`${requestTarget}_SUCCESS`, payload: response.data});
        // fields_data.map((field)=>{
          /**/
          instance.post("/forms/"+response.data.id+"/fields/add/?client="+client_id,{fields:fields_data})
          .then((response)=>{
            if (response.status!==201 && response.status !==200) {
              console.log("Create field", requestTarget, response);
              dispatch({type :`${requestTarget}_FAILURE`, payload: response.data});
            }
            else{
              console.log("Create field success", requestTarget, response.data);
              dispatch({type :`${requestTarget}`, payload: response.data});
            }
          }).catch(error=>{
            console.log("Create fiel", requestTarget, error, fields_data);
          if (error.response && error.response.data) {
              dispatch({ type: `${requestTarget}`, payload: error.response.data })
            }else{
              dispatch({ type: `${requestTarget}`, payload: error })
            }
          })
          /**/
        // });

      }
    }).catch(error=>{
      console.log("Create Form", requestTarget, error)
    if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      }else{
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
  }
}
export function changeSpinnerStatus(requestTarget){
  return(dispatch, getState)=>{
  dispatch({ type: requestTarget, payload: true });
}
}

export function UpdateFormEntrie(requestTarget,entries_id, client_id,form_id,data){
  return(dispatch, getState)=>{
    let instance=getState().user.axiosInstance;
    instance.post('forms/'+form_id+'/fields/entries/'+entries_id+'/add/?client='+client_id,data)
    .then((response)=>{
      if (response.status!==201 && response.status !==200) {
        console.log("Add entrie field", requestTarget, response);
        dispatch({type :`${requestTarget}_FAILURE`, payload: response.data});
      }
      else{
        console.log("Create Form success", requestTarget, response.data);
        dispatch({type :`${requestTarget}_SUCCESS`, payload: response.data});
      }
    }).catch(error=>{
      console.log("Create Form", requestTarget, error, data);
    if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      }else{
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
  }
}

export function Payment(url,requestTarget,data){
  return(dispatch, getState)=>{
    let instance=getState().user.axiosInstance;
    instance.post(url,data)
    .then((response)=>{
      if (response.status!==201 && response.status !==200) {
        console.log("Payment", requestTarget, response);
        dispatch({type :`${requestTarget}_FAILURE`, error: response.data});
      }
      else{
        console.log("Payment success", requestTarget, response.data);
        dispatch({ type: requestTarget, shopping_carts:response.data.shopping_carts, message:response.data.message });
        // dispatch({type :requestTarget, shoppings_carts: response.data});
      }
    }).catch(error=>{
      console.log("Payment", requestTarget, error, data);
    if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      }else{
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
  }
}

export function sendInvitation(url, requestTarget, data){
  return(dispatch, getState)=>{
    let instance=getState().user.axiosInstance;
    instance.post(url,data)
    .then((response)=>{
      dispatch({type :`${requestTarget}_SUCCESS`, payload: response.data.message});
    })
    .catch((error)=>{
      if (error.response && error.response.data) {
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error.response.data })
      }else{
        dispatch({ type: `${requestTarget}_FAILURE`, payload: error })
      }
    })
  }
}