import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Link} from "react-router-dom";
import {Animated} from "react-animated-css";
import WorkIcon from '@material-ui/icons/Work';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Menu from '@material-ui/core/Menu';
import IntlMessages from "util/IntlMessages";
import EditOpportunities from 'pages/EditOpportunities';
const useStyles = makeStyles((theme) => ({
  ul:{
    'list-style-type': 'none',
    paddingLeft: 0,

  },
  btn:{
    position:'absolute',
    left:'42%',
    backgroundColor:"#fdc000",
    '&:hover':{
      backgroundColor:"#fdc000",
    }
  },
 cardActions:{
      paddingLeft:15,
      paddingRight:15
     
  },
  avatar:{
    width: 50,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius:'0%',
    marginLeft:'-15px',
    marginBottom:'15px'
  }, 
  flexEnd:{
      paddingLeft:'28%',
  },
  icon:{
      width:15,
      height:15
  },
  icons:{
    width:15,
    height:15,
    border:"solid 1px #c1c1c1"
  },
  link:{
    textDecoration:'none',
    color:'#4285f4',
    '&:hover':{
      textDecoration:'none'
    }
}

}));
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#f1f1f1",
  },
}));
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}
export default function OpportunityByCompany(props) {
  const classes = useStyles();
  const data =props.data
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
 
    <Animated animationIn="fadeInUp" animationOut="fadeOut">
       <Card className={classes.card}>
            <CardActions className={classes.cardActions}>
            <Grid container>
            {/* <Grid item xs={2} md={2}>
            {data.organization_id?
                      <span className="company-imgOpp">
                        <img
                          src={data.organization_id.logo ? data.organization_id.logo : "https://cdn.wutiko.com/v3/static/images/default/high/wutiko.png" }
                          alt={<IntlMessages id="cover_photo" />} 
                        />
                      </span>:null}
            </Grid>  */}
                <Grid item xs={9} md={9}>
                     <Link to={`/opportunity/${data.slug}`} className="title-opportunity">{data.title}</Link>
                     <div><span className="tag-opp">
                     {data.categories?
                     data.categories.length === 0 ?null :
                          <>
                          {data.categories.length !== 1 ?
                                <div>
                                <Button aria-controls="" aria-haspopup="true" onClick={handleClick}>
                                {data.categories[0].name + " , +" }
                                </Button>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={handleClose}
                                >
                                            {
                                              data.categories.map((item)=>{
                                                return <a href={`/opportunities?FacetCategory=${item.name}`} className="opportunity-tag">{item.name + " "}</a>
                                              })
                                            }
                                </Menu>  </div>
                                :

                                <a href={`/opportunities?FacetCategory=${data.categories[0].name}`} className="opportunity-tag">{data.categories[0].name}</a>
                                          } </>   
                          :null} 
                          
                      </span></div>
                    <span>{data.country_name} {data.city_name ? " - " + data.city_name : null}</span>
                </Grid>
                
                <Grid xs={3} md={3} >

                <div className="col-md-12 text-right  padding-0">
                <span>{data.publication_date}</span><br/>
                {props.is_admin?
                <EditOpportunities 
                itemDetailsOpportunity={data}
                slug={data.slug} companiesData={data}/>:
                <Link to={`/opportunity/${data.slug}`}><Button className="col-md-12 top-20">{<IntlMessages id="see_more" />} </Button></Link> 
                  }
                </div>
                </Grid>
                </Grid>
            </CardActions>
        </Card>
        </Animated>
  );
}

OpportunityByCompany.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
