import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Link} from "react-router-dom";
import {Animated} from "react-animated-css";
import WorkIcon from '@material-ui/icons/Work';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Menu from '@material-ui/core/Menu';
import IntlMessages from "util/IntlMessages";

const useStyles = makeStyles((theme) => ({
  ul:{
    'list-style-type': 'none',
    paddingLeft: 0,

  },
  btn:{
    position:'absolute',
    left:'42%',
    backgroundColor:"#fdc000",
    '&:hover':{
      backgroundColor:"#fdc000",
    }
  },
 cardActions:{
      paddingLeft:15,
      paddingRight:15
     
  },
  avatar:{
    width: 50,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius:'0%',
    marginLeft:'-15px',
    marginBottom:'15px'
  }, 
  flexEnd:{
      paddingLeft:'28%',
  },
  icon:{
      width:15,
      height:15
  },
  icons:{
    width:15,
    height:15,
    border:"solid 1px #c1c1c1"
  },
  link:{
    textDecoration:'none',
    color:'#4285f4',
    '&:hover':{
      textDecoration:'none'
    }
}

}));
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#f1f1f1",
  },
}));
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}
export default function Opportunity(props) {
  const classes = useStyles();
  const data =props.data
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
 
    <Animated animationIn="fadeInUp" animationOut="fadeOut">
       <Card className={classes.card}>
            <CardActions className={classes.cardActions}>
            <Grid container>
   
                <Grid item xs={12}>
                     <Link to={`/opportunity/${data.slug}`} className="title-opportunity">{data.title}</Link>
                </Grid>
                <Grid xs={12} md={12} container >
                <div className="col-md-6 col-6 padding-0 block-opp">
                <span>{data.country_name} {data.country_name?"-":null} {data.city_name}</span>
                </div>
                <div className="col-md-6 col-6 text-right  padding-0 block-opp">
                <span>{data.publication_date}</span>
                </div>
                </Grid>
                <Grid xs={12} md={12}>
                  {/* {
                           data.categories.map((item) =>{
                            return    item?<Link to={`/opportunities?OpportunityType=${item}`} className="opportunity-tag">{item? item : null}</Link> :' , '}).toString() }
                     */}
                </Grid>
                <Grid item xs={12}>
                <ListItem alignItems="flex-start">
                <ListItemAvatar> 
                 <Link to={`/companies/${data.organization_slug}`}> <Avatar alt="Travis Howard" src={data.organization_logo}  className={classes.avatar}/></Link> 
                    </ListItemAvatar>
                    <ListItemText
                     className="company-name list"
                    primary={<Link to={`/companies/${data.organization_slug}`}> {data.organization_name }</Link> }
                    secondary={
                        <React.Fragment>
                        <Typography
                            component="span"
                           
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                        >
                        <Link to={`/companies/${data.organization_slug}`}>   <BusinessCenterIcon/> {data.total_opportunities}</Link>
                        </Typography>
                        
                       </React.Fragment>
                     }
                    />
                </ListItem>

                </Grid>
                <Grid item xs={12}>
                   {/* {
                    data.industries.map((item)=>{
                      return <div className="tag"><Link to={`/opportunities?FacetIndustry=${item}`} className={classes.link}>{item}</Link></div>
                    })
                  }  */}
                   
                </Grid>
                
                
                <Grid xs={12} md={12} container >
                <div className="col-md-6 padding-0 menu-opp col-6">
                {/* {data.categories.length !== 1 ?
                   <ClickAwayListener onClickAway={handleTooltipClose}>
                  <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                 title={
                    data.categories.map((item)=>{
                      return <Link to={`/opportunities?OpportunityType=${item}`} className="opportunity-tag">{item + " "}</Link>
                    })
                  }>
                    <Button onClick={handleTooltipOpen}>{data.categories[0] + ", +" }</Button>
                <Link to={`/opportunities?OpportunityType=${data.categories[0]}`} className="opportunity-tag">{data.categories[0] + ", +" } 
                </Link> 
               </Tooltip></ClickAwayListener>
               :
               <Link to={`/opportunities?OpportunityType=${data.categories[0]}`} className="opportunity-tag">{data.categories[0]}</Link>
                }  */}

              {data.categories.length === 0 ?null :
              <>
              {data.categories.length !== 1 ?
                    <div>
                    <Button aria-controls="" aria-haspopup="true" onClick={handleClick}>
                    {data.categories[0] + " , +" }
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                                {
                                  data.categories.map((item)=>{
                                    return <a href={`/opportunities?FacetCategory=${item}`} className="opportunity-tag">{item + " "}</a>
                                  })
                                }
                    </Menu>  </div>
                    :

                    <a href={`/opportunities?FacetCategory=${data.categories[0]}`} className="opportunity-tag">{data.categories[0]}</a>
                               } </>   
              } 
  

 {/*
                 <BootstrapTooltip title={
                    data.categories.map((item)=>{
                      return <Link to={`/opportunities?OpportunityType=${item}`} className="opportunity-tag">{item + " "}</Link>
                    })
                  }>
                <Link to={`/opportunities?OpportunityType=${data.categories[0]}`} className="opportunity-tag">{data.categories[0]}
                {data.categories.length !== 1 ?
                ", +" : null
                  }
                </Link>
               </BootstrapTooltip> 
               {
                    data.categories.map((item)=>{
                      return <Link to={`/opportunities?OpportunityType=${item}`} className="opportunity-tag">{item + " "}</Link>
                    })
                  }   */}
                </div>
                <div className="col-md-6 text-right  padding-0 col-6">
                <Link to={`/opportunity/${data.slug}`}><Button className="btn-opp">{<IntlMessages id="see_more"/>}</Button></Link>
                </div>
                </Grid>
                 {/* <Grid className="date-publication" item xs={12} container justify="flex-start"><AccessTimeIcon  className={classes.icon}/>{data.publication_date}</Grid>
               <Grid item xs={4} container justify="center">{data.opportunity_views}<VisibilityIcon  className={classes.icon}/></Grid>
                <Grid item xs={4} container justify="flex-end">{data.opportunity_views}<BorderColorIcon className={classes.icons}/></Grid> */}
            </Grid>
            </CardActions>
        </Card>
        </Animated>
  );
}

Opportunity.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
