import {all} from 'redux-saga/effects';
import authSagas from './Auth';
import opportunitiesSaga from './opportunities/Opportunities';
import EditOpportunitySaga from './opportunities/EditOpportunities';
import lastUpdatesSage from './LastUpdates';
import testimoniesSaga from './Testimonies';
import newsLetterSaga  from './NewsLetter';
import headerSaga from './Header';
import searchSaga from './Search';
import skillsSaga from './filters/Skills';
import locationsSaga from './filters/Locations';
import industriesSaga   from './filters/Industries';
import contractTypeSaga from './filters/ContractType';
import educationsSaga from './filters/Education';
import positionsSaga from './filters/Positions';
import opportunitySaga from './filters/Opportunity';
import companySaga from './filters/Company';
import experienceSaga from './filters/Experience';

import AssessedSaga from './filters/Assessed';
import DateSaga from './filters/Date';
import MatchingScoreSaga from './filters/MatchingScore';

import opportunityTypeSaga from './filters/OpportunityType';
import uploadCvSaga from './uploadCv';
import createCompanySaga from './companies/createCompany';
import editCompanySaga from './companies/EditCompany';
import getEditCompanySaga from './companies/GetEditCompany';
import editPhotoCompanySaga from './companies/EditPhotoCompany';
import addOpportunitySaga from './opportunities/AddOpportunity';
import statusOpportunitySaga from './opportunities/StatusOpportunity';
import getEditOpportunitySaga from './opportunities/GetEditOpportunity';
import saveOpportunitySaga from './opportunities/SaveOpportunity';
import applyOpportunitySaga from './opportunities/ApplyOpportunity';
import getOpportunityDetailsSaga from './opportunities/GetOpportunityDetails';
import formsGeneratorSaga  from './forms/FormsGenerator';
import shareOpportunitySaga  from './opportunities/ShareOpportunity';
import contactFormSaga from './forms/ContactForm';
import preViewFormSaga from './forms/PreViewForm';
import createFormSaga  from './forms/CreateForm';
import addFields2FormSaga from './forms/AddFields2From';
import resumeCheckSaga from './opportunities/ResumeCheck';
import getProfileSaga from './profile/GetProfileDetails';
import getEditProfileSaga from './profile/GetEditProdile';
import getSkillsSaga from './profile/GetSkills';
import getSectorsSaga from './profile/GetSectors';
import getProfileAreasSaga from './profile/Area/GetAreas';
import AddEducationSaga from './profile/Education/AddEducation';
import AddExperienceSaga from './profile/Experience/Addexperience';
import EducationLevelSaga from './profile/Education/EducationLevel';
import GetcompanySaga from './companies/getCompany';
import GetcompanyAdminSaga from './companies/getCompanyAdmin';
import AddSkillSaga from './profile/Skills/AddSkill';
import AddSectorSaga from './profile/Sector/AddSector';
import DeleteSectorSaga from './profile/Sector/DeleteSector';
import DeleteSkillsSaga from './profile/Skills/DeleteSkill';
import PartnersSaga from './Partners';
import GetCompanyDetailsSaga from './companies/GetCompanyDetails';
import SaveCompanySaga from './companies/SaveCompany';
import ShareCompanySaga from './companies/ShareCompany';
import ApplyComanySaga from './companies/ApplyComany';
import GetProfilesByCompanySaga from './companies/GetProfilesByCompany'
import GetOpportunitiesByCompanySaga from './companies/GetOpportunitiesByCompany'
import GetCompaniesLatestSaga from './companies/getCompanyLatest'
import KeysDasboardSaga from './Dashboard/KeysDasboard'
import GetFileSaga from './profile/Files/GetFiles'
import DeleteFileSaga from './profile/Files/DeleteFile'
import AddPublicationSaga from './profile/Publication/AddPublication'
import GetPublicationSaga from './profile/Publication/GetPublication'
import DeletePublicationSaga from './profile/Publication/DeletePublication'
import AddLangueSaga from './profile/Langue/AddLangue'
import GetLangueSaga from './profile/Langue/GetLangue'
import DeleteLangueSaga from './profile/Langue/DeleteLangue'
import AddAreaSaga from './profile/Area/AddArea'
import DeleteAreaSaga from './profile/Area/DeleteArea'
import FollowProfileSaga from './profile/FollowProfile'
import EditProfileSaga from './profile/EditProfile'
import EditEducationSaga from './profile/Education/EditEducation'
import DeleteEducationSaga from './profile/Education/DeleteEducation'
import GetEducationSaga from './profile/Education/GetEducation'
import DeleteObjectSaga from './profile/DeleteObject'
import EditExperienceSaga from './profile/Experience/EditExperience'
import DeleteExperienceSaga from './profile/Experience/DeleteExperience'
import GetExperienceSaga from './profile/Experience/GetExperience'
import AddFileSaga from './profile/Files/AddFile'
import ResumeCheckCompanySaga from './companies/ResumeCheckCompany'
//import ProfilesSaga from './profile/ProfileList';
// import putItemSaga from './genericSaga/PutItemSaga';
// import postItemSaga from './genericSaga/PostItem';
// import getItemSaga from  './genericSaga/GetItem';

export default function* rootSaga(getState) {
    yield all([
        experienceSaga(),
        formsGeneratorSaga(),
        MatchingScoreSaga(),
        DateSaga(),
        AssessedSaga(),
        statusOpportunitySaga(),
        companySaga(),
        opportunitySaga(),
        getEditProfileSaga(),
        DeleteObjectSaga(),
        GetcompanyAdminSaga(),
        editPhotoCompanySaga(),
        getEditOpportunitySaga(),
        EditOpportunitySaga(),
        editCompanySaga(),
        contactFormSaga(),
        authSagas(),
        EditProfileSaga(),
        educationsSaga(),
        positionsSaga(),
        AddLangueSaga(),
        GetEducationSaga(),
        GetExperienceSaga(),
        AddFileSaga(),
        EditExperienceSaga(),
        DeleteExperienceSaga(),
        DeleteEducationSaga(),
        EditEducationSaga(),
        FollowProfileSaga(),
        DeleteAreaSaga(),
        AddAreaSaga(),
        AddPublicationSaga(),
        DeleteLangueSaga(),
        GetLangueSaga(),
        DeletePublicationSaga(),
        GetPublicationSaga(),
        GetFileSaga(),
        DeleteFileSaga(),
        KeysDasboardSaga(),
        GetOpportunitiesByCompanySaga(),
        GetCompaniesLatestSaga(),
        GetProfilesByCompanySaga(),
        getEditCompanySaga(),
        PartnersSaga(),
        GetCompanyDetailsSaga(),
        ApplyComanySaga(),
        ShareCompanySaga(),
        SaveCompanySaga(),
        AddSkillSaga(),
        DeleteSectorSaga(),
        DeleteSkillsSaga(),
        AddSectorSaga(),
        GetcompanySaga(),
        EducationLevelSaga(),
        AddEducationSaga(),
        AddExperienceSaga(),
        getProfileAreasSaga(),
        getSectorsSaga(),
        getSkillsSaga(),
        getProfileSaga(),
        shareOpportunitySaga(),
        applyOpportunitySaga(),
        saveOpportunitySaga(),
        opportunitiesSaga(),
        lastUpdatesSage(),
        testimoniesSaga(),
        newsLetterSaga(),
        headerSaga(),
        searchSaga(),
        skillsSaga(),
        locationsSaga(),
        industriesSaga(),
        contractTypeSaga(),
        opportunityTypeSaga(),
        uploadCvSaga(),
        createCompanySaga(),
        addOpportunitySaga(),
        getOpportunityDetailsSaga(),
        saveOpportunitySaga(),
        preViewFormSaga(),
        createFormSaga(),
        addFields2FormSaga(),
        resumeCheckSaga(),
        ResumeCheckCompanySaga(),
        // putItemSaga(),
        // postItemSaga(),
        // getItemSaga()
    ]);
}
