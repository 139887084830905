import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {SortableElement, SortableHandle} from 'react-sortable-hoc';
import { Link } from "react-router-dom";

const DragHandle = SortableHandle(() =>
  <i className="zmdi zmdi-menu draggable-icon" style={{fontSize: 25}}/>);

const ItemMatching = (props) => {

  const [anchorEl, setAnchorEl] = useState();
  const [menuState, setMenuState] = useState();

  const onContactOptionSelect = event => {
    setMenuState(true);
    setAnchorEl(event.currentTarget);
  };
  const handleRequestClose = () => {
    setMenuState(false);
  };
 
  const {profile, matching_score, opportunity,organization, starred} = props;
    // const {title, photo} = opportunity;
    // const {title, photo, first_name, first_name, title, matching_score, thumb,starred} = matching_score;
    // const {title, photo, first_name, first_name, title, matching_score, thumb,starred} = profile;
    const options = [
      'Edit',
      'Delete',
    ];
    return (
      <div className="col-12 contact-item ripple row no-gutters align-items-center py-2 px-3 py-sm-4 px-sm-6">

        {/* <DragHandle/> */}
         
          <div className="col-3 text-center font-weight-bold">
      {organization?
             <Link  to={`/opportunities/${organization.slug}`}>
               <img className="rounded-circle size-40 mx-4" alt={organization.name} src={organization.logo}/>
          {organization.name}
            </Link> 
          :
            <Link  to={`/opportunities/${opportunity.slug}`}>
                {opportunity.title}
            </Link> 
      }
          </div> 
          
         <div className="col-3 text-truncate font-weight-bold">  
         <Link  to={`/profiles/${profile.slug}`}>
          <img className="rounded-circle size-40 mx-4" alt={profile.first_name} src={profile.photo}/>
            </Link>
         <Link  to={`/profiles/${profile.slug}`}>
                {profile.first_name} {profile.last_name}
            </Link>
         </div>


         <div className="col-3 title text-truncate ">
          {profile.title}
        </div>

        <div className="col-1 score text-truncate ">
          {matching_score}
        </div>

        <div className="col-1 actions">
          <IconButton onClick={() => {
          }}>
            {starred ? <i className="zmdi zmdi-star"/> : <i className="zmdi zmdi-star-outline"/>}
          </IconButton>

          <IconButton onClick={onContactOptionSelect}>
            <i className="zmdi zmdi-more-vert"/>
          </IconButton>

          <Menu id="long-menu"
                anchorEl={anchorEl}
                open={menuState}
                onClose={handleRequestClose}

                MenuListProps={{
                  style: {
                    width: 100,
                  },
                }}>
            {options.map(option =>
              <MenuItem key={option} onClick={() => {
                handleRequestClose();
              }
              }>
                {option}
              </MenuItem>,
            )}
          </Menu>
        </div>
      </div>
    )
};

export default SortableElement(ItemMatching);
