export function handleFilterMatchingScore(data){
    return {
        type:"HANDLE_GET_MATCHING_SCORE_FILTER",
        payload:data
    }
}
export function handleFilterMatchingScoreSuccess(data){
    return {
        type:"GET_MATCHING_SCORE_FILTER",
        payload:data
    }
}

export function handleFilterMatchingScoreError(data){
    return{
        type:"GET_MATCHING_SCORE_FILTER_FAILLURE",
        payload:data
    }
}
