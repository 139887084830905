import React from "react";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import WorkIcon from '@material-ui/icons/Work';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import { handleGetopportunityDetails } from "actions/opportunities";
const useStyles = makeStyles((theme) => ({
    active:{
        borderLeft:'solid 5px #fdc000',
        cursor:'pointer',
        backgroundColor:"#f1f1f1",
    },
    simple:{
      cursor:'pointer',
      '&:hover':{
        backgroundColor:"#f1f1f1",
        borderLeft:'solid 5px #fdc000',
      }
    }
  }));
function HoriOpportunity({data,isActive}) {
  const browserHistory=useHistory()
  const {title, subTitle, isFeatured} = data;
  const dispatch =useDispatch();
  const classes = useStyles()
  const goToOppDetails=(slug)=>{
    dispatch(handleGetopportunityDetails(slug));
    browserHistory.push(`/opportunity/${slug}`)
  }
  return (
    




<div>
<div className={`media jr-featured-item d-none d-md-block d-lg-block  ${isActive?classes.active:classes.simple}`} onClick={()=>goToOppDetails(data.slug)}>
      <div className="jr-featured-thumb">
        <img className="rounded-md" src={data.organization_logo} alt="..."/>
        {/* <span className="jr-tag">Featured</span> */}
      </div>
      <div className="media-body jr-featured-content">
        <div className="jr-featured-content-left">
          <h3 className="mb-1">{title}</h3>
          <p className="text-grey mb-1">{subTitle}</p>
          <div className="d-flex flex-wrap mb-2">
            <p className="mr-3 mb-1"><span className="text-grey"><WorkIcon/></span> {data.organization_name}</p>
            <a className="text-grey text-underline" href="#/"> <LocationOnIcon/> {data.location} </a>
          </div>
        </div>
        <div className="jr-featured-content-right">
          <div>
            <p className="text-grey  time jr-fs-sm"><AccessTimeIcon />{data.publication_date}</p>
          </div>
        </div>

      </div>
    </div>

<div className={`media row  d-xl-none d-md-none ${isActive?classes.active:classes.simple}`} onClick={()=>goToOppDetails(data.slug)}>
  <div className="jr-featured-thumb col-2">
    <img className="rounded-md" src={data.organization_logo} alt="..."/>
    {/* <span className="jr-tag">Featured</span> */}
  </div>
  <div className="media-body jr-featured-content col-10">
    <div className="jr-featured-content-left">
      <h3 className="mb-1">{title}</h3>
      <p className="text-grey mb-1">{subTitle}</p>
      <div className="d-flex flex-wrap mb-2">
        <p className="mr-3 mb-1"><span className="text-grey"><WorkIcon/></span> {data.organization_name}</p>
      </div>
    </div>
    <div className="jr-featured-content-right">
      <div className="row location-mobile">
      <a className="text-grey text-underline col-6" href="#/"> <LocationOnIcon/> {data.location} </a>
        <p className="text-grey  time jr-fs-sm col-6"><AccessTimeIcon />{data.publication_date}</p>
      </div>
    </div>

  </div>
</div>
</div>
  );
}

export default HoriOpportunity;
