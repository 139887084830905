// eslint-disable import / first
import {useHistory} from 'react-router-dom';  
import React,{useEffect,Fragment, useState} from 'react';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { LocationSearchInput } from 'components/formsElements/FieldsComponents';
import DateFnsUtils from '@date-io/date-fns';
import { Col, Form,  Row, Button} from 'react-bootstrap';
import {renderDateTime, RichTextMarkdown, CheckboxGroup, renderSelectField,renderSubmitGroup, renderRadioGroup,renderTextField,
    renderPlaceAutocomplete, renderAsyncAutocomplete, renderFieldWithButton,renderRadioGroupTest, renderCheckbox} from '../components/formsElements/FormComponents';
import {  EditProfileResetMessages, handleDeleteObject, handleEditProfile } from '../actions/profile';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from 'react-redux';
import Dialog2 from '@material-ui/core/Dialog';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { handleGetProfileDetails } from '../actions/profile';
import TextField from '@material-ui/core/TextField';
import IntlMessages from "util/IntlMessages";
import {Alert} from '@material-ui/lab';
import NativeSelect from '@material-ui/core/NativeSelect';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from "@date-io/moment";
import { EditCompanyResetMessages, handleEditCompany, handleGetEditCompanyDetails } from 'actions/companies/editCompany';
import CircularProgress from '@material-ui/core/CircularProgress';
import { addOpportunityDraftResetMessages, EditOpportunityResetMessages, handleAddOpportunityDraft, handleEditOpportunity, handleEditSaveOpportunity, handleGetEditOpportunityDetails, handleGetopportunityDetails } from 'actions/opportunities';
import { deleteObjectResetMessages} from 'actions/profile';
import RichTextEditor from 'react-rte';
import { handleSearchSkills, handleSearchCompany, handleSearchEducation, handleSearchIndustries, handleSearchLangue, handleSearchPosition, handleSearchArea, handleSearchOccupations, handleSearchContractType, handleSearchCategories, handleSearchBenefits, handleSearchCompanyAdmin } from 'actions/search';
import { handleGetOpportunitiesByCompany } from 'actions/companies/getCompanies';
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { handleGetOpportunitiesDashboard } from 'actions/dashboard/keysList';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


//const browserHistory = useHistory();
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


const EditOpportunities =  (props) =>{
  const { handleSubmit,  submitError,serverError, reset } = props


 const {
   slug,
   t} = props;

 const workHour=[
    {name: t("wutiko.full_time"), value:1},
    {name: t("wutiko.part_time"), value:2},
    {name:t("wutiko.fixed_hours"), value:3},
    {name:t("wutiko.flexible"), value:4},
  ]
  const NumberYearsOfExperience=[
    {name:t("wutiko.not_necessary"), value:0},
    {name:'1', value:1},
    {name:'2', value:2},
    {name:'3', value:3},
    {name:'4', value:4},
    {name:'5', value:5},
    {name:'6', value:6},
    {name:'7', value:7},
    {name:'8', value:8},
    {name:'9', value:9},
    {name:'10', value:10},
    {name:'11', value:11},
  ]
  const companySize=[
    {name:'< 250', value:1},
    {name:'< 50', value:2},
    {name:'< 10', value:3},
  ]



  
const DetailsOpportunity = useSelector(state=>state.opportunities.get_edit_opportunity.details)
const SpinnerDetailsOpportunity = useSelector(state=>state.opportunities.get_edit_opportunity.spinner)
 
const skillsList = DetailsOpportunity.skills?DetailsOpportunity.skills:[]
const initState = { 
 title:  DetailsOpportunity?DetailsOpportunity.title:"",
   about: DetailsOpportunity?DetailsOpportunity.about:"",
      description: DetailsOpportunity?DetailsOpportunity.description:"",
     responsibilities: DetailsOpportunity?DetailsOpportunity.responsibilities:"",
     qualifications: DetailsOpportunity?DetailsOpportunity.qualifications:"",
 }

  const classes = useStyles();
  const [state, setState] = useState({initState});
  const Sectors = useSelector(state=>state.search.industries.list)
  const SectorsData = Sectors ? Sectors : null
  const skills = useSelector(state=>state.search.skills.list)
  const skillsData = skills ? skills : []
  const [openDialog, setOpenDialog] = React.useState(false);
  const spinnerDraft = useSelector(state=>state.opportunities.edit_save_opportunity.spinner)
  const spinner = useSelector(state=>state.opportunities.edit_opportunity.spinner)
  const error = useSelector(state=>state.opportunities.edit_opportunity.error)
  const message = useSelector(state=>state.opportunities.edit_opportunity.message)
  const errorDraft = useSelector(state=>state.opportunities.edit_save_opportunity.error)
  const messageDraft = useSelector(state=>state.opportunities.add_opp_ano_draft.message)
  const DeleteMessage = useSelector(state=>state.profile.delete_object.message)
  const DeleteError = useSelector(state=>state.profile.delete_object.error)
  const positionsData = useSelector(state=>state.search.positions.list)
  const companiesData = useSelector(state=>state.search.companiesAdmin.list);
  const educationData = useSelector(state=>state.search.educations.list)
  const occupationsData = useSelector(state=>state.search.occupations.list);
  const categoriesData = useSelector(state=>state.search.categories.list);
  const langue = useSelector(state=>state.search.langue.list)
  const [locations, setLocations]= useState([]);
  const positions = useSelector(state=>state.search.positions.list)
  const [inputValueSectors, setInputValueSectors] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const Areas = useSelector(state=>state.search.area.list)
  const AreasData = Areas ? Areas : null
  const contractTypeData = useSelector(state=>state.search.contractType.list);
  const [inputValueContractType, setInputValueContractType] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState(props.itemDetailsOpportunity?props.itemDetailsOpportunity.end_date:null)
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(options[0]);
  const [organization_size, setOrganization_size] = React.useState(DetailsOpportunity?DetailsOpportunity.organization_size:null);
  const [level_of_experience, setLevel_of_experience] = React.useState(DetailsOpportunity?DetailsOpportunity.level_of_experience:"");
  const [number_years_of_experience, setNumber_years_of_experience] = React.useState(DetailsOpportunity?DetailsOpportunity.number_years_of_experience:"");
  const [work_hours, setWork_hours] = React.useState(DetailsOpportunity?DetailsOpportunity.work_hours:"");
  const [description, setDescription] = React.useState(props.itemDetailsOpportunity?props.itemDetailsOpportunity.description:"");
  const [publication_status, setPublication_status] = React.useState(DetailsOpportunity.publication_status);
  const [about, setAbout] = React.useState(props.itemDetailsOpportunity?props.itemDetailsOpportunity.about:"");
  const [responsibilities, setResponsabilities] = React.useState(props.itemDetailsOpportunity.responsibilities);
  const [qualifications, setQualifications] = React.useState(props.itemDetailsOpportunity ?props.itemDetailsOpportunity.qualifications:"");
  const [inputValueSkills, setInputValueSkills] = React.useState();
  const [inputValueAreas, setInputValueAreas] = React.useState('');
  const [tagsContractType, setTagsContractType] = useState("");

 const handleSearchChangeAreas=(event)=>{
  dispatch(handleSearchArea({q:event.target.value}))
}

 const [inputValueLangue, setInputValueLangue] = React.useState('');

 const handleSearchChangeCompany=(event)=>{
  dispatch(handleSearchCompany({q:event.target.value}))
}

const handleSearchChangeBenefits=(event)=>{
  dispatch(handleSearchBenefits({q:event.target.value}))
}
const handleSearchChangeCategories=(event)=>{
  dispatch(handleSearchCategories({q:event.target.value}))
}

const handleSearchChangeContractType=(event)=>{
  dispatch(handleSearchContractType({q:event.target.value}))
}
const handleSearchChangeOccupations=(event)=>{
  dispatch(handleSearchOccupations({q:event.target.value}))
}
const dispatch = useDispatch();
const dateFormatter = str => {
  return str;
};
console.log("List des skills de l'opp" , DetailsOpportunity.skills)
// useEffect (()=>{

//     },[dispatch]);

  const handleAddLocation=(item)=>{
    setLocations([...locations.filter(obj=>obj.place_id!==item.place_id), item])
}

  const handleChangeCS = (event) => {
    setOrganization_size(event.target.value);
  };
  const handleChangeDescription = (event) => {
    setDescription(event.toString('html'));
  };

  const handleChangeAbout = (event) => {
    setAbout(event.toString('html'));
  };
  const handleChangeResponsabilities = (event) => {
    setResponsabilities(event.toString('html'));
  };
  const handleChangeQualifications = (event) => {
    setQualifications(event.toString('html'));
  };

  const handleChangeLE = (event) => {
    setLevel_of_experience(event.target.value);
  };

  const handleChangeNE = (event) => {
    setNumber_years_of_experience(event.target.value);
  };
  
  const handleChangeWH = (event) => {
    setWork_hours(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChangeCheckbox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeField =(event)=>{
    setState({...state, [event.target.name]:event.target.value});
  }
 
  const handleSearchChangeLangue=(event)=>{
    dispatch(handleSearchLangue({q:event.target.value}))
  }

  const handleSearchChangeSectors=(event)=>{
    dispatch(handleSearchIndustries({q:event.target.value}))
  }

  const handleSearchChangeEducation=(event)=>{
    dispatch(handleSearchEducation({q:event.target.value}))
  }

  const handleSearchChangePosition=(event)=>{
    dispatch(handleSearchPosition({q:event.target.value}))
  }

  const handleSearchChangeSkills=(event)=>{
    dispatch(handleSearchSkills({q:event.target.value}))
  }

  const handleSelectContractType=(test,elt, reason)=>{
    setTagsContractType(elt);
  }
  const [tagsCompany, setTagsCompany] = useState("");
  const handleSelectCompany=(test,elt, reason)=>{
    setTagsCompany(elt);
  }
  const handleFormReset = () => {
    setState(() => initState);
    reset()
    handleClose()
 }

 const [inputValueCompany, setInputValueCompany] = React.useState();
  
 const saveData={
  opportunity_id:DetailsOpportunity.slug,
  slug:props.itemDetailsOpportunity.organization_id?props.itemDetailsOpportunity.organization_id.slug:"",
  page:"1"
}
  const DeleteOpportunity=(event)=>{
    event.preventDefault();
    dispatch(handleDeleteObject(saveData))
    if(props.search){
    dispatch(handleGetOpportunitiesDashboard({scope:"opportunity", search:props.search, page:`${props.page}`}))
    }
    dispatch(handleGetOpportunitiesDashboard({scope:"opportunity", search:props.search, page:`${props.page}`}))
  }

  const formData ={
    slug:props.itemDetailsOpportunity?props.itemDetailsOpportunity.organization_id.slug:"" , 
    page:"1"
  }
 const submit=(event, values)=>{
   event.preventDefault();
    console.log("des", state.description)
    dispatch(handleEditOpportunity({...state,
      locations:locations.map(item=>item.data),
        page:"1",
        slug:props.itemDetailsOpportunity?props.itemDetailsOpportunity.organization_id.slug:"",
        slug_opp: DetailsOpportunity? DetailsOpportunity.slug:"",
        end_date: moment.isMoment(selectedDate) ? 
        selectedDate.format('YYYY-MM-DD')  : selectedDate,
        organization_id:tagsCompany? tagsCompany.organization_id : DetailsOpportunity.organization_id.id,
        title: state.title?state.title:DetailsOpportunity.title,
        amount_min : state.amount_min? state.amount_min: DetailsOpportunity.amount_min,
        amount_max : state.amount_max? state.amount_max: DetailsOpportunity.amount_max,
       // about:about?about: DetailsOpportunity.about,
        description:description?description: DetailsOpportunity.description,
       // qualifications:qualifications?qualifications: DetailsOpportunity.qualifications,
        //responsibilities:responsibilities?responsibilities: DetailsOpportunity.responsibilities,
        organization_size:organization_size,
        level_of_experience:level_of_experience,
        number_years_of_experience:number_years_of_experience,
        work_hours:work_hours,
        publication_status:1,
        skill_id: valueSkills.length===0?  []  : valueSkills.map(item=>item.skill_id),
        job_contract_type_id:tagsContractType?tagsContractType.job_contract_type_id:DetailsOpportunity.job_contract_type_id.id,
        area_id: valueAreas.length===0?  []  : valueAreas.map(item=>item.area_id),
        industry_id: valueIndustries.length==0?   [] : valueIndustries.map(item=>item.industry_id),
        position_id : valuePositions.length===0?  [] : valuePositions.map(item=>item.position_id),
        occupation_id: valueOccupations.length===0? []  : valueOccupations.map(item=>item.occupation_id),
        category_id: valueCategories.length===0? [] : valueCategories.map(item=>item.category_id),
        education_id : valueEducations.length===0?  []  : valueEducations.map(item=>item.education_id),
        language_id : valueLanguages.length===0?  []  : valueLanguages.map(item=>item.language_id),
        }))
        if(props.search) {
        dispatch(handleGetOpportunitiesDashboard({
         //scope:"opportunity", search:props.search, page:`${props.page}`
        }))

      }
    }




 const handleSaveSumbit=(event, values)=>{
  event.preventDefault();
   dispatch(handleEditSaveOpportunity({...state,
    
     locations:locations.map(item=>item.data),
       page:"1",
       slug:props.itemDetailsOpportunity?props.itemDetailsOpportunity.organization_id.slug:"",
       slug_opp: DetailsOpportunity? DetailsOpportunity.slug:"",
       end_date: moment.isMoment(selectedDate) ? 
       selectedDate.format('YYYY-MM-DD')  : selectedDate,
       organization_id:tagsCompany? tagsCompany.organization_id : DetailsOpportunity.organization_id.id,
       title: state.title?state.title:DetailsOpportunity.title,
       amount_min : state.amount_min? state.amount_min: DetailsOpportunity.amount_min,
       amount_max : state.amount_max? state.amount_max: DetailsOpportunity.amount_max,
       // about:about?about: DetailsOpportunity.about,
       description:description?description: DetailsOpportunity.description,
       //qualifications:qualifications?qualifications: DetailsOpportunity.qualifications,
       //responsibilities:responsibilities?responsibilities: DetailsOpportunity.responsibilities,
       organization_size:organization_size,
       level_of_experience:parseInt(level_of_experience),
       number_years_of_experience:number_years_of_experience,
       work_hours:work_hours,
       publication_status:0,
       skill_id: valueSkills.length===0?  []  : valueSkills.map(item=>item.skill_id),
       job_contract_type_id:tagsContractType?tagsContractType.job_contract_type_id:DetailsOpportunity.job_contract_type_id.id,
       area_id: valueAreas.length===0?  []  : valueAreas.map(item=>item.area_id),
       industry_id: valueIndustries.length==0?   [] : valueIndustries.map(item=>item.industry_id),
       position_id : valuePositions.length===0?  [] : valuePositions.map(item=>item.position_id),
       occupation_id: valueOccupations.length===0? []  : valueOccupations.map(item=>item.occupation_id),
       category_id: valueCategories.length===0? [] : valueCategories.map(item=>item.category_id),
       education_id : valueEducations.length===0?  []  : valueEducations.map(item=>item.education_id),
       language_id : valueLanguages.length===0?  []  : valueLanguages.map(item=>item.language_id),
       }))
       
       if(props.search) {
        dispatch(handleGetOpportunitiesDashboard({
          //scope:"opportunity", search:props.search, page:`${props.page}`
        }))
       }}   

  const [valueSkills, setValueSkills] = useState(props.itemDetailsOpportunity.skills);
  const [valueEducations, setValueEducations] = useState(props.itemDetailsOpportunity.educations);
  const [valuePositions, setValuePositions] = useState(props.itemDetailsOpportunity.positions);
  const [valueAreas, setValueAreas] = useState(props.itemDetailsOpportunity.areas);
  const [valueLanguages, setValueLanguages] = useState(props.itemDetailsOpportunity.languages);
  const [valueOccupations, setValueOccupations] = useState(props.itemDetailsOpportunity.occupations);
  const [valueIndustries, setValueIndustries] = useState(props.itemDetailsOpportunity.industries);
  const [valueCategories, setValueCategories] = useState(props.itemDetailsOpportunity.categories);
  const [valueOrganizationId, setValueOrganizationId] = useState(
    [
   { organization_id: props.itemDetailsOpportunity.organization_id?props.itemDetailsOpportunity.organization_id.id:"", name: props.itemDetailsOpportunity.organization_id?props.itemDetailsOpportunity.organization_id.name:""}
  ]
  );
  const [valueContractType, setValueContractType] = useState(
    [
   { job_contract_type_id: props.itemDetailsOpportunity.job_contract_type_id?props.itemDetailsOpportunity.job_contract_type_id.id :"", name: props.itemDetailsOpportunity.job_contract_type_id?props.itemDetailsOpportunity.job_contract_type_id.name:""}
  ]
  );
  console.log("props.itemDetailsOpportunity", props.itemDetailsOpportunity)
  
 
  const loader=<CircularProgress  color="primary"/>;
const test =<IntlMessages id="formRechercheDemploi"/>
        const handleClickOpen = (slug) => {
        dispatch(EditOpportunityResetMessages())
        dispatch(handleGetEditOpportunityDetails(slug));
        dispatch(handleSearchCompany({scope:"companies",search:`${window.location.search}`}));
        dispatch(handleSearchEducation());
        dispatch(handleSearchPosition({q:""}));
        dispatch(handleSearchLangue({q:""}));
        dispatch(handleSearchCompanyAdmin({q:""}))
        dispatch(handleSearchSkills({q:""}))
        dispatch(handleSearchContractType({q:""}))
        dispatch(handleSearchIndustries({q:""}))
        dispatch(handleSearchOccupations({q:""}))
        dispatch(handleSearchCategories({q:""}))
        dispatch(handleSearchArea({q:""}))
        setOpen(true);
        };
    

        const handleClose = () => {
          dispatch(EditOpportunityResetMessages())
          dispatch(addOpportunityDraftResetMessages())
          setOpen(false);
        };

  
    return(
    <div className="PageEditOpportunity">
    <Button variant="outlined" color="primary" className="edit-opportunity"
    onClick={()=>handleClickOpen(slug)}>
    {
      props.icon?
      <EditIcon/>:
        <div><EditIcon/>{<IntlMessages id="edit_opportunity"/>}</div>
      }
      
    </Button>
    
   
    <Dialog 
    className="EditOpportunity"
    onClose={handleClose} 
    aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
      <div className="">{<IntlMessages id="edit_opportunity"/>}    </div>
      </DialogTitle>
              {error?<Alert severity="error" onClose={()=>dispatch(EditOpportunityResetMessages())}>{error}</Alert>:null}
              {serverError?<Alert severity="error" onClose={()=>dispatch(EditOpportunityResetMessages())}>{serverError}</Alert>:null}
              {DeleteError?<Alert severity="error" onClose={()=>dispatch(deleteObjectResetMessages())}>{DeleteError}</Alert>:null}
              
              {DeleteMessage?
              <Alert severity="success" onClose={()=>dispatch(deleteObjectResetMessages())}>{DeleteMessage}</Alert>:null}
              {/* {message?
                <Alert severity="success" onClose={()=>dispatch(EditOpportunityResetMessages())}>{message}</Alert>:
                null} */}

            <DialogContent className="edit-opportunity">
               <DialogContentText>
              {
              message?
              <Alert severity="success">{message}</Alert>:
              SpinnerDetailsOpportunity?
              <div className="text-center">{loader}</div>
              :
              <Form>
              <>
              <Row>
                  <Col sm={12}>
                   <TextField
                    required
                    id="title"
                    name="title"
                    label={<IntlMessages id="title"/>}
                    onChange={handleChangeField}
                    defaultValue={DetailsOpportunity.title}
                    variant="outlined"
                    value={state.title}
                  />
                  </Col>
                  <Col md={6}>
                  
                <Autocomplete
                fullWidth
                id="organization_id"
                required
                size="small"
                onChange={handleSelectCompany}
                value={value}
                options={companiesData}
                defaultValue={valueOrganizationId[0]}
                getOptionLabel={(option) => option.name}
                onInputChange={(event, newInputValue) => {
                setInputValueCompany(newInputValue);}}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.name}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params}  variant="outlined" 
                  label={<IntlMessages id="organization"/>}
                  margin="normal"
                  placeholder="Ex: Wutiko" />
                )}
              />
            </Col>
                  <Col sm={6}>
                   <Autocomplete
                    fullWidth
                    multiple
                    id="category_id"
                    size="small"
                    onChange={(_, selectedOptions) => setValueCategories(selectedOptions)}
                    //onChange={handleSelectCategories}
                    options={categoriesData}
                    getOptionLabel={(option) => option.name}
                    value={valueCategories}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} onChange={handleSearchChangeCategories} variant="outlined" 
                      label={<IntlMessages id="opportunity_type"/>}
                      margin="normal"
                      placeholder="Ex: Job" />
                    )}
                  />
              </Col>
              
                  {/* <Col sm={12}>
                  <Field
                      id="about"
                      name="about"
                      label={<IntlMessages id="context_opportunity"/>}
                      component={RichTextMarkdown}
                      componentclassName="textarea"
                      onChange={handleChangeAbout}
                      value={state.about}
                      />  
                  </Col> */}
                  <Col sm={12}>
                  <Field
                      id="description"
                      name="description"
                      label={<IntlMessages id="job_description"/>}
                      component={RichTextMarkdown}
                      componentclassName="textarea"
                      onChange={handleChangeDescription}
                      value={state.description}
                      />  
                  </Col>
                  {/* <Col sm={12}>
                    <Field
                    id="responsibilities"
                      name="responsibilities"
                      label={<IntlMessages id="responsibilities"/>}
                      component={RichTextMarkdown}
                      componentclassName="textarea"
                      onChange={handleChangeResponsabilities}
                      value={state.responsibilities}
                      />
                  </Col>
                  <Col sm={12}>
                 
                  <Field
                      id="qualifications"
                      name="qualifications"
                      component={RichTextMarkdown}
                      label={<IntlMessages id="qualifications"/>}
                      componentclassName="textarea"
                      onChange={handleChangeQualifications}
                      value={state.qualifications}
                      />
                  </Col>

                  */}

                        <Col sm={6}>
                        <TextField
                        id="amount_min"
                        name="amount_min"
                        label={<IntlMessages id="minimum_salary"/>}
                        onChange={handleChangeField}
                        variant="outlined"
                        defaultValue={DetailsOpportunity.amount_min}
                        value={state.amount_min}
                        />
                        </Col>

                        <Col sm={6}>
                        <TextField
                        id="amount_max"
                        name="amount_max"
                        label={<IntlMessages id="maximum_salary"/>}
                        onChange={handleChangeField}
                        defaultValue={DetailsOpportunity.amount_max}
                        variant="outlined"
                        value={state.amount_max}
                        />
                        </Col>

                        <Col sm={3}>
                        <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">{t("wutiko.experience_level")}</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="level_of_experience"
                        defaultValue={DetailsOpportunity.level_of_experience}
                        value={level_of_experience}
                        onChange={handleChangeLE}
                        >
                            <MenuItem value="0">{t("wutiko.not_applicable")}</MenuItem>
                            <MenuItem value="1">{t("wutiko.internship")}</MenuItem> 
                            <MenuItem value="2">{t("wutiko.junior")}</MenuItem> 
                            <MenuItem value="3">{t("wutiko.experienced")}</MenuItem> 
                            <MenuItem value="4">{t("wutiko.senior")}</MenuItem> 
                            <MenuItem value="5">{t("wutiko.expert")}</MenuItem> 
                        </Select>
                        </FormControl>
                        </Col>
                        
                       

                        <Col sm={3}>
                         <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">{t("wutiko.experience")}</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="number_years_of_experience"
                        defaultValue={DetailsOpportunity.number_years_of_experience}
                        value={number_years_of_experience}
                        onChange={handleChangeNE}
                        >
                            {NumberYearsOfExperience.map((item,key) => (
                                      <MenuItem key={key} value={item.name}>{`${item.name} ${t(`wutiko.years`)}`}</MenuItem> 
                                      ))}
                        </Select>
                        </FormControl> 
                        </Col>
                        <Col sm={3}>
                        <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">{t("wutiko.work_hours")}</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="work_hours"
                        defaultValue={DetailsOpportunity.work_hours}
                        value={work_hours}
                        onChange={handleChangeWH}
                        >
                            {workHour.map((item,key) => (
                                      <MenuItem key={key} value={item.value}>{`${item.name}`}</MenuItem> 
                                      ))}
                        </Select>
                        </FormControl> 
                        </Col>
                        <Col sm={3}>
                        <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">{t('wutiko.company_size')}</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="organization_size"
                        defaultValue={DetailsOpportunity.organization_size}
                        value={organization_size}
                        onChange={handleChangeCS}
                        >
                        {companySize.map((name,key) => (
                        <MenuItem key={key} value={name.value}>{name.name}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                        </Col>


                        <Col md={6}>       
                        <MuiPickersUtilsProvider  libInstance={moment} utils={MomentUtils}>
                        <Col md={12}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="end_date"
                            name="end_date"
                            label={<IntlMessages id="application_deadline"/>}
                            format='YYYY-MM-DD'
                            rifmFormatter={dateFormatter}
                            value={selectedDate}
                            selected={state.end_date}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />  
                        </Col>
                        </MuiPickersUtilsProvider> 
                        </Col>

                        <Col sm={6}>
                            
                        <Col sm={12}>
                              <Field
                                  fullWidth
                                  value={state.location_id}
                                  component={LocationSearchInput}
                                  addLocation={handleAddLocation}
                                  placeholder="Location(s)"
                                  label="Location(s)"
                              />
                          </Col> 
                          <Col sm={12}>
                              {
                                    
                                      locations.map(item=>{
                                          return(
                                            <Chip label={`${item.data.city_name} ${item.data.state_province_name} ${item.data.country_name}`} onDelete={()=>setLocations(locations.filter(obj=>obj.place_id!==item.place_id))}/>
                                          )
                                        })
                                     
                              }
                              
                              {/* <Chip label={`${props.itemDetailsOpportunity.locations.name}`} />
                               { props.itemDetailsOpportunity.locations?
                                props.itemDetailsOpportunity.locations.map(item=>{
                                          return(
                                            <Chip label={`${item.name}`} />
                                          )
                                        }):null
                                      }  */}
                            </Col>
                  </Col>
                       
                </Row> 

                <Col sm={12}>
                 <Autocomplete
                    fullWidth
                    id="job_contract_type_id"
                    size="small"
                    value={value}
                    defaultValue={valueContractType[0]}
                    onChange={handleSelectContractType}
                    options={contractTypeData}
                    getOptionLabel={(option) => option.name}
                    onInputChange={(event, newInputValue) => {
                    setInputValueContractType(newInputValue);}}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} onChange={handleSearchChangeContractType} variant="outlined" 
                      label={<IntlMessages id="contract_type"/>}
                      margin="normal"
                      placeholder="Ex: CDD" />
                    )}
                  /> 
            </Col> 
                <Col sm={12}>
                <Autocomplete
                fullWidth
                multiple
                id="education_id"
                size="small"
                onChange={(_, selectedOptions) => setValueEducations(selectedOptions)}
                options={educationData}
                value={valueEducations}
                getOptionLabel={ (option) => option.name}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.name}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params}  variant="outlined"
                  onChange={handleSearchChangeEducation} 
                  label={<IntlMessages id="education"/>}
                  margin="normal"
                  placeholder="Ex: Informatique ..." />
                )}
              />
              
            </Col> 


            <Col sm={12}>
                <Autocomplete
                fullWidth
                multiple
                id="position_id"
                size="small"
                //onChange={handleSelectPosition}
                options={positionsData}
                getOptionLabel={ (option) => option.title}
                value={valuePositions}
                onChange={(_, selectedOptions) => setValuePositions(selectedOptions)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.title}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params}  variant="outlined" 
                  label={<IntlMessages id="position"/>}
                  onChange={handleSearchChangePosition} 
                  margin="normal"
                  placeholder="Ex: Informatique ..." />
                )}
              />
            </Col> 
                 <Col sm={12}>
                      <Autocomplete
                          fullWidth
                          multiple
                          id="language_id"
                          size="small"
                          freeSolo
                          disableClearable
                          open={openDialog}
                          onOpen={() => {
                            setOpenDialog(true);
                          }}
                          onClose={() => {
                            setOpenDialog(false);
                          }}
                          onChange={(_, selectedOptions) => setValueLanguages(selectedOptions)}
                          options={langue}
                          getOptionLabel={(option) => option.name}
                          value={valueLanguages}
                          inputValue={inputValueLangue}
                          onInputChange={(event, newInputValue) => {
                            setInputValueLangue(newInputValue);}}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option.name}
                                size="small"
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField {...params} 
                            variant="outlined"
                            onChange={handleSearchChangeLangue}  
                            label={<IntlMessages id="language"/>}
                            margin="normal"
                            placeholder="Ex: French" />
                          )}
                        /> 
                             
                 <Autocomplete
                    multiple
                    fullWidth
                    id="industry_id"
                    size="small"
                    onChange={(_, selectedOptions) => setValueIndustries(selectedOptions)}
                    options={SectorsData}
                    getOptionLabel={(option) => option.name}
                    value={valueIndustries}
                    inputValue={inputValueSectors}
                    onInputChange={(event, newInputValue) => {
                      setInputValueSectors(newInputValue);}}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} onChange={handleSearchChangeSectors} variant="outlined" 
                      label={<IntlMessages id="sectors"/>}
                      margin="normal"
                      placeholder="Ex: Pétroles"
                      />
                    )}
                  />
               <Autocomplete
                    multiple
                    fullWidth
                    id="skill_id"
                    size="small"      
                    disableCloseOnSelect
                    options={skillsData}
                    value={valueSkills}
                    getOptionLabel={(option) =>  option.name }
                    inputValue={inputValueSkills}
                    onInputChange={(event, newInputValue) => {
                      setInputValueSkills(newInputValue);}}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} onChange={handleSearchChangeSkills} variant="outlined" 
                      label={<IntlMessages id="skills"/>}
                      margin="normal"
                      placeholder="Ex: Chef d'entreprise"
                     
                       />
                    )}
                   onChange={(_, selectedOptions) => setValueSkills(selectedOptions)}
                  /> 

              <Autocomplete
                    multiple
                    fullWidth
                    id="area_id"
                    size="small"
                    onChange={(_, selectedOptions) => setValueAreas(selectedOptions)}
                    options={AreasData}
                    getOptionLabel={(option) => option.name}
                    value={valueAreas}
                    inputValue={inputValueAreas}
                    onInputChange={(event, newInputValue) => {
                      setInputValueAreas(newInputValue);}}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} onChange={handleSearchChangeAreas} variant="outlined" 
                      label={<IntlMessages id="areas"/>}
                      margin="normal"
                      placeholder="Ex: Sport" />
                    )}
                  />

              {/* <Autocomplete
                    multiple
                    fullWidth
                    id="benefit_id"
                    size="small"
                    freeSolo
                    onChange={handleSelectBenefits}
                    options={benefitsData}
                    getOptionLabel={(option) => option.name}
                    value={value}
                    // inputValue={inputValueAreas}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValueAreas(newInputValue);}}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} onChange={handleSearchChangeBenefits} variant="outlined" 
                      label={<IntlMessages id="add_benefits"/>}
                      margin="normal"
                      placeholder="Ex: Sport" />
                    )}
                  /> */}




                <Autocomplete
                    multiple
                    fullWidth
                    id="occupation_id"
                    size="small"
                    onChange={(_, selectedOptions) => setValueOccupations(selectedOptions)}
                    options={occupationsData}
                    getOptionLabel={(option) => option.name}
                    value={valueOccupations}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} 
                      onChange={handleSearchChangeOccupations} variant="outlined" 
                      label={<IntlMessages id="occupations"/>}
                      margin="normal"
                      placeholder="Ex: Sport" />
                    )}
                  />





                {/* <Autocomplete
                    multiple
                    fullWidth
                    id="contracttype_id"
                    size="small"
                    freeSolo
                    onChange={handleSelectContractType}
                    options={contactTypeData}
                    getOptionLabel={(option) => option.name}
                    value={value}
                    // inputValue={inputValueAreas}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValueAreas(newInputValue);}}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} onChange={handleSearchChangeContractType} variant="outlined" 
                      label={<IntlMessages id="add_contract_type"/>}
                      margin="normal"
                      placeholder="Ex: Sport" />
                    )}
                  /> */}
              </Col>
                <Col sm={12}>
                {message?<Alert severity="success" onClose={()=>dispatch(EditOpportunityResetMessages())}>{message}</Alert>:null}
              {error?<Alert severity="error" onClose={()=>dispatch(EditOpportunityResetMessages())}>{error}</Alert>:null}
              {serverError?<Alert severity="error" onClose={()=>dispatch(EditOpportunityResetMessages())}>{serverError}</Alert>:null}
             
              {messageDraft?<Alert severity="success" onClose={()=>dispatch(addOpportunityDraftResetMessages())}>{messageDraft}</Alert>:null}
              {errorDraft?<Alert severity="error" onClose={()=>dispatch(addOpportunityDraftResetMessages())}>{errorDraft}</Alert>:null}
              
               {/* {spinner ?loader:
                <Field name="submit"
                  className="save"
                  component={renderSubmitGroup}
                  onCancel={e => handleClose()}
                  removeText={<i className="fa fa-trash-o"></i>}
                  onRemove={ e => DeleteOpportunity(saveData)}
                     />}
                */}


                <Grid>
                <div  className="bottom-20 roww add-opportunity-btn">
                <div className="col-md-6">
                  <Button  onClick={handleFormReset} className="cancel" variant="contained" type="submit" color="primary">{<IntlMessages id="cancel"/>} </Button>
                  <Button  onClick={DeleteOpportunity} className="cancel" variant="contained" type="submit" color="primary"><i className="fa fa-trash-o"></i></Button>
                  </div>
                
                <div className="col-md-3 text-right">
                {spinnerDraft?loader:
                <Button  onClick={handleSaveSumbit} className="cancel saveDraft text-right" variant="contained" type="submit" color="primary">{<IntlMessages id="draft"/>} </Button>}
               </div>
                <div className="col-md-3 text-right">
                {spinner?loader:<Button  onClick={submit} className="addopp" variant="contained" type="submit" color="primary">{<IntlMessages id="published_now"/>}</Button>
                 }
               </div>
                </div>
                </Grid>
                </Col></>
              
            </Form>  }
              </DialogContentText> 
            </DialogContent> 
            </Dialog>
           
      </div>
    );
  
          }
  function mapStateToProps(state, props) {

    
    const itemDetailsOpportunitytest = state.opportunities.get_edit_opportunity.details;
    const customInitialValues = {
      title: itemDetailsOpportunitytest.title,
      description:itemDetailsOpportunitytest.description,
      responsibilities:itemDetailsOpportunitytest.responsibilities,
      about:itemDetailsOpportunitytest.about,
      qualifications:itemDetailsOpportunitytest.qualifications,
      // skillsList:itemDetailsOpportunitytest.skills
      //number_years_of_experience:itemDetailsOpportunitytest.number_years_of_experience,
      // amount_min : itemDetailsOpportunitytest.amount_min,
      //  amount_max : itemDetailsOpportunitytest.amount_max,
      // level_of_experience:itemDetailsOpportunitytest.level_of_experience,
      //job_contract_type_id:itemDetailsOpportunitytest.job_contract_type_id,
       // number_years_of_experience:itemDetailsOpportunitytest.number_years_of_experience,
          // work_hours:itemDetailsOpportunitytest.work_hours,
          // end_date:itemDetailsOpportunitytest.end_date,
     
    }

    return {
      initialValues: customInitialValues,
  };
}

export default connect(
  mapStateToProps
)(reduxForm({
   form: 'EditOpportunities', // a unique identifier for this form
  enableReinitialize: true
})(withTranslation('translation')(EditOpportunities)))

// reduxForm({
//   form: 'EditOpportunities',
//   enableReinitialize: true,
// })(EditOpportunities);

// export default connect (mapStateToProps)(withTranslation('translation')(EditOpportunities));