import React,{useEffect,useState} from 'react';
import PropTypes from 'prop-types';
import { fade, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import { splitStringQueryAddState } from 'util/UtilFunctions';
import { opportunities } from 'reducers/opportunities';

// const useStyles = makeStyles((theme) => ({
//   search:{
//     paddingTop:0,
//     paddingBottom:0
//   }
// }));


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function SearchBar() {
  const classes = useStyles();
  const [keywords, setKeywords] = useState('')
  const [keys, setState]=useState([]);
  const constructUrl=()=>{
    var url ="";
    keys.map((item)=>{
        if(!url){
            return url+=`${item.type}=${item.name}`;
        }else{
            return url+=`&${item.type}=${item.name}`
        }
    })
    return url   
    }

  const handleKeyPress=(event)=>{
    if(event.charCode==13){
      setTimeout(
        ()=>{
          window.location.href = 
          window.location.pathname === '/' ?
          `/opportunities?${constructUrl()}`:
          `${window.location.pathname}?${constructUrl()}`
        },0
      )       
    }
          
  }

  
  const  handleTest =(e) =>{
      if (e.charCode == 13) {
        { return; }
        window.location.href="http://www.monsite.com/"
      }
      if (e.keyCode == 13) {
        { return; }
        window.location.href="http://www.monsite.com/"
      }
    }


  useEffect(()=>{
    if(keys.length==0){
      setState(splitStringQueryAddState(window.location.search));
    }
  },[setState])
  return (

<div className="search_bar ">
        <form className="search">
    <div className="search__wrapper">
    <input type="text"  name="keywords" placeholder="Search for..." 
    className="search__field"
    onKeyPress={handleKeyPress}
    onChange={(event)=>setState([...keys.filter(obj=>obj.type!=='keywords'),{name:event.target.value, type:'keywords'}])}
    
    />
    <button type="submit" class="fa fa-search search__icon"></button>
  </div>  
        
</form></div> 


  );
}

SearchBar.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};





{/* <div className="search-header">
<form action="">  
  {/* <input type="search"  className="search-input"/>
  <i className="fa fa-search"></i> */}

{/* <form action="" className="search-bar">
	<input type="search" name="search" pattern=".*\S.*" required />
	<button className="search-btn" type="submit">
		<span>Search</span>
	</button>
</form> 



    <div className={classes.search}>
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth={false}
            size="small"
            name="keywords"
            placeholder="SEARCH"
            type="text"
            id="keywords"
            className="search-input"
            onKeyPress={handleKeyPress}
            autoComplete="current-keywords"
            onChange={(event)=>setState([...keys.filter(obj=>obj.type!=='keywords'),{name:event.target.value, type:'keywords'}])}
            InputProps={{
            endAdornment: <InputAdornment position="end">
                    <IconButton>
                          {keywords?<ClearIcon onClick={()=>setKeywords('')}/>:<div className={classes.searchIcon}><SearchIcon /> </div>}
                    </IconButton>  
            </InputAdornment>,
            
             }}
          />
        </div></form></div> */}