import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import SortIcon from "@material-ui/icons/ArrowDownward";
import {useDispatch, useSelector} from 'react-redux'
import {  handleGetFollowersDashboard } from 'actions/dashboard/keysList';
import React,{useState,useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Button } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CardBox from "components/CardBox/index";
import CardMenu from 'components/dashboard/Common/CardMenu';
import {Area, AreaChart, Line, LineChart, ResponsiveContainer} from "recharts";
import ChartCard from "./ChartCard";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import ItemFollowers from './ItemFollowers'
import ReactPaginate from "react-paginate";
import Grid from '@material-ui/core/Grid';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {arrayMove, SortableContainer} from 'react-sortable-hoc';

const FollowersDashboard = (props , t) => {
//const {FollowersDashboard} = props;


const countFollowers = useSelector(state=>state.keys.followersDashboard.count);
const spinnerFollowersDashboard = useSelector(state=>state.keys.followersDashboard.spinner);
const FollowersDashboard = useSelector(state=>state.keys.followersDashboard.list);
const [currentPageFollowers, setcurrentPageFollowers] = React.useState(1);
const dispatch = useDispatch();
const Contacts = SortableContainer(({contacts}) => {
  return (
    <div className="row col-md-12">
      {spinnerFollowersDashboard?
          <Grid container item justify="center" xs={12}><CircularProgress size={80}/></Grid>:
            FollowersDashboard.map((contact, index) => (
        <ItemFollowers key={index} index={index} contact={contact.profile_id}/>
      ))}
    </div>
  );
});

console.log("FollowersDashboard Dashboard" , FollowersDashboard)

const handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    console.log('Selected Rows: ', state.selectedRows);
  };


  const [contacts, setContacts] = useState(FollowersDashboard);

  console.log("contact 2 " , contacts)
  const onSortEnd = ({oldIndex, newIndex}) => {
    setContacts(arrayMove(contacts, oldIndex, newIndex))
  };

  const RESULTS_PER_PAGE = 12;
  useEffect(()=>{
    dispatch(handleGetFollowersDashboard({search:`${window.location.search}`, page:`${currentPageFollowers}`}))
  },[dispatch])


  const handlePageClickFollowers=(newPage) =>{
    setcurrentPageFollowers(newPage.selected + 1)
    dispatch(handleGetFollowersDashboard({page:`${newPage.selected + 1}`}));
  } 
  
      let pageCountFollowers;
      let onPageChangeFollowers;
  
  
      pageCountFollowers = Math.ceil(countFollowers / RESULTS_PER_PAGE);
      onPageChangeFollowers = handlePageClickFollowers;

  return (
    
    <div className="container-wrap container animated slideInUpTiny animation-duration-3 dashboard">
       <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
       <h2 className="title mb-3 mb-sm-0"><IntlMessages id="followers"/></h2>
        <nav className="mb-0" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <Link to="/dashboard" className="breadcrumb-item">{<IntlMessages id="dashboard" />}</Link>
            <span href="#" className="active breadcrumb-item" aria-current="page">{props.title}</span>
            </ol></nav></div>
        <div className="animated slideInUpTiny animation-duration-3">
        <Contacts contacts={contacts} onSortEnd={onSortEnd} useDragHandle={true}/>
        <Grid item md={12} xs={12} container justify="center" className="Listpagination">
       
        <ReactPaginate
        previousLabel={<IntlMessages id="previous" />}
        nextLabel={<IntlMessages id="next" />}
        pageCount={pageCountFollowers}
        onPageChange={handlePageClickFollowers}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
        </Grid>
    </div>
    </div>
  );
};
export default  FollowersDashboard;