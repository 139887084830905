import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { deleteObjectSuccess, deleteObjectError } from  "actions/profile";

const DeleteObjectRequest = async (instance,data)=>
    await instance.put(`/opportunities/${data.opportunity_id}/remove/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* DeleteObject(actions){
    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(DeleteObjectRequest,axiosInstance, actions.data);
    if(data){

    console.log("Delete opp" , "Delete opp")
        yield put(deleteObjectSuccess(data.message));
        yield put({type:"HANDLE_GET_OPPORTUNITIES_BY_COMPANY", data:actions.data})
    }else{
        yield put(deleteObjectError(data.error));
    }
    
}
export function* handleDeleteObject(){
    console.log("Delete" , "Delete Object")
    yield takeEvery("HANDLE_DELETE_OBJECT", DeleteObject)
}
export default function* rootSaga() {
    yield all([fork(handleDeleteObject)]);
}
//