import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { getCompaniesLatestListSuccess, getCompaniesLatestListError } from  "actions/companies/getCompanies";

const GetCompanyLatestRequest = async (instance)=>
    await instance.get(`/ads/interests/display/1/1/list/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetCompanyLatest(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetCompanyLatestRequest,axiosInstance, actions);
    
    if(data.data){

    console.log("Get Latest list",  data.data)
        yield put(getCompaniesLatestListSuccess(data.data));
    }else{
        yield put(getCompaniesLatestListError(data.error));
    }
    
}
export function* handleGetCompanyLatest(){
    yield takeEvery("HANDLE_GET_COMPANY_LATEST", GetCompanyLatest)
}
export default function* rootSaga() {
    yield all([fork(handleGetCompanyLatest)]);
}
