import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { getEditOpportunityDetailsSuccess, getEditOpportunityDetailsError } from  "actions/opportunities";

const GetEditOpportunityDetailsRequest = async (instance,slug)=>
    await instance.get(`/opportunities/${slug}/edit/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetEditOpportunityDetails(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetEditOpportunityDetailsRequest,axiosInstance, actions.slug);
    if(data.data){
        yield put(getEditOpportunityDetailsSuccess(data.data));
    }else{
        yield put(getEditOpportunityDetailsError(data.error));
    }
}
export function* handleGetEditOpportunityDetails(){
    yield takeEvery("HANDLE_GET_EDIT_OPPORTUNITY_DETAILS", GetEditOpportunityDetails)
}
export default function* rootSaga() {
    yield all([fork(handleGetEditOpportunityDetails)]);
}
//