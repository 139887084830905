import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { EditCompanySuccess,EditCompanyError} from "actions/companies/editCompany";

const EditCompanyData = async (instance,data)=>
    await instance.put(`/organizations/${data.slug}/edit/`, data)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* EditCompany(actions){
    
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(EditCompanyData,axiosInstance, actions.data);
    if(data.message){
        yield put({type:"HANDLE_GET_COMPANY_DETAILS", slug:actions.data.slug})
        yield put(EditCompanySuccess(data.message));
    }else{
        console.log("data.error", data, data.error)
        yield put(EditCompanyError(data.error));
    }
}
export function* handleEditCompany(){
    yield takeEvery("HANDLE_EDIT_COMPANY", EditCompany)
}
export default function* rootSaga() {
    yield all([fork(handleEditCompany)]);
}

