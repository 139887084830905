export function handleFilterOpportunity(data){
    return {
        type:"HANDLE_GET_OPPORTUNITY_FILTER",
        payload:data
    }
}
export function handleFilterOpportunitySuccess(data){
    return {
        type:"GET_OPPORTUNITY_FILTER",
        payload:data
    }
}

export function handleFilterOpportunityError(data){
    return{
        type:"GET_OPPORTUNITY_FILTER_FAILLURE",
        payload:data
    }
}
