import { connect } from 'react-redux';

import { getItem,postItem,Empty,showItemDetails} from '../../actions/index'
import { setEducationToProfile } from '../../actions/profile';
import {Alert} from '@material-ui/lab';
import EducationForm from 'pages/EducationForm';


function mapStateToProps(state, router) {
  return {
    companies: state.search.companies,
    studies : state.search.educations,
    educationLevels: state.profil.education.educationLevels,
    serverError: state.profil.education.error,
    serverStatus: state.profil.education.status,
    error: state.profil.add_education.error,
    message: state.profil.add_education.message,
    spinner: state.profil.add_education.spinner,
    userId: state.user.userId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
   //    getItem: (url, type) => dispatch(getItem(url, type)),
//     postItem: (url, type,data) => dispatch(postItem(url, type,data)),
//     Empty: (type) => dispatch(Empty(type)),
//     showItemDetails: (url) => dispatch(showItemDetails(url)),
//     setEducationToProfile: (companies, educations, data, itemId) => dispatch(setEducationToProfile(companies, educations, data, itemId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EducationForm)