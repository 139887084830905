import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import {createFormError } from "actions/forms/createForm";

const createFormRequest = async (instance,formData)=>
    await instance.post(`/forms/form/add/`, formData)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* createForm(actions){

    const state = yield select((state)=>state.users);
    let axiosInstance=state.axiosInstance;
    const data =yield call(createFormRequest,axiosInstance, actions.data);
    if(data.id){
        window.location.href=`/addfieldstoform/${data.id}`  
    }else{
        yield put(createFormError(data.error));
    }
    
}

export function* handlecreateForm(){
    yield takeEvery("HANDLE_CREATE_FORM", createForm)
}
export default function* rootSaga() {
    yield all([fork(handlecreateForm)]);
}
