import React,{useState,useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import {createMuiTheme} from "@material-ui/core/styles";
import SidebarRight from "components/Sidebar/SidebarRight";
import { CardImgOverlay } from "reactstrap";
import index from "react-typical";
import Slider from "react-slick";
import {useDispatch, useSelector} from 'react-redux'
import { handleGetKeysDashboard ,handleGetCurrentPlanDashboard, handleGetTopLocationsDashboard, handleGetBestTalentsDashboard,
  handleGetFollowersDashboard, handleGetScoresDashboard, handleGetApplicationsDashboard, handleGetOpportunitiesDashboard, handleGetMatchingDashboard, handleGetRecentActivitiesDashboard} from 'actions/dashboard/keysList';
import SiteVisitor from "components/dashboard/Misc/SiteVisitor";
import CardBox from "components/CardBox/index";
import CardMenu from 'components/dashboard/Common/CardMenu';
import {Area, AreaChart, Line, LineChart, ResponsiveContainer} from "recharts";
import ChartCard from "./ChartCard";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import {Link} from "react-router-dom";
import {Animated} from "react-animated-css";
import SalesGauge from "./SalesGauge";
import Opportunities from "./Opportunities"
import FollowersDashboardPage from "./FollowersDashboard"
import MatchingDashboardPage from "./MatchingDashboard"
import ApplicationDashboardPage from "./Applications"
import Aux from "util/Auxiliary.js"
import Widget from "components/Widget/index";
import Button from '@material-ui/core/Button';
import {recentActivity} from "./data1";
import RecentActivity from "./RecentActivity";
import IntlMessages from 'util/IntlMessages';
import ProfileView from 'components/profile/ProfileHeader/ProfileView'
import {STORE_URL ,ACCOUNT_URL, PAGE_URL} from "config";
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => ({
  ul:{
    'list-style-type': 'none',
    paddingLeft: 0,
  },
  btn:{
    position:'absolute',
    left:'42%',
    backgroundColor:"#fdc000",
    '&:hover':{
      backgroundColor:"#fdc000",
    }
  },
 cardActions:{
      paddingLeft:15,
      paddingRight:15
  },
  avatar:{
    width: 50,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius:'100%',
  }, 
  flexEnd:{
      paddingLeft:'28%',
  },
  icon:{
      width:15,
      height:15
  },
  icons:{
    width:15,
    height:15,
    border:"solid 1px #c1c1c1"
  },
  link:{
    textDecoration:'none',
    color:'#4285f4',
    '&:hover':{
      textDecoration:'none'
    }
  
}
}));
var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};


export default function Dashboard({props, t }) {
  const classes = useStyles();
  const [anchorEl,setanchorEl]=useState();
  const [menuState,setMenuState]=useState(false);

  

   const increamentData = [
    {name: 'Page A', pv: 200},
    {name: 'Page B', pv: 1200},
    {name: 'Page C', pv: 600},
    {name: 'Page D', pv: 1600},
    {name: 'Page D', pv: 1000},
    {name: 'Page H', pv: 2260},
    {name: 'Page K', pv: 800},
  ];
  
  const onOptionMenuSelect = event => {
    setMenuState(true);
    setanchorEl(event.currentTarget);
  };

  const handleRequestClose = () => {
    setMenuState(false);
  };
  const dispatch = useDispatch();
  const KeysList = useSelector(state=>state.keys.keysDashboard.list);
  const topLocationsDashboard = useSelector(state=>state.keys.topLocationsDashboard.list);
  const bestTalentDashboard = useSelector(state=>state.keys.bestTalents.list);
  const ApplicationsDashboard = useSelector(state=>state.keys.applicationsDashboard.list);
  const OpportunitiesDashboard = useSelector(state=>state.keys.opportunitiesDashboard.list);
  const MatchingDashboard = useSelector(state=>state.keys.matchingDashboard.list);
  const FollowersDashboard = useSelector(state=>state.keys.followersDashboard.list);
  const ScoresDashboard = useSelector(state=>state.keys.scoresDashboard.list);
  const RecentActivitiesDashboard = useSelector(state=>state.keys.recentActivitiesDashboard.list);
  const CurrentPlanDashboard = useSelector(state=>state.keys.currentPlanDashboard.list);
  const is_user_recruiter = useSelector(state=>state.users.userSettings.is_user_recruiter);

  const [pages,setPages]= useState("20");
  const pagesScore = useSelector(state=>state.keys.scoresDashboard.pages);
  const KeysListopportunities = KeysList.opportunities;
  const KeysListFollowers = KeysList.followers;
  const KeysListMatching = KeysList.matching;
  const KeysListApplications = KeysList.applicants;
  const TotalViews = KeysList.organizations_views;
  
  const TestPage = () => {
    setPages = 90;
  };
  
    console.log("ScoresDashboard[0]", ScoresDashboard.pages)
    console.log("Rescent Activities", RecentActivitiesDashboard)
    console.log("Current Plan", CurrentPlanDashboard)
    console.log("recentActivity", recentActivity)
    
  
  const data = 

      [
        {
          "category": "Competitions",
          "value":   40,
          "full": 100
        },
        {
          "category": "Pages",
          "value":  ScoresDashboard? ScoresDashboard.pages: "",
          "full": 100
        },
        {
          "category": "Opportunities",
          "value":   60,
          "full": 100
        },]  ;

  console.log("Score Pages" , parseInt(pages))
  
  useEffect(()=>{
    dispatch(handleGetKeysDashboard({}))
    dispatch(handleGetTopLocationsDashboard({}))
    dispatch(handleGetBestTalentsDashboard({}))
    dispatch(handleGetApplicationsDashboard({}))
    dispatch(handleGetOpportunitiesDashboard({}))
    dispatch(handleGetMatchingDashboard({}))
    dispatch(handleGetScoresDashboard({}))
    dispatch(handleGetRecentActivitiesDashboard({}))
    dispatch(handleGetCurrentPlanDashboard({}))
  },[dispatch])

  console.log("KeysList" , KeysList)
  console.log("KeysListopportunities" , KeysListopportunities)
  console.log("topLocationsDashboard" , topLocationsDashboard)
  console.log("Best Talents" , bestTalentDashboard)
  console.log("ApplicationsDashboard" , ApplicationsDashboard)
  console.log("OpportunitiesDashboard" , OpportunitiesDashboard)
  console.log("MatchingDashboard" , MatchingDashboard)
  console.log("FollowersDashboard" , FollowersDashboard)
  console.log("ScoresDashboard" , ScoresDashboard)
    const settings = {
        dots: true,
        infinite: true,
        speed: 600,
        slidesToShow: 5,
        slidesToScroll: 5
      };

    
    
      
    return (
    <div className="container-wrap container animated slideInUpTiny animation-duration-3 dashbord-bloc-1">
        <div className="row  padding-0">
        <div className="col-md-9">
        <div className="row">
      {is_user_recruiter?
      <div className="best-talents col-md-9">
        <Aux>
      <h2 className="jr-entry-title d-flex flex-row">Best talents
      <span className="text-primary jr-font-weight-medium jr-fs-md pointer ml-auto d-none d-sm-block"> <Link to="/dashboard/matching">See more <i
          className="zmdi zmdi-long-arrow-right jr-fs-xxl ml-2 d-inline-block align-middle"/></Link></span></h2>

      <ul className="jr-agents-list">
         <Slider {...settings} className="container paddin-0">
     

             
        {bestTalentDashboard.map((user, index) =>
          <li key={index}>
            <div className="jr-profileon">
              <div className="jr-profileon-thumb"><img alt="..." src={user.profile.photo}/></div>
              <div className="jr-profileon-content">
                <h5 className="mb-0 text-truncate">
                <ProfileView ProfileSlug={user.profile} item="item"/></h5>
                <p className="mb-0 jr-fs-sm text-truncate"><i className={`zmdi zmdi-star text-orange`}/> {user.matching_score} %
                  {/* <span>|</span> {user.opportunity.title} */}
                </p>
              </div>
            </div>
          </li>
       
          )
        }
        </Slider> 
        </ul>
  <span className="text-primary jr-font-weight-medium jr-fs-md pointer mb-3 d-block d-sm-none">      <Link to="/dashboard/matching">See more<i
        className="icon icon-long-arrow-right jr-fs-xxl ml-2 d-inline-block align-middle"/></Link></span>
    </Aux>
     <div className="timetohire col-md-12">
     <h1>Time to hire <span className="count">23 <small>week</small></span></h1>
     </div>
     </div>
    :

    <div className="best-talents col-md-9 blockNotConnect">
    <Aux >
   <h2 className="jr-entry-title d-flex flex-row">Best talents
  <span className="text-primary jr-font-weight-medium jr-fs-md pointer ml-auto d-none d-sm-block">  <Link to="/dashboard/matching">See more <i
        className="zmdi zmdi-long-arrow-right jr-fs-xxl ml-2 d-inline-block align-middle"/></Link></span></h2>

    <ul className="jr-agents-list">
       <Slider {...settings} className="container paddin-0">
   

           
      {bestTalentDashboard.map((user, index) =>
        <li key={index}>
          <div className="jr-profileon">
            <div className="jr-profileon-thumb"><img alt="..." src={user.profile.photo}/></div>
            <div className="jr-profileon-content">
              <h5 className="mb-0 text-truncate">
              <ProfileView ProfileSlug={user.profile}/></h5>
              <p className="mb-0 jr-fs-sm text-truncate"><i className={`zmdi zmdi-star text-orange`}/> {user.matching_score} %
                {/* <span>|</span> {user.opportunity.title} */}
              </p>
            </div>
          </div>
        </li>
     
        )
      }
      </Slider> 
      </ul>
    <span className="text-primary jr-font-weight-medium jr-fs-md pointer mb-3 d-block d-sm-none">See more<i
      className="icon icon-long-arrow-right jr-fs-xxl ml-2 d-inline-block align-middle"/></span>
  </Aux>
   <div className="timetohire col-md-12">
   <h1>Time to hire <span className="count">23 <small>week</small></span></h1>
   </div>
   </div>
}
   




    {is_user_recruiter?
    <div className="col-md-3 current-plan">
          <Widget>
          {/* {"id":"922720ea-b4de-4243-9fa8-d70d1716e228",
          "name":"ENTREPRISE",
          "is_active":true,
          "free_post":null,
          "number_of_user":null,
          "premium_post":null,
          "price":"50.0 USD",
          "plan_category":"Corporate Yearly",
          "validation_date":"Expires in 371 days"} */}
            <h2 className="title">Your Current Plan</h2>
            <div className="jr-currentplan-row">
              <div className="jr-currentplan-col pb-xl-2">
                <h2 className="text-primary jr-fs-xlxl jr-font-weight-medium mb-2 price">
                  {CurrentPlanDashboard.price}
                  <sub
                  className="jr-fs-md"></sub></h2>
                <p className="mb-1"><span className="size-8 bg-dark rounded d-inline-block mr-1"/> Max
                  listing items 2K</p>
                <p><span className="size-8 bg-dark rounded d-inline-block mr-1"/> Max agents - 10</p>
              </div>
              <div className="jr-currentplan-col jr-currentplan-right">
                <div className="jr-currentplan-right-content">
                  <p className="text-red">{CurrentPlanDashboard.validation_date}</p>
                  <a href={`${STORE_URL}/plans/pricing/`}> 
                  <Button variant="contained" className="jr-btn jr-btn-sm bg-success text-white">
                    RENEW PLAN
                  </Button>
                  </a>
                  <span className="text-primary jr-font-weight-medium jr-fs-md pointer jr-oth-plans-down">
                  <a href={`${STORE_URL}/plans/pricing/`}>Other plans </a><i
                    className="zmdi zmdi-long-arrow-right jr-fs-lg ml-2 d-inline-block align-middle"/></span>
                </div>
              </div>
            </div>
          </Widget>
          </div>
          :
          <div className="col-md-3 current-plan blockNotConnect">
          <Widget>
          {/* {"id":"922720ea-b4de-4243-9fa8-d70d1716e228",
          "name":"ENTREPRISE",
          "is_active":true,
          "free_post":null,
          "number_of_user":null,
          "premium_post":null,
          "price":"50.0 USD",
          "plan_category":"Corporate Yearly",
          "validation_date":"Expires in 371 days"} */}
            <h2 className="title">Your Current Plan</h2>
            <div className="jr-currentplan-row">
              <div className="jr-currentplan-col pb-xl-2">
                <h2 className="text-primary jr-fs-xlxl jr-font-weight-medium mb-2">
                  {CurrentPlanDashboard.price}
                  <sub
                  className="jr-fs-md"></sub></h2>
                <p className="mb-1"><span className="size-8 bg-dark rounded d-inline-block mr-1"/> Max
                  listing items 2K</p>
                <p><span className="size-8 bg-dark rounded d-inline-block mr-1"/> Max agents - 10</p>
              </div>
              <div className="jr-currentplan-col jr-currentplan-right">
                <div className="jr-currentplan-right-content">
                  <p className="text-red">{CurrentPlanDashboard.validation_date}</p>
                  <a href={`${STORE_URL}/plans/pricing/`}> 
                  <Button variant="contained" className="jr-btn jr-btn-sm bg-success text-white">
                    RENEW PLAN
                  </Button>
                  </a>
                  <span className="text-primary jr-font-weight-medium jr-fs-md pointer jr-oth-plans-down">
                  <a href={`${STORE_URL}/plans/pricing/`}>Other plans </a><i
                    className="zmdi zmdi-long-arrow-right jr-fs-lg ml-2 d-inline-block align-middle"/></span>
                </div>
              </div>
            </div>
          </Widget>
          </div>
}



{is_user_recruiter?
    
            <div className="row col-12 ScoresDashboard">
            <div className="col-12">
              <h2 class="jr-entry-title d-flex flex-row">Site Visitors Statistics</h2>
            </div>
           <div className="col-md-6 col-12">
       
        <ResponsiveContainer width="100%">
          {ScoresDashboard ? 
            <SalesGauge pages={ScoresDashboard.pages} ScoresDashboard={ScoresDashboard} data={data}/>
          : null}
          </ResponsiveContainer>
        </div>
        <div className="col-md-6 col-12 legende">
          <p>Site Visitors Statistics
            Lorem ipsum is dummy content Cenas in erat accumsan, hendrerit lorem vel, pulvinar odio. Quisque eu conva. hendrerit lorem vel, pulvinar odio. Quisque eu conva.
            <br/></p>
            <p>
            Site Visitors Statistics
          
            Lorem ipsum is dummy content Cenas in erat accumsan, hendrerit lorem vel, pulvinar odio. Quisque eu conva. hendrerit lorem vel, pulvinar odio. Quisque eu conva.
            <br/>
            </p>
            <p>
            Site Visitors Statistics
            Lorem ipsum is dummy content Cenas in erat accumsan, hendrerit lorem vel, pulvinar odio. Quisque eu conva. hendrerit lorem vel, pulvinar odio. Quisque eu conva.
          </p>
        </div>
        </div>
      :
      <div className="row col-12 ScoresDashboard blockNotConnect">
      <div className="col-12">
        <h2 class="jr-entry-title d-flex flex-row">Site Visitors Statistics</h2>
      </div>
     <div className="col-md-6 col-12">
 
  <ResponsiveContainer width="100%">
    {ScoresDashboard.pages ? 
      <SalesGauge pages={ScoresDashboard.pages} competitions={ScoresDashboard.competitions}   opportunities={ScoresDashboard.opportunities}  />
    : <div className="text-center top-20"> <CircularProgress  color="primary"/></div>}
    </ResponsiveContainer>
  </div>
  <div className="col-md-6 col-12 legende">
    <p>Site Visitors Statistics
      Lorem ipsum is dummy content Cenas in erat accumsan, hendrerit lorem vel, pulvinar odio. Quisque eu conva. hendrerit lorem vel, pulvinar odio. Quisque eu conva.
      <br/></p>
      <p>
      Site Visitors Statistics
    
      Lorem ipsum is dummy content Cenas in erat accumsan, hendrerit lorem vel, pulvinar odio. Quisque eu conva. hendrerit lorem vel, pulvinar odio. Quisque eu conva.
      <br/>
      </p>
      <p>
      Site Visitors Statistics
      Lorem ipsum is dummy content Cenas in erat accumsan, hendrerit lorem vel, pulvinar odio. Quisque eu conva. hendrerit lorem vel, pulvinar odio. Quisque eu conva.
    </p>
  </div>
  </div>

    }

</div>









{is_user_recruiter?
      <div className="row">
        <div className="row col-12">
          
          <CardBox styleName="carte-dashboard" cardStyle='mb-0' heading="Site Visitors Statistics" headingStyle="mb-1">
            <SiteVisitor topLocationsDashboard={topLocationsDashboard? topLocationsDashboard : []}/>
          </CardBox>
        </div>
        <CardMenu menuState={menuState} anchorEl={anchorEl}
                  handleRequestClose={handleRequestClose}/>
        </div>:
        <div className="row blockNotConnect">
        <div className="row col-12">
          <CardBox styleName="carte-dashboard" cardStyle='mb-0' heading="Site Visitors Statistics" headingStyle="mb-1">
            <SiteVisitor topLocationsDashboard={topLocationsDashboard? topLocationsDashboard : []}/>
          </CardBox>
        </div>
        <CardMenu menuState={menuState} anchorEl={anchorEl}
                  handleRequestClose={handleRequestClose}/>
        </div>
}  </div>



       {is_user_recruiter? 
        <div className="col-md-3 ">
          <RecentActivity 
          is_user_recruiter={is_user_recruiter} shape="rounded" recentList={RecentActivitiesDashboard ? RecentActivitiesDashboard :{}} />
          
          <div className="nb-view">
            <div className="card jr-card-intra shadow text-center">
              <div className="card-header py-3 d-flex align-items-center">
                <h3 className="mb-0"><IntlMessages id="sidebar.view"/></h3>
                <span className="badge badge-secondary ml-auto"><IntlMessages id="dashboard.monthly"/></span>
              </div>
              <div className="stack-order py-4 px-2">
                <h1 className="chart-f30">{TotalViews}</h1>
                <span className="h3 text-muted"><IntlMessages id="dashboard.totalView"/></span>
                <span className="h5 text-green">
                                    {/* <i className="zmdi zmdi-flash zmdi-hc-fw"/>98% */}
                                    </span>
              </div>
            </div>
          </div>
        </div>
:
<div className="col-md-3 blockNotConnect">
<RecentActivity 
is_user_recruiter={is_user_recruiter}
shape="rounded" recentList={RecentActivitiesDashboard ? RecentActivitiesDashboard :{}} />

<div className="nb-view">
  <div className="card jr-card-intra shadow text-center">
    <div className="card-header py-3 d-flex align-items-center">
      <h3 className="mb-0"><IntlMessages id="sidebar.view"/></h3>
      <span className="badge badge-secondary ml-auto"><IntlMessages id="dashboard.monthly"/></span>
    </div>
    <div className="stack-order py-4 px-2">
      <h1 className="chart-f30">{TotalViews}</h1>
      <span className="h3 text-muted"><IntlMessages id="dashboard.totalView"/></span>
      <span className="h5 text-green">
                          {/* <i className="zmdi zmdi-flash zmdi-hc-fw"/>98% */}
                          </span>
    </div>
  </div>
</div>
</div>

       }
       
        </div>
        
        

        </div>
    )
}




