import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { getFormSuccess, getFormError } from "actions/forms/formsGenerator";
import { Json2Form } from "components/formsElements/Json2Form";

const getFormRequest = async (instance,formId)=>
    await instance.get(`/forms/${formId}/view/`)
    .then(response=>response)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* getForm(actions){
    var REQUIRED=[]; var UISCHEMA={}; var PROPERTIES={};
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(getFormRequest,axiosInstance, actions.formId);
    console.log("FORM-DATA",data)
    if(data.data){
        data.data.fields.forEach(field=>{
                Json2Form(field,[],REQUIRED, UISCHEMA,PROPERTIES)
        }, yield put(getFormSuccess({uiSchema:UISCHEMA, required:REQUIRED, properties:PROPERTIES}))
        )
        
    }else{
        yield put(getFormError(data.error));
    }
    
}

export function* formsGenerator(){
    yield takeEvery("HANDLE_GET_FORM", getForm)
}
export default function* rootSaga() {
    yield all([fork(formsGenerator)]);
}
