import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
  } from "react-router-dom";
  import {Link} from "react-router-dom";
  import { Navbar,Nav,NavDropdown,Form,FormControl } from 'react-bootstrap'
  import Button from '@material-ui/core/Button';
  import Home from '../../containers/Home';
import IntlMessages from "util/IntlMessages";
import CompaniesAll from "components/companies/ListCompanies";

import Opportunities from "containers/opportunities/Opportunities";
import ProfilesAll from "components/profile/ListProfil/ProfilesList";
import {STORE_URL ,ACCOUNT_URL, PAGE_URL} from "config";
import AddOpportunity from 'pages/AddOpportunity';

class MenuPrincipal extends React.Component{

    render(){
        return(
                       
                           <div> <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
                                {/* <Navbar.Brand href="#home">React Bootstrap Navbar</Navbar.Brand> */}
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                <div className="container menu-principal col-md-12">
                                    <Nav className="mr-auto col-md-10">
                                     <Nav className="NavOpportunities col-md-9 col-8">
                                    {/*<a href="/opportunities?FacetCategory=Job">{<IntlMessages id="jobs"/>}</a>
                                    <a href="/opportunities?FacetCategory=Funding">{<IntlMessages id="funding"/>}</a>
                                    <a href="/opportunities?FacetCategory=Training">{<IntlMessages id="training"/>}</a> */}
                                    <NavDropdown title={<IntlMessages id="jobs"/>} id="basic-nav-dropdown">
                                        <NavDropdown.Item href="/opportunities?FacetCategory=Job">{<IntlMessages id="jobs"/>}</NavDropdown.Item>
                                        <NavDropdown.Item href="/opportunities?FacetCategory=Internships">{<IntlMessages id="internships"/>}</NavDropdown.Item>
                                        <NavDropdown.Item href="/opportunities?FacetCategory=volunteerings">{<IntlMessages id="volunteerings"/>}</NavDropdown.Item>
                                         </NavDropdown> 
                                    <NavDropdown title={<IntlMessages id="training"/>} id="basic-nav-dropdown">
                                        <NavDropdown.Item href="/opportunities?FacetCategory=Master Class">{<IntlMessages id="master_class"/>}</NavDropdown.Item>
                                        <NavDropdown.Item href="/opportunities?FacetCategory=Competitions">{<IntlMessages id="competitions"/>}</NavDropdown.Item>
                                        <NavDropdown.Item href="/opportunities?FacetCategory=Online Courses">{<IntlMessages id="online_courses"/>}</NavDropdown.Item>
                                        <NavDropdown.Item href="/opportunities?FacetCategory=Certifications">{<IntlMessages id="certifications"/>}</NavDropdown.Item>
                                        </NavDropdown> 
                                    <NavDropdown title={<IntlMessages id="funding"/>} id="basic-nav-dropdown">
                                        <NavDropdown.Item href="/opportunities?FacetCategory=Startups">{<IntlMessages id="startups"/>}</NavDropdown.Item>
                                        <NavDropdown.Item href="/opportunities?FacetCategory=Grants">{<IntlMessages id="grants"/>}</NavDropdown.Item>
                                        <NavDropdown.Item href="/opportunities?FacetCategory=Scholarships">{<IntlMessages id="scholarships"/>}</NavDropdown.Item>
                                          </NavDropdown> 
                                    <NavDropdown title={<IntlMessages id="events"/>} id="basic-nav-dropdown">
                                        <NavDropdown.Item href="/opportunities?FacetCategory=Webinars">{<IntlMessages id="webinars"/>}</NavDropdown.Item>
                                        <NavDropdown.Item href="/opportunities?FacetCategory=Conferences">{<IntlMessages id="conferences"/>}</NavDropdown.Item>
                                        <NavDropdown.Item href="/opportunities?FacetCategory=Awards">{<IntlMessages id="awards"/>}</NavDropdown.Item>
                                          </NavDropdown> 
                                    <NavDropdown title={<IntlMessages id="fellowships"/>} id="basic-nav-dropdown">
                                        <NavDropdown.Item href="/opportunities?FacetCategory=Fellowships">{<IntlMessages id="fellowships"/>}</NavDropdown.Item>
                                    </NavDropdown> 
                                    </Nav>
                                    <Nav className="NavNetworking  col-md-3 col-4">
                                        <Link to="/profiles">{<IntlMessages id="talents"/>}</Link>
                                        <Link to="/companies">{<IntlMessages id="recruiters"/>}</Link>
                                        
                                    </Nav>
                                    </Nav>
                                    <div className="col-md-2 upgrade">
                                    <AddOpportunity title="add_opportunity"/>
                                     {/* <a href={STORE_URL}><Button variant="outline-success">{<IntlMessages id="post_an_opportunity"/>}</Button></a> */}
                                    </div>
                                    </div>
                                    {/* <Form inline>
                                    <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                                    <Button variant="outline-success">Search</Button>
                                    </Form> */}
                                </Navbar.Collapse>
                            </Navbar></div>
                           

                            // <Switch>
                            //     <Route exact path="/">
                            //     <Home />
                            //     </Route>
                            //     {/* <Route path="/companies">
                            //     <CompaniesAll/>
                            //     </Route>
                            //     <Route path="/profiles">
                            //         <ProfilesAll/>
                            //     </Route> */}
                            //     {/* <Route path="/opportunities?FacetCategory=Job">
                            //         <Opportunities/>
                            //     </Route>
                            //     <Route path="/opportunities?FacetCategory=Fundings">
                            //         <Opportunities/>
                            //     </Route>
                            //     <Route path="/opportunities?FacetCategory=Training">
                            //         <Opportunities/>
                            //     </Route> */}
                                
                            // // </Switch> */}
                        
        )  
    }
}
export default MenuPrincipal;