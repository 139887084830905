import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import CssBaseline from '@material-ui/core/CssBaseline';
import { Field, reduxForm } from 'redux-form';
import { renderImageInput} from 'components/formsElements/FormComponents';
import { Form} from 'react-bootstrap';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import IntlMessages from "util/IntlMessages";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import {Alert} from '@material-ui/lab';
import { EditPhotoCompanyResetMessages } from "actions/companies/editCompany";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const loader=<CircularProgress  color="primary"/>;

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


class EditPhotoCompany extends React.Component {
  constructor(props) {
    super(props);   
    this.handleClickOpen=this.handleClickOpen.bind(this);
    this.handleClose=this.handleClose.bind(this);
    this.state={
    open_edit: false
    }
  }


  handleClose = () => {
    this.setState({ open_edit: false });
    this.props.EditPhotoCompanyResetMessages()
  };
  handleClickOpen(e){
    this.setState({open_edit:true});
    }
  render() {
    // const default_logo=this.props.data.logo?this.props.data.logo:"https://static.wutiko.com/back/static/default/default_wutiko_2x.png";
    const { classes, handleSubmit, serverError } = this.props;
    return (
      <div className="PageEditProfile">
      <Button variant="outlined"  color="primary" className="edit-company photo" onClick={this.handleClickOpen}>
      <PhotoCameraIcon/>
      </Button>
      <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" 
      open={this.state.open_edit} 
      >
        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
        <div className="">
            <IntlMessages id="profile_photo"/>
            </div>
        </DialogTitle>
        <DialogContent>
        {this.props.message?<Alert severity="success" onClose={()=>(this.props.EditPhotoCompanyResetMessages())}>{this.props.message}</Alert>:null}
        {this.props.error?<Alert severity="error" onClose={()=>(this.props.EditPhotoCompanyResetMessages())}>{this.props.error}</Alert>:null}
        {serverError?<Alert severity="error" onClose={()=>(this.props.EditPhotoCompanyResetMessages())}>{serverError}</Alert>:null}
       
        
        <DialogContentText>
       
          <div>
        <div style={{ width: "100%", height:'50%' }}>
        <Form onSubmit={handleSubmit(this.props.SubmitPhoto)}>
      
          <Field  name="photo"  type='file'  component={renderImageInput}/>
      
          <br />
          {/* {this.props.spinner? <img src={logo} alt="wutiko-alt" /> : <button type="submit">{t('wutiko.submit')}</button>} */}
        </Form>
        </div>
        <div>
        <CssBaseline/>
        </div>
        <br style={{ clear: "both" }}/>
      </div>
      </DialogContentText>
        </DialogContent> 
              <DialogActions>
                      {this.props.spinner?loader:<Button type="submit"  className="register-training save pull-rigth" onClick={handleSubmit(this.props.SubmitPhoto)}> <IntlMessages id="save"/></Button>}
              </DialogActions> 
      </Dialog>
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    EditPhotoCompanyResetMessages: () => dispatch(EditPhotoCompanyResetMessages())
  }
  
}

function mapStateToProps(state) {
  return {
    userId: state.users.userSettings.userId,
    axiosInstance: state.users.axiosInstance
  }
}

EditPhotoCompany.propTypes = {
  classes: PropTypes.object.isRequired
};
EditPhotoCompany = reduxForm({
  form: 'EditPhotoCompany',
  enableReinitialize: true
})(withStyles(styles)(EditPhotoCompany));
export default (connect(mapStateToProps, mapDispatchToProps)  (EditPhotoCompany));

