import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { handleFilterMatchingScoreSuccess, handleFilterMatchingScoreError } from "actions/filters/matchingscore";

// 
const setFilterMatchingScore = async (instance,actions)=>
    await instance.get(`/searches/search/pipeline/applicants/matching-score/${actions.search}`,actions)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            return error.response.data
        }
    })

function* FilterMatchingScore(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(setFilterMatchingScore,axiosInstance,actions.payload);
    if(data.results){
            yield put(handleFilterMatchingScoreSuccess(data));
    }else{
           yield put(handleFilterMatchingScoreError(data.error));
    }
    
}

export function* handleFilterMatchingScore(){
    yield takeEvery("HANDLE_GET_MATCHING_SCORE_FILTER", FilterMatchingScore)
}

export default function* rootSaga() {
    yield all([fork(handleFilterMatchingScore)]);
}