import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { EditPhotoCompanySuccess,EditPhotoCompanyError,
    EditCoverPhotoCompanySuccess,EditCoverPhotoCompanyError
} from "actions/companies/editCompany";

const EditPhotoCompanyData = async (instance, slug, data)=>
    await instance.put(`/organizations/${slug}/logo/edit/`, data)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* EditPhotoCompany(actions){
    
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(EditPhotoCompanyData,axiosInstance, actions.data.slug, actions.data.data);
    if(data.message){
        yield put({type:"HANDLE_GET_COMPANY_DETAILS", slug:actions.data.slug})
        yield put(EditPhotoCompanySuccess(data.message));
    }else{
        console.log("data.error", data, data.error)
        yield put(EditPhotoCompanyError(data.error));
    }
}








const EditCoverPhotoCompanyData = async (instance, slug, data)=>
    await instance.put(`/organizations/${slug}/cover-photo/edit/`, data)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* EditCoverPhotoCompany(actions){
    
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(EditCoverPhotoCompanyData,axiosInstance, actions.data.slug, actions.data.data);
    if(data.message){
        yield put({type:"HANDLE_GET_COMPANY_DETAILS", slug:actions.data.slug})
        yield put(EditCoverPhotoCompanySuccess(data.message));
    }else{
        console.log("data.error", data, data.error)
        yield put(EditCoverPhotoCompanyError(data.error));
    }
}
export function* handleEditCoverPhotoCompany(){
    yield takeEvery("HANDLE_EDIT_COVER_PHOTO_COMPANY", EditCoverPhotoCompany)
}






export function* handleEditPhotoCompany(){
    yield takeEvery("HANDLE_EDIT_PHOTO_COMPANY", EditPhotoCompany)
}
export default function* rootSaga() {
    yield all([fork(handleEditPhotoCompany)]);
    yield all([fork(handleEditCoverPhotoCompany)]);
}

