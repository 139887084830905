const initialState = {
    testimonies:{
        error:null,
        spinner:false,
        list:[]
    }
 }

 export function testimonies(state = initialState, action){
 
   switch(action.type) {
   case "HANDLE_GET_TESTIMONIES":
           return {...state, testimonies:{
               ...state.testimonies, error:null, spinner:true, list:[]
           }}
   case "GET_TESTIMONIES_FAILLURE":
           return {...state, testimonies:{
               ...state.testimonies, error:action.payload, spinner:false, list:[]
           }}
   case "GET_TESTIMONIES":
      return {...state, testimonies:{
          ...state.testimonies, error:null, spinner:false, list:action.payload
      }}
     default:
       return state;
   }
 }
