import React, {useState,useEffect} from 'react';
import {SortableElement, SortableHandle} from 'react-sortable-hoc';
import EditOpportunities from 'pages/EditOpportunities';
import {useDispatch, useSelector} from 'react-redux'
import { StatusOpportunityResetMessages, HandleStatusOpportunity } from 'actions/opportunities';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import FontAwesome from 'react-fontawesome';
import { Button } from 'react-bootstrap';
import Notif from '../Notification';
import IntlMessages from 'util/IntlMessages';
import { handleGetOpportunitiesDashboard } from 'actions/dashboard/keysList';

const DragHandle = SortableHandle(() =>
  <i className="zmdi zmdi-menu draggable-icon" style={{fontSize: 25}}/>);

const ItemOpportunities = (props) => {
  const status_action =useSelector(state=>state.opportunities.statusOpportunity)
  const error =useSelector(state=>state.opportunities.statusOpportunity.error)
  const message =useSelector(state=>state.opportunities.statusOpportunity.message)
  const [anchorEl, setAnchorEl] = useState();
  const [menuState, setMenuState] = useState();
  const [open, setOpen]=React.useState(false)
  const onContactOptionSelect = event => {
    setMenuState(true);
    setAnchorEl(event.currentTarget);
  };
  const handleRequestClose = () => {
    setMenuState(false);
  };

  const {contact, OpportunitiesDashboard, all } = props;
  
    const {title,opportunity_type,id,publication_status,slug,total_views,total_applications,pipelined, start_date, starred} = contact;
    const options = [
      'Edit',
      'Delete',
    ];
    const deactivateData={
      action:"deactivate",
      opportunity_id:id? id : null
    }
    const activateData={
      action:"activate",
      opportunity_id:id? id : null
    }
    const reactivateData={
      action:"reactivate",
      opportunity_id:id? id : null
    }
    const removeData={
      action:"remove",
      opportunity_id:slug? slug : null
    }
    const [publicationStatus, setPublicationStatus]=React.useState(publication_status === 1? "success":"warning")
    const HandleActionOpportunity=(Data)=>{
      dispatch(HandleStatusOpportunity(Data))
      
      message?
       publicationStatus === "warning"?
        setPublicationStatus("success"):setPublicationStatus("warning")
        :
        setPublicationStatus("test")
        dispatch(StatusOpportunityResetMessages())
    }
     const HandleActionOpportunityReload=(Data)=>{
      dispatch(HandleStatusOpportunity(Data))
      dispatch(handleGetOpportunitiesDashboard({scope:"opportunity",search:props.search, page:'1'}));
    }
   
    const HandleActionOpportunityDelete=(removeData)=>{
      dispatch(HandleStatusOpportunity(removeData))
      dispatch(handleGetOpportunitiesDashboard({scope:"opportunity",search:props.search, page:'1'}))
    }
    const handleClose=()=>{
      setOpen(false)
      dispatch(StatusOpportunityResetMessages())
      }
    const dispatch = useDispatch();
  
        //console.log("Organ", companiesData)
    return (
      <div className={`col-12
       roww contact-item ripple  no-gutters align-items-center py-2 px-3 py-sm-4 px-sm-6
       ${publication_status===4? 
       'subject text-muted text-strikethrough':
      'online'}`}>
        <Notif open={open} title={<IntlMessages id="notification"/>} text={
        status_action.error?
        status_action.error :
        status_action.message?status_action.message:null}
        notification="notification"
        error={status_action.error? status_action.error : null}
        message={status_action.message ? "message" : null}
        handleClose={handleClose}/>
       {/* <DragHandle/> */}
          <div className="col-md-6 text-truncate font-weight-bold" style={{marginLeft: 10}}>
          
          <a  href={`/opportunity/${slug}`}> 
          <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={title}
                    secondary={
                      <React.Fragment>
                          <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary">
                         <small>{opportunity_type}</small>
                        </Typography> <br/> 
                        <Typography 
                          component="span" 
                          variant="body2" 
                          color="textPrimary">
                          <small>{start_date}</small>
                          </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem></a>
          </div> 

         {/* <div className="col-md-2  text-truncate px-1 d-none d-lg-flex">
          {opportunity_type}
          </div> */}
         <div className="col-md-2  text-center">
          {total_views}
        </div>

        <div className="col-md-2 text-center">
          {total_applications}
        </div>

        {/* <div className="col job-title text-truncate px-1 d-none d-sm-flex">
          {pipelined}
        </div> 
        <div className="col job-title text-truncate px-1 d-none d-sm-flex">
          {start_date}
        </div>  */}
        <div className="col job-title  text-truncate px-1">
        
              


              {all? 

                <Button 
                className={`btn ${publicationStatus}`}
                bsStyle={publicationStatus}
                onClick={()=>
                publication_status === 1 ?
                HandleActionOpportunity(deactivateData)
                :
                HandleActionOpportunity(activateData)}
                >
                <FontAwesome name='power-off' />

                </Button>
                :
              <Button 
                className={`btn ${publicationStatus}`}
                bsStyle={publicationStatus}
                onClick={()=>
                publication_status === 4 ?
                HandleActionOpportunityReload(reactivateData)
                :
                HandleActionOpportunityReload(activateData)}
              >
                <FontAwesome name='power-off' />
              </Button> 
              
              }     
               {/* <e onClick={()=> HandleActionOpportunityDelete(removeData)}>
                  <IconButton  
                  >
                            <DeleteIcon className="green_color" color="#28a745;"/>
                  </IconButton>
                  </e> */}

          <EditOpportunities 
          itemDetailsOpportunity={contact}
          icon="icon"
          search={props.search}
          page='1'
          slug={slug} />
        </div> 

        {/* <div className="col-auto actions">
          <IconButton onClick={() => {
          }}>
            {starred ? <i className="zmdi zmdi-star"/> : <i className="zmdi zmdi-star-outline"/>}
          </IconButton>

          <IconButton onClick={onContactOptionSelect}>
            <i className="zmdi zmdi-more-vert"/>
          </IconButton>

          <Menu id="long-menu"
                anchorEl={anchorEl}
                open={menuState}
                onClose={handleRequestClose}

                MenuListProps={{
                  style: {
                    width: 100,
                  },
                }}>
            {options.map(option =>
              <MenuItem key={option} onClick={() => {
                handleRequestClose();
              }
              }>
                {option}
              </MenuItem>,
            )}
          </Menu>
        </div> */}
      </div>
    )
};

export default SortableElement(ItemOpportunities);
