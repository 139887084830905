import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { CLIENT_ID } from "config";
import { ApplyCompanySuccess, ApplyCompanyError} from "actions/companies/getCompanies";

const ApplyCompany = async (instance, companyId)=>
    await instance.post(`/organizations/${companyId}/applications/spontaneous/add/`, {})
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })
function* ApplyCompanyAction(actions){
        const state = yield select((state)=>state.users);
        let axiosInstance;
        if(state.authenticated){
            axiosInstance=state.axiosInstance
        }else{
            axiosInstance=state.blankAxiosInstance
        }
        const data =yield call(ApplyCompany,axiosInstance, actions.data.companyId);
        if(data.message){
            yield put({type:"HANDLE_GET_COMPANY_DETAILS", slug:actions.data.slug})
            yield put(ApplyCompanySuccess(data.message));
        }else{
            console.log("data.error", data, data.error)
            yield put(ApplyCompanyError(data.error));
        }
        
    }
function* HandleApplyCompany(){
    yield takeEvery("HANDLE_APPLY_COMPANY", ApplyCompanyAction)
}
export default function* rootSaga() {
    yield all([fork(HandleApplyCompany)]);
}