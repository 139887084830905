import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { AddPublicationSuccess,AddPublicationError} from "actions/profile";

const AddPublicationData = async (instance,data)=>
    await instance.post(`/profiles/publications/add/`, data)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* AddPublication(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(AddPublicationData,axiosInstance, actions.data);
    if(data.message){

        yield put(AddPublicationSuccess(data.message));
        yield put({type:"HANDLE_GET_PROFILE_PUBLICATION"})
    }else{
        console.log("data.error", data, data.error)
        yield put(AddPublicationError(data.error));
    }
}
export function* handleAddPublication(){
    yield takeEvery("HANDLE_ADD_PUBLICATION", AddPublication)
    console.log("Publication", "OK")
}
export default function* rootSaga() {
    yield all([fork(handleAddPublication)]);
}

