import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Link} from "react-router-dom";
import {Animated} from "react-animated-css";
import Button from '@material-ui/core/Button';
import IntlMessages from "util/IntlMessages";

const useStyles = makeStyles((theme) => ({
  ul:{
    'list-style-type': 'none',
    paddingLeft: 0,

  },
  btn:{
    position:'absolute',
    left:'42%',
    backgroundColor:"#fdc000",
    '&:hover':{
      backgroundColor:"#fdc000",
    }
  },
 cardActions:{
      paddingLeft:15,
      paddingRight:15
  },
  avatar:{
    width: 80,
    height: 80,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius:'0%',
    marginLeft:'-15px',
  }, 
  flexEnd:{
      paddingLeft:'28%',
  },
  icon:{
      width:15,
      height:15
  },
  icons:{
    width:15,
    height:15,
    border:"solid 1px #c1c1c1"
  },
  link:{
    textDecoration:'none',
    color:'#4285f4',
    '&:hover':{
      textDecoration:'none'
    }
  
}
}));
export default function ProfilesByCompany(props) {
  const classes = useStyles();
  const data =props.data
  return (
 
    <Animated animationIn="fadeInUp" animationOut="fadeOut">
       <Card className="card-profile company">
            <CardActions className={classes.cardActions}>
            <Grid container className="profiles">
        
            <Grid item xs={2}>
                <ListItemAvatar> 
                    <Avatar alt="Travis Howard" src={data.photo}  className={classes.avatar}/>
                    </ListItemAvatar>
                        </Grid>


            <Grid item xs={8}>
                     <Link to={`/profiles/${data.slug}`} className="title-opportunity text-left">{data.first_name} {data.last_name}</Link>
                     <div className="col-md-12 padding-0 block-profil">
                   <span className="title">{data.title}</span>
                </div>
                <span className="current_position">{data.current_position ? data.current_position : "Wutiko" }</span><br/>
                <span className="profile-location">{data.country_name} {data.city_name ?  " - "+data.city_name  : null}</span>
                
            </Grid>    
                <Grid xs={2} md={2} container className="footer-bloc-profile">
                <div className="col-md-12 text-right  padding-0">
                <Button className="btn-profile text-right">{<IntlMessages id="connect" />} </Button>
                </div>
                </Grid>
                {/* <Grid item xs={12} className="btn-profile text-right"><Button>Voir Profil</Button></Grid> */}
                {/*                 
                <Grid className="date-publication" item xs={12} container justify="flex-start"><AccessTimeIcon  className={classes.icon}/>{data.is_connected ? null : "Connecter" }</Grid>
                 */}
                </Grid>
            </CardActions>
        </Card>
        </Animated>
  );
}

ProfilesByCompany.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
