import React,{useState, useEffect}from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {useDispatch} from 'react-redux'
import { splitStringQueryAddState } from 'util/UtilFunctions';
import {useHistory} from "react-router-dom";
import FilterCompanies from './Companies';
import Locations from './Location';
import FilterOpportunites from "./Opportunites";
import FilterExperience from "./Experience";
import Chip from '@material-ui/core/Chip';
import IntlMessages from "util/IntlMessages";
import { handleFilterCompany } from 'actions/filters/company';
import { handleGetApplicationsDashboard, handleGetApplicationsRecommendationsDashboard, handleGetApplicationsSharedDashboard, handleGetOpportunitiesDashboard, handleGetOpportunitiesDeleteDashboard, handleGetOpportunitiesOfflineDashboard, handleGetOpportunitiesOnlineDashboard } from 'actions/dashboard/keysList';
import { handleAdminLocationsSearch } from 'actions/filters/locations';
import FilterListIcon from '@material-ui/icons/FilterList';
import { handleFilterOpportunity } from 'actions/filters/opportunities';
import { handleFilterExperience } from 'actions/filters/experience';
import FilterDate from './date';
import FilterAssessed from './Assessed';
import  FilterMatchingScore  from './matchingscore';
import { handleFilterDate } from 'actions/filters/date';
import { handleFilterAssessed } from 'actions/filters/assessed';
import { handleFilterMatchingScore } from 'actions/filters/matchingscore';
import { handleGetSpontaneousApplyDashboardPipeline } from 'actions/dashboard/profil';


export default function FilterPipelineAdmin(props) {
  const [keys, setState]=useState([]);
  const dispatch = useDispatch()
  const browserHistory= useHistory();
  const constructUrl=()=>{
    var url ="";
    keys.map((item)=>{
        if(!url){
            return url+=`${item.type}=${item.name}`;
        }else{
            return url+=`&${item.type}=${item.name}`
        }
    })
    return url   
    }

  const handleDelete=(key)=>{
   setState([...keys.filter(obj=>obj.name!==key.name)]);
   setTimeout(
    ()=>{
      browserHistory.push(`${window.location.pathname}?${constructUrl()}`);
      dispatch(handleFilterCompany({scope:"organization", search:`?${constructUrl()}`}))
      dispatch(handleFilterOpportunity({scope:"opportunity", search:`?${constructUrl()}`}))
      dispatch(handleFilterExperience({scope:"experience", search:`?${constructUrl()}`}))
      dispatch(handleAdminLocationsSearch({scope:"countries", search:`?country=${window.location.search}`, page:`1`}))   
      dispatch(handleFilterMatchingScore({scope:"matching_score", search:`${window.location.search}`, page:`1`}))
      dispatch(handleFilterDate({scope:"time_threshold", search:`${window.location.search}`, page:`1`}))
      dispatch(handleFilterAssessed({scope:"assessed", search:`${window.location.search}`, page:`1`}))  
      dispatch(handleGetOpportunitiesDashboard({scope:"organization", search:`${window.location.search}`, page:`1`}))
      dispatch(handleGetOpportunitiesOnlineDashboard({scope:"organization", search:`${window.location.search}`, page:`1`}))     
      dispatch(handleGetOpportunitiesOfflineDashboard({scope:"organization", search:`${window.location.search}`, page:`1`}))     
      dispatch(handleGetOpportunitiesDeleteDashboard({scope:"organization", search:`${window.location.search}`, page:`1`}))         
      dispatch(handleGetApplicationsDashboard({scope:"organization", search:`${window.location.search}`, page:`1`}))  
      dispatch(handleGetApplicationsSharedDashboard({scope:"organization", search:`${window.location.search}`, page:`1`}))  
      dispatch(handleGetSpontaneousApplyDashboardPipeline({scope:"organization", search:`${window.location.search}`, page:`1`}))  
      dispatch(handleGetApplicationsRecommendationsDashboard({scope:"organization", search:`${window.location.search}`, page:`1`}))        

    },80
  )
  }
  //
  const addItem=(item)=>{
    setState([...keys.filter(obj=>obj.name!==item.name), item])
    setTimeout(
      ()=>{
        browserHistory.push(`${window.location.pathname}?${constructUrl()}`);
        dispatch(handleFilterCompany({scope:"organization", search:`?${constructUrl()}`}))
        dispatch(handleFilterOpportunity({scope:"opportunity", search:`?${window.location.search}`}))
        dispatch(handleFilterExperience({scope:"experience", search:`?${constructUrl()}`}))
        dispatch(handleFilterMatchingScore({scope:"organization", search:`${window.location.search}`, page:`1`}))
        dispatch(handleFilterDate({scope:"organization", search:`${window.location.search}`, page:`1`}))
        dispatch(handleFilterAssessed({scope:"organization", search:`${window.location.search}`, page:`1`}))  
        dispatch(handleAdminLocationsSearch({scope:"countries", search:`?country=${window.location.search}`, page:`1`}))   
        dispatch(handleGetOpportunitiesDashboard({scope:"organization", search:`${window.location.search}`, page:`1`}))     
        dispatch(handleGetOpportunitiesOnlineDashboard({scope:"organization", search:`${window.location.search}`, page:`1`}))     
        dispatch(handleGetOpportunitiesOfflineDashboard({scope:"organization", search:`${window.location.search}`, page:`1`}))     
        dispatch(handleGetOpportunitiesDeleteDashboard({scope:"organization", search:`${window.location.search}`, page:`1`}))  
        dispatch(handleGetApplicationsDashboard({scope:"organization", search:`${window.location.search}`, page:`1`}))  
        dispatch(handleGetApplicationsSharedDashboard({scope:"organization", search:`${window.location.search}`, page:`1`}))  
        dispatch(handleGetSpontaneousApplyDashboardPipeline({scope:"organization", search:`${window.location.search}`, page:`1`}))  
        dispatch(handleGetApplicationsRecommendationsDashboard({scope:"organization", search:`${window.location.search}`, page:`1`}))        
    },80
    )
  }
  useEffect(()=>{
    if(keys.length==0){
      setState(splitStringQueryAddState(window.location.search));
    }
  },[setState])

  return (
      <Grid container  className="col-md-12 bloc-filter-admin">
        <Grid item md={1} xs={1} className="icone">
        <FilterListIcon/> 
        </Grid>
        <Grid item md={2} xs={4}>
          <FilterCompanies scope="organization" addItem={addItem} delete={handleDelete} keys={keys}/>
        </Grid>
         <Grid item md={2} xs={4}>
          <FilterOpportunites scope="organization" addItem={addItem} delete={handleDelete} keys={keys}/>
        </Grid>
        <Grid item md={2} xs={4}>
          <FilterExperience scope="organization" addItem={addItem} delete={handleDelete} keys={keys}/>
        </Grid>
        <Grid item md={2} xs={4}>
          <FilterDate scope="organization" addItem={addItem} delete={handleDelete} keys={keys}/>
        </Grid>
        <Grid item md={2} xs={4}>
          <FilterAssessed scope="organization" addItem={addItem} delete={handleDelete} keys={keys}/>
        </Grid>
        <Grid item md={2} xs={4}>
          <FilterMatchingScore scope="organization" addItem={addItem} delete={handleDelete} keys={keys}/>
        </Grid>
        <Grid item md={2} xs={4}>
            <Locations scope="organization" addItem={addItem} delete={handleDelete} keys={keys}/>
        </Grid>
        
        <Grid item xs={12}>
          {
            keys.map((item)=>{
              return(
                <Chip label={item.name} 
                 onDelete={()=>handleDelete(item)}
                />
              )
            })
          }
        </Grid>
      </Grid>
  );
}

FilterPipelineAdmin.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
