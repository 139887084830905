import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import InputAdornment from '@material-ui/core/InputAdornment'

import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '@material-ui/core';
import { FieldStyle } from './FormsElementsStyles';
/**
 * This component define field's structure, used for constructing a form.
 * Props are sent to this components (fieldId, nextFieldId, prevFieldId, ...)
 * for allowing the user to perform  some actions on it (remove, push up, push down,...) and for displaying 
 * some informations related to the field(type,...)
 */
export default function FieldStructure(props) {
  const classes =FieldStyle();
  const {id,numberAllFields,
    onChangeValueBool, 
    onChangeValue,nextFieldId, 
    prevFieldId, pushDown, pushUp,field,
    hanRemoveField,handleAddOption, 
    handleRemoveOption,fieldId,fieldIcon,onChangeChoiceValue}=props
  return (
        <Card className={classes.card}>
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={11}>
                    <TextField name="label" value={field.label} fullWidth label="Label" onChange={(e)=>onChangeValue(e,fieldId)}/>
                </Grid>
                 <Grid item xs={1}>
                    <Fab  aria-label="Add" className="ranking_content pull-right">
                       {id}
                    </Fab>
                 </Grid>
                 <Grid item xs={6}>
                     <TextField name="default_value" fullWidth label="Default value" value={field.default_value} onChange={(e)=>onChangeValue(e,fieldId)}/>
                 </Grid>
                 <Grid item xs={6}>
                     <TextField name="placeholder" fullWidth label="Placeholder" value={field.placeholder} onChange={(e)=>onChangeValue(e,fieldId)}/>
                 </Grid>
                 <Grid item xs={12}>
                     <TextField name="help_text" fullWidth label="Help text" value={field.help_text} onChange={(e)=>onChangeValue(e,fieldId)}/>
                 </Grid>
                 <Grid item xs={12}>
                     <TextField name="description" fullWidth label="Description" value={field.description} onChange={(e)=>onChangeValue(e,fieldId)}/>
                 </Grid>
                 <Grid item xs={6}>
                     <TextField name="style_class" fullWidth label="Style" value={field.style_class} onChange={(e)=>onChangeValue(e,fieldId)}/>
                 </Grid>
                 <Grid item xs={6} container justify="flex-end">
                    <FormControlLabel label="Is required" control={<Checkbox name="required" checked={field.required} onClick={(e)=>onChangeValueBool(e,fieldId)}/>} />
                    <FormControlLabel label="Is visible" control={<Checkbox name="is_visible" checked={field.is_visible} value={field.is_visible} onClick={(e)=>onChangeValueBool(e,fieldId)} />} />
                 </Grid>
                 <Grid item xs={12}>
                     {   
                        field.choices?
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                {
                                Object.keys(field.choices).map(item=>{
                                        return(
                                            <TextField fullWidth name={item} value={field.choices[item]} label={field.choices[item]}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                        <IconButton>
                                                            <ClearIcon onClick={()=>handleRemoveOption(fieldId,item)}/>
                                                        </IconButton>  
                                                </InputAdornment>,
                                                }}
                                                onChange={(e)=>onChangeChoiceValue(e, fieldId, item)}
                                            />
                                        )
                                    })                                    
                                }
                            </Grid>
                            <Grid item xs={8} container justify="flex-end">
                                <Button constiant="contained" color="primary" onClick={(e)=>handleAddOption(e,fieldId)}>Add option</Button>
                            </Grid>
                        </Grid>
                        :null
                     }
                 </Grid>
                 <Grid item xs={6}>
                    <IconButton className={classes.remove} color="secondary">
                        <DeleteIcon color="#e40c0c" style={{color:'#e40c0c'}} onClick={()=>hanRemoveField(fieldId)}/>
                    </IconButton>                          
                     <IconButton color="primary">
                        {fieldIcon}
                    </IconButton>
                 </Grid>
                 <Grid item container justify="flex-end" xs={6}>
                 {numberAllFields!==id?
                    <IconButton>
                        <ArrowDownward onClick={()=>pushUp(prevFieldId,fieldId,nextFieldId)}/>
                    </IconButton>                     
                 :null}
                 {id!==1?
                  <IconButton>
                    <ArrowUpward onClick={()=>pushDown(prevFieldId,fieldId,nextFieldId)}/>
                  </IconButton>  
                 :null}   
                 </Grid>
            </Grid>
        </CardContent>
      </Card>
        );
    }



