import React, {useState, useEffect} from 'react';
import IconButton from '@material-ui/core/IconButton'

import {useDispatch, useSelector} from 'react-redux'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {SortableElement, SortableHandle} from 'react-sortable-hoc';
import { Link } from 'react-router-dom';
import { handleGetEditCompanyDetails } from 'actions/companies/editCompany';
import EditCompanyPage from 'pages/CompanyFormEdit';
const DragHandle = SortableHandle(() =>
  <i className="zmdi zmdi-menu draggable-icon" style={{fontSize: 25}}/>);

const ItemOrganization = (props) => {

  const [anchorEl, setAnchorEl] = useState();
  const [menuState, setMenuState] = useState();

  const onContactOptionSelect = event => {
    setMenuState(true);
    setAnchorEl(event.currentTarget);
  };
  const handleRequestClose = () => {
    setMenuState(false);
  };


  
  const {contact} = props;
  console.log("TestContact", contact)

  //const itemDetailsCompany = useSelector(state=>state.company.get_edit_company.details)
    const {item, thumb,starred} = props;
    //console.log("itemDetailsCompany text", itemDetailsCompany)
    const dispatch = useDispatch();
    // useEffect(()=>{
    //     dispatch(handleGetEditCompanyDetails(item.slug));
    //   },[dispatch])
      
    const options = [
        'Edit',
      'Delete',
    ];
    return (
      <div className="col-12 contact-item ripple row no-gutters align-items-center py-2 px-3 py-sm-4 px-sm-6">
                     
        {/* <DragHandle/> */}
        
        <a href={`/companies/${item.slug}`}><img className="rounded-circle size-40 mx-4" alt={item.name} src={item.logo}/></a>
         
        <div className="col text-truncate font-weight-bold"> <Link to={`/companies/${item.slug}`}>{item.name}</Link></div>

        <div className="col  text-truncate text-right">

                        {item?
                          <EditCompanyPage slug={item.slug}  
                            itemDetailsCompany={item? item : item }/>
                           :null}
                           </div>  
{/* {`${STORE_URL}/plans/pricing/`}
       <div className="col email text-truncate px-1 d-none d-lg-flex">
          {opportunity.title}
        </div>

        <div className="col phone text-truncate px-1 d-none d-md-flex">
          {matching}
        </div>

        <div className="col job-title text-truncate px-1 d-none d-sm-flex">
          {date_of_application}
        </div>  */}


        {/* <div className="col-auto actions">
          <IconButton onClick={() => {
          }}>
            {starred ? <i className="zmdi zmdi-star"/> : <i className="zmdi zmdi-star-outline"/>}
          </IconButton>

          <IconButton onClick={onContactOptionSelect}>
            <i className="zmdi zmdi-more-vert"/>
          </IconButton>

          <Menu id="long-menu organization"
                anchorEl={anchorEl}
                open={menuState}
                onClose={handleRequestClose}

                MenuListProps={{
                  style: {
                    width: 100,
                  },
                }}>
            {options.map(option =>
              <MenuItem key={option} onClick={() => {
                handleRequestClose();
              }
              }>
                {option}
              </MenuItem>,
            )}
          </Menu>
        </div> */}
      </div>
    )
};

export default SortableElement(ItemOrganization);
