import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import {  Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getItem } from '../../../actions/index';
import Chip from '@material-ui/core/Chip';
import { withTranslation } from 'react-i18next';
import { handleGetProfileAreas } from '../../../actions/profile';
import React,{useEffect, useState} from 'react';
import { useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import IntlMessages from "util/IntlMessages"; 
const DEFAULT_NUMBER_AREA_DISPLAYED = 3;


export default function ProfileAreasPublic({props, t,handleSubmit }) {
//const {userId} =useParams();
const state =useSelector((state)=>state)
const areas = useSelector(state=>state.profile.profile_areas.list);

const  nArea = DEFAULT_NUMBER_AREA_DISPLAYED;

const dispatch = useDispatch();

 const  showAll = ()=>{
    this.setState({ nArea: areas.length })
  }

  const hideAll = ()=>{
    this.setState({ nArea: DEFAULT_NUMBER_AREA_DISPLAYED })
  }

// const componentDidMount = ()=>{
//     const url = `/profiles/${this.props.userId}/areaofinterests/list/`
//     this.props.getItem(url,`GET_PROFILE_AREAS`);
    
//   }

const handleRemoveArea = (areaId)=>{
    const axiosInstance = this.props.axiosInstance;
    const url =`/profiles/${this.props.userId}/areaofinterests/${areaId}/remove/`;

    axiosInstance.put(url)
    .then((response) => {
      console.log("removeArea response : ",response);
      const url2 = `/profiles/${this.props.userId}/areaofinterests/list/`
      this.props.getItem(url2, `GET_PROFILE_AREAS`);
    })
    .catch((error) => {
      console.log("removeArea error : ", error);
    })
  }

  const renderListArea = (n)=>{
    this.props.itemDetails.slice(0,n).map((area) => {
      return(
        <Chip label={area.profile_area_of_interest_id.name} onDelete={e => this.handleRemoveArea(area.id)} color="#0066b4" variant="outlined"/>
      )
    })
    console.log("renderListArea", renderListArea);
  }
// const renderListArea = (n)=>{
//   {areas?areas.slice(0,n).map(
//     item=>item.industry_id?item.industry_id.name:''
//   ).toString():null}}

    return (
        
        <div className=" col-md-12 bloc content-page-interne areas">
              <div className="bloc content-page-interne skill"  data-aos="fade-up">

                  <div className="row">
                      <div className="col-md-6 col-11"><h6  className="pull-left">{<IntlMessages id="areas"/>}</h6></div>
                      <div className="col-md-6">
                          {/* <Link  to='/networking/profile/add-areas/'  className="industry btn pull-right col-md-6">
                              <i className="fa fa-plus f-5x skills pull-right add_area_new_style"></i> 
                          </Link> */}
                      </div>
                  </div>
                  <div className="row area"  data-aos="fade-up">
                      <div className="col-md-12 col-12">
                        {this.props.itemDetails.slice().map((area) => {
                            return(
                                <Chip label={area.profile_area_of_interest_id.name}  color="#0066b4" variant="outlined"/>
                            )
                            })}
                      {/* {renderListArea ? renderListArea(areas.length) : null}
                           { renderListArea ? renderListArea(nArea) : null}
                            {areas.length > 3 ?
                                nArea === DEFAULT_NUMBER_AREA_DISPLAYED ?
                                  <Button bsStyle="link" onClick={this.showAll} className="neutre">{t('wutiko.see_all')}</Button>
                                :
                                  <Button bsStyle="link" onClick={this.hideAll} className="neutre">{t('wutiko.hide')}</Button>
                                :
                              null}   */}
                    </div>
                </div>
          </div>
          
          {/* <div className="bloc content-page-interne skill">
                  <div className="row">
                      <div className="col-md-6 col-6"><h6  className="pull-left">{t("wutiko.skill")}</h6></div>
                  </div>
                  <div className="area">
                    <div className="col-md-12 col-12">
                        <div className="skill-endorsed">
                            <div className="col-md-12 col-12">
                                {renderListArea ? renderListArea(areas.length) : null}
                            </div>
                        </div>
                    </div>
                  </div>
          </div> */}
          </div>
   );
  }

// function mapStateToProps(state) {
//   return {
//     // itemDetails:[],
//     itemDetails: state.profile.areas.profile_areas,
//     userId: state.user.userId,
//     axiosInstance: state.user.axiosInstance,
//   }
// }
ProfileAreasPublic = withTranslation('translation')(ProfileAreasPublic);

/*
<div>
  {this.props.myProfile ?
      <div className="bloc content-page-interne skill"  data-aos="fade-up">

          <div className="row">
              <div className="col-md-6 col-11"><h6  className="pull-left">{t("wutiko.areas")}</h6></div>
              <div className="col-md-6">
                  <Link  to='/networking/profile/add-areas/'  className="industry btn pull-right col-md-6">
                      <i className="fa fa-plus f-5x skills pull-right"></i> 
                  </Link>
              </div>
          </div>
          <div className="row area"  data-aos="fade-up">
              <div className="col-md-12 col-12">
                    {areas ? this.renderListArea(this.state.nArea) : null}
                    {areas.length > 3 ?
                        this.state.nArea === DEFAULT_NUMBER_AREA_DISPLAYED ?
                          <Button bsStyle="link" onClick={this.showAll} className="neutre">{t('wutiko.see_all')}</Button>
                        :
                          <Button bsStyle="link" onClick={this.hideAll} className="neutre">{t('wutiko.hide')}</Button>
                        :
                      null} 
            </div>
        </div>
  </div>
  :
  <div className="bloc content-page-interne skill">
          <div className="row">
              <div className="col-md-6 col-6"><h6  className="pull-left">{t("wutiko.skill")}</h6></div>
          </div>
          <div className="area">
            <div className="col-md-12 col-12">
                <div className="skill-endorsed">
                    <div className="col-md-12 col-12">
                        {areas ? this.renderListArea(areas.length) : null}
                    </div>
                </div>
            </div>
          </div>
  </div>}
  </div>
*/

/*
<div>
  {this.props.myProfile ?
      <div className="bloc content-page-interne skills"  data-aos="fade-up">

          <div className="row">
              <div className="col-md-6 col-6"><h6  className="pull-left">{t("wutiko.skills")}</h6></div>
        
                <div className="col-md-6 col-6">
                <div className="pull-right">
                </div>
            </div>
            </div>
            <div className="skill"  data-aos="fade-up">
                <div className="col-md-12 col-12">

              <div className="skills-endorsed"  data-aos="fade-up">
              
                <div className="row">
                <h6 className="title col-md-6">{t("wutiko.other_skills_not_endorsed")} </h6>
                <Link  to='/networking/profile/add-skills/'  className="pull-right industry btn col-md-6">
                  <i className="fa fa-plus f-5x"></i>
                </Link>
                </div>
                <div className="col-md-12 col-12">
  
          </div>
          </div>


        <div className="skills-endorsed"  data-aos="fade-up">
              


            <div className="row">
                  <h6 className="title col-md-6">{t("wutiko.industries")} </h6>
                  
                <Link  to='/networking/profile/add-industries'  className="pull-right industry btn col-md-6">
                  <i className="fa fa-plus f-5x"></i>
                </Link>
                </div>
          <div className="col-md-12 col-12">


          </div>
          </div>
          </div>
          </div>
    </div>
    :
    <div className="bloc content-page-interne skills">
            <div className="row">
                <div className="col-md-6 col-6"><h6  className="pull-left">{t("wutiko.skills")}</h6></div>
            </div>
            <div className="skill">
                <div className="col-md-12 col-12">

              <div className="skills-endorsed">
                <h6 className="title col-md-12 col-12">{t("wutiko.other_skills_not_endorsed")}</h6>
                <div className="col-md-12 col-12">
            
          </div>
          </div>


        <div className="skills-endorsed">
          <h6 className="title col-md-12 col-12">{t("wutiko.industry")} </h6>
          <div className="col-md-12 col-12">

          </div>
          </div>
          </div>
          </div>
    </div>}</div>
*/