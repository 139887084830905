import React,{useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Col, Form,  Row} from 'react-bootstrap';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useDispatch, useSelector} from 'react-redux'
import { handleSearchIndustries } from 'actions/search';
import CircularProgress from '@material-ui/core/CircularProgress';
import { handleResetcreateCompany } from 'actions/companies/createCompany';
import {Alert} from '@material-ui/lab';
import IntlMessages from "util/IntlMessages";
import { LocationSearchInput } from 'components/formsElements/FieldsComponents';
import {RichTextMarkdown} from '../formsElements/FormComponents';
import NativeSelect from '@material-ui/core/NativeSelect';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MomentUtils from "@date-io/moment";
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
const dateFormatter = str => {
  return str;
};


const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    title: {
    //   marginLeft: theme.spacing(2),
    //   flex: 1,
    },
    paper:{
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        marginLeft: 15,
        marginRight: 15,
        [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(2),
        },
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    content:{
        padding:15,
        paddingBottom:30,
        overflowY:"hidden"
    },
    paper:{
        backgroundColor:'#e0e0e0'
    },
    upload:{
        width:80,
        height:80
    }
  }));
const initState={
    name:'',
    // first_name:'',
    // last_name:'',
    // primary_email:'',
    // phone:''

}
const CreateCompany = (props) =>{
  const {t} = props;
  const classes = useStyles();
  const dispatch =useDispatch();
  const searchData = useSelector(state=>state.search.industries.list)
  const ccompany= useSelector(state=>state.createCompany.create_company)
  const isAuthenticated = useSelector((state)=> state.users.authenticated)
  const [selectedDate, setSelectedDate] = React.useState("2012")
  const [tags, setTags] = useState([])
  const [locations, setLocations]= useState([]);
  const [fieldsValues, setValue] =useState(initState);
  const [organization_category, setOrganization_category] = React.useState("");
   const [description, setDescription] = React.useState("");
 
  const handleChangeCategory = (event) => {
    setOrganization_category(event.target.value);
  };

  const handleSubmit=(event)=>{
      event.preventDefault();
      dispatch({type:"HANDLE_CREATE_COMPANY", data:{
        ...fieldsValues,
      }});
       
      
  }
  const handleAddLocation=(item)=>{
    setLocations([...locations.filter(obj=>obj.place_id!==item.place_id), item])
}
  const handleSearchChange=(event)=>{
    dispatch(handleSearchIndustries({q:event.target.value}))
  }
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleSelect=(test,elt, reason)=>{
    setTags(elt);
  }
  const handleChanges=(event)=>{
      setValue({...fieldsValues, [event.target.name]:event.target.value})
  }
  const loader=<CircularProgress  color="primary"/>;
  
  const handleChangeDescription = (event) => {
    setDescription(event.toString('html'));
  };
  const handleChangeCheckbox = (event) => {
    setValue({ ...fieldsValues, [event.target.name]: event.target.checked });
  };

  
  useEffect(()=>{
     dispatch(handleSearchIndustries({q:""}))
  },[dispatch])
  return (
    <React.Fragment>
    <Dialog fullScreen={false} maxWidth="sm" fullWidth={true} open={props.open} onClose={props.handleClose}>
        <AppBar className={classes.appBar}>
        <Toolbar>
            <Grid container>
                <Grid md={10} xs={10} item container justify="left"><Typography variant="h6" className={classes.title}>{<IntlMessages id="create_my_page"/>} </Typography></Grid>
                <Grid md={2} xs={2} item container justify="flex-end"> 
                    <IconButton edge="start" color="inherit" aria-label="close" onClick={props.handleClose}>
                        <CloseIcon />
                    </IconButton>   
                </Grid>
            </Grid>
          </Toolbar>
          {ccompany.message?<Alert severity="success" onClose={()=>dispatch(handleResetcreateCompany())}>{ccompany.message}</Alert>:null}
        {ccompany.error?<Alert severity="error" onClose={()=>dispatch(handleResetcreateCompany())}>{ccompany.error}</Alert>:null}
        </AppBar>
         {/*  */}
         <DialogContent >
        <form onSubmit={handleSubmit}  className="add-company">
        <Grid container spacing={3} className={classes.content}>
        <div className="row">
       
        <div className="col-md-12">
            <p>
                Connect your business, yourself or your course to the wordwide community of people on Wutiko to get started, choose page category.
            </p>
        </div>
        <div className="bockUser">
        <div className="col-md-6 col-12">

          <TextField
            required
            id="name"
            name="name"
            label={<IntlMessages id="company_name"/>}
            fullWidth
            autoComplete="name"
            onChange={handleChanges}
            value={fieldsValues.name}
          />
        </div>
        <div className="col-md-6 col-12">
                  <TextField
                  id="full_name"
                  name="full_name"
                  label={<IntlMessages id="company_full_name"/>}
                  fullWidth
                  autoComplete="full_name"
                  onChange={handleChanges}
                  multiLine={true} rows={2}
                  value={fieldsValues.full_name}
                />
        </div> 
        {isAuthenticated ? 
        <>
                  <Col sm={12}>
                              {
                                    
                                      locations.map(item=>{
                                          return(
                                            <Chip label={`${item.data.city_name}, ${item.data.country_name}`} onDelete={()=>setLocations(locations.filter(obj=>obj.place_id!==item.place_id))}/>
                                          )
                                        })
                              }
                            </Col>
                        <Col sm={12}>
                              <Field
                                  fullWidth
                                  value={fieldsValues.location_id}
                                  component={LocationSearchInput}
                                  addLocation={handleAddLocation}
                                  placeholder="Location(s)"
                                  label="Location(s)"
                              />
                          </Col> 
          
                  <Col sm={12}>
                      <Field
                      id="about"
                      name="about"
                      label={<IntlMessages id="about"/>}
                      component={RichTextMarkdown}
                      componentclassName="textarea"
                      onChange={handleChangeDescription}
                      value={fieldsValues.about}
                      />  
                  </Col>
               
                  <Col sm={6}>
                    <TextField
                    id="primary_email"
                    name="primary_email"
                    label={t('wutiko.primary_email')}
                    fullWidth
                    autoComplete={t('wutiko.primary_email')}
                    onChange={handleChanges}
                    multiLine={true} rows={2}
                    value={fieldsValues.primary_email}
                  />
                  </Col>
                  <Col sm={6}>
                    <TextField
                    id="size_range"
                    name="size_range"
                    label={<IntlMessages id="type_your_company_size_range_here"/>}
                    fullWidth
                    autoComplete="type_your_company_size_range_here"
                    onChange={handleChanges}
                    multiLine={true} rows={2}
                    value={fieldsValues.size_range}
                  />
                  </Col>
            
                  <Col sm={6}>
                    <TextField
                    id="primary_phone"
                    name="primary_phone"
                    label={t('wutiko.primary_phone')}
                    fullWidth
                    autoComplete={t('wutiko.primary_phone')}
                    onChange={handleChanges}
                    multiLine={true} rows={2}
                    value={fieldsValues.primary_phone}
                  />
                  </Col>
                  <Col sm={6}>
                    <TextField
                    id="pobox"
                    name="pobox"
                    label={<IntlMessages id="pobox"/>}
                    fullWidth
                    autoComplete="pobox"
                    onChange={handleChanges}
                    multiLine={true} rows={2}
                    value={fieldsValues.pobox}
                  />
                  </Col>
              
                  <Col sm={6}>
                    <TextField
                    id="revenues_range"
                    name="revenues_range"
                    label={<IntlMessages id="revenues_range"/>}
                    fullWidth
                    autoComplete="revenues_range"
                    onChange={handleChanges}
                    multiLine={true} rows={2}
                    value={fieldsValues.revenues_range}
                  />
                  </Col>
                  <Col sm={6}>
                    <TextField
                    id="website"
                    name="website"
                    label={<IntlMessages id="website"/>}
                    fullWidth
                    autoComplete="website"
                    onChange={handleChanges}
                    multiLine={true} rows={2}
                    value={fieldsValues.website}
                  />
                  </Col>
              
                <Col md={12}>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="uncontrolled-native">{<IntlMessages id="organization_category"/>}</InputLabel>
                    <NativeSelect
                      id="organization_category"
                      name="current_status"
                      value={organization_category}
                      onChange={handleChangeCategory}>
                             <option value="0">{t('wutiko.organization')}</option>
                            <option value="1">{t('wutiko.foundation')}</option>
                            <option value="2">{t('wutiko.gouvernement_agency')} </option>
                            <option value="3">{t('wutiko.ngo')} </option>
                            <option value="4">{t('wutiko.public_institution')} </option>
                            <option value="5">{t('wutiko.international_organization')} </option>
                            <option value="6">{t('wutiko.embassy')} </option>
                            <option value="7">{t('wutiko.university')} </option>
                            <option value="8">{t('wutiko.association')} </option>
                            <option value="9">{t('wutiko.hospital')} </option>
                    </NativeSelect>
                  </FormControl>
                  </Col>
                  
                 <Col sm={12}>
                   <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          id="founded_at"
                          name="founded_at"
                          views={["year"]}
                          label={<IntlMessages id="founded"/>}
                          format="YYYY"
                          rifmFormatter={dateFormatter}
                          value={selectedDate}
                          selected={fieldsValues.founded_at}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />   
                      </MuiPickersUtilsProvider>  
                </Col>
                
                <Col sm={6}>
                  <FormControlLabel
                   control={<Checkbox 
                    checked={fieldsValues.is_always_open} 
                    onChange={handleChangeCheckbox} 
                    id="is_always_open" 
                    name="is_always_open" />}
                    label={<IntlMessages id="is_always_open"/>}
                    />
                </Col> 
                  </>:null} 
                  </div> 
         {!isAuthenticated ? 
        <div className="bockUser">
        {/* <Grid container justify="flex-start" xs={12}><Typography variant="h6" className={classes.title}>Your details</Typography></Grid> */}
        <div  className="col-md-6 col-12">
          <TextField
            required
            id="first_name"
            name="first_name"
            label={<IntlMessages id="first_name"/>}
            fullWidth
            autoComplete="given-first-name"
            value={fieldsValues.first_name}
            onChange={handleChanges}
          />
        </div>
        <div  className="col-md-6  col-12">
          <TextField
            required
            id="last_name"
            name="last_name"
            label={<IntlMessages id="last_name"/>}
            fullWidth
            autoComplete="family-name"
            onChange={handleChanges}
            value={fieldsValues.last_name}
          />
        </div>
        <div  className="col-md-6  col-12">
          <TextField
            required
            id="primary_email"
            name="primary_email"
            label={<IntlMessages id="email"/>}
            fullWidth
            autoComplete="given-name"
            onChange={handleChanges}
            value={fieldsValues.primary_email}
          />
        </div>
        <div  className="col-md-6  col-12">
          <TextField
            required
            id="phone"
            name="phone"
            label={<IntlMessages id="phone"/>}
            fullWidth
            autoComplete="shipping address-line1"
            onChange={handleChanges}
            value={fieldsValues.phone}
            placeholder={`Ex:+221xxxxxxxxx`}
          />
        </div>
        <div className="col-md-12 top-20 terms_and_conditions">
            <center>
            {<IntlMessages id="upload_my_cv_footer"
            values={{ terms_and_conditions : <a target="blank" href="https://pages.wutiko.com/en/legal/terms-and-conditions/"> <IntlMessages id="terms_and_conditions"/> </a>}}/>} 
            </center>   
        </div>
        </div>
        : null
        } 
        <Grid item xs={12}  className="col-md-12 top-20 text-right">
         {ccompany.spinner?loader:<Button variant="contained" type="submit" color="primary">{<IntlMessages id="save"/>} </Button>}
        </Grid>
        </div>
        </Grid>
        </form>
        </DialogContent>
         {/*  */}
      </Dialog>   
    </React.Fragment>
  );
}
export default reduxForm({
  form: 'CreateCompany',
  enableReinitialize: true,
})(withTranslation('translation')(CreateCompany));