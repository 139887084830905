import frLang from './entries/fr_FR';
import enLang from './entries/en-US';


const AppLocale = {
    fr: frLang,
    en: enLang
};

export default AppLocale;
