import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
//import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { GET_TOKEN_URL } from 'config';
import { REGISTER_URL } from 'config';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);
  
 
  
  
  
export default function Notif(props) {
  const [open, setOpen] = React.useState(false);


  const gotoRegister= ()=>{
    window.location.href=REGISTER_URL;
  }

  const gotoLogin = ()=>{
    window.location.href=`${GET_TOKEN_URL}&state=/home`;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (

    <div className="">

      <Dialog onClose={props.handleClose}  open={props.open}>
       
        {props.notification? 
        <>
          <DialogContent style={{backgroundColor:'#fdc000'}}>
            <DialogContentText id="alert-dialog-description">
            
            {
             props.text?
            <>
            {
             props.message?
            <div className="row">
            <div className="col-md-10">
              <p className={props.error? props.error : props.message?props.message:null}>{props.text}</p> 
              </div>
              <div className="col-md-2">  <DialogTitle  onClose={props.handleClose} >
            </DialogTitle></div></div>
              :
              props.error?
              <div className="row">
            <div className="col-md-10">
              <p className={props.error? 'error' : props.message?'message':null}>{props.error}</p> 
              </div>
              <div className="col-md-2">  <DialogTitle  onClose={props.handleClose} >
            </DialogTitle></div></div>
            :
            <div className="text-center "> <CircularProgress  color="#000"/></div>
}
            </>
            :
            <div className="text-center "> <CircularProgress  color="#000"/></div>
            }       
            </DialogContentText>
          </DialogContent></>
            :
            <>
             <DialogTitle id="customized-dialog-title" onClose={props.handleClose} >
            {props.title}
            </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {props.text?
           <p className={props.error? props.error : props.message?props.message:null}>{props.text}</p>  :
           <div className="text-center"> <CircularProgress  color="primary"/></div>
            }       
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          {props.signup?
          <Button onClick={gotoRegister}  className="btn-signup">
            {props.signup}
          </Button>:null}
          {props.login?
          <Button onClick={gotoLogin}  className="btn-login">
          {props.login}
           </Button>
           :null}
         </DialogActions></>
          }   
          
        </Dialog>
        </div>
  );
}