import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  bar: {
      border:"2px solid #e0e0e0"
  },
}));

export default function Divider(props) {
  const classes = useStyles();
  return (
      <>
        <Grid item md={5} xs={2}>
            <hr fullWidth className={classes.bar}/>
        </Grid>
        <Grid item md={2} xs={8} container justify="center">
            {props.title}
        </Grid>
        <Grid item md={5} xs={2} >
         <hr fullWidth className={classes.bar}/>
        </Grid>
      </>
  );
}

Divider.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
