import React,{useEffect} from 'react';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import { cleanStringQuery } from 'util/UtilFunctions';
import {useDispatch,useSelector} from 'react-redux'
import { handleEducationsSearch } from 'actions/filters/educations';
import IntlMessages from "util/IntlMessages";
  
function Educations(props) {
  const dispatch = useDispatch();
  const educations = useSelector(state=>state.educations.search_education.educations);

  const handleSearchEducations=(data)=>{
    dispatch(handleEducationsSearch({scope:props.scope, search:`?FacetEducation=${data.currentTarget.value}`}))
  }

  useEffect(()=>{
      dispatch(handleEducationsSearch({scope: props.scope, search:`${cleanStringQuery(window.location.search)?`?${cleanStringQuery(window.location.search)}`:''}`}))
  },[dispatch])

  const onChecked=(value)=>{
    if(props.keys.filter(obj=>obj.name===value).length!==0){
        props.delete({name:value, type:'FacetEducation'})
    }else{
        props.addItem({name:value, type:'FacetEducation'})
    }
   }
    return (
        <FormControl className="filter">
          <InputLabel htmlFor="select-multiple-chip">{<IntlMessages id="educations"/>}</InputLabel>
          <Select
            label={<IntlMessages id="educations"/>}
            multiple
            value={[]}
            >
          
            <Input  className="filter" name="search" type="search" placeholder="Search" autocomplete="off" onChange={(data)=>handleSearchEducations(data)}/>
           
            {educations.map((name,key) => (
              <MenuItem key={key} value={name.education_id__name}>
                <Checkbox checked={props.keys.filter(obj=>obj.name===name.education_id__name).length!==0} onClick={()=>onChecked(name.education_id__name)}/>
                <ListItemText primary={`${name.education_id__name}(${name.total})`} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
     
    );
}

export default Educations;
