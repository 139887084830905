import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { handleFilterExperienceSuccess, handleFilterExperienceError } from "actions/filters/experience";

// 
const setSearchExperience = async (instance,actions)=>
    await instance.get(`/searches/search/admins/experiences/${actions.search}`,actions)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            return error.response.data
        }
    })

function* SearchExperience(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(setSearchExperience,axiosInstance,actions.payload);
    if(data.results){
            yield put(handleFilterExperienceSuccess(data));
    }else{
           yield put(handleFilterExperienceError(data.error));
    }
    
}

export function* handleFilterExperience(){
    yield takeEvery("HANDLE_GET_EXPERIENCE_FILTER", SearchExperience)
}

export default function* rootSaga() {
    yield all([fork(handleFilterExperience)]);
}