export function handleIndustriesSearch(data){
    return {
        type:"HANDLE_GET_INDUSTRIES_SEARCH",
        payload:data
    }
}
export function handleSetSearchIndustries(data){
    return {
        type:"GET_INDUSTRIES_SEARCH",
        payload:data
    }
}

export function handleSetSearchIndustriesError(data){
    return{
        type:"GET_INDUSTRIES_SEARCH_FAILLURE",
        payload:data
    }
}
