// eslint-disable import / first
import {useHistory} from 'react-router-dom';  
import React,{useEffect,Fragment, useState} from 'react';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { LocationSearchInput } from 'components/formsElements/FieldsComponents';
import DateFnsUtils from '@date-io/date-fns';
import { Col, Form,  Row, Button} from 'react-bootstrap';
import { renderField, renderSubmitGroup, StaticError,
  renderImageInput, renderDates, renderDatePicker, renderSelect, renderCheckbox, renderDateTime, RichTextMarkdown} from '../components/formsElements/FormComponents';
import {  EditProfileResetMessages, handleEditProfile } from '../actions/profile';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from 'react-redux';
import Dialog2 from '@material-ui/core/Dialog';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { handleGetProfileDetails } from '../actions/profile';
import TextField from '@material-ui/core/TextField';
import IntlMessages from "util/IntlMessages";
import {Alert} from '@material-ui/lab';
import NativeSelect from '@material-ui/core/NativeSelect';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
// import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
// import LocalizaitonProvider from '@material-ui/lab/LocalizationProvider';
// import StaticDatePicker from '@material-ui/lab/StaticDatePicker';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import { EditCompanyResetMessages, handleEditCompany, handleGetEditCompanyDetails } from 'actions/companies/editCompany';
import CircularProgress from '@material-ui/core/CircularProgress';
//const browserHistory = useHistory();
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


const EditCompanyPage =  (props,submitError, serverError , params , handleSubmit) =>{


 const {itemDetailsCompany,t} = props;
 console.log("détail edit company", itemDetailsCompany)


  const initState = { 
    name:  itemDetailsCompany.name? itemDetailsCompany.name:"",
    full_name: itemDetailsCompany.full_name?itemDetailsCompany.full_name:"",
    about: itemDetailsCompany.about?itemDetailsCompany.about:"",
    primary_email:itemDetailsCompany.primary_email ? itemDetailsCompany.primary_email : "",
    website:itemDetailsCompany.website ? itemDetailsCompany.website : "",
    revenues_range:itemDetailsCompany.revenues_range ? itemDetailsCompany.revenues_range : "",
    size_range:itemDetailsCompany.size_range ? itemDetailsCompany.size_range : "",
    is_always_open: itemDetailsCompany.is_always_open,
    pobox:itemDetailsCompany.pobox?itemDetailsCompany.pobox:"",
    primary_phone:itemDetailsCompany.primary_phone?itemDetailsCompany.primary_phone:"",
  }
  
  const classes = useStyles();
  const [state, setState] = useState(initState);
  const [tagsPosition, setTagsPosition] = useState([]);
  const [tagsEntreprise, setTagsEntreprise] = useState([]);
  const spinner = useSelector(state=>state.company.edit_company.spinner)
  const error = useSelector(state=>state.company.edit_company.error)
  const message = useSelector(state=>state.company.edit_company.message)
  const companiesData = useSelector(state=>state.search.companies.list)
  const [locations, setLocations]= useState([]);
  const positions = useSelector(state=>state.search.positions.list)
  const [open, setOpen] = React.useState(false);
  const {slug} =useParams();
  const founded_at = itemDetailsCompany.founded_at? itemDetailsCompany.founded_at : "";
  const [selectedDate, setSelectedDate] = React.useState(`"${itemDetailsCompany.founded_at? itemDetailsCompany.founded_at :2020}"`)
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(options[0]);
  const [publication_status, setPublication_status] = React.useState(itemDetailsCompany.publication_status? itemDetailsCompany.publication_status :null);
  const [organization_category, setOrganization_category] = React.useState(itemDetailsCompany.organization_category);
  const [current_position, setCurrent_position] = React.useState(itemDetailsCompany.current_position);
  const [description, setDescription] = React.useState(itemDetailsCompany.description?itemDetailsCompany.description:"");
 
  console.log("founded_at", founded_at);
  const handleAddLocation=(item)=>{
    setLocations([...locations.filter(obj=>obj.place_id!==item.place_id), item])
}
  const handleChangePS = (event) => {
    setPublication_status(event.target.value);
  };
  const handleChangeCP = (event) => {
    setCurrent_position(event.target.value);
  };

  const handleChangeCategory = (event) => {
    setOrganization_category(event.target.value);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleChangeDescription = (event) => {
    setDescription(event.toString('html'));
  };

  const handleChangeCheckbox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeField =(event)=>{
    setState({...state, [event.target.name]:event.target.value});
  }
  const dispatch = useDispatch();
  //   useEffect(()=>{
  //  dispatch(handleGetEditCompanyDetails(slug));
  //     },[dispatch]);
        
        const dateFormatter = str => {
          return str;
        };
 const submit=(event, values)=>{
   event.preventDefault();

    dispatch(handleEditCompany({...state,
      locations:locations.map(item=>item.data),
      slug: itemDetailsCompany? itemDetailsCompany.slug:"",
      founded_at: moment.isMoment(selectedDate) ? 
      selectedDate.format('YYYY') : itemDetailsCompany.founded_at ,
      description:description,
      organization_category:organization_category,
    }
      ))
  }

 
const test =<IntlMessages id="formRechercheDemploi"/>

        const handleClickOpen = () => {
          setOpen(true);
          dispatch(EditProfileResetMessages())
        };
        const handleClose = () => {
          dispatch(EditCompanyResetMessages())
          setOpen(false);
          
        };

        console.log("détail edit state", state)

    return(
    <div className="PageEditCompany">
    <Button variant="outlined" color="primary" className="edit-company" onClick={handleClickOpen}>
    <EditIcon/>{<IntlMessages id="edit_company"/>}
    </Button>
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
      <div className="">{<IntlMessages id="edit_company"/>}</div>
      </DialogTitle>
            <DialogContent className="edit-company">
               <DialogContentText>
               {error?<Alert severity="error" onClose={()=>dispatch(EditCompanyResetMessages())}>{error}</Alert>:null}
              {serverError?<Alert severity="error" onClose={()=>dispatch(EditCompanyResetMessages())}>{serverError}</Alert>:null}
             {message?
             <Alert severity="success" onClose={()=>dispatch(EditCompanyResetMessages())}>{message}</Alert>
             // <div className="text-center"><CircularProgress color="inherit" size={20} /> </div>
              :
              <Form onSubmit={submit}>
              <Row className="top-50">
                <Col sm={12}>
                 <Row>
                
                   <Col sm={6}>
                  <TextField
                    id="name"
                    name="name"
                    label={<IntlMessages id="name"/>}
                    fullWidth
                    autoComplete="name"
                    onChange={handleChangeField}
                    multiLine={true} rows={2}
                    value={state.name}
                  />
                  </Col>
                  <Col sm={6}>
                    
                  <TextField
                  id="full_name"
                  name="full_name"
                  label={<IntlMessages id="full_name"/>}
                  fullWidth
                  autoComplete="full_name"
                  onChange={handleChangeField}
                  multiLine={true} rows={2}
                  value={state.full_name}
                />
                  </Col>
                </Row> 
              
                  <Row>
                  <Col sm={12}>
                              {
                                    
                                      locations.map(item=>{
                                          return(
                                            <Chip label={`${item.data.city_name} ${item.data.state_province_name} ${item.data.country_name}`} onDelete={()=>setLocations(locations.filter(obj=>obj.place_id!==item.place_id))}/>
                                          )
                                        })
                              }
                            </Col>
                        <Col sm={12}>
                              <Field
                                  fullWidth
                                  value={state.location_id}
                                  component={LocationSearchInput}
                                  addLocation={handleAddLocation}
                                  placeholder="Location(s)"
                                  label="Location(s)"
                              />
                          </Col> 
                  </Row>

                 <Row>
                  <Col sm={12}>
                      <Field
                      id="about"
                      name="about"
                      label={<IntlMessages id="about"/>}
                      component={RichTextMarkdown}
                      componentclassName="textarea"
                      onChange={handleChangeDescription}
                      value={state.about}
                      />  
                  </Col>
                </Row> 
                {/* <Row>
                  <Col sm={12}>
                  <TextareaAutosize
                    rowsMax={4}
                    id="description"
                    name="description"
                    label={<IntlMessages id="description"/>}
                    fullWidth
                    autoComplete="description"
                    onChange={handleChangeField}
                    value={state.description}
                  />
                  </Col>
                </Row>  */}
                <Row>
                  <Col sm={6}>
                    <TextField
                    id="primary_email"
                    name="primary_email"
                    label={t('wutiko.primary_email')}
                    fullWidth
                    autoComplete={t('wutiko.primary_email')}
                    onChange={handleChangeField}
                    multiLine={true} rows={2}
                    value={state.primary_email}
                  />
                  </Col>
                  <Col sm={6}>
                    <TextField
                    id="size_range"
                    name="size_range"
                    label={<IntlMessages id="type_your_company_size_range_here"/>}
                    fullWidth
                    autoComplete="type_your_company_size_range_here"
                    onChange={handleChangeField}
                    multiLine={true} rows={2}
                    value={state.size_range}
                  />
                  </Col>
                </Row> 
                <Row>
                  <Col sm={6}>
                    <TextField
                    id="primary_phone"
                    name="primary_phone"
                    label={t('wutiko.primary_phone')}
                    fullWidth
                    autoComplete={t('wutiko.primary_phone')}
                    onChange={handleChangeField}
                    multiLine={true} rows={2}
                    value={state.primary_phone}
                  />
                  </Col>
                  <Col sm={6}>
                    <TextField
                    id="pobox"
                    name="pobox"
                    label={<IntlMessages id="pobox"/>}
                    fullWidth
                    autoComplete="pobox"
                    onChange={handleChangeField}
                    multiLine={true} rows={2}
                    value={state.pobox}
                  />
                  </Col>
                </Row> 
                <Row>
                  <Col sm={6}>
                    <TextField
                    id="revenues_range"
                    name="revenues_range"
                    label={<IntlMessages id="revenues_range"/>}
                    fullWidth
                    autoComplete="revenues_range"
                    onChange={handleChangeField}
                    multiLine={true} rows={2}
                    value={state.revenues_range}
                  />
                  </Col>
                  <Col sm={6}>
                    <TextField
                    id="website"
                    name="website"
                    label={<IntlMessages id="website"/>}
                    fullWidth
                    autoComplete="website"
                    onChange={handleChangeField}
                    multiLine={true} rows={2}
                    value={state.website}
                  />
                  </Col>
                </Row> 

                <Row>
                <Col md={12}>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="uncontrolled-native">{<IntlMessages id="company_category"/>}</InputLabel>
                    <NativeSelect
                      defaultValue={itemDetailsCompany?itemDetailsCompany.organization_category:""}
                      id="organization_category"
                      name="current_status"
                      value={organization_category}
                      onChange={handleChangeCategory}>
                             <option value="0">{t('wutiko.organization')}</option>
                            <option value="1">{t('wutiko.foundation')}</option>
                            <option value="2">{t('wutiko.gouvernement_agency')} </option>
                            <option value="3">{t('wutiko.ngo')} </option>
                            <option value="4">{t('wutiko.public_institution')} </option>
                            <option value="5">{t('wutiko.international_organization')} </option>
                            <option value="6">{t('wutiko.embassy')} </option>
                            <option value="7">{t('wutiko.university')} </option>
                            <option value="8">{t('wutiko.association')} </option>
                            <option value="9">{t('wutiko.hospital')} </option>
                    </NativeSelect>
                  </FormControl>
                  </Col>
                  </Row>
                  {/* <Row>
                  <Col sm={12}>
                   <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="uncontrolled-native">{<IntlMessages id="publication_status"/>}</InputLabel>
                    <NativeSelect
                      defaultValue={state.publication_status}
                      id="publication_status"
                      name="publication_status"
                      value={publication_status}
                      onChange={handleChangePS}
                      
                    >
                       <option  value="1">{t('wutiko.active')} </option>
                        <option value="2">{t('wutiko.desactivated')} </option>
                        <option value="3">{t('wutiko.removed')} </option>
                    </NativeSelect>
                  </FormControl> 
                 
                  </Col>
                  </Row>  */}
                  
                {/* <Row>
                <Col sm={12}>
                   <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="uncontrolled-native">Poste actuel</InputLabel>
                    <NativeSelect
                      defaultValue={state.current_position}
                      value={current_position}
                      name={current_position}
                      onChange={handleChangeCP}
                    >
                        <option value="">--</option>
                        
                        {props.positions?
                        props.positions.map((position , index) =>
                       <option key={index} value={position.position_id.id}> {position.position_id.title} chez {position.organization_id.name}</option>
                       ):null}
                    </NativeSelect>
                  </FormControl>
                  </Col>
                </Row> */}
                 <Row>
                 <Col sm={12}>
                   <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            
                        <KeyboardDatePicker
                          margin="normal"
                          id="founded_at"
                          name="founded_at"
                          label={<IntlMessages id="founded"/>}
                          views={["year"]}
                          format="YYYY"
                          // rifmFormatter={dateFormatter}
                          value={selectedDate}
                          // selected={state.founded_at}
                          onChange={handleDateChange}
                          
                        />   
                      </MuiPickersUtilsProvider>   


        {/* <LocalizaitonProvider dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        openTo="year"
        value={selectedDate}
        onChange={(newValue) => {
          setSelectedDate(newValue);
        }}
        renderInput={(params) => <TextField {...params} variant="standard" />}
      />
    </LocalizaitonProvider> */}
                </Col>
                 
                <Col sm={6}>
                  <FormControlLabel
                   control={<Checkbox 
                    checked={state.is_always_open} 
                    onChange={handleChangeCheckbox} 
                    id="is_always_open" 
                    name="is_always_open" />}
                    label={<IntlMessages id="is_always_open"/>}
                    />
                </Col> 
                {/* <Col sm={6}>
                 <FormControlLabel
                  control={<Checkbox checked={state.is_hide_age} onChange={handleChangeCheckbox} name="is_hide_age" />}
                  label={<IntlMessages id="formHideBirth"/>} 
                />
                  </Col> */}
                </Row>

                </Col>
               

              </Row> 
              <Row>
                <Col sm={12}>
                {spinner?
                  <CircularProgress color="inherit" size={20} />:
                <Field name="submit"
                  className="register-training save pull-rigth"
                  component={renderSubmitGroup}
                 />
                }
                </Col>
              
              </Row> 
              
              </Form>}
              </DialogContentText> 
            </DialogContent> 
            {/* <DialogActions>
            
            </DialogActions>  */}
            </Dialog>
           
      </div>
    );
  }

  export default reduxForm({
  form: 'EditCompanyPage',
  enableReinitialize: true,
})(withTranslation('translation')(EditCompanyPage));