import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import OpportunitiesAll from './OpportunitiesAll';

const useStyles = makeStyles((theme) => ({
  cont:{
    paddingTop:30,
    paddingBottom:30,
    zIndex:1,
    background:"#fdc000",
  },
  title:{
    paddingBottom:15,
    color:"#000",
    fontWeight:'bold'
  }
}));

const Opportunities = (props) => {
    const classes = useStyles();
    return (
        <>
          <OpportunitiesAll/>
        </>
    );
};
export default Opportunities;