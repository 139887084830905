import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { addOpportunitySuccess,addOpportunityError, 
    addOpportunityDraftSuccess,addOpportunityDraftError} from "actions/opportunities";

const addOpportunityData = async (instance,data)=>
    await instance.post(`/opportunities/add/`, data)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* addOpportunity(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(addOpportunityData,axiosInstance, actions.data);
    if(data.message){
        yield put(addOpportunitySuccess(data.message));
        yield put({type:"HANDLE_GET_OPPORTUNITIES_BY_COMPANY", data:actions.data})
    }else{
        console.log("data.error", data, data.error)
        yield put(addOpportunityError(data.error));
    }
    
}


function* addOpportunityHome(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(addOpportunityData,axiosInstance, actions.data);
    if(data.message){
        yield put(addOpportunitySuccess(data.message));
        window.location.href=`/opportunity/${data.data.slug}/`
    }else{
        console.log("data.error", data, data.error)
        yield put(addOpportunityError(data.error));
    }
    
}

function* addOpportunityDraftHome(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(addOpportunityData,axiosInstance, actions.data);
    if(data.message){
        yield put(addOpportunityDraftSuccess(data.message));
        window.location.href=`/opportunity/${data.data.slug}/`
    }else{
        console.log("data.error", data, data.error)
        yield put(addOpportunityDraftError(data.error));
    }
    
}

function* addOpportunityDraft(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(addOpportunityData,axiosInstance, actions.data);
    if(data.message){
        yield put(addOpportunityDraftSuccess(data.message));
        yield put({type:"HANDLE_GET_OPPORTUNITIES_BY_COMPANY", data:actions.data})
    }else{
        console.log("data.error", data, data.error)
        yield put(addOpportunityDraftError(data.error));
    }
    
}
export function* handleAddOpportunity(){
    yield takeEvery("HANDLE_ADD_ANONYMOUSLY_OPPORTUNITY", addOpportunity)
}
export function* handleAddOpportunityHome(){
    yield takeEvery("HANDLE_ADD_ANONYMOUSLY_OPPORTUNITY_HOME", addOpportunityHome)
}
export function* handleAddOpportunityDraftHome(){
    yield takeEvery("HANDLE_ADD_ANONYMOUSLY_OPPORTUNITY_DRAFT_HOME", addOpportunityDraftHome)
}
export function* handleAddOpportunityDraft(){
    yield takeEvery("HANDLE_ADD_ANONYMOUSLY_OPPORTUNITY_DRAFT", addOpportunityDraft)
}
export default function* rootSaga() {
    yield all([fork(handleAddOpportunity)]);
    yield all([fork(handleAddOpportunityDraft)]);
    yield all([fork(handleAddOpportunityHome)]);
    yield all([fork(handleAddOpportunityDraftHome)]);
}

