export function handleLocationsSearch(data){
    return {
        type:"HANDLE_GET_LOCATIONS_SEARCH",
        payload:data
    }
}
export function handleSetSearchLocations(data){
    return {
        type:"GET_LOCATIONS_SEARCH",
        payload:data
    }
}

export function handleSetSearchLocationsError(data){
    return{
        type:"GET_LOCATIONS_SEARCH_FAILLURE",
        payload:data
    }
}




export function handleAdminLocationsSearch(data){
    return {
        type:"HANDLE_GET_ADMIN_LOCATIONS_SEARCH",
        payload:data
    }
}
export function handleSetSearchAdminLocations(data){
    return {
        type:"GET_ADMIN_LOCATIONS_SEARCH",
        payload:data
    }
}

export function handleSetSearchAdminLocationsError(data){
    return{
        type:"GET_ADMIN_LOCATIONS_SEARCH_FAILLURE",
        payload:data
    }
}