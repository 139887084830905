import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { getOpportunityDetailsSuccess, getOpportunityDetailsError } from "actions/opportunities";

const GetopportunityDetailsRequest = async (instance,slug)=>
    await instance.get(`/opportunities/${slug}`)
    .then(response=>response)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetopportunityDetails(actions){
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetopportunityDetailsRequest,axiosInstance, actions.slug);
    if(data.data){
        yield put(getOpportunityDetailsSuccess(data.data));
    }else{
        yield put(getOpportunityDetailsError(data.error));
    }
    
}
export function* handleGetopportunityDetails(){
    yield takeEvery("HANDLE_GET_OPPORTUNITY_DETAILS", GetopportunityDetails)
}
export default function* rootSaga() {
    yield all([fork(handleGetopportunityDetails)]);
}
//
