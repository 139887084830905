
const initialState = {
    search_opportunity_type:{
        error:null,
        spinner:false,
        count:null,
        opportunity_type:[]
    },

 }

 export function opportunityType(state = initialState, action){
 
   switch(action.type) {

    case "GET_OPPORTUNITY_TYPE_SEARCH_FAILLURE":
            return {...state, search_opportunity_type:{
                ...state.search_opportunity_type, error:action.payload, spinner:false, opportunity_type:[]
            }}
   case "GET_OPPORTUNITY_TYPE_SEARCH":
           return {...state, search_opportunity_type:{
               ...state.search_opportunity_type, error:null, spinner:false, opportunity_type:action.payload.results
           }}
     default:
       return state;
   }
 }
