import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { DEBUG, LANGUAGE_LIST } from '../config.js';
import { error as notifError } from 'react-notification-system-redux';
import { logError } from '../utils';
import i18next from '../i18n';
import  axios from "axios";
//import { useDispatch} from 'react-redux'
import {
    auth,
    facebookAuthProvider,
    githubAuthProvider,
    googleAuthProvider,
    twitterAuthProvider
} from "../firebase/firebase";
import {
    SIGNIN_FACEBOOK_USER,
    SIGNIN_GITHUB_USER,
    SIGNIN_GOOGLE_USER,
    SIGNIN_TWITTER_USER,
    SIGNIN_USER,
    SIGNOUT_USER,
    SIGNUP_USER
} from "constants/ActionTypes";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess} from "actions/Auth";
import {
    userFacebookSignInSuccess,
    userGithubSignInSuccess,
    userGoogleSignInSuccess,
    userTwitterSignInSuccess
} from "../actions/Auth";
import { API_BASE_URL } from "config";
import { CLIENT_ID } from "config";
import { DEFAULT_REQUEST_TIMEOUT } from "config";
import { getTokenFromStorage, setTokenToStorage, SetUserSettings, getUserIdFromStorage} from "actions/users";
import { getHashUrlParameterByName } from "util/UtilFunctions";
import { VERSION } from "config";
import { LAN } from "config";
import { resetUserSession } from "actions/users";
import { GET_TOKEN_URL } from 'config';
import { Redirect } from "react-router";
const createUserWithEmailPasswordRequest = async (email, password) =>
    await auth.createUserWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);

        function Login() {
        window.location.pathname = `/${GET_TOKEN_URL}&state=/home/;`;
        
        }

const getSettings = async (instance)=>
    await instance.get(`/profiles/account/settings/view/`)
    .then(response=>response.data)
    .catch(error => {
        if(error.response.status===401){
            console.log("Error login", error.response.status)
            console.log("Error login url", GET_TOKEN_URL)
            // window.location.href=`${GET_TOKEN_URL}&state=/home`;
            
            // window.location.href="/";
            resetUserSession()
           // yield put({type:'FIRST_ACCESS', payload:{}});
            const getLocation = (state) => state.users.location
            console.log("get Url token", resetUserSession())

            // return(
            //    // window.location.replace(`https://accounts2.wutiko.com/en/login/`) 
            //    )
            
        }
   
            else{
                    console.log(error.response.data)
                    return error.response.data
                }
    //     yield put(showAuthMessage(error));
    //    yield put ({type:"RESET_USER_SESSION"})
    //    yield put ({ type: "RESET_USER_SESSION" ,  instance:false})
    //    window.location.replace("www.wutiko.com")    ;
      })
    //   .catch((error)=>{
    //     if(error.response.status===401 || error.response.status===403){
    //         return {error:error.response.statusText}
    //     }else{
    //         console.log(error.response.data)
    //         return error.response.data
    //     }
    // })


const signInUserWithEmailPasswordRequest = async (email, password) =>
    await auth.signInWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);

const signOutRequest = async () =>
    await auth.signOut()
        .then(authUser => authUser)
        .catch(error => error);


const signInUserWithGoogleRequest = async () =>
    await auth.signInWithPopup(googleAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

const signInUserWithFacebookRequest = async () =>
    await auth.signInWithPopup(facebookAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

const signInUserWithGithubRequest = async () =>
    await auth.signInWithPopup(githubAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

const signInUserWithTwitterRequest = async () =>
    await auth.signInWithPopup(twitterAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

function* createUserWithEmailPassword({payload}) {
    const {email, password} = payload;
    try {
        const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userSignUpSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

  
function* signInUserWithGoogle() {
    try {
        const signUpUser = yield call(signInUserWithGoogleRequest);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userGoogleSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithFacebook() {
    try {
        const signUpUser = yield call(signInUserWithFacebookRequest);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userFacebookSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithGithub() {
    try {
        const signUpUser = yield call(signInUserWithGithubRequest);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userGithubSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithTwitter() {
    try {
        const signUpUser = yield call(signInUserWithTwitterRequest);
        if (signUpUser.message) {
            if (signUpUser.message.length > 100) {
                yield put(showAuthMessage('Your request has been canceled.'));
            } else {
                yield put(showAuthMessage(signUpUser.message));
            }
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userTwitterSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* signInUserWithEmailPassword({payload}) {
    const {email, password} = payload;
    try {
        const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
        if (signInUser.message) {
            yield put(showAuthMessage(signInUser.message));
        } else {
            localStorage.setItem('user_id', signInUser.user.uid);
            yield put(userSignInSuccess(signInUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* signOut() {
    try {
        const signOutUser = yield call(signOutRequest);
        if (signOutUser === undefined) {
            localStorage.removeItem('user_id');
            yield put(userSignOutSuccess(signOutUser));
        } else {
            yield put(showAuthMessage(signOutUser.message));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}
function* createAxiosInstance(){
    const getLocation = (state) => state.users.location
    const urlWithToken= yield select(getLocation);
    let token = getTokenFromStorage();
    let userId = getUserIdFromStorage();
    let instance;
    let instanceNotAuth;
    console.log("testId", userId)
    if(token){
        instanceNotAuth = axios.create({
            baseURL: `${API_BASE_URL}/${LAN}/${VERSION}`,
            params:{'client':CLIENT_ID},
            timeout: DEFAULT_REQUEST_TIMEOUT,
          });
          yield put({ type: "CREATE_BLANK_AXIOS_INSTANCE", payload: instanceNotAuth});
        instance = axios.create({
            baseURL: `${API_BASE_URL}/${LAN}/${VERSION}`,
            timeout: DEFAULT_REQUEST_TIMEOUT,
            params:{'client':CLIENT_ID},
            headers: {
              'Content-Type':'application/json',
              'Authorization': 'Bearer ' + token.access_token,
            },
          });
          
          instance.interceptors.response.use(res => (res), error => {
            if(error.response){

                switch(error.response.status) {
                    
                  case 401: // unauthorized
                    // token is now invalid, need to relogin
                    // FIXME : that can also be because the user made an inappropriate request, how to tell that to him ?
                   // dispatch(resetUserSession());
                    window.location.href= `${GET_TOKEN_URL}&state=/home;`
                    break;
          
                  case 403:
                    //dispatch(notifError({ title: i18next.t('error403Title'), message: i18next.t('error403Msg') }));
                    break;
          
                  case 400: // bad request
                    // the error in the request needs to be logged to be corrected
                    if(!DEBUG) logError(window.location.href, JSON.stringify(error.response, null, 2));
                    break;
          
                  case 404: // not found
                    // the error in the request needs to be logged to be corrected
                    if(!DEBUG) logError(window.location.href, JSON.stringify(error.response, null, 2));
                    break;
          
                  case 500: // bad request
                    // the error in the request needs to be logged to be corrected
                    if(!DEBUG) logError(window.location.href, JSON.stringify(error.response, null, 2));
                    break;
          
                  default:
                    break;
                }
              }
          
              return Promise.reject(error);
            });
          yield put({ type: "CREATE_AXIOS_INSTANCE", payload: instance });
          console.log( "CREATE_AXIOS_INSTANCE TEST", instance)
    }else if(urlWithToken.hash.trim()!==''){
        const token = {
            access_token: getHashUrlParameterByName('access_token', urlWithToken.hash),
            expires_in: getHashUrlParameterByName('expires_in', urlWithToken.hash),
            token_type: getHashUrlParameterByName('token_type', urlWithToken.hash),
            scope: getHashUrlParameterByName('scope', urlWithToken.hash),
            state: getHashUrlParameterByName('state', urlWithToken.hash),
          }
          instance = axios.create({
            baseURL: `${API_BASE_URL}/${LAN}/${VERSION}`,
            timeout: DEFAULT_REQUEST_TIMEOUT,
            params:{'client':CLIENT_ID},
            headers: {
              'Content-Type':'application/json',
              'Authorization': 'Bearer ' + token.access_token,
            },
          }
          );
          instance.interceptors.response.use(res => (res), error => {
            if(error.response){

                switch(error.response.status) {
          
                  case 401: // unauthorized
                    // token is now invalid, need to relogin
                    // FIXME : that can also be because the user made an inappropriate request, how to tell that to him ?
                   // dispatch(resetUserSession());
                    window.location.href= `${GET_TOKEN_URL}&state=/home;`
                    break;
          
                  case 403:
                    //dispatch(notifError({ title: i18next.t('error403Title'), message: i18next.t('error403Msg') }));
                    break;
          
                  case 400: // bad request
                    // the error in the request needs to be logged to be corrected
                    if(!DEBUG) logError(window.location.href, JSON.stringify(error.response, null, 2));
                    break;
          
                  case 404: // not found
                    // the error in the request needs to be logged to be corrected
                    if(!DEBUG) logError(window.location.href, JSON.stringify(error.response, null, 2));
                    break;
          
                  case 500: // bad request
                    // the error in the request needs to be logged to be corrected
                    if(!DEBUG) logError(window.location.href, JSON.stringify(error.response, null, 2));
                    break;
          
                  default:
                    break;
                }
              }
          
              return Promise.reject(error);
            });
          setTokenToStorage(token);
          instanceNotAuth = axios.create({
            baseURL: `${API_BASE_URL}/${LAN}/${VERSION}`,
            params:{'client':CLIENT_ID},
            headers:{
                'Content-Type':'application/json',
            },
            timeout: DEFAULT_REQUEST_TIMEOUT,
          });
          yield put({ type: "LOGIN", payload:true})
          yield put({ type: "CREATE_BLANK_AXIOS_INSTANCE", payload: instanceNotAuth});
          yield put({ type: "CREATE_AXIOS_INSTANCE", payload: instance });
    }
    else{
        instance = axios.create({
            baseURL: `${API_BASE_URL}/${LAN}/${VERSION}`,
            params:{'client':CLIENT_ID},
            timeout: DEFAULT_REQUEST_TIMEOUT,
            headers:{
                'Content-Type':'application/json',
            },
          });
          instance.interceptors.response.use(res => (res), error => {
            if(error.response){

                switch(error.response.status) {
          
                  case 401: // unauthorized
                    // token is now invalid, need to relogin
                    // FIXME : that can also be because the user made an inappropriate request, how to tell that to him ?
                   // dispatch(resetUserSession());
                    window.location.href= `${GET_TOKEN_URL}&state=/home;`
                    break;
          
                  case 403:
                    //dispatch(notifError({ title: i18next.t('error403Title'), message: i18next.t('error403Msg') }));
                    break;
          
                  case 400: // bad request
                    // the error in the request needs to be logged to be corrected
                    if(!DEBUG) logError(window.location.href, JSON.stringify(error.response, null, 2));
                    break;
          
                  case 404: // not found
                    // the error in the request needs to be logged to be corrected
                    if(!DEBUG) logError(window.location.href, JSON.stringify(error.response, null, 2));
                    break;
          
                  case 500: // bad request
                    // the error in the request needs to be logged to be corrected
                    if(!DEBUG) logError(window.location.href, JSON.stringify(error.response, null, 2));
                    break;
          
                  default:
                    break;
                }
              }
          
              return Promise.reject(error);
            });
          yield put({ type: "CREATE_BLANK_AXIOS_INSTANCE", payload: instance });
    }

    

}
function* handleGetsettings(){
    const axiosInstance = (state)=>state.users.axiosInstance
    const instance =yield select(axiosInstance);
    const settings =yield call(getSettings,instance);
    yield put(SetUserSettings(settings));
   
    console.log("Test Log", settings)
}
export function* initSession(){
    yield takeEvery("FIRST_ACCESS",createAxiosInstance);
    yield takeEvery("GET_USER_SETTINGS", handleGetsettings)
}
// export function* getSetings(){
//     //GET_USER_SETTINGS
// }
export function* createUserAccount() {
    yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
    yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
    yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
    yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
    yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
    yield all([fork(signInUser),
        fork(createUserAccount),
        fork(signInWithGoogle),
        fork(signInWithFacebook),
        fork(signInWithTwitter),
        fork(signInWithGithub),
        fork(initSession),
        fork(signOutUser)]);
}