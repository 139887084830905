import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { getOpportunitiesByCompanySuccess, getOpportunitiesByCompanyError } from  "actions/companies/getCompanies";

const GetOpportunitiesByCompanyRequest = async (instance, data)=>
   // await instance.get(`${data}`)
   await instance.get(`organizations/${data.slug}/opportunities/list/?page=${data.page}`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* GetOpportunitiesByCompany(actions){

    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(GetOpportunitiesByCompanyRequest,axiosInstance,  actions.data );
    
    if(data.data.results){

        yield put(getOpportunitiesByCompanySuccess(data.data));
    }else{
        yield put(getOpportunitiesByCompanyError(data.error));
    }
    
}
export function* handleGetOpportunitiesByCompany(){
    console.log("test opp" )
    yield takeEvery("HANDLE_GET_OPPORTUNITIES_BY_COMPANY", GetOpportunitiesByCompany)
}
export default function* rootSaga() {
    yield all([fork(handleGetOpportunitiesByCompany)]);
}
