import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { FollowProfileSuccess, FollowProfileError} from "actions/profile";

const FollowProfile = async (instance, user_id)=>
    await instance.post(`/profiles/connexions/invite/${user_id}/add/`, {})
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })
function* FollowProfileAction(actions){
        const state = yield select((state)=>state.users);
        let axiosInstance;
        if(state.authenticated){
            axiosInstance=state.axiosInstance
        }else{
            axiosInstance=state.blankAxiosInstance
        }
        const data =yield call(FollowProfile ,axiosInstance, actions.data.user_id);
        if(data.message){
           // yield put({type:"HANDLE_SEARCH_PROFILES"})
           //const profileList = yield select((state)=>state.profile.all_profiles.list.profiles);
          
            yield put(FollowProfileSuccess(data.message));
        }else{
            console.log("data.error", data, data.error)

            //yield put({type:"HANDLE_SEARCH_PROFILES"})
            yield put(FollowProfileError(data.error));
        }
        
    }
function* HandleFollowProfile (){
    
    yield takeEvery("HANDLE_FOLLOW_PROFILE", FollowProfileAction)
}
export default function* rootSaga() {
    yield all([fork(HandleFollowProfile)]);
}