
const initialState = {
    search_locations:{
        error:null,
        spinner:false,
        count:null,
        locations:[]
    },
    search_admin_locations:{
        error:null,
        spinner:false,
        count:null,
        locations:[]
    },
 }

 export function locations(state = initialState, action){
 
   switch(action.type) {

    case "GET_LOCATIONS_SEARCH_FAILLURE":
            return {...state, search_locations:{
                ...state.search_locations, error:action.payload, spinner:false, locations:[]
            }}
   case "GET_LOCATIONS_SEARCH":
           return {...state, search_locations:{
               ...state.search_locations, error:null, spinner:false, locations:action.payload.results
           }}


    case "GET_ADMIN_LOCATIONS_SEARCH_FAILLURE":
            return {...state, search_admin_locations:{
                ...state.search_admin_locations, error:action.payload, spinner:false, locations:[]
            }}
   case "GET_ADMIN_LOCATIONS_SEARCH":
           return {...state, search_admin_locations:{
               ...state.search_admin_locations, error:null, spinner:false, locations:action.payload.results
           }}
     default:
       return state;
   }
 }
