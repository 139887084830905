import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles/index";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar/index";
import Tabs from "@material-ui/core/Tabs/index";
import Tab from "@material-ui/core/Tab/index";
import { Parser } from 'html-to-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IntlMessages from "util/IntlMessages";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

import CompanyDetails from "./CompanyDetails";
import OpportunityByCompany from "./OpportunityByCompany";
import ProfilesByCompany from "./ProfilesByCompany";
import AddOpportunityNotAuth from "components/opportunities/AddOpportunityNotAuth";
import Button from '@material-ui/core/Button';
import EditOpportunities from "pages/EditOpportunities";
import ReactPaginate from "react-paginate";
import AddOpportunity from "pages/AddOpportunity";


const MapWithAMarker = withGoogleMap(props =>

  <GoogleMap
    defaultZoom={8}
    defaultCenter={{ lat:props.company ? props.company.latLng[0]:14.7552617, lng: props.company ? props.company.latLng[1]:17.4044798}}
  >
    <Marker
      position={{ lat:props.company ? props.company.latLng[0]:14.7552617, lng: props.company ? props.company.latLng[1]:17.4044798}}
      text="text"
    />
  </GoogleMap>
);

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const style = {
  width: '100%',
  height: '200%'
}



function TabContainer({ children, dir }) {
  return (
    <div dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
};

class CompanyDetailsTab extends Component {
  state = {
    value: 0
  };

  

  handleChange = (event, value) => {
    this.setState({ value });
  };
   

  handleChangeIndex = index => {
    this.setState({ value: index });
  };


  
  render() {
    const { theme, is_admin,company, opportunitiesList, spinnerOpp, profilesList , spinnerProfile, isAuthenticated, } = this.props;

   
    var parser = new Parser();
    return (
      <div className="w-100">
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            scrollButtons="on">

            <Tab className="tab" label={this.props.tab1} />
            <Tab className="tab" label={this.props.tab2} />
            <Tab className="tab" label={this.props.tab3} />
          {/* <Tab className="tab" label={this.props.tab4} />  */}
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
        >
          
        
         
          <TabContainer dir={theme.direction}>
          {/* {company ?
           <div className="row">
             {company.organization_id.about ?
            <div className="col-12">
              
                <MapWithAMarker
                  containerElement={<div style={{ height: `250px` }} />}
                 mapElement={<div style={{ height: `100%` }} />}/> 
              <br/>
              <h3 className="">About </h3>
             <p>{company.organization_id.about}</p>
            </div>:null}

            {company.organization_id.website ?
            <div className="col-12">
             <h3 className=""><label>Website : </label>{company.organization_id.website}</h3>
            </div>:null}
            
            {company.organization_id.primary_email ?  
            <div className="col-12">
             <h3 className=""><label>Email : </label>{company.organization_id.primary_email}</h3>
            </div>
            :null}
            
            {company.organization_id.primary_phone ?
            <div className="col-12">
             <h3 className=""><label>Phone : </label> {company.organization_id.primary_phone}</h3>
            </div>:null}
            {company.organization_id.size_range ?
            <div className="col-12">
             <h3 className=""><label>Size :  </label>{company.organization_id.size_range}</h3>
            </div>:null}

            {company.organization_id.location ?
            <div className="col-12">
             <h3 className=""><label>Location :</label> {company.organization_id.location}</h3>
            </div>:null}

            {company.organization_id.headquarters ?
            <div className="col-12">
             <h3 className=""><label>Headquarters : </label> {company.organization_id.headquarters}</h3>
            </div>:null}

            {company.organization_id.industry ?
            <div className="col-12">
             <h3 className=""><label>Industry :</label> {company.organization_id.industry}</h3>
            </div>:null}

            {company.detailsorganization_id.founded_at?
            <div className="col-12">
             <h3 className=""><label>Founded :</label>{company.organization_id.founded_at}</h3>
            </div>
            :null}
          </div>
          : null} */}

        <Grid container spacing={2}>
          {is_admin?
          <>
        <AddOpportunity title="add_opportunity" slug={company.slug}/>
        {/* <Grid item md={12} xs={12} sm={6} className="text-right">
          <Button onClick={()=>this.props.setopenOpportunity(true)} fullWidth variant="contained" className="btn_y col-md-4" justify="right">
          {<IntlMessages id="post_an_opportunity"/>}</Button>
        </Grid> */}
        </>
        :null}
        {
          spinnerOpp?
          <Grid container item justify="center" xs={12}><CircularProgress size={80}/></Grid>:
          opportunitiesList?
          opportunitiesList.map((item)=>{
            return(
              <Grid item md={12} xs={12}>
                  <OpportunityByCompany data={item} is_admin={is_admin}/>
             </Grid>
            )
            }) : null
        }
        </Grid>

        <Grid item md={12} xs={12} container justify="center" className="Listpagination">
        
        <ReactPaginate
          previousLabel={<IntlMessages id="previous" />}
          nextLabel={<IntlMessages id="next" />}
          pageCount={this.props.pageCount}
          onPageChange={this.props.handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />


  </Grid>


          </TabContainer>




          <TabContainer dir={theme.direction}>
          {company?
         <div className="row">
            <div className="col-md-12 ">
            {company.about?
             <>
              <p className="">
                {company?
                parser.parse(company.about)
                :null}
              </p></>
              :null}
            </div>
            <div className="col-md-12 row">
            {company.website?
             <><h3 className="col-md-4">{<IntlMessages id="website"/>} </h3>
              <p className="col-md-8">
              {company?
                <a href={company.website} target="blank">{company.website}</a>
                :null} 
              </p></>
              :null}
            </div>
            <div className="col-md-12 row">
              {company.primary_email?
              <>
             <h3 className="col-md-4">{<IntlMessages id="email"/>}</h3>
              <p className="col-md-8">
                {company.primary_email}
              </p>
              </>
              :null}
            </div>
            <div className="col-md-12 row">
            {company.primary_phone?
            <>
             <h3 className="col-md-4">{<IntlMessages id="phone"/>}</h3>
             <p className="col-md-8">
                {company.primary_phone}
              </p>
              </>
              :null}
            </div>
          
            <div className="col-md-12 row">
            {company.headquarters?
            <>
            <h3 className="col-md-4">{<IntlMessages id="headquarters"/>}</h3>
              <p className="col-md-8">
                {company.headquarters}
              </p>
              </>
              :null}
            </div>
            
            <div className="col-md-12 row">
            {company.industry?
              <>
             <h3 className="col-md-4">{<IntlMessages id="industry"/>}</h3>
             <p className="col-md-8">
               {company.industry}
              </p>
              </>
              :null}
            </div>

            <div className="col-md-12 row">
            {company.founded_at?
            <>
             <h3 className="col-md-4">{<IntlMessages id="founded"/>}</h3>
             <p className="col-md-8">
               { company.founded_at}
              </p></>
                :null}
            </div>
            <div className="col-md-12 row">
            {company.location?
            <>
             <h3 className="col-md-4">{<IntlMessages id="location"/>} </h3>
             <p className="col-md-8">{company.location}</p>
              <p className="col-md-12">
                
                {/* <MapWithAMarker
                 containerElement={<div style={{ height: `350px` }} />}
                 mapElement={<div style={{ height: `100%` }} />}/> */}
              </p>
              </>
               :null}
            </div>
           
            {/* <div className="col-12 row">
            {company.skills ?
            <div>
            {company.skills.length !== 0 ?
            <div>
             <h3 className="col-md-4">Skills</h3>
              <p className="col-md-8">

              {company.skills.map((item)=>{
                      return <div>{item.skill_id.name},&ensp;</div>
                    })}
              </p></div>
              :null} 
              </div> 
              :null}
            </div> */}
          </div> 
          :null}
          </TabContainer>

          

          <TabContainer dir={theme.direction}>

           <Grid container spacing={2}>
        {
          
        this.props.isAuthenticated ?
          spinnerProfile?
          <Grid container item justify="center" xs={12}><CircularProgress size={80}/></Grid>:
          
          profilesList?
          profilesList.map((item)=>{
            return(
              <Grid item md={12} xs={12}>
                  <ProfilesByCompany data={item}/>
             </Grid>
            )
            }) : null
            : <div>{<IntlMessages id="reserved_only_for_members"/>}</div>
        }
        </Grid> 
          </TabContainer>
          

         <TabContainer dir={theme.direction}>
          
          <MapWithAMarker
                  containerElement={<div style={{ height: `250px` }} />}
                 mapElement={<div style={{ height: `100%` }} />}/> 
             
          </TabContainer> 
        </SwipeableViews>
      </div>
    );
  }
}

CompanyDetailsTab.propTypes = {
  theme: PropTypes.object.isRequired
};

export default withStyles(null, { withTheme: true })(CompanyDetailsTab);