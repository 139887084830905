var lan='fr'
var Nlang='French'
var codelang='FR'
try {
   lan=localStorage.getItem('i18nextLng').split('-')[0];
   if(lan ==='en'){
    Nlang='English'
    codelang='US'
  }else{
    Nlang='French'
  }
} catch (error) {
   if(localStorage.getItem('i18nextLng')==='en'){
     lan=localStorage.getItem('i18nextLng')
   }
}

export const LAN=lan;
export const Nlangue=Nlang;
export const CodeLang=codelang;
export const VERSION ="v3"
export const SOCIAL_REGISTER_REDIREDT_URI="https://accounts.wutiko.com/en/v2/login/";
export const FACEBOOK_CLIENT_ID="399241986875633";
export const FACEBOOK_SCOPE="email,public_profile";
export const LINKEDIN_CLIENT_ID="77formcu2ppox2";
export const GOOGLE_SCOPE="profile+email+https://www.googleapis.com/auth/plus.login+https://www.googleapis.com/auth/plus.me+https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/userinfo.profile";
export const GOOGLE_CLIENT_ID="170785488435-b3v1id5ebfmdstlrai3mcps2v27al0j0.apps.googleusercontent.com";
// General and authentification
export const CLIENT_ID = 'zI48utDcA96zItzjr5xGgIvbD3QvDmAFObsZPIiF';

export const FORM_ID ='919f9c66-bd83-48f4-992c-d6c3193ba06a';
export const NEWS_LETTER_ID="b0238eac-340d-4690-90b6-814510a37277";
export const CONTACT_FORM_ID="919f9c66-bd83-48f4-992c-d6c3193ba06a";
export const APP_FORM_ID="919f9c66-bd83-48f4-992c-d6c3193ba06a";


export const HOMEPAGE_URL = 'https://www.wutiko.com';

export const ACCOUNT_SERVER_URL = `https://accounts.wutiko.com/${LAN}/login`;
export const ACCOUNT_REGISTER_SERVER_URL =  `https://accounts.wutiko.com/${LAN}/signup`;


export const COMPLETE_SOCIAL_REGISTRATION_MISSING_DATA="https://api.wutiko.com/en/v3/authentications/complete-social-registration-data/";


export const  CHECKOUT = 'https://api.wutiko.com/en/v3/payment/checkout/add';


export const LOGOUT_URL = `${ACCOUNT_SERVER_URL}/${LAN}/v2/authen/logout/${HOMEPAGE_URL}`;
export const GET_TOKEN_URL = `${ACCOUNT_SERVER_URL}/?client_id=${CLIENT_ID}&redirect_uri=${HOMEPAGE_URL}`;
export const REGISTER_URL = `${ACCOUNT_REGISTER_SERVER_URL}/?client_id=${CLIENT_ID}&redirect_uri=${HOMEPAGE_URL}`;


export const API_BASE_URL = 'https://api.wutiko.com';
export const STORE_URL = `https://store.wutiko.com/${LAN}`;
export const PAGE_URL = `https://pages.wutiko.com/${LAN}`;
export const ACCOUNT_URL = `https://accounts.wutiko.com/${LAN}`;

// API request params

//export const API_BASE_URL_2 = 'https://api2.wutiko.com';

export const DEFAULT_REQUEST_TIMEOUT = 50000;
export const DEFAULT_AXIOS_PARAMS = {
  headers: {
    'Content-Type': 'application/json',
 },
  timeout: DEFAULT_REQUEST_TIMEOUT,
}
// https://www.youtube.com/watch?v=8yo44xN7-nQ
// Jobs

export const ACCOUNT_TYPE_RECRUITER = 'Recruiter';
export const RESULTS_PER_PAGE = 20;

export const LANGUAGE_LIST = [ 'fr','en'];

// Development params

export const DEBUG = false;
export const LANGUAGE_DEBUG = false; // allow to know which i18n keys are missing, but messes the language change
  

