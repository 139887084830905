export function handleSearchSkills(data){
    return {
        type:"HANDLE_GET_SKILLS_SEARCH",
        payload:data
    }
}

export function handleSetSearchSkills(data){
    return {
        type:"GET_SKILLS_SEARCH",
        payload:data
    }
}

export function handleSetSearchSkillsError(data){
    return{
        type:"GET_SKILLS_SEARCH_FAILLURE",
        payload:data
    }
}