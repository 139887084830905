const initialState = {
    pre_view_form:{
        error:null,
        spinner:false,
        uiSchema:{},
        required:[],
        properties:{}
    }
 }

 export function preViewForm(state = initialState, action){
 
   switch(action.type) {
   /**
    * Handle pre view
    */
   case "HANDLE_PRE_VIEW_FORM":
           return {...state, pre_view_form:{
               ...state.pre_view_form, error:null, spinner:true, uiSchema:{},required:[],properties:{} 
           }}
   case "PRE_VIEW_FORM_FAILLURE":
            return {...state, pre_view_form:{
                ...state.pre_view_form, error:action.payload, spinner:false, uiSchema:{},required:[],properties:{} 
            }}
   case "PRE_VIEW_FORM":
        return {...state, pre_view_form:{
            ...state.pre_view_form, error:null, spinner:false, uiSchema:action.payload.uiSchema,required:action.payload.required
            ,properties:action.payload.properties 
        }}
   case "PRE_VIEW_FORM_RESET_MESSAGES":
        return {...state, pre_view_form:{
            ...state.pre_view_form, error:null, spinner:false
        }}

     default:
       return state;
   }
 }