import React,{useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import i18next from 'i18n';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logo from '../../assets/imgs/wutiko-logo-black-yellow.png';

import { LAN } from "config";
import { GET_TOKEN_URL } from 'config';
import { REGISTER_URL } from 'config';
import { useSelector, useDispatch} from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import SearchBar from './searchEngine/SearchBar';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import { handleLogout } from 'actions/header';
import {Link} from "react-router-dom";
import MenuLayout from './MenuLayout';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import LanguageSwitcher from "components/LanguageSwitcher/index";
import { initSession, resetUserSession, updateAPILanguage } from 'actions/user';
import IconButton from "@material-ui/core/IconButton";
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import {switchLanguage, toggleCollapsedNav} from "actions";
import IntlMessages from "util/IntlMessages";
import {STORE_URL , ACCOUNT_URL, PAGE_URL} from "config";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color:theme.palette.common.black,
  },
}));

function CTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}




const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  header:{
    position:'fixed',
    top:0,
    width:'100%',
    background:'#ffffff',
    zIndex:10
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  }, 
  search:{
    paddingTop:0,
    paddingBottom:0
  }
  
}));




export default function Header(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const state =useSelector((state)=>state)
  const isAuthenticated = state.users.authenticated
  const userId=state.users.userSettings.id
  const status =state.users.userSettings
  const slug =state.users.userSettings.slug
  
  const [langSwitcher, setLangSwitcher] = useState(false);
  const {locale} = useSelector(({settings}) => settings);
  const classes = useStyles()

  console.log("userSettings" , status)

  console.log("userId" , userId ? userId : null)
  const dispatch = useDispatch()
  useEffect(()=>{
      dispatch({type:"FIRST_ACCESS", payload:props.location});
      if(isAuthenticated){
        dispatch({type:"GET_USER_SETTINGS"});
      }


      // dispatch({type:"FIRST_ACCESS", payload:props.location});
      // slug ?
      //   dispatch({type:"GET_USER_SETTINGS"})
      //   :
      //   dispatch(handleLogout())


        //window.location.href=`${GET_TOKEN_URL}&state=/home`
      // initSession()
      // else{
      //   dispatch({type:"RESET_USER_SESSION"});
      // // window.location.href=`${GET_TOKEN_URL}&state=/home;`;
      // }
     
      // initSession
      // else{
      //   dispatch({type:"RESET_USER_SESSION"});                    <      
      // // window.location.href=`${GET_TOKEN_URL}&state=/home;`;
      // }
     
  },[props,dispatch]);

  console.log("status" , status)
  const gotoRegister= ()=>{
    window.location.href=REGISTER_URL;
  }
  const onLangSwitcherSelect = () => {
    setLangSwitcher(!langSwitcher);
  };
  const onSwitchLanguage = (lang) => {
    dispatch(switchLanguage(lang))
  };

  const handleRequestClose = () => {
    setLangSwitcher(false);
  };


  const gotoLogin = ()=>{
    window.location.href=`${GET_TOKEN_URL}&state=/home`;
  }
  const gotoLogout = ()=>{
    dispatch(handleLogout())
    window.location.href=`${ACCOUNT_URL}/logout/`
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  



  return (
    <div>
    <div className="d-none d-md-block d-lg-block" >
      <Toolbar className={classes.toolbar}>
        <MenuLayout/>
        <div className="Logo col-md-2 col-4">
        <a href="/"><img src={logo} alt="wtk"></img></a>
        </div>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="center"
          noWrap
          className={classes.toolbarTitle}
        >
        </Typography>
        <SearchBar/> 
    {
       isAuthenticated?
       <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
      <Button aria-controls="fade-menu" aria-haspopup="true" className="sub-menu " onClick={handleClick}>
      {state.users.userSettings.first_name} {state.users.userSettings.photo?<Avatar className="img-profil" alt="Remy Sharp" src={state.users.userSettings.photo} />:null}
      </Button>
     
      <div className="list-inline-item">
              <Dropdown
                className="quick-menu langue"
                isOpen={langSwitcher}
                toggle={onLangSwitcherSelect}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <Button className="langue connect">{`${locale.locale}`}</Button>
                </DropdownToggle>

                <DropdownMenu right className="w-50">
                  <LanguageSwitcher switchLanguage={onSwitchLanguage}
                                    handleRequestClose={handleRequestClose}/>
                </DropdownMenu>
              </Dropdown>
            </div>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <Link to={`/profile/`+slug} ><MenuItem>{<IntlMessages id="my_profile"/>}</MenuItem></Link>
        <a href="/dashboard"><MenuItem>{<IntlMessages id="access_to_dashboard"/>}</MenuItem></a>
        <a href="/admin-organization"><MenuItem>{<IntlMessages id="manage_companies"/>}</MenuItem></a>
        <a href={`${STORE_URL}/plans/pricing/`}><MenuItem >{<IntlMessages id="upgrade_to_premium"/>}</MenuItem></a>
        <a href={`${ACCOUNT_URL}/settings/`}><MenuItem>{<IntlMessages id="settings"/>}</MenuItem></a>
        <a href="#"  onClick={gotoLogout}><MenuItem>{<IntlMessages id="logout"/>}</MenuItem></a>
      </Menu>
      </ButtonGroup>
      :
      <ButtonGroup variant="text" color="primary" aria-label="text primary button group" className="register">
        <Button onClick={gotoLogin}> 
        {<IntlMessages id="log_in"/>}
        </Button>
        <Button onClick={gotoRegister} className="signup">
         {<IntlMessages id="sign_up"/>}
        </Button>
        
        <div className="list-inline-item">
              <Dropdown
                className="quick-menu langue"
                isOpen={langSwitcher}
                toggle={onLangSwitcherSelect}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <Button className="langue">{`${locale.locale}`}</Button>
                    {/* <i className={`flag flag-24 flag-${locale.icon}`}/> */}
                </DropdownToggle>

                <DropdownMenu right className="w-50">
                  <LanguageSwitcher switchLanguage={onSwitchLanguage}
                                    handleRequestClose={handleRequestClose}/>
                </DropdownMenu>
              </Dropdown>
            </div>
      </ButtonGroup>
      }
      </Toolbar>
    </div>


<div className="d-xl-none d-md-none mobile">
      <Toolbar className={classes.toolbar}>
        
        <div className="Logo col-md-2 col-5">
        <Link to="/"><img src={logo} alt="wtk"></img></Link>
        </div>
        
        {/* <div className="list-inline-item">
              <Dropdown
                className="quick-menu langue"
                isOpen={langSwitcher}
                toggle={onLangSwitcherSelect}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <Button className="langue connect">{`${locale.locale}`}</Button>
                </DropdownToggle>

                <DropdownMenu right className="w-50">
                  <LanguageSwitcher switchLanguage={onSwitchLanguage}
                                    handleRequestClose={handleRequestClose}/>
                </DropdownMenu>
              </Dropdown>
            </div> */}
            
            <SearchBar/> 
            <MenuLayout/>
      </Toolbar>
    </div>
    </div>
  );
}
Header.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
}


