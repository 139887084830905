import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { setgetLastUpdatesData } from "actions/lastUpdates";

const getLastUpdatesData = async (instance)=>
    await instance.get(`/activities/latest/updates/list/`)
    .then(response=>response.data.results)
    .catch(error=>error)

function* getLastUpdatesList(){
    const axiosInstance = (state)=>state.users.blankAxiosInstance
    const instance =yield select(axiosInstance);
    const data =yield call(getLastUpdatesData,instance);
    console.log("/activities/latest/updates/list/",data)
    yield put(setgetLastUpdatesData(data));
}
export function* getLastUpdates(){
    yield takeEvery("HANDLE_GET_LAST_UPDATES", getLastUpdatesList)
}
export default function* rootSaga() {
    yield all([fork(getLastUpdates)]);
}
