
const initialState = {
    search_skills:{
        error:null,
        spinner:false,
        count:null,
        skills:[]
    },

 }

 export function skills(state = initialState, action){
 
   switch(action.type) {

    case "GET_SKILLS_SEARCH_FAILLURE":
            return {...state, search_skills:{
                ...state.search_skills, error:action.payload, spinner:false, skills:[]
            }}
   case "GET_SKILLS_SEARCH":
           return {...state, search_skills:{
               ...state.search_skills, error:null, spinner:false, skills:action.payload.results
           }}
     default:
       return state;
   }
 }
