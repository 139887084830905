import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { EditOpportunitySuccess,EditOpportunityError, EditSaveOpportunityError, EditSaveOpportunitySuccess} from "actions/opportunities";

const EditOpportunityData = async (instance,data)=>
    await instance.put(`/opportunities/${data.slug_opp}/edit/`, data)
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* EditOpportunity(actions){
    
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(EditOpportunityData,axiosInstance, actions.data);
    if(data.message){
        yield put(EditOpportunitySuccess(data.message));
        yield put({type:"HANDLE_GET_OPPORTUNITIES_BY_COMPANY", data:actions.data})
        yield put({type:"HANDLE_GET_OPPORTUNITY_DETAILS", slug:actions.data.slug_opp})
        yield put({type:"HANDLE_GET_EDIT_OPPORTUNITY_DETAILS", slug:actions.data.slug_opp})
       // yield put({type:"HANDLE_GET_OPPORTUNITIES_DASHBOARD"})
    }else{
        console.log("data.error", data, data.error)
        yield put(EditOpportunityError(data.error));
    }
}


function* EditSaveOpportunity(actions){
    
    const state = yield select((state)=>state.users);
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(EditOpportunityData,axiosInstance, actions.data);
    if(data.message){
        yield put(EditSaveOpportunitySuccess(data.message));
        yield put({type:"HANDLE_GET_OPPORTUNITIES_BY_COMPANY", data:actions.data})
        yield put({type:"HANDLE_GET_OPPORTUNITY_DETAILS", slug:actions.data.slug_opp})
        yield put({type:"HANDLE_GET_EDIT_OPPORTUNITY_DETAILS", slug:actions.data.slug_opp})
       // yield put({type:"HANDLE_GET_OPPORTUNITIES_DASHBOARD"})
    }else{
        console.log("data.error", data, data.error)
        yield put(EditSaveOpportunityError(data.error));
    }
}
export function* handleEditOpportunity(){
    yield takeEvery("HANDLE_EDIT_OPPORTUNITY", EditOpportunity)
}

export function* handleEditSaveOpportunity(){
    yield takeEvery("HANDLE_SAVE_EDIT_OPPORTUNITY", EditSaveOpportunity)
}
export default function* rootSaga() {
    yield all([
        fork(handleEditOpportunity),
        fork(handleEditSaveOpportunity),
    ]);
}

