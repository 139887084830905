import { Field, reduxForm } from 'redux-form';
import { browserHistory } from 'react-router';
import DateFnsUtils from '@date-io/date-fns';
// import { translate } from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {useParams} from 'react-router-dom';
import Select from '@material-ui/core/Select';
import { withTranslation } from 'react-i18next';
import { Col, Form, Row, Panel, Modal, Button } from 'react-bootstrap';
import { renderTextField,renderSubmitGroup, renderDates,renderSelectField, renderFileInput,renderField,  renderAsyncAutocomplete,renderPlaceAutocomplete, renderDateTime, renderFieldWithButton } from '../components/formsElements/FormComponents';
import {  AddPublicationResetMessages, handleAddExperience, handleAddFile, handleAddLangue, handleAddPublication, handleDeletePublication, handleGetProfilePublication } from '../actions/profile';
import { UncontrolledAlert } from 'reactstrap';
import logo from '../assets/imgs/spinner.gif';
import FontAwesome from 'react-fontawesome';
import { LocationSearchInput } from 'components/formsElements/FieldsComponents';
import TextField from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import React,{useEffect, useState} from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid } from '@material-ui/core';

import IntlMessages from "util/IntlMessages";
import DeleteIcon from '@material-ui/icons/Delete';
import {Alert} from '@material-ui/lab';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const loader=<CircularProgress  color="primary"/>;


const initState={
    title:'',
    publication_url:'',
  }

  function sleep(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  }
  function AddPublication( props,submitError, serverError , params , handleSubmit) {

    const [state, setState] = useState(initState);
    const [openDialog, setOpenDialog] = React.useState(false);
    const spinner = useSelector(state=>state.profile.add_publication.spinner)
    const error = useSelector(state=>state.profile.add_publication.error)
    const message = useSelector(state=>state.profile.add_publication.message)
    const [open, setOpen] = React.useState(false);
    const {userId} =useParams();
    const [inputValue, setInputValue] = React.useState('');
 
   
    const dispatch = useDispatch();
  
    const handleClickOpen = () => {
        setOpen(true);
      };
    const handleClose = () => {
        setOpen(false);
        dispatch(AddPublicationResetMessages())
      };

    const handleChange =(event)=>{
        setState({...state, [event.target.name]:event.target.value});
      }
      const handleRemovePublication =(publicationId)=> {
        const saveData={
         // userId:this.props,
         publicationId:publicationId
        }
        console.log("removeSector response : ", saveData);
        dispatch(handleDeletePublication(saveData))
        }
  const submit=(event)=>{
    event.preventDefault();
   console.log("Data" , state)
   dispatch(handleAddPublication({...state}))
  }

  const {t} = props;


    return (
      
      <div className="">
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
      <Icon className="fa fa-plus-circle" style={{ color: "#ccc"}} /> 
    </Button>
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {<IntlMessages id="add_publications"/>}
      </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <div className="row">
                {message ?<Alert severity="success"  onClose={()=>dispatch(AddPublicationResetMessages())}>{message}</Alert>: null}
               {serverError ?<Alert  severity="success"   onClose={()=>dispatch(AddPublicationResetMessages())}>{serverError.message}</Alert>: null}
                {error ?<Alert severity="danger"  onClose={()=>dispatch(AddPublicationResetMessages())}>{error}</Alert>: null}
           
               <div className="col-lg-12">
               <Grid item xs={12}>
                     <h4 className="title form-publication">{<IntlMessages id="publications"/>}</h4>
                     {props.publications? props.publications.map((publication, index) =>
                                    <div><small>{publication.title}, {publication.publication_date ? publication.publication_date.substring(0,4): null }  
                                    <a href={publication.publication_url} target="blank">  {t('wutiko.open')}</a>
                            <DeleteIcon  onClick={e => handleRemovePublication(publication.id)} style={{ color: "#ccc"}} /> </small>
                            </div>
                            )  :null}  

                </Grid>
               <Form onSubmit={submit}>                            
               <div>
               <Grid item xs={12}>
                      <TextField
                        required
                        id="title"
                        name="title"
                        label={<IntlMessages id="add_publication_title"/>}
                        fullWidth
                        autoComplete={<IntlMessages id="add_publication_title"/>}
                        onChange={handleChange}
                        multiLine={true} rows={2}
                        value={state.title}
                    />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField
                        required
                        id="publication_url"
                        name="publication_url"
                        label={<IntlMessages id="add_publication_url"/>}
                        fullWidth
                        autoComplete={<IntlMessages id="add_publication_url"/>}
                        onChange={handleChange}
                        multiLine={true} rows={2}
                        value={state.publication_url}
                    />
                    </Grid>
           </div>
           
           <Row>
             <div className="col-md-12">
                  {spinner?loader:<button name="submit" type="submit" className="register-training save  pull-right"  >{t('wutiko.save')}</button>}
             </div>
           </Row>
          <br/>
        </Form>

              </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>

   
      </div>
    );
  }


  AddPublication = reduxForm({
  form: 'AddPublication',
  enableReinitialize: true
})(AddPublication);

export default withTranslation('translation')(AddPublication);