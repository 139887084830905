import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
// import { Parser } from 'html-to-react';
import {  Button } from 'react-bootstrap';
// import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import {useParams} from 'react-router-dom'
import Chip from '@material-ui/core/Chip';
import React,{useEffect, useState} from 'react';
import { useSelector, useDispatch} from 'react-redux'
import { withTranslation } from 'react-i18next';
import { handleGetProfileSkills , handleGetProfileSectors, handleDeleteSectors, handleDeleteSkills } from '../../../actions/profile';
import AddSkills from 'containers/Profil/AddSkills';
import AddSectors from 'containers/Profil/AddSectors';
import IntlMessages from "util/IntlMessages";
const DEFAULT_NUMBER_SKILL_DISPLAYED = 3;
const DEFAULT_NUMBER_INDUSTRY_DISPLAYED = 3;

export default function ProfileSkillsPublic( {props,t,handleSubmit }) {
    const {userId} =useParams();
    const Skills = useSelector(state=>state.profile.skills.list);
    const skills = useSelector(state=>state.profile.skills.list);
    const Sectors = useSelector(state=>state.profile.sectors.list);
    
    console.log("ListSkills", Skills )
    console.log("ListSectors", Sectors )
    const dispatch = useDispatch();
    useEffect(()=>{
            dispatch(handleGetProfileSkills());
            dispatch(handleGetProfileSectors());
              },[dispatch]);



  const nSkill =  DEFAULT_NUMBER_SKILL_DISPLAYED;     
  const nIndusry = DEFAULT_NUMBER_INDUSTRY_DISPLAYED ;
  const showAll = ()=> {
    this.setState({ nSkill: Skills.length })
  }

    const hideAll = ()=> {
    this.setState({ nSkill: DEFAULT_NUMBER_SKILL_DISPLAYED })
  }

  const showAllIndustry = ()=> {
    this.setState({ nIndusry: Sectors.length })
  }

  const hideAllIndustry = ()=> {
    this.setState({ nIndusry: DEFAULT_NUMBER_INDUSTRY_DISPLAYED })
  }
  
const handleRemoveSector = (sectorId) =>{
  const saveData={
    userId:userId,
    sectorId:sectorId
  }
  console.log("removeSector response : ", saveData);
  dispatch(handleDeleteSectors(saveData))
  }
  const handleRemoveSkill = (skillId) =>{
    const saveData={
      userId:userId,
      skillId:skillId
    }
    console.log("removeSector response : ", saveData);
    dispatch(handleDeleteSkills(saveData))
    }
  
    return (
      <div className="bloc content-page-interne skills col-md-12"  data-aos="fade-up">
      
           <div className="">

                <div className="row">
                    <div className="col-md-6 col-6">
                      <h2  className="text-left title">{<IntlMessages id="skills"/>}</h2>
                      </div>
             
                     <div className="col-md-6 col-6">{/*<a className="all-skill" href="">3 skills in review</a>*/}
                    <div className="pull-right">
                    </div>
                 </div>
                </div>
                <div className="skill"  data-aos="fade-up">
                    <div className="col-md-12 col-12">

                  <div className="skills-endorsed"  data-aos="fade-up">
                   
                    <div className="row">
                    <div className="col-md-6"> 
                     <h2 className="title">
                      {<IntlMessages id="other_skills_not_endorsed"/>} 
                    </h2>
                    </div>
                    <div className="text-right col-md-6"> 
                    </div> 
                    </div>


                    <div className="col-md-12 col-12">

                    {skills?
                    skills.length !== 0 ?
                      
                        skills.slice().map((skill) => {
                            return(
                                <Chip label={skill.is_certified===false ? skill.skill_id.name : null}  color="#0066b4" variant="outlined"/>
                            )
                            }):[]:[]}

                            
                      {/* {Skills ? renderListSkill(nSkill) : null}
                      {Skills.length > 3 ?
                      nSkill === DEFAULT_NUMBER_SKILL_DISPLAYED ?
                      <Button bsStyle="link" onClick={this.showAll} className="neutre">
                      {t('wutiko.see_all')}
                     </Button>
                      :
                      <Button bsStyle="link" onClick={this.hideAll} className="neutre">
                       {t('wutiko.hide')} 
                      </Button>
                      :
                 null}  */}
              </div>
              </div>


            <div className="skills-endorsed"  data-aos="fade-up">
                   


                <div className="row">
                 <div className="title col-md-6"> <h2 className="title"> 
                      {<IntlMessages id="industries"/>}</h2> </div>     
                 <div className="col-md-6 text-right"> 
                  </div>
                 </div>
              <div className="col-md-12 col-12">
               { Sectors?
               Sectors.length !==0 ?
               Sectors.slice().map((industry) => {
                    return(
                     <Chip label={industry.industry_id.name} color="#0066b4" variant="outlined" 
                     />
                        )
                    }): []:[]}
                    {/* onDelete={e =>dispatch(handleDeleteSectors(userId,industry.id))} */}
                  {/* {Sectors ? renderListIndustry(nIndusry) : null}
                 {Sectors.length > 3 ?
                  
                nIndusry === DEFAULT_NUMBER_INDUSTRY_DISPLAYED ?
                <Button bsStyle="link" onClick={showAllIndustry} className="neutre">
                {t('wutiko.see_all')}
               </Button>
              :
                <Button bsStyle="link" onClick={hideAllIndustry} className="neutre">
                {t('wutiko.hide')}
                </Button>

               : null}  */}
              </div>
              </div>
              </div>
              </div>
        </div>
        
        {/* <div className="bloc content-page-interne skills">
                <div className="row">
                    <div className="col-md-6 col-6"><h6  className="pull-left">{t("wutiko.skills")}</h6></div>
                </div>
                <div className="skill">
                    <div className="col-md-12 col-12">

                  <div className="skills-endorsed">
                    <h6 className="title col-md-12 col-12">{t("wutiko.other_skills_not_endorsed")}</h6>
                    <div className="col-md-12 col-12">
                {Skills ? this.renderListSkill(Skills.length) : null}
                
              </div>
              </div>


            <div className="skills-endorsed">
              <h6 className="title col-md-12 col-12">{t("wutiko.industry")} </h6>
              <div className="col-md-12 col-12">
                  {Sectors ? this.renderListIndustry(Skills.length) : null}
              </div>
              </div>
              </div>
              </div>
        </div> */}
        
    </div>
   );
}

ProfileSkillsPublic = withTranslation('translation')(ProfileSkillsPublic);
