
const initialState = {
    filter_opportunity:{
        error:null,
        spinner:false,
        count:null,
        opportunities:[]
    },

 }

 export function filteropportunity(state = initialState, action){
 
   switch(action.type) {

    case "GET_OPPORTUNITY_FILTER_FAILLURE":
            return {...state, filter_opportunity:{
                ...state.filter_opportunity, error:action.payload, spinner:false, opportunities:[]
            }}
   case "GET_OPPORTUNITY_FILTER":
           return {...state, filter_opportunity:{
               ...state.filter_opportunity, error:null, spinner:false, opportunities:action.payload.results
           }}
     default:
       return state;
       
   }
 }
