import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {useDispatch, useSelector} from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/lab/Pagination';
import Opportunity from 'components/uielements/Opportunity';
import OpportunitiesFilters from 'containers/filters/OpportunitiesFilters';
import { handleOpportunitiesSearch } from 'actions/search';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import IntlMessages from "util/IntlMessages";
import ReactPaginate from 'react-paginate';
import { handleResetcreateCompany } from 'actions/companies/createCompany';
import { handleResetuploadCvState } from 'actions/uploadCv';
import { addOpportunityResetMessages } from 'actions/opportunities';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {Link} from "react-router-dom";
import Typical from 'react-typical';
import {Animated} from "react-animated-css";
import AddOpportunityNotAuth from 'components/opportunities/AddOpportunityNotAuth';
import CreateCompany from 'components/companies/CreateCompany';
import UploadCV from 'components/uploadCV/UploadCV';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding:15,
    paddingLeft:24,
    paddingRight:24,
    width:'100%',
  },
  ul:{
    'list-style-type': 'none',
    paddingLeft: 0,

  },
  btn:{
    backgroundColor:"#ff9801",
    '&:hover':{
      backgroundColor:"#ff9801",
    }
  }
}));

export default function OpportunitiesAll() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const state = useSelector(state=>state.opportunities.all_opportunities);
    const count = useSelector(state=>state.opportunities.all_opportunities.count);
    const opportunities=state.list.opportunities?state.list.opportunities:[];
    const [open, setOpen]=React.useState(false)
    const [openompany, setopenCompany]=React.useState(false)
    const [openOpportunity, setopenOpportunity]=React.useState(false)
    const [currentPage, setcurrentPage] = React.useState(1);
    const RESULTS_PER_PAGE = 12;


    const handleOpenModal= ()=>{
      setOpen(true);
      
    }
    const handleClose=()=>{
      setOpen(false)
      dispatch(handleResetuploadCvState())
    }
    const handleCloseComp=()=>{
      setopenCompany(false)
      dispatch(handleResetcreateCompany())
  
    }
    const handleCloseOpp=()=>{
      setopenOpportunity(false)
      dispatch(addOpportunityResetMessages())
    }

    
    useEffect(()=>{
      dispatch(handleOpportunitiesSearch({scope:"opportunity", search:`${window.location.search}`, page:`${currentPage}`}))
    },[dispatch])


    const handlePageClick=(newPage) =>{
      dispatch(handleOpportunitiesSearch({scope:"opportunity",search:`${window.location.search}`, page:`${newPage.selected + 1}`}));
    
      setcurrentPage(newPage.selected + 1)
    } 

    let pageCount;
    let onPageChange;


    pageCount = Math.ceil(count / RESULTS_PER_PAGE);
    onPageChange = handlePageClick;
    return (
    <div className="container list-opportunities">
      
     
      <div className="container-filter">

      <Container maxWidth={false} className={classes.cont}>
            <Typography 
                component="h1"
                variant="h5"
                color="inherit"
                align="center"
                noWrap
                className={classes.title}>
                 {<IntlMessages id="list_of_all_opportunities"/>}
          </Typography>
          </Container>

        <Grid  xs={12} className="total-opportunities">
          {<IntlMessages id="count_profiles" values={{ count: state.count}}/>}
        </Grid>
       
        <Grid className="container filter-opportunities">
         <OpportunitiesFilters/>
        </Grid>
       { state.spinner?null:
        count <= 0 ?

        <>
        <div maxWidth={true} className="makeStyles-cont-111">
        <main className="container">
          <UploadCV open={open} handleClose={handleClose}/>
          <CreateCompany open={openompany} handleClose={handleCloseComp}  />
          <AddOpportunityNotAuth open={openOpportunity} handleClose={handleCloseOpp}/>
          <Grid container spacing={4}>
          <Grid item xs={12} md={6} xs={12} sm={6}>
          <Animated  animationIn="fadeInUp" animationOut="fadeOut">
                  <Card className="card-1">
                    <CardActions>
                    <fieldset className="bloc-home">
                    <legend className={classes.legend}>   {<IntlMessages id="i_m_looking_for_an_opportunity"/>} </legend>
                    <Grid item container spacing={2}> 
                    <Grid item md={12} xs={12}  sm={12} container justify="flex-start">
                    </Grid>
                        <Grid item md={7} xs={12}  sm={6} container justify="flex-start">
                        <Link to="/opportunities" className="list-opp">
                        <Button fullWidth variant="contained" className={classes.btn_y} color="warning" >{<IntlMessages id="seize_an_opportunity"/>} </Button>
                        </Link>
                        </Grid>
                        <Grid item md={5} xs={12}  sm={6} container justify="flex-end">
                        <Button onClick={handleOpenModal} fullWidth variant="contained" className="transp" justify="center">{<IntlMessages id="upload_my_cv"/>}</Button>
                        </Grid>
                    </Grid>
                    </fieldset>
                    </CardActions>
                  </Card>
                </Animated>
            </Grid>

        <Grid item xs={12} md={6} xs={12} sm={6}>
          <Animated  animationIn="fadeInUp" animationOut="fadeOut">
            <Card className="card-2"> 
                <CardActions>
                  <fieldset className="bloc-home">
                    <legend className={classes.legend}>{<IntlMessages id="i_m_providing_an_opportunity"/>} </legend>
                    <Grid item container spacing={2}> 
                    <Grid item md={12} xs={12} container justify="flex-start">
                    </Grid>
                        <Grid item md={5} xs={12} sm={6} container justify="flex-start">
                       
                        <Button onClick={()=>setopenCompany(true)} fullWidth variant="contained"  className="transp" color="warning"> {<IntlMessages id="create_my_page"/>}</Button>
                        </Grid>
                        <Grid item md={7} xs={12} sm={6} container justify="flex-end">
                            <Button onClick={()=>setopenOpportunity(true)} fullWidth variant="contained" className="btn_y" justify="center">{<IntlMessages id="post_an_opportunity"/>}</Button>
                        </Grid>
                    </Grid>                    
                  </fieldset>
                </CardActions>
            </Card>
        </Animated>
        </Grid>
          </Grid>
        </main>
    </div></>:null}


 

      </div>
      <div>
        <Grid container spacing={2}>
        {
          state.spinner?
          <Grid container item justify="center" xs={12}><CircularProgress size={80}/></Grid>:
          opportunities.map((item)=>{
            return(
              <Grid item md={4} xs={12}>
                  <Opportunity data={item}/>
             </Grid>
            )
          })
        }
        </Grid>
        <Grid item md={12} xs={12} container justify="center" className="Listpagination">
        <ReactPaginate
        previousLabel={count > 0? <IntlMessages id="previous" />:null}
        nextLabel={count > 0?<IntlMessages id="next" />:null}
        pageCount={count > 0?pageCount:null}
        onPageChange={count > 0?handlePageClick:null}
        containerClassName={count > 0? "pagination":null}
        previousLinkClassName={count > 0?  "pagination__link":null}
        nextLinkClassName={count > 0?"pagination__link":null}
        disabledClassName={count > 0?"pagination__link--disabled":null}
        activeClassName={count > 0?"pagination__link--active":null}
        />
      </Grid>
      </div>
      

      
        
       
       
    </div>
  );
}

OpportunitiesAll.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};





















