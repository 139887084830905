import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { deletePublicationSuccess, deletePublicationError } from  "actions/profile";

const DeletePublicationRequest = async (instance,data)=>
    await instance.put(`/profiles/publications/${data.publicationId}/remove/`)
    .then(response=>response)
    .catch((error)=>{


        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* DeletePublication(actions){
    const state = yield select((state)=>state.users);
   
    let axiosInstance;
    if(state.authenticated){
        axiosInstance=state.axiosInstance
    }else{
        axiosInstance=state.blankAxiosInstance
    }
    const data =yield call(DeletePublicationRequest,axiosInstance, actions.data);
    if(data){

    console.log("Delete" , "data.message")
        yield put({type:"HANDLE_GET_PROFILE_PUBLICATION"})
        console.log("HANDLE_GET_PROFILE_PUBLICATION")
        yield put(deletePublicationSuccess(data.message));
    }else{
        yield put(deletePublicationError(data.error));
    }
    
}
export function* handleDeletePublication(){
    console.log("Delete" , "Delete Publication")
    yield takeEvery("HANDLE_DELETE_PUBLICATION", DeletePublication)
}
export default function* rootSaga() {
    yield all([fork(handleDeletePublication)]);
}
//