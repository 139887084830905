import React,{useState} from 'react';
import Typography from '@material-ui/core/Typography';
import { Container, Button } from '@material-ui/core';
import { initState } from './initState';
import Choices from './Choices';
import FieldStructure from './FieldStructure';
import {v4 as uuid} from "uuid";
import {FormBuilderStyles} from './FormsElementsStyles'
import { getFormStructToPreview, renderChoices } from './utils/getForm2PreView';
import {useDispatch, useSelector} from 'react-redux'
import { handlePreViewForm } from 'actions/forms/prevViewForm';
import { PreViewForm } from './PreViewForm';
import {useParams} from 'react-router-dom'
import { handleAddFielsd2Form, ddFielsd2FormResetMessages } from 'actions/forms/addFields2Form';
import { Alert } from '@material-ui/lab';

/**
 * This component manages the form construction, it calls FieldStructure component
 * which represents a field, here a form is a set of fields. All actions that the user can 
 * perform on a field are implemented in this component and they are sent to the field as props.
 */
export default function FormBuilder() {
const {formId}=useParams()
const classes = FormBuilderStyles();
const [state, setState]=useState(initState);
const formData =useSelector(state=>state.preViewForm.pre_view_form)
const addFieldsData = useSelector(state=>state.addFields2Form.add_fields_2_form)
const dispatch = useDispatch()
console.log("formData", formData)
const onChangeValue=(e,fieldId)=>{
    setState({...state,fields:{
      ...state.fields, [fieldId]:{
        ...state.fields[fieldId], [e.target.name]:e.target.name==="order"?parseInt(e.target.value):e.target.value
      }
    }})
}
const handleAddFields2Form=()=>{
  const formFields=Object.keys(state.fields).map(item=>{
    return {
      field_type:state.fields[item]['field_type'],
      order:state.fields[item]['order'],
      style_class:state.fields[item]['style_class'],
      description:state.fields[item]['description'],
      help_text:state.fields[item]['help_text'],
      required:state.fields[item]['required'],
      choices:renderChoices(state.fields[item]['choices']), 
      label:state.fields[item]['label']?state.fields[item]['label']:item, 
      placeholder_text:state.fields[item].placeholder}
  })
  dispatch(handleAddFielsd2Form({formFields:formFields, formId:formId}))
}
const onChangeValueBool=(e,fieldId)=>{
  setState({...state, fields:{
    ...state.fields, [fieldId]:{
      ...state.fields[fieldId], [e.target.name]:!state.fields[fieldId][e.target.name]
    }
  }})
}
const pushUp=(prev, field, next)=>{
  var data=state.fields
  var data_c={}
  Object.keys(data).map((item)=>{
    if(item===next){
      data_c[field]=data[field]
    }else if(item==field){
      data_c[next]=data[next]
    }else{
      data_c[item]=data[item] 
    }
  })
  setState({...state, fields:data_c})
}

const pushDown=(prev, field, next)=>{
  var data=state.fields
  var data_c={}
  Object.keys(data).map((item)=>{
    if(item===prev){
      data_c[field]=data[field]
    }else if(item==field){
      data_c[prev]=data[prev]
    }else{
      data_c[item]=data[item] 
    }
  })
  setState({...state,fields:data_c})
}

const hanRemoveField=(fieldId)=>{
    var data=state.fields;
    delete data[fieldId]
    setState({...state, fields:data})
  }
  const handleOnChange =(e)=>{
    const choices={}
    choices[`Choice_${uuid()}`]='Option_1';
    choices[`Choice_${uuid()}`]='Option_2';
    const data={
      field_type:e.target.value,
      label:"",
      order:Object.keys(state.fields).length,
      style_class:"col-lg-12 col-md-12",
      description:"",
      help_text:"",
      "placeholder":"",
      required:false,
      is_visible:true,
      choices:parseInt(e.target.value)==8 || parseInt(e.target.value)===5 || parseInt(e.target.value)===6?choices:""
    }
    setState({...state, fields:{...state.fields,[`field_${uuid()}`]:data}})
  }
  const handleRemoveOption=(fieldId,optionId)=>{
    const fields=state.fields;
    delete fields[fieldId].choices[optionId];
    setState({...state, fields:fields});
  }
  const handleAddOption = (e, fieldId)=>{
    e.preventDefault()
  try {
      const fields=state.fields;
      fields[fieldId].choices[`Choice_${uuid()}`]=`Choice_${Object.keys(state.fields[fieldId].choices).length+1}`;
      setState({...state, fields:fields});        
  }catch (error) {
      const fields=state.fields;
      var data={}
      fields[fieldId].choices.split('[(%').map((item,key)=>{
          return data[`Choice_${uuid()}`]=item
      })
      data[`Choice_${uuid()}`]=`Option_${Object.keys(data).length+1}`
      fields[fieldId].choices=data;
      setState({...state, fields:fields}); 
  }
 }
 const onChangeChoiceValue=(e,field,elt)=>{
  const fields =state.fields;
  fields[field].choices[elt]=e.target.value;
  setState({...state,fields:fields});
}
  return (
        <>
        <Container maxWidth={false} className={classes.cont}>
        <main className={classes.main}>
        <Typography
          component="h1"
          variant="h5"
          color="inherit"
          align="center"
          noWrap
          className={classes.title}
         >
            Form Builder  
         </Typography>
        </main>
      </Container>
      <div className="row"> 
          <div className="col-md-12 col-sm-12 col-12 animation">
            {addFieldsData.message?<Alert severity="success" onClose={()=>dispatch(ddFielsd2FormResetMessages())}>{addFieldsData.message}</Alert>:null}
            {addFieldsData.error?<Alert severity="error" onClose={()=>dispatch(ddFielsd2FormResetMessages())}>{addFieldsData.error}</Alert>:null}
            {
              Object.keys(state.fields).map((fieldId,key)=>{
                          return(
                            <FieldStructure 
                                field={state.fields[fieldId]} 
                                fieldId={fieldId} 
                                state={state} 
                                onChangeValue={onChangeValue} 
                                id={key+1}
                                pushDown={pushDown}
                                pushUp={pushUp}
                                hanRemoveField={hanRemoveField}
                                numberAllFields={Object.keys(state.fields).length}
                                handleRemoveOption={handleRemoveOption}
                                handleAddOption={handleAddOption}
                                onChangeChoiceValue={onChangeChoiceValue}
                                onChangeValueBool={onChangeValueBool}
                                prevFieldId={Object.keys(state.fields)[key-1]}
                                nextFieldId={Object.keys(state.fields)[key+1]}
                                fieldIcon={state.fields_icons[parseInt(state.fields[`${fieldId}`].field_type)]}
                            />
                          )
                        })
            }
          </div>
          <div className="col-md-2 col-sm-2 col-12 animation slideInLeft">
            <Choices choices={state.choices} onChange={handleOnChange}/>
          </div>
          <div className="col-md-11 col-sm-11 col-12 animation slideInRight">
           <Button onClick={()=>dispatch(handlePreViewForm(getFormStructToPreview(state)))}>PreView</Button>
          </div>
          <div>
            <Button disabled={addFieldsData.spinner} variant="contained" color="primary" onClick={handleAddFields2Form}>Add to form</Button>
          </div>
          <div className="col-md-12">
            <PreViewForm/>
          </div>
      </div>
   </>
  );
}





