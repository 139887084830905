const initialState = {
     featured_opportunities:{
         error:null,
         spinner:false,
         list:[]
     },
     all_opportunities:{
         list:{},
         spinner:false,
         error:null,
         count:0
     },
     add_opp_ano:{
         spinner:false,
         error:null,
         message:null
     },
     add_opp_ano_draft:{
        spinner:false,
        error:null,
        message:null
    },
     share_opp:{
        spinner:false,
        error:null,
        message:null
    },
     get_opp_details:{
         spinner:false,
         error:null,
         details:null,
         
     },
     saveOpportunity:{
        spinner: null,
        error: null,
        message:null,
    },
    applyOpportunity:{
        spinner: null,
        error: null,
        message:null,
    
     },
     resume_check:{
         error:null,
         spinner:false,
         data:{}
     },
     edit_opportunity:{
        spinner:false,
        error:null,
        message:null
    },
    edit_save_opportunity:{
       spinner:false,
       error:null,
       message:null
    },
    get_edit_opportunity:{
        details:{},
        spinner: false,
        error: null,
        message:null,
    },
    statusOpportunity:{
    spinner: false,
    error: null,
    message:null,
  },
  selectPipeline:{
    spinner: false,
    error: null,
    message:null,
  },
}

  export function opportunities(state = initialState, action){
  
    switch(action.type) {
   
    
   
        // case ADD_FAVOURITE: {
        //     return {
        //       ...state,
        //       alertMessage: action.payload.starred ? 'Contact removed as star' : 'Contact marked as star',
        //       showMessage: true,
        //       contactList: state.contactList.map((contact) => contact.id === action.payload.id ? {
        //         ...contact,
        //         starred: !action.payload.starred
        //       } : contact),
        //       allContact: state.allContact.map((contact) => contact.id === action.payload.id ? {
        //         ...contact,
        //         starred: !action.payload.starred
        //       } : contact)
        //     };
        //   }

/**
  * Get Edit company
  */

 case "HANDLE_GET_EDIT_OPPORTUNITY_DETAILS":
     return{
         ...state, get_edit_opportunity:{
             ...state.get_edit_opportunity, error:null, spinner:true
         }
 }
 case "GET_EDIT_OPPORTUNITY":
     return{
         ...state, get_edit_opportunity:{
             ...state.get_edit_opportunity, error:null, spinner:false, details:action.payload
         }
 }
 case "GET_EDIT_OPPORTUNITY_FAILLURE":
         return{
             ...state, get_edit_opportunity:{
                 ...state.get_edit_opportunity, error:action.payload, spinner:true, details:null
             }
     }

case "GET_EDIT_OPPORTUNITY_RESET_MESSAGES":
    return{
        ...state, get_edit_opportunity:{
            ...state.get_edit_opportunity, error:null, spinner:false, message:null
        }
    } 

    
/**
  * Edit opportunity
  */
 case "HANDLE_EDIT_OPPORTUNITY":
    return{
        ...state, edit_opportunity:{
            ...state.edit_opportunity, error:null, spinner:true, message:null
        }
}

case "EDIT_OPPORTUNITY":
    return{
        ...state, edit_opportunity:{
            ...state.edit_opportunity, error:null, spinner:false, message:action.payload
        }
    }

case "EDIT_OPPORTUNITY_FAILLURE":
    return{
        ...state, edit_opportunity:{
            ...state.edit_opportunity, error:action.payload, spinner:false, message:null
        }
    }
case "EDIT_OPPORTUNITY_RESET_MESSAGES":
    return{
        ...state, edit_opportunity:{
            ...state.edit_opportunity, error:null, spinner:false, message:null
        }
    } 



       
/**
  * Edit SAVE opportunity
  */
 case "HANDLE_SAVE_EDIT_OPPORTUNITY":
    return{
        ...state, edit_save_opportunity:{
            ...state.edit_save_opportunity, error:null, spinner:true, message:null
        }
}

case "EDIT_SAVE_OPPORTUNITY":
    return{
        ...state, edit_save_opportunity:{
            ...state.edit_save_opportunity, error:null, spinner:false, message:action.payload
        }
    }

case "EDIT_SAVE_OPPORTUNITY_FAILLURE":
    return{
        ...state, edit_save_opportunity:{
            ...state.edit_save_opportunity, error:action.payload, spinner:false, message:null
        }
    }
case "EDIT_SAVE_OPPORTUNITY_RESET_MESSAGES":
    return{
        ...state, edit_save_opportunity:{
            ...state.edit_save_opportunity, error:null, spinner:false, message:null
        }
    } 


    /**
     * Get opportunity details with slug
     */
    case "HANDLE_GET_OPPORTUNITY_DETAILS":
        return{
            ...state, get_opp_details:{
                ...state.get_opp_details, error:null, spinner:true
            }
    }
    case "GET_OPPORTUNITY_DETAILS":
        return{
            ...state, get_opp_details:{
                ...state.get_opp_details, error:null, spinner:false, details:action.payload
            }
    }
    case "GET_OPPORTUNITY_DETAILS_FAILLURE":
            return{
                ...state, get_opp_details:{
                    ...state.get_opp_details, error:action.payload, spinner:true, details:null
                }
        }
  


       /**
     * Opportunity resume check
     */
    case "HANDLE_RESUME_CHECK":
        return{
            ...state, resume_check:{
                ...state.resume_check, error:null, spinner:true, data:{}
            }
    }
    case "RESUME_CHECK":
        return{
            ...state, resume_check:{
                ...state.resume_check, error:null, spinner:false, data:action.payload
            }
    }
    case "RESUME_CHECK_FAILLURE":
            return{
                ...state, resume_check:{
                    ...state.resume_check, error:action.payload, spinner:false, data:{}
                }
        }
    case "RESUME_CHECK_RESET_MESSAGES":
        return{
            ...state, resume_check:{
                ...state.resume_check, error:null, spinner:false, data:{}
            }
        }
  



          /**
     * Add opportunity draf
     */
    case "HANDLE_ADD_ANONYMOUSLY_OPPORTUNITY_DRAFT_HOME":
        return{
            ...state, add_opp_ano_draft:{
                ...state.add_opp_ano_draft, error:null, spinner:true, message:null
            }
    }
    
    case "HANDLE_ADD_ANONYMOUSLY_OPPORTUNITY_HOME":
        return{
            ...state, add_opp_ano:{
                ...state.add_opp_ano, error:null, spinner:true, message:null
            }
    }
    case "HANDLE_ADD_ANONYMOUSLY_OPPORTUNITY_DRAFT":
        return{
            ...state, add_opp_ano_draft:{
                ...state.add_opp_ano_draft, error:null, spinner:true, message:null
            }
    }
 

    case "ADD_ANONYMOUSLY_OPPORTUNITY_DRAFT":
        return{
            ...state, add_opp_ano_draft:{
                ...state.add_opp_ano_draft, error:null, spinner:false, message:action.payload
            }
        }

    case "ADD_ANONYMOUSLY_OPPORTUNITY_DRAFT_FAILLURE":
        return{
            ...state, add_opp_ano_draft:{
                ...state.add_opp_ano_draft, error:action.payload, spinner:false, message:null
            }
        }
    case "ADD_ANONYMOUSLY_OPPORTUNITY_DRAFT_RESET_MESSAGES":
        return{
            ...state, add_opp_ano_draft:{
                ...state.add_opp_ano_draft, error:null, spinner:false, message:null
            }
        }    



          /**
     * Add opportunity anonymosly
     */
    
    case "HANDLE_ADD_ANONYMOUSLY_OPPORTUNITY":
        return{
            ...state, add_opp_ano:{
                ...state.add_opp_ano, error:null, spinner:true, message:null
            }
    }
 

    case "ADD_ANONYMOUSLY_OPPORTUNITY":
        return{
            ...state, add_opp_ano:{
                ...state.add_opp_ano, error:null, spinner:false, message:action.payload
            }
        }

    case "ADD_ANONYMOUSLY_OPPORTUNITY_FAILLURE":
        return{
            ...state, add_opp_ano:{
                ...state.add_opp_ano, error:action.payload, spinner:false, message:null
            }
        }
    case "ADD_ANONYMOUSLY_OPPORTUNITY_RESET_MESSAGES":
        return{
            ...state, add_opp_ano:{
                ...state.add_opp_ano, error:null, spinner:false, message:null
            }
        }    
    case "HANDLE_GET_FEATURED_OPPORTUNITIES":
            return {...state, featured_opportunities:{
                ...state.featured_opportunities, error:null, spinner:true, list:[]
            }}
    case "GET_FEATURED_OPPORTUNITIES_FAILLURE":
            return {...state, featured_opportunities:{
                ...state.featured_opportunities, error:action.payload, spinner:false, list:[]
            }}
    case "GET_FEATURED_OPPORTUNITIES":
       return {...state, featured_opportunities:{
           ...state.featured_opportunities, error:null, spinner:false, list:action.payload
       }}
    case "SET_ALL_OPPORTUNITIES":
        return {
            ...state, all_opportunities:{
                ...state.all_opportunities, error:null, spinner:false, list:action.payload.results, count:action.payload.count
            }
        }
    case "SET_ALL_OPPORTUNITIES_FAILLURE":
        return {
            ...state, all_opportunities:{
                ...state.all_opportunities, error:action.payload, spinner:false, list:{}, count:0
            }
        }
    case "HANDLE_SEARCH":
        return{
            ...state, all_opportunities:{
                ...state.all_opportunities, error:null, spinner:true, list:{}, count:0
        }}

    default:
        return state;

    case "HANDLE_SAVE_OPPORTUNITY":
            return {...state, saveOpportunity: {...state.saveOpportunity , spinner: true,  message: null  }};
    
    case "SAVE_OPPORTUNITY_SUCCESS":
        return {...state, saveOpportunity: 
            {...state.saveOpportunity , spinner: true,  
                message: action.payload }};
    case "SAVE_OPPORTUNITY_RESET_MESSAGES":
         return {
            ...state, saveOpportunity:{
                ...state.saveOpportunity,
                spinner:null, error:null, message:null
            }
         }
    case "SAVE_OPPORTUNITY_FAILLURE":
         return {...state, saveOpportunity: 
                {...state.saveOpportunity , 
                    spinner: true, 
                    error: action.payload.error || action.payload.message}}
        
    case "HANDLE_APPLY_OPPORTUNITY":
                    return {...state, applyOpportunity: {...state.applyOpportunity , spinner: true,  message: null  }};
            
            case "APPLY_OPPORTUNITY_SUCCESS":
                return {...state, applyOpportunity: 
                    {...state.applyOpportunity , spinner: null,  
                        message: action.payload }};
            case "APPLY_OPPORTUNITY_RESET_MESSAGES":
                 return {
                    ...state, applyOpportunity:{
                        ...state.applyOpportunity,
                        spinner:null, error:null, message:null
                    }
                 }
            case "APPLY_OPPORTUNITY_FAILLURE":
                    return {...state, applyOpportunity: 
                        {...state.applyOpportunity , 
                            spinner: null, 
                            error: action.payload || action.payload}};
              

                            
    /**
     * change the Opportunities spinner
     */

        case "HANDLE_STATUS_OPPORTUNITY":
                return {...state, statusOpportunity: {...state.statusOpportunity , spinner: true,  message: null  }};
        
        case "STATUS_OPPORTUNITY_SUCCESS":
            return {...state, statusOpportunity: 
                {...state.statusOpportunity , spinner: null,  
                    message: action.payload }};
        case "STATUS_OPPORTUNITY_RESET_MESSAGES":
                return {
                ...state, statusOpportunity:{
                    ...state.statusOpportunity,
                    spinner:null, error:null, message:null
                }
                }
        case "STATUS_OPPORTUNITY_FAILLURE":
                return {...state, statusOpportunity: 
                    {...state.statusOpportunity , 
                        spinner: null, 
                        error: action.payload || action.payload}};
            
                           

 /**
     * Select profile 
     */

    case "HANDLE_SELECT_PIPELINE_PROFILE":
        return {...state, selectPipeline: {...state.selectPipeline , spinner: true,  message: null  }};

case "SELECT_PIPELINE_PROFILE_SUCCESS":
    return {...state, selectPipeline: 
        {...state.selectPipeline , spinner: null,  
            message: action.payload }};
case "SELECT_PIPELINE_PROFILE_RESET_MESSAGES":
        return {
        ...state, selectPipeline:{
            ...state.selectPipeline,
            spinner:null, error:null, message:null
        }
        }
case "SELECT_PIPELINE_PROFILE_FAILLURE":
        return {...state, selectPipeline: 
            {...state.selectPipeline , 
                spinner: null, 
                error: action.payload || action.payload}};
    
                   
     /**
     * Share Opportunities
     */
    case "HANDLE_SHARE_OPPORTUNITY":
        return{
            ...state, share_opp:{
                ...state.share_opp, error:null, spinner:true, message:null
            }
    }

    case "SHARE_OPPORTUNITY":
        return{
            ...state, share_opp:{
                ...state.share_opp, error:null, spinner:false, message:action.payload
            }
        }

    case "SHARE_OPPORTUNITY_FAILLURE":
        return{
            ...state, share_opp:{
                ...state.share_opp, error:action.payload, spinner:false, error:null
            }
        }
    case "SHARE_RESET_MESSAGES":
        return{
            ...state, share_opp:{
                ...state.share_opp, error:null, spinner:false, message:null
            }
        }  
                    
  }
  }
