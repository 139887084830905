export function successSetFormData(data){
    return{
        type:"UPLOAD_CV",
        payload:data
    }
}
export function setFormDataError(data){
    return{
        type:"UPLOAD_CV_FAILLURE",
        payload: data
    }
}
export function handleResetuploadCvState(){
    return{
        type:"REMOVE_UPLOAD_CV_MESSAGES",
        payload:{}
    }
}

export function successSetFormDataOpp(data){
    return{
        type:"UPLOAD_CV_OPP",
        payload:data
    }
}
export function setFormDataOppError(data){
    return{
        type:"UPLOAD_CV_FAILLURE_OPP",
        payload: data
    }
}
export function handleResetuploadCvStateOpp(){
    return{
        type:"REMOVE_UPLOAD_CV_MESSAGES_OPP",
        payload:{}
    }
}