import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import {   FormGroup, FormControl, Col, Row, Alert } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import FormLabel from '@material-ui/core/FormLabel';
import DateTime from 'react-datetime';
import Radio from '@material-ui/core/Radio';
import {AsyncTypeahead } from 'react-bootstrap-typeahead';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import { FormHelperText } from '@material-ui/core';
import Cropper from 'react-cropper';
import '../../assets/css/cropper.css'
import '../../assets/css/react-datetime.css';
import logo_spinner from '../../assets/imgs/spinner.gif';
import ControlledEditor from "../../wysiwyg/ControlledEditor";
import RichTextEditor from 'react-rte';
// import { unemojify } from "node-emoji";
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
//import TextField from 'material-ui/core/TextField'
//import SelectField from 'material-ui/core/SelectField'
import { SelectField , TextField} from 'redux-form-material-ui';
import Dropzone from 'react-dropzone';
// import SelectField from 'material-ui/SelectField'
import Select from '@material-ui/core/Select';
import Clear from '@material-ui/icons/Clear';
import { IconButton } from '@material-ui/core';
import { InputGroup, InputGroupAddon} from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import PropTypes from 'prop-types'

export class renderDatePicker extends React.Component {
  
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.bool,
    }),
    placeholder: PropTypes.string,
  }

  static defaultProps = {
    placeholder: ''
  }

  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (date) {
    this.props.input.onChange(moment(date).format('YYYY-MM-DD'))
  }

  render () {
    const {
      input, placeholder,
      meta: {touched, error}
    } = this.props
    
    return (
      <div>
        <DatePicker
          {...input}
          placeholder={placeholder}
          dateFormat="YYYY-MM-DD"
          selected={input.value ? moment(input.value, 'YYYY-MM-DD') : null}
          onChange={this.handleChange}
        />
        {touched && error && <span>{error}</span>}
      </div>
    )
  }
}

// export default renderDatePicker




/**
 * DatePicker Input
 */
// export const DatePicker = compose(
//   withState('selectedDate', 'setSelectedDate', null),
//   withHandlers({
//     handleChange: ({ input, setSelectedDate }) => (date) => {
//       setSelectedDate(date)
//       input.onChange(date)
//     }
//   }),
//   lifecycle({
//     componentWillMount () {
//       if (this.props.input.value) {
//         this.props.setSelectedDate(moment(this.props.input.value, 'DD/MM/YYY'))
//       }
//     }
//   })
// )(({ input, meta, selectedDate, handleChange, ...rest }) => (
//   <DatePickerComponent
//     selected={selectedDate}
//     onChange={handleChange}
//     {...rest}
//   />
// ))

// /**
//  * DatePicker Field
//  */
// export const DatePickerField = withFormLabelField(DatePicker)

export const  renderDatePickerTest  = ({input, placeholder, defaultValue, meta: {touched, error} }) => (
  <div>
        <DatePicker 
          {...input} dateForm="MM/DD/YYYY" 
          selected={input.value ? moment(input.value) : null}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
           />
        {touched && error && <span>{error}</span>}
  </div>
);
export class  renderDates extends React.Component{
  constructor(props) {
    super(props)
    
    this.state = {
      startDate: new Date()
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(date) {
    this.setState({startDate: date});
  }
  render(){
    
    const validationState = (this.props.meta.touched &&  (this.props.meta.error && "error")) ||  null;
    return(
    <FormGroup validationState={validationState}>
      <FormLabel><div>{this.props.label}<b style={{color:'red'}}>{this.props.required?'*':null}</b></div></FormLabel>
      <div>
        <DatePicker
            {...this.props}
            selected={this.props.selected}
            onChange={this.props.onChange}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            dateFormat="yyyy/MM/dd"
            tabIndex={12}
            isClearable={true}
         />
        {this.props.help && <FormHelperText>{this.props.help}</FormHelperText>}
        {this.props.meta.error && <span>{this.props.meta.error}</span>}
      </div>
    </FormGroup>
    )
  }
}

export const renderTextArea = ({textarea, meta: { placeholder,touched,rows, error, warning }}) => (
    <div>
        <div>
            <span>{textarea}</span>
            <textarea {...textarea} placeholder={placeholder} rows={rows?rows:3}></textarea>
            {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
    </div>
);

export class FileField extends React.Component {
    
  handleDropOrClick = (acceptedFiles, rejectedFiles, e) => {
    let eventOrValue = e;
    let {input: {onChange, onBlur}} = this.props;
    if (e.type === 'drop') {
      if (acceptedFiles.length) {
        // FileList or [File]
        eventOrValue = (e.dataTransfer && e.dataTransfer.files) || acceptedFiles;
      } else {
        eventOrValue = null;
      }
    }
    onBlur(eventOrValue); // update touched
    onChange(eventOrValue); // update value
  }


  render() {
    let {input} = this.props;
    let {accept, multiple} = this.props;
    let selectedFile = (input && input.value && input.value[0]) || null;
    let dropzoneProps = {
      accept,
      multiple,
      onDrop: this.handleDropOrClick,
    };
    return (
      <div>
        <input type='hidden' disabled {...input} />
        {selectedFile? <span>{selectedFile.name}</span> : null}
        <Dropzone {...dropzoneProps} />  
      </div>
      );
  }
}


// const style={
//   opacity: 0
// }

export const renderSelectField = ({
  input,
  label,
  help,
  required,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <SelectField
    floatingLabelText={<div>{label}<b style={{color:'red'}}>{required?'*':null}</b></div>}
    errorText={touched && error}
    {...input}
    showAutocompleteThreshold={3}
    onChange={(event, index, value) => input.onChange(value)}
    children={children}
    {...custom}
    hintText={<b>{help}</b>}
  />
)

export class CheckboxGroup extends React.Component {

    checkboxGroup() {
        let { options, input} = this.props;

        return options.map((option, index) => {
            return (
            <div className="checkbox  checkbox-info" key={index}>
                    <input type="checkbox"
                           name={`${input.id}[${index}]`}
                           value={option.name}
                           checked={input.value.indexOf(option.id) !== -1}
                           onChange={(event) => {
                               const newValue = [...input.value];
                               if (event.target.checked) {
                                   newValue.push(option.id);
                               } else {
                                   newValue.splice(newValue.indexOf(option.id), 1);
                               }

                               return input.onChange(newValue);
                           }}/>
                            <label>
                    {option.name}
                </label>
            </div>)
        });
    }

    render() {
        return (
            <div>
                {this.checkboxGroup()}
            </div>
        )
    }
}





export const renderRadioGroup = ({ input, ...rest }) => (
  <RadioGroup {...input} {...rest}
    valueSelected={input.value}
    onChange={(event, value) => input.onChange(value)}
   
            />
)


// export const renderRadioGroupTest = ({ required,meta, label, input, ...rest }) => (
//   <Select {...input} {...rest}
//     value={[]}
//     multiple
//     onChange={(event, value) => input.onChange(value)}
//     label={<div>{'/////////////////////////////////////////'}<b style={{color:'red'}}>{required?'*':null}</b></div>}
//     >
//    </Select>
// )
export const renderRadioGroupTest = ({
  input,
  required,
  label,
  value,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <SelectField
    floatingLabelText={<div>{label}<b style={{color:'red'}}>{required?'*':null}</b></div>}
    errorText={touched && error}
    {...input}
    onChange={(event, index, value) => input.onChange(value)}
    children={children}
    {...custom}
  />
)
// required={true}
const styles = {
 

  underlineStyle: {
    borderColor: '#0064b2',
  },
  floatingLabelStyle: {
    color: '#9b9b9b',
  },
  floatingLabelFocusStyle: {
    color: '#0064b2',
  },
};

export const renderTextField = ({ input,required, label, meta: { touched, error }, ...custom }) => (
  <TextField hintText={label}
    floatingLabelText={<div>{label}<b style={{color:'red'}}>{required?'*':null}</b></div>}
  floatingLabelStyle={styles.floatingLabelStyle}
   underlineFocusStyle={styles.underlineStyle}
   floatingLabelFocusStyle={styles.floatingLabelFocusStyle}
    errorText={touched && error}
    {...input}
    {...custom}
  />
)

export const normalizeNumbers = (value) => {

  if(!value) {
    return value;
  }

  return value.replace(/[^\d]/g, '');
}

export const normalizeYear = (value) => {

  if(!value) {
    return value;
  }

  return value.replace(/[^\d]/g, '').slice(0,4);
}

export const normalizePhone = (value) => {

  if(!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  return `+${onlyNums}`
}

/**
 * Test if an email is a valid address, return true if is valid, false otherwise
 */
export const isValidEmail = (value) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
}

/**
 * Test if a list of emails separated by commas are valid address, return true if is valid, false otherwise
 */
export const areValidEmail = (value) => {
  return /^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+,*[\W]*)+$/i.test(value);
}

/**
 * Test if a password match these criteria :
 * - contains at least one letter (min or maj)
 * - contains at least one number
 * - is at least at least 8 characters long
 * Return true if is valid, false otherwise
 */
export const isValidPassword = (value) => {
  return /^(?=.*?[A-Za-z])(?=.*?[0-9])[A-Za-z0-9!$%@#£€*?&]{8,}$/.test(value);
}

export const renderField = props => {

  const validationState = (props.meta.touched &&
  (props.meta.error && "error")) ||  null;
  
  return(
    <FormGroup validationState={validationState}>
      <FormLabel><div>{props.label}<b style={{color:'red'}}>{props.required?'*':null}</b></div></FormLabel>
      <FormControl {...props}{...props.input} maxLength={props.maxLength || "100"}></FormControl>
      {props.help && <FormHelperText>{props.help}</FormHelperText>}
      {props.meta.touched && (props.meta.error && <span>{props.meta.error}</span>)}
    </FormGroup>
  )
}

export const EditorFieldComponent = props => {
   const validationState = (props.meta.touched &&
  (props.meta.error && "error")) ||  null;
const { placeholder, input: { onChange, value},  id } = props;
 

  return (
    <div>
    <FormLabel>{props.label}</FormLabel>
     <ControlledEditor
     validationState={validationState}
      id={id}
      disabled={false}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
        
    />
    <FormControl.Static>{props.text || props.input.value}</FormControl.Static>
      <FormControl {...props.input} maxLength={props.maxLength || "100"}></FormControl>
    {props.help && <FormHelperText>{props.help}</FormHelperText>}
      {props.meta.touched && (props.meta.error && <span>{props.meta.error}</span>)}
      </div>
  );
}


export class RichTextMarkdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.input.value === '' ?
                RichTextEditor.createEmptyValue() :
                RichTextEditor.createValueFromString(this.props.input.value, 'html')
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.input.value !== this.state.value.toString('html')) {
            this.setState({
                value: nextProps.input.value ?
                    RichTextEditor.createValueFromString(nextProps.input.value, 'html') :
                    RichTextEditor.createEmptyValue()
            });
        }
    }

    onChange(value) {
        const isTextChanged = this.state.value.toString('html') !== value.toString('html');
        this.setState({value}, e => isTextChanged && this.props.input.onChange(value.toString('html')));
    };

    render() {
      // export const renderField = props => {

        const validationState = (this.props.meta.touched &&
        (this.props.meta.error && "error")) ||  null;
      
      //   return(
      //     <FormGroup validationState={validationState}>
      //       <FormLabel>{props.label}</FormLabel>
      //       <FormControl {...props}{...props.input} maxLength={props.maxLength || "100"}></FormControl>
      //       {props.help && <FormHelperText>{props.help}</FormHelperText>}
      //       {props.meta.touched && (props.meta.error && <span>{props.meta.error}</span>)}
      //     </FormGroup>
      //   )
      // }
        return (
          <FormGroup validationState={validationState} style={{borderColor:'red'}}>
             <FormLabel>{this.props.label}{this.props.required?<b style={{color:'red'}}>*</b>:null}</FormLabel>
             <RichTextEditor value={this.state.value} onChange={this.onChange.bind(this)} />
             {this.props.meta.touched && (this.props.meta.error && <span><b>{this.props.meta.error}</b></span>)}
          </FormGroup>
        );
    }
}




export const renderFieldWithButton = props => {

  const validationState = (props.meta.touched &&
  (props.meta.error && "error")) ||  null;

  return(
    <FormGroup validationState={validationState}>
      <Row>
      <Col sm={10}>
        <FormControl {...props}{...props.input}></FormControl>
      </Col>
      <Col sm={1}>
        <Button onClick={props.onClick} bsStyle="primary" className="form neutre">
          <FontAwesome className='super-crazy-colors' name='pencil'/>
        </Button>
      </Col>
      </Row>
    </FormGroup>
  )
}

export const renderStaticText = props => (
  <FormGroup>
    <FormLabel>{props.label}</FormLabel>
      <FormControl.Static>{props.text || props.input.value}</FormControl.Static>
  </FormGroup>
)

export const renderSelect = props => {
  const validationState = (props.meta.touched &&
  (props.meta.error && "error")) ||  null;

  return (
    <FormGroup validationState={validationState}>
      <FormLabel><div>{props.label}<b style={{color:'red'}}>{props.required?'*':null}</b></div></FormLabel>
      <FormControl {...props} {...props.input} componentClass="select"></FormControl>
      {props.help && <FormHelperText>{props.help}</FormHelperText>}
      {props.meta.touched && (props.meta.error && <span>{props.meta.error}</span>)}
    </FormGroup>
  )
}

export const renderCheckbox = ({ input,required, label }) => (
  <Checkbox label={<div>{label}<b style={{color:'red'}}>{required?'*':null}</b></div>}
    checked={input.value ? true : false}
    onCheck={input.onChange}/>
)

export const renderCheckboxT = props => (
  <FormGroup>
    <div className="checkbox-circle">
      <Checkbox checked={props.input.value} onChange={props.input.onChange}>
        {props.text}
        {props.meta.error && <div>{props.meta.error}</div>}
      </Checkbox>

    </div>
  </FormGroup>
)

export const renderRadioButton = props => (
  <FormGroup>
    <FormLabel><div>{props.label}<b style={{color:'red'}}>{props.required?'*':null}</b></div></FormLabel>
    <Radio inline>
      {props.text}
      {props.touched && props.error && <span>{props.error}</span>}
    </Radio>
  </FormGroup>
)
/**
 * To be used  with Field.
 * Component that renders a small calendar allowing the user to pick a date.
 * Props :
 * -dateFormat: the date format that will be DISPLAYED in the form control. The params follow those
 * from momentjs (see http://momentjs.com/docs/)
 * Output Value : a momentjs Date (different from a plain Javascript date), providing usefull
 * methods to format the output. Carefull, the output is an object that cannot be read directly, you
 * need to use one of the provided methods (here : http://momentjs.com/docs/#/displaying/format/).
 * Tip : if no method is used on the output object, axios will convert it in the ISO format like
 * 2014-09-08T08:02:17-05:00, because it calls JSON.stringify() that calls the toJSON object's method.
 */
export const renderDateTime = props => {

  const validationState = (props.meta.touched &&
  (props.meta.error && "error")) ||  null;

  return (
    <FormGroup validationState={validationState}>
      <FormLabel><div>{props.label}<b style={{color:'red'}}>{props.required?'*':null}</b></div></FormLabel>
      <div>
        <DateTime
          // peekNextMonth
          // showMonthDropdown
          // showYearDropdown
          // dropdownMode="select"

          timeFormat={false}
          dateFormat={props.dateFormat}
          value={props.input.value ? props.input.value : "YYYY-MM-DD"}
          onChange={param => props.input.onChange(param)} />
        {props.help && <FormHelperText>{props.help}</FormHelperText>}
        {props.meta.error && <span>{props.meta.error}</span>}
      </div>
    </FormGroup>
  )
}

export const renderDateAndTime = props => {

  const validationState = (props.meta.touched &&
  (props.meta.error && "error")) ||  null;

  return (
    <FormGroup validationState={validationState}>
      <FormLabel><div>{props.label}<b style={{color:'red'}}>{props.required?'*':null}</b></div></FormLabel>
      <div>
        <DateTime
          timeFormat={false}
          dateFormat={"D MMMM YYYY HH:mm"}
          value={props.input.value ? props.input.value : "D MMMM YYYY HH:mm"}
          onChange={param => props.input.onChange(param)} 
          />
        {props.help && <FormHelperText>{props.help}</FormHelperText>}
        {props.meta.error && <span>{props.meta.error}</span>}
      </div>
    </FormGroup>
  )
}


/**
 * To be used with Field.
 * Render the classic submit & cancel buttons, with eventually a delete button.
 * Props :
 * - submitting : pass it to disable the buttons when submitting. Usefull only when a promise is
 * returned to redux form submit function
 * - onCancel : callback to call when the user clicks on cancel button
 * - onRemove : callback to call when the user clicks on remove button. If it is not set, the remove
 * button is not displayed
 * - spinner : boolean, if true displays a spinner next to save / submit button.
 * - submitText : custom text on submit button. "Save" is default
 * - removeText : custom text on delete button. "Remove" is default
 */
let renderSubmitGroup = (props) => {

  const { t } = props;

  return(
    <FormGroup className="row">
      <div className="col-md-6 col-6 text-left">
        {props.onCancel ?
          <Button
            type="button"
            disabled={props.submitting || props.spinner}
            onClick={props.onCancel}
            bsStyle="primary text-left"
            className="neutre"

          >
            {t('wutiko.cancel')}
          </Button>
        : null }

        {props.onRemove ?
          <Button
            type="button"
            disabled={props.submitting || props.spinner}
            onClick={props.onRemove}
            bsStyle="primary text-right"
            className="red"
          >
            {props.removeText || t('wutiko.remove')}
          </Button>
        : null}
      </div>
      <div className="col-md-6 col-6 text-right">
        <Button
          type="submit"
          // className="pull-right"
          className={props.className}
          disabled={props.submitting || props.spinner}
          bsStyle="primary"
        >
        {props.spinner  || null}
        
          {props.submitText || t('wutiko.save')}
        </Button>
      </div>
    </FormGroup>
  );
}


renderSubmitGroup = withTranslation()(renderSubmitGroup);
export { renderSubmitGroup };

let renderSubmitBnt = (props) => {

  const { t } = props;

  return(
    <FormGroup className="OnBnt">
      <div className="text-right">
        <Button
          type="submit"
          className={props.className}
          onClick = {props.onClick}
          disabled={props.submitting || props.spinner}
          bsStyle="primary"
          >
          {props.spinner ? <img src={logo_spinner} className="Form-Dialog-spinner" alt="logo" /> : null}
          {props.submitText || t('wutiko.save')}
        </Button>
      </div>
    </FormGroup>
  );
}
renderSubmitBnt = withTranslation()(renderSubmitBnt);
export { renderSubmitBnt };

let renderSubmit = (props) => {

  const { t } = props;

  return(
    <FormGroup className="row">
     <div className="col-md-5"></div>
      <div className="col-md-3">
        <Button
          type="submit"
          disabled={props.submitting || props.spinner}
          bsStyle="default"
          className={props.className}
        >
        {props.spinner ? <img src={logo_spinner} className="Form-Dialog-spinner" alt="logo" /> : null}
        
          {props.submitText || t('wutiko.save')}
        </Button>
      </div>
       <div className="col-md-4"></div>
    </FormGroup>
  );
}

renderSubmit = withTranslation()(renderSubmit);
export { renderSubmit };
/**
 * Display a static Error in a bootstrap alert box
 * Should be given a visible (bool) prop if it should be displayed
 */
export const StaticError = (props) => {

  if (props.visible) {
    return (
      <Alert bsStyle="danger">
        <strong>{props.text}</strong>
      </Alert>
    );
  } else {
    return(<div></div>);
  }
}

/**
 * Display a static box validation message in a bootstrap alert box
 * Should be given a visible (bool) prop if it should be displayed
 */
export const StaticSuccess = (props) => {

  if (props.visible) {
    return (
      <Alert bsStyle="success">
        <strong>{props.text}</strong>
      </Alert>
    );
  } else {
    return(<div></div>);
  }
}

/**
 * Display an error box that can be closed by the user
 * Should be given a visible (bool) if it should be displayed
 */
export class ClosableError extends Component {

  getInitialState() {
    return {
      alertVisible: true
    };
  }

  handleAlertDismiss() {
    this.setState({alertVisible: false});
  }

  render() {

    if (this.props.visible && this.state.alertVisible) {
      return (
        <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
          <strong>{this.props.text}</strong>
          <p>
            <Button onClick={this.handleAlertDismiss}>Got It</Button>
          </p>
        </Alert>
      );
    } else {
      return(<div></div>)
    }
  }
}


/**
 * To use this component :
 * After autocompleting from the Google Maps API, it gives back the location id
 * So you need to provide it a callback thas will keep trace of the city id, stateprovince_id
 * and country_id in the parent component :
 * updateLocationId (data) {
 *    this.setState({ location: data });
 * }
 * The data objects is :
 * {
 *    city_id: <city_id>,
 *    stateprovince_id: <stateprovince_id>,
 *    country_id: <country_id>,
 * }
 */
class renderPlaceAutocomplete extends Component {

  constructor(props){
    super(props);
    // this.onChange = (newValue) => this.props.input.onChange(newValue)
    this.onChange=this.onChange.bind(this)
    this.handleSelect = this.handleSelect.bind(this);
    this.resetInpuValue=this.resetInpuValue.bind(this);
    this.state={
      value:""
    }
  }
  resetInpuValue(){
    console.log(this.state.value)
    this.setState({value:''})
  }
  onChange(newValue){
    console.log(newValue)
    this.setState({value:newValue})
    this.props.input.onChange(newValue)
  }
  componentDidMount() {
    this.setState({value:this.props.input.value||this.props.defaultValue})
  }
  handleSelect(location) {

    this.onChange(location)

    var city_name;
    var stateprovince_name;
    var country_name;
    var city_lat;
    var city_long;
    var stateprovince_lat;
    var stateprovince_long;
    var country_lat;
    var country_long;

    /*  Google API returns an array, with an object for the place.
     *  Tree of the object :
        - Array
          - 0:
            -address_components (Array)
              - object x :
                - long_name
                - short_name
                - types (Array)
                  - 0 : 'locality', 'administrative_leve_area_x', 'country'

            - place_id
        Full doc here : https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
     */
    geocodeByAddress(location,  (err, { lat, lng }, results) => {
      if (err) {
        return
      }
      console.log('Entire payload from Google API', results)

      results[0].address_components.forEach(component => {
        if(component.types[0] === 'locality'){ city_name = component.long_name; }
        if(component.types[0] === 'administrative_area_level_1'){ stateprovince_name = component.long_name; }
        if(component.types[0] === 'country'){ country_name = component.long_name; }
      });

      city_lat = lat;
      city_long = lng;

      geocodeByAddress(stateprovince_name,  (err, { lat, lng }) => {
        if (err) {
          return
        }
        console.log('Geocoding success!', { lat, lng })
        stateprovince_lat = lat;
        stateprovince_long = lng;


        geocodeByAddress(country_name,  (err, { lat, lng }) => {
          if (err) {
            return
          }
          console.log('Geocoding success!', { lat, lng })
          country_lat =  lat;
          country_long = lng;

          console.log("variables : ", city_name, stateprovince_name, country_name, city_lat, city_long,
            stateprovince_lat, stateprovince_long, country_lat, country_long);

          const data = {
            country_name: country_name,
            country_lat: country_lat,
            country_lng: country_long,
            stateprovince_name: stateprovince_name,
            stateprovince_lat: stateprovince_lat,
            stateprovince_lng: stateprovince_long,
            city_name: city_name,
            city_lat: city_lat,
            city_lng: city_long,
            is_active: true,
          }

          let instance = this.props.axiosInstance;
          instance.post('/locations/locations/add/', data)
          .then((response) => { 
            this.props.updateLocationId(response.data,data);
            // this.props.getLocation(response.data,data);
            console.log("renderPlaceAutocomplete response", response);
          })
          .catch((err) => {
            console.log("renderPlaceAutocomplete error", err);
          })
        })
      })
    })
  }

  render(){

    const cssClasses = {
      input: 'form-control',
      autocompleteContainer: 'bootstrap-typeahead',
    }

    const options = {
      types: ['(cities)'],
    }

    const validationState = (this.props.meta.touched &&
    (this.props.meta.error && "error")) ||  null;
    const value=this.state.value
    return(
      <FormGroup validationState={validationState} >
        <FormLabel><div>{this.props.label}<b style={{color:'red'}}>{this.props.required?'*':null}</b></div></FormLabel>
        <InputGroup>
                      {/* <InputGroupAddon addonType="prepend">
                      <Button color="success"><i class="fa fa-search" aria-hidden="true"></i></Button>
                      </InputGroupAddon> */}
                      <PlacesAutocomplete
                    value={value}
                    onChange={this.onChange}
                    onSelect={this.handleSelect}
                    classNames={cssClasses}
                    placeholder={this.props.placeholder}
                    options={options}
                  />
                      <InputGroupAddon addonType="append">
                      {
                          this.props.removeIcon && this.state.value?
                          <IconButton onClick={()=>this.resetInpuValue()}><Clear/></IconButton>
                          :null
                        }
                        {/* {this.state.value?<Button color="danger" onClick={()=>this.resetSearInput()}><i class="fa fa-times" aria-hidden="true"></i></Button>:null} */}
                      </InputGroupAddon>
                    </InputGroup>


        {this.props.meta.touched && (this.props.meta.error && <span>{this.props.meta.error}</span>)}
      </FormGroup>
    )
  }
}

function mapStateToProps(state) {
  return {
    axiosInstance: state.user.axiosInstance,
  };
}

renderPlaceAutocomplete = connect(mapStateToProps, undefined)(renderPlaceAutocomplete);
export { renderPlaceAutocomplete };

class renderPlaceAutocompleteWithTag extends Component {

  constructor(props){
    super(props);
    // this.onChange = (newValue) => this.props.input.onChange(newValue)
    this.onChange=this.onChange.bind(this)
    this.handleSelect = this.handleSelect.bind(this);
    this.resetInpuValue=this.resetInpuValue.bind(this);
    this.state={
      value:""
    }
  }
  resetInpuValue(){
    console.log(this.state.value)
    this.setState({value:''})
  }
  onChange(newValue){
    console.log(newValue)
    this.setState({value:newValue})
    this.props.input.onChange(newValue)
  }
  componentDidMount() {
    this.setState({value:this.props.input.value||this.props.defaultValue})
  }
  handleSelect(location) {

    this.onChange(location)

    var city_name;
    var stateprovince_name;
    var country_name;
    var city_lat;
    var city_long;
    var stateprovince_lat;
    var stateprovince_long;
    var country_lat;
    var country_long;

    /*  Google API returns an array, with an object for the place.
     *  Tree of the object :
        - Array
          - 0:
            -address_components (Array)
              - object x :
                - long_name
                - short_name
                - types (Array)
                  - 0 : 'locality', 'administrative_leve_area_x', 'country'

            - place_id
        Full doc here : https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
     */
    geocodeByAddress(location,  (err, { lat, lng }, results) => {
      if (err) {
        return
      }
      console.log('Entire payload from Google API', results)

      results[0].address_components.forEach(component => {
        if(component.types[0] === 'locality'){ city_name = component.long_name; }
        if(component.types[0] === 'administrative_area_level_1'){ stateprovince_name = component.long_name; }
        if(component.types[0] === 'country'){ country_name = component.long_name; }
      });

      city_lat = lat;
      city_long = lng;

      geocodeByAddress(stateprovince_name,  (err, { lat, lng }) => {
        if (err) {
          return
        }
        console.log('Geocoding success!', { lat, lng })
        stateprovince_lat = lat;
        stateprovince_long = lng;


        geocodeByAddress(country_name,  (err, { lat, lng }) => {
          if (err) {
            return
          }
          console.log('Geocoding success!', { lat, lng })
          country_lat =  lat;
          country_long = lng;

          console.log("variables : ", city_name, stateprovince_name, country_name, city_lat, city_long,
            stateprovince_lat, stateprovince_long, country_lat, country_long);

          const data = {
            country_name: country_name,
            country_lat: country_lat,
            country_lng: country_long,
            stateprovince_name: stateprovince_name,
            stateprovince_lat: stateprovince_lat,
            stateprovince_lng: stateprovince_long,
            city_name: city_name,
            city_lat: city_lat,
            city_lng: city_long,
            is_active: true,
          }

          let instance = this.props.axiosInstance;
          instance.post('/locations/locations/add/', data)
          .then((response) => { 
            this.props.updateLocationId(response.data,data,this.props.tag);
            // this.props.getLocation(response.data,data);
            console.log("renderPlaceAutocompleteWithTag response", response);
          })
          .catch((err) => {
            console.log("renderPlaceAutocompleteWithTag error", err);
          })
        })
      })
    })
  }

  render(){

    const cssClasses = {
      input: 'form-control',
      autocompleteContainer: 'bootstrap-typeahead',
    }

    const options = {
      types: ['(cities)'],
    }

    const validationState = (this.props.meta.touched &&
    (this.props.meta.error && "error")) ||  null;
    const value=this.state.value
    return(
      <FormGroup validationState={validationState} >
        <FormLabel><div>{this.props.label}<b style={{color:'red'}}>{this.props.required?'*':null}</b></div></FormLabel>
        <InputGroup>
                      {/* <InputGroupAddon addonType="prepend">
                      <Button color="success"><i class="fa fa-search" aria-hidden="true"></i></Button>
                      </InputGroupAddon> */}
                      <PlacesAutocomplete
                    value={value}
                    onChange={this.onChange}
                    onSelect={this.handleSelect}
                    classNames={cssClasses}
                    placeholder={this.props.placeholder}
                    options={options}
                  />
                      <InputGroupAddon addonType="append">
                      {
                          this.props.removeIcon && this.state.value?
                          <IconButton onClick={()=>this.resetInpuValue()}><Clear/></IconButton>
                          :null
                        }
                        {/* {this.state.value?<Button color="danger" onClick={()=>this.resetSearInput()}><i class="fa fa-times" aria-hidden="true"></i></Button>:null} */}
                      </InputGroupAddon>
                    </InputGroup>


        {this.props.meta.touched && (this.props.meta.error && <span>{this.props.meta.error}</span>)}
      </FormGroup>
    )
  }
}

function mapStateToProps2(state) {
  return {
    // axiosInstance: state.user.blankAxiosInstance,
    axiosInstance: state.user.axiosInstance,
  };
}

renderPlaceAutocompleteWithTag = connect(mapStateToProps2, undefined)(renderPlaceAutocompleteWithTag);
export { renderPlaceAutocompleteWithTag};

class renderPlaceAutocompleteWithTagAnonyme extends Component {

  constructor(props){
    super(props);
    // this.onChange = (newValue) => this.props.input.onChange(newValue)
    this.onChange=this.onChange.bind(this)
    this.handleSelect = this.handleSelect.bind(this);
    this.resetInpuValue=this.resetInpuValue.bind(this);
    this.state={
      value:""
    }
  }
  resetInpuValue(){
    console.log(this.state.value)
    this.setState({value:''})
  }
  onChange(newValue){
    console.log(newValue)
    this.setState({value:newValue})
    this.props.input.onChange(newValue)
  }
  componentDidMount() {
    this.setState({value:this.props.input.value||this.props.defaultValue})
  }
  handleSelect(location) {

    this.onChange(location)

    var city_name;
    var stateprovince_name;
    var country_name;
    var city_lat;
    var city_long;
    var stateprovince_lat;
    var stateprovince_long;
    var country_lat;
    var country_long;

    /*  Google API returns an array, with an object for the place.
     *  Tree of the object :
        - Array
          - 0:
            -address_components (Array)
              - object x :
                - long_name
                - short_name
                - types (Array)
                  - 0 : 'locality', 'administrative_leve_area_x', 'country'

            - place_id
        Full doc here : https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
     */
    geocodeByAddress(location,  (err, { lat, lng }, results) => {
      if (err) {
        return
      }
      console.log('Entire payload from Google API', results)

      results[0].address_components.forEach(component => {
        if(component.types[0] === 'locality'){ city_name = component.long_name; }
        if(component.types[0] === 'administrative_area_level_1'){ stateprovince_name = component.long_name; }
        if(component.types[0] === 'country'){ country_name = component.long_name; }
      });

      city_lat = lat;
      city_long = lng;

      geocodeByAddress(stateprovince_name,  (err, { lat, lng }) => {
        if (err) {
          return
        }
        console.log('Geocoding success!', { lat, lng })
        stateprovince_lat = lat;
        stateprovince_long = lng;


        geocodeByAddress(country_name,  (err, { lat, lng }) => {
          if (err) {
            return
          }
          console.log('Geocoding success!', { lat, lng })
          country_lat =  lat;
          country_long = lng;

          console.log("variables : ", city_name, stateprovince_name, country_name, city_lat, city_long,
            stateprovince_lat, stateprovince_long, country_lat, country_long);

          const data = {
            country_name: country_name,
            country_lat: country_lat,
            country_lng: country_long,
            stateprovince_name: stateprovince_name,
            stateprovince_lat: stateprovince_lat,
            stateprovince_lng: stateprovince_long,
            city_name: city_name,
            city_lat: city_lat,
            city_lng: city_long,
            is_active: true,
          }

          let instance = this.props.axiosInstance;
          instance.post('/locations/locations/add/', data)
          .then((response) => { 
            this.props.updateLocationId(response.data,data,this.props.tag);
            // this.props.getLocation(response.data,data);
            console.log("renderPlaceAutocompleteWithTag response", response);
          })
          .catch((err) => {
            console.log("renderPlaceAutocompleteWithTag error", err);
          })
        })
      })
    })
  }

  render(){

    const cssClasses = {
      input: 'form-control',
      autocompleteContainer: 'bootstrap-typeahead',
    }

    const options = {
      types: ['(cities)'],
    }

    const validationState = (this.props.meta.touched &&
    (this.props.meta.error && "error")) ||  null;
    const value=this.state.value
    return(
      <FormGroup validationState={validationState} >
        <FormLabel><div>{this.props.label}<b style={{color:'red'}}>{this.props.required?'*':null}</b></div></FormLabel>
        <InputGroup>
                      {/* <InputGroupAddon addonType="prepend">
                      <Button color="success"><i class="fa fa-search" aria-hidden="true"></i></Button>
                      </InputGroupAddon> */}
                      <PlacesAutocomplete
                    value={value}
                    onChange={this.onChange}
                    onSelect={this.handleSelect}
                    classNames={cssClasses}
                    placeholder={this.props.placeholder}
                    options={options}
                  />
                      <InputGroupAddon addonType="append">
                      {
                          this.props.removeIcon && this.state.value?
                          <IconButton onClick={()=>this.resetInpuValue()}><Clear/></IconButton>
                          :null
                        }
                        {/* {this.state.value?<Button color="danger" onClick={()=>this.resetSearInput()}><i class="fa fa-times" aria-hidden="true"></i></Button>:null} */}
                      </InputGroupAddon>
                    </InputGroup>


        {this.props.meta.touched && (this.props.meta.error && <span>{this.props.meta.error}</span>)}
      </FormGroup>
    )
  }
}

function mapStateToProps1(state) {
  return {
    axiosInstance: state.user.blankAxiosInstance,
    // axiosInstance: state.user.axiosInstance,
  };
}

renderPlaceAutocompleteWithTagAnonyme = connect(mapStateToProps1, undefined)(renderPlaceAutocompleteWithTagAnonyme);
export { renderPlaceAutocompleteWithTagAnonyme };

// The "searching" display between the moment when onSearch is triggered, and the moment
// when the props of the component have been updated (in general, because the options dataset
// has been fulfilled with new data)
// If there is an issue, please check the the props have effectively been updated
/**
 * Autocommplete item fetching data from a server.
 * The output value (when validating the form) is always an array containing the selected
 * objects (with all their properties).
 * Props to provide :
 * - labelKey : the string field that will be use for the search AND display. Can places-autocomplete-addressform
 * a function to concatenate multiple strings
 * - filterBy : callback to control entrely how the filtering works. Takes options ant inputText as
 * params, and should return true or false depending if the option should be displayed in the list.
 * - allowNew : allow to add a new item to the component selection (not to the original
 * dataset). The new component is included in the output value array in the form :
 *    customOption: true
 *    id: "new-id-5"
 *    <labelKeyName>: <labelKeyContent> 
 * - dataset : the set of data to do the autocomplete on. Should ba an array.
 * - onChange : optional callback (parameters : event, newValue, previousVaue),
 * can be provided to do some specific action when Enter or the value is selected
 * - onInputChange : optional callback (parameters : stringValue), can be specified to act
 * on the string value currently typed in by the user
 * - onSearch : mandatory, callback (param : stringQuery) that wil be launch when the user
 * types in some string. Not the same that onInputChange because it triggers the text
 * 'Searching' to be displayed when the data is fetching and not updated.
 */

 
let renderAsyncAutocomplete = (props) => {

  const { t } = props;

  const validationState = (props.meta.touched &&
  (props.meta.error && "error")) ||  null;

  return (
    <FormGroup validationState={validationState}>
      {props.label && <FormLabel>{props.label}<b style={{color:'red'}}>{props.required?'*':null}</b></FormLabel>}
      <AsyncTypeahead
        filterBy={() => true} // custom filtering : we display all results. Only the backend will filter the options.
        labelKey={props.labelKey}
        minLength={2} // prevent the compnent to do a search when the page is loading
        allowNew={props.allowNew}
        options={props.dataset} // supposed to be the data
        placeholder={props.placeholder}
        newSeletionPrefix={t("wutiko.add")}
        onChange={props.input.onChange}
        onSearch={props.onSearch}
        className={props.className}
        onInputChange={props.onInputChange}
        submitFormOnEnter={props.submitFormOnEnter}
        multiple={props.multiple}
        renderMenu={props.renderMenu}
        renderMenuItemChildren={props.renderMenuItemChildren}
        newSelectionPrefix={props.AddNewLabel}
        caseSensitive={props.caseSensitive}
        selectHintOnEnter={true}
        defaultSelected={props.defaultSelected}
        // ignoreDiacritics={props.ignoreDiacritics}
        maxHeight={500}
        isLoading={props.isLoading}
        emptyLabel={props.emptyLabel}
        searchText={''}
      />
      {props.meta.touched && (props.meta.error && <span>{props.meta.error}</span>)}

    </FormGroup>
  );
}

renderAsyncAutocomplete = withTranslation()(renderAsyncAutocomplete);
export { renderAsyncAutocomplete }; 

export const FilterAutocomplete = (props) => {
  return (
      <div>
      <AsyncTypeahead
        labelKey={props.labelKey}
        filterBy={() => true} // custom filtering : we display all results. Only the backend will filter the options.
        minLength={1} // prevent the compnent to do a search when the page is loading
        options={props.dataset} // supposed to be the data
        placeholder={props.placeholder}
        onSearch={props.onSearch}
        renderMenu={() => (<div></div>)}
      />
      </div>
  );
}

// code from https://github.com/erikras/redux-form/issues/71 (asiniy comment)
export class renderFileInput extends Component {

  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    this.props.input.onChange(e.target.files[0])
  }

  render() {

    const validationState = (this.props.meta.touched &&
    (this.props.meta.error && "error")) ||  null;
    // FIXME : the meta.error prop is undefined when placed AFTER the input for some very weird
    // reason (it is defined when placed before >< ...)
    return (
      <FormGroup validationState={validationState}>
        <FormLabel>{this.props.label}</FormLabel>
        <input type="file" src={this.props.input.value} onChange={this.onChange} />
        {this.props.help && <FormHelperText>{this.props.help}</FormHelperText>}
        <span>{this.props.meta.error}</span>
      </FormGroup>
    )
  }
}

export class renderFileImageInput extends Component {

  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    this.props.input.onChange(e.target.files[0])
    console.log('////////////////////', e.target.files[0].type.split('image'))
  }

  render() {

    const validationState = (this.props.meta.touched &&
    (this.props.meta.error && "error")) ||  null;
    // FIXME : the meta.error prop is undefined when placed AFTER the input for some very weird
    // reason (it is defined when placed before >< ...)
    return (
      <FormGroup validationState={validationState}>
        <FormLabel>{this.props.label}</FormLabel>
        <b style={{color:'blue'}} type="file" onClick={()=>this.fileInput.click()} className="neutre">
        <i className='fa fa-file-image-o'></i>
        </b>
        {this.props.input.value?this.props.input.value.name:null}
        <input type="file" src={this.props.input.value} onChange={this.onChange} ref={fileInput=>this.fileInput=fileInput} style={{display:'none'}}/>
        {this.props.help && <FormHelperText>{this.props.help}</FormHelperText>}
        <span>{this.props.meta.error}</span>
      </FormGroup>
    )
  }
}

export class renderFileFileInput extends Component {

  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    this.props.input.onChange(e.target.files[0]);
    console.log('////////////////////', e.target.files[0])
  }

  render() {

    const validationState = (this.props.meta.touched &&
    (this.props.meta.error && "error")) ||  null;
    // FIXME : the meta.error prop is undefined when placed AFTER the input for some very weird
    // reason (it is defined when placed before >< ...)
    return (
      <FormGroup validationState={validationState}>
        <FormLabel>{this.props.label}</FormLabel>
        <b type="file" onClick={()=>this.fileInput.click()} className="neutre">
        <i className='fa fa-paperclip'></i>
        </b>
        {this.props.input.value?this.props.input.value.name:null}
        <input type="file" src={this.props.input.value} onChange={this.onChange} ref={fileInput=>this.fileInput=fileInput} style={{display:'none'}}/>
        {this.props.help && <FormHelperText>{this.props.help}</FormHelperText>}
        <span>{this.props.meta.error}</span>
      </FormGroup>
    )
  }
}
// code insired from https://github.com/erikras/redux-form/issues/71 (asiniy comment) and
// https://github.com/roadmanfong/react-cropper/blob/master/example/src/Demo.jsx
/**
 * Component proposing an image input and a diplay of the image, allowing to crop the image.
 * The redux-form submit value is then a blob containing the cropped image.
 * Props:
 * - label
 * - help
 * - aspectRatio (number, default=1): ratio of the cropper
 * - heigth (number, default=200): height of the image display in pixels. The width fits the parent
 * component.
 */

class renderImageInput extends Component {

  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this);
    this.cropImage = this.cropImage.bind(this);
    this.state = {
      src: null,
    }
  }

  onChange(e) {

    const { t } = this.props;
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    if(files[0].size > 5000000){
      alert("Sorry, images large than 5Mo are not allowed. Please compress it or choose another one.");
      return;
    } else if(files[0].type !== 'image/jpeg' && files[0].type !== 'image/jpg' && files[0].type !== 'image/png') {
      alert("Sorry, this format is not allowed");
      return;
    }
    // we load the image and store the result in the state
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ src: reader.result });
    };
    // console.log("######",files[0])
    // console.log("######",files[0].name)
    reader.readAsDataURL(files[0]);
  }

  cropImage() {

    // when the user crops the image, we take the result using the ref and convert it to a blob,
    // that can be sent in a FormData (accecpted by backend for images)
    if(this.cropper) {
      this.cropper
        .getCroppedCanvas()
        .toBlob(
          (jpg) =>{
            this.props.input.onChange(jpg);
          });
    }
  }

  render() {

    const validationState = (this.props.meta.touched &&
    (this.props.meta.error && "error")) ||  null;
    // FIXME : the meta.error prop is undefined when placed AFTER the input for some very weird
    // reason (it is defined when placed before >< ...)
    // src of input is controlled by redux form
    // src of cropper is the loaded image that we will crop
    return (
      <FormGroup validationState={validationState}>
       {/* // <ControlLabel>{this.props.label}</ControlLabel> */}
        <input type="file" src={this.props.input.value} onChange={this.onChange}/>
        {this.state.src ?
          <div>
            <br />
            {/* <Cropper  style={{ height: this.props.height || 250, width: '100%' }}
              aspectRatio={this.props.aspectRatio || 1}
              guides
              src={this.state.src}
              ref={cropper => { this.cropper = cropper;}}
              crop={this.cropImage}
              dragMode="move"
              viewMode={2}
              background={false}
            /> */}
          <Cropper
          style={{ height: this.props.cover_photo? 500 : 200 , width: "100%" }}
          initialAspectRatio={1}
          preview=".img-preview"
          src={this.state.src}
          ref={cropper => { this.cropper = cropper;}}
          crop={this.cropImage}
          viewMode={1}
          guides={true}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
         
        />
          </div>
        : null}
        {/* {this.props.help && <HelpBlock>{this.props.help}</HelpBlock>} */}
        <span>{this.props.meta.error}</span>
      </FormGroup>
    )
  }
}

renderImageInput = (renderImageInput);
export { renderImageInput };

class renderImageInputCropper extends Component {

  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this);
    this.cropImage = this.cropImage.bind(this);
    this.state = {
      src: null,
    }
  }

  onChange(e) {

    const { t } = this.props;
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    if(files[0].size > 5000000){
      alert("Sorry, images large than 5Mo are not allowed. Please compress it or choose another one.");
      return;
    } else if(files[0].type !== 'image/jpeg' && files[0].type !== 'image/jpg' && files[0].type !== 'image/png') {
      alert("Sorry, this format is not allowed");
      return;
    }
    // we load the image and store the result in the state
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ src: reader.result });
    };
    // console.log("######",files[0])
    // console.log("######",files[0].name)
    reader.readAsDataURL(files[0]);
  }

  cropImage() {

    // when the user crops the image, we take the result using the ref and convert it to a blob,
    // that can be sent in a FormData (accecpted by backend for images)
    if(this.cropper) {
      this.cropper
        .getCroppedCanvas()
        .toBlob(
          (jpg) =>{
            this.props.input.onChange(jpg);
          });
    }
  }

  render() {

    const validationState = (this.props.meta.touched &&
    (this.props.meta.error && "error")) ||  null;
    // FIXME : the meta.error prop is undefined when placed AFTER the input for some very weird
    // reason (it is defined when placed before >< ...)
    // src of input is controlled by redux form
    // src of cropper is the loaded image that we will crop style={{ height: this.props.height || 250, width: '100%' }}
    return (
      <FormGroup validationState={validationState}>
        {/* <ControlLabel>{this.props.label}</ControlLabel> */}
        <input type="file" src={this.props.input.value} onChange={this.onChange}/>
        {this.state.src ?
          <div>
            <br />
            <Cropper  
              // aspectRatio={this.props.aspectRatio || 1}
              // guides
              src={this.state.src}
              ref={cropper => { this.cropper = cropper;}}
              crop={this.cropImage}
              dragMode="move"
              // viewMode={2}
              background={false}
              // 
              width={160}
              height={90}
              minWidth={256}
              minHeight={256}
              maxWidth={4096}
              maxHeight={4096}
            />
          </div>
        : null}
        {/* {this.props.help && <HelpBlock>{this.props.help}</HelpBlock>} */}
        <span>{this.props.meta.error}</span>
      </FormGroup>
    )
  }
}

renderImageInputCropper = (renderImageInputCropper);
export { renderImageInputCropper };


/*
-This component does the same thing as renderImageInput, 
-but allows to be invisible and does not use the croper
*/
class renderSimpleImageInput extends Component {

  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this);
    this.state = {
      src: null,
      display:'none'
    }
  }

  onChange(e) {

    // const { t } = this.props;
    e.preventDefault();
    this.setState({ display: 'bloc'});
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    if(files[0].size > 5000000){
      alert("Sorry, images large than 5Mo are not allowed. Please compress it or choose another one.");
      return;
    } else if(files[0].type !== 'image/jpeg' && files[0].type !== 'image/jpg' && files[0].type !== 'image/png') {
      alert("Sorry, this format is not allowed");
      return;
    }
    // we load the image and store the result in the state
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ src: reader.result });
    };
    reader.readAsDataURL(files[0]);
  }

  render() {

    const validationState = (this.props.meta.touched &&
      renderField    (this.props.meta.error && "error")) ||  null;
    // FIXME : the meta.error prop is undefined when placed AFTER the input for some very weird
    // reason (it is defined when placed before >< ...)
    // src of input is controlled by redux form
    // src of cropper is the loaded image that we will crop
    return (
      <FormGroup validationState={validationState}>
        <FormLabel>{this.props.label}</FormLabel>
        <input type="file" src={this.props.input.value} onChange={this.onChange} />
        {this.props.help && <FormHelperText>{this.props.help}</FormHelperText>}
        <span>{this.props.meta.error}</span>
        <button type="submit" style={{display:this.state.display}}>Save</button>
      </FormGroup>
    )
  }
}

renderSimpleImageInput = withTranslation('translation')(renderSimpleImageInput);
export { renderSimpleImageInput };


let renderAutocomplete = (props) => {

  const { t } = props;

  const validationState = (props.meta.touched &&
  (props.meta.error && "error")) ||  null;

  return (
    <FormGroup validationState={validationState}>
      {props.label && <FormLabel>{props.label}</FormLabel>}
      <AsyncTypeahead
        filterBy={() => true} // custom filtering : we display all results. Only the backend will filter the options.
        labelKey={props.labelKey}
        minLength={2} // prevent the compnent to do a search when the page is loading
        allowNew={props.allowNew}
        options={props.dataset} // supposed to be the data
        placeholder={props.placeholder}
        newSeletionPrefix={t("wutiko.add")}
        onChange={props.input.onChange}
        onSearch={props.onKeyUp}
        onInputChange={props.onInputChange}
        submitFormOnEnter={props.submitFormOnEnter}
        multiple={props.multiple}
        renderMenu={props.renderMenu}
        renderMenuItemChildren={props.renderMenuItemChilrenderFielddren}
        maxHeight={500}
      />
      {props.meta.touched && (props.meta.error && <span>{props.meta.error}</span>)}

    </FormGroup>
  );
}

renderAutocomplete = withTranslation()(renderAutocomplete);
export { renderAutocomplete }; 




