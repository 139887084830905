import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { addFielsd2FormError, addFielsd2FormSuccess } from "actions/forms/addFields2Form";

const addFields2FormRequest = async (instance,formId, formFields)=>
    await instance.post(`/forms/${formId}/fields/add/`, {fields:formFields})
    .then(response=>response.data)
    .catch((error)=>{
        if(error.response.status===500 || error.response.status===404){
            return {error:error.response.statusText}
        }else{
            console.log(error.response.data)
            return error.response.data
        }
    })

function* addFields2Form(actions){

    const state = yield select((state)=>state.users);
    let axiosInstance=state.axiosInstance;
    const data =yield call(addFields2FormRequest,axiosInstance, actions.data.formId, actions.data.formFields);
    if(data.message){
        yield put(addFielsd2FormSuccess(data.message))        
    }else{
        yield put(addFielsd2FormError(data.error));
    }
    
}

export function* handleaddFields2Form(){
    yield takeEvery("HANDLE_ADD_FIELDS_2_FORM", addFields2Form)
}
export default function* rootSaga() {
    yield all([fork(handleaddFields2Form)]);
}