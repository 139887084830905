const initialState = {
    listpartners:{
        error:null,
        spinner:false,
        list:[]
    }
 }

 export function partners(state = initialState, action){
 
   switch(action.type) {
   case "HANDLE_GET_PARTNERS":
           return {...state, listpartners:{
               ...state.listpartners, error:null, spinner:true, list:[]
           }}
   case "GET_PARTNERS_FAILLURE":
           return {...state, listpartners:{
               ...state.listpartners, error:action.payload, spinner:false, list:[]
           }}
   case "GET_PARTNERS":
      return {...state, listpartners:{
          ...state.listpartners, error:null, spinner:false, list:action.payload.results
      }}
     default:
       return state;
   }
 }
