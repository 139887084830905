// eslint-disable import / first
import {useHistory} from 'react-router-dom';  
import React,{useEffect,Fragment, useState} from 'react';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import DateFnsUtils from '@date-io/date-fns';
import { Col, Form,  Row, Button} from 'react-bootstrap';
import { renderField, renderSubmitGroup, StaticError,
  renderImageInput, renderDates, renderDatePicker, renderSelect, renderCheckbox} from '../components/formsElements/FormComponents';
import {  EditProfileResetMessages, handleEditProfile } from '../actions/profile';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from 'react-redux';
import Dialog2 from '@material-ui/core/Dialog';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { handleGetProfileDetails } from '../actions/profile';
import TextField from '@material-ui/core/TextField';
import IntlMessages from "util/IntlMessages";
import {Alert} from '@material-ui/lab';
import NativeSelect from '@material-ui/core/NativeSelect';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
//const browserHistory = useHistory();
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

//const first_name = itemDetailsProfile ? itemDetailsProfile.first_name : "Test";

//console.log("first_name", props.first_name);
const test =(props) =>{
console.log("initial fist_name"  ,props.first_name )
 const first_name = props.first_name;
}


const EditProfilePage =  (props,submitError, serverError , params , handleSubmit) =>{


  //const ItemDetailsProfile = useSelector(state=>state.profile.details.details);

  //const ItemDetailsProfile = useSelector(state=>state.profile.get_profile_bassique.details);
  //const first_name = itemDetailsProfile ? itemDetailsProfile.first_name : "Test";
  const {itemDetailsProfile} = props;
  const initState = { 
    first_name:  itemDetailsProfile.first_name? itemDetailsProfile.first_name:"",
    last_name: itemDetailsProfile.last_name?itemDetailsProfile.last_name:"",
    is_hide_age: itemDetailsProfile.is_hide_age,
    date_of_birth: itemDetailsProfile.date_of_birth ? itemDetailsProfile.date_of_birth : "",
    is_disabled: itemDetailsProfile.is_disabled,
   // gender:itemDetailsProfile.gender? itemDetailsProfile.gender:"",
    about_me:itemDetailsProfile.about_me?itemDetailsProfile.about_me:"",
    current_status:itemDetailsProfile.current_status?itemDetailsProfile.current_status:"",
    //current_position: itemDetailsProfile.current_position? itemDetailsProfile.current_position:"",
    username:  itemDetailsProfile.username? itemDetailsProfile.username:"",
    title : itemDetailsProfile.title? itemDetailsProfile.title:"",
  }
  
  //const  {first_name} = props
  const classes = useStyles();
  const [state, setState] = useState(  initState );
  const [tagsPosition, setTagsPosition] = useState([]);
  const [tagsEntreprise, setTagsEntreprise] = useState([]);
  const spinner = useSelector(state=>state.profile.edit_profile.spinner)
  const error = useSelector(state=>state.profile.edit_profile.error)
  const message = useSelector(state=>state.profile.edit_profile.message)
  const companiesData = useSelector(state=>state.search.companies.list)
  const positions = useSelector(state=>state.search.positions.list)
  const [open, setOpen] = React.useState(false);
  const {slug} =useParams();
  console.log("positions", positions? positions : null);
  const [selectedDate, setSelectedDate] = React.useState(itemDetailsProfile.date_of_birth)
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(options[0]);
  const [gender, setGender] = React.useState(itemDetailsProfile.gender);
  const [current_status, setCurrent_status] = React.useState(itemDetailsProfile.current_status);
  const [current_position, setCurrent_position] = React.useState(itemDetailsProfile.current_position);
  
  const handleChange = (event) => {
    setGender(event.target.value);
  };
  const handleChangeCP = (event) => {
    setCurrent_position(event.target.value);
  };

  const handleChangeCS = (event) => {
    setCurrent_status(event.target.value);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChangeCheckbox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeField =(event)=>{
    setState({...state, [event.target.name]:event.target.value});
  }
  const dispatch = useDispatch();
    // useEffect(()=>{
    // //dispatch(handleGetProfileDetails(slug));
    //     },[dispatch]);
        const dateFormatter = str => {
          return str;
        };  

 const submit=(event, values)=>{
   event.preventDefault();

    dispatch(handleEditProfile({...state, gender:gender, 
      date_of_birth: moment.isMoment(selectedDate) ? 
      selectedDate.format('YYYY-MM-DD') : selectedDate,
      current_status: current_status,
      current_position:current_position,
    }
      ))
  }

 
const test =<IntlMessages id="formRechercheDemploi"/>

        const handleClickOpen = () => {
          setOpen(true);
          dispatch(EditProfileResetMessages())
        };
        const handleClose = () => {
          setOpen(false);
        };
        console.log("Profil détail edit profile", itemDetailsProfile)
        const {t} = props;
    return(
    <div className="PageEditProfile">
    <Button variant="outlined" color="primary" className="edit-profile" onClick={handleClickOpen}>
    <EditIcon/>{<IntlMessages id="edit_profile"/>}
    </Button>
    {props ?
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
      <div className="">{<IntlMessages id="edit_profile"/>}</div>
      </DialogTitle>
            <DialogContent className="edit-profile">
               <DialogContentText>
               {message?<Alert severity="success" onClose={()=>dispatch(EditProfileResetMessages())}>{message}</Alert>:null}
              {error?<Alert severity="error" onClose={()=>dispatch(EditProfileResetMessages())}>{error}</Alert>:null}
              {serverError?<Alert severity="error" onClose={()=>dispatch(EditProfileResetMessages())}>{serverError}</Alert>:null}
      
              <Form onSubmit={submit}>
              <Row className="top-50">
                <Col sm={12}>
                 <Row>
                
                   <Col sm={6}>
                  <TextField
                    id="first_name"
                    name="first_name"
                    label={<IntlMessages id="first_name"/>}
                    fullWidth
                    autoComplete="family-name"
                    onChange={handleChangeField}
                    multiLine={true} rows={2}
                    value={state.first_name}
                  />
                  </Col>
                  <Col sm={6}>
                    
                  <TextField
                  id="last_name"
                  name="last_name"
                  label={<IntlMessages id="last_name"/>}
                  fullWidth
                  autoComplete="family-name"
                  onChange={handleChangeField}
                  multiLine={true} rows={2}
                  value={state.last_name}
                />
                  </Col>
                </Row> 
              
                 <Row>
                  <Col sm={12}>
                    <TextField
                    id="title"
                    name="title"
                    label={<IntlMessages id="title"/>}
                    fullWidth
                    autoComplete="family-name"
                    onChange={handleChangeField}
                    multiLine={true} rows={2}
                    value={state.title}
                  />
                  </Col>
                </Row> 
                <Row>
                  <Col sm={12}>
                    <TextField
                    id="about_me"
                    name="about_me"
                    label={<IntlMessages id="about_me"/>}
                    fullWidth
                    autoComplete="family-name"
                    onChange={handleChangeField}
                    multiLine={true} rows={2}
                    value={state.about_me}
                  />
                  </Col>
                </Row> 
               
                <Row>
                <Col sm={12}>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="uncontrolled-native">{<IntlMessages id="formStatus"/>}</InputLabel>
                    <NativeSelect
                      defaultValue={state.current_status}
                      id="current_status"
                      name="current_status"
                      value={current_status}
                      onChange={handleChangeCS}
                    >
                      <option value={0}>En recherche d'emploi </option>
                      <option value={1}>En recherche de stage</option>
                      <option value={2}>En recherche de financement</option>
                      <option value={3}>À l'écoute du marché</option>
                      <option value={4}>En recherche de partenariat</option>
                      <option value={5}>Ouvert a d'autres opportunités</option>
                    </NativeSelect>
                  </FormControl>
                  </Col>
                  </Row>
                  <Row>
                  <Col sm={12}>
                   <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="uncontrolled-native">{<IntlMessages id="gender"/>}</InputLabel>
                    <NativeSelect
                      defaultValue={state.gender}
                      id="gender"
                      name="gender"
                      value={gender}
                      onChange={handleChange}
                      
                    >
                      <option value={0}>Male</option>
                      <option value={1}>Female</option>
                    </NativeSelect>
                  </FormControl> 
                 
                  </Col>
                  </Row> 
                  
                <Row>
                <Col sm={12}>
                   <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="uncontrolled-native">Poste actuel</InputLabel>
                    <NativeSelect
                      defaultValue={state.current_position}
                      value={current_position}
                      name={current_position}
                      onChange={handleChangeCP}
                    >
                        <option value="">--</option>
                        
                        {props.positions?
                        props.positions.map((position , index) =>
                       <option key={index} value={position.position_id.id}> {position.position_id.title} chez {position.organization_id.name}</option>
                       ):null}
                    </NativeSelect>
                  </FormControl>
                  </Col>
                </Row>
                 <Row>
                 <Col sm={12}>
                   <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            
                        <KeyboardDatePicker
                          margin="normal"
                          id="date_of_birth"
                          name="date_of_birth"
                          label={<IntlMessages id="date_of_birth"/>}
                          format="YYYY-MM-DD"
                          rifmFormatter={dateFormatter}
                          value={selectedDate}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />   
                      </MuiPickersUtilsProvider>  
          
                </Col>
                 
                  
                <Col sm={6}>
                  <FormControlLabel
                   control={<Checkbox 
                    checked={state.is_disabled} 
                    onChange={handleChangeCheckbox} 
                    id="is_disabled" 
                    name="is_disabled" />}
                    label="Handicape"
                    />
                </Col> 
                <Col sm={6}>
                 <FormControlLabel
                  control={<Checkbox checked={state.is_hide_age} onChange={handleChangeCheckbox} name="is_hide_age" />}
                  label={<IntlMessages id="formHideBirth"/>} 
                />
                </Col>
                </Row>
                </Col>
              </Row> 
              <Row>
                <Col sm={12}>
                <Field name="submit"
                  className="register-training save pull-rigth"
                  component={renderSubmitGroup}
                 />
                  
                </Col>
              
              </Row> 
              
              </Form>
              </DialogContentText> 
            </DialogContent> 
            {/* <DialogActions>
            
            </DialogActions>  */}
            </Dialog>:null }
      </div>
    );
  }

  export default reduxForm({
  form: 'EditProfilePage',
  enableReinitialize: true,
})(EditProfilePage);

//export default  connect(mapStateToProps) (withTranslation('translation')(EditProfilePage));


