import logo from 'assets/imgs/spinner.gif';
//import Panel from 'react-bootstrap';
import { UncontrolledAlert } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Col, Form, Row, Panel } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import FontAwesome from 'react-fontawesome';
// import { Helmet } from 'react-helmet';
import { getItemAnonymous,fetchItem,getItem,Empty,postItem } from 'actions/index';
import {useParams} from 'react-router-dom';
import React,{useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {  Modal } from 'react-bootstrap';
//import { browserHistory } from 'react-router';
import { renderSubmitGroup } from 'components/formsElements/FormComponents';
import { Table } from 'reactstrap';
import { InputGroup, InputGroupAddon, Button, Input } from 'reactstrap';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';
import { handleSearchArea } from 'actions/search';
import { AddAreaResetMessages, handleAddArea } from 'actions/profile';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Alert} from '@material-ui/lab';
import IntlMessages from "util/IntlMessages";

const initState={
    
  }
  
const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  

  function AddAreasForm ({ props,submitError, serverError, t , params , handleSubmit}) {
        const [state, setState] = useState(initState);
        const [tagsAreas, setTagsAreas] = useState([]);
        const spinner = useSelector(state=> state.profile.add_area.spinner,)
        const error = useSelector(state=>state.profile.add_area.error)
        const message = useSelector(state=>state.profile.add_area.message,)
        const Areas = useSelector(state=>state.search.area.list)
        const AreasData = Areas ? Areas : null
        const [open, setOpen] = React.useState(false);
        const {userId} =useParams();
        console.log("userId", userId? userId : null);
        console.log("Areas", Areas? Areas : null);
        const  dispatch =useDispatch();
        const [options, setOptions] = React.useState([]);
        const [value, setValue] = React.useState(options[0]);
        const [inputValueAreas, setInputValueAreas] = React.useState('');
        const handleSelectAreas=(test,elt, reason)=>{
          setTagsAreas(elt);
        }
       
        const handleSearchChangeAreas=(event)=>{
          dispatch(handleSearchArea({q:event.target.value}))
        }

        const handleClickOpen = () => {
            setOpen(true);
          };
          const handleClose = () => {
            setOpen(false);
            dispatch(AddAreaResetMessages())
          };
        

 const loader=<CircularProgress  color="primary"/>;
  const submit=(event)=>{
    event.preventDefault();
    dispatch(handleAddArea({...state, userId, 
    area_id:tagsAreas.map(item=>item.area_id),
    new_areas:inputValueAreas? [inputValueAreas]:[]
        
    }))
   
  }

return (

<div className="">
<Button variant="outlined" color="" onClick={handleClickOpen}>
<Icon className="fa fa-plus-circle" style={{ color: "#ccc"}} /> 
</Button>
<Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
<DialogTitle id="customized-dialog-title" onClose={handleClose}>
  {<IntlMessages id="add_areas_to_your_profile"/>}
</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
      <div className="row">
      {message ?<Alert severity="success"  onClose={()=>dispatch(AddAreaResetMessages())}>{message}</Alert>: null}
      {serverError ?<Alert  severity="success"   onClose={()=>dispatch(AddAreaResetMessages())}>{serverError.message}</Alert>: null}
      {error ?<Alert severity="danger"  onClose={()=>dispatch(AddAreaResetMessages())}>{error}</Alert>: null}
       <div className="col-lg-12">
         <Form onSubmit={submit}>                            
                 <Autocomplete
                    multiple
                    fullWidth
                    id="position_autocomplete"
                    size="small"
                    freeSolo
                    onChange={handleSelectAreas}
                    options={AreasData}
                    getOptionLabel={(option) => option.name}
                    value={value}
                    inputValue={inputValueAreas}
                    onInputChange={(event, newInputValue) => {
                      setInputValueAreas(newInputValue);}}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} onChange={handleSearchChangeAreas} variant="outlined" 
                      label={<IntlMessages id="add_new"/>}
                      margin="normal"
                      placeholder="Ex: Sport" />
                    )}
                  />
             
              
              {spinner ?loader:<Field name="submit"
              className="register-training save"
              component={renderSubmitGroup}
              />}
              </Form>

        </div>
        </div>
      </DialogContentText>
    </DialogContent>
  </Dialog>


</div>

    );
  
}


AddAreasForm = reduxForm({
  form: 'AddAreasForm'
})(AddAreasForm);

export default (withTranslation('translation')(AddAreasForm));

